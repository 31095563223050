import React, { Component } from "react";
import Logo from "./../../assets/Images/logo.jpg";
import { NotificationManager } from "react-notifications";
import ShopSter from "./../../assets/Images/Shopster.png";
import RightBlue from "./../../assets/Images/blueRight.svg";
import LoginService from "../../Services/Login/LoginService";
import CRMMasterService from "../../Services/Settings/CRMMasterService";
import CampaignService from "../../Services/Campaign/CampaignService";
import { connect } from "react-redux";
import { encryption } from "../../helpers/encryption";
import { videoCallSocket } from "../../helpers/SocketConnection";

class LanguageSelection extends Component {
  constructor(props) {
    super(props);

    this.state = {
      language: "",
      languageData: [],
      isMobileView: false,
    };
    this.LoginService = new LoginService();
    this.CRMMasterService = new CRMMasterService();
    this.CampaignService = new CampaignService();
  }
  componentDidMount() {
    this.handleGetSelectedLanguageDetails();
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
  }
  resize() {
    if (window.innerWidth <= 1000) {
      this.setState({ isMobileView: window.innerWidth <= 760 });
    } else {
      this.setState({ isMobileView: false });
    }
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.resize.bind(this));
  }
  ////handle crm role
  handleCRMRole() {
    let self = this;
    this.CRMMasterService.Post("/StoreCRMRole/GetStoreRolesByUserID")
      .then((response) => {
        let msg = response.message;
        let data = response.responseData.modules;
        if (msg === "Success") {

          
          //To check if video call is enabled or not
          this.props.setIsVideoCallEnable(response.responseData.isVideoEnable)
          let managerDetails = {
            agentId: response.responseData.userID,
            programCode: response.responseData.programCode,
            storeCode: response.responseData.storeCode
          };
          videoCallSocket.emit("storeMangerDetail", managerDetails);
          videoCallSocket.emit("storeManagerOnlineOffline", { managerDetails: managerDetails, isOnline: response.responseData.isOnline });
          // window.localStorage.setItem(
          //   "isNumberMasking",
          //   response.responseData.isNumberMasking
          // );
          // console.log(window.localStorage.getItem("isNumberMasking") +" "+ false)
          // window.localStorage.setItem(
          //   "maskingPrefix",
          //   response.responseData.maskingPrefix
          // );
          // window.localStorage.setItem("maskingSufix", response.responseData.maskingSufix);
          //  window.localStorage.setItem("tenantNumberMasking",response.responseData.tenantLevelMasking)
          if (data !== null) {
            var isCallStorePayAPI = false;
            for (var i = 0; i <= data.length; i++) {
              if (i === data.length) {
                if (isCallStorePayAPI) {
                  self.handleGenerateStorePayLink();
                } else {
                  NotificationManager.error(
                    "You don't have any sufficient page access. Please contact administrator for access.",
                    "",
                    2000
                  );
                }
                self.props.history.push("/store/nomodulefound");
                self.setState({
                  loading: false,
                });
              }
              // else
              if (
                data[i].moduleName === "Dashboard" &&
                data[i].modulestatus === true
              ) {
                self.props.history.push("/store/storedashboard");
                return;
              }
              // else
              if (
                data[i].moduleName === "Tasks" &&
                data[i].modulestatus === true
              ) {
                self.props.history.push("/store/StoreTask");
                return;
              }
              // else
              if (
                data[i].moduleName === "Claim" &&
                data[i].modulestatus === true
              ) {
                self.props.history.push("/store/claim");
                return;
              }
              // else
              if (
                data[i].moduleName === "Campaign" &&
                data[i].modulestatus === true
              ) {
                self.props.history.push("/store/campaign");
                return;
              }
              //  else
              if (
                data[i].moduleName === "Appointment" &&
                data[i].modulestatus === true
              ) {
                self.props.history.push("/store/appointment");
                return;
              }
              // else
              if (
                data[i].moduleName === "MyTicket" &&
                data[i].modulestatus === true
              ) {
                self.props.history.push("/store/myTicketList");
                return;
              }
              //  else
              if (
                data[i].moduleName === "Orders" &&
                data[i].modulestatus === true
              ) {
                self.props.history.push("/store/orders");
                return;
              }
              //else
              if (
                data[i].moduleName === "Chat" &&
                data[i].modulestatus === true
              ) {
                self.props.history.push({
                  pathname: "/store/Chatbot",
                  state: {
                    programCode: response.responseData.programCode,
                    storeCode: response.responseData.storeCode,
                    agentId: response.responseData.userID,
                    tenantID: response.responseData.tenantID,
                    UserName: response.responseData.agentName,
                  },
                });
                return;
              }
              // else
              if (
                data[i].moduleName === "Settings" &&
                data[i].modulestatus === true
              ) {
                self.props.history.push("/store/settings");
                return;
              }
              // else
              if (
                data[i].moduleName === "StorePay" &&
                data[i].modulestatus === true
              ) {
                isCallStorePayAPI = true;
              }
            }
          }
        }
      })
      .catch((error) => {
        console.log(error);
        window.localStorage.setItem("isNumberMasking", false);
        window.localStorage.setItem("maskingPrefix", 0);
        window.localStorage.setItem("maskingSufix", 0);
      });
  }
  ////handle genrate store pay link
  handleGenerateStorePayLink = () => {
    this.LoginService.Post("/StorePay/GenerateStorePayLink")
      .then((response) => {
        var message = response.message;
        //var storePayURL = response.responseData;
        var storePayURL = encryption(response.responseData, "decr");
        if (message === "Success" && storePayURL) {
          // self.setState({ storePayURL });
          window.open(storePayURL, "blank");
        } else {
          // window.location = "http://www.google.com/";
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  ////handle continue button click
  handleContinue() {
    let language = this.state.language;
    if (language === "hindi") {
      window.localStorage.setItem("translateLanguage", language);
    } else if (language === "marathi") {
      window.localStorage.setItem("translateLanguage", language);
    } else if (language === "punjabi") {
      window.localStorage.setItem("translateLanguage", language);
    } else if (language === "gujrati") {
      window.localStorage.setItem("translateLanguage", language);
    } else if (language === "telugu") {
      window.localStorage.setItem("translateLanguage", language);
    } else {
      this.state.translateLanguage = {};
    }
    this.handleCRMRole();
  }
  ////handle skip button click
  handleSkip() {
    this.handleCRMRole();
  }
  ////handle on change
  handleOnChange(e) {
    this.setState({ language: e.target.value });
  }
  ////handle get seleted language details
  handleGetSelectedLanguageDetails() {
    let self = this;
    this.CampaignService.Post("/StoreCampaign/GetSelectedLanguageDetails")
      .then((response) => {
        var message = response.message;
        var responseData = response.responseData;
        var languageData = [];
        if (message === "Success" && responseData) {
          for (let i = 0; i < responseData.length; i++) {
            if (responseData[i].isActive) {
              languageData.push(responseData[i]);
            }
          }
          self.setState({ languageData });
        } else {
          self.setState({ languageData: [] });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  render() {
    return (
      <div className="auth-wrapper box-center Mainpro">
        <div className="Shopster">
          <img src={ShopSter} alt="ShopSter" className="" />
        </div>
        <h3 className="logintxt">Select Language</h3>
        <div className="auth-content">
          <div className="card">
            <div className="card-body text-center">
              {!this.state.isMobileView ? (
                <div className="mb-4">
                  <img
                    src={Logo}
                    alt="logo"
                    className="main-logo initial-logo logohi"
                  />
                </div>
              ) : null}
              {!this.state.isMobileView ? (
                <label
                  className="sign-in"
                  style={{
                    fontSize: "16px",
                    fontWeight: "bold",
                  }}
                >
                  Choose Language
                </label>
              ) : (
                <label
                  className="sign-in"
                  style={{
                    display: "block",
                    fontSize: "14px",
                    fontWeight: "500",
                  }}
                >
                  Choose One Language
                </label>
              )}
              <div
                className="languagebox"
                onClick={this.handleOnChange.bind(this)}
              >
                {this.state.languageData !== null
                  ? this.state.languageData.map((item, i) => {
                    return (
                      <button
                        className={
                          item.language.split(" ")[0].toLowerCase() ===
                            this.state.language
                            ? "langbtn active"
                            : "langbtn"
                        }
                        value={item.language.split(" ")[0].toLowerCase()}
                      >
                        {item.language}
                        {item.language.split(" ")[0].toLowerCase() ===
                          this.state.language && this.state.isMobileView ? (
                          <img src={RightBlue} style={{ float: "right" }} />
                        ) : null}
                      </button>
                    );
                  })
                  : null}
              </div>
              <button
                type="submit"
                className="program-code-button"
                value={this.state.language}
                onClick={this.handleContinue.bind(this)}
              >
                Continue
              </button>
              <p className="skip" onClick={this.handleSkip.bind(this)}>
                Skip
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

// export default LanguageSelection;
const mapStateToProps = (state) => {
};

const mapDispatchToProps = (dispatch) => {
  return {
    setIsVideoCallEnable: (isVideoCallEnable) => dispatch({ type: "IS_VIDEO_CALL_ENABLE", payload: isVideoCallEnable }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LanguageSelection);
