import React, { Component } from "react";
import Header from "./store-header";
import StoreFooter from "./store-footer";
import Aux from "./../../Component/child";
import { connect } from "react-redux";

class storelayout extends Component {
  render() {
    const { children } = this.props;
    return (
      <Aux>
        <div className="drag-drop-main changedCss">
          <Header />
          <div className={this.props.isFullScreen && this.props.isCallAccepted ? "main-content displayChatBody" : "main-content small_screen_overlay"} style={{ backgroundColor: "#f5f8f9" }}>
            {children}
          </div>
          <StoreFooter />
        </div>
      </Aux>
    );
  }
}

const mapStateToProps = state => {
  return {
    isFullScreen: state.isFullScreen,
    isCallAccepted: state.isCallAccepted,
  };
}

const mapDispatchToProps = dispatch => {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(storelayout);
