import React, { Component } from "react";
import { Table, Popover, Tooltip, Card, Spin, Empty, Select } from "antd";
import Pagination from "react-pagination-js";
import "react-pagination-js/dist/styles.css";
import OrderHamb from "./../../../assets/Images/order-hamb.png";
import Info from "./../../../assets/Images/Info-black.png";
import { NotificationManager } from "react-notifications";
import * as translationHI from "../../../translations/hindi";
import * as translationMA from "../../../translations/marathi";
import OrderService from "../../../Services/Orders/OrderService";
import copy from "./../../../assets/Images/copy.png";
import { encryption } from "./../../../helpers/encryption";
import { handleMaskingNumber } from "../../../helpers/maskingNumber";
import config from "../../../helpers/config";
import LoginService from "../../../Services/Login/LoginService";

const {Option} = Select
class DeliveredTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filterOrderDeliveredStatus: false,
      deliveredGridData: [],
      totalCount: 0,
      currentPage: 1,
      postsPerPage: 10,
      strStatus: "",
      statusFilterData: [],
      orderPopoverOverlay: false,
      DeliveredLoading: false,
      translateLanguage: {},
      orderSearchText: "",
      isMobileView: false,
      isNumberMasking: false,
      maskingPrefix: 0,
      maskingSufix: 0,
      tenantMobileMasking: false,
    };
    this.OrderService = new OrderService();
    this.LoginService = new LoginService();
  }
  componentDidMount() {
    this.handleCRMRole();
    this.handleGetOrderDeliveredData();
    this.handleGetOrderStatusFilterData();
    if (window.localStorage.getItem("translateLanguage") === "hindi") {
      this.state.translateLanguage = translationHI;
    } else if (window.localStorage.getItem("translateLanguage") === "marathi") {
      this.state.translateLanguage = translationMA;
    } else {
      this.state.translateLanguage = {};
    }
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
  }
  resize() {
    if (window.innerWidth <= 1000) {
      this.setState({ isMobileView: true });
    } else {
      this.setState({ isMobileView: false });
    }
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.resize.bind(this));
    clearTimeout(this.intervalID);
  }
  //handle crm role data
  handleCRMRole() {
    
    this.LoginService.Post("/StoreCRMRole/GetStoreRolesByUserID")
      .then((response) => {
        let msg = response.message;
        if (msg === "Success") {
          this.setState({
            isNumberMasking: response.responseData.isNumberMasking,
            maskingPrefix: response.responseData.maskingPrefix,
            maskingSufix: response.responseData.maskingSufix,
            tenantMobileMasking:
              response.responseData.tenantLevelMasking,
            isSuggestionText: response.responseData.suggestionText,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  //To handle the copied button icon
  handleCopiedNumber = (contact_number, item) => {
    let numberValue = encryption(contact_number, "enc");
    window.localStorage.setItem("copiedNumber", numberValue);
    this.setState({
      isNumberCopiedClicked: true,
      isTextCopied: true,
      recentCopiedWBsNo: item.id,
      // copiedTextChatId: copiedTextChatId,
    });
    this.state.isNumberCopiedClicked = true;
    setTimeout(() => {
      this.setState({
        isNumberCopiedClicked: false,
        isTextCopied: false,
      });
    }, 3000);
  };

  /// search data
  handleDeliveredSearch = (searchData) => {
    this.setState({
      orderSearchText: searchData,
      currentPage: 1,
    });

    setTimeout(() => {
      this.handleGetOrderDeliveredData();
    }, 5);
  };

  /// handle Get grid data
  handleGetOrderDeliveredData() {
    let self = this;
    var pageNumber = this.state.currentPage;
    this.setState({
      DeliveredLoading: true,
      filterOrderDeliveredStatus: false,
    });
    this.intervalID = setTimeout(
      this.handleGetOrderDeliveredData.bind(this),
      config.pageRefreshTiming
    );
    let inputData = {
      SearchText: this.state.orderSearchText,
      PageNo: pageNumber,
      PageSize: this.state.postsPerPage,
      FilterStatus: this.state.strStatus,
    };
    this.OrderService.PostWithData(
      "/HSOrder/GetOrderDeliveredDetails",
      inputData
    )
      .then((response) => {
        let status = response.message;
        let data = response.responseData;
        if (status === "Success") {
          self.setState({
            deliveredGridData: data.orderDelivereds,
            totalCount: data.totalCount,
            DeliveredLoading: false,
          });
        } else {
          self.setState({
            deliveredGridData: [],
            totalCount: 0,
            DeliveredLoading: false,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  /// handle Get order status filter data
  handleGetOrderStatusFilterData() {
    let self = this;
    let inputParams = {
      pageID: 4,
    };
    this.OrderService.PostWithParams(
      "/HSOrder/GetOrderStatusFilter",
      inputParams
    )
      .then((response) => {
        let status = response.message;
        let data = response.responseData;
        if (status === "Success") {
          self.setState({
            statusFilterData: data,
          });
        } else {
          NotificationManager.error(status);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  PaginationOnChange = async (numPage) => {
    await this.setState({
      currentPage: numPage,
    });

    this.handleGetOrderDeliveredData();
  };
  /// pagination per page item
  handlePageItemchange = async (e) => {
    await this.setState({
      postsPerPage: e,
      currentPage: 1,
    });

    this.handleGetOrderDeliveredData();
  };

  /// individual status check
  handleCheckDeliIndividualStatus() {
    var checkboxes = document.getElementsByName("DeliveredStatus");
    var strStatus = "";
    for (var i in checkboxes) {
      if (isNaN(i) === false) {
        if (checkboxes[i].checked === true) {
          if (checkboxes[i].getAttribute("attrIds") !== null)
            strStatus += checkboxes[i].getAttribute("attrIds") + ",";
        }
      }
    }
    this.setState({
      strStatus,
    });
  }

  render() {
    const TranslationContext = this.state.translateLanguage.default;
    return (
      <>
        {this.state.orderPopoverOverlay && (
          <div className="order-popover-overlay"></div>
        )}
        <div className="table-cntr store dv-table-paging delivtab">
          <p className="shopi">
            {TranslationContext !== undefined
              ? TranslationContext.a.delivered
              : "Delivered"}
          </p>
          {!this.state.isMobileView ? (
            <Table
              className="components-table-demo-nested antd-table-campaign antd-table-order antd-table-order-mobile custom-antd-table"
              columns={[
                {
                  title:
                    TranslationContext !== undefined
                      ? TranslationContext.label.orderid
                      : "Order ID",
                  dataIndex: "invoiceNo",
                  key: "invoiceNo",
                  render: (row, item) => {
                    return (
                      <div className="d-flex align-items-center namenumbermain">
                        <Tooltip title={item.invoiceNo} placement="bottom">
                          <p className="order-bill-no">
                            {item.invoiceNo.length > 13
                              ? item.invoiceNo.substr(0, 13).concat("...")
                              : item.invoiceNo}
                          </p>
                        </Tooltip>
                        <div className="namenumber">
                          <p>{item.customerName},</p>
                          <p className="order-small-font">
                            {item.mobileNumber}
                          </p>
                        </div>
                      </div>
                    );
                  },
                },
                {
                  className: "table-coloum-hide",
                  title:
                    TranslationContext !== undefined
                      ? TranslationContext.title.customer
                      : "Customer",

                  render: (row, item) => {
                    return (
                      <div>
                        <p>{item.customerName},</p>
                        <div className="text_copied_div">
                          <p className="order-small-font">
                            {/* {item.mobileNumber} */}
                            {this.state.isNumberMasking === true &&
                              this.state.tenantMobileMasking === true ? (
                              <p className="num">
                                {handleMaskingNumber(
                                  item.mobileNumber,
                                  this.state.maskingPrefix,
                                  this.state.maskingSufix
                                )}
                              </p>
                            ) : (
                              <p className="num stop_copying">
                                {item.mobileNumber}
                              </p>
                            )}
                          </p>
                          {this.state.isNumberMasking === true &&
                            this.state.tenantMobileMasking === true ? (
                            <p>
                              <img
                                className="imgsz"
                                src={copy}
                                onClick={(e) =>
                                  this.handleCopiedNumber(
                                    item.mobileNumber,
                                    item
                                  )
                                }
                              // onClick={() => {navigator.clipboard.writeText(item.customerName)}}
                              />
                            </p>
                          ) : null}
                          {this.state.isTextCopied &&
                            this.state.recentCopiedWBsNo == item.id ? (
                            <label className="text_copied"> Copied </label>
                          ) : null}
                        </div>
                      </div>
                    );
                  },
                },
                {
                  title:
                    TranslationContext !== undefined
                      ? TranslationContext.title.items
                      : "Items",
                  className: "cus-strecth",
                  render: (row, item) => {
                    return (
                      <div className="d-flex align-items-center">
                        <p>{item.orderDeliveredItems.length}</p>
                        <Popover
                          content={
                            <Table
                              className="components-table-demo-nested antd-table-campaign antd-table-order custom-antd-table"
                              columns={[
                                {
                                  title:
                                    TranslationContext !== undefined
                                      ? TranslationContext.title.itemid
                                      : "Item ID",
                                  dataIndex: "itemID",
                                },
                                {
                                  title:
                                    TranslationContext !== undefined
                                      ? TranslationContext.title.itemname
                                      : "Item Name",
                                  dataIndex: "itemName",
                                },
                                {
                                  title:
                                    TranslationContext !== undefined
                                      ? TranslationContext.title.itemprice
                                      : "Item Price",
                                  dataIndex: "itemPrice",
                                },
                                {
                                  title:
                                    TranslationContext !== undefined
                                      ? TranslationContext.title.quantity
                                      : "Quantity",
                                  dataIndex: "quantity",
                                },
                              ]}
                              scroll={{ y: 240 }}
                              pagination={false}
                              dataSource={item.orderDeliveredItems}
                            />
                          }
                          trigger="click"
                          overlayClassName="order-popover-table order-popover"
                          onVisibleChange={(visible) =>
                            this.setState({ orderPopoverOverlay: visible })
                          }
                        >
                          <img
                            src={OrderHamb}
                            className="order-hamb iteminfo"
                          />
                          <img
                            src={Info}
                            className="item-img"
                          />
                        </Popover>
                      </div>
                    );
                  },
                },
                {
                  className: "table-coloum-hide",
                  title:
                    TranslationContext !== undefined
                      ? TranslationContext.title.date
                      : "Date",
                  render: (row, item) => {
                    return (
                      <div>
                        <p>{item.date}</p>
                        <p className="order-small-font">{item.time}</p>
                      </div>
                    );
                  },
                },
                {
                  title:
                    TranslationContext !== undefined
                      ? TranslationContext.title.status
                      : "Status",
                  className:
                    "camp-status-header camp-status-header-statusFilter table-coloum-hide order-status-header",
                  render: (row, item) => {
                    return (
                      <div className="d-flex align-items-center">
                        <p>{item.statusName}</p>
                      </div>
                    );
                  },
                  filterDropdown: () => {
                    return (
                      <div className="campaign-status-drpdwn">
                        <ul>
                          {this.state.statusFilterData !== null &&
                            this.state.statusFilterData.map((item, b) => (
                              <li key={b}>
                                <input
                                  type="checkbox"
                                  id={"New" + item.statusID}
                                  className="ch1"
                                  onChange={this.handleCheckDeliIndividualStatus.bind(
                                    this
                                  )}
                                  name="DeliveredStatus"
                                  attrIds={item.statusID}
                                />
                                <label htmlFor={"New" + item.statusID}>
                                  <span className="ch1-text">
                                    {item.statusName}
                                  </span>
                                </label>
                              </li>
                            ))}
                        </ul>
                        <div className="dv-status">
                          <button
                            className="btn-apply-status"
                            onClick={this.handleGetOrderDeliveredData.bind(
                              this
                            )}
                          >
                            {TranslationContext !== undefined
                              ? TranslationContext.button.apply
                              : "Apply"}
                          </button>
                          <button
                            className="btn-cancel-status"
                            onClick={() =>
                              this.setState({
                                filterOrderDeliveredStatus: false,
                              })
                            }
                          >
                            {TranslationContext !== undefined
                              ? TranslationContext.button.cancel
                              : "Cancel"}
                          </button>
                        </div>
                      </div>
                    );
                  },
                  filterDropdownVisible: this.state.filterOrderDeliveredStatus,
                  onFilterDropdownVisibleChange: (visible) =>
                    this.setState({ filterOrderDeliveredStatus: visible }),
                  filterIcon: (filtered) => (
                    <span
                      style={{ color: filtered ? "#1890ff" : undefined }}
                    ></span>
                  ),
                },
              ]}
              pagination={false}
              showSizeChanger={true}
              onShowSizeChange={true}
              dataSource={this.state.deliveredGridData}
              loading={this.state.DeliveredLoading}
            />
          ) : (
            <>
              <Pagination
                currentPage={this.state.currentPage}
                totalSize={this.state.totalCount}
                sizePerPage={this.state.postsPerPage}
                changeCurrentPage={this.PaginationOnChange}
                theme="bootstrap"
              />
              <div className="position-relative">
                <div className="item-selection Camp-pagination">
                  <Select
                    value={this.state.postsPerPage}
                    onChange={this.handlePageItemchange}
                  >
                    <Option value={10}>10</Option>
                    <Option value={20}>20</Option>
                    <Option value={30}>30</Option>
                  </Select>
                  <p>
                    {TranslationContext !== undefined
                      ? TranslationContext.p.itemsperpage
                      : "Items per page"}
                  </p>
                </div>
              </div>
              <Spin spinning={this.state.DeliveredLoading}>
                <div className="mobdelivt">
                  {this.state.deliveredGridData.length > 0
                    ? this.state.deliveredGridData.map((item, key) => {
                      return (
                        <Card>
                          <p className="innumb">{item.invoiceNo}</p>
                          {item.orderDeliveredItems.length > 0 ? (
                            <>
                              <Popover
                                content={
                                  <Table
                                    className="components-table-demo-nested antd-table-campaign antd-table-order custom-antd-table"
                                    columns={[
                                      {
                                        title:
                                          TranslationContext !== undefined
                                            ? TranslationContext.title.itemid
                                            : "Item ID",
                                        dataIndex: "itemID",
                                      },
                                      {
                                        title:
                                          TranslationContext !== undefined
                                            ? TranslationContext.title
                                              .itemname
                                            : "Item Name",
                                        dataIndex: "itemName",
                                      },
                                      {
                                        title:
                                          TranslationContext !== undefined
                                            ? TranslationContext.title
                                              .itemprice
                                            : "Item Price",
                                        dataIndex: "itemPrice",
                                      },
                                      {
                                        title:
                                          TranslationContext !== undefined
                                            ? TranslationContext.title
                                              .quantity
                                            : "Quantity",
                                        dataIndex: "quantity",
                                      },
                                    ]}
                                    scroll={{ y: 240 }}
                                    pagination={false}
                                    dataSource={item.orderDeliveredItems}
                                  />
                                }
                                trigger="click"
                                placement="bottom"
                                overlayClassName="order-popover-table order-popover order-popover-table-big"
                              >
                                <div className="mobeye">
                                  <img src={Info} className="item-img" />
                                  <span>
                                    {item.orderDeliveredItems.length}
                                  </span>
                                </div>
                              </Popover>
                            </>
                          ) : null}
                          <div className="namenumber">
                            <p>{item.customerName},</p>
                            <p className="order-small-font">
                              {this.state.isNumberMasking === true &&
                                this.state.tenantMobileMasking === true ? (
                                <p className="num">
                                  {handleMaskingNumber(
                                    item.mobileNumber,
                                    this.state.maskingPrefix,
                                    this.state.maskingSufix
                                  )}
                                </p>
                              ) : (
                                <p className="num stop_copying">
                                  {item.mobileNumber}
                                </p>
                              )}
                            </p>
                          </div>
                          <div className="row">
                            <div className="col-7">
                              <p className="date">
                                {item.date} {item.time}
                              </p>
                            </div>
                            <div className="col-5">
                              <p className="status">{item.statusName}</p>
                            </div>
                          </div>
                        </Card>
                      );
                    })
                    : null}
                </div>
                {this.state.deliveredGridData.length === 0 ? (
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                ) : null}
              </Spin>
            </>
          )}
          {!this.state.isMobileView ? (
            <>
              <Pagination
                currentPage={this.state.currentPage}
                totalSize={this.state.totalCount}
                sizePerPage={this.state.postsPerPage}
                changeCurrentPage={this.PaginationOnChange}
                theme="bootstrap"
              />
              <div className="position-relative">
                <div className="item-selection Camp-pagination">
                  <Select
                    value={this.state.postsPerPage}
                    onChange={this.handlePageItemchange}
                  >
                    <Option value={10}>10</Option>
                    <Option value={20}>20</Option>
                    <Option value={30}>30</Option>
                  </Select>
                  <p>
                    {TranslationContext !== undefined
                      ? TranslationContext.p.itemsperpage
                      : "Items per page"}
                  </p>
                </div>
              </div>
            </>
          ) : null}
        </div>
      </>
    );
  }
}

export default DeliveredTab;
