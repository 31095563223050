import APICaller from "../../APICaller/APICaller";

class StoreUserService {
  constructor() {
    this.APICaller = new APICaller();
  }
  async Post(url) {
    return await this.APICaller.Post(url);
  }
  async PostWithData(url, inputData) {
    return await this.APICaller.PostWithData(url, inputData);
  }
  async PostWithParams(url, inputData) {
    return await this.APICaller.PostWithParams(url, inputData);
  }
}

export default StoreUserService;
