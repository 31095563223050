import React, { Component } from "react";
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
import Tooltip from '@material-ui/core/Tooltip';
import "./../../assets/css/store-chat.css";
import { connect } from 'react-redux';

class CardData extends Component {
    constructor(props) {
        super(props);
        this.state = {
            colorIndexVal : 0,
            selectedSize : '',
            selectedColor : '',
            isDisabled: false,
            selectedSizeIndex:0,
            sizeValidation: false,
        }
    }

  handleSelectedColor = async (i,color,availableSizeQty) => {
        await this.setState({colorIndexVal : i, selectedColor : color});
        var carddetails=this.props.cardDetails;
        for(var j=0;j<carddetails.length;j++)
        {
            if(this.props.item.itemCode === carddetails[j].itemCode)
            {
                carddetails[j].colour = color;
                
                for (var s=0; s < this.props.item.availableSize[i].length;s++)
                {
                  if(this.props.item.availableSize[i][s] == carddetails[j].size)
                  {
                    if(availableSizeQty[s] <= 0){
                      carddetails[j].size = "";
                     this.setState({selectedSize : "", sizeValidation: true});
                    }
                    carddetails[j].barCode = this.props.item.barCode[i][s];
                    break;
                  } else if( s === this.props.item.availableSize[i].length - 1)
                  {
                      carddetails[j].size = "";
                     this.setState({selectedSize : "", sizeValidation: true});
                  }
                }
            }
        }
        this.props.CardAdded(carddetails);
      }

  handleSelectedSize = async (size, availableSizeQty,barCode, price, sizeindex) => {

    if(availableSizeQty > 0){
      await this.setState({selectedSize : size, selectedSizeIndex: sizeindex, sizeValidation: false});
      
      var carddetails=this.props.cardDetails;
      for(var i=0;i<carddetails.length;i++)
      {
          if(this.props.item.itemCode === carddetails[i].itemCode)
          {
              carddetails[i].size = size;
              carddetails[i].availableQty = availableSizeQty;
              carddetails[i].barCode = barCode;
              carddetails[i].price = price;
          }
      }
      this.props.CardAdded(carddetails);
      //console.log( this.state.selectedSize);
    }
}

    render(){
        return(
            <>
              <div className="cart-item-list"  >
                <div className="cart-item-details-container">
                  <div className="cart-item-image">
                    <img
                        className="chat-card-img"
                        src={this.props.item.imageUrl}
                        alt="Product Image"
                        title={this.props.item.name}
					            />
                  </div>
                  <div className="cart-item-details">
                    <div className="cart-item-name">{this.props.item.name}</div>
                      <div className="cart-item-size-label">size : {!this.state.sizeValidation ? this.state.selectedSize : 
                              <lable className="add-to-cart-sizevalidation">
                                Select available size
                              </lable>
                              }</div>
                        <div className="cart-item-size">
                          <ToggleButtonGroup type="radio" name="options" className="size-select"
                            style={{overflowX: "auto", overflowY: "hidden"}}
                          >
                          {this.props.item.availableSize[this.state.colorIndexVal].map((size,i) => 
                          <Tooltip title={this.props.item.sizeAvailability[this.state.colorIndexVal][i]+" LEFT"} arrow placement="top-end">
                            <ToggleButton className="cart-size-button" 
                              disabled = {this.props.item.sizeAvailability[this.state.colorIndexVal][i] > 0 ? false : true }
                              key={i}  value={size} 
                              onClick={()=>this.handleSelectedSize(size,
                                this.props.item.sizeAvailability[this.state.colorIndexVal][i] ,
                                this.props.item.barCode[this.state.colorIndexVal][i],
                                this.props.item.sizePrice[this.state.colorIndexVal][i],i) }
                            >{size}</ToggleButton>
                          </Tooltip>
                        )}
                      </ToggleButtonGroup>
                    </div>
                    <div className="cart-item-color-label">Color : {this.state.selectedColor}</div>
                    <div className="cart-item-color">
                      <div className="color-Map">
                        <div className="check-div" style={{overflowX: "auto", overflowY: "hidden"}}>
                          {this.props.item.availableColor.map((colr,i) => 
                          <div className="color-inside-map" key={i}
                          onClick={()=>this.handleSelectedColor(i,colr[0],
                            this.props.item.sizeAvailability[i] )
                          }>
                            <label className="color-Label"  value={colr} style={{backgroundColor:colr[0]}}></label>
                            <span className="color-span" key={i} value={colr}>{colr[0]}</span>
                          </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> 
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
      cardDetails: state.selectedCardData,
    };
  }
  
const mapDispatchToProps = dispatch => {
    return {
      CardAdded: (tDetails) => dispatch({ type: 'ADDTOCART', payload: tDetails }),
    }
  }
  
  export default connect(mapStateToProps, mapDispatchToProps)(CardData);

// export default CardData; 