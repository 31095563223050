
import { ActionTypeText } from "./ActionTypeText"

const actionText = new ActionTypeText();

const iState = {
    selectedCardData: [],
    isFullScreen: false,
    isVideoCallEnable: false,
    isCallRecived: false,
    customerMobileNumber: '',
    roomName: '',
    messageCount: 0,
    onGoingChatMobileNo: '',
    isMobileView: false
}
const reducers = (state = iState, action) => {

    switch (action.type) {
        case actionText.addToCartText:
            return {
                ...state,
                selectedCardData: action.payload
            }

        case 'IS_FULL_SCREEN':
            return {
                ...state,
                isFullScreen: action.payload
            }
        case 'IS_VIDEO_CALL_ENABLE':
            return {
                ...state,
                isVideoCallEnable: action.payload
            }
        case 'IS_CALL_RIECIVED':
            return {
                ...state,
                isCallRecived: action.payload
            }
        case 'SET_CUSTOMER_NUMBER':
            return {
                ...state,
                customerMobileNumber: action.payload
            }
        case 'SET_ROOMNAME':
            return {
                ...state,
                roomName: action.payload
            }
        case 'SET_MESSAGECOUNT':
            return {
                ...state,
                messageCount: action.payload
            }
        case 'SET_ONGOINGCHAT':
            return {
                ...state,
                onGoingChatMobileNo: action.payload
            }
        case 'IS_MOBILE_VIEW':
            return {
                ...state,
                isMobileView: action.payload
            }
        default:
            return state;
    }
}
export default reducers;