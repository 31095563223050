import React, { Component } from "react";
import { Select, Spin } from "antd";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NotificationManager } from "react-notifications";
import HSMService from "../../Services/HSM/HSMService";
import SearchBlueImg from "./../../assets/Images/search-blue.png";
import { encryption } from "../../helpers/encryption";
import LoginService from "../../Services/Login/LoginService";
import paste from "./../../assets/Images/paste.png";
import { handleMaskingNumber } from "../../helpers/maskingNumber";
import "./../../assets/css/webbotLink.css";
import { ImPaste } from 'react-icons/im';
import { WindowSharp } from "@mui/icons-material";

const { Option } = Select;
class WebBotLink extends Component {
  constructor(props) {
    super(props);

    this.state = {
      WebBotData: [],
      orderIdData: [],
      selectedWebBot: undefined,
      OptionMode: 0,
      showSendBtn: false,
      ShopingNoSelected: 0,
      OrdIdsSelected: 0,
      countryCode: [],
      mobileNo: "",
      custNameValidation: "",
      mobileNoValidation: "",
      countryCodeValidation: "",
      ordIdValidation: "",
      selectedCountryCode: 91,
      customerName: "",
      addSaveLoading: false,
      wabaNoData: [],
      WABANoSelected: null,
      wabaNoValidation: "",
      isGenerateLink: false,
      isStoreCode: false,
      storeCodeData: [],
      storeCodeValidation: "",
      selectedStoreCode: "",
      generateLink: "",
      linkSaveLoading: false,
      productSearchData: [],
      searchLoading: false,
      productSearchText: "",
      selectedProduct: [],
      numberValue: "",
      isNumberMasking: false,
      isChecked: false,
      tenantMobileMasking: false,
      searchItemCode:""
    };
    this.HSMService = new HSMService();
    this.LoginService = new LoginService();
  }
  componentDidMount() {
    this.handleGetWebBotOption();

    if (this.state.isNumberMasking === true) {
      this.handleEncryptedData();
    }

    this.handleCRMRole();

    // if (document.getElementById('mobielNumberInput')) {
    //   document.getElementById('mobielNumberInput').onClick = function () { return false; };
    // }
  }

  componentDidUpdate() {
    if (document.getElementById("mobielNumberInput")) {
      document
        .getElementById("mobielNumberInput")
        .removeEventListener("mousemove", (event) => {
          event.preventDefault();
        });
    }
  }
  /// handle id selct
  handleIdSelection = (value) => {
    this.setState({
      selectedWebBot: value,
      OptionMode: 0,
      custNameValidation: "",
      mobileNoValidation: "",
      countryCodeValidation: "",
      ordIdValidation: "",
      wabaNoData: [],
      WABANoSelected: null,
      wabaNoValidation: "",
      selectedCountryCode: 91,
      noProductFound: "",
      linkSaveLoading: false,
      addSaveLoading: false,
      customerName: "",
      mobileNo: "",
      ShopingNoSelected: 0,
      OrdIdsSelected: 0,
      showSendBtn: false,
      WABANoSelected: null,
      storeCodeValidation: "",
      productSearchText: "",
      noProductFound: "",
      productSearchData: [],
      selectedProduct: [],
      generateLink: "",
    });
   var itemCode = window.localStorage.getItem("CopiedItemCode")
   if (itemCode !== undefined && itemCode !== null) {
     if (itemCode.length > 0) {
       this.setState({
         searchItemCode: itemCode,
       });
     }
   }
    this.handleGetWebBotFilterByOptionID(value);
  };

  handleNextData() {
    this.setState({
      OptionMode: this.state.selectedWebBot,
      showSendBtn: true,
    });
  }
  //handle get web bot select option data
  handleGetWebBotOption = () => {
    let self = this;
    this.HSMService.Post("/WebBot/GetWebBotOption")
      .then((response) => {
        var message = response.message;
        var responseData = response.responseData;
        if (message === "Success" && responseData) {
          let isGenerateLink = false;
          let isStoreCode = false;
          if (
            responseData.filter((x) => x.option === "Generate Link").length > 0
          ) {
            isGenerateLink = responseData.filter(
              (x) => x.option === "Generate Link"
            )[0].isActive;
          }
          if (
            responseData.filter((x) => x.option === "Store Code").length > 0
          ) {
            isStoreCode = responseData.filter(
              (x) => x.option === "Store Code"
            )[0].isActive;
          }
          if (isStoreCode) {
            if (localStorage.getItem("UserProfile")) {
              let tempUserProfile = JSON.parse(
                localStorage.getItem("UserProfile")
              );
              this.setState({
                selectedStoreCode: tempUserProfile.storeCode,
              });
            }
          }

          self.setState({
            WebBotData: responseData,
            isStoreCode,
            isGenerateLink,
          });
        } else {
          self.setState({ WebBotData: [] });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  //handle get webbot filter by option id
  handleGetWebBotFilterByOptionID = (optionId) => {
    let self = this;
    let inputParams = {
      OptionID: optionId,
    };
    this.HSMService.PostWithParams(
      "/WebBot/GetWebBotFilterByOptionID",
      inputParams
    )
      .then((response) => {
        var message = response.message;
        var responseData = response.responseData;
        if (message === "Success" && responseData) {
          if (
            optionId === 1 ||
            optionId === 2 ||
            optionId === 9 ||
            optionId === 10
          ) {
            self.setState({
              countryCode: responseData.webBotFilter,
              wabaNoData: responseData.webBotFilterDataList,
              isNumberMasking:
                window.localStorage.getItem("isNumberMasking") == "true"
                  ? true
                  : false,
              maskingPrefix: window.localStorage.getItem("maskingPrefix"),
              maskingSufix: window.localStorage.getItem("maskingSufix"),
              tenantMobileMasking:
                window.localStorage.getItem("tenantMobileMasking") === "true"
                  ? true
                  : false,
              storeCodeData: responseData.webBotStoreCodeFilter || [],
            });
            if (responseData.webBotFilterDataList.length === 1) {
              self.setState({
                WABANoSelected: responseData.webBotFilterDataList[0].wabaNo,
              });
            }
          } else {
            self.setState({
              storeCodeData: responseData.webBotStoreCodeFilter || [],
              orderIdData: responseData.webBotFilter,
              wabaNoData: responseData.webBotFilterDataList,
            });
            if (responseData.webBotFilterDataList.length === 1) {
              self.setState({
                WABANoSelected: responseData.webBotFilterDataList[0].wabaNo,
              });
            }
          }
        } else {
          if (optionId === 1 || optionId === 2) {
            self.setState({
              countryCode: [],
              wabaNoData: [],
              storeCodeData: [],
            });
          } else {
            self.setState({
              orderIdData: [],
              wabaNoData: [],
              storeCodeData: [],
            });
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  handleCheckFinalData(isSend) {
    if (
      this.state.OptionMode === 1 ||
      this.state.OptionMode === 9 ||
      this.state.OptionMode === 10
    ) {
      if (this.state.isStoreCode) {
        if (
          this.state.customerName !== "" &&
          this.state.selectedCountryCode > 0 &&
          this.state.selectedStoreCode !== "" &&
          this.state.mobileNo !== "" &&
          this.state.WABANoSelected
        ) {
          this.handleFinalSendData(isSend);
        } else {
          this.setState({
            custNameValidation: "Please Enter Customer Name.",
            mobileNoValidation: "Please Enter Mobile No.",
            countryCodeValidation: "Please Select Country Code.",
            wabaNoValidation: "Please Select WABA No.",
            storeCodeValidation: "Please Select Store Code.",
          });
        }
      } else {
        if (
          this.state.customerName !== "" &&
          this.state.selectedCountryCode > 0 &&
          this.state.mobileNo !== "" &&
          this.state.WABANoSelected
        ) {
          this.handleFinalSendData(isSend);
        } else {
          this.setState({
            custNameValidation: "Please Enter Customer Name.",
            mobileNoValidation: "Please Enter Mobile No.",
            countryCodeValidation: "Please Select Country Code.",
            wabaNoValidation: "Please Select WABA No.",
          });
        }
      }
    } else if (this.state.OptionMode === 2) {
      if (
        this.state.customerName !== "" &&
        this.state.selectedCountryCode > 0 &&
        this.state.mobileNo !== "" &&
        this.state.WABANoSelected
      ) {
        this.handleFinalSendData(isSend);
      } else {
        this.setState({
          custNameValidation: "Please Enter Customer Name.",
          mobileNoValidation: "Please Enter Mobile No.",
          countryCodeValidation: "Please Select Country Code.",
          wabaNoValidation: "Please Select WABA No.",
        });
      }
    } else if (this.state.OptionMode === 9) {
      if (this.state.isStoreCode) {
        if (
          this.state.customerName !== "" &&
          this.state.selectedCountryCode > 0 &&
          this.state.selectedStoreCode !== "" &&
          this.state.mobileNo !== "" &&
          this.state.WABANoSelected
        ) {
          this.handleFinalSendData(isSend);
        } else {
          this.setState({
            custNameValidation: "Please Enter Customer Name.",
            mobileNoValidation: "Please Enter Mobile No.",
            countryCodeValidation: "Please Select Country Code.",
            wabaNoValidation: "Please Select WABA No.",
            storeCodeValidation: "Please Select Store Code.",
          });
        }
      } else {
        if (
          this.state.customerName !== "" &&
          this.state.selectedCountryCode > 0 &&
          this.state.mobileNo !== "" &&
          this.state.WABANoSelected
        ) {
          this.handleFinalSendData(isSend);
        } else {
          this.setState({
            custNameValidation: "Please Enter Customer Name.",
            mobileNoValidation: "Please Enter Mobile No.",
            countryCodeValidation: "Please Select Country Code.",
            wabaNoValidation: "Please Select WABA No.",
          });
        }
      }
    } else {
      if (this.state.ShopingNoSelected > 0 || this.state.OrdIdsSelected > 0) {
        this.handleFinalSendData(isSend);
      } else {
        this.setState({
          ordIdValidation: "Please Enter Id.",
        });
      }
    }
  }
  // handle final send data
  handleFinalSendData(isSend) {
    let self = this;

    if (!isSend) {
      this.setState({
        linkSaveLoading: true,
        addSaveLoading: false,
      });
    } else {
      this.setState({
        linkSaveLoading: false,
        addSaveLoading: true,
      });
    }

    var wabaNo = "";

    if (this.state.OptionMode === 3 || this.state.OptionMode === 4) {
      wabaNo = this.state.orderIdData.filter(
        (x) => x.shoppingID == this.state.ShopingNoSelected
      )[0].wabaNumber;
    } else if (this.state.OptionMode === 5) {
      wabaNo = this.state.orderIdData.filter(
        (x) => x.orderID == this.state.OrdIdsSelected
      )[0].wabaNumber;
    } else {
      wabaNo = this.state.WABANoSelected;
    }

    let inputData = {
      OptionID: this.state.OptionMode,
      CustomerName: this.state.customerName,
      MobileNo: this.state.mobileNo.length > 10 ? this.state.mobileNo : this.state.selectedCountryCode + this.state.mobileNo,
      ShopingBagNo: this.state.ShopingNoSelected,
      OrderID: this.state.OrdIdsSelected,
      StoreCode: this.state.selectedStoreCode || null,
      WABANo: wabaNo,
      IsSend: isSend,
    };
    if (this.state.OptionMode === 10) {
      inputData.chatMode = true;
    } else {
      inputData.chatMode = false;
    }
    if (this.state.OptionMode === 9) {
      if (this.state.selectedProduct.length > 0) {
        let tempProductArray = [];
        this.state.selectedProduct.forEach((element) => {
          let tempItmeObj = {};
          tempItmeObj.barCode = "";
          tempItmeObj.itemCode = element;
          tempProductArray.push(tempItmeObj);
        });
        inputData.productArray = tempProductArray;
        inputData.productText = "";
        inputData.showProductArray = true;
        inputData.productType = "productarray";
        inputData.productArrayBy = "itemCode";
      } else {
        inputData.productArrayBy = "";
        inputData.productType = "text";
        inputData.showProductArray = true;
        inputData.productArray = [
          {
            barCode: "",
            itemCode: "",
          },
        ];
        inputData.productText = this.state.productSearchText;
      }
    }
    this.HSMService.PostWithData("/WebBot/SendWebBotHSM", inputData)
      .then((response) => {
        var message = response.message;
        var data = response.responseData;
        if (!isSend) {
          self.setState({
            generateLink: data.generateLink || "",
            linkSaveLoading: false,
          });
        } else {
          self.setState({
            generateLink: "",
            linkSaveLoading: false,
          });
        }
        if (message === "Success") {
          // if (this.state.OptionMode == 10) {
          //   this.setState({
          //     generateLink: data.generateLink,
          //   });
          // }
          if (isSend) {
            // if (this.state.OptionMode == 10) {
            //   self.setState({
            //     linkSaveLoading: false,
            //     addSaveLoading: false,
            //   });
            // } else {
            self.setState({
              linkSaveLoading: false,
              addSaveLoading: false,
              customerName: "",
              selectedCountryCode: 91,
              mobileNo: "",
              ShopingNoSelected: 0,
              OrdIdsSelected: 0,
              OptionMode: 0,
              selectedWebBot: undefined,
              showSendBtn: false,
              WABANoSelected: null,
              selectedStoreCode: "",
              storeCodeValidation: "",
              productSearchText: "",
              noProductFound: "",
              productSearchData: [],
              selectedProduct: [],
            });
            //}

            NotificationManager.success("HSM send successfully.");
            if (localStorage.getItem("UserProfile") && this.state.isStoreCode) {
              let tempUserProfile = JSON.parse(
                localStorage.getItem("UserProfile")
              );
              this.setState({
                selectedStoreCode: tempUserProfile.storeCode,
              });
            }
          }
        } else {
          self.setState({
            addSaveLoading: false,
            linkSaveLoading: false,
          });
          if (data.errorMessage) {
            NotificationManager.error(data.errorMessage);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  handleOrdIdsSelection = (value) => {
    if (this.state.OptionMode === 3 || this.state.OptionMode === 4) {
      this.setState({
        ShopingNoSelected: value,
      });
    } else {
      this.setState({
        OrdIdsSelected: value,
      });
    }
  };
  /// select country code change
  handleCountryCodeSelect = (value) => {
    this.setState({
      selectedCountryCode: value,
    });
  };
  /// mobile no validation
  handleMobileNoValidation = (e) => {
    var reg = /^[0-9\b]+$/;

    if (e.target.value === "" || reg.test(e.target.value)) {
      this.setState({ mobileNo: e.target.value });
    } else {
      e.target.value = "";
    }
  };
  //handle onchage waba no select
  handleWABANoSelect = (value) => {
    this.setState({
      WABANoSelected: value,
    });
  };
  handleStoreCodeSelect = (value) => {
    console.log(value);
    this.setState({
      selectedStoreCode: value,
    });
  };
  handleSelectProduct = (e) => {
    this.setState({
      selectedProduct: e,
    });
  };
  handleSearchProduct = (e) => {
    this.setState({
      productSearchText: e.target.value,
      noProductFound: "",
    });
  };
  //handle get chat notification count
  handleSearchChatItemDetails() {
    let self = this;
    if (!this.state.productSearchText) {
      this.setState({
        noProductFound: "Please Enter Product Name",
      });
      return false;
    }
    var inputParam = {};
    let tempChatSession = JSON.parse(localStorage.getItem("ChatSession"));

    if (tempChatSession.cardSearchStoreCode) {
      let tempChatSession = JSON.parse(localStorage.getItem("UserProfile"));
      inputParam.SearchText = this.state.productSearchText;
      inputParam.ProgramCode = tempChatSession.programCode;
      inputParam.StoreCode = tempChatSession.storeCode
        ? tempChatSession.storeCode
        : null;
    } else {
      inputParam.SearchText = this.state.productSearchText;
      inputParam.ProgramCode = tempChatSession.programCode;
    }
    this.setState({
      noProductFound: "",
      searchLoading: true,
    });
    this.HSMService.PostWithParams(
      "/CustomerChat/searchChatItemDetailsWB",
      inputParam
    )
      .then((response) => {
        var message = response.message;
        var searchCardData = response.responseData.items;
        self.setState({ searchLoading: false });

        if (message == "Success" && searchCardData) {
          this.setState({
            productSearchData: searchCardData,
          });
        } else {
          self.setState({
            productSearchData: [],
            searchLoading: false,
          });
        }
      })
      .catch((error) => {
        self.setState({
          productSearchData: [],
          searchLoading: false,
        });
        console.log(error);
      });
  }

  handleEncryptedData = () => {
    let maskedValue = window.localStorage.getItem("copiedNumber");
    console.log("maskedValue ", maskedValue);
    let mobileNumber = encryption(maskedValue, "decr");
    console.log("mobileNumber", mobileNumber);
    this.setState({
      numberValue: mobileNumber,
    });
  };

  //handle crm role data
  handleCRMRole() {
    
    this.LoginService.Post("/StoreCRMRole/GetStoreRolesByUserID")
      .then((response) => {
        let msg = response.message;
        if (msg === "Success") {
          this.setState({
            isNumberMasking:response.responseData.isNumberMasking,
            maskingPrefix: response.responseData.maskingPrefix,
            maskingSufix: response.responseData.maskingSufix,
            tenantMobileMasking:
            response.responseData.tenantLevelMasking,
            isSuggestionText: response.responseData.suggestionText,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  handleEditMobileNumber = (event) => {
    this.setState({
      isChecked: !this.state.isChecked,
      isNumberMasking: !this.state.isNumberMasking,
    });
  };
  //handle ItemCode 
  handlePasteItemCode=async()=>{
  await this.setState({
      productSearchText : this.state.searchItemCode
    })
    await this.handleSearchChatItemDetails()
  }

  handlePasteNumber() {
    let self = this;
    let number = window.localStorage.getItem("copiedNumber");
    let decryptnumber = encryption(number, "decr");
    self.setState({
      mobileNo: decryptnumber,
    });
    let muskedNumber = handleMaskingNumber(
      decryptnumber,
      this.state.maskingPrefix,
      this.state.maskingSufix
    );
    self.setState({
      mobileNo1:muskedNumber,
    })
    return muskedNumber;
  }

  render() {
    return (
      <>
        <div>
          <div className="row m-0">
            <div className="col-md-6 m-auto">
              <div className="right-sect-div minHeightlink m-t-20 heightMOb">
                <div className="createSpace">
                  <label className="create-department">
                    Please select options to send communication to customer
                  </label>

                  <div className="div-padding-1 webbotMob">
                    <Select
                      showSearch
                      style={{ width: 200 }}
                      placeholder="Please Select Option"
                      optionFilterProp="children"
                      className="webBotDd"
                      onChange={this.handleIdSelection}
                      notFoundContent="No Data Found"
                      value={this.state.selectedWebBot}
                    >
                      {this.state.WebBotData !== null
                        ? this.state.WebBotData.filter(
                            (x) =>
                              x.option !== "Generate Link" &&
                              x.option !== "Store Code"
                          ).map((item, i) =>
                            item.isActive ? (
                              <Option
                                key={i}
                                value={item.id}
                                className="webBotOpt"
                              >
                                {item.option}
                              </Option>
                            ) : null
                          )
                        : null}
                    </Select>
                  </div>
                  <div className="m-t-10 m-b-10">
                    <button
                      className="addBtn-ticket-hierarchy"
                      onClick={this.handleNextData.bind(this)}
                    >
                      <label className="pop-over-btnsave-text">Next</label>
                    </button>
                  </div>
                  {this.state.OptionMode === 1 ||
                  this.state.OptionMode === 2 ||
                  this.state.OptionMode === 9 ||
                  this.state.OptionMode === 10 ? (
                    <>
                      <div className="dropDrownSpace m-b-10">
                        <label className="webBotLbl wbLbl">Customer Name</label>
                        <input
                          className="form-control"
                          name="customername"
                          placeholder="Enter Customer Name"
                          maxLength={100}
                          autoComplete="off"
                          onChange={(e) =>
                            this.setState({ customerName: e.target.value })
                          }
                        />
                        {this.state.customerName.length === 0 && (
                          <p style={{ color: "red", marginBottom: "0px" }}>
                            {this.state.custNameValidation}
                          </p>
                        )}
                      </div>
                      {this.state.isStoreCode &&
                      (this.state.OptionMode === 1 ||
                        this.state.OptionMode === 9 ||
                        this.state.OptionMode === 10) ? (
                        <div className="divSpace">
                          <div className="dropDrownSpace">
                            <label className="webBotLbl wbLbl">
                              Store Code
                            </label>
                            <Select
                              showSearch
                              style={{ width: 200 }}
                              placeholder="Select Store Code"
                              optionFilterProp="children"
                              className="webBotDd"
                              onChange={this.handleStoreCodeSelect}
                              notFoundContent="No Data Found"
                              value={this.state.selectedStoreCode}
                            >
                              {this.state.storeCodeData.length > 0
                                ? this.state.storeCodeData.map((item, i) => {
                                    return (
                                      <Option
                                        id={i}
                                        value={item.storeCode}
                                        className="webBotOpt"
                                        key={i}
                                      >
                                        {item.storeCode}
                                      </Option>
                                    );
                                  })
                                : null}
                            </Select>
                            {!this.state.selectedStoreCode && (
                              <p style={{ color: "red", marginBottom: "0px" }}>
                                {this.state.storeCodeValidation}
                              </p>
                            )}
                          </div>
                        </div>
                      ) : null}

                      <div className="divSpace">
                        <div className="dropDrownSpace">
                          <label className="webBotLbl wbLbl">
                            Country Code
                          </label>
                          <Select
                            showSearch
                            style={{ width: 200 }}
                            placeholder="Select Country Code"
                            optionFilterProp="children"
                            className="webBotDd"
                            onChange={this.handleCountryCodeSelect}
                            notFoundContent="No Data Found"
                            value={this.state.selectedCountryCode}
                          >
                            {this.state.countryCode.length > 0
                              ? this.state.countryCode.map((item, i) => {
                                  return (
                                    <Option
                                      id={i}
                                      value={item.phoneCode}
                                      className="webBotOpt"
                                      key={i}
                                    >
                                      {item.phoneCode}
                                      {" (" + item.iso + ")"}
                                    </Option>
                                  );
                                })
                              : null}
                          </Select>
                          {this.state.selectedCountryCode === 0 && (
                            <p style={{ color: "red", marginBottom: "0px" }}>
                              {this.state.countryCodeValidation}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="dropDrownSpace">
                        <div className="label_checkbox_mobileNo_div">
                          <label className="webBotLbl wbLbl">Mobile No</label>
                          <div className="label_div">
                            <label className="checkbox_label_name">
                              Manual Input
                            </label>
                            <div
                              className="checkbox_div"
                              onClick={(e) => this.handleEditMobileNumber(e)}
                            >
                              <label className="checkbox_label">
                                <span className="checkbox_tick">
                                  {this.state.isChecked ? "L" : null}
                                </span>
                              </label>
                            </div>
                          </div>
                        </div>
                        {this.state.isNumberMasking === true &&
                        this.state.tenantMobileMasking === true ? (
                          <div className="fake-input" id="fake">
                            <input
                              className="form-control"
                              id="mobielNumberInput"
                              name="mobileNo"
                              placeholder="Enter Mobile no"
                              //value={this.state.isNumberMasking === true ? this.state.numberValue : this.state.mobileNo}
                              value={this.state.mobileNo1}
                              onChange={this.handleMobileNoValidation}
                              autoComplete="off"
                              disabled={!this.state.isChecked ? true : false}
                            />
                            <img
                              className="imgsz"
                              src={paste}
                              onClick={this.handlePasteNumber.bind(this)}
                            />
                          </div>
                        ) : (
                          <input
                            className="form-control"
                            id="mobielNumberInput"
                            name="mobileNo"
                            placeholder="Enter Mobile no"
                            //value={this.state.isNumberMasking === true ? this.state.numberValue : this.state.mobileNo}
                            value={this.state.mobileNo}
                            onChange={this.handleMobileNoValidation}
                            autoComplete="off"
                            disabled={!this.state.isChecked ? true : false}
                          />
                        )}
                        {this.state.mobileNo.length === 0 && (
                          <p style={{ color: "red", marginBottom: "0px" }}>
                            {this.state.mobileNoValidation}
                          </p>
                        )}
                      </div>
                      <div className="divSpace">
                        <div className="dropDrownSpace">
                          <label className="webBotLbl wbLbl">WABA No</label>
                          {this.state.wabaNoData.length === 1 ? (
                            <Select
                              showSearch
                              style={{ width: 200 }}
                              placeholder="Select WABA No"
                              optionFilterProp="children"
                              className="webBotDd"
                              onChange={this.handleWABANoSelect}
                              notFoundContent="No Data Found"
                              value={this.state.WABANoSelected}
                              disabled={
                                this.state.wabaNoData.length === 1
                                  ? true
                                  : false
                              }
                            >
                              {this.state.wabaNoData.length > 0
                                ? this.state.wabaNoData.map((item, i) => {
                                    return (
                                      <Option
                                        id={i}
                                        value={item.wabaNo}
                                        className="webBotOpt"
                                        key={i}
                                      >
                                        {item.wabaNo}
                                      </Option>
                                    );
                                  })
                                : null}
                            </Select>
                          ) : null}
                          {this.state.wabaNoData.length === 0 ||
                          this.state.wabaNoData.length > 1 ? (
                            <Select
                              showSearch
                              style={{ width: 200 }}
                              placeholder="Select WABA No"
                              optionFilterProp="children"
                              className="webBotDd"
                              onChange={this.handleWABANoSelect}
                              notFoundContent="No Data Found"
                            >
                              {this.state.wabaNoData.length > 0
                                ? this.state.wabaNoData.map((item, i) => {
                                    return (
                                      <Option
                                        id={i}
                                        value={item.wabaNo}
                                        className="webBotOpt"
                                        key={i}
                                      >
                                        {item.wabaNo}
                                      </Option>
                                    );
                                  })
                                : null}
                            </Select>
                          ) : null}
                          {this.state.WABANoSelected === "" && (
                            <p style={{ color: "red", marginBottom: "0px" }}>
                              {this.state.wabaNoValidation}
                            </p>
                          )}
                        </div>
                      </div>
                      {this.state.OptionMode === 9 ? (
                        <div className="divSpace">
                          <div className="dropDrownSpace">
                            <label className="webBotLbl wbLbl">
                              Search Product
                            </label>
                            <div
                              className="input-group searchtxt-new"
                              style={{ background: "none" }}
                            >
                              <input
                                type="text"
                                className="search-customerAddSrch"
                                placeholder={"Search Product"}
                                name="Search"
                                maxLength="100"
                                autoComplete="off"
                                value={this.state.productSearchText}
                                onChange={this.handleSearchProduct}
                                onKeyPress={this.handleSearchChatItemDetails.bind(
                                  this
                                )}
                              />
                              {/* <span className="input-group-addon seacrh-img-addsearch searchtxt-span-wb"><ImPaste/></span> */}
                              <span
                                onClick={this.handleSearchChatItemDetails.bind(
                                  this
                                )}
                                className="input-group-addon seacrh-img-addsearch searchtxt-span-wb"
                              >
                                <div
                                className="itemCode_paste" 
                                onClick= {this.handlePasteItemCode.bind(this)}
                                ><ImPaste/></div>
                                <img
                                  src={SearchBlueImg}
                                  alt="SearchBlueImg"
                                  className="srch-imge"
                                />
                              </span>
                              <p
                                style={{
                                  color: "red",
                                  marginTop: "5px",
                                }}
                              >
                                {this.state.noProductFound}
                              </p>
                            </div>
                            <label className="webBotLbl wbLbl">
                              Select Products
                            </label>
                            <Select
                              style={{
                                width: 200,
                                height: "fit-content",
                                padding: "5px",
                              }}
                              placeholder="Select Product"
                              optionFilterProp="children"
                              className="webBotDd webBotDd-product"
                              onChange={this.handleSelectProduct}
                              notFoundContent="No Data Found"
                              value={this.state.selectedProduct}
                              mode="multiple"
                            >
                              {this.state.productSearchData.length > 0
                                ? this.state.productSearchData.map(
                                    (item, i) => {
                                      return (
                                        <Option
                                          id={i}
                                          value={item.itemCode}
                                          className="webBotOpt"
                                          key={i}
                                        >
                                          {item.name}
                                        </Option>
                                      );
                                    }
                                  )
                                : null}
                            </Select>
                          </div>
                        </div>
                      ) : null}
                    </>
                  ) : null}

                  {this.state.OptionMode === 3 ||
                  this.state.OptionMode === 4 ||
                  this.state.OptionMode === 5 ? (
                    <div className="dropDrownSpace">
                      <label className="webBotLbl wbLbl">
                        {this.state.OptionMode === 5
                          ? "Order Id"
                          : "Shopping Bag Id"}
                      </label>
                      <Select
                        showSearch
                        style={{ width: 200 }}
                        placeholder={
                          this.state.OptionMode === 5
                            ? "Select Order Id"
                            : "Select Shopping Bag Id"
                        }
                        optionFilterProp="children"
                        className="webBotDd"
                        onChange={this.handleOrdIdsSelection}
                        notFoundContent="No Data Found"
                      >
                        {this.state.orderIdData !== null
                          ? this.state.orderIdData.map((item, o) => (
                              <Option
                                key={o}
                                value={
                                  this.state.OptionMode === 3 ||
                                  this.state.OptionMode === 4
                                    ? item.shoppingID
                                    : this.state.OptionMode === 5
                                    ? item.orderID
                                    : null
                                }
                                className="webBotOpt"
                              >
                                {this.state.OptionMode === 3 ||
                                this.state.OptionMode === 4
                                  ? item.shoppingBagNo +
                                    " " +
                                    "(" +
                                    item.mobileNumber +
                                    ")"
                                  : this.state.OptionMode === 5
                                  ? item.orderID +
                                    " " +
                                    "(" +
                                    item.mobileNumber +
                                    ")"
                                  : null}
                              </Option>
                            ))
                          : null}
                      </Select>
                      {this.state.ShopingNoSelected === 0 &&
                        this.state.OrdIdsSelected === 0 && (
                          <p style={{ color: "red", marginBottom: "0px" }}>
                            {this.state.ordIdValidation}
                          </p>
                        )}
                    </div>
                  ) : null}

                  {this.state.OptionMode > 0 ? (
                    <div className="row m-t-25">
                      {(this.state.OptionMode === 1 ||
                        this.state.OptionMode === 9 ||
                        this.state.OptionMode === 10) &&
                      this.state.isGenerateLink ? (
                        <div className="col-md-6">
                          <button
                            className="addBtn-ticket-hierarchy"
                            onClick={this.handleCheckFinalData.bind(
                              this,
                              false
                            )}
                            disabled={this.state.linkSaveLoading}
                          >
                            <label className="pop-over-btnsave-text">
                              {this.state.linkSaveLoading ? (
                                <FontAwesomeIcon
                                  className="circular-loader"
                                  icon={faCircleNotch}
                                  spin
                                />
                              ) : (
                                ""
                              )}
                              Generate Link
                            </label>
                          </button>
                        </div>
                      ) : null}
                      <div
                        className={
                          (this.state.OptionMode === 1 ||
                            this.state.OptionMode === 9 ||
                            this.state.OptionMode === 10) &&
                          this.state.isGenerateLink
                            ? "col-md-6"
                            : "col-md-12"
                        }
                      >
                        <button
                          className="addBtn-ticket-hierarchy"
                          onClick={this.handleCheckFinalData.bind(this, true)}
                          disabled={this.state.addSaveLoading}
                        >
                          <label className="pop-over-btnsave-text">
                            {this.state.addSaveLoading ? (
                              <FontAwesomeIcon
                                className="circular-loader"
                                icon={faCircleNotch}
                                spin
                              />
                            ) : (
                              ""
                            )}
                            Send
                          </label>
                        </button>
                      </div>
                    </div>
                  ) : null}
                  {this.state.generateLink ? (
                    <div className="row webbot-link">
                      <div className="col-md-12">
                        <p>Please click on</p>
                        <p>
                          <a
                            href={"http://" + this.state.generateLink}
                            target="_blank"
                            rel="webbotlink"
                          >
                            {this.state.generateLink}
                          </a>
                        </p>
                        <p>to launch Webbot</p>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default WebBotLink;
