import React, { Component, Fragment } from "react";
import HeadphoneImg from "./../../assets/Images/headphone.png";
import LoadingImg from "./../../assets/Images/loading.png";
import Headphone2Img from "./../../assets/Images/headphone2.png";
import DownImg from "./../../assets/Images/down.png";
import EyeImg from "./../../assets/Images/eye.png";
import BillInvoiceImg from "./../../assets/Images/bill-Invoice.png";
import copy from "./../../assets/Images/copy.png";
import { encryption } from "../../helpers/encryption";
import WhatsappActive from "./../../assets/Images/whatsapp-active.png";
import WhatsappDeactive from "./../../assets/Images/whatsapp-deactive.png";
import CancelImg from "./../../assets/Images/cancel.png";
import StoreIcon from "./../../assets/Images/store.png";
import { NotificationManager } from "react-notifications";
import StoreMyTicketStatus from "./StoreMyTicketStatus";
import moment from "moment";
import Modal from "react-responsive-modal";
import ReactTable from "react-table";
import * as translationHI from "./../../translations/hindi";
import { handleMaskingNumber } from "../../helpers/maskingNumber";
import * as translationMA from "./../../translations/marathi";
import LoginService from "../../Services/Login/LoginService";
import MyTicketService from "../../Services/MyTickets/MytTicketService";
import { Select } from "antd";
import { data } from "jquery";
import Table from "react-bootstrap/Table";
import { BsPlusSquare } from 'react-icons/bs';
import { BsDashSquare } from 'react-icons/bs';

const { Option } = Select;

class storeMyTicket extends Component {
  constructor(props) {
    super(props);

    this.state = {
      Notesdetails: [],
      NoteAddComment: "",
      AddNoteValidation: "",
      ticket_Id: 0,
      NotesCount: 0,
      ticketDetailsData: {},
      CategoryData: [],
      SubCategoryData: [],
      IssueTypeData: [],
      TicketStatusData: StoreMyTicketStatus(),
      historicalTicket: false,
      historicalDetails: [],
      loading: false,
      translateLanguage: {},
      customerID: 0,
      chatID: 0,
      storeID: "",
      customerName: "",
      customerMobileNumber: "",
      isIconDisplay: false,
      isChatAllreadyActive: false,
      chatEndDateTime: "",
      isWhatsAppIconClick: false,
      reInitiateChatDateTime: "",
      isMobileView: false,
      isNumberMasking: false,
      maskingPrefix: 0,
      maskingSufix: 0,
      tenantMobileMasking: false,
      viewDetails: false,
      abandonProducts: [],
    };
    this.MyTicketService = new MyTicketService();
    this.LoginService = new LoginService();
  }
  componentDidMount() {
    if (this.props.location.ticketDetailID) {
      var ticketId = this.props.location.ticketDetailID;
      this.handleCRMRole();
      this.setState({ ticket_Id: ticketId });
      this.handleGetNoteCommentData(ticketId);
      this.handleGetTicketDetails(ticketId);
      this.handleGetCategoryList();
    } else {
      this.props.history.push("myTicketList");
    }

    if (window.localStorage.getItem("translateLanguage") === "hindi") {
      this.state.translateLanguage = translationHI;
    } else if (window.localStorage.getItem("translateLanguage") === "marathi") {
      this.state.translateLanguage = translationMA;
    } else {
      this.state.translateLanguage = {};
    }
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
  }
  resize() {
    if (window.innerWidth <= 1000) {
      this.setState({ isMobileView: window.innerWidth <= 1023 });
    } else {
      this.setState({ isMobileView: false });
    }
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.resize.bind(this));
  }
  /// ---------------------API call start---------------------------------

  //handle crm role data
  handleCRMRole() {

    this.LoginService.Post("/StoreCRMRole/GetStoreRolesByUserID")
      .then((response) => {
        let msg = response.message;
        if (msg === "Success") {
          this.setState({
            isNumberMasking: response.responseData.isNumberMasking,
            maskingPrefix: response.responseData.maskingPrefix,
            maskingSufix: response.responseData.maskingSufix,
            tenantMobileMasking:
              response.responseData.tenantLevelMasking,
            isSuggestionText: response.responseData.suggestionText,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  /// handle Get Category Data for drop-down
  handleGetCategoryList() {
    let self = this;
    this.MyTicketService.Post("/HSChatTicketing/GetChatCategory")
      .then((response) => {
        let Msg = response.message;
        let data = response.responseData;
        if (Msg === "Success") {
          self.setState({
            CategoryData: data,
          });
        } else {
          self.setState({
            CategoryData: [],
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  /// handle Get Sub Category Data for drop-down
  handleGetSubCategoryList() {
    let self = this;
    let inputParams = {
      categoryID: this.state.ticketDetailsData.categoryID,
    };
    this.MyTicketService.PostWithParams(
      "/HSChatTicketing/GetChatSubCategoryByCategoryID",
      inputParams
    )
      .then((response) => {
        let Msg = response.message;
        let data = response.responseData;
        if (Msg === "Success") {
          self.setState({
            SubCategoryData: data,
          });
        } else {
          self.setState({
            SubCategoryData: [],
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  /// handle Get Issue Type list for drop-down
  handleGetIssueTypeList() {
    let self = this;
    let inputParams = {
      subCategoryID: this.state.ticketDetailsData.subCategoryID,
    };
    this.MyTicketService.PostWithParams(
      "/HSChatTicketing/GetChatIssueTypeBySubcategory",
      inputParams
    )
      .then((response) => {
        let Msg = response.message;
        let data = response.responseData;
        if (Msg === "Success") {
          self.setState({
            IssueTypeData: data,
          });
        } else {
          self.setState({
            IssueTypeData: [],
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  //To handle the copied button icon
  handleCopiedNumber = (e, contact_number, copiedTextChatId) => {
    let numberValue = encryption(contact_number, "enc");
    window.localStorage.setItem("copiedNumber", numberValue);
    this.setState({
      isNumberCopiedClicked: true,
      isTextCopied: true,
      copiedTextChatId: copiedTextChatId,
    });
    this.state.isNumberCopiedClicked = true;
    setTimeout(() => {
      this.setState({
        isNumberCopiedClicked: false,
        isTextCopied: false,
      });
    }, 3000);
  };

  ///handle Get ticket details data
  handleGetTicketDetails(TID) {
    document.addEventListener("contextmenu", (event) => event.preventDefault());
    var self = this;
    this.setState({ loading: true });
    let inputParams = {
      ticketID: TID,
    };
    this.MyTicketService.PostWithParams(
      "/HSChatTicketing/GetChatTicketsByID",
      inputParams
    )
      .then((response) => {
        let Msg = response.message;
        let data = response.responseData;
        if (Msg === "Success") {
          self.setState({
            ticketDetailsData: data,
            loading: false,
            isIconDisplay: data.isIconDisplay,
            isChatAllreadyActive: data.isChatAllreadyActive,
            chatEndDateTime: data.chatEndDateTime,
            chatID: data.chatID,
            customerMobileNumber: data.customerMobileNumber,
            storeID: data.assignTo,
            customerID: data.customerID,
            reInitiateChatDateTime: data.reInitiateChatDateTime,
            abandonProducts: data.abandonProducts,
          });

          setTimeout(() => {
            self.handleGetSubCategoryList();
            self.handleGetIssueTypeList();
          }, 100);
        } else {
          self.setState({
            ticketDetailsData: {},
            loading: false,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  /// Handle Add Note comment
  handleNoteAddComments() {
    const TranslationContext = this.state.translateLanguage.default;
    let self = this;
    if (this.state.NoteAddComment.length > 0) {
      let inputParams = {
        ticketID: this.state.ticket_Id,
        comment: this.state.NoteAddComment,
      };
      this.MyTicketService.PostWithParams(
        "/HSChatTicketing/AddChatTicketNotes",
        inputParams
      )
        .then((response) => {
          let Msg = response.message;
          if (Msg === "Success") {
            var TID = self.state.ticket_Id;
            self.handleGetNoteCommentData(TID);
            self.setState({
              NoteAddComment: "",
              AddNoteValidation: "",
            });
            NotificationManager.success(
              TranslationContext !== undefined
                ? TranslationContext.alertmessage.commentaddedsuccessfully
                : "Comment added successfully."
            );
          } else {
            NotificationManager.error(
              TranslationContext !== undefined
                ? TranslationContext.alertmessage.commentnotadded
                : "Comment not added."
            );
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      this.setState({
        AddNoteValidation: "Please Enter Comment.",
      });
    }
  }
  /// handle Get Note comment data
  handleGetNoteCommentData(TID) {
    var self = this;
    let inputParams = {
      ticketID: TID,
    };
    this.MyTicketService.PostWithParams(
      "/HSChatTicketing/GetChatTicketNotes",
      inputParams
    )
      .then((response) => {
        let Msg = response.message;
        let data = response.responseData;
        if (Msg === "Success") {
          self.setState({
            Notesdetails: data,
            NotesCount: data.length,
          });
        } else {
          self.setState({
            Notesdetails: [],
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  /// handle Update ticket function
  handleUpdateTicketDetails() {
    const TranslationContext = this.state.translateLanguage.default;
    var self = this;
    let inputParams = {
      ticketID: this.state.ticketDetailsData.ticketID,
      statusID: this.state.ticketDetailsData.ticketStatus,
      assignTo: 0,
    };
    this.MyTicketService.PostWithParams(
      "/HSChatTicketing/UpdateChatTicketStatus",
      inputParams
    )
      .then((response) => {
        let Msg = response.message;
        if (Msg === "Success") {
          NotificationManager.success(
            TranslationContext !== undefined
              ? TranslationContext.alertmessage.ticketupdatedsuccessfully
              : "Ticket Updated successfully."
          );
          self.handleGetTicketDetails(self.state.ticketDetailsData.ticketID);
        } else {
          NotificationManager.error(
            TranslationContext !== undefined
              ? TranslationContext.alertmessage.ticketnotupdated
              : "Ticket Not Updated."
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  /// handle get Ticket History function
  handleGetHistoricalData() {
    let self = this;
    let inputParams = {
      ticketID: this.state.ticketDetailsData.ticketID,
    };
    this.MyTicketService.PostWithParams(
      "/HSChatTicketing/GetChatTicketHistory",
      inputParams
    )
      .then((response) => {
        let status = response.status;
        let data = response.responseData;
        if (status === true) {
          self.setState({ historicalDetails: data, historicalTicket: true });
        } else {
          self.setState({ historicalDetails: [], historicalTicket: true });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  /// ---------------------API call End-----------------------------------
  handleViewDetails = () => {
    this.setState({
      viewDetails: !this.state.viewDetails
    })
  }
  handleNoteOnChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  HistoricalModalClose() {
    this.setState({
      historicalTicket: false,
    });
  }
  handleDropDownChangeStatus = (e) => {
    var data = this.state.ticketDetailsData;
    // if (name === "ticketStatus") {
    data["ticketStatus"] = e;
    console.log(data, "data");
    this.setState({
      ticketDetailsData: data,
    });
  }
  /// drop-down value change
  handleDropDownChange = (e) => {
    console.log(e);
    let name = e.target.name;
    let Value = e.target.value;
    var data = this.state.ticketDetailsData;
    if (name === "ticketStatus") {
      data[name] = Value;
      this.setState({
        ticketDetailsData: data,
      });
    } else if (name === "categoryID") {
      data[name] = Value;
      this.setState({
        ticketDetailsData: data,
        SubCategoryData: [],
        IssueTypeData: [],
      });
      setTimeout(() => {
        this.handleGetSubCategoryList();
      }, 100);
    } else if (name === "subCategoryID") {
      data[name] = Value;
      this.setState({
        ticketDetailsData: data,
        IssueTypeData: [],
      });
      setTimeout(() => {
        this.handleGetIssueTypeList();
      }, 100);
    } else if (name === "issueTypeID") {
      data[name] = Value;
      this.setState({
        ticketDetailsData: data,
      });
    }
  };
  ////handle whats app icon click
  handleWhatsAppIconClick = () => {
    let self = this;
    let number = encryption(this.state.customerMobileNumber, "enc")
    window.localStorage.setItem("customerNum", number);

    if (this.state.isWhatsAppIconClick === false) {
      this.setState({ isWhatsAppIconClick: true });
      let inputData = {
        StoreID: this.state.storeID,
        CustomerID: this.state.customerID,
        FirstName: this.state.ticketDetailsData.customerName,
        LastName: "",
        MobileNo: this.state.customerMobileNumber,
        ChatTicketID: this.state.ticket_Id,
      };
      this.MyTicketService.PostWithData(
        "/CustomerChat/saveReInitiateChat",
        inputData
      )
        .then((response) => {
          var message = response.message;
          var responseData = response.responseData;
          if (message === "Success" && responseData) {
            self.setState({
              isChatAllreadyActive: true,
              isWhatsAppIconClick: false,
            });
            if (document.getElementById("chatwindow")) {
              // document.getElementById("newTicketChatId").value = responseData;
              window.localStorage.setItem("newTicketChatId", responseData);
              document.getElementById("chatwindow").click();
            }
            self.handleGetTicketDetails(self.state.ticket_Id);
          } else {
            self.setState({ isWhatsAppIconClick: false });
          }
        })
        .catch((error) => {
          self.setState({ isWhatsAppIconClick: false });
          console.log(error);
        });
    } else {
      return false;
    }
  };
  render() {
    const TranslationContext = this.state.translateLanguage.default;
    return (
      <Fragment>
        {this.state.loading === true ? (
          <div className="loader-icon"></div>
        ) : (
          <div className="stoticket">
            {this.state.isMobileView ? null : (
              <div className="head-header">
                <div className="head-header-1">
                  <div className="row">
                    <div className="col-12 col-xs-4 col-sm-4 col-md-3">
                      <img
                        src={HeadphoneImg}
                        alt="headphone"
                        className="headphone"
                      />
                      <label className="id-abc-1234">
                        {TranslationContext !== undefined
                          ? TranslationContext.label.id
                          : "ID"}
                        - {this.state.ticketDetailsData.ticketID}
                        <span className="updated-2-d-ago">
                          {this.state.ticketDetailsData.createdDate}
                        </span>
                      </label>
                      <div
                        className="loading-rectangle-cntr"
                        onClick={this.handleGetHistoricalData.bind(this)}
                      >
                        <img
                          src={LoadingImg}
                          alt="Loading"
                          className="loading-rectangle m-0"
                          title="Ticket History"
                        />
                      </div>
                    </div>
                    <div className="historical-model">
                      <Modal
                        open={this.state.historicalTicket}
                        onClose={this.HistoricalModalClose.bind(this)}
                        closeIconId="sdsg"
                        modalId="Historical-popup"
                        overlayId="logout-ovrly"
                        classNames={{ modal: "historical-popup" }}
                      >
                        <label className="lblHistorical">
                          {TranslationContext !== undefined
                            ? TranslationContext.label.tickethistorical
                            : "Ticket History"}
                        </label>
                        <img
                          src={CancelImg}
                          alt="cancelImg"
                          className="cancalImg"
                          onClick={this.HistoricalModalClose.bind(this)}
                        />
                        <div className="tic-history tic-his varunoverflow">
                          <ReactTable
                            data={this.state.historicalDetails}
                            columns={[
                              {
                                Header: (
                                  <span>
                                    {TranslationContext !== undefined
                                      ? TranslationContext.span.name
                                      : "Name"}
                                  </span>
                                ),
                                accessor: "name",
                                width: 150,
                              },
                              {
                                Header: (
                                  <span>
                                    {TranslationContext !== undefined
                                      ? TranslationContext.span.actions
                                      : "Action"}
                                  </span>
                                ),
                                accessor: "action",
                              },
                              {
                                Header: (
                                  <span>
                                    {TranslationContext !== undefined
                                      ? TranslationContext.span.timeanddate
                                      : "Time & Date"}
                                  </span>
                                ),
                                accessor: "dateandTime",
                                width: 200,
                                Cell: (row) => {
                                  var date = row.original["dateandTime"];
                                  return (
                                    <span>
                                      {moment(date).format("M/D/YYYY")} &nbsp;
                                      {moment(date).format("HH:mm A")}
                                    </span>
                                  );
                                },
                              },
                            ]}
                            resizable={false}
                            defaultPageSize={10}
                            showPagination={false}
                            minRows={2}
                          />
                        </div>
                      </Modal>
                    </div>
                    <div className="col-12 col-xs-8 col-sm-8 col-md-9 submitbtnnone">
                      <div style={{ float: "right", marginTop: "0px" }}>
                        <img
                          src={Headphone2Img}
                          alt="headphone"
                          className="oval-55"
                          title="Agent List"
                        />
                        <label className="naman-r">
                          {this.state.ticketDetailsData.assignTo}
                        </label>
                        <img
                          src={DownImg}
                          alt="down"
                          className="down-header"
                          style={{ display: "none" }}
                        />

                        <button
                          type="button"
                          className="myticket-submit-solve-button"
                          onClick={this.handleUpdateTicketDetails.bind(this)}
                        >
                          {TranslationContext !== undefined
                            ? TranslationContext.button.submit
                            : "SUBMIT"}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="card-rectangle">
              <div className="rectangle-box">
                <div className="row marzero">
                  <div
                    className={` ${this.state.isMobileView ? "col-md-12" : "col-md-4"
                      }`}
                    style={{ padding: "0" }}
                  >
                    <div
                      className="padmobil"
                      style={{ padding: "15px 0px 15px 30px" }}
                    >
                      <div className="row marzero">
                        <div
                          className={` col-6 ${this.state.isMobileView ? "col-md-12" : "col-md-5"
                            } `}
                        >
                          <label className="mobile-number">
                            {TranslationContext !== undefined
                              ? TranslationContext.label.customername
                              : "Customer Name"}
                          </label>

                          <br />
                          <label className="mobile-no">
                            {this.state.ticketDetailsData.customerName}
                          </label>
                        </div>
                        <div
                          className={`col-6  ${this.state.isMobileView ? "col-md-12" : "col-md-7"
                            }`}
                        >
                          <div className="row marzero">
                            <div
                              className={`col-12  ${this.state.isMobileView
                                ? "col-md-12"
                                : "col-md-3"
                                } alignright`}
                              style={{ paddingRight: 0 }}
                            >
                              <label className="mobile-number">
                                {TranslationContext !== undefined
                                  ? TranslationContext.label.customername
                                  : "Chat ID"}
                              </label>

                              <br />
                              <label className="mobile-no">
                                {this.state.chatID}
                              </label>
                            </div>
                            <div
                              className={`col-6  ${this.state.isMobileView
                                ? "col-md-12"
                                : "col-md-9"
                                } whicnone`}
                              style={{ padding: 0 }}
                            >
                              {this.state.isIconDisplay &&
                                !this.state.isChatAllreadyActive ? (
                                <img
                                  style={{
                                    width: "30px",
                                    cursor: "pointer",
                                  }}
                                  title="Re-Initiate Chat"
                                  src={WhatsappActive}
                                  alt="WhatsappActive"
                                  onClick={this.handleWhatsAppIconClick.bind(
                                    this
                                  )}
                                />
                              ) : this.state.isIconDisplay === true &&
                                this.state.isChatAllreadyActive ? (
                                <img
                                  style={{
                                    width: "30px",
                                    cursor: "pointer",
                                  }}
                                  title={
                                    "Re-initiate chat on " +
                                    this.state.reInitiateChatDateTime
                                  }
                                  src={WhatsappDeactive}
                                  alt="WhatsappDeactive"
                                />
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row marzero break">
                        <div
                          className={`col-6   ${this.state.isMobileView ? "col-md-12" : "col-md-5"
                            }`}
                        >
                          <div className="m-t-15">
                            <label className="mobile-number mobile-numbermobnone">
                              {TranslationContext !== undefined
                                ? TranslationContext.label.mobilenumber
                                : "Mobile Number"}
                            </label>
                            <label className="mobile-number mobile-numbermob">
                              {TranslationContext !== undefined
                                ? TranslationContext.label.mobilenumber
                                : "Mobile No."}
                            </label>
                            <br />
                            <div className="text_copied_div">
                              <label className="mobile-no">
                                {this.state.isNumberMasking === true &&
                                  this.state.tenantMobileMasking === true ? (
                                  <p className="num">
                                    {handleMaskingNumber(
                                      this.state.ticketDetailsData
                                        .customerMobileNumber,
                                      this.state.maskingPrefix,
                                      this.state.maskingSufix
                                    )}
                                  </p>
                                ) : (
                                  <p className="num stop_copying">
                                    {
                                      this.state.ticketDetailsData
                                        .customerMobileNumber
                                    }
                                  </p>
                                )}
                              </label>
                              {this.state.isNumberMasking === true &&
                                this.state.tenantMobileMasking === true ? (
                                <p>
                                  <img
                                    className="imgsz"
                                    src={copy}
                                    onClick={(e) =>
                                      this.handleCopiedNumber(
                                        e,
                                        this.state.ticketDetailsData
                                          .customerMobileNumber,
                                        this.state.chatID
                                      )
                                    }
                                  />
                                </p>
                              ) : null}
                              {this.state.isTextCopied &&
                                this.state.copiedTextChatId ===
                                this.state.chatID ? (
                                <label className="text_copied"> Copied </label>
                              ) : null}
                            </div>
                          </div>
                        </div>
                        <div
                          className={`col-6  ${this.state.isMobileView ? "col-md-12" : "col-md-7"
                            }`}
                        >
                          <div className="m-t-15 right">
                            <label className="mobile-number numb mobile-numbermobnone">
                              {TranslationContext !== undefined
                                ? TranslationContext.label.mobilenumber
                                : "Customer Last Message Date & Time"}
                            </label>
                            <label className="mobile-number numb mobile-numbermob">
                              {TranslationContext !== undefined
                                ? TranslationContext.label.mobilenumber
                                : "Last Message Date & Time"}
                            </label>
                            <br />
                            <label className="mobile-no">
                              {this.state.chatEndDateTime}
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="row mt-3 marzero textnone">
                        <div className="col-12">
                          <label className="ticket-title-where mb-0">
                            {TranslationContext !== undefined
                              ? TranslationContext.label.tickettitle
                              : "Ticket Title"}
                            :
                          </label>
                        </div>
                      </div>
                      <div
                        className="row marzero textnone"
                        style={{ marginTop: "0" }}
                      >
                        <div className="col-12">
                          <label className="storelabel-3 mb-0">
                            {this.state.ticketDetailsData.ticketTitle}
                          </label>
                        </div>
                      </div>
                      {this.state.isMobileView ? null : (
                        <div className="backtick textnone">
                          <div className="row mt-3 marzero">
                            <label className="ticket-title-where mb-0">
                              {TranslationContext !== undefined
                                ? TranslationContext.label.ticketdetails
                                : "Ticket Details"}
                            </label>
                          </div>
                          {this.state.ticketDetailsData?.abandonProducts !== null &&
                              <div className="row mt-3 marzero " style={{ display: "flex", flexDirection: "column", paddingTop: "10px" }}>
                                <label className="ticket-title-where mb-0" style={{ cursor: "pointer", width: "fit-content" }} onClick={this.handleViewDetails}>
                                  {TranslationContext !== undefined
                                    ? TranslationContext.label.ticketdetails
                                    : "Abandon Details"}
                                  +
                                </label>
                                {this.state.viewDetails &&
                                  <div style={{ width: "fit-content", height: "100%", maxHeight: "130px", marginTop: "10px", overflowY: "auto", maxWidth: "500px" }}>
                                    {this.state.ticketDetailsData?.abandonProducts?.map((ele, ind) => {
                                      return (
                                        <div style={{ display: "flex", marginBottom: "10px" }} key={ind}>
                                          <img style={{ height: "150px", width: "150px" }} src={ele.imageUrl} />
                                          <div style={{ paddingLeft: "10px", paddingRight: "10px" }}>
                                            <p className="label-3 pb-2" style={{ fontWeight: "600" }}>{ele.itemName}</p>
                                            <p className="label-3 pb-2" style={{ fontWeight: "600" }}>{ele.category}</p>
                                            <p className="label-3 pb-0" style={{ fontWeight: "600" }}>Rs. {ele.price}</p>
                                          </div>
                                        </div>
                                      )
                                    })}
                                  </div>
                                }
                              </div>
                          }
                          <div className="" style={{ marginTop: "5px" }}>
                            {this.state.ticketDetailsData.ticketDescription
                              ? this.state.ticketDetailsData.ticketDescription
                                .split(",")
                                .map((item) => {
                                  return (
                                    <>
                                      <p
                                        className="label-3 pb-0"
                                        style={{ marginBottom: "5px" }}
                                      >
                                        {item}
                                      </p>
                                    </>
                                  );
                                })
                              : null}
                          </div>
                        </div>
                      )}
                      <img
                        src={EyeImg}
                        alt="eye"
                        className="eyeImg1"
                        title="Customer Profile"
                        style={{ display: "none" }}
                      />

                      <img
                        src={BillInvoiceImg}
                        alt="eye"
                        className="billImg"
                        title="Historical Order"
                        style={{ display: "none" }}
                      />
                    </div>
                  </div>
                  <div
                    className={` ${this.state.isMobileView ? "col-md-12" : "col-md-8"
                      } `}
                  >
                    <div className="mid-sec mid-secnew">
                      <div className="row mob-pad">
                        <div
                          className={`col-12 col-xs-12 col-sm-6   ${this.state.isMobileView ? "col-md-12" : "col-md-6"
                            } col-lg-4`}
                        >
                          <div className="form-group">
                            <label className="label-4">
                              {TranslationContext !== undefined
                                ? TranslationContext.label.status
                                : "Status"}
                            </label>
                            <Select
                              className="rectangle-9 select-category-placeholder p-0"
                              value={this.state.ticketDetailsData.ticketStatus}
                              name="ticketStatus"
                              onChange={this.handleDropDownChangeStatus}
                            >
                              <Option value=''>
                                {TranslationContext !== undefined
                                  ? TranslationContext.option.ticketstatus
                                  : "Ticket Status"}
                              </Option>
                              {this.state.TicketStatusData !== null &&
                                this.state.TicketStatusData.map((item, s) => {
                                  return (
                                    <Option key={s} value={item.statusID}>
                                      {item.statusName}
                                    </Option>
                                  )
                                })}
                            </Select>
                          </div>
                        </div>

                        <div
                          className={`col-12 col-xs-12 col-sm-6  ${this.state.isMobileView ? "col-md-12" : "col-md-6"
                            }  col-lg-4 dropdrown`}
                        >
                          <div className="form-group disabled-link">
                            <label className="label-4">
                              {TranslationContext !== undefined
                                ? TranslationContext.label.priority
                                : "Priority"}
                            </label>
                            <select
                              className="rectangle-9 select-category-placeholder"
                              value={this.state.ticketDetailsData.priority}
                            >
                              <option
                                className="select-category-placeholder"
                                value={this.state.ticketDetailsData.priority}
                              >
                                {this.state.ticketDetailsData.priority}
                              </option>
                            </select>
                          </div>
                        </div>
                        <div
                          className={`col-12 col-xs-12 col-sm-6   ${this.state.isMobileView ? "col-md-12" : "col-md-6"
                            } col-lg-4 dropdrown`}
                        >
                          <div className="form-group disabled-link">
                            <label className="label-4">
                              {TranslationContext !== undefined
                                ? TranslationContext.label.brand
                                : "Brand"}
                            </label>
                            <select
                              className="rectangle-9 select-category-placeholder"
                              value={this.state.ticketDetailsData.brand}
                            >
                              <option
                                className="select-category-placeholder"
                                value={this.state.ticketDetailsData.brand}
                              >
                                {this.state.ticketDetailsData.brand}
                              </option>
                            </select>
                          </div>
                        </div>
                        <div
                          className={`col-12 col-xs-12 col-sm-6   ${this.state.isMobileView ? "col-md-12" : "col-md-6"
                            } col-lg-4`}
                        >
                          <div className="form-group disabled-link">
                            <label className="label-4">
                              {TranslationContext !== undefined
                                ? TranslationContext.label.category
                                : "Category"}
                            </label>
                            <select
                              className="rectangle-9 select-category-placeholder"
                              value={this.state.ticketDetailsData.categoryID}
                              name="categoryID"
                              onChange={this.handleDropDownChange}
                            >
                              <option className="select-category-placeholder">
                                {TranslationContext !== undefined
                                  ? TranslationContext.option.selectcategory
                                  : "Select Category"}
                              </option>
                              {this.state.CategoryData !== null &&
                                this.state.CategoryData.map((item, i) => (
                                  <option
                                    key={i}
                                    value={item.categoryID}
                                    className="select-category-placeholder"
                                  >
                                    {item.categoryName}
                                  </option>
                                ))}
                            </select>
                          </div>
                        </div>
                        <div
                          className={`col-12 col-xs-12 col-sm-6  ${this.state.isMobileView ? "col-md-12" : "col-md-6"
                            } col-lg-4 dropdrown`}
                        >
                          <div className="form-group disabled-link">
                            <label className="label-4">
                              {TranslationContext !== undefined
                                ? TranslationContext.label.subcategory
                                : "Sub Category"}
                            </label>
                            <select
                              className="rectangle-9 select-category-placeholder"
                              value={this.state.ticketDetailsData.subCategoryID}
                              onChange={this.handleDropDownChange}
                              name="subCategoryID"
                            >
                              <option className="select-category-placeholder">
                                {TranslationContext !== undefined
                                  ? TranslationContext.option.selectsubcategory
                                  : "Select Sub Category"}
                              </option>
                              {this.state.SubCategoryData !== null &&
                                this.state.SubCategoryData.map((item, i) => (
                                  <option
                                    key={i}
                                    value={item.subCategoryID}
                                    className="select-category-placeholder"
                                  >
                                    {item.subCategoryName}
                                  </option>
                                ))}
                            </select>
                          </div>
                        </div>
                        <div
                          className={`col-12 col-xs-12 col-sm-6   ${this.state.isMobileView ? "col-md-12" : "col-md-6"
                            }  col-lg-4 dropdrown`}
                        >
                          <div className="form-group disabled-link">
                            <label className="label-4">
                              {TranslationContext !== undefined
                                ? TranslationContext.label.issuetype
                                : "Issue Type"}
                            </label>
                            <select
                              className="rectangle-9 select-category-placeholder"
                              value={this.state.ticketDetailsData.issueTypeID}
                              onChange={this.handleDropDownChange}
                              name="issueTypeID"
                            >
                              <option className="select-sub-category-placeholder">
                                {TranslationContext !== undefined
                                  ? TranslationContext.option.selectissuetype
                                  : "Select Issue Type"}
                              </option>
                              {this.state.IssueTypeData !== null &&
                                this.state.IssueTypeData.map((item, i) => (
                                  <option
                                    key={i}
                                    value={item.issueTypeID}
                                    className="select-category-placeholder"
                                  >
                                    {item.issueTypeName}
                                  </option>
                                ))}
                            </select>
                          </div>
                        </div>
                        <div
                          className={`"col-12 col-xs-12 col-sm-6  ${this.state.isMobileView ? "col-md-12" : "col-md-6"
                            } col-lg-4"`}
                        >
                          <button
                            type="button"
                            className="myticket-submit-solve-button submitbtn"
                            onClick={this.handleUpdateTicketDetails.bind(this)}
                          >
                            {TranslationContext !== undefined
                              ? TranslationContext.button.submit
                              : "SUBMIT"}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div style={{ padding: "15px", background: "#fff" }}>
              <div className="rectangle-3 text-editor">
                <div className="row mt-2 textnone1">
                  <label className="ticket-title-where mb-0">
                    {TranslationContext !== undefined
                      ? TranslationContext.label.tickettitle
                      : "Ticket Title"}
                    :
                  </label>
                </div>
                <div className="row textnone1" style={{ marginTop: "0" }}>
                  <label className="storelabel-3 mb-0">
                    {this.state.ticketDetailsData.ticketTitle}
                  </label>
                </div>
                <div className="backtick textnone1">
                  <div className="row mt-3">
                    <label className="ticket-title-where mb-0">
                      {TranslationContext !== undefined
                        ? TranslationContext.label.ticketdetails
                        : "Ticket Details"}
                      :
                    </label>
                  </div>
                  <div className="" style={{ marginTop: "5px" }}>
                    {this.state.ticketDetailsData.ticketDescription
                      ? this.state.ticketDetailsData.ticketDescription
                        .split(",")
                        .map((item) => {
                          return (
                            <>
                              <p
                                className="label-3 pb-0"
                                style={{ marginBottom: "5px" }}
                              >
                                {item}
                              </p>
                            </>
                          );
                        })
                      : null}
                  </div>
                </div>
                {this.state.abandonProducts !== null &&
                  <div className="row mt-2 textnone1" style={{ display: "flex", flexDirection: "column", paddingTop: "10px" }}>
                    <label className="ticket-title-where mb-0" id="abd-table-heading" style={{ fontSize: "16px", cursor: "pointer", width: "fit-content" }} 
                    onClick={this.handleViewDetails}
                    >
                      {TranslationContext !== undefined
                        ? TranslationContext.label.tickettitle
                        : "Product Detail's:   "}
                      { this.state.viewDetails ? <BsDashSquare/>: <BsPlusSquare/> }
                    </label>
                    {this.state.viewDetails &&
                      <div className={this.state.isMobileView ? "abd_product_details_mobile" : "abd_product_details"} style={{ marginTop: "10px", marginLeft: "10px"}}>
                          <Table className={this.state.isMobileView ? "abd_product_table_mobile" : "abd_product_table"} >
                            <thead id="abd-table-heading">
                              <tr>
                                <th>Product Image</th>
                                <th>Product Name</th>
                                <th>Price</th>
                                <th>Original Price</th>
                                <th>Discount</th>
                                <th>Quantity</th>
                                <th>Color</th>
                                <th>Size</th>
                              </tr>
                            </thead>
                            <tbody style={{ fontSize: "14px", overflowX: "auto", overflowY: "hidden" }} >
                              {this.state.abandonProducts !== null ? (
                                this.state.abandonProducts.map((ele, ind) => (
                                  <tr key={ind}>
                                    <td>
                                      <img src={ele.imageUrl} alt="img" className={this.state.isMobileView ? 'mobile_abd_product_img' : 'abd_product_img'} />
                                    </td>
                                    <td>{ele.title}</td>
                                    <td>₹{ele.price - ele.discount_on_product}</td>
                                    <td id={ele.discount_on_product > 0 ? "product_discount" : "zero_discount"}>₹{ele.price}</td>
                                    <td>{ele.discount_on_product}</td>
                                    <td>{ele.quantity}</td>
                                    <td>{ele.color}</td>
                                    <td>{ele.size}</td>
                                  </tr>
                                ))
                              ) : null}
                            </tbody>
                          </Table>
                      </div>
                    }
                  </div>
                }
              </div>
              <div className="row">
                <div
                  className={` ${this.state.isMobileView ? "col-md-12" : "col-md-4"
                    }`}
                  style={{ top: "20px" }}
                >
                  <label className="storeTickeTtl mb-0">
                    {TranslationContext !== undefined
                      ? TranslationContext.label.notes
                      : "Notes"}
                    :
                    {this.state.NotesCount < 9
                      ? "0" + this.state.NotesCount
                      : this.state.NotesCount}
                  </label>
                </div>
              </div>
              <div className="row mobto" style={{ marginTop: "20px" }}>
                <div
                  className={`col-12   ${this.state.isMobileView && "col-md-12"
                    }  col-xs-12 col-sm-4`}
                  style={{ marginTop: "20px" }}
                >
                  <label className="addnotes">Add Notes</label>
                  <textarea
                    className="Add-Notes-textarea"
                    placeholder={
                      TranslationContext !== undefined
                        ? TranslationContext.placeholder.addnotes
                        : "Add Notes"
                    }
                    name="NoteAddComment"
                    value={this.state.NoteAddComment}
                    onChange={this.handleNoteOnChange}
                  ></textarea>
                  {this.state.NoteAddComment.length === 0 && (
                    <p
                      style={{
                        color: "red",
                        marginBottom: "0px",
                      }}
                    >
                      {this.state.AddNoteValidation}
                    </p>
                  )}
                  <button
                    type="button"
                    className="notesbtn notesbtn-text"
                    onClick={this.handleNoteAddComments.bind(this)}
                    style={{ marginTop: "5px" }}
                  >
                    {TranslationContext !== undefined
                      ? TranslationContext.button.addcomment
                      : "ADD COMMENT"}
                  </button>
                </div>

                <div
                  className="col-12 col-xs-12 col-sm-8 my-ticket-notes"
                  style={{ top: "18px" }}
                >
                  {this.state.Notesdetails !== null &&
                    this.state.Notesdetails.map((item, i) => (
                      <div className="row my-ticket-notes-row marzero" key={i}>
                        <div
                          className={`col-3  ${this.state.isMobileView ? "col-md-2" : "col-md-1"
                            } `}
                        >
                          <div className="oval-5-1-new">
                            <img
                              src={StoreIcon}
                              style={{ padding: "5px" }}
                              alt="store-icon"
                            />
                          </div>
                        </div>
                        <div
                          className={`col-9  ${this.state.isMobileView ? "col-md-10" : "col-md-11"
                            } `}
                        >
                          <div className="row my-ticket-notes-created">
                            <label className="varun-nagpal">
                              {item.name}
                              <span className="addTask-time-ago">
                                {item.commentDate}
                              </span>
                            </label>
                          </div>
                          <div className="row my-ticket-notes-created">
                            <label className="hi-diwakar-i-really tab">
                              {item.comment}
                            </label>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        )}
      </Fragment>
    );
  }
}

export default storeMyTicket;
