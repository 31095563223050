import APICaller from "../../APICaller/APICaller";

class CRMMasterService {
  constructor() {
    this.APICaller = new APICaller();
  }
  async Post(url) {
    return await this.APICaller.Post(url);
  }
}

export default CRMMasterService;
 