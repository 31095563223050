import React, { Component } from "react";
import axios from "axios";
import {
  Table,
  Popover,
  Popconfirm,
  Collapse,
  Spin,
  Checkbox,
  Empty,
  Select,
} from "antd";
import Deliver from "./../../../assets/Images/icons8-delivery.svg";
import OrderHamb from "./../../../assets/Images/order-hamb.png";
import Info from "./../../../assets/Images/Info-black.png";
import SyncIcon from "./../../../assets/Images/SyncIcon.png";
import { authHeader } from "../../../helpers/authHeader";
import config from "../../../helpers/config";
import Pagination from "react-pagination-js";
import "react-pagination-js/dist/styles.css";
import * as translationHI from "../../../translations/hindi";
import * as translationMA from "../../../translations/marathi";
import { NotificationManager } from "react-notifications";
import CancelImg from "./../../../assets/Images/cancel.png";
import Modal from "react-responsive-modal";
import OrderDel from "./../../../assets/Images/order-del.png";
import OrderInfo from "./../../../assets/Images/order-info.png";
import plus from "./../../../assets/Images/plus.png";
import minus from "./../../../assets/Images/minus.png";
import copy from "./../../../assets/Images/copy.png";
import { encryption } from "./../../../helpers/encryption";
import LoginService from "../../../Services/Login/LoginService";
import { handleMaskingNumber } from "../../../helpers/maskingNumber";

const { Panel } = Collapse;
const { Option } = Select;

class ShoppingListTab extends Component {
  constructor(props) {
    super(props);

    this.state = {
      shoppingBagGridData: [],
      filterShoppingStatus: false,
      orderPopoverOverlay: false,
      filterShoppingDeliveryType: false,
      totalCount: 0,
      currentPage: 1,
      postsPerPage: 10,
      statusFilterData: [],
      strStatus: "",
      ShopBagLoading: false,
      DeliveryStatusFilter: [],
      deliveryStrStatus: "",
      translateLanguage: {},
      ShopCancelComment: "",
      invoiceNo: "",
      amountNo: "",
      orderSearchText: "",
      activePanel: [],
      isMobileView: false,
      ShareCustomerData: false,
      ShopItemData: [],
      OrdStoreCode: "",
      ordItemPopupClose: false,
      MobCancelCommentMdl: false,
      OrdShopId: "",
      popUpClicking: false,
      ItemShareCustomer: false,
      itemId: 0,
      clicks: 0,
      show: true,
      checkboxSelectedValue: "",
      selectedCheckbox: "",
      hideSearchBox: true,
      shoppingListSearchData: [],
      shoppingListSearchText: null,
      showSearchOption: false,
      loading: false,
      saveSearchData: [],
      actualData: [],
      addSearchData: false,
      selectedSearchValue: undefined,
      searchLoading: false,
      selectedSearchSKUValue: undefined,
      selectedRowKeys: [],
      showSelectSearchItem: false,
      isNumberMasking: false,
      maskingPrefix: 0,
      maskingSufix: 0,
      tenantMobileMasking: false,
      PopUpItemList:[],
    };
    this.LoginService = new LoginService();
  }

  handleGetShoppingListSearch = async (event) => {
    var searchedText = "";
    await this.setState({
      shoppingListSearchText: event,
    });
    searchedText = this.state.shoppingListSearchText;
    if (searchedText.length == 0) {
      this.setState({
        shoppingListSearchData: [],
      });
      return;
    }
    this.setState({
      searchLoading: true,
    });
    let self = this;
    axios({
      method: "post",
      url:
        config.apiUrl +
        "/HSOrder/ShoppingListProductSearch" +
        "?SearchText=" +
        searchedText +
        "&ProgramCode=erreportingdemo&StoreCode=EZ001",
      headers: authHeader(),
      data: {
        FilterDelivery: "",
        FilterStatus: "",
        PageNo: 1,
        PageSize: 10,
        SearchText: this.state.shoppingListSearchText,
      },
    })
      .then(function (res) {
        let status = res.data.message;
        let data = res.data.responseData;
        self.setState({
          searchLoading: false,
        });
        if (status === "Success") {
          self.setState({
            shoppingListSearchData: data.items,
            showSearchOption: true,
          });
        }
      })
      .catch((data) => {
        console.log(data);
        self.setState({
          searchLoading: false,
          shoppingListSearchData: [],
          showSearchOption: false,
        });
      });
  };
  handleAddSearchedText = (item, e) => {
    var data = this.state.saveSearchData;
    if (data.length < 1) {
      this.setState({
        showSelectSearchItem: true,
      });
    } else {
      this.setState({
        showSelectSearchItem: false,
      });
      if (
        this.state.shoppingBagGridData.filter(
          (x) => x.shoppingID === item.shoppingID
        )[0] &&
        data
      ) {
        if (
          this.state.shoppingBagGridData.filter(
            (x) => x.shoppingID === item.shoppingID
          )[0].itemList
        ) {
          if (this.state.selectedRowKeys.length > 0) {
            let objData = this.state.PopUpItemList[this.state.selectedRowKeys[0]];
            objData["searchItemName"] = data[0].name || "";
            objData["searchItemID"] = data[0].itemCode || "";
            objData["searchItemPrice"] = data[0].price || "";
            // objData["searchQuantity"] = 1 || "";

            this.state.PopUpItemList[this.state.selectedRowKeys[0]] = objData;
          } else {
            let objDataArray = this.state.shoppingBagGridData.filter(
              (x) => x.shoppingID === item.shoppingID
            )[0].itemList;
            let objData = {};
            objData["searchItemName"] = data[0].name || "";
            objData["searchItemID"] = data[0].itemCode || "";
            objData["searchItemPrice"] = data[0].price || "";
            // objData["searchQuantity"] = 1 || "";
            objData["itemID"] = "";
            objData["itemName"] = "";
            objData["itemPrice"] = "";
            objData["quantity"] = "";

            objDataArray.push(objData);
            this.state.shoppingBagGridData.filter(
              (x) => x.shoppingID === item.shoppingID
            )[0].itemList = objDataArray;
          }
          this.setState({
            selectedRowKeys: [],
          });
        }
      }
    }
    this.setState({
      shoppingBagGridData: this.state.shoppingBagGridData,
    });
    this.setState({
      shoppingBagGridData: this.state.shoppingBagGridData,
      saveSearchData: [],
      shoppingListSearchText: "",
      selectedSearchValue: undefined,
      selectedSearchSKUValue: undefined,
      shoppingListSearchData: [],
    });
  };

  handleSearchedItem = (data) => {
    this.state.saveSearchData.push(
      this.state.shoppingListSearchData.filter(
        (x) => x.itemCode === data.key
      )[0]
    );
    this.setState(
      {
        // saveSearchData: data,
        selectedSearchValue: data,
        showSearchOption: false,
      },
      () => {
        console.log("saveSearchData", this.state.saveSearchData);
      }
    );
  };

  handleClickLabel = async (event) => {
    console.log(event.currentTarget.textContent, "text");
    await this.setState({
      checkboxSelectedValue: event.currentTarget.textContent,
    });
    console.log(this.state.checkboxSelectedValue, "checkboxSelectedValue");
  };
  IncrementItem = (item, data, e) => {
    // this.state.shoppingBagGridData
    //   .filter((x) => x.shoppingID === item.shoppingID)[0]
    //   .itemList.filter((y) => y.itemID === data.itemID)[0].searchQuantity += 1;
    this.state.PopUpItemList.filter((x)=>x.itemID === data.itemID)[0].searchQuantity +=1;
    this.setState({
      shoppingBagGridData: this.state.shoppingBagGridData,
      PopUpItemList : this.state.PopUpItemList
    });
  };
  DecreaseItem = (item, data, e) => {
    if (
      // this.state.shoppingBagGridData
      //   .filter((x) => x.shoppingID === item.shoppingID)[0]
      //   .itemList.filter((y) => y.itemID === data.itemID)[0].searchQuantity ===
      // 0
      this.state.PopUpItemList.filter((x)=>x.itemID === data.itemID)[0].searchQuantity === 0
    ) {
      // this.state.shoppingBagGridData
      //   .filter((x) => x.shoppingID === item.shoppingID)[0]
      //   .itemList.filter((y) => y.itemID === data.itemID)[0].searchQuantity = 0;
      this.state.PopUpItemList.filter((x)=>x.itemID === data.itemID)[0].searchQuantity = 0;
    } else {
      // this.state.shoppingBagGridData
      //   .filter((x) => x.shoppingID === item.shoppingID)[0]
      //   .itemList.filter(
      //     (y) => y.itemID === data.itemID
      //   )[0].searchQuantity -= 1;
      this.state.PopUpItemList.filter((x)=>x.itemID === data.itemID)[0].searchQuantity -= 1;
    }

    this.setState({
      shoppingBagGridData: this.state.shoppingBagGridData,
      PopUpItemList : this.state.PopUpItemList
    });
  };
  // ToggleClick = () => {
  //   this.setState({ show: !this.state.show });
  // }
  componentDidMount() {
    this.handleCRMRole();
    this.handleGetShoppingListGridData();
    this.handleGetShoppingBagStatusFilterData();
    this.handleGetShoppingBagDeliveryStatus();
    // this.setState({
    //   isNumberMasking:
    //     window.localStorage.getItem("isNumberMasking") == "true" ? true : false,
    //   maskingPrefix: window.localStorage.getItem("maskingPrefix"),
    //   maskingSufix: window.localStorage.getItem("maskingSufix"),
    //   tenantMobileMasking:
    //     window.localStorage.getItem("tenantMobileMasking") === "true"
    //       ? true
    //       : false,
    // });
    if (window.localStorage.getItem("translateLanguage") === "hindi") {
      this.state.translateLanguage = translationHI;
    } else if (window.localStorage.getItem("translateLanguage") === "marathi") {
      this.state.translateLanguage = translationMA;
    } else {
      this.state.translateLanguage = {};
    }
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
  }
  resize() {
    if (window.innerWidth <= 1000) {
      this.setState({ isMobileView: true });
    } else {
      this.setState({ isMobileView: false });
    }
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.resize.bind(this));
    clearTimeout(this.intervalID);
  }
  /// search data
  handleShoppingListSearch = (searchData) => {
    if(this.state.orderSearchText !== undefined)
    this.setState({
      orderSearchText: searchData,
      currentPage: 1,
    });
    setTimeout(() => {
      this.handleGetShoppingListGridData();
    }, 5);
  };
  ////   -------------------API Function start-------------------------------

  //handle crm role data
  handleCRMRole() {
    
    this.LoginService.Post("/StoreCRMRole/GetStoreRolesByUserID")
      .then((response) => {
        let msg = response.message;
        if (msg === "Success") {
          this.setState({
            isNumberMasking: response.responseData.isNumberMasking,
            maskingPrefix: response.responseData.maskingPrefix,
            maskingSufix: response.responseData.maskingSufix,
            tenantMobileMasking:
              response.responseData.tenantLevelMasking,
            isSuggestionText: response.responseData.suggestionText,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  /// handle Get Order Tab Grid Data
  handleGetShoppingListGridData(filter) {
    let self = this;

    this.setState({
      ShopBagLoading: true,
    });
    this.intervalID = setTimeout(
      this.handleGetShoppingListGridData.bind(this),
      config.pageRefreshTiming
    );
    axios({
      method: "post",
      url: config.apiUrl + "/HSOrder/GetShoppingListDetails",
      headers: authHeader(),
      data: {
        SearchText: this.state.orderSearchText,
        PageNo: this.state.currentPage,
        PageSize: this.state.postsPerPage,
        FilterStatus: this.state.strStatus,
        FilterDelivery: this.state.deliveryStrStatus,
      },
    })
      .then(function (res) {
        let status = res.data.message;
        let data = res.data.responseData;
        let popupData = [];
        let dataEntry = res.data.responseData.listEntry;
        if(dataEntry !== undefined && null)
        dataEntry.forEach((element) => {
          popupData.push(element);
        });
        if (filter === "filter") {
          if (status === "Success") {
            self.setState({
              shoppingBagGridData: data.listEntry,
              totalCount: data.totalCount,
              filterOrderStatus: false,
              filterShoppingDeliveryType: false,
              ShopBagLoading: false,
              popupData: popupData.itemList,
            });
          } else {
            self.setState({
              shoppingBagGridData: [],
              totalCount: 0,
              filterOrderStatus: false,
              filterShoppingDeliveryType: false,
              ShopBagLoading: false,
            });
          }
        } else {
          if (status === "Success") {
            self.setState({
              shoppingBagGridData: data.listEntry,
              totalCount: data.totalCount,
              ShopBagLoading: false,
            });
          } else {
            self.setState({
              shoppingBagGridData: [],
              totalCount: 0,
              ShopBagLoading: false,
            });
          }
        }
      })
      .catch((data) => {
        console.log(data);
      });
  }

  //To handle the copied button icon
  handleCopiedNumber = (contact_number, item) => {
    // let muskedNumber = handleMaskingNumber(
    //   contact_number,
    //   this.state.maskingPrefix,
    //   this.state.maskingSufix
    // );
    let numberValue = encryption(contact_number, "enc");
    window.localStorage.setItem("copiedNumber", numberValue);
    this.setState({
      isNumberCopiedClicked: true,
      isTextCopied: true,
      recentCopiedWBsNo: item.shoppingID,
    });
    this.state.isNumberCopiedClicked = true;
    setTimeout(() => {
      this.setState({
        isNumberCopiedClicked: false,
        isTextCopied: false,
      });
    }, 3000);

    return numberValue;
  };

  /// handle Get Shopping bag status filter
  handleGetShoppingBagStatusFilterData() {
    let self = this;
    axios({
      method: "post",
      url: config.apiUrl + "/HSOrder/GetOrderStatusFilter",
      headers: authHeader(),
      params: {
        pageID: 8,
      },
    })
      .then(function (res) {
        let status = res.data.message;
        let data = res.data.responseData;
        if (status === "Success") {
          self.setState({
            statusFilterData: data,
          });
        } else {
          self.setState({
            statusFilterData: [],
          });
        }
      })
      .catch((data) => {
        console.log(data);
      });
  }
  /// handle Get Shopping bag delivery status filter
  handleGetShoppingBagDeliveryStatus() {
    let self = this;
    axios({
      method: "post",
      url: config.apiUrl + "/HSOrder/GetShoppingBagDeliveryType",
      headers: authHeader(),
      params: {
        pageID: 1,
      },
    })
      .then(function (res) {
        let status = res.data.message;
        let data = res.data.responseData;
        if (status === "Success") {
          self.setState({
            DeliveryStatusFilter: data,
          });
        } else {
          self.setState({
            DeliveryStatusFilter: [],
          });
        }
      })
      .catch((data) => {
        console.log(data);
      });
  }

  /// handle cancel and comment for Shopping bag
  handleCancleAndCommnetShopBag(item) {
    const TranslationContext = this.state.translateLanguage.default;
    let self = this;
    if (this.state.ShopCancelComment !== "") {
      axios({
        method: "post",
        url: config.apiUrl + "/HSOrder/UpdateShoppingListCancelData",
        headers: authHeader(),
        params: {
          ShoppingID: item.shoppingID,
          CancelComment: this.state.ShopCancelComment,
          SharewithCustomer: this.state.ShareCustomerData,
          CustomerName: item.customerName,
          CustomerMob: item.mobileNumber,
        },
      })
        .then(function (res) {
          let status = res.data.message;
          console.log(res.data, "UpdateShoppingListCancelData");
          if (status === "Success") {
            self.setState({
              ShopCancelComment: "",
              ShareCustomerData: false,
              MobCancelCommentMdl: false,
            });
            NotificationManager.success(
              TranslationContext !== undefined
                ? TranslationContext.alertmessage.success
                : "Success."
            );
            self.handleGetShoppingListGridData();
          } else {
            NotificationManager.error(
              TranslationContext !== undefined
                ? TranslationContext.alertmessage.failed
                : "Failed."
            );
          }
        })
        .catch((data) => {
          console.log(data);
        });
    } else {
      NotificationManager.error(
        TranslationContext !== undefined
          ? TranslationContext.alertmessage.pleaseentercomment
          : "Please Enter Comment."
      );
    }
  }
  /// convert to Order
  handleConvertToOrder(ShopId, e) {
    const TranslationContext = this.state.translateLanguage.default;
    e.stopPropagation();
    let self = this;
    if (this.state.invoiceNo !== "" && this.state.amountNo !== "") {
      axios({
        method: "post",
        url: config.apiUrl + "/HSOrder/InsertOrderDetails",
        headers: authHeader(),
        data: {
          ShoppingID: ShopId,
          InvoiceNo: this.state.invoiceNo,
          Amount: this.state.amountNo,
        },
      })
        .then(function (res) {
          let status = res.data.message;
          console.log(res.data, "InsertOrderDetails");
          if (status === "Success") {
            self.setState({
              invoiceNo: "",
              amountNo: "",
            });
            NotificationManager.success(
              TranslationContext !== undefined
                ? TranslationContext.alertmessage.success
                : "Success."
            );
            self.handleGetShoppingListGridData();
          } else {
            NotificationManager.error(status);
          }
        })
        .catch((data) => {
          console.log(data);
        });
    } else {
      if (this.state.invoiceNo === "") {
        NotificationManager.error(
          TranslationContext !== undefined
            ? TranslationContext.alertmessage.pleaseenterorderid
            : "Please Enter Order Id."
        );
      } else {
        NotificationManager.error(
          TranslationContext !== undefined
            ? TranslationContext.alertmessage.pleaseenteramount
            : "Please Enter Amount."
        );
      }
    }
  }
  /// handle push to poss data
  handlePushToPossData(ShopId) {
    const TranslationContext = this.state.translateLanguage.default;
    var self = this;
    axios({
      method: "post",
      url: config.apiUrl + "/HSOrder/PushOrderToPoss",
      headers: authHeader(),
      data: {
        ShoppingID: ShopId,
      },
    })
      .then(function (res) {
        let status = res.data.message;
        if (status === "Success" && res.data.responseData.result === "true") {
          NotificationManager.success(
            TranslationContext !== undefined
              ? TranslationContext.alertmessage.orderconfirmed
              : "Order Confirmed"
          );
          self.handleGetShoppingListGridData();
        } else {
          NotificationManager.error(
            TranslationContext !== undefined
              ? TranslationContext.alertmessage.orderfailed
              : "Order Failed"
          );
        }
      })
      .catch((data) => {
        console.log(data);
      });
  }
  /// handle push to poss data
  handleRefreshPossData(ShopId) {
    const TranslationContext = this.state.translateLanguage.default;
    var self = this;
    this.setState({
      ShopBagLoading: true,
    });
    axios({
      method: "post",
      url: config.apiUrl + "/HSOrder/GetOrderStatus",
      headers: authHeader(),
      params: {
        OrderID: ShopId,
      },
    })
      .then(function (res) {
        let status = res.data.message;
        if (status === "Success") {
          NotificationManager.success(
            TranslationContext !== undefined
              ? TranslationContext.alertmessage.success
              : "Success"
          );
          self.handleGetShoppingListGridData();
          self.setState({
            ShopBagLoading: false,
          });
        } else {
          NotificationManager.error(status);
          self.setState({
            ShopBagLoading: false,
          });
        }
      })
      .catch((data) => {
        this.setState({
          ShopBagLoading: false,
        });
        console.log(data);
      });
  }
  ///-------------------API function end--------------------------------

  ///handle pagination onchage
  PaginationOnChange = async (numPage) => {
    await this.setState({
      currentPage: numPage,
    });

    this.handleGetShoppingListGridData();
  };
  /// handle per page item change
  handlePageItemchange = async (e) => {
    await this.setState({
      postsPerPage: e,
      currentPage: 1,
    });

    this.handleGetShoppingListGridData();
  };
  /// handle check individual status
  handleCheckShopBagIndividualStatus() {
    var checkboxes = document.getElementsByName("ShopBagStatus");
    var strStatus = "";
    for (var i in checkboxes) {
      if (isNaN(i) === false) {
        if (checkboxes[i].checked === true) {
          if (checkboxes[i].getAttribute("attrIds") !== null)
            strStatus += checkboxes[i].getAttribute("attrIds") + ",";
        }
      }
    }
    this.setState({
      strStatus,
    });
  }
  /// handle check individual Deliverystatus
  handleCheckShopBagDeliveryStatus() {
    var checkboxes = document.getElementsByName("shopBagDeliveryStatus");
    var deliveryStrStatus = "";
    for (var i in checkboxes) {
      if (isNaN(i) === false) {
        if (checkboxes[i].checked === true) {
          if (checkboxes[i].getAttribute("attrIds") !== null)
            deliveryStrStatus += checkboxes[i].getAttribute("attrIds") + ",";
        }
      }
    }
    this.setState({
      deliveryStrStatus,
    });
  }
  /// close status filter
  handleCloseStatusFilter() {
    this.setState({
      filterShoppingStatus: false,
    });
  }
  /// close delivery filter
  handleCloseDeliveryFilter() {
    this.setState({
      filterShoppingDeliveryType: false,
    });
  }
  ///handle text onchange
  handleTextOnchage = (e) => {
    var name = e.target.name;
    if (name === "amountNo") {
      var reg = /^[0-9\b]+$/;
      if (e.target.value === "" || reg.test(e.target.value)) {
        this.setState({
          [e.target.name]: e.target.value,
        });
      } else {
        e.target.value = "";
      }
    } else {
      this.setState({
        [e.target.name]: e.target.value,
      });
    }
  };
  //// clear order data
  handleClearOrderData() {
    this.setState({
      invoiceNo: "",
      amountNo: "",
    });
  }
  handlecollapseChange = (e) => {
    this.state.activePanel = e[e.length - 1];
    this.setState({ activePanel: this.state.activePanel });
  };
  /// push to poss notification
  handlePushToPosNotification(e) {
    NotificationManager.success("Order has been Pushed to Shopping Bag");
  }
  handlePushToShoppingBag(itemID, id) {
    var self = this;
    axios({
      method: "post",
      url: config.apiUrl + "/HSOrder/ConvertToShoppingBag?SLOrderID=" + itemID,
      headers: authHeader(),
    }).then(function (res) {
      var status = res.data.message;
      var updatedRow = res.data.responseData;
      console.log(res.data);
      if (status === "Success" && updatedRow > 0) {
        NotificationManager.success("Order has been Pushed to Shopping Bag");
        self.handleGetShoppingListGridData();
      } else {
        NotificationManager.error("Error On Updaing Shopping List.");
      }
    });
  }
  /// handle check box check
  handleCheckBox(e) {
    this.setState({
      ShareCustomerData: e.target.checked,
    });
  }
  handleStoreOrderItemData = async (OrderData) => {
    await this.setState({
      ShopItemData: [],
      OrdStoreCode: OrderData.storeCode,
      ordItemPopupClose: true,
      OrdShopId: OrderData.listID,
      itemId: OrderData.id,
      selectedRowKeys: [],
      PopUpItemList: OrderData.itemList
    });
  }
  /// handle get itam data
  handleOrdItemOpen(itemId) {
    let self = this;
    self.setState({
      popUpClicking: true,
    });
    axios({
      method: "post",
      url: config.apiUrl + "/HSOrder/GetItemDetailsBySKU",
      headers: authHeader(),
      params: {
        StoreCode: this.state.OrdStoreCode,
        SKUItemCodes: itemId,
      },
    })
      .then(function (res) {
        var status = res.data.message;
        var data = res.data.responseData.data;
        if (status === "Success" && data.length > 0) {
          var OrdData = self.state.ShopItemData;
          OrdData.push(data[0]);
          self.setState({
            ShopItemData: OrdData,
            popUpClicking: false,
          });
        } else {
          NotificationManager.error("Record not found.");
          self.setState({
            popUpClicking: false,
          });
        }
      })
      .catch((data) => {
        self.setState({
          popUpClicking: false,
        });
        console.log(data);
      });
  }

  handleLoader = () => {
    if (!this.state.loading) {
      this.setState({ loading: true });
    } else {
      this.setState({ loading: false });
    }
  };
  /// handle close all item pop modal
  handleCloseAllItemPop() {
    this.setState({
      orderPopoverOverlay: false,
      ordItemPopupClose: false,
      ShopItemData: [],
      OrdShopId: "",
      popUpClicking: false,
      selectedRowKeys: [],
    });
  }
  /// handle close item pop modal
  handleCloseItemPop(itemId) {
    document.getElementById(
      "ord" + itemId + this.state.OrdShopId + "Itm"
    ).style.display = "none";
    this.setState({
      popUpClicking: false,
    });
  }
  handleMobCancelCommentOpen = () => {
    this.setState({
      MobCancelCommentMdl: true,
    });
  };
  handleMobCancelCommentClose = () => {
    this.setState({
      MobCancelCommentMdl: false,
    });
  };
  handleSelectAbldQty(items, e) {
    this.state.shoppingBagGridData
      .filter((x) => x.listID === this.state.OrdShopId)[0]
      .itemList.filter((x) => x.itemID === items.itemID)[0].selectAvailableqty =
      e.target.value;
    this.setState({
      shoppingBagGridData: this.state.shoppingBagGridData,
    });
  }
  handleUpdateShoppingListQty = (item) => {
    let self = this;
    let shoppingListAvalQuantity =  this.state.PopUpItemList;//item.itemList;
    let tempshoppingListAvalQuantity = [];
    if (shoppingListAvalQuantity) {
      shoppingListAvalQuantity.forEach((element) => {
        let tempObj = {};
        tempObj["S_ItemID"] = element.itemID || "";
        tempObj["S_ItemName"] = element.itemName || "";
        tempObj["S_ItemPrice"] = element.itemPrice || "";
        tempObj["S_Quantity"] = element.quantity || "";
        tempObj["SKU"] = element.searchItemID || "";
        tempObj["ItemName"] = element.searchItemName || "";
        tempObj["ItemPrice"] = element.searchItemPrice || "";
        tempObj["Quantity"] = element.searchQuantity || "";
        tempshoppingListAvalQuantity.push(tempObj);
      });
    }

    let inputData = {
      ShoppingID: item.shoppingID,
      MobileNumber: item.mobileNumber,
      ListID: item.listID,
      ShoppingListAvalQuantity: tempshoppingListAvalQuantity,
    };
    this.setState({
      loading: true,
    });
    axios({
      method: "post",
      url: config.apiUrl + "/HSOrder/UpdateShoppingListQty",
      headers: authHeader(),
      data: inputData,
    })
      .then((response) => {
        let message = response.data.message;
        self.setState({
          loading: false,
        });
        if (message === "Success") {
          self.handleCloseAllItemPop();
          self.handleGetShoppingListGridData();
          NotificationManager.success("Item Update Successfully.");
        } else {
          NotificationManager.error("Item Not Update .");
        }
      })
      .catch((erro) => {
        self.setState({
          loading: false,
        });
        console.log(erro);
      });
  };
  handleSearchedSKUItem = () => { };
  handleGetShoppingListSKUSearch = () => { };
  onSelectChange = (selectedRowKeys) => {
    if (selectedRowKeys.length > 1 || this.state.isItemAdd) {
      return false;
    } else {
      let tempselectedRowKeys = selectedRowKeys;
      if (selectedRowKeys.length !== 0) {
        tempselectedRowKeys =
          tempselectedRowKeys[tempselectedRowKeys.length - 1];
        selectedRowKeys = [];
        selectedRowKeys.push(tempselectedRowKeys);
      }
      this.setState({ selectedRowKeys });
    }
  };
  handleItemPopoverHide = (item, e) => {
    console.log(item);
    this.setState({
      selectedRowKeys: [],
    });
  };
  render() {
    const TranslationContext = this.state.translateLanguage.default;

    const { selectedRowKeys } = this.state;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };
    return (
      <>
        {/* <h1>CODE</h1> */}
        {this.state.orderPopoverOverlay && (
          <div className="order-popover-overlay"></div>
        )}
        <div className="table-cntr store dv-table-paging shopbag">
          <p className="shopi">
            {TranslationContext !== undefined
              ? TranslationContext.a.shoppingbag
              : "Shopping Bag"}
          </p>
          {!this.state.isMobileView ? (
            <Table
              className="components-table-demo-nested antd-table-campaign antd-table-order antd-table-order-mobile custom-antd-table"
              columns={[
                {
                  title:
                    TranslationContext !== undefined
                      ? TranslationContext.title.listID
                      : "List Id",
                  dataIndex: "listID",
                  key: "listID",
                  render: (row, item) => {
                    return (
                      <div className="namenumbermain">
                        <label>{item.listID}</label>
                        <div className="namenumber">
                          <p>{item.customerName},</p>
                        </div>
                      </div>
                    );
                  },
                },
                {
                  title:
                    TranslationContext !== undefined
                      ? TranslationContext.title.date
                      : "Date",
                  render: (row, item) => {
                    return (
                      <div>
                        <p>{item.date}</p>
                        {/* <p className="order-small-font">{item.time}</p> */}
                      </div>
                    );
                  },
                  className: "order-desktop",
                },
                {
                  title:
                    TranslationContext !== undefined
                      ? TranslationContext.title.customer
                      : "Customer Name",
                  render: (row, item) => {
                    return (
                      <div className="text_copied_div">
                        {this.state.isNumberMasking &&
                          this.state.tenantMobileMasking ? (
                          <p className="num">
                            {handleMaskingNumber(
                              item.customerName,
                              this.state.maskingPrefix,
                              this.state.maskingSufix
                            )}
                          </p>
                        ) : (
                          <p className="num stop_copying">
                            {item.customerName}
                          </p>
                        )}
                        {this.state.isNumberMasking &&
                          this.state.tenantMobileMasking ? (
                          <p>
                            <img
                              className="imgsz"
                              src={copy}
                              onClick={(e) =>
                                this.handleCopiedNumber(item.customerName, item)
                              }
                            // onClick={() => {navigator.clipboard.writeText(item.customerName)}}
                            />
                          </p>
                        ) : null}
                        {this.state.isTextCopied &&
                          this.state.recentCopiedWBsNo == item.shoppingID ? (
                          <label className="text_copied"> Copied </label>
                        ) : null}
                      </div>
                    );
                  },
                  className: "order-desktop",
                },

                //title
                {
                  title:
                    TranslationContext !== undefined
                      ? TranslationContext.title.items
                      : "Items",
                  render: (row, item) => {
                    return (
                      <div className="d-flex align-items-center">
                        <p>{item.itemList.length}</p>
                        <Popover
                          content={
                            <>
                              {this.state.hideSearchBox ? (
                                <div className="row pd-10">
                                  <div className="col-md-9">
                                    <Select
                                      style={{ width: "100%" }}
                                      labelInValue
                                      filterOption={false}
                                      notFoundContent={
                                        this.state.searchLoading ? (
                                          <Spin size="small" />
                                        ) : null
                                      }
                                      showSearch
                                      defaultActiveFirstOption={false}
                                      placeholder={
                                        "Search By Item Name OR Item ID"
                                      }
                                      onSearch={
                                        this.handleGetShoppingListSearch
                                      }
                                      onChange={this.handleSearchedItem}
                                      value={this.state.selectedSearchValue}
                                    >
                                      {this.state.shoppingListSearchData
                                        ? this.state.shoppingListSearchData.map(
                                          (item, i) => {
                                            return (
                                              <Option value={item.itemCode}>
                                                {item.name}
                                              </Option>
                                            );
                                          }
                                        )
                                        : null}
                                    </Select>
                                  </div>
                                  <div className="col-md-3">
                                    <button
                                      className="shopping_list_add"
                                      onClick={this.handleAddSearchedText.bind(
                                        this,
                                        item
                                      )}
                                    >
                                      Add
                                    </button>
                                  </div>

                                  {this.state.showSelectSearchItem ? (
                                    <div>
                                      <label className="shopping_list_lbl_error">
                                        {" "}
                                        *Please select SkuID or Item Name{" "}
                                      </label>
                                    </div>
                                  ) : null}
                                </div>
                              ) : null}

                              <Table
                                rowSelection={rowSelection}
                                className="components-table-demo-nested antd-table-campaign antd-table-order custom-antd-table shopping_list_popupTable"
                                columns={[
                                  {
                                    title:
                                      TranslationContext !== undefined
                                        ? TranslationContext.title.itemid
                                        : "Item ID",
                                    dataIndex: "itemID",
                                  },
                                  {
                                    title:
                                      TranslationContext !== undefined
                                        ? TranslationContext.title.itemname
                                        : "Item Name",
                                    dataIndex: "itemName",
                                  },
                                  {
                                    title: "M-Item ID",
                                    dataIndex: "searchItemID",
                                  },
                                  {
                                    title: "M-Item Name",
                                    dataIndex: "searchItemName",
                                  },
                                  {
                                    title: "M-Item Price",
                                    dataIndex: "searchItemPrice",
                                  },
                                  {
                                    title:
                                      TranslationContext !== undefined
                                        ? TranslationContext.ticketingDashboard
                                          .availableqty
                                        : "Quantity",
                                    dataIndex: "searchQuantity",
                                    render: (row, data) => {
                                      return data.searchQuantity >= 0 ? (
                                        <div className="inc_dec_container">
                                          <button
                                            disabled={
                                              data.searchQuantity === 0
                                                ? true
                                                : false
                                            }
                                            className="inc_dec_btn"
                                            type="button"
                                            onClick={this.DecreaseItem.bind(
                                              this,
                                              item,
                                              data
                                            )}
                                          >
                                            <img src={minus}></img>
                                          </button>
                                          {this.state.show ? (
                                            <div>{data.searchQuantity}</div>
                                          ) : (
                                            ""
                                          )}
                                          <button
                                            className="inc_dec_btn"
                                            type="button"
                                            onClick={this.IncrementItem.bind(
                                              this,
                                              item,
                                              data
                                            )}
                                          >
                                            <img src={plus}></img>
                                          </button>
                                        </div>
                                      ) : null;
                                    },
                                  },
                                ]}
                                scroll={{ y: 240 }}
                                pagination={false}
                                dataSource={this.state.PopUpItemList}
                                // dataSource={item.itemList}
                              />
                              <div className="popShpBagDiv">
                                <Spin spinning={this.state.loading}>
                                  <button
                                    className="shopping_list_add"
                                    style={{ marginBottom: "8px" }}
                                    onClick={this.handleUpdateShoppingListQty.bind(
                                      this,
                                      item
                                    )}
                                  >
                                    {TranslationContext !== undefined
                                      ? TranslationContext.label.update
                                      : "Update"}
                                  </button>
                                </Spin>
                              </div>
                            </>
                          }
                          trigger="click"
                          placement="bottom"
                          overlayClassName={
                            this.state.ordItemPopupClose
                              ? "order-popover-table order-popover shopping-bag-popover-item order-popover-table-bigXL"
                              : "order-popover-table order-popover shopping-bag-popover-item order-popover-table-bigXL OrdItem"
                          }
                          onVisibleChange={(visible) =>
                            this.setState({ orderPopoverOverlay: visible })
                          }
                        >
                          <img
                            src={OrderHamb}
                            className="order-hamb"
                            onClick={this.handleStoreOrderItemData.bind(
                              this,
                              item
                            )}
                          />
                        </Popover>
                      </div>
                    );
                  },
                  className: "order-desktop",
                },

                {
                  title:
                    TranslationContext !== undefined
                      ? TranslationContext.title.status
                      : "Status",
                  className:
                    "camp-status-header camp-status-header-statusFilter order-status-header order-desktop",
                  render: (row, item) => {
                    return (
                      <div className="d-flex align-items-center">
                        <p
                          className={
                            item.statusName === "Cancelled"
                              ? "order-clr-pink"
                              : ""
                          }
                        >
                          {item.statusName}
                        </p>
                        {item.statusName === "Cancelled" ? (
                          <Popover
                            content={
                              <div className="order-tab-popover">
                                <div className="d-flex align-items-center justify-content-between">
                                  <p>{item.canceledOn}</p>
                                  <p className="username-mar">
                                    {item.userName}
                                  </p>
                                </div>
                                <p className="shopping-popover-cancel-info">
                                  {item.canceledComment}
                                </p>
                              </div>
                            }
                            trigger="click"
                            overlayClassName="order-popover shopping-popover-cancel"
                            onVisibleChange={(visible) =>
                              this.setState({
                                orderPopoverOverlay: visible,
                              })
                            }
                          >
                            <img src={OrderInfo} className="order-info" />
                          </Popover>
                        ) : (
                          ""
                        )}
                      </div>
                    );
                  },
                  filterDropdown: (data, row) => {
                    return (
                      <div className="campaign-status-drpdwn">
                        <ul>
                          {this.state.statusFilterData !== null &&
                            this.state.statusFilterData.map((item, b) => (
                              <li key={b}>
                                <input
                                  type="checkbox"
                                  id={"New" + item.statusID}
                                  className="ch1"
                                  onChange={this.handleCheckShopBagIndividualStatus.bind(
                                    this
                                  )}
                                  name="ShopBagStatus"
                                  attrIds={item.statusID}
                                />
                                <label htmlFor={"New" + item.statusID}>
                                  <span className="ch1-text">
                                    {item.statusName}
                                  </span>
                                </label>
                              </li>
                            ))}
                        </ul>
                        <div className="dv-status">
                          <button
                            className="btn-apply-status"
                            onClick={this.handleGetShoppingListGridData.bind(
                              this,
                              "filter"
                            )}
                          >
                            {TranslationContext !== undefined
                              ? TranslationContext.button.apply
                              : "Apply"}
                          </button>
                          <button
                            className="btn-cancel-status"
                            onClick={this.handleCloseStatusFilter.bind(this)}
                          >
                            {TranslationContext !== undefined
                              ? TranslationContext.button.cancel
                              : "Cancel"}
                          </button>
                        </div>
                      </div>
                    );
                  },
                  filterDropdownVisible: this.state.filterShoppingStatus,
                  onFilterDropdownVisibleChange: (visible) =>
                    this.setState({ filterShoppingStatus: visible }),
                  filterIcon: (filtered) => (
                    <span
                      style={{ color: filtered ? "#1890ff" : undefined }}
                    ></span>
                  ),
                },
                {
                  title:
                    TranslationContext !== undefined
                      ? TranslationContext.title.deliverytype
                      : "Cart type",
                  className:
                    "camp-status-header camp-status-header-statusFilter order-status-header shopping-delivery-header order-desktop",
                  render: (row, item) => {
                    return <p>{item.cartType}</p>;
                  },
                },
                {
                  title:
                    TranslationContext !== undefined
                      ? TranslationContext.title.actions
                      : "Action",
                  className: "btn-dgn",
                  render: (row, item) => {
                    return (
                      <div className="d-flex convert">
                        {item.statusName === "Cancelled" ? null : (
                          <>
                            <button
                              disabled={!item.isSBConvertVisible}
                              className={
                                item.isSBConvertVisible == true
                                  ? "butn order-grid-butn order-grid-butn-green add_to_shopping_bag"
                                  : "butn order-grid-butn order-grid-butn-yellow order-grid-btn-disable"
                              }
                              onClick={this.handlePushToShoppingBag.bind(
                                this,
                                item.shoppingID
                              )}
                            >
                              {TranslationContext !== undefined
                                ? TranslationContext.ticketingDashboard
                                  .pushtoposs
                                : "Convert to Shopping Bag"}
                            </button>
                            <Popconfirm
                              title={
                                <>
                                  <div className="popover-input-cntr">
                                    <div>
                                      <p>
                                        {TranslationContext !== undefined
                                          ? TranslationContext.p.comment
                                          : "Comment"}
                                      </p>
                                      <textarea
                                        placeholder={
                                          TranslationContext !== undefined
                                            ? TranslationContext.placeholder
                                              .entercomment
                                            : "Enter Comment"
                                        }
                                        value={this.state.ShopCancelComment}
                                        name="ShopCancelComment"
                                        onChange={this.handleTextOnchage}
                                      ></textarea>
                                    </div>
                                  </div>
                                </>
                              }
                              overlayClassName="order-popover order-popover-butns shopping-popover-delete"
                              placement="bottomRight"
                              onVisibleChange={(visible) =>
                                this.setState({
                                  orderPopoverOverlay: visible,
                                })
                              }
                              icon={false}
                              okText="Cancel Bag"
                              cancelText="Close"
                              onConfirm={this.handleCancleAndCommnetShopBag.bind(
                                this,
                                item
                              )}
                            >
                              {item.statusName === "Mapped" ||
                                item.statusName === "Rejected" ||
                                item.statusName === "Accepted" || item.statusName === "Customer Rejected" ? (
                                <button className="butn order-grid-btn order-delete-btn">
                                  <img
                                    height="22px"
                                    src={OrderDel}
                                    alt="delete icon"
                                  />
                                </button>
                              ) : null}
                            </Popconfirm>
                          </>
                        )}
                      </div>
                    );
                  },
                },
              ]}
              pagination={false}
              showSizeChanger={false}
              onShowSizeChange={false}
              dataSource={this.state.shoppingBagGridData}
              loading={this.state.ShopBagLoading}
            />
          ) : (
            <>
              {this.state.isMobileView ? (
                <>
                  <Pagination
                    currentPage={this.state.currentPage}
                    totalSize={this.state.totalCount}
                    sizePerPage={this.state.postsPerPage}
                    changeCurrentPage={this.PaginationOnChange}
                    theme="bootstrap"
                  />
                  <div className="position-relative">
                    <div className="item-selection Camp-pagination">
                      <Select
                        value={this.state.postsPerPage}
                        onChange={this.handlePageItemchange}
                      >
                        <Option value={10}>10</Option>
                        <Option value={20}>20</Option>
                        <Option value={30}>30</Option>
                      </Select>
                      <p>
                        {TranslationContext !== undefined
                          ? TranslationContext.p.itemsperpage
                          : "Items per page"}
                      </p>
                    </div>
                  </div>
                </>
              ) : null}
              <Spin spinning={this.state.ShopBagLoading}>
                <Collapse
                  bordered={false}
                  className="site-collapse-custom-collapse shbag"
                  expandIconPosition={"right"}
                  destroyInactivePanel={true}
                  onChange={this.handlecollapseChange.bind(this)}
                  activeKey={this.state.activePanel}
                >
                  {this.state.shoppingBagGridData.length > 0
                    ? this.state.shoppingBagGridData.map((item, key) => {
                      return (
                        <Panel
                          header={
                            <div className="shopbagne">
                              <div className="namenumbermain">
                                <label>{item.listID}</label>
                                <div className="namenumber">
                                  {this.state.isNumberMasking &&
                                    this.state.tenantMobileMasking ? (
                                    <p className="num">
                                      {handleMaskingNumber(
                                        item.customerName,
                                        this.state.maskingPrefix,
                                        this.state.maskingSufix
                                      )}
                                    </p>
                                  ) : (
                                    <p className="num stop_copying">
                                      {item.customerName}
                                    </p>
                                  )}
                                </div>
                              </div>
                              <div className="itemviewmobile">
                                <div className="d-flex align-items-center">
                                  <Popover
                                    content={
                                      <div>
                                        <table className="custom__table">
                                          <thead className="custom__table_tr">
                                            <tr>
                                              <th></th>
                                              <th>List Entry</th>
                                              <th>Item ID</th>
                                              <th>Item Name</th>
                                              <th>Price</th>
                                              <th>Quantity</th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            <tr>
                                              <td colspan="6">
                                                <div className="search_main_container">
                                                  <div className="form-group has-search search_container pr-3">
                                                    <span className="fa fa-search form-control-feedback"></span>
                                                    {/* <img src={OrderSearch} alt="search" className="shopping_list_search"/> */}
                                                    <input
                                                      type="text"
                                                      className="form-control"
                                                      placeholder="Search SKU ID"
                                                    />
                                                  </div>
                                                  <div className="form-group has-search search_container">
                                                    {/* <img src={OrderSearch} alt="search" className="shopping_list_search_product"/> */}
                                                    <span className="fa fa-search form-control-feedback_product"></span>
                                                    <input
                                                      type="text"
                                                      className="form-control"
                                                      placeholder="Search Product"
                                                    />
                                                  </div>
                                                </div>
                                                <div className="d-flex justify-content-end">
                                                  <button className="shopping_list_add">
                                                    Add
                                                  </button>
                                                </div>
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>
                                                <div className="checkbox_container_div">
                                                  <label className="checkbox_container">
                                                    <input
                                                      type="checkbox"
                                                      id="checked_terms_condition"
                                                    />
                                                    <span className="checkbox_checkmark"></span>
                                                  </label>
                                                  <label
                                                    className="terms_conditions"
                                                    id="checkbox_label"
                                                  >
                                                    X1surf
                                                  </label>
                                                </div>
                                              </td>
                                              <td>String</td>
                                              <td>1234</td>
                                              <td>Surf</td>
                                              <td>₹120</td>
                                              <td>
                                                <div className="inc_dec_container">
                                                  <button
                                                    className="inc_dec_btn"
                                                    type="button"
                                                    onClick={
                                                      this.IncrementItem
                                                    }
                                                  >
                                                    +
                                                  </button>
                                                  {this.state.show ? (
                                                    <div className="inc_dec_number">
                                                      {this.state.clicks}
                                                    </div>
                                                  ) : (
                                                    ""
                                                  )}
                                                  <button
                                                    className="inc_dec_btn"
                                                    type="button"
                                                    onClick={
                                                      this.DecreaseItem
                                                    }
                                                  >
                                                    -
                                                  </button>
                                                </div>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </div>
                                    }
                                    trigger="click"
                                    placement="bottom"
                                    overlayClassName="order-popover-table order-popover shopping-bag-popover-item"
                                  >
                                    <img
                                      src={OrderHamb}
                                      className="order-hamb iteminfo"
                                    />
                                    <img src={Info} className="item-img" />
                                  </Popover>
                                  <p>{item.itemList.length}</p>
                                </div>
                              </div>
                            </div>
                          }
                        >
                          <div className="row">
                            <div className="col-8 p-0">
                              <span className="date">
                                {item.date} {item.time}
                              </span>
                            </div>
                            <div className="col-4 p-0 text-right">
                              <span className="stname">
                                {item.statusName}
                              </span>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-12 p-0">
                              <span className="delivery">
                                <img
                                  src={Deliver}
                                  alt="Delivery"
                                  className="delivery"
                                  width="20px"
                                />
                                {item.deliveryTypeName}
                              </span>
                            </div>
                          </div>
                          <div className="row mb-2">
                            <div className="col-6 p-0">
                              <span className="addres">
                                <p>{item.address}</p>
                              </span>
                            </div>
                            <div className="col-6 p-0 text-right">
                              <label className="date linehei">
                                Pickup
                                <span style={{ display: "block" }}>
                                  {item.pickupDate} {item.pickupTime}
                                </span>
                              </label>
                            </div>
                          </div>
                          {item.isPushToPoss ? (
                            <>
                              {item.isPosPushed ? (
                                <>
                                  <button
                                    className="butn order-grid-butn order-grid-butn-green"
                                    onClick={this.handlePushToPosNotification.bind(
                                      this
                                    )}
                                  >
                                    {TranslationContext !== undefined
                                      ? TranslationContext.ticketingDashboard
                                        .ispushtoposs
                                      : "Convert to Shopping Bag1"}
                                  </button>
                                </>
                              ) : (
                                <>
                                  <div className="ordShopCanl">
                                    <button
                                      className="butn order-grid-butn order-grid-butn-yellow"
                                      onClick={this.handlePushToPossData.bind(
                                        this,
                                        item.shoppingID
                                      )}
                                    >
                                      {TranslationContext !== undefined
                                        ? TranslationContext
                                          .ticketingDashboard.ispushtoposs
                                        : "Convert to Shopping Bag2"}
                                    </button>

                                    <Modal
                                      open={this.state.MobCancelCommentMdl}
                                      onClose={
                                        this.handleMobCancelCommentClose
                                      }
                                      center
                                      modalId="ordShopCancelModal"
                                      overlayId="Pincode-ovrly"
                                    >
                                      <div className="shopCancel">
                                        <div>
                                          <div className="ordShopDivLbl">
                                            <p>
                                              {TranslationContext !==
                                                undefined
                                                ? TranslationContext.p.comment
                                                : "Comment"}
                                            </p>
                                            <img
                                              src={CancelImg}
                                              alt="Cancel icon"
                                              className=""
                                              onClick={
                                                this
                                                  .handleMobCancelCommentClose
                                              }
                                            />
                                          </div>
                                          <textarea
                                            placeholder={
                                              TranslationContext !== undefined
                                                ? TranslationContext
                                                  .placeholder.entercomment
                                                : "Enter Comment"
                                            }
                                            value={
                                              this.state.ShopCancelComment
                                            }
                                            name="ShopCancelComment"
                                            onChange={this.handleTextOnchage}
                                          ></textarea>
                                        </div>
                                        <div>
                                          <Checkbox
                                            onChange={this.handleCheckBox.bind(
                                              this
                                            )}
                                            defaultValue={
                                              this.state.ShareCustomerData
                                            }
                                          >
                                            {TranslationContext !== undefined
                                              ? TranslationContext
                                                .ticketingDashboard
                                                .sharedetailswithcustomer
                                              : "Share Details with Customer"}
                                          </Checkbox>
                                        </div>
                                        <div className="ordShopbtnDiv">
                                          <button
                                            className="butn shopCancelBtnOrd"
                                            onClick={
                                              this.handleMobCancelCommentClose
                                            }
                                          >
                                            {TranslationContext !== undefined
                                              ? TranslationContext.button
                                                .close
                                              : "Close"}
                                          </button>
                                          <button
                                            className="butn shopRemoveBtn"
                                            onClick={this.handleCancleAndCommnetShopBag.bind(
                                              this,
                                              item
                                            )}
                                          >
                                            {TranslationContext !== undefined
                                              ? TranslationContext
                                                .ticketingDashboard
                                                .cancelbag
                                              : "Cancel Bag"}
                                          </button>
                                        </div>
                                      </div>
                                    </Modal>

                                    {/* <button
                                        className="butn order-grid-butn order-del-butn dels"
                                        onClick={
                                          this.handleMobCancelCommentOpen
                                        }
                                      >
                                        <img
                                          src={OrderDel}
                                          alt="delete iconn"
                                        />
                                      </button> */}
                                  </div>
                                </>
                              )}
                            </>
                          ) : (
                            <>
                              {item.actionTypeName !== "" ? (
                                <div className="row">
                                  <div className="col-6 p-0">
                                    <Modal
                                      open={this.state.MobCancelCommentMdl}
                                      onClose={
                                        this.handleMobCancelCommentClose
                                      }
                                      center
                                      modalId="ordShopCancelModal"
                                      overlayId="Pincode-ovrly"
                                    >
                                      <div className="shopCancel">
                                        <div>
                                          <div className="ordShopDivLbl">
                                            <p>
                                              {TranslationContext !==
                                                undefined
                                                ? TranslationContext.p.comment
                                                : "Comment"}
                                            </p>
                                            <img
                                              src={CancelImg}
                                              alt="Cancel icon"
                                              className=""
                                              onClick={
                                                this
                                                  .handleMobCancelCommentClose
                                              }
                                            />
                                          </div>

                                          <textarea
                                            placeholder={
                                              TranslationContext !== undefined
                                                ? TranslationContext
                                                  .placeholder.entercomment
                                                : "Enter Comment"
                                            }
                                            value={
                                              this.state.ShopCancelComment
                                            }
                                            name="ShopCancelComment"
                                            onChange={this.handleTextOnchage}
                                          ></textarea>
                                        </div>
                                        <div>
                                          <Checkbox
                                            onChange={this.handleCheckBox.bind(
                                              this
                                            )}
                                            defaultValue={
                                              this.state.ShareCustomerData
                                            }
                                          >
                                            {TranslationContext !== undefined
                                              ? TranslationContext
                                                .ticketingDashboard
                                                .sharedetailswithcustomer
                                              : "Share Details with Customer"}
                                          </Checkbox>
                                        </div>
                                        <div className="ordShopbtnDiv">
                                          <button
                                            className="butn shopCancelBtnOrd"
                                            onClick={
                                              this.handleMobCancelCommentClose
                                            }
                                          >
                                            {TranslationContext !== undefined
                                              ? TranslationContext.button
                                                .close
                                              : "Close"}
                                          </button>
                                          <button
                                            className="butn shopRemoveBtn"
                                            onClick={this.handleCancleAndCommnetShopBag.bind(
                                              this,
                                              item
                                            )}
                                          >
                                            {TranslationContext !== undefined
                                              ? TranslationContext
                                                .ticketingDashboard
                                                .cancelbag
                                              : "Cancel Bag"}
                                          </button>
                                        </div>
                                      </div>
                                    </Modal>
                                    {item.refreshPoss && (
                                      <button
                                        className="butn order-grid-butn order-sync-butn dels"
                                        onClick={this.handleRefreshPossData.bind(
                                          this,
                                          item.shoppingID
                                        )}
                                      >
                                        <img src={SyncIcon} alt="Sync icon" />
                                      </button>
                                    )}
                                    {/* <button
                                        className="butn order-grid-butn order-del-butn dels"
                                        onClick={
                                          this.handleMobCancelCommentOpen
                                        }
                                      >
                                        <img
                                          src={OrderDel}
                                          alt="delete iconn"
                                        />
                                      </button> */}
                                  </div>
                                  <div className="col-6 p-0">
                                    <Popconfirm
                                      title={
                                        <>
                                          <div className="popover-input-cntr">
                                            <div>
                                              <p>
                                                {TranslationContext !==
                                                  undefined
                                                  ? TranslationContext.p
                                                    .orderid
                                                  : "Order Id"}
                                              </p>
                                              <input
                                                type="text"
                                                placeholder="Enter Order Id"
                                                name="invoiceNo"
                                                value={this.state.invoiceNo}
                                                onChange={
                                                  this.handleTextOnchage
                                                }
                                                autoComplete="off"
                                              />
                                            </div>
                                            <div>
                                              <p>
                                                {TranslationContext !==
                                                  undefined
                                                  ? TranslationContext.p
                                                    .amount
                                                  : "Amount"}
                                              </p>
                                              <input
                                                type="text"
                                                placeholder={
                                                  TranslationContext !==
                                                    undefined
                                                    ? TranslationContext
                                                      .placeholder.amount
                                                    : "Enter Amount"
                                                }
                                                name="amountNo"
                                                value={this.state.amountNo}
                                                onChange={
                                                  this.handleTextOnchage
                                                }
                                                autoComplete="off"
                                              />
                                            </div>
                                          </div>
                                        </>
                                      }
                                      overlayClassName="order-popover order-popover-butns"
                                      placement="bottomRight"
                                      icon={false}
                                      okText="Done"
                                      onConfirm={this.handleConvertToOrder.bind(
                                        this,
                                        item.shoppingID
                                      )}
                                    >
                                      <button
                                        className="butn order-grid-butn convertbt"
                                        onClick={this.handleClearOrderData.bind(
                                          this
                                        )}
                                      >
                                        {TranslationContext !== undefined
                                          ? TranslationContext.button
                                            .convertoorder
                                          : "Convert to Order"}
                                      </button>
                                    </Popconfirm>
                                  </div>
                                </div>
                              ) : null}
                            </>
                          )}
                        </Panel>
                      );
                    })
                    : null}
                </Collapse>

                {this.state.shoppingBagGridData.length === 0 ? (
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                ) : null}
              </Spin>
            </>
          )}
          {!this.state.isMobileView ? (
            <>
              <Pagination
                currentPage={this.state.currentPage}
                totalSize={this.state.totalCount}
                sizePerPage={this.state.postsPerPage}
                changeCurrentPage={this.PaginationOnChange}
                theme="bootstrap"
              />
              <div className="position-relative">
                <div className="item-selection Camp-pagination">
                  <Select
                    value={this.state.postsPerPage}
                    onChange={this.handlePageItemchange}
                  >
                    <Option value={10}>10</Option>
                    <Option value={20}>20</Option>
                    <Option value={30}>30</Option>
                  </Select>
                  <p>
                    {TranslationContext !== undefined
                      ? TranslationContext.p.itemsperpage
                      : "Items per page"}
                  </p>
                </div>
              </div>
            </>
          ) : null}
        </div>
      </>
    );
  }
}

export default ShoppingListTab;
