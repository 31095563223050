import React, { Component } from "react";
import { Route } from "react-router-dom";
// import Loadable from "react-loadable";
import StoreLayout from "./../StoreManager/storelayout";
import Settings from "./../Settings/Settings";
import HierarchyMaster from "./../Settings/Store/HierarchyMaster";
import StorePriority from "./../Settings/Store/StorePriority";
import StoreCRMRole from "./../Settings/Store/StoreCRMRole";
import StoreReports from "./../Settings/Store/StoreReports";
import ItemMaster from "./../Settings/Store/ItemMaster";
import SlaTemplateDepartment from "./../Settings/Store/SlaTemplateDepartment";
import StoreUsers from "./../Settings/Store/StoreUsers";
import StoreModule from "./../Settings/Store/StoreModule";
import DepartmentMaster from "./../Settings/Store/DepartmentMaster";
import ClaimCategoryMaster from "./../Settings/Store/ClaimCategoryMaster";
import StoreAlerts from "./../Settings/Store/StoreAlerts";
import { NotificationContainer } from "react-notifications";
import StoreFileUploadLogs from "../Settings/Store/StoreFileUploadLogs";
import UserProfile from "./UserProfile";
import Appointment from "./Appointment";
import StoreCampaign from "../Campaign/StoreCampaign";
import HomeShopSetting from "./../Settings/Store/HomeShopSetting";
import ChatSettings from "./../Settings/Store/ChatSettings";
import CardAssets from "./../Settings/Store/CardAssets";
import OrderSetting from "./../Settings/Store/OrderSetting";
import storeMyTicket from "./storeMyTicket";
import storeMyTicketList from "./storeMyTicketList";
import Orders from "./Orders";
import Notification from "./MobileChatNotification";
import WebBotLink from "./WebBotLink";
import Chatbot from "./Chatbot";
import StoreChangePassword from "./../../Component/Store/ChangePassword";
import MobileChatNotification from "./MobileChatNotification";
import mobileAppSetting from "./mobileAppSetting";
import StoreMaster from "./../Settings/Store/StoreMaster";
import ApplicationInfo from "../Settings/Store/ApplicationInfo";
import VideoCallAppointment from "./VideoCallAppointment";
import Call from "./Call";
import { videoCallSocket } from "../../helpers/SocketConnection";
import { connect } from "react-redux";
import VideoCall from "./VideoCall";
import RetargetFlow from "../StoreManager/RetargetFlow";
import ChatService from "../../Services/Chats/ChatService";
import RetargetConfig from "./../Settings/Store/RetargetConfig"

class StoreApp extends Component {
  constructor(props) {
    super(props);
    this.ChatService = new ChatService();
  }
  componentDidMount() {
    console.log(this.props)
    console.log('from index.js ', videoCallSocket)
    let userData = JSON.parse(window.localStorage.getItem("UserProfile"));
    let _this = this
    document.addEventListener("visibilitychange", function () {
      console.log(document.hidden, document.visibilityState);
      if (!document.hidden) {
        _this.ChatService.GetWithServerUrl(userData?.programCode, userData?.storeCode, userData?.agentId).then((response) => {
          console.log(response);
          if (response.callStatus && _this.props.isMobileView) {
            _this.props.setIsCallRecived(true);
            _this.props.setCustomerNumber(localStorage.getItem('contactNumberUser'))
            _this.props.setVideoCallRoomName(userData.programCode + ',' + localStorage.getItem('contactNumberUser') + ',' + userData.storeCode)
          }
        })
          .catch((error) => {
            console.log(error);
          });
      }
    }, false);
    if (window.localStorage.getItem("CallAccepted")) {
      window.localStorage.setItem("CallAccepted", false);
    }
    if (userData && this.props.isVideoCallEnable) {
      let managerDetails = {
        //managerCode: userData.agentId + "",
        programCode: userData.programCode,
        storeCode: userData.storeCode
        //isBusy: localStorage.getItem("CallAccepted") ? (localStorage.getItem("CallAccepted") === true ? true : false) : false,
      }
      //videoCallSocket.emit("storeOnline", userData.programCode + userData.storeCode);
      // videoCallSocket.emit("mobileNumber", {
      //   roomID: userData.programCode + userData.storeCode + userData.agentId,
      //   sotreId: userData.programCode + userData.storeCode
      // });
      // videoCallSocket.emit("storeMangerDetail", managerDetails);

    } else {
      videoCallSocket.disconnect();
    }
  }

  render() {
    const { match } = this.props;
    return (
      <StoreLayout>
        <Route exact path={`${match.url}/myTicket`} component={storeMyTicket} />
        <Route
          exact
          path={`${match.url}/myTicketList`}
          component={storeMyTicketList}
        />
        <Route
          exact
          path={`${match.url}/hierarchyMaster`}
          component={HierarchyMaster}
        />
        <Route
          exact
          path={`${match.url}/storePriority`}
          component={StorePriority}
        />
        <Route
          exact
          path={`${match.url}/storeCRMRole`}
          component={StoreCRMRole}
        />
        <Route exact path={`${match.url}/settings`} component={Settings} />
        <Route
          exact
          path={`${match.url}/storeReports`}
          component={StoreReports}
        />
        <Route exact path={`${match.url}/itemMaster`} component={ItemMaster} />
        <Route
          exact
          path={`${match.url}/slaTemplateDepartment`}
          component={SlaTemplateDepartment}
        />
        <Route exact path={`${match.url}/storeUsers`} component={StoreUsers} />
        <Route
          exact
          path={`${match.url}/storeModule`}
          component={StoreModule}
        />
        <Route
          exact
          path={`${match.url}/departmentMaster`}
          component={DepartmentMaster}
        />
        <Route
          exact
          path={`${match.url}/claimCategoryMaster`}
          component={ClaimCategoryMaster}
        />
        <Route
          exact
          path={`${match.url}/storeAlerts`}
          component={StoreAlerts}
        />
        <Route
          exact
          path={`${match.url}/storeFileUploadLogs`}
          component={StoreFileUploadLogs}
        />
        <Route
          exact
          path={`${match.url}/userProfile`}
          component={UserProfile}
        />
        <Route
          exact
          path={`${match.url}/appointment`}
          component={Appointment}
        />
        <Route exact path={`${match.url}/campaign`} component={StoreCampaign} />
        <Route
          exact
          path={`${match.url}/homeshopsetting`}
          component={HomeShopSetting}
        />
        <Route
          exact
          path={`${match.url}/chatsettings`}
          component={ChatSettings}
        />
        <Route exact path={`${match.url}/cardassets`} component={CardAssets} />
        <Route
          exact
          path={`${match.url}/ordersetting`}
          component={OrderSetting}
        />
        <Route exact path={`${match.url}/orders`} component={Orders} />
        <Route
          exact
          path={`${match.url}/notification`}
          component={Notification}
        />
        <Route exact path={`${match.url}/webBotLink`} component={WebBotLink} />
        <Route exact path={`${match.url}/Chatbot`} component={Chatbot} />
        <Route
          exact
          path={`${match.url}/storeChangePassword`}
          component={StoreChangePassword}
        />
        <Route
          exact
          path={`${match.url}/mobileAppSetting`}
          component={mobileAppSetting}
        />
        <Route
          exact
          path={`${match.url}/storeMaster`}
          component={StoreMaster}
        />
        <Route
          exact
          path={`${match.url}/applicationinfo`}
          component={ApplicationInfo}
        />
        <Route
          exact
          path={`${match.url}/VideoCallAppointment`}
          component={VideoCallAppointment}
        />
        <Route
          exact
          path={`${match.url}/Call`}
          component={Call}
        />
        <Route
          exact
          path={`${match.url}/RetargetFlow`}
          component={RetargetFlow}
        />
        <Route
          exact
          path={`${match.url}/retargetConfig`}
          component={RetargetConfig}
        />
        {/* <Route exact path={`/video%${}`} component={VideoSolution} /> */}

        <NotificationContainer />
      </StoreLayout>
    );
  }
}
//export default StoreApp;
const mapStateToProps = (state) => {
  return {
    isVideoCallEnable: state.isVideoCallEnable,
    isMobileView: state.isMobileView,
    customerMobileNumber: state.customerMobileNumber,
    roomName: state.roomName,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setIsCallRecived: (isCallRecived) => dispatch({ type: "IS_CALL_RIECIVED", payload: isCallRecived }),
    setCustomerNumber: (customerMobileNumber) => dispatch({ type: "SET_CUSTOMER_NUMBER", payload: customerMobileNumber }),
    setVideoCallRoomName: (roomName) => dispatch({ type: "SET_ROOMNAME", payload: roomName }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StoreApp);
