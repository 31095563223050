/* eslint-disable no-loop-func */
import React, { Component, PureComponent } from "react";
import { authHeader } from "../../helpers/authHeader";
import {
  Popover,
  Collapse,
  Checkbox,
  Empty,
  Spin,
  Table,
  Select,
  notification,
  Menu,
  Dropdown,
  Tooltip,
  Radio,
} from "antd";
import { Tabs, Tab } from "react-bootstrap-tabs/dist";
import "react-pagination-js/dist/styles.css";
import * as translationHI from "../../translations/hindi";
import * as translationMA from "../../translations/marathi";
import Dropzone from "react-dropzone";
import { NotificationManager } from "react-notifications";
import "antd/dist/antd.css";
import "./../../assets/css/store-chat.css";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleNotch,
  faWindowRestore,
  faCalendar,
  faCopy,
} from "@fortawesome/free-solid-svg-icons";
import NoImage from "./../../assets/Images/No-Image.png";
import RightShape from "./../../assets/Images/RightShape.png";
import RightBlue from "./../../assets/Images/blueRight.svg";
import Shoppingbag from "./../../assets/Images/Shoppingbag.png";
import Blueplus from "./../../assets/Images/blueplus.png";
import AttachmentIcon from "./../../assets/Images/attachmentIcon.png";
import EditIcon from "./../../assets/Images/edit.png";
import CancelBlueImg from "./../../assets/Images/CancelBlue.png";
import CancelBlack from "./../../assets/Images/cancel.png";
import CircleRight from "./../../assets/Images/circle-right.png";
import SearchBlueImg from "./../../assets/Images/search-blue.png";
import searchico from "./../../assets/Images/serach-icon-left.png";
import SchRight from "./../../assets/Images/sch-right.png";
import CardTick from "./../../assets/Images/card-tick.png";
import UpBlue from "./../../assets/Images/new-Up.png";
import DownBlue from "./../../assets/Images/new-Down.png";
import DownArw from "./../../assets/Images/down.png";
import SendUp from "./../../assets/Images/send-up.png";
import Cancelico from "./../../assets/Images/cancel.png";
import Arwup from "./../../assets/Images/arwup.png";
import Pin from "./../../assets/Images/pin.png";
import Chatw from "./../../assets/Images/chatw.png";
import Pin2 from "./../../assets/Images/pin2.png";
import AddWh from "./../../assets/Images/AddWh.svg";
import Arwdown from "./../../assets/Images/arwdwn.png";
import SmartMessageWeb from "./../../assets/Images/Smart-Message-Web.svg";
import DownArrowChat from "./../../assets/Images/down-arrow-chat.svg";
import ReactHtmlParser from "react-html-parser";
import SuggSearch from "./../../assets/Images/sugg-search.png";
import Assign from "./../../assets/Images/sent-icon.svg";
import addimg from "./../../assets/Images/addimg.png";
import Modal from "react-responsive-modal";
import CancelImg from "./../../assets/Images/cancel.png";
import Blub from "./../../assets/Images/bulb.svg";
import Triangle from "./../../assets/Images/triangle.png";
import video_call from "./../../assets/Images/video-call.png";
import { socket } from "../../helpers/SocketConnection";
import ChatService from "../../Services/Chats/ChatService";
import OrderService from "../../Services/Orders/OrderService";
import SettingsService from "../../Services/Settings/SettingsService";
import HSMService from "../../Services/HSM/HSMService";
import { encryption } from "../../helpers/encryption";
import LoginService from "../../Services/Login/LoginService";
import copy from "./../../assets/Images/copy.png";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import plus from "./../../assets/Images/plus.png";
import axios from "axios";
import minus from "./../../assets/Images/minus.png";
import CardData from "./CardData";
import { connect } from "react-redux";
import paste from "./../../assets/Images/paste.png";
import { handleMaskingNumber } from "../../helpers/maskingNumber";
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';
import imageCompression from 'browser-image-compression';
import config from "../../helpers/config";
import { ImPaste } from 'react-icons/im';
import { ThirtyFpsSelect } from "@mui/icons-material";

const { Option } = Select;
const { Panel } = Collapse;

var uid = 0;
var i = 0;
class ChatNotificationComponent extends PureComponent {
  handleChatNotificationClick = () => {
    if (this.props.notitficationType === "NewChat") {
      this.props.handleChatNotificationClick(this.props.chatData);
    } else {
      this.props.handleChatNotificationClick(this.props.chatData);
    }
  };

  render() {
    return (
      <div
        className="row"
        style={{
          cursor: "pointer",
        }}
        onClick={this.handleChatNotificationClick}
      >
        <div className="col-3">
          <div className="chat-trail-img">
            <span
              className="chat-initial"
              alt="face image"
              title={
                this.props.chatData.length > 0
                  ? this.props.chatData[0].customerName
                  : null
              }
            >
              {this.props.notitficationType === "appointmentNotification"
                ? "Appointment"
                : this.props.chatData[0].customerName
                  .split(" ")
                  .map((n) => n[0])
                  .join("")
                  .toUpperCase()}
            </span>
          </div>
        </div>
        <div className="col-9">
          <h5 title="Customer Name">
            {this.props.chatData.length > 0
              ? this.props.chatData[0].customerName
              : null}
          </h5>
          <p
            style={{
              wordBreak: "break-word",
            }}
          >
            {this.props.msgData}
          </p>
        </div>
      </div>
    );
  }
}
const ProductHoverItem = (props) => {
  const TranslationContext = props.TranslationContext;
  const item = props.item;
  const cardConfigData = props.cardConfigData;
  return (
    <div className="productdesc">
      {cardConfigData.ItemName ? <h4>{item.productName}</h4> : null}
      {cardConfigData.ItemCode ? (
        <p>
          {TranslationContext !== undefined
            ? TranslationContext.p.lasttransaction
            : "Item Code "}
          - {item.uniqueItemCode}
        </p>
      ) : null}
      <table>
        <tbody>
          {item.color !== "" && cardConfigData.Color ? (
            <>
              <tr>
                <td
                  style={{
                    width: "50px",
                  }}
                >
                  <label>
                    {TranslationContext !== undefined
                      ? TranslationContext.label.colors
                      : "Colors"}
                    :
                  </label>
                </td>
                <td>
                  <ul>
                    {item.color === "Blue" ? (
                      <li>
                        <a className="colorblue">
                          <span>1</span>
                        </a>
                      </li>
                    ) : null}

                    {item.color === "Black" ? (
                      <li>
                        <a className="colorblack">
                          <span>1</span>
                        </a>
                      </li>
                    ) : null}

                    {item.color === "Grey" ? (
                      <li>
                        <a className="colorgrey">
                          <span>1</span>
                        </a>
                      </li>
                    ) : null}

                    {item.color === "Red" ? (
                      <li>
                        <a className="colorRed">
                          <span>1</span>
                        </a>
                      </li>
                    ) : null}
                    {item.color === "Yellow" ? (
                      <li>
                        <a className="colorYellow">
                          <span>1</span>
                        </a>
                      </li>
                    ) : null}
                    {item.color === "Green" ? (
                      <li>
                        <a className="colorGreen">
                          <span>1</span>
                        </a>
                      </li>
                    ) : null}
                  </ul>
                </td>
              </tr>
            </>
          ) : null}

          {item.size !== "" && cardConfigData.Size ? (
            <>
              <tr>
                <td>
                  <label>
                    {TranslationContext !== undefined
                      ? TranslationContext.label.sizes
                      : "Sizes"}
                    :
                  </label>
                </td>
                <td>
                  {isNaN(parseInt(item.size)) === false ? (
                    <ul className="sizes">
                      <li>
                        <a>{item.size}</a>
                      </li>
                    </ul>
                  ) : (
                    <ul>
                      <li>
                        <a>{item.size}</a>
                      </li>
                    </ul>
                  )}
                </td>
              </tr>
            </>
          ) : null}
        </tbody>
      </table>
      {cardConfigData.Price ? (
        <h3>
          {TranslationContext !== undefined
            ? TranslationContext.h3.inr
            : "INR "}
          {item.price}
          /-
        </h3>
      ) : null}
    </div>
  );
};

class Chatbot extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ongoingChatsData: [],
      newChatsData: [],
      searchCardData: [],
      chatId: 0,
      isDownbtn: true,
      customerName: "",
      messageData: [],
      message: "",
      chatMessageCount: 0,
      activeTab: 1,
      cardModal: false,
      searchItem: "",
      timeSlotData: [],
      selectedSlot: {},
      noOfPeople: "",
      selectedDate: "",
      noOfPeopleMax: "",
      isSelectSlot: "",
      customerId: "",
      mobileNo: "",
      messageSuggestionData: [],
      messageSuggestion: "",
      scheduleModal: false,
      recommendedModal: false,
      paymentModal: false,
      selectedCard: 0,
      chkSuggestion: 0,
      programCode: "",
      storeID: "",
      notificationAccess: "none",
      settingAccess: "none",
      storeAgentDetail: [],
      AgentID: 0,
      searchChat: "",
      isSendRecomended: false,
      chatAccess: "none",
      noProductFound: "",
      remainingCount: "",
      tempRemainingCount: "",
      noRecommendedFound: "",
      suggestionModal: false,
      suggestionText: "",
      suggestionModalMob: false,
      availableSlot: 0,
      isHistoricalChat: false,
      visible: false,
      historicalChatData: [],
      agentRecentChatData: [],
      agentData: [],
      sAgentId: 0,
      isScroll: false,
      selectedSugpage: 1,
      tempmessageSuggestionData: [],
      translateLanguage: {},
      storeCode: "",
      actionBtn: false,
      isCustEndChat: false,
      mainTabSelect: 1,
      isPastChatLoading: false,
      isHistoricalChatLoading: false,
      showHistoricalChat: false,
      chatTimeAgo: "",
      rowChatId: 0,
      storeManagerId: 0,
      tempCardSearch: "",
      reportAccess: "none",
      mobileHeading: "",
      isNumberPaste: false,
      messageHistoryChatData: [],
      isMainLoader: true,
      messageSuggestionTagsData: [],
      selectedTags: 0,
      newChatSoundVolume: 0,
      newMessageSoundVolume: 0,
      isNotiNewChat: false,
      isNotiNewMessage: false,
      newChatSoundFile: "",
      newMessageSoundFile: "",
      storePayURL: "",
      newTicketChatId: 0,
      onHoverName: false,
      isPinClick: false,
      customerTier: "",
      customerMobileNo: "",
      customerEmailID: "",
      totalPoints: 0,
      lifetimeValue: 0,
      visitCount: 0,
      billNumber: "",
      billAmount: 0,
      transactionDate: "",
      insights: [],
      orderDelivered: 0,
      orderShoppingBag: 0,
      orderReadyToShip: 0,
      orderReturns: 0,
      isMobileView: false,
      isShutterOpen: false,
      shoppingBagData: [],
      wishListData: [],
      recommendedData: [],
      cartData: [],
      ProfileProductTab: 0,
      activeCollpse: [0],
      productTypeTab: 0,
      selectedRecommended: [],
      selectedShoppingBag: [],
      selectedShoppingCart: [],
      cartItem: [],
      selectedWishList: [],
      isButtonClick: false,
      buyNowClick: false,
      colorCode: [
        "#E8E8E8",
        "#FFE8A7",
        "#DAF3C0",
        "#DDF6FC",
        "#CDE4FF",
        "#FFDEE2",
      ],
      notificationTime: 0,
      shippingAddress: "",
      shippingLandmark: "",
      shippingPinCode: "",
      shippingCity: "",
      shippingState: "",
      shippingCountry: "",
      isMessageTabActive: false,
      isCardTabActive: false,
      isRecommendedTabActive: false,
      isSchedualTabActive: false,
      isGeneratePaymentTabActive: false,
      isCustomerProfile: false,
      isCustomerProduct: false,
      isCustomerComment: false,
      selectedColor: "",
      mainProductsData: [],
      storePinCode: "",
      isAddress: "",
      isPinCode: "",
      isCity: "",
      isState: "",
      isCountry: "",
      isPinCodeCheck: "",
      maxPeopleAllow: 0,
      itemDetails: [],
      isCustTimeout: false,
      customerChatSessionValue: "",
      customerChatSessionDuration: "",
      agentChatSessionValue: "",
      agentChatSessionDuration: "",
      isEmptyArray: false,
      tmpOngoingChatsData: [],
      sourceType: "",
      pastChatCount: 0,
      pageNumberCurrentChat: 1,
      pastChatPageNo: 1,
      historyPage: 1,
      isProductLoading: false,
      isScrollMessage: false,
      isCallChatMessgaeApi: false,
      tenantID: 0,
      isProfileLoading: false,
      cardSearchStoreCode: false,
      isAddressName: "",
      isAddressMobileNo: "",
      addressMobileNo: "",
      addressName: "",
      oldScrollHeight: 0,
      alertMessageModal: false,
      alertMessageContent: "",
      storeDetails: "",
      insightsLoader: false,
      lasttransactionLoader: false,
      cardConfigData: {},
      isCardSearch: false,
      orderDetailsLoader: false,
      suggestionTagModal: false,
      ticketIds: false,
      ticketNo: "",
      AddWhite: false,
      isCardMobileUp: false,
      totalShoppingBag: 0,
      totalShoppingCart: 0,
      totalWishList: 0,
      totalRecommend: 0,
      mobileCollpseActive: [1],
      mobileShopBagProcessMdl: false,
      mobileWishProcessMdl: false,
      mobileRecommendProcessMdl: false,
      mobileShopCartProcessMdl: false,
      mobileChatActive: 0,
      uniqueItemID: 0,
      mobileprofilePic: "",
      NotificationPage: 1,
      chatNotificationModal: false,
      chatNotificationData: [],
      showSearch: false,
      chatNotificationMomCount: 0,
      userLoginMobileNo: "",
      isHSMShow: false,
      AttachementFiles: [],
      fileShowMdl: false,
      fileAttachDocWeb: "",
      isAttachmentMainLoader: false,
      isCheckSuggetion: false,
      isSelectedCard: false,
      mobileFileupload: false,
      customerNameProfile: "",
      attachmentSendCount: 0,
      UserName: "",
      chatTypeCollpase: [2],
      Profane_Words: [],
      Suggested_Words: {},
      selectedSuggested_Words: {},
      isgrammarlyCheck: false,
      grammarlyModal: false,
      replaceMessageVal: "",
      isTextCorret: false,
      storeName: "",
      storeAddress: "",
      suggestionType: "1",
      imgModerationMdl: false,
      cardAddImage: "",
      cardAddImageName: "",
      cardItemCode: "",
      cardColors: [],
      selectCardColor: 0,
      selectColorCompulsory: "",
      imageModerationLoader: false,
      isVideoCall: false,
      isVideoSearch: false,
      attachementViewModal: false,
      attachmentUrl: "",
      attachmentDocUrl: "",
      messageListLoader: false,
      isVideoCallTabActive: false,
      selectedAppointmentDate: 1,
      selectedVideocallDate: null,
      commentVideoAppointment: "",
      automaticChatSuggestion: [],
      showAutomaticTextOption: false,
      selectedCursorValue: -1,
      chatSuggestionValue: [],
      appointMentData: [],
      isNumberCopiedClicked: false,
      isTextCopied: false,
      copiedTextChatId: 0,
      isVideoCallBookedTabCloseInMobile: false,
      isNumberMasking: false,
      maskingPrefix: 0,
      maskingSufix: 0,
      tenantMobileMasking: false,
      isSuggestionText: false,
      isOpenClose: false,
      itemlist: [],
      colorIndexVal: 0,
      isSelectedCardSizeColor: false,
      addToCartModal: false,
      isAddedToCartSpin: false,
      NoteAddComment: "",
      update: false,
      isCreateTicket: false,
      activeCollapse: "0",
      CategoryData: [],
      SubCategoryData: [],
      IssueTypeData: [],
      priorityData: [],
      categoryID: 0,
      subCategoryID: 0,
      issueTypeID: 0,
      PriorityID: 0,
      TicketTitle: '',
      TicketDetails: '',
      TicketDetailsValidations: '',
      CreateTicketDetailValidation: '',
      CreateTicketTitleValidation: '',
      GeneratedTicketId: 0,
      TicketList: [],
      copiedNumber: "",
      isAddToCardEnableForCB: false,
      isSendCardImageEnableForCB: false,
      addressModal: false,
      isItmeCodeCopied: false,
      ItemCodeKey: 0,
      CopiedItemCode: "",
      TabConfigData: [],
      IsShoppingBagTab: false,
      IsWishListTab: false,
      IsRecommendedTab: false,
      IsShoppingCartTab: false,
      IsShoppingCartTabEnableForWB: false,
      isSort: false,
      isRetarget: false,
      retargetDataList: [],
      retargetSerch: [],
      ticketDetailID: 0,
      maskedNumber: ''
    };
    this.ChatService = new ChatService();
    this.OrderService = new OrderService();
    this.SettingsService = new SettingsService();
    this.HSMService = new HSMService();
    this.LoginService = new LoginService();
    this.handleSelectVideoCallApppintmentDate =
      this.handleSelectVideoCallApppintmentDate.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    var chatData = [];

    if (
      window.location.pathname.split("/").pop().toLowerCase() === "chatbot" &&
      /iPhone|iPad|iPod|Android/i.test(navigator.userAgent) &&
      nextProps.history.location.state?.isVideoCall
    ) {
      this.setState({
        programCode: nextProps.history.location.state.programCode,
        storeCode: nextProps.history.location.state.storeCode,
        UserName: nextProps.history.location.state.UserName,
        AgentID: nextProps.history.location.state.agentId,
        tenantID: nextProps.history.location.state.tenantID,
      });

      if (nextProps.history.location.state.isSearch) {
        // this.handleTabClick(2);
        this.setState({
          isVideoSearch: true,
        });
      } else {
        this.setState({
          isVideoSearch: false,
        });
      }
      if (this.state.newChatsData.length > 0) {
        chatData = this.state.newChatsData.filter(
          (x) =>
            x.mobileNo === nextProps.history.location.state.MobileNo &&
            x.sourceName === "WebBot"
        );
      }
      if (chatData.length === 0) {
        if (this.state.ongoingChatsData.length > 0) {
          chatData = this.state.ongoingChatsData.filter(
            (x) =>
              x.mobileNo === nextProps.history.location.state.MobileNo &&
              x.sourceName === "WebBot"
          );
        }
      }
      if (chatData.length > 0) {
        setTimeout(() => {
          this.handleOngoingChatClick(
            chatData[0].chatID,
            chatData[0].customerName,
            chatData[0].messageCount,
            chatData[0].mobileNo,
            chatData[0].customerID,
            chatData[0].programCode,
            chatData[0].storeID,
            chatData[0].isCustEndChat,
            chatData[0].storeManagerId,
            chatData[0].initialColor,
            chatData[0].isCustTimeout,
            chatData[0].sourceAbbr
          );
        }, 100);
      } else {
        this.handleGetOngoingChat();
      }
    } else {
      this.setState({
        isVideoSearch: false,
      });
    }
  }

  componentDidMount() {
    // console.log(socket);
    // socket.connect();
    localStorage.setItem("isOngoingClick", false);
    let agentId = 0;
    let tenantID = 0;
    let programCode = "";
    if (this.props.location.state) {
      agentId = this.props.location.state.agentId;
      tenantID = this.props.location.state.tenantID;
      programCode = this.props.location.state.programCode;
    } else {
      let UserProfile = JSON.parse(localStorage.getItem("UserProfile"));
      let ChatSoundNotiSetting = JSON.parse(
        localStorage.getItem("ChatSoundNotiSetting")
      );

      agentId = UserProfile.agentId;
      programCode = UserProfile.programCode;
      tenantID = ChatSoundNotiSetting.tenantID;
      this.setState({
        programCode: programCode,
        AgentID: agentId,
        sAgentId: agentId,
      });
    }

    setTimeout(() => {
      var objNewChat = {
        userMaster_ID: agentId,
        tenant_ID: tenantID,
        ProgramCode: programCode,
        ChatId: 0,
      };
      socket.emit("CallSetCurrentChatSP", objNewChat);
    }, 2000);
    // setTimeout(() => {

    //To get the value for number masking
    // this.handleCRMRole();

    if (this.props.location.state) {
      if (this.props.location.state.isVideoCall) {
        if (this.props.location.state.isSearch) {
          this.setState({
            isVideoSearch: true,
          });
        } else {
          this.setState({
            isVideoSearch: false,
          });
        }
      } else {
        this.setState({
          isVideoCall: false,
          isVideoSearch: false,
        });
      }
      if (window.localStorage.getItem("newTicketChatId")) {
        this.setState({
          newTicketChatId:
            Number(window.localStorage.getItem("newTicketChatId")) || 0,
        });
        window.localStorage.removeItem("newTicketChatId");
      }
      if (
        this.props.location.state.chatId &&
        !this.props.location.state.isNewChat
      ) {
        this.setState({
          programCode: this.props.location.state.programCode || "",
          storeCode: this.props.location.state.storeCode || "",
          tenantID: this.props.location.state.tenantID || 0,
          AgentID: this.props.location.state.agentId || 0,
          UserName: this.props.location.state.UserName || "",
          chatId: this.props.location.state.chatId || 0,
        });
        this.props.location.state.chatId = 0;
      } else if (
        this.props.location.state.chatId &&
        this.props.location.state.isNewChat
      ) {
        this.setState({
          programCode: this.props.location.state.programCode,
          storeCode: this.props.location.state.storeCode,
          tenantID: this.props.location.state.tenantID,
          AgentID: this.props.location.state.agentId,
          UserName: this.props.location.state.UserName,
          chatId: this.props.location.state.chatId,
        });
        this.props.location.state.chatId = 0;
        this.props.location.state.isNewChat = false;
      } else {
        this.setState({
          programCode: this.props.location.state.programCode,
          storeCode: this.props.location.state.storeCode,
          tenantID: this.props.location.state.tenantID,
          AgentID: this.props.location.state.agentId,
          UserName: this.props.location.state.UserName,
        });
      }

      this.handleCreateSocketConnection(
        this.props.location.state.programCode,
        this.props.location.state.storeCode
      );
      this.handleGetStoreAgentDetailsById(this.props.location.state.agentId);
    }
    else {
      this.handleCreateSocketConnection(
        JSON.parse(localStorage.getItem("UserProfile")).programCode,
        JSON.parse(localStorage.getItem("UserProfile")).storeCode
      );
      this.handleGetStoreAgentDetailsById(JSON.parse(localStorage.getItem("UserProfile")).agentId);
    }
    // }, 1000);

    window.addEventListener("resize", this.handleCheckView.bind(this));
    if (window.localStorage.getItem("translateLanguage") === "hindi") {
      this.state.translateLanguage = translationHI;
    } else if (window.localStorage.getItem("translateLanguage") === "marathi") {
      this.state.translateLanguage = translationMA;
    } else {
      this.state.translateLanguage = {};
    }

    if (localStorage.getItem("UserData")) {
      var userData = localStorage.getItem("UserData");
      var objData = JSON.parse(userData);
      var storeName = objData.storeName;
      var storeCode = objData.storeCode;
      var storeAddress = objData.storeAddress;
      this.setState({
        storeName,
        storeAddress,
        storeCode,
      });
    } else {
      this.handleGetUserProfileData();
    }

    this.handleCheckView();
    setTimeout(() => {
      this.handleGetNewChat();
      this.handleGetOngoingChat();
      this.handleGetChatNotificationCount();
    }, 900);
    this.handleGetAgentList();
    this.handleGetTabConfiguration();
    var checkChatSession = window.localStorage.getItem("ChatSession") || "";
    var checkChatSoundNotiSetting =
      window.localStorage.getItem("ChatSoundNotiSetting") || "";

    if (
      checkChatSoundNotiSetting === "" ||
      checkChatSoundNotiSetting === null
    ) {
    } else {
      var responseData = JSON.parse(
        window.localStorage.getItem("ChatSoundNotiSetting")
      );
      this.setState({
        newChatSoundVolume: responseData.newChatSoundVolume || 0,
        newMessageSoundVolume: responseData.newMessageSoundVolume || 0,
        isNotiNewChat: responseData.isNotiNewChat || false,
        isNotiNewMessage: responseData.isNotiNewMessage || false,
        notificationTime: responseData.notificationTime,
        tenantID: responseData.tenantID,
      });
    }

    if (checkChatSession === "" || checkChatSession === null) {
      this.handleGetChatSession();
    } else {
      var data = JSON.parse(window.localStorage.getItem("ChatSession"));
      this.setState({
        tempRemainingCount: data.chatCharLimit,
        remainingCount: data.chatCharLimit,
        isMessageTabActive: data.message,
        isCardTabActive: data.card,
        isRecommendedTabActive: data.recommendedList,
        isSchedualTabActive: data.scheduleVisit,
        isGeneratePaymentTabActive: data.paymentLink,
        isCustomerProfile: data.customerProfile,
        isCustomerProduct: data.customerProduct,
        isCustomerComment: data.storeStaffCommentInProfile,
        isCreateTicket: data.createTicketInChatWindow,
        isAddToCardEnableForCB: data.addToCardEnableForCB,
        isSendCardImageEnableForCB: data.sendCardImageEnableForCB,
        agentChatSessionValue: data.agentChatSessionValue,
        agentChatSessionDuration: data.agentChatSessionDuration,
        customerChatSessionValue: data.customerChatSessionValue,
        customerChatSessionDuration: data.customerChatSessionDuration,
        cardSearchStoreCode: data.cardSearchStoreCode,
        isgrammarlyCheck: data.grammarlyCheck,
        isVideoCallTabActive: data.videoCallAppointment,
        isRetarget: data.retargetInProfile
      });
      if (data.customerProfile) {
        this.setState({ ProfileProductTab: 0 });
      } else {
        this.setState({ ProfileProductTab: 1 });
      }
    }
    var cardConfigData = window.localStorage.getItem("cardConfigData") || "";

    if (cardConfigData !== "" || cardConfigData !== null) {
      this.handleGetCardConfiguration();
    } else {
      this.setState({ cardConfigData });
    }
    setTimeout(() => {
      if (this.state.isMobileView) {
        this.handleChatNotificationDetails();
      }
    }, 1000);
    let soundFile = JSON.parse(
      window.localStorage.getItem("newMessageSoundFile")
    );

    if (!soundFile) {
      this.handleGetChatSoundNotiSetting();
    } else {
      this.setState({
        newMessageSoundFile: JSON.parse(
          window.localStorage.getItem("newMessageSoundFile")
        ),
        newChatSoundFile: JSON.parse(
          window.localStorage.getItem("newChatSoundFile")
        ),
      });
    }

    //To fetch all the text message suggestion.
    this.handleSuggestionOnTyping("");

    //To get appointment notification in web app
    var userProfile = JSON.parse(window.localStorage.getItem("UserProfile"));
    if (userProfile !== null && userProfile.agentName) {
      this.getVideoCallAppointmentData(userProfile.storeID);
    }
    this.checkAppointmentNotification(0);

    //To get the value for number masking
    // this.handleCRMRole();
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.handleCheckView.bind(this));

    // socket.disconnect();
  }
  componentDidUpdate() {
    if (this.state.chatModal && this.state.isDownbtn && this.state.isScroll) {
      this.setState({ isScroll: false });
      this.scrollToBottom();
    }

    if (this.state.showHistoricalChat) {
      this.historyMessageScrollToBottom();
    }
  }
  //handleGet Ongoing Chat
  async handleGetOngoingChat(event) {
    let self = this;
    var search = "";
    if (event !== undefined) {
      search = event.target.value;
      this.setState({
        searchChat: event.target.value,
        noRecommendedFound: "",
      });
      // console.log(this.state)
    } else {
      search =
        this.state.copiedNumber !== ""
          ? this.state.copiedNumber
          : this.state.searchChat;
    }
    var objOngoing = {
      userMaster_ID: this.state.AgentID,
      tenant_ID: this.state.tenantID,
      search: search || "",
      StoreMgr_ID: this.state.sAgentId,
      ProgramCode: this.state.programCode,
      StoreCode: this.state.storeCode,
      ChatId: this.state.chatId,
    };

    socket.emit("CallOngoingSP", objOngoing);

    // socket.on("connect", () => {
    socket.once(
      "CallOngoingSP" +
      this.state.storeCode.toLowerCase() +
      this.state.programCode.toLowerCase(),
      function (data) {
        var ongoingChatsData = [];
        // debugger
        if (data.length > 0) {
          for (let i = 0; i < data.length; i++) {
            var objData = {};
            objData.chatID = data[i].CurrentChatID;
            objData.storeID = data[i].StoreID;
            objData.programCode = data[i].ProgramCode;
            objData.customerID = data[i].CustomerID;
            objData.customerName = data[i].CustomerName;
            objData.mobileNo = data[i].CustomerNumber;
            objData.messageCount = data[i].NewMessageCount;
            objData.timeAgo = data[i].TimeAgo;
            objData.createdDate = data[i].CreatedDate;
            objData.storeManagerId = data[i].StoreManagerId;
            objData.storeManagerName = data[i].StoreManagerName;
            if (data[i].IsCustEndChat === 0) {
              objData.isCustEndChat = false;
            } else {
              objData.isCustEndChat = true;
            }
            if (data[i].IsCustTimeout === 0) {
              objData.isCustTimeout = false;
            } else {
              objData.isCustTimeout = true;
            }
            objData.sourceName = data[i].SourceName;
            objData.chatSourceID = data[i].SourceID;
            objData.sourceAbbr = data[i].SourceAbbr;
            objData.sourceIconUrl = data[i].SourceIconUrl;
            ongoingChatsData.push(objData);
          }
        }
        localStorage.setItem(
          "ongoingChatsData",
          JSON.stringify(ongoingChatsData)
        );
        if (self.props.location.state) {
          if (self.props.location.state.isVideoCall) {
            if (ongoingChatsData.length > 0) {
              var chatDataNew = ongoingChatsData.filter(
                (x) =>
                  x.mobileNo === self.props.location.state.MobileNo &&
                  x.sourceName === "WebBot"
              );
              if (chatDataNew.length > 0) {
                self.handleOngoingChatClick(
                  chatDataNew[0].chatID,
                  chatDataNew[0].customerName,
                  chatDataNew[0].messageCount,
                  chatDataNew[0].mobileNo,
                  chatDataNew[0].customerID,
                  chatDataNew[0].programCode,
                  chatDataNew[0].storeID,
                  chatDataNew[0].isCustEndChat,
                  chatDataNew[0].storeManagerId,
                  chatDataNew[0].initialColor,
                  chatDataNew[0].isCustTimeout,
                  chatDataNew[0].sourceAbbr
                );
              }
            }
          }
        }
        if (ongoingChatsData.length > 0) {
          //         //for getting new ongoing data

          var chatData = ongoingChatsData.filter(
            (x) => x.chatID === self.state.chatId
          );
          var ustEndChat = ongoingChatsData.filter(
            (x) => x.chatID === self.state.chatId
          );

          var isCustTimeout = false;
          if (ustEndChat.length > 0) {
            isCustTimeout = ustEndChat[0].isCustTimeout;
          }

          if (chatData.length == 0) {
            self.setState({
              // customerName: "",
              // messageData: [],
              isMainLoader: false,
              isPinClick: false,
              onHoverName: false,
              chatId: 0,
              isCheckSuggetion: false,
            });
          } else {
            // self.handleOngoingChatClick(
            //   chatData[0].chatID,
            //   chatData[0].customerName,
            //   chatData[0].messageCount,
            //   chatData[0].mobileNo,
            //   chatData[0].customerID,
            //   chatData[0].programCode,
            //   chatData[0].storeID,
            //   chatData[0].isCustEndChat,
            //   chatData[0].storeManagerId,
            //   chatData[0].initialColor,
            //   chatData[0].isCustTimeout,
            //   chatData[0].sourceAbbr
            // );
          }
          self.setState({
            isCustTimeout,
            ongoingChatsData,
            isEmptyArray: false,
            // tmpOngoingChatsData: ongoingChatsData,
          });

          for (let i = 0; i < ongoingChatsData.length; i++) {
            ongoingChatsData[i].initialColor =
              self.state.colorCode[Math.floor(Math.random() * 6)];
          }
          //for reiniate
          if (self.state.newTicketChatId > 0) {
            var chatData = ongoingChatsData.filter(
              (x) => x.chatID === self.state.newTicketChatId
            );
            self.setState({
              ongoingChatsData,
              newTicketChatId: 0,
            });
            self.handleOngoingChatClick(
              chatData[0].chatID,
              chatData[0].customerName,
              chatData[0].messageCount,
              chatData[0].customerNumber,
              chatData[0].customerID,
              chatData[0].programCode,
              chatData[0].storeID,
              chatData[0].isCustEndChat,
              chatData[0].storeManagerId,
              chatData[0].initialColor,
              chatData[0].isCustTimeout,
              chatData[0].sourceAbbr
            );
          } else {
            var selectedColor = "";
            if (self.state.chatId > 0) {
              var selectedColor = ongoingChatsData.filter(
                (x) => x.chatID === self.state.chatId
              )[0]?.initialColor;
            }

            self.setState({
              selectedColor,
              ongoingChatsData,
            });
          }
        } else {
          self.setState({
            isPinClick: false,
            onHoverName: false,
            // customerName: "",
            // messageData: [],
            isMainLoader: false,
          });
          self.setState({
            ongoingChatsData: [],
            tmpOngoingChatsData: [],
          });
        }
      }
    );
  }
  //handle clear chat search
  handleClearChatSearch = async () => {
    await this.setState({
      searchChat: "",
      copiedNumber: "",
      showSearch: false,
      isNumberPaste: false,
    });
    this.handleGetOngoingChat();
  };
  //handle Get New Chat
  async handleGetNewChat(mobileNo, msgData) {
    this.setState({
      isMainLoader: true,
    });
    socket.send("hi");
    let self = this;
    var objNewChat = {
      userMaster_ID: this.state.AgentID,
      tenant_ID: this.state.tenantID,
      ProgramCode: this.state.programCode,
      StoreCode: this.state.storeCode,
    };
    socket.emit("CallNewChatSP", objNewChat);
    // socket.on("connect", () => {
    socket.once(
      "CallNewChatSP" +
      this.state.storeCode.toLowerCase() +
      this.state.programCode.toLowerCase(),
      function (data) {
        var newChatsData = [];
        if (data.length > 0) {
          for (let i = 0; i < data.length; i++) {
            var objData = {};
            objData.chatID = data[i].CurrentChatID;
            objData.storeID = data[i].StoreID;
            objData.programCode = data[i].ProgramCode;
            objData.customerID = data[i].CustomerID;
            objData.customerName = data[i].CustomerName;
            objData.mobileNo = data[i].CustomerNumber;
            objData.messageCount = data[i].NewMessageCount;
            objData.timeAgo = data[i].TimeAgo;
            objData.createdDate = data[i].CreatedDate;
            objData.storeManagerId = data[i].StoreManagerId;
            objData.storeManagerName = data[i].StoreManagerName;
            if (data[i].IsCustEndChat === 0) {
              objData.isCustEndChat = false;
            } else {
              objData.isCustEndChat = true;
            }
            if (data[i].IsCustTimeout === 0) {
              objData.isCustTimeout = false;
            } else {
              objData.isCustTimeout = true;
            }
            objData.sourceName = data[i].SourceName;
            objData.chatSourceID = data[i].SourceID;
            objData.sourceAbbr = data[i].SourceAbbr;
            objData.sourceIconUrl = data[i].SourceIconUrl;
            newChatsData.push(objData);
          }
          localStorage.setItem("newChatsData", JSON.stringify(newChatsData));
          for (let i = 0; i < newChatsData.length; i++) {
            newChatsData[i].initialColor =
              self.state.colorCode[Math.floor(Math.random() * 6)];
          }

          self.setState({ newChatsData, isMainLoader: false });
          if (self.state.newTicketChatId > 0) {
            var chatData = newChatsData.filter(
              (x) => x.chatID === self.state.newTicketChatId
            );
            self.handleNewChatNotification(chatData);
          }

          if (self.state.isNotiNewChat && mobileNo && msgData) {
            var chatData = newChatsData.filter((x) => x.mobileNo === mobileNo);
            if (chatData.length > 0) {
              // const Sound1Play = new Audio();
              // Sound1Play.src = JSON.parse(
              //   localStorage.getItem("newChatSoundFile")
              // );
              // Sound1Play.volume =
              //   Math.round(self.state.newChatSoundVolume / 10) / 10;
              // Sound1Play.play();
              let newChatAudio = document.getElementById("newChatAudio");
              let newChatAudioSrc = "";
              if (JSON.parse(window.localStorage.getItem("newChatSoundFile"))) {
                newChatAudioSrc = JSON.parse(
                  window.localStorage.getItem("newChatSoundFile")
                );
              } else {
                newChatAudioSrc = self.state.newChatSoundFile;
              }
              if (newChatAudio) {
                newChatAudio.src = newChatAudioSrc;
                newChatAudio.volume =
                  Math.round(self.state.newChatSoundVolume / 10) / 10;
                newChatAudio.play();
              }
              notification.open({
                key: chatData[0].chatID,
                duration: self.state.notificationTime,
                placement: "bottomRight",
                className: "hide-message-title",
                description: (
                  <ChatNotificationComponent
                    msgData={msgData}
                    chatData={chatData}
                    handleChatNotificationClick={(e) =>
                      self.handleNewChatNotification(e)
                    }
                    notitficationType={"NewChat"}
                  />
                ),
              });
            }
          }
        } else {
          self.setState({ newChatsData: [], isMainLoader: false });
        }
      }
    );
  }
  //handle Make As Read On Going Chat
  async handleMakeAsReadOnGoingChat(id) {
    let self = this;
    this.setState({ chatId: id });
    let inputParam = {
      chatID: id,
    };
    self.handleGetChatMessagesList(id);
    this.ChatService.PostWithParams(
      "/CustomerChat/MarkAsReadOnGoingChat",
      inputParam
    )
      .then((response) => {
        var message = response.message;
        var responseData = response.responseData;
        if (message === "Success" && responseData) {
          self.handleGetOngoingChat();
          self.handleGetChatNotificationCount();
        } else {
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  //handle update customer chat status
  handleUpdateCustomerChatStatus(
    id,
    storeManagerId,
    StoreID,
    name,
    mobileNo,
    customerId,
    ProgramCode,
    sourceType
  ) {
    let self = this;
    if (this.state.isMobileView) {
      document.getElementById("store-footre-main").style.display = "none";
      document.getElementById("mobileHederIcon").style.display = "none";
      document.getElementById("chatBackIcon").style.display = "inline-block";
    }
    this.setState({
      chatTypeCollpase: [2],
      pageNumberCurrentChat: 1,
      sourceType,
      chatModal: true,
      isMainLoader: true,
      isCustEndChat: false,
      storeManagerId,
      rowChatId: 0,
      agentRecentChatData: [],
      showHistoricalChat: false,
      mainTabSelect: 1,
      storeID: StoreID,
      customerName: name,
      mobileNo: mobileNo,
      customerId: customerId,
      programCode: ProgramCode,
      message: "",
      messageSuggestionData: [],
      messageSuggestionTagsData: [],
      selectedTags: 0,
      chkSuggestion: 0,
      toggle: {
        one: true,
        two: false,
        three: false,
        four: false,
        five: false,
      },
      noOfPeople: "",
      selectSlot: {},
      scheduleModal: false,
      selectedSlot: {},
      activeTab: 1,
      timeSlotData: [],
      searchItem: "",
      searchCardData: [],
      messageData: [],
      isSendClick: false,
      isHistoricalChat: false,
      isDownbtn: true,
    });

    let inputParams = {
      chatID: id,
    };
    this.ChatService.PostWithParams(
      "/CustomerChat/UpdateCustomerChatStatus",
      inputParams
    )
      .then((response) => {
        var message = response.message;
        var responseData = response.responseData;
        if (message === "Success" && responseData) {
          self.setState({ chatId: id });
          self.handleGetNewChat();
          self.handleGetOngoingChat();

          self.handleGetChatMessagesList(id);
          self.handleGetChatNotificationCount();
          var objNewChat = {
            userMaster_ID: self.state.AgentID,
            tenant_ID: self.state.tenantID,
            ProgramCode: self.state.programCode,
            ChatId: id,
          };
          socket.emit("CallSetCurrentChatSP", objNewChat);
        }
      })
      .catch((error) => {
        self.setState({ isMainLoader: false });
        console.log(error);
      });
    sessionStorage.setItem("currentMobile", mobileNo);
  }
  //handle get chat messgae by chat id
  handleGetChatMessagesList(id, RecentChat, isScrollTop) {
    let self = this;
    var forRecentChat = 0;
    var urlType = "";
    var objParam = {};

    this.setState({ isCallChatMessgaeApi: true });
    if (RecentChat) {
      forRecentChat = 1;
      urlType = "/CustomerChat/getChatMessagesList";
      objParam.chatID = id;
      objParam.ForRecentChat = forRecentChat;
    } else {
      let chatID = 0;
      if (localStorage.getItem("ongoingChatId") && this.state.isMobileView) {
        chatID = localStorage.getItem("ongoingChatId");
        localStorage.removeItem("ongoingChatId");
      } else {
        chatID = id;
      }
      objParam.chatID = chatID;
      objParam.ForRecentChat = forRecentChat;
      objParam.PageNo = isScrollTop ? this.state.pageNumberCurrentChat : 1;
      urlType = "/CustomerChat/getChatMessagesListNew";
    }

    this.setState({ isMainLoader: true });
    if (this.state.isMobileView) {
      this.setState({ messageListLoader: true });
    }

    this.ChatService.PostWithParams(urlType, objParam)
      .then((response) => {
        var message = response.message;
        var messageData = RecentChat
          ? response.responseData
          : response.responseData.chatMessages;
        var recentChatCount = 0;

        if (RecentChat) {
          recentChatCount = self.state.pastChatCount;
        } else {
          recentChatCount = response.responseData.recentChatCount;
        }
        self.setState({
          messageListLoader: false,
          isMainLoader: false,
          isCallChatMessgaeApi: false,
          pastChatCount: recentChatCount || 0,
        });
        if (message === "Success" && messageData) {
          if (self.state.showHistoricalChat) {
            self.setState({
              messageHistoryChatData: messageData,
              isMainLoader: false,
              messageListLoader: false,
            });
          } else {
            var newMessageData = [];
            if (messageData.length > 0) {
              for (let i = messageData.length - 1; i >= 0; i--) {
                newMessageData.push(messageData[i]);
              }
            }

            if (self.state.messageData.length > 0 && isScrollTop) {
              for (let i = 0; i < self.state.messageData.length; i++) {
                newMessageData.push(self.state.messageData[i]);
              }
            }

            self.setState({
              isScrollMessage: true,
              // ...messageData,
              messageData: newMessageData,
              isScroll: isScrollTop === true ? false : true,
              isMainLoader: false,
              messageListLoader: false,
              pastChatCount: recentChatCount,
              pageNumberCurrentChat: self.state.pageNumberCurrentChat + 1,
              AttachementFiles: [],
              //message: "",
            });
          }
        } else {
          self.setState({
            isScrollMessage: false,
            messageData:
              self.state.messageData.length > 0 ? self.state.messageData : [],
            isMainLoader: false,
            messageListLoader: false,
          });
        }
      })
      .catch((error) => {
        self.setState({
          isMainLoader: false,
          isCallChatMessgaeApi: false,
          messageListLoader: false,
        });
        console.log(error);
      });

    // {================================================}
    var currentMobile = sessionStorage.getItem("currentMobile");
    var temp = sessionStorage.getItem(currentMobile);
    if (temp !== null) {
      this.setState({ message: temp });
    } else {
      this.setState({ message: "" });
    }
    // {================================================}
  }
  //handle get chat notification count
  async handleGetChatNotificationCount() {
    let self = this;
    var objNotiCount = {
      userMaster_ID: this.state.AgentID,
      tenant_ID: this.state.tenantID,
      ProgramCode: this.state.programCode,
      StoreCode: this.state.storeCode,
    };

    socket.emit("CallChatNotificationCount", objNotiCount);

    socket.once(
      "CallChatNotificationCount" +
      this.state.storeCode.toLowerCase() +
      this.state.programCode.toLowerCase(),
      function (data) {
        // console.log(data, "---CallChatNotificationCount");
        if (data) {
          self.setState({
            chatMessageCount: data[0].TotalUnreadChatCount,
          });
          if (document.getElementById("chatMessageCount")) {
            document.getElementById("chatMessageCount").innerText =
              data[0].TotalUnreadChatCount;
          }
          if (document.getElementById("chatMessageCountMobile")) {
            document.getElementById("chatMessageCountMobile").innerText =
              data[0].TotalUnreadChatCount;
          }
        } else {
          self.setState({
            chatMessageCount: data[0].TotalUnreadChatCount,
          });
          if (document.getElementById("chatMessageCount")) {
            document.getElementById("chatMessageCount").innerText =
              data[0].TotalUnreadChatCount;
          }
          if (document.getElementById("chatMessageCountMobile")) {
            document.getElementById("chatMessageCountMobile").innerText =
              data[0].TotalUnreadChatCount;
          }
        }
      }
    );
  }

  //handle get chat notification count
  handleSearchChatItemDetails() {
    let self = this;
    var inputParam = {};
    if (this.state.cardSearchStoreCode) {
      inputParam.SearchText = this.state.searchItem;
      inputParam.ProgramCode = this.state.programCode;
      inputParam.StoreCode = this.state.storeCode;
    } else {
      inputParam.SearchText = this.state.searchItem;
      inputParam.ProgramCode = this.state.programCode;
    }
    this.setState({
      isCardSearch: true,
      isCardSend: false,
      isSelectedCard: false,
      selectedCard: 0,
    });
    this.ChatService.PostWithParams(
      "/CustomerChat/searchChatItemDetailsNew",
      inputParam
    )
      .then((response) => {
        var message = response.message;
        var searchCardData = response.responseData;
        self.setState({ isCardSearch: false });

        if (message == "Success" && searchCardData) {
          if (self.state.isMobileView) {
            self.setState({ cardModal: true });
          }
          searchCardData.forEach((element, i) => {
            element["itemID"] = i + 1;
            element["isChecked"] = false;
            if (!self.state.cardConfigData.Brand) {
              element.brandName = "";
            }
            if (!self.state.cardConfigData.Category) {
              element.categoryName = "";
            }
            if (!self.state.cardConfigData.Color) {
              element.color = "";
            }
            if (!self.state.cardConfigData.ColorCode) {
              element.colorCode = "";
            }
            if (!self.state.cardConfigData.ImageURL) {
              element.imageURL = "";
            }
            if (!self.state.cardConfigData.ItemCode) {
              element.uniqueItemCode = "";
            }
            if (!self.state.cardConfigData.ItemName) {
              element.productName = "";
            }
            if (!self.state.cardConfigData.Price) {
              element.price = "";
            }
            if (!self.state.cardConfigData.Size) {
              element.size = "";
            }
            if (!self.state.cardConfigData.SubCategory) {
              element.subCategoryName = "";
            }
            if (!self.state.cardConfigData.Url) {
              element.url = "";
            }
            if (!self.state.cardConfigData.discount) {
              element.discount = "";
            }
          });
          self.setState({
            isCardMobileUp: self.state.isMobileView ? true : false,
            searchCardData,
            noProductFound: "",
            tempCardSearch: self.state.searchItem,
            isDownbtn: self.state.isMobileView === false ? false : true,
          });
        } else {
          self.setState({
            searchCardData: [],
            noProductFound: "No Product Found",
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  //handle get time slot by store id
  handleGetTimeSlot(tabId) {
    let self = this;
    let url = "";
    if (tabId == 6) {
      url = "/CustomerChat/GetVideoTimeSlot";
    } else {
      url = "/CustomerChat/GetTimeSlot";
    }
    this.ChatService.Post(url)
      .then((response) => {
        var message = response.message;
        var timeSlotData = response.responseData;
        var availableSlot = 0;

        if (message == "Success" && timeSlotData) {
          if (this.state.activeTab === 6) {
            let selectedVideocallDate = timeSlotData[0].id;
            this.setState({
              selectedVideocallDate,
            });
          }

          for (var i = 0; i < timeSlotData.length; i++) {
            if (timeSlotData[i].alreadyScheduleDetails.length > 0) {
              availableSlot += 1;
            }
          }
          var maxPeopleAllow = timeSlotData[0].maxPeopleAllowed;
          self.setState({
            timeSlotData,
            isSendClick: false,
            availableSlot,
            maxPeopleAllow,
          });
        } else {
          self.setState({ timeSlotData, isSendClick: false });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  //handle send schedual visit
  handleScheduleVisit() {
    let self = this;

    var inputParam = {};
    if (this.state.customerId == 0) {
    } else {
    }
    if (Object.keys(this.state.selectedSlot).length === 0) {
      this.setState({ isSelectSlot: "please select time slot" });
    } else {
      this.setState({ isSelectSlot: "" });
    }
    if (this.state.noOfPeople === "" && this.state.activeTab !== 6) {
      this.setState({ noOfPeopleMax: "Please enter the no of people" });
      return false;
    } else {
      this.setState({ noOfPeopleMax: "" });
    }

    if (
      this.state.customerId > 0 &&
      // this.state.noOfPeople !== "" &&
      Object.keys(this.state.selectedSlot).length !== 0
    ) {
      var date = new Date(this.state.selectedDate);

      this.setState({ isSendClick: true, isSendRecomended: true });
      let Url = "";
      if (this.state.activeTab === 6) {
        //for video call appointment
        Url = "/CustomerChat/ScheduleVideoVisit";

        inputParam.AppointmentDate =
          moment(date).format("YYYY-MM-DD").toString() || "";
        inputParam.SlotID = this.state.selectedSlot.timeSlotId;
        inputParam.NOofPeople = 1;
        inputParam.MobileNo = this.state.mobileNo;
        inputParam.StoreStaffNameID = this.state.selectedSlot.storeStaffNameID;
        inputParam.Source = "bell";
        inputParam.Note = this.state.commentVideoAppointment;
        inputParam.Comment = "";
        inputParam.StoreCode = this.state.storeCode;
      } else {
        // for store appointment
        inputParam.CustomerID = this.state.customerId.toString();
        inputParam.AppointmentDate =
          moment(date).format("YYYY-MM-DD").toString() || "";
        inputParam.SlotID = this.state.selectedSlot.timeSlotId;
        inputParam.NOofPeople = Number(this.state.noOfPeople);
        inputParam.MobileNo = this.state.mobileNo;
        inputParam.StoreID = "1";
        Url = "/CustomerChat/ScheduleVisit";
      }
      this.ChatService.PostWithData(Url, inputParam)
        .then((response) => {
          var message = response.message;
          var timeSlotData = response.responseData;
          if (message == "Success" && timeSlotData) {
            let tempMessage = "";
            if (self.state.activeTab === 6) {
              tempMessage = "Your video call appointment is booked at ";
            } else {
              tempMessage = "Your appointment is booked at ";
            }
            var messagedata =
              tempMessage +
              self.state.selectedDate +
              " on " +
              self.state.selectedSlot.timeSlot +
              " \n \n Appointment ID : " +
              timeSlotData[0].appointmentID +
              " \n Customer : " +
              timeSlotData[0].customerName +
              ", " +
              timeSlotData[0].customerMobileNo +
              " \n Store Name : " +
              timeSlotData[0].storeName +
              " \n Address : " +
              timeSlotData[0].storeAddress +
              " \n Contact Number : " +
              (timeSlotData[0].storeManagerMobile.length > 10
                ? "+" + timeSlotData[0].storeManagerMobile
                : "+91" + timeSlotData[0].storeManagerMobile) +
              " \n Number Of People : " +
              timeSlotData[0].noOfPeople;
            var appointmentSendWB = {};
            appointmentSendWB.Date = self.state.selectedDate;
            appointmentSendWB.Slot = self.state.selectedSlot.timeSlot;
            appointmentSendWB.AppointmentID = timeSlotData[0].appointmentID;
            appointmentSendWB.CustomerName = timeSlotData[0].customerName;
            appointmentSendWB.CustomerMobileNo = self.state.selectedDate;
            appointmentSendWB.StoreName = timeSlotData[0].storeName;
            appointmentSendWB.StoreAddress = timeSlotData[0].storeAddress;
            appointmentSendWB.StoreMobileNo =
              timeSlotData[0].storeManagerMobile;
            appointmentSendWB.NoOfPeople = timeSlotData[0].noOfPeople;

            self.setState({
              commentVideoAppointment: "",
              noOfPeople: "",
              selectSlot: {},
              scheduleModal: false,
              selectedSlot: {},
              message: messagedata,
              isSendRecomended: false,
            });

            self.handleGetTimeSlot(self.state.activeTab);
            self.handleSendMessageToCustomer(
              messagedata,
              "",
              "",
              appointmentSendWB
            );
          } else {
            self.setState({ isSendRecomended: false });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }
  //handle send message to customer
  handleSendMessageToCustomer(
    Message,
    messagewhatsAppContent,
    imageURL,
    appointmentSendWB
  ) {
    // debugger
    // let activeD = this.state.ongoingChatsData.filter(x=>x.chatID=this.state.chatId)
    // this.setState({
    //   mobileNo: activeD[0].chatID
    // })
    let self = this;
    if (this.state.AttachementFiles.length === 0) {
      if (Message === "" || Message == null) {
        return false;
      }
    }

    var dataObj = {};

    if (this.state.sourceType === "cb") {
      if (this.state.AttachementFiles.length === 0) {
        dataObj = {
          TenantID: this.state.tenantID,
          chatId: this.state.chatId,
          StoreMgr_ID: this.state.storeManagerId,
          userMaster_ID: this.state.AgentID,
          ProgramCode: this.state.programCode,
          StoreCode: this.state.storeCode,
          Source: this.state.sourceType,
          MobileNo: this.state.mobileNo ? this.state.mobileNo : encryption(window.localStorage.getItem("customerNum"), 'decr'),
          MessageData: Message,
          WhatsAppMessage: messagewhatsAppContent || "",
          ImageURL: imageURL || "",
          HeaderToken: authHeader()["X-Authorized-Token"],
          isAttachment: false,
          attachmentData: null,
        };

        socket.emit("SendBellReplyV2", dataObj);
      } else {
        if (this.state.AttachementFiles.length > 0) {
          this.handleSendAttachmentFileOnChatAPI();
        }
      }
    } else {
      if (this.state.AttachementFiles.length === 0) {
        dataObj = {
          isFromAgent: true,
          chatId: this.state.chatId,
          StoreMgr_ID: this.state.sAgentId,
          userMaster_ID: this.state.AgentID,
          ProgramCode: this.state.programCode,
          StoreCode: this.state.storeCode,
          Source: this.state.sourceType,
          MobileNo: this.state.mobileNo ? this.state.mobileNo : encryption(window.localStorage.getItem("customerNum"), 'decr'),
          MessageData: Message,
          WhatsAppMessage: messagewhatsAppContent || "",
          ImageURL: imageURL || "",
          isCard: false,
          cardItem: null,
          isAppointment: this.state.activeTab === 4 ? true : false,
          appointmentData: appointmentSendWB,
          isAttachment: false,
          attachmentData: null,
          sentAt:
            new Date().getMonth() +
            1 +
            "/" +
            new Date().getDate() +
            "/" +
            new Date().getFullYear() +
            " " +
            new Date().getHours() +
            ":" +
            new Date().getMinutes() +
            ":" +
            new Date().getSeconds(),
          HeaderToken: authHeader()["X-Authorized-Token"],
          isSuccess: true,
          message: "OK",
          appVersion: "2.0",
        };
        socket.emit("SendBellReplyV2", dataObj); ///web bot

        socket.once("SendToWebbotReplyV2Call", function (data) {
          if (data === true) {
            self.setState({
              isSendRecomended: false,
              message: "",
              // messageSuggestionData: [],
              // messageSuggestionTagsData: [],
              // selectedTags: 0,
              cardModal: false,
              remainingCount: self.state.tempRemainingCount,
              suggestionModal: false,
              suggestionModalMob: false,
              suggestionTagModal: false,
              isMainLoader: false,
            });
            // {================================================}
            var temp = sessionStorage.getItem("currentMobile");
            sessionStorage.removeItem(temp);
            // {================================================}
          }
        });
      } else {
        this.handleSendAttachmentFileOnChatAPI();
      }
    }

    if (this.state.sourceType === "cb" || this.state.sourceType === "wb") {
      if (this.state.AttachementFiles.length === 0) {
        var messageData = self.state.messageData;
        var objMessgage = {};
        objMessgage.isBotReply = false;
        objMessgage.chatDate = "Today";
        objMessgage.chatTime = new Date().toLocaleTimeString();
        objMessgage.byCustomer = false;
        objMessgage.message = Message;
        messageData.push(objMessgage);
        self.setState({
          isScroll: true,
          messageData,
          isSendRecomended: false,
          message: "",
          // messageSuggestionData: [],
          // messageSuggestionTagsData: [],
          // selectedTags: 0,
          chkSuggestion: 0,
          cardModal: false,
          remainingCount: self.state.tempRemainingCount,
          suggestionModal: false,
          suggestionModalMob: false,
          suggestionTagModal: false,
          isMainLoader: false,
        });
        // {================================================}
        var temp = sessionStorage.getItem("currentMobile");
        sessionStorage.removeItem(temp);
        // {================================================}
      }
    }
    this.setState({
      suggestionText: "",
    });
  }
  //handlecselect card in card tab
  handleSelectCard(id, imageUrl) {
    if (imageUrl) {
      this.state.searchCardData.filter((x) => x.itemID === id)[0].isChecked =
        !this.state.searchCardData.filter((x) => x.itemID === id)[0].isChecked;

      this.setState({
        searchCardData: this.state.searchCardData,
        selectedCard: id,
        isSelectedCard: false,
        isCardSend: false,
      });
    } else {
      NotificationManager.error(
        "Image is not available so,not select the card"
      );
    }
  }
  //handle on change ck editor
  handleOnChangeCKEditor = (evt) => {
    var message = evt.target.value;
    // if (evt.target.value.length > 1 && this.state.isSuggestionText) {
    //   this.handleSuggestionOnTyping(evt.target.value);
    // }
    //{===============================================}
    var curr = sessionStorage.getItem("currentMobile");
    sessionStorage.setItem(curr, message);
    //{===============================================}
    var remLength = 0;
    remLength = this.state.tempRemainingCount - parseInt(message.length);
    if (remLength < 0) {
      message = message.substring(0, this.state.tempRemainingCount);
      return false;
    }

    this.setState({
      message,
      remainingCount: remLength,
    });

    this.state.automaticChatSuggestion = [];
    //it check's if the string is empty or not
    if (!/^\s*$/.test(message)) {
      this.state.chatSuggestionValue.forEach((option) => {
        const regexResult = new RegExp(
          `^${message.replace(/[^\w]/g, "")}`,
          `i`
        );
        if (regexResult.test(option.suggestionText)) {
          this.state.automaticChatSuggestion.push(option);
        }
      });
    } else {
      this.setState({
        showAutomaticTextOption: false,
      });
    }
    this.handleSuggestionListClick();
  };
  //handle message suggestion
  handleMessageSuggestion = () => {
    // setTimeout(() => {
    if (this.state.message.length > 0) {
      if (this.state.isMobileView) {
        this.setState({ suggestionTagModal: true });
      } else {
        this.setState({ isCheckSuggetion: true });
      }

      this.setState({
        onHoverName: false,
        isPinClick: false,
      });
      this.handleGetMessageSuggestionList();
    } else {
      this.setState({
        messageSuggestionData: [],
        messageSuggestionTagsData: [],
        selectedTags: 0,
        chkSuggestion: 0,
      });
    }
    // }, 1);
  };
  //handle get message suggestion list
  handleGetMessageSuggestionList() {
    let self = this;
    // if (this.state.isMobileView) {
    this.setState({ isSuggestionLoad: true });
    // }
    let inputParams = {
      SearchText: this.state.message,
    };
    this.ChatService.PostWithParams(
      "/CustomerChat/getChatSuggestions",
      inputParams
    )
      .then((response) => {
        let status = response.message;
        let responseData = response.responseData;
        self.setState({ isSuggestionLoad: false });
        if (status === "Success") {
          self.setState({
            messageSuggestionTagsData: responseData[0],
            selectedTags:
              responseData[0].length > 0 ? responseData[0][0].tagID : 0,
            messageSuggestionData: responseData[1],
            chkSuggestion: 0,
          });
        } else {
          self.setState({
            messageSuggestionData: [],
            chkSuggestion: 0,
            messageSuggestionTagsData: [],
            selectedTags: 0,
          });
        }
      })
      .catch((error) => { });
  }
  //handle on going chat click
  handleOngoingChatClick = (
    id,
    name,
    count,
    mobileNo,
    customerId,
    ProgramCode,
    StoreID,
    isCustEndChat,
    storeManagerId,
    selectedColor,
    isCustTimeout,
    sourceType
  ) => {
    this.props.setOnGoingMobileNumber(mobileNo);
    // document.addEventListener("contextmenu", (event) => event.preventDefault());
    if (this.state.isNumberCopiedClicked) {
      this.setState({
        isNumberCopiedClicked: false,
      });
    } else {
      if (mobileNo !== undefined) {
        let maskedNum = handleMaskingNumber(mobileNo, this.state.maskingPrefix, this.state.maskingSufix)
        this.setState({
          maskedNumber: maskedNum
        })
      }
      if (this.state.isMobileView) {
        document.getElementById("store-footre-main").style.display = "none";
        document.getElementById("mobileHederIcon").style.display = "none";
        document.getElementById("chatBackIcon").style.display = "inline-block";
        localStorage.setItem("isOngoingClick", true);
      }
      var activeTab = 1;
      if (this.state.isMessageTabActive === true) {
        activeTab = 1;
      } else if (this.state.isCardTabActive === true) {
        activeTab = 2;
      } else if (this.state.isRecommendedTabActive === true) {
        activeTab = 3;
      } else if (this.state.isSchedualTabActive === true) {
        activeTab = 4;
      } else if (this.state.isGeneratePaymentTabActive === true) {
        activeTab = 5;
      } else if (this.state.isVideoCallTabActive === true) {
        activeTab = 6;
      } else {
        activeTab = 1;
      }
      if (this.state.customerMobileNo !== mobileNo) {
        window.localStorage.setItem("LastTransaction", "");
        window.localStorage.setItem("IsLastTransaction", "");
        window.localStorage.setItem("CustomerInsights", "");
        window.localStorage.setItem("IsCustomerInsights", "");
        window.localStorage.setItem("CustomerProfile", "");
        window.localStorage.setItem("IsCustomerProfile", "");
        window.localStorage.setItem("OrderDetails", "");
        window.localStorage.setItem("IsOrderDetails", "");

        //{===========================================}
        sessionStorage.setItem("currentMobile", mobileNo);
        //{===========================================}
      }

      if (count > 0) {
        this.setState({ chatMessageCount: this.state.chatMessageCount - 1 });
      }

      if (this.state.chatId !== id) {
        var objNewChat = {
          userMaster_ID: this.state.AgentID,
          tenant_ID: this.state.tenantID,
          ProgramCode: this.state.programCode,
          ChatId: id,
        };
        socket.emit("CallSetCurrentChatSP", objNewChat);
      }
      if (this.state.messageData.length == 0 || this.state.chatId != id) {
        if (this.state.chatId === id) {
          this.setState({
            commentVideoAppointment: "",
            Suggested_Words: {},
            selectedSuggested_Words: {},
            Profane_Words: [],
            replaceMessageVal: "",
            grammarlyModal: false,
            isScroll: true,
            attachmentSendCount: 0,
            customerMobileNo: "",
            customerNameProfile: "",
            isShutterOpen: false,
            isCheckSuggetion: false,
            isSelectedCard: false,
            isCardSend: false,
            AttachementFiles: [],
            activeCollpse: "",
            isScrollMessage: false,
            sourceType,
            isCustTimeout,
            messageSuggestionTagsData: [],
            activeTab,
            selectedColor,
            chatModal: true,
            productTypeTab: 0,
            selectedWishList: [],
            selectedShoppingBag: [],
            selectedRecommended: [],
            shoppingBagData: [],
            wishListData: [],
            recommendedData: [],
            storeManagerId,
            showHistoricalChat: false,
            rowChatId: 0,
            agentRecentChatData: [],
            mainTabSelect: 1,
            isCustEndChat,
            storeID: StoreID,
            chatId: id,
            customerName: name,
            mobileNo: mobileNo,
            customerId: customerId,
            programCode: ProgramCode,
            message: "",
            messageSuggestionData: [],
            chkSuggestion: 0,
            noOfPeople: "",
            selectSlot: {},
            scheduleModal: false,
            selectedSlot: {},
            timeSlotData: [],
            searchItem: "",
            searchCardData: [],
            messageData: [],
            isSendClick: false,
            isHistoricalChat: false,
            isDownbtn: true,
            pageNumberCurrentChat: 1,
          });

          this.handleGetChatMessagesList(id);
        } else {
          this.setState({
            commentVideoAppointment: "",
            Suggested_Words: {},
            selectedSuggested_Words: {},
            Profane_Words: [],
            replaceMessageVal: "",
            grammarlyModal: false,
            isScroll: true,
            attachmentSendCount: 0,
            customerMobileNo: "",
            customerNameProfile: "",
            isShutterOpen: false,
            isCheckSuggetion: false,
            isSelectedCard: false,
            isCardSend: false,
            AttachementFiles: [],
            activeCollpse: "",
            isScrollMessage: false,
            sourceType,
            isCustTimeout,
            messageSuggestionTagsData: [],
            activeTab,
            selectedColor,
            chatModal: true,
            productTypeTab: 0,
            selectedWishList: [],
            selectedShoppingBag: [],
            selectedRecommended: [],
            shoppingBagData: [],
            wishListData: [],
            recommendedData: [],
            storeManagerId,
            rowChatId: 0,
            agentRecentChatData: [],
            showHistoricalChat: false,
            mainTabSelect: 1,
            isCustEndChat,
            storeID: StoreID,
            chatId: id,
            customerName: name,
            mobileNo: mobileNo,
            customerId: customerId,
            programCode: ProgramCode,
            message: "",
            messageSuggestionData: [],
            chkSuggestion: 0,
            noOfPeople: "",
            selectSlot: {},
            scheduleModal: false,
            selectedSlot: {},
            timeSlotData: [],
            searchItem: "",
            searchCardData: [],
            messageData: [],
            isSendClick: false,
            isHistoricalChat: false,
            isDownbtn: true,
            pageNumberCurrentChat: 1,
          });
          if (count === 0) {
            this.handleGetChatMessagesList(id);
          } else {
            this.handleMakeAsReadOnGoingChat(id);
          }
          if (this.state.isMobileView) {
            this.handleGetChatCustomerProfile(mobileNo);
          }
        }
      } else {
        this.setState({
          commentVideoAppointment: "",
          Suggested_Words: {},
          selectedSuggested_Words: {},
          Profane_Words: [],
          replaceMessageVal: "",
          grammarlyModal: false,
          isScroll: true,
          attachmentSendCount: 0,
          isShutterOpen: false,
          isCheckSuggetion: false,
          isSelectedCard: false,
          isCardSend: false,
          AttachementFiles: [],
          activeCollpse: "",
          isScrollMessage: false,
          isCustTimeout,
          sourceType,
          messageSuggestionTagsData: [],
          activeTab,
          selectedColor,
          chatModal: true,
          productTypeTab: 0,
          selectedWishList: [],
          selectedShoppingBag: [],
          selectedRecommended: [],
          shoppingBagData: [],
          wishListData: [],
          recommendedData: [],
          storeManagerId,
          rowChatId: 0,
          agentRecentChatData: [],
          showHistoricalChat: false,
          mainTabSelect: 1,
          isCustEndChat,
          storeID: StoreID,
          chatId: id,
          customerName: name,
          mobileNo: mobileNo,
          customerId: customerId,
          programCode: ProgramCode,
          message: "",
          messageSuggestionData: [],
          chkSuggestion: 0,
          noOfPeople: "",
          selectSlot: {},
          scheduleModal: false,
          selectedSlot: {},
          timeSlotData: [],
          searchItem: "",
          searchCardData: [],
          // messageData: [],
          isSendClick: false,
          isHistoricalChat: false,
          isDownbtn: true,
          pageNumberCurrentChat: 1,
        });
        if (
          this.state.isMobileView &&
          localStorage.getItem("isOngoingClick") === "true"
        )
          this.handleGetChatMessagesList(id);
      }

      this.setState({ isHistoricalChat: false, isDownbtn: true });
      if (this.state.isPinClick) {
        this.handleGetChatCustomerProfile(mobileNo);
      }

      if (this.state.isVideoSearch) {
        this.setState({
          activeTab: 2,
        });
      }
      //{=============================================}
      var temp = sessionStorage.getItem(mobileNo);
      if (temp !== null) {
        this.setState({ message: sessionStorage.getItem(mobileNo) });
      }
      //{=============================================}
    }
  };
  //handle search item text change
  handleSearchItemChange = (e) => {
    this.setState({ searchItem: e.target.value, noProductFound: "" });
  };
  //handle got to message scroll down
  scrollToBottom() {
    if (this.messageList) {
      const scrollHeight = this.messageList.scrollHeight;
      const height = this.messageList.clientHeight;
      const maxScrollTop = scrollHeight - height;
      this.messageList.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
    }
  }
  //handle no of people text change
  handleNoOfPeopleChange = (e) => {
    if (Object.keys(this.state.selectedSlot).length !== 0) {
      if (Number(e.target.value) > 0) {
        if (Number(e.target.value) <= this.state.selectedSlot.remaining) {
          if (Number(e.target.value) <= this.state.maxPeopleAllow) {
            this.setState({
              noOfPeople: e.target.value,
              noOfPeopleMax: "",
            });
          } else {
            this.setState({
              noOfPeople: "",
              noOfPeopleMax:
                "Maximum capacity are " + this.state.maxPeopleAllow,
            });
          }
        } else {
          this.setState({
            noOfPeople: "",
            noOfPeopleMax:
              "Remaining capacity are " + this.state.selectedSlot.remaining,
          });
        }
      } else {
        this.setState({
          noOfPeople: "",
          noOfPeopleMax: "No. of people should be greater than 0",
        });
      }
    } else {
      this.setState({
        isSelectSlot: "Please select time slot",
      });
    }
  };
  //handle select slot button
  handleSelectSlot = (data, selectedDate, isDisabled) => {
    if (isDisabled) {
    } else {
      this.setState({
        selectedSlot: data,
        selectedDate,
        isSelectSlot: "",
        noOfPeople: "",
        noOfPeopleMax: "",
      });
    }
  };
  //handel enter pressed event in card
  enterPressed(event) {
    var code = event.keyCode || event.which;
    if (code === 13) {
      if (this.state.sourceType === "cb" && !this.state.isAddToCardEnableForCB) {
        this.handleSearchChatItemDetails();
      } else {
        // this.handleSearchChatItemDetails();

        this.handleSearchChatItemDetailsWB();
      }
    }
  }
  //hadle AddToCard button
  handleSelectedCardAddToCard = () => {
    var self = this;
    var selectedCards = this.state.searchCardData.filter(
      (x) => x.isChecked === true
    );
    var cardDeatils = [];

    if (selectedCards.length > 0) {
      if (self.state.isMobileView) {
        self.setState({ addToCartModal: true });
      } else {
        this.setState({ isOpenClose: true });
      }
      for (var i = 0; i < selectedCards.length; i++) {
        var itemDetals = {
          itemId: selectedCards[i].itemID,
          barCode: selectedCards[i].barCode,
          name: selectedCards[i].name,
          category: selectedCards[i].categoryName,
          subCategory: selectedCards[i].subcategory,
          gender: selectedCards[i].gender,
          colour: "",
          price: "",
          size: "",
          availableQty: "",
          itemCode: selectedCards[i].itemCode,
          qty: 1,
        };
        cardDeatils.push(itemDetals);
        this.props.CardAdded(cardDeatils);
      }

      //console.log(selectedCards);
      this.setState({ itemlist: selectedCards });
    } else {
      this.setState({ isSelectedCard: true, isCardSend: false });
    }
  };

  handleClose = () => {
    var self = this;
    if (self.state.isMobileView) {
      self.setState({ addToCartModal: false });
    } else {
      this.setState({ isOpenClose: false, isSelectedCardSizeColor: false });
    }
  };

  handleAddCardModalClose = () => {
    this.setState({ addToCartModal: false });
  };

  handleAddToCart = () => {
    var addtocartData = this.props.cardDetails;
    var cardDetails = [];
    var validFlag = false;

    if (addtocartData.length > 0) {
      for (var i = 0; i < addtocartData.length; i++) {
        if (addtocartData[i].size !== "" && addtocartData[i].colour !== "") {
          var itemDetals = {
            itemId: addtocartData[i].itemId.toString(),
            barCode: addtocartData[i].barCode,
            name: addtocartData[i].name,
            category: addtocartData[i].category,
            subCategory: addtocartData[i].subCategory,
            gender: addtocartData[i].gender,
            colour: addtocartData[i].colour,
            price: addtocartData[i].price,
            size: addtocartData[i].size,
            availableQty: addtocartData[i].availableQty,
            itemCode: addtocartData[i].itemCode,
            qty: addtocartData[i].qty.toString(),
          };
          cardDetails.push(itemDetals);

          if (addtocartData.length - 1 === i) {
            validFlag = true;
            this.setState({ isSelectedCardSizeColor: false });
          }
        } else {
          this.setState({ isSelectedCardSizeColor: true });
          validFlag = false;
          break;
        }
      }
    }

    if (validFlag) {
      //spin on addtocart button
      this.setState({ isAddedToCartSpin: true });

      var CardInputData = {
        programCode: this.state.programCode,
        storeCode: this.state.storeCode,
        mobileNumber: this.state.mobileNo ? this.state.mobileNo.toString() : encryption(window.localStorage.getItem("customerNum"), 'decr').toString() ,
        itemsDetails: cardDetails,
      };

      this.ChatService.PostWithData(
        "/CustomerChat/WebBotAddToCartProduct",
        CardInputData
      )
        .then((response) => {
          let status = response.responseData.isSuccess;
          //console.log(response);
          if (status) {
            this.setState({ isAddedToCartSpin: false });
            NotificationManager.success("Product added To Cart Successfully.");
            //this.setState({isOpenClose: false, isSelectedCardSizeColor : false});

            if (this.state.isMobileView) {
              this.setState({ addToCartModal: false });
            } else {
              this.setState({
                isOpenClose: false,
                isSelectedCardSizeColor: false,
              });
            }

            this.state.searchCardData.forEach((element) => {
              element.isChecked = false;
            });

            this.props.CardAdded([]);
          } else {
            this.setState({ isAddedToCartSpin: false });
            NotificationManager.error("Product Not added to Cart");
          }
        })
        .catch((error) => {
          console.log(error);
        });
      //console.log(JSON.stringify(CardInputData));
    }
  };

  //handle card send button
  handleSendCard() {
    var selectedCards = this.state.searchCardData.filter(
      (x) => x.isChecked === true
    );
    var count = 0;
    if (selectedCards.length > 0) {
      for (let i = 0; i < selectedCards.length; i++) {
        var messageStringData = document.getElementById(
          "card" + selectedCards[i]["itemID"]
        ).innerHTML;

        var imageURL = "";
        if (this.state.sourceType === "cb" && !this.state.isAddToCardEnableForCB) {
          var messagewhatsAppContent =
            selectedCards[i].productName +
            (selectedCards[i].brandName !== "" &&
              selectedCards[i].brandName !== null
              ? "\nBrand: " + selectedCards[i].brandName.trim()
              : "") +
            (selectedCards[i].categoryName !== "" &&
              selectedCards[i].categoryName !== null
              ? ", Category: " + selectedCards[i].categoryName.trim()
              : "") +
            (selectedCards[i].subCategoryName !== "" &&
              selectedCards[i].subCategoryName !== null
              ? ", Sub Category: " + selectedCards[i].subCategoryName.trim()
              : "") +
            (selectedCards[i].color !== "" && selectedCards[i].color !== null
              ? ", Color: " + selectedCards[i].color.trim()
              : "") +
            (selectedCards[i].size !== "" && selectedCards[i].size !== null
              ? ", Size: " + selectedCards[i].size.trim()
              : "") +
            (selectedCards[i].uniqueItemCode !== "" &&
              selectedCards[i].uniqueItemCode !== null
              ? ", Item Code: " + selectedCards[i].uniqueItemCode.trim()
              : "") +
            (selectedCards[i].discount !== "" &&
              parseFloat(selectedCards[i].discount) !== 0 &&
              selectedCards[i].discount !== null
              ? ", Discount: " + selectedCards[i].discount.trim()
              : "") +
            (selectedCards[i].price !== "" &&
              parseFloat(selectedCards[i].price) !== 0 &&
              selectedCards[i].price !== null
              ? ", Price: " + selectedCards[i].price.trim()
              : "") +
            "\n" +
            (selectedCards[i].url !== null ? selectedCards[i].url : "");

          imageURL = selectedCards[i].imageURL;
        } else {
          imageURL = selectedCards[i].imageUrl;
        }
        this.setState({ isSendRecomended: true });

        this.handleSendProductsOnChat(
          true,
          messageStringData,
          messagewhatsAppContent,
          imageURL,
          selectedCards[i]
        );
        count++;
      }
      if (selectedCards.length == count) {
        this.state.searchCardData.forEach((element) => {
          element.isChecked = false;
        });
        this.setState({ searchCardData: this.state.searchCardData });
      }
    } else {
      this.setState({ isSelectedCard: true, isCardSend: false });
    }
  }
  //handle tabl click
  handleTabClick = (tabIndex, e) => {
    if (tabIndex === 1) {
      this.setState({ isDownbtn: true, activeTab: 1, isScroll: true });
    }

    if (tabIndex === 2) {
      this.setState({ isDownbtn: true, activeTab: 2, isScroll: true });
    }
    if (tabIndex === 3) {
      this.setState({ isDownbtn: true, activeTab: 3, isScroll: true });
    }

    if (tabIndex === 4) {
      this.setState({ isDownbtn: true, activeTab: 4, isScroll: true });
      this.handleGetTimeSlot(4);
    }
    if (tabIndex === 5) {
      this.setState({ isDownbtn: true, activeTab: 5, isScroll: true });
    }
    if (tabIndex === 6) {
      this.handleGetTimeSlot(6);
      this.setState({ isDownbtn: true, activeTab: 6, isScroll: true });
    }
    this.AddWhiteIconClose();

    this.setState({
      selectedSlot: {},
      Suggested_Words: {},
      selectedSuggested_Words: {},
      Profane_Words: [],
      replaceMessageVal: "",
      grammarlyModal: false,
      isCardSend: false,
      isSelectedCard: false,
      selectedCard: 0,
      //message: "",
      AttachementFiles: [],
      isCardMobileUp: false,
      isCardSearch: false,
      searchCardData: [],
      searchItem: "",
      noRecommendedFound: "",
      noOfPeopleMax: "",
      noProductFound: "",
      isSelectSlot: "",
      timeSlotData: [],
    });
  };
  //handle send recommended list
  handleSendRecommendedList() {
    let self = this;
    this.setState({ isSendRecomended: true });
    let inputParams = {
      CustomerID: this.state.customerId,
      MobileNumber: this.state.mobileNo,
      ChatID: this.state.chatId,
      Source: this.state.sourceType,
      // Source: this.state.sourceType || "WT",
    };
    this.ChatService.PostWithParams(
      "/CustomerChat/sendRecommendationsToCustomerNew",
      inputParams
    )
      .then((response) => {
        let status = response.message;
        if (status === "Success") {
          self.setState({ isSendRecomended: false, noRecommendedFound: "" });

          self.handleGetChatMessagesList(self.state.chatId);
        } else {
          self.setState({ isSendRecomended: false });
          self.setState({
            messageSuggestionData: [],
            messageSuggestionTagsData: [],
            selectedTags: 0,
            chkSuggestion: 0,
            noRecommendedFound: "No Record Found",
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  //handle opne mobile suggestion list modal
  onOpenMobSuggestionModal(suggestionText, index) {
    if (index > 0) {
      this.setState({
        suggestionModalMob: true,
        chkSuggestion: index,
        suggestionText: suggestionText,
      });
    }
  }
  onOpenMobSuggestionModalNew(suggestionText, index) {
    if (index > 0) {
      this.setState({
        chkSuggestion: index,
        suggestionText: suggestionText,
      });
    }
  }
  //handle close mobile suggestion list modal
  onCloseMobSuggestionModal = () => {
    this.setState({ suggestionModalMob: false, suggestionText: "" });
  };
  //handle change tab click
  handleHistTabClick = () => {
    this.setState({
      isHistoricalChat: true,
      isDownbtn: false,
      messageData: [],
      rowChatId: 0,
      // customerName: "",
      showHistoricalChat: false,
      mainTabSelect: 2,
    });
    this.handleGetAgentChatHistory();
  };
  //handle get agent recent chat data
  handleGetAgentRecentChat(customerId) {
    let self = this;
    this.setState({ isPastChatLoading: true });
    let inputParams = {
      CustomerID: customerId,
      PageNo: this.state.pastChatPageNo,
    };
    this.ChatService.PostWithParams(
      "/CustomerChat/GetAgentRecentChatNew",
      inputParams
    )
      .then((response) => {
        var message = response.message;
        var agentRecentChatData = response.responseData;

        if (message === "Success" && agentRecentChatData) {
          self.setState({ agentRecentChatData });
          self.setState({ isPastChatLoading: false });
        } else {
          self.setState({ agentRecentChatData });
          self.setState({ isPastChatLoading: false });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  //handle get agent list
  handleGetAgentList() {
    let self = this;
    if (window.localStorage.getItem("AgentList")) {
      var agentData = JSON.parse(window.localStorage.getItem("AgentList"));
      this.setState({ agentData });
    } else {
      this.ChatService.Post("/CustomerChat/GetAgentList")
        .then((response) => {
          var message = response.message;
          var agentData = response.responseData;
          if (message === "Success" && agentData) {
            window.localStorage.setItem("AgentList", JSON.stringify(agentData));
            self.setState({ agentData });
          } else {
            self.setState({ agentData: [] });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }
  //handle change main tabs

  handleMainTabChange(e) {
    this.setState({ mainTabSelect: e });
    if (e === 2) {
      if (this.state.isMobileView) {
        this.setState({
          showHistoricalChat: false,
          rowChatId: 0,
        });
      } else {
        this.setState({
          messageData: [],
          showHistoricalChat: false,
          rowChatId: 0,
        });
      }
    } else {
      if (this.state.isMobileView) {
        this.setState({
          showHistoricalChat: false,
          rowChatId: 0,
        });
      } else {
        this.setState({
          messageData: [],
          showHistoricalChat: false,
          rowChatId: 0,
        });
      }

      if (!this.state.isMobileView) {
        this.handleGetChatMessagesList(this.state.chatId);
      }
    }
    this.handleGetAgentRecentChat(this.state.customerId);
  }
  //handle change agent dropdown
  handleChangeAgentDropdown(e) {
    this.setState({ sAgentId: e });
    setTimeout(() => {
      this.handleGetOngoingChat();
    }, 10);
  }
  //handle get agent chat history
  handleGetAgentChatHistory() {
    let self = this;
    this.setState({ isHistoricalChatLoading: true });
    this.ChatService.Post("/CustomerChat/GetAgentChatHistory")
      .then((response) => {
        var message = response.message;
        var historicalChatData = response.responseData;
        if (message === "Success" && historicalChatData) {
          self.setState({ historicalChatData, isHistoricalChatLoading: false });
        } else {
          self.setState({
            historicalChatData: [],
            isHistoricalChatLoading: false,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  ///handle create socket connection
  handleCreateSocketConnection(programCode, storeCode) {
    let self = this;
    console.log(window.localStorage.getItem("customerNum"))
    self.handleCRMRole()
    // debugger
    if (window.localStorage.getItem("customerNum") !== null) {
      self.setState({
        mobileNo: encryption(window.localStorage.getItem("customerNum"), 'decr')
      })
    }
    self.handleGetOngoingChat();
    // self.handleGetChatCustomerProfile(this.state.mobileNo)
    socket.send("hi");
    if (programCode !== "" && programCode !== "") {
      socket.on(

        storeCode.toLowerCase() + programCode.toLowerCase(),
        function (data) {
          if (self.state.storeCode !== "" && data[5] !== "" && data[5]) {
            if (self.state.storeCode.toLowerCase() === data[5].toLowerCase()) {
              var isMobileNoExist = self.state.ongoingChatsData.filter(
                (x) =>
                  x.mobileNo === data[3] &&
                  x.isCustEndChat === false &&
                  x.sourceAbbr === data[7]
              );
              // console.log(self.state.mobileNo,"local", data[3] , "self.state.mobileNo === data[3]");
              if (isMobileNoExist.length > 0) {
                if (
                  self.state.mobileNo === data[3] &&
                  self.state.sourceType === data[7]
                ) {
                  //for current chat message
                  self.setState({ sourceType: data[7] });
                  var chatId = 0;
                  let newChatMeaasgeAudio = document.getElementById(
                    "newChatMeaasgeAudio"
                  );
                  let newChatMeaasgeAudioSrc = "";
                  if (
                    JSON.parse(
                      window.localStorage.getItem("newMessageSoundFile")
                    )
                  ) {
                    newChatMeaasgeAudioSrc = JSON.parse(
                      window.localStorage.getItem("newMessageSoundFile")
                    );
                  } else {
                    newChatMeaasgeAudioSrc = self.state.newMessageSoundFile;
                  }
                  if (newChatMeaasgeAudio) {
                    newChatMeaasgeAudio.src = newChatMeaasgeAudioSrc;
                    newChatMeaasgeAudio.volume =
                      Math.round(self.state.newMessageSoundVolume / 10) / 10;
                    newChatMeaasgeAudio.play();
                  }

                  var chatData = self.state.ongoingChatsData.filter(
                    (x) => x.mobileNo === data[3] && x.sourceAbbr === data[7]
                  );
                  var messageData = self.state.messageData;
                  // console.log("programCode", programCode, "storeCode", storeCode, "data[0]", data[0]);
                  var objMessgage = {};
                  objMessgage.isBotReply = false;
                  objMessgage.chatDate = "Today";
                  objMessgage.chatTime = new Date().toLocaleTimeString();
                  objMessgage.byCustomer = true;
                  objMessgage.message = data[0].toString();
                  objMessgage.customerName = chatData[0].customerName;
                  objMessgage.isAttachment =
                    data[8] === undefined ? false : data[8];
                  objMessgage.attachment =
                    data[9] === undefined ? "" : data[9];
                  objMessgage.isCopyIcon =
                    data[10] === undefined ? false : data[10];
                  objMessgage.searchText =
                    data[11] === undefined ? "" : data[11];
                  messageData.push(objMessgage);
                  self.setState({
                    messageData,
                    isScroll: true,
                  });
                  setTimeout(() => {
                    notification.open({
                      key: chatData[0].chatID,
                      duration: self.state.notificationTime,
                      placement: "bottomRight",
                      // message: "Notification Title",
                      className: "hide-message-title",
                      description: (
                        <ChatNotificationComponent
                          msgData={data[0]}
                          chatData={chatData}
                          handleChatNotificationClick={(e) =>
                            self.handleNotificationClick(e, true)
                          }
                          notitficationType={""}
                        />
                      ),
                    });
                  }, 1200);
                  if (self.state.ongoingChatsData.length > 0) {
                    chatId = self.state.ongoingChatsData.filter(
                      (x) =>
                        x.mobileNo === self.state.mobileNo &&
                        x.sourceAbbr === data[7]
                    )[0].chatID;
                  }
                  if (data[6]) {
                    self.handleEndCustomerChat(chatId, data[0]);
                  } else {
                    setTimeout(() => {
                      if (
                        localStorage.getItem("isOngoingClick") === "false" ||
                        !localStorage.getItem("isOngoingClick")
                      ) {
                        self.handleGetOngoingChat();
                      }
                    }, 4500);
                  }
                } else {
                  let newChatMeaasgeAudio = document.getElementById(
                    "newChatMeaasgeAudio"
                  );
                  let newChatMeaasgeAudioSrc = "";
                  if (
                    JSON.parse(
                      window.localStorage.getItem("newMessageSoundFile")
                    )
                  ) {
                    newChatMeaasgeAudioSrc = JSON.parse(
                      window.localStorage.getItem("newMessageSoundFile")
                    );
                  } else {
                    newChatMeaasgeAudioSrc = self.state.newMessageSoundFile;
                  }
                  if (newChatMeaasgeAudio) {
                    newChatMeaasgeAudio.src = newChatMeaasgeAudioSrc;
                    newChatMeaasgeAudio.volume =
                      Math.round(self.state.newMessageSoundVolume / 10) / 10;
                    newChatMeaasgeAudio.play();
                  }
                  if (self.state.isNotiNewMessage) {
                    var chatData = self.state.ongoingChatsData.filter(
                      (x) => x.mobileNo === data[3] && x.sourceAbbr === data[7]
                    );
                    notification.open({
                      key: chatData[0]?.chatID,
                      duration: self.state.notificationTime,
                      placement: "bottomRight",
                      // message: "Notification Title",
                      className: "hide-message-title",
                      description: (
                        <ChatNotificationComponent
                          msgData={data[0]}
                          chatData={chatData}
                          handleChatNotificationClick={(e) =>
                            self.handleNotificationClick(e)
                          }
                          notitficationType={""}
                        />
                      ),
                    });
                  }
                  if (self.state.mobileNo === undefined) {
                    var chatData = self.state.ongoingChatsData.filter(
                      (x) => x.mobileNo === data[3] && x.sourceAbbr === data[7]
                    );
                    var messageData = self.state.messageData;
                    // console.log("programCode", programCode, "storeCode", storeCode, "data[0]", data[0]);
                    var objMessgage = {};
                    objMessgage.isBotReply = false;
                    objMessgage.chatDate = "Today";
                    objMessgage.chatTime = new Date().toLocaleTimeString();
                    objMessgage.byCustomer = true;
                    objMessgage.message = data[0].toString();
                    objMessgage.customerName = chatData[0].customerName;
                    objMessgage.isAttachment =
                      data[8] === undefined ? false : data[8];
                    objMessgage.attachment =
                      data[9] === undefined ? "" : data[9];
                    objMessgage.isCopyIcon =
                      data[10] === undefined ? false : data[10];
                    objMessgage.searchText =
                      data[11] === undefined ? "" : data[11];
                    messageData.push(objMessgage);
                    self.setState({
                      messageData,
                      isScroll: true,
                    });
                  }
                  setTimeout(() => {
                    self.handleGetOngoingChat();
                    self.handleGetChatNotificationCount();
                  }, 3000);
                  self.handleGetChatNotificationCount();
                }
              }
              else {

                //new chat message
                var chatData = self.state.newChatsData.filter(
                  (x) => x.mobileNo === data[3]
                );
                if (chatData.length > 0) {
                  notification.open({
                    key: chatData[0].chatID,
                    duration: self.state.notificationTime,
                    placement: "bottomRight",
                    // message: "Notification Title",
                    className: "hide-message-title",
                    description: (
                      <ChatNotificationComponent
                        msgData={data[0]}
                        chatData={chatData}
                        handleChatNotificationClick={(e) =>
                          self.handleNewChatNotification(e)
                        }
                        notitficationType={""}
                      />
                    ),
                  });
                  let newChatAudio = document.getElementById("newChatAudio");
                  let newChatAudioSrc = "";
                  if (
                    JSON.parse(window.localStorage.getItem("newChatSoundFile"))
                  ) {
                    newChatAudioSrc = JSON.parse(
                      window.localStorage.getItem("newChatSoundFile")
                    );
                  } else {
                    newChatAudioSrc = self.state.newChatSoundFile;
                  }
                  if (newChatAudio) {
                    newChatAudio.src = newChatAudioSrc;
                    newChatAudio.volume =
                      Math.round(self.state.newChatSoundVolume / 10) / 10;
                    newChatAudio.play();
                  }
                  self.handleGetChatNotificationCount();
                } else {
                  setTimeout(() => {
                    self.handleGetChatNotificationCount();
                    self.handleGetNewChat(data[3], data[0]);
                  }, 3000);
                  self.handleGetChatNotificationCount();
                }
              }
              //console.log("Mobile no. : ", isMobileNoExist[0].mobileNo, self.props.customerMobileNumber);
              if (isMobileNoExist.length > 0) {
                if (isMobileNoExist[0].mobileNo == self.props.customerMobileNumber) {
                  if (localStorage.getItem("isOngoingClick") === "false" || self.props.isFullScreen) {
                    self.props.setMessageCount(self.props.messageCount + 1);
                  }
                }
              }
            }
          }
        }
      );
    }
    // });

    // setTimeout(() => {
    //   window.localStorage.removeItem("customerNum")
    // }, 2000);
  }
  handleCardImageOnchange(itemcode, itemColor, e) {
    if (!e[0].name.match(/\.(jpg|jpeg|png)$/i)) {
      NotificationManager.error("Please select valid image file JPG,JPEG,PNG.");
      return false;
    }
    if (e[0].size > 5242880) {
      NotificationManager.error("Please select image file under 5MB");
      return false;
    }

    var tempItemColor = [];
    if (this.state.sourceType === "cb") {
      tempItemColor = itemColor.split(";");
    } else {
      tempItemColor = itemColor;
    }
    this.setState({
      imgModerationMdl: true,
      cardAddImage: e[0],
      cardAddImageName: e[0].name,
      cardItemCode: itemcode,
      cardColors: tempItemColor,
    });
  }
  ///handle insert card image upload
  handleInsertCardImageUpload = () => {
    if (this.state.selectCardColor !== 0) {
      var self = this;
      this.setState({
        imageModerationLoader: true,
      });

      var formData = new FormData();
      formData.append("files", this.state.cardAddImage);
      formData.append("ItemID", this.state.cardItemCode);
      formData.append("Color", this.state.selectCardColor);
      formData.append("CustomerMobileNo", this.state.mobileNo);
      formData.append("ChatSource", this.state.sourceType);
      formData.append("StoreCode", this.state.storeCode);
      this.ChatService.PostWithData(
        "/CustomerChat/InsertCardImageUpload",
        formData
      )
        .then((response) => {
          var messgae = response.message;
          if (messgae === "Success") {
            NotificationManager.success("Image Upload Successfully.");
            self.setState({
              imgModerationMdl: false,
              cardAddImage: "",
              cardAddImageName: "",
              cardItemCode: "",
              cardColors: [],
              imageModerationLoader: false,
              selectColorCompulsory: "",
            });
          } else {
            NotificationManager.error("Image Not Uploaded.");
            self.setState({
              imageModerationLoader: false,
            });
          }
        })
        .catch((error) => {
          this.setState({
            imageModerationLoader: false,
          });
          console.log(error);
        });
    } else {
      this.setState({
        selectColorCompulsory: "Please Select Color.",
      });
    }
  };
  //handle open action modal pop up
  handleActionOpen = () => {
    this.setState({ actionBtn: true });
    // this.setState({ actionBtn: !this.state.actionBtn });
  };
  //handle close action modal pop up
  handleActionClose = () => {
    this.setState({ actionBtn: false });
  };
  //handle update store manage chat status
  handleUpdateStoreManagerChatStatus(id) {
    if (!this.state.isCustEndChat || !this.state.isCustTimeout) {
      let self = this;
      let inputParams = {
        ChatID: this.state.chatId,
        ChatStatusID: id,
      };
      this.ChatService.PostWithParams(
        "/CustomerChat/UpdateStoreManagerChatStatus",
        inputParams
      )
        .then((response) => {
          var message = response.message;
          var responseData = response.responseData;
          if (message === "Success" && responseData) {
            self.setState({
              customerName: "",
              messageData: [],
              isCustEndChat: false,
              isCustTimeout: false,
            });
            self.handleActionClose();
            self.handleGetChatNotificationCount();
            self.handleGetOngoingChat();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }
  //handle historical table row click
  handleHistoricalTableRow = (e) => {
    this.setState({
      rowChatId: e.chatID,
      showHistoricalChat: true,
      chatTimeAgo: e.timeAgo,
      historyPage: 1,
    });

    this.handleGetChatMessagesList(e.chatID, 1);
  };
  ///handle set row class
  setRowClassName = (record) => {
    return record.chatID === this.state.rowChatId ? "clickRowStyl" : "";
  };
  //handle history messge scrool to bottom
  historyMessageScrollToBottom() {
    if (this.historyMessageList) {
      const scrollHeight = this.historyMessageList.scrollHeight;
      const height = this.historyMessageList.clientHeight;
      const maxScrollTop = scrollHeight - height;
      this.historyMessageList.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
    }
  }
  //handle history chat close
  handleHistoryChatClose() {
    this.setState({
      rowChatId: 0,
      showHistoricalChat: false,
      chatTimeAgo: "",
    });
  }

  //handle get chat session
  handleGetChatSession() {
    let self = this;
    this.ChatService.Post("/CustomerChat/GetChatSessionNew")
      .then((response) => {
        var message = response.message;
        var data = response.responseData;

        if (message === "Success" && data) {
          window.localStorage.setItem("ChatSession", JSON.stringify(data));
          self.setState({
            tempRemainingCount: data.chatCharLimit,
            remainingCount: data.chatCharLimit,
            isMessageTabActive: data.message,
            isCardTabActive: data.card,
            isRecommendedTabActive: data.recommendedList,
            isSchedualTabActive: data.scheduleVisit,
            isGeneratePaymentTabActive: data.paymentLink,
            isCustomerProfile: data.customerProfile,
            isCustomerProduct: data.customerProduct,
            isCustomerComment: data.storeStaffCommentInProfile,
            isCreateTicket: data.createTicketInChatWindow,
            isAddToCardEnableForCB: data.addToCardEnableForCB,
            isSendCardImageEnableForCB: data.sendCardImageEnableForCB,
            agentChatSessionValue: data.agentChatSessionValue,
            agentChatSessionDuration: data.agentChatSessionDuration,
            customerChatSessionValue: data.customerChatSessionValue,
            customerChatSessionDuration: data.customerChatSessionDuration,
            cardSearchStoreCode: data.cardSearchStoreCode,
            isgrammarlyCheck: data.grammarlyCheck,
            isVideoCallTabActive: data.videoCallAppointment,
            isRetarget: data.retargetInProfile,
          });
          if (data.message === false) {
            self.setState({
              activeTab: 2,
            });
          } else if (data.card === false) {
            self.setState({
              activeTab: 3,
            });
          } else if (data.recommendedList === false) {
            self.setState({
              activeTab: 4,
            });
          } else if (data.scheduleVisit === false) {
            self.setState({
              activeTab: 5,
            });
          } else if (data.paymentLink === false) {
            self.setState({
              activeTab: 1,
            });
          } else {
            self.setState({
              activeTab: 1,
            });
          }

          if (data.customerProfile) {
            self.setState({ ProfileProductTab: 0 });
          } else {
            self.setState({ ProfileProductTab: 1 });
          }
        } else {
          self.setState({
            tempRemainingCount: "",
            remainingCount: "",
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  //handle get Tab configuration
  handleGetTabConfiguration() {
    let self = this;
    this.ChatService.Post("/CustomerChat/GetTabConfigSetting")
      .then((response) => {
        var message = response.message;
        var data = response.responseData;
        if (message === "Success" && data) {
          self.setState({
            IsShoppingBagTab: data.isShoppingBagTab,
            IsWishListTab: data.isWishListTab,
            IsRecommendedTab: data.isRecommendedTab,
            IsShoppingCartTab: data.isShoppingCartTab,
            IsShoppingCartTabEnableForWB: data.isShoppingCartTabEnableForWB
          });
        } else {
          self.setState({
            IsShoppingBagTab: "",
            IsWishListTab: "",
            IsRecommendedTab: "",
            IsShoppingCartTab: "",
            IsShoppingCartTabEnableForWB: ""
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  //handle end customer chat
  handleEndCustomerChat(chatId, message) {
    let self = this;
    let inputParams = {
      ChatID: chatId,
      EndChatMessage: message || "",
    };
    this.ChatService.PostWithParams(
      "/CustomerChat/EndCustomerChat",
      inputParams
    )
      .then((response) => {
        var message = response.message;
        var data = response.responseData;
        if (message === "Success" && data) {
          self.setState({
            isCustEndChat: true,
          });
          self.handleGetOngoingChat();
          self.handleGetNewChat();
        } else {
          self.setState({
            isCustEndChat: false,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  //handle tag button click
  handleTagsButtonClick = (tagsId) => {
    this.setState({ selectedTags: tagsId });
  };
  //handle get chat sound notification setting
  handleGetChatSoundNotiSetting = () => {
    let self = this;
    this.ChatService.Post("/CustomerChat/GetChatSoundNotiSetting")
      .then((response) => {
        var message = response.message;
        var responseData = response.responseData;
        if (message === "Success" && responseData) {
          var reader = new FileReader();
          var reader1 = new FileReader();

          fetch(responseData.newMessageSoundFile).then(function (res) {
            res.blob().then(function (blob) {
              reader.addEventListener("loadend", function () {
                var base64FileData = reader.result.toString();
                self.setState({
                  newMessageSoundFile: base64FileData,
                });
                localStorage.setItem(
                  "newMessageSoundFile",
                  JSON.stringify(base64FileData)
                );
              });
              reader.readAsDataURL(blob);
            });
          });

          fetch(responseData.newChatSoundFile).then(function (res) {
            res.blob().then(function (blob) {
              reader1.addEventListener("loadend", function () {
                var base64FileData = reader1.result.toString();
                self.setState({
                  newChatSoundFile: base64FileData,
                });
                localStorage.setItem(
                  "newChatSoundFile",
                  JSON.stringify(base64FileData)
                );
              });
              reader1.readAsDataURL(blob);
            });
          });
          window.localStorage.setItem(
            "ChatSoundNotiSetting",
            JSON.stringify(responseData)
          );
          self.setState({
            newChatSoundVolume: responseData.newChatSoundVolume || 0,
            newMessageSoundVolume: responseData.newMessageSoundVolume || 0,
            isNotiNewChat: responseData.isNotiNewChat || false,
            isNotiNewMessage: responseData.isNotiNewMessage || false,
            notificationTime: responseData.notificationTime,
            tenantID: responseData.tenantID,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //handle get chat customer profile details
  handleGetChatCustomerProfile = (mobileNo) => {
    let self = this;

    if (mobileNo) {
      let inputParams = {
        MobileNo: mobileNo,
      };
      this.setState({ isProfileLoading: true });
      this.ChatService.PostWithParams(
        "/CustomerChat/GetChatCustomerProfile",
        inputParams
      )
        .then((response) => {
          var message = response.message;
          var responseData = response.responseData;
          self.setState({ isProfileLoading: false });
          if (message === "Success" && responseData) {
            window.localStorage.setItem(
              "CustomerProfile",
              JSON.stringify(responseData)
            );
            window.localStorage.setItem("IsCustomerProfile", false);
            var customerNameProfile = "";
            var customerMobileNo = "";
            if (responseData.mobileNumber) {
              customerMobileNo = responseData.mobileNumber;
            } else {
              customerMobileNo = self.state.mobileNo;
            }
            if (responseData.name) {
              customerNameProfile = responseData.name;
            } else {
              customerNameProfile = self.state.customerName;
            }

            self.setState({
              customerNameProfile,
              customerTier: responseData.tiername || "",
              customerMobileNo: customerMobileNo,
              customerEmailID: responseData.email || "",
              totalPoints: responseData.availablePoints || 0,
              lifetimeValue: responseData.lifeTimeValue || 0,
              visitCount: responseData.visitCount || 0,
            });
          } else {
            window.localStorage.setItem("CustomerProfile", {});
            window.localStorage.setItem("IsCustomerProfile", true);
          }
        })
        .catch((error) => {
          self.setState({ isProfileLoading: false });
          console.log(error);
        });
    }
  };
  /// handle get customer profile order details
  handleChatCustomerProfileOrderDetails = () => {
    let self = this;
    this.setState({ orderDetailsLoader: true });
    let inputParams = {
      CustomerID: this.state.customerId,
    };
    this.ChatService.PostWithParams(
      "/CustomerChat/ChatCustomerProfileOrderDetails",
      inputParams
    )
      .then((response) => {
        var message = response.message;
        var responseData = response.responseData;
        self.setState({ orderDetailsLoader: false });
        if (message === "Success" && responseData) {
          window.localStorage.setItem("OrderDetails", responseData);
          window.localStorage.setItem("IsOrderDetails", false);
          self.setState({
            orderDelivered: responseData.orderDelivered,
            orderShoppingBag: responseData.orderShoppingBag,
            orderReadyToShip: responseData.orderReadyToShip,
            orderReturns: responseData.orderReturns,
          });
        } else {
          window.localStorage.setItem("OrderDetails", {});
          window.localStorage.setItem("IsOrderDetails", true);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  /// handle get profile last transcation data
  handleChatProfileLastTransactionData = (mobileNo) => {
    let self = this;
    this.setState({ lasttransactionLoader: true });
    let inputParams = {
      MobileNo: mobileNo ? mobileNo : encryption(window.localStorage.getItem("customerNum"), 'decr'),
    };
    this.ChatService.PostWithParams(
      "/CustomerChat/ChatProfileLastTransactionDetails",
      inputParams
    )
      .then((response) => {
        var message = response.message;
        var responseData = response.responseData;
        self.setState({ lasttransactionLoader: false });
        if (message === "Success" && responseData) {
          window.localStorage.setItem(
            "LastTransaction",
            JSON.stringify(responseData)
          );
          window.localStorage.setItem("IsLastTransaction", false);
          self.setState({
            billNumber: responseData.billNumber || "",
            billAmount: responseData.billAmount,
            transactionDate: responseData.transactionDate,
            itemDetails: responseData.itemDetails || [],
            storeDetails: responseData.storeDetails,
          });
        } else {
          window.localStorage.setItem("LastTransaction", []);
          window.localStorage.setItem("IsLastTransaction", true);
        }
      })
      .catch((error) => {
        self.setState({ lasttransactionLoader: false });
        console.log(error);
      });
  };
  /// handle get profile customer insight
  handleChatProfileCustomerInsightData = (mobileNo) => {
    let self = this;
    this.setState({ insightsLoader: true });
    let inputParams = {
      MobileNo: mobileNo ? mobileNo : encryption(window.localStorage.getItem("customerNum"), 'decr'),
    };
    this.ChatService.PostWithParams(
      "/CustomerChat/ChatProfileCustomerInsights",
      inputParams
    )
      .then((response) => {
        var message = response.message;
        var responseData = response.responseData;

        self.setState({ insightsLoader: false });
        if (message === "Success" && responseData) {
          window.localStorage.setItem(
            "CustomerInsights",
            JSON.stringify(responseData)
          );
          window.localStorage.setItem("IsCustomerInsights", false);

          self.setState({
            insights: responseData || [],
          });
        } else {
          window.localStorage.setItem("CustomerInsights", responseData);
          window.localStorage.setItem("IsCustomerInsights", true);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  //handle name mouse enter
  handleNameHover = () => {
    if (!this.state.onHoverName) {
      this.setState({
        onHoverName: !this.state.onHoverName,
      });
    }
    this.setState({ isCheckSuggetion: false });

    if (this.state.isCustomerProfile) {
      this.setState({ ProfileProductTab: 0 });
      if (!this.state.onHoverName) {
        if (this.state.customerMobileNo !== this.state.mobileNo) {
          this.handleGetChatCustomerProfile(this.state.mobileNo);
        } else {
          var responseData = JSON.parse(
            window.localStorage.getItem("CustomerProfile")
          );
          var IsCustomerProfile =
            window.localStorage.getItem("IsCustomerProfile");
          if (IsCustomerProfile === "true") {
            this.setState({
              customerTier: "",
              customerEmailID: "",
              totalPoints: 0,
              lifetimeValue: 0,
              visitCount: 0,
            });
          } else {
            if (IsCustomerProfile === "false") {
              this.setState({
                customerTier: responseData.customerTier || "",
                customerEmailID: responseData.email || "",
                totalPoints: responseData.availablePoints || 0,
                lifetimeValue: responseData.lifeTimeValue || 0,
                visitCount: responseData.visitCount || 0,
              });
            } else {
              this.handleGetChatCustomerProfile(this.state.mobileNo);
            }
          }
        }
      }
    } else {
      if (this.state.isCustomerProduct) {
        this.setState({ ProfileProductTab: 1 });
        if (!this.state.onHoverName) {
          this.handleGetChatCustomerProducts();
        }
      }
    }
  };
  //handle name hover leave
  handleNameHoverLeave = () => {
    if (this.state.onHoverName && !this.state.isPinClick) {
      this.setState({
        onHoverName: false,
      });
      if (this.state.isCustomerProfile) {
        this.setState({ ProfileProductTab: 0 });
      } else {
        this.setState({ ProfileProductTab: 1 });
      }
    }
  };
  //handle pin click
  handlePinClick = () => {
    this.setState({
      isPinClick: !this.state.isPinClick,
    });
  };
  //handle change shutter windows in mobile view
  handleChangeShutterWindow = (isOpne) => {
    this.setState({
      isShutterOpen: isOpne,
    });
    if (this.state.isCustomerProfile) {
      this.setState({ ProfileProductTab: 0 });
    } else {
      this.setState({ ProfileProductTab: 1 });
    }
  };
  //handle profile product tab change
  handleProfileProductTabChange = (index) => {
    this.setState({
      ProfileProductTab: index,
      totalShoppingBag: 0,
      totalWishList: 0,
      totalRecommend: 0,
      totalShoppingCart: 0,
      //NoteAddComment: "",
      AddNoteValidation: "",
    });
    if (index === 1) {
      this.handleGetChatCustomerProducts();
      this.handleCartDetails();
    }
    else if (index === 3) {
      this.handleGetCategoryList();
      this.handleGetPriorityList();
      this.handleGetTicketListByMobile();
      this.setState({ GeneratedTicketId: 0 });
    } else if (index === 4) {
      this.handleGetRetargetList()
    }
  };
  //handle Create ticket collapse in Profile section
  handleCollapseChange = (e) => {
    this.setState({
      activeCollapse: e,
    });
  };
  //handle Comment textbox change
  handleNoteOnChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  //handle Add StoreStaff Comment
  handleNoteAddComments() {
    const TranslationContext = this.state.translateLanguage.default;
    let self = this;
    if (this.state.NoteAddComment.length > 0) {
      let inputParams = {
        StoreCode: this.state.storeCode,
        MobileNo: this.state.mobileNo,
        Note: "",
        CommentId: 0,
        Comment: this.state.NoteAddComment,
      };
      this.ChatService.PostWithData(
        "/CustomerChat/InsertUpdateStaffComment",
        inputParams
      )
        .then((response) => {
          let Msg = response.message;
          if (Msg === "Success") {
            self.setState({
              NoteAddComment: "",
              AddNoteValidation: "",
              isShutterOpen: false,
            });
            NotificationManager.success(
              TranslationContext !== undefined
                ? TranslationContext.alertmessage.commentaddedsuccessfully
                : "Comment added successfully."
            );
          } else {
            NotificationManager.error(
              TranslationContext !== undefined
                ? TranslationContext.alertmessage.commentnotadded
                : "Comment not added."
            );
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      this.setState({
        AddNoteValidation: "Please Enter Comment.",
      });
    }
  }
  //handle collpse change
  handleCollpseChange = (e) => {
    this.state.activeCollpse = e[e.length - 1];
    this.setState({
      activeCollpse: this.state.activeCollpse,
    });

    if (Number(this.state.activeCollpse) === 1) {
      var InsightData = window.localStorage.getItem("CustomerInsights");

      if (InsightData) {
        this.setState({
          // insights:JSON.parse(InsightData.insights),
          insights: JSON.parse(InsightData),
        });
      } else {
        this.handleChatProfileCustomerInsightData(this.state.mobileNo);
      }
    }

    if (Number(this.state.activeCollpse) === 2) {
      var responseData = {};
      if (window.localStorage.getItem("OrderDetails")) {
        var responseData = JSON.parse(
          window.localStorage.getItem("OrderDetails")
        );
      }

      var IsOrderDetails = window.localStorage.getItem("IsOrderDetails");
      if (
        IsOrderDetails === "true" ||
        (IsOrderDetails !== null && IsOrderDetails !== "")
      ) {
        this.setState({
          orderDelivered: "",
          orderShoppingBag: "",
          orderReadyToShip: "",
          orderReturns: "",
        });
      } else {
        if (IsOrderDetails === "false") {
          this.setState({
            orderDelivered: responseData.orderDelivered,
            orderShoppingBag: responseData.orderShoppingBag,
            orderReadyToShip: responseData.orderReadyToShip,
            orderReturns: responseData.orderReturns,
          });
        } else {
          this.handleChatCustomerProfileOrderDetails();
        }
      }
    }

    if (Number(this.state.activeCollpse) === 3) {
      var responseDatavar = null;
      if (window.localStorage.getItem("LastTransaction")) {
        responseDatavar = window.localStorage.getItem("LastTransaction");
      }

      if (responseDatavar) {
        var responseData = JSON.parse(responseDatavar);
        this.setState({
          billNumber: responseData.billNumber || "",
          billAmount: responseData.billAmount,
          transactionDate: responseData.transactionDate,
          itemDetails: responseData.itemDetails || [],
          storeDetails: responseData.storeDetails,
        });
      } else {
        this.handleChatProfileLastTransactionData(this.state.mobileNo);
      }
    }
  };
  //handle get chat customer products
  handleGetChatCustomerProducts = () => {
    let self = this;
    this.setState({ isProductLoading: true });
    let inputParams = {
      CustomerID: this.state.customerId,
      MobileNo: this.state.mobileNo ? this.state.mobileNo : encryption(window.localStorage.getItem("customerNum"), 'decr'),
    };
    this.ChatService.PostWithParams(
      "/CustomerChat/GetChatCustomerProducts",
      inputParams
    )
      .then((response) => {
        var message = response.message;
        var responseData = response.responseData;
        var shoppingBagData = [];
        var wishListData = [];
        var recommendedData = [];
        var totalShoppingBag = 0;
        var totalWishList = 0;
        var totalRecommend = 0;
        self.setState({ isProductLoading: false });
        if (message === "Success" && responseData) {
          responseData.forEach((element) => {
            if (!self.state.cardConfigData.Brand) {
              element.brandName = "";
            }
            if (!self.state.cardConfigData.Category) {
              element.categoryName = "";
            }
            if (!self.state.cardConfigData.Color) {
              element.color = "";
            }
            if (!self.state.cardConfigData.ColorCode) {
              element.colorCode = "";
            }
            if (!self.state.cardConfigData.ImageURL) {
              element.imageURL = "";
            }
            if (!self.state.cardConfigData.ItemCode) {
              element.uniqueItemCode = "";
            }
            if (!self.state.cardConfigData.ItemName) {
              element.productName = "";
            }
            if (!self.state.cardConfigData.Price) {
              element.price = "";
            }
            if (!self.state.cardConfigData.Size) {
              element.size = "";
            }
            if (!self.state.cardConfigData.SubCategory) {
              element.subCategoryName = "";
            }
            if (!self.state.cardConfigData.Url) {
              element.url = "";
            }
            if (!self.state.cardConfigData.discount) {
              element.discount = "";
            }
          });
          for (let i = 0; i < responseData.length; i++) {
            if (responseData[i].isShoppingBag) {
              responseData[i].isCheck = false;
              shoppingBagData.push(responseData[i]);
              totalShoppingBag += Number(responseData[i].price);
            }
            if (responseData[i].isWishList) {
              responseData[i].isCheck = false;
              wishListData.push(responseData[i]);
              totalWishList += Number(responseData[i].price);
            }
            if (responseData[i].isRecommended) {
              responseData[i].isCheck = false;
              totalRecommend += Number(responseData[i].price);
              recommendedData.push(responseData[i]);
            }
          }

          self.setState({
            shoppingBagData,
            totalShoppingBag,
            totalWishList,
            wishListData,
            recommendedData,
            totalRecommend,
            mainProductsData: responseData,
          });
        }
      })
      .catch((error) => {
        self.setState({ isProductLoading: false });
        console.log(error);
      });
  };

  //handle shopping bag ,wishlist & recommended product select
  handleProductTabsChange = (tabIndex, itemIndex, uniqueItemID) => {
    if (this.state.isCustTimeout || this.state.isCustEndChat) {
      return false;
    }

    //for Shopping Bag list
    if (tabIndex === 1) {
      this.state.shoppingBagData[itemIndex].isCheck =
        !this.state.shoppingBagData[itemIndex].isCheck;
      var selectedShoppingBag = [];
      for (let k = 0; k < this.state.shoppingBagData.length; k++) {
        if (this.state.shoppingBagData[k].isCheck) {
          selectedShoppingBag.push(this.state.shoppingBagData[k]);
        }
      }
      this.setState({
        shoppingBagData: this.state.shoppingBagData,
        selectedShoppingBag,
        uniqueItemID,
      });
    }
    // for Wish List
    if (tabIndex === 2) {
      this.state.wishListData[itemIndex].isCheck =
        !this.state.wishListData[itemIndex].isCheck;
      var selectedWishList = [];
      for (let k = 0; k < this.state.wishListData.length; k++) {
        if (this.state.wishListData[k].isCheck) {
          selectedWishList.push(this.state.wishListData[k]);
        }
      }
      this.setState({
        wishListData: this.state.wishListData,
        selectedWishList,
        uniqueItemID,
      });
    }
    //for Recommended list
    if (tabIndex === 3) {
      this.state.recommendedData[itemIndex].isCheck =
        !this.state.recommendedData[itemIndex].isCheck;
      var selectedRecommended = [];
      for (let k = 0; k < this.state.recommendedData.length; k++) {
        if (this.state.recommendedData[k].isCheck) {
          selectedRecommended.push(this.state.recommendedData[k]);
        }
      }
      this.setState({
        recommendedData: this.state.recommendedData,
        selectedRecommended,
        uniqueItemID,
      });
    }
    if (tabIndex === 4) {
      this.state.cartItem[itemIndex].isCheck =
        !this.state.cartItem[itemIndex].isCheck;
      var selectedShoppingCart = [];
      for (let k = 0; k < this.state.cartItem.length; k++) {
        if (this.state.cartItem[k].isCheck) {
          selectedShoppingCart.push(this.state.cartItem[k]);
        }
      }
      this.setState({
        cartItem: this.state.cartItem,
        selectedShoppingCart,
        uniqueItemID,
      });
    }
  };
  //handle product type tab change
  handleProductTypeTabChange = (index) => {
    this.state.shoppingBagData.forEach((element) => {
      element.isCheck = false;
    });
    this.state.wishListData.forEach((element) => {
      element.isCheck = false;
    });
    this.state.recommendedData.forEach((element) => {
      element.isCheck = false;
    });
    // this.state.cartItem.forEach((element) => {
    //   element.isCheck = false;
    // });
    this.setState({
      shoppingBagData: this.state.shoppingBagData,
      recommendedData: this.state.recommendedData,
      wishListData: this.state.wishListData,
      // cartItem: this.state.cartItem,
      productTypeTab: index,
      selectedShoppingBag: [],
      selectedWishList: [],
      selectedRecommended: [],
      selectedShoppingCart: [],
    });
  };
  ///handle select all product base on tab index
  handleSelectAllProduct = (tabIndex) => {
    if (this.state.isCustEndChat || this.state.isCustTimeout) {
      return false;
    }
    //for shopping bag list tab select all
    if (tabIndex === 1) {
      for (let i = 0; i < this.state.shoppingBagData.length; i++) {
        this.state.shoppingBagData[i].isCheck = true;
      }
      var selectedShoppingBag = [];
      for (let k = 0; k < this.state.shoppingBagData.length; k++) {
        if (this.state.shoppingBagData[k].isCheck) {
          selectedShoppingBag.push(this.state.shoppingBagData[k]);
        }
      }
      this.setState({
        shoppingBagData: this.state.shoppingBagData,
        selectedShoppingBag,
      });
    }
    //for wish list tab select all
    if (tabIndex === 2) {
      for (let i = 0; i < this.state.wishListData.length; i++) {
        this.state.wishListData[i].isCheck = true;
      }
      var selectedWishList = [];
      for (let k = 0; k < this.state.wishListData.length; k++) {
        if (this.state.wishListData[k].isCheck) {
          selectedWishList.push(this.state.wishListData[k]);
        }
      }
      this.setState({
        wishListData: this.state.wishListData,
        selectedWishList,
      });
    }
    //for recommended tab select all
    if (tabIndex === 3) {
      for (let i = 0; i < this.state.recommendedData.length; i++) {
        this.state.recommendedData[i].isCheck = true;
      }
      var selectedRecommended = [];
      for (let k = 0; k < this.state.recommendedData.length; k++) {
        if (this.state.recommendedData[k].isCheck) {
          selectedRecommended.push(this.state.recommendedData[k]);
        }
      }
      this.setState({
        recommendedData: this.state.recommendedData,
        selectedRecommended,
      });
    }
  };
  //handle remove product
  handleRemoveProduct = (itemCode, fromType) => {
    let self = this;
    let inputParams = {
      CustomerID: this.state.customerId,
      MobileNo: this.state.mobileNo,
      ItemCode: itemCode,
      RemoveFrom: fromType,
    };
    this.ChatService.PostWithParams("/CustomerChat/RemoveProduct", inputParams)
      .then((response) => {
        var message = response.message;
        var responseData = response.responseData;
        if (message === "Success" && responseData) {
          self.handleGetChatCustomerProducts();
          self.setState({
            mobileWishProcessMdl: false,
            mobileRecommendProcessMdl: false,
            mobileShopBagProcessMdl: false,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  //handle add products to shopping bag

  handleAddProductsToBagOrWishlist = (type) => {
    let self = this;
    var itemCode = "";

    if (type === "shoppingbag") {
      if (this.state.selectedShoppingBag.length > 0) {
        for (let i = 0; i < this.state.selectedShoppingBag.length; i++) {
          if (this.state.selectedShoppingBag[i].isCheck) {
            itemCode += this.state.selectedShoppingBag[i].uniqueItemCode + ",";
          }
        }
      }
    } else if (type === "wishlist") {
      if (this.state.selectedWishList.length > 0) {
        for (let i = 0; i < this.state.selectedWishList.length; i++) {
          if (this.state.selectedWishList[i].isCheck) {
            itemCode += this.state.selectedWishList[i].uniqueItemCode + ",";
          }
        }
      }
    } else if (type === "recommended") {
      if (this.state.selectedRecommended.length > 0) {
        for (let i = 0; i < this.state.selectedRecommended.length; i++) {
          if (this.state.selectedRecommended[i].isCheck) {
            itemCode += this.state.selectedRecommended[i].uniqueItemCode + ",";
          }
        }
      }
    }
    this.setState({ isButtonClick: true });
    let inputParams = {
      CustomerID: this.state.customerId,
      MobileNo: this.state.mobileNo,
      ItemCodes: itemCode,
      Action: type,
    };
    this.ChatService.PostWithParams(
      "/CustomerChat/AddProductsToBagOrWishlist",
      inputParams
    )
      .then((response) => {
        var message = response.message;
        var responseData = response.responseData;

        if (message === "Success" && responseData) {
          if (type === "wishlist") {
            self.setState({
              selectedWishList: [],
              isButtonClick: false,
              mobileWishProcessMdl: false,
              mobileShopBagProcessMdl: false,
              mobileRecommendProcessMdl: false,
            });
          } else if (type === "shoppingbag") {
            self.setState({
              selectedShoppingBag: [],
              isButtonClick: false,
              mobileWishProcessMdl: false,
              mobileShopBagProcessMdl: false,
              mobileRecommendProcessMdl: false,
            });
          } else {
            self.setState({
              selectedRecommended: [],
              isButtonClick: false,
              mobileRecommendProcessMdl: false,
              mobileWishProcessMdl: false,
              mobileShopBagProcessMdl: false,
            });
          }
          self.handleGetChatCustomerProducts();
        } else {
          self.setState({
            isButtonClick: false,
            mobileWishProcessMdl: false,
            mobileShopBagProcessMdl: false,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  //handle add products to wish list
  handleAddRecommendationToWishlist = () => {
    let self = this;
    var itemCode = "";
    if (this.state.selectedRecommended.length > 0) {
      for (let i = 0; i < this.state.selectedRecommended.length; i++) {
        if (this.state.selectedRecommended[i].isCheck) {
          itemCode += this.state.selectedRecommended[i].uniqueItemCode + ",";
        }
      }
    }
    // if (this.state.selectedShoppingBag.length > 0 && !formType) {
    //   for (let i = 0; i < this.state.selectedShoppingBag.length; i++) {
    //     if (this.state.selectedShoppingBag[i].isCheck) {
    //       itemCode += this.state.selectedShoppingBag[i].uniqueItemCode + ",";
    //     }
    //   }
    // }

    this.setState({ isButtonClick: true });
    let inputParams = {
      CustomerID: this.state.customerId,
      MobileNo: this.state.mobileNo,
      ItemCodes: itemCode,
    };
    this.ChatService.PostWithParams(
      "/CustomerChat/AddRecommendationToWishlist",
      inputParams
    )
      .then((response) => {
        var message = response.message;
        var responseData = response.responseData;

        if (message === "Success" && responseData) {
          self.setState({ selectedRecommended: [], isButtonClick: false });
          self.handleGetChatCustomerProducts();
        } else {
          self.setState({ isButtonClick: false });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  //handle WebBotShoppingCart Details
  handleCartDetails = async () => {
    let self = this;
    let inputParams = {
      ProgramCode: this.state.programCode,
      StoreCode: this.state.storeCode,
      MobileNumber: this.state.mobileNo ? this.state.mobileNo : encryption(window.localStorage.getItem("customerNum"), 'decr'),
    };
    await axios({
      method: "post",
      url: config.webbotUrl.replace('{programcode}', this.state.programCode) + "/api/v1/webbot/getcartdetailsforbell",
      data: inputParams,
    })
      .then((response) => {
        var message = response.data.message;
        var responseData = response.data.data;
        if (message === "OK" && responseData) {
          self.setState({
            cartItem: response.data.data,
          });
          var totalPrice = 0;

          for (var i = 0; i < this.state.cartItem.length; i++) {

            totalPrice += this.state.cartItem[i].selectedQuantity * this.state.cartItem[i].price;

          }

          self.setState({

            totalShoppingCart: totalPrice,

          });
        } else {
          self.setState({ isButtonClick: false });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  remove(item) {
    var cart_Item = [];
    for (let i = 0; i < this.state.cartItem.length; i++) {
      var x = -1;
      var y = -1;
      if (this.state.cartItem[i].barCode != item) {
        for (
          let j = 0;
          j < this.state.cartItem[i].selectedQuantity.length;
          j++
        ) {
          for (
            let k = 0;
            k < this.state.cartItem[i].selectedQuantity[j].length;
            k++
          ) {
            if (this.state.cartItem[i].selectedQuantity[j][k] != 0) {
              x = j;
              y = k;
              break;
            }
          }
          if (x != -1 && y != -1) {
            break;
          }
        }
        var test = {
          availableQty: this.state.cartItem[i].availability,
          barCode: this.state.cartItem[i].barCode,
          category: this.state.cartItem[i].categoryName,
          colour: this.state.cartItem[i].colour,
          gender: this.state.cartItem[i].gender,
          itemId: this.state.cartItem[i].barCode,
          name: this.state.cartItem[i].name,
          price: this.state.cartItem[i].price,
          qty: this.state.cartItem[i].selectedQuantity,
          size: this.state.cartItem[i].size,
          // subCategory: this.state.cartItem[i].scSubCat,
        };
        cart_Item.push(test);
      }
    }
    return cart_Item;
  }
  //handle UpdateQuantity and Remove Item
  handleUpdateQuantity = (item, update) => {
    let self = this;
    var cart_Item = [];
    var ItemData = this.state.cartItem.filter(
      (x) => x.itemCode != item.itemCode
    );
    cart_Item = this.remove(item);
    for (let i = 0; i < this.state.cartItem.length; i++) {
      var x = -1;
      var y = -1;
      if (this.state.cartItem[i].barCode == item) {
        for (
          let j = 0;
          j < this.state.cartItem[i].selectedQuantity.length;
          j++
        ) {
          for (
            let k = 0;
            k < this.state.cartItem[i].selectedQuantity[j].length;
            k++
          ) {
            if (this.state.cartItem[i].selectedQuantity[j][k] != 0) {
              x = j;
              y = k;
              break;
            }
          }
          if (x != -1 && y != -1) {
            break;
          }
        }
        var qty =
          update === "plus"
            ? this.state.cartItem[i].selectedQuantity + 1
            : this.state.cartItem[i].selectedQuantity - 1;
        var test = {
          availableQty: this.state.cartItem[i].availability,
          barCode: this.state.cartItem[i].barCode,
          category: this.state.cartItem[i].categoryName,
          colour: this.state.cartItem[i].colour,
          gender: this.state.cartItem[i].gender,
          itemId: this.state.cartItem[i].barCode,
          name: this.state.cartItem[i].name,
          price: this.state.cartItem[i].price,
          qty: qty,
          size: this.state.cartItem[i].size,
        };
        cart_Item.push(test);
      }
    }
    let inputParams = {
      programCode: this.state.programCode,
      storeCode: this.state.storeCode,
      mobileNumber: this.state.mobileNo,
      itemsDetails: cart_Item,
      update: true,
      storeManagerID: this.state.AgentID,
    };
    axios({
      method: "post",
      url: config.webbotUrl.replace('{programcode}', this.state.programCode) + "/api/v1/webbot/addtocartandupdateforbell",
      data: inputParams,
    })
      .then((response) => {
        var message = response.data.message;
        var responseData = response.data.data;
        //console.log(responseData, "data");
        if (message === "OK" && responseData) {
          NotificationManager.success("Cart Updated.");
          self.handleCartDetails();
        }
        // console.log(this.state.cartItem);
        else {
          self.setState({ isButtonClick: false });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  handleRemoveItem(item) {
    let self = this;
    var cart_Item = this.remove(item);
    let inputParams = {
      programCode: this.state.programCode,
      storeCode: this.state.storeCode,
      mobileNumber: this.state.mobileNo,
      itemsDetails: cart_Item,
      update: true,
      storeManagerID: this.state.AgentID,
    };
    axios({
      method: "post",
      url: config.webbotUrl.replace('{programcode}', this.state.programCode) + "/api/v1/webbot/addtocartandupdateforbell",
      data: inputParams,
    })
      .then((response) => {
        var message = response.data.message;
        var responseData = response.data.data;
        if (message === "OK" && responseData) {
          NotificationManager.success("Item Removed.");
          self.handleCartDetails();
        } else {
          self.setState({ isButtonClick: false });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  //handle mobile view back button
  handleBackButton = () => {
    this.setState({ customerName: "", isHistoricalChat: false });
  };
  //handle notification click
  handleNotificationClick = (chatData, isCurrenctChat) => {
    notification.close(chatData[0].chatID);
    // setTimeout(() => {


    if (!isCurrenctChat) {
      if (window.location.pathname !== "/store/Chatbot") {
        this.props.history.push({
          pathname: "chatbot",
          state: {
            programCode: this.state.programCode,
            storeCode: this.state.storeCode,
            tenantID: this.state.tenantID,
            agentId: this.state.AgentID,
            UserName: this.state.UserName,
            chatId: chatData[0].chatID,
          },
        });
      }
      this.handleOngoingChatClick(
        chatData[0].chatID,
        chatData[0].customerName,
        chatData[0].messageCount,
        chatData[0].mobileNo,
        chatData[0].customerID,
        chatData[0].programCode,
        chatData[0].storeID,
        chatData[0].isCustEndChat,
        chatData[0].storeManagerId,
        chatData[0].initialColor,
        chatData[0].isCustTimeout,
        chatData[0].sourceAbbr
      );
    } else if (window.location.pathname !== "/store/Chatbot") {
      this.props.history.push({
        pathname: "chatbot",
        state: {
          programCode: this.state.programCode,
          storeCode: this.state.storeCode,
          tenantID: this.state.tenantID,
          agentId: this.state.AgentID,
          UserName: this.state.UserName,
          chatId: chatData[0].chatID,
        },
      });
    }
    // }, 2000);
  };
  //handle check is mobile view active or not
  handleCheckView() {
    var isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    if (isMobile) {
      this.setState({
        isMobileView: true,
        isVideoCallBookedTabCloseInMobile: true,
      });
    } else {
      this.setState({
        isMobileView: false,
        isVideoCallBookedTabCloseInMobile: false,
        suggestionTagModal: false,
      });
    }
  }
  //handle new chat notification click
  handleNewChatNotification = (chatData) => {
    notification.close(chatData[0].chatID);

    if (window.location.pathname !== "/store/Chatbot") {
      this.props.history.push({
        pathname: "chatbot",
        state: {
          programCode: this.state.programCode,
          storeCode: this.state.storeCode,
          tenantID: this.state.tenantID,
          agentId: this.state.AgentID,
          UserName: this.state.UserName,
          chatId: chatData[0].chatID,
        },
      });
      this.handleUpdateCustomerChatStatus(
        chatData[0].chatID,
        chatData[0].storeManagerId,
        chatData[0].storeID,
        chatData[0].customerName,
        chatData[0].mobileNo,
        chatData[0].customerID,
        chatData[0].programCode,
        chatData[0].sourceAbbr
      );
    } else {
      this.handleUpdateCustomerChatStatus(
        chatData[0].chatID,
        chatData[0].storeManagerId,
        chatData[0].storeID,
        chatData[0].customerName,
        chatData[0].mobileNo,
        chatData[0].customerID,
        chatData[0].programCode,
        chatData[0].sourceAbbr
      );
    }
    this.setState({
      newTicketChatId: 0,
    });
  };
  //handle buy now button
  handleBuyNowButtonClick = () => {
    this.setState({ buyNowClick: !this.state.buyNowClick });
  };
  //handle address modal close
  handleAddressModalClose = () => {
    this.setState({
      addressModal: false,
      isPinCodeCheck: "",
      isAddress: "",
      isPinCode: "",
      isCity: "",
      isState: "",
      isCountry: "",
      shippingAddress: "",
      shippingCity: "",
      shippingLandmark: "",
      shippingPinCode: "",
      shippingState: "",
      shippingCountry: "",
    });
  };
  //handle address modal open
  handleAddressModalOpen = () => {
    this.setState({
      addressModal: true,
      addressMobileNo: this.state.mobileNo,
      addressName: this.state.customerName,
    });
    this.handleGetCheckServiceData();
  };
  //handle modal text on change
  handleTextOnchage = (e) => {
    const { name, value } = e.target;

    if (name === "shippingAddress") {
      if (value) {
        this.setState({ [name]: value, isAddress: "" });
      } else {
        this.setState({ [name]: value, isAddress: "Please Enter Address." });
      }
    }
    if (name === "shippingPinCode") {
      if (value) {
        this.setState({ [name]: value, isPinCode: "" });
      } else {
        this.setState({ [name]: value, isPinCode: "Please Enter Pincode." });
      }
    }

    if (name === "shippingCity") {
      if (value) {
        this.setState({ [name]: value, isCity: "" });
      } else {
        this.setState({ [name]: value, isCity: "Please Enter City." });
      }
    }

    if (name === "shippingState") {
      if (value) {
        this.setState({ [name]: value, isState: "" });
      } else {
        this.setState({ [name]: value, isState: "Please Enter State." });
      }
    }
    if (name === "shippingCountry") {
      if (value) {
        this.setState({ [name]: value, isCountry: "" });
      } else {
        this.setState({ [name]: value, isCountry: "Please Enter Country." });
      }
    }
    if (name === "addressName") {
      if (value) {
        this.setState({ [name]: value, isName: "" });
      } else {
        this.setState({ [name]: value, isName: "Please Enter Name." });
      }
    }
    if (name === "addressMobileNo") {
      if (value) {
        if (value.length <= 10) {
          this.setState({
            [name]: value,
            isAddressMobileNo: "",
            isAddressMobileNoValid: "",
          });
        } else {
          this.setState({
            [name]: "",
            isAddressMobileNoValid: "Please Enter Valid Mobile No.",
          });
        }
      } else {
        this.setState({
          [name]: value,
          isAddressMobileNo: "Please Enter Mobile No.",
        });
      }
    }
    if (name === "shippingLandmark") {
      this.setState({ [name]: value });
    }
  };
  //handle buy products on chat
  handleBuyProductsOnChat = (isFromRecommendation, isDirectBuy) => {
    let self = this;

    if (!this.state.shippingAddress) {
      this.setState({ isAddress: "Please Enter Address." });
    } else {
      this.setState({ isAddress: "" });
    }
    if (!this.state.shippingPinCode) {
      this.setState({ isPinCode: "Please Enter Pincode." });
    } else {
      this.setState({ isPinCode: "" });
    }
    if (!this.state.shippingCity) {
      this.setState({ isCity: "Please Enter City." });
    } else {
      this.setState({ isCity: "" });
    }
    if (!this.state.shippingState) {
      this.setState({ isState: "Please Enter State." });
    } else {
      this.setState({ isState: "" });
    }
    if (!this.state.shippingCountry) {
      this.setState({ isCountry: "Please Enter Country." });
    } else {
      this.setState({ isCountry: "" });
    }
    if (!this.state.addressName) {
      this.setState({ isAddressName: "Please Enter Name." });
    } else {
      this.setState({ isAddressName: "" });
    }
    if (!this.state.addressMobileNo) {
      this.setState({ isAddressMobileNo: "Please Enter Mobile No." });
    } else {
      this.setState({ isAddressMobileNo: "" });
    }
    if (
      this.state.isAddress === "" &&
      this.state.isPinCode === "" &&
      this.state.isCity === "" &&
      this.state.isState === "" &&
      this.state.isCountry === "" &&
      // this.state.isPinCodeCheck === "" &&
      this.state.isAddressMobileNo === "" &&
      this.state.isAddressName === ""
    ) {
      var addressDetails = {};
      addressDetails.address = this.state.shippingAddress || "";
      addressDetails.landmark = this.state.shippingLandmark || "";
      addressDetails.pinCode = this.state.shippingPinCode;
      addressDetails.city = this.state.shippingCity || "";
      addressDetails.state = this.state.shippingState || "";
      addressDetails.country = this.state.shippingCountry || "";
      var itemCodes = "";
      ///for recommendation
      if (isFromRecommendation) {
        for (let i = 0; i < this.state.selectedRecommended.length; i++) {
          itemCodes += this.state.selectedRecommended[i].uniqueItemCode + ",";
        }
      }
      ///for wish list
      if (this.state.productTypeTab == 1 && isFromRecommendation === false) {
        for (let i = 0; i < this.state.selectedWishList.length; i++) {
          itemCodes += this.state.selectedWishList[i].uniqueItemCode + ",";
        }
      }
      ///for shopping bag list
      if (this.state.productTypeTab == 0 && isFromRecommendation === false) {
        for (let i = 0; i < this.state.selectedShoppingBag.length; i++) {
          itemCodes += this.state.selectedShoppingBag[i].uniqueItemCode + ",";
        }
      }
      var inputParam = {};
      inputParam.CustomerID = this.state.customerId;
      inputParam.CustomerMobile = this.state.addressMobileNo;
      inputParam.IsFromRecommendation = isFromRecommendation;
      inputParam.IsDirectBuy = isDirectBuy;
      inputParam.ItemCodes = itemCodes;
      inputParam.CustomerName = this.state.addressName;

      if (isDirectBuy === false) {
        inputParam.CustomerAddress = addressDetails;
      }
      this.ChatService.PostWithData(
        "/CustomerChat/BuyProductsOnChatNew",
        inputParam
      )
        .then((response) => {
          var message = response.message;
          var responseData = response.responseData;
          var statusCode = response.statusCode;

          if (statusCode === 200) {
            NotificationManager.success("Products Buy Successfully.");
            self.setState({
              selectedRecommended: [],
              selectedShoppingBag: [],
              selectedWishList: [],
              addressModal: false,
              shippingAddress: "",
              shippingCity: "",
              shippingCountry: "",
              shippingPinCode: "",
              shippingState: "",
              shippingLandmark: "",
              addressMobileNo: "",
              addressName: "",
              mobileWishProcessMdl: false,
              mobileRecommendProcessMdl: false,
              mobileShopBagProcessMdl: false,
            });

            self.handleGetChatCustomerProducts();
          } else {
            NotificationManager.error("Products Not Bought Successfully.");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  handleSendProductsOnChat = (
    isCard,
    messagecontent,
    WhatsAppMessage,
    imageURL,
    selectedCard
  ) => {
    var selectedProduct = [];
    if (isCard) {
      if (this.state.sourceType === "wb" && isCard) {
        selectedCard["IsCard"] = true;
        selectedCard["CardHtmlContent"] = messagecontent;
        let selectedProductTemp = [];
        selectedProductTemp.push(selectedCard);
        socket.emit("SendBellReplyV2", {
          isFromAgent: true,
          ChatID: this.state.chatId,
          ProgramCode: this.state.programCode,
          StoreCode: this.state.storeCode,
          Source: this.state.sourceType,
          MobileNo: this.state.mobileNo,
          MessageData: "",
          ImageURL: "",
          isCard: true,
          cardItem: selectedProductTemp,
          sentAt:
            new Date().getMonth() +
            1 +
            "/" +
            new Date().getDate() +
            "/" +
            new Date().getFullYear() +
            " " +
            new Date().getHours() +
            ":" +
            new Date().getMinutes() +
            ":" +
            new Date().getSeconds(),
          HeaderToken: authHeader()["X-Authorized-Token"],
          isSuccess: true,
          message: "OK",
          appVersion: "2.0",
        });
      }
      if (this.state.sourceType === "cb" && !this.state.isAddToCardEnableForCB) {
        selectedCard["IsCard"] = true;
        selectedCard["CardHtmlContent"] = messagecontent;
        selectedProduct.push(selectedCard);
      } else {
        var objCard = {};
        objCard["IsCard"] = true;
        objCard["CardHtmlContent"] = messagecontent;
        objCard.uniqueItemCode = selectedCard.itemCode;
        objCard.imageURL = selectedCard.imageUrl
        if (this.state.sourceType === "cb" && this.state.isAddToCardEnableForCB) {
          objCard.colorArray = selectedCard.availableColor;
          objCard.sizeArray = selectedCard.availableSize;
          objCard.productName = selectedCard.name;
          objCard.categoryName = selectedCard.categoryName;
          objCard.subCategoryName = selectedCard.subcategory;
          objCard.brandName = selectedCard.brandName;
          objCard.price = selectedCard.price;
        }
        selectedProduct.push(objCard);
      }

      var messageData = this.state.messageData;
      var objMessgage = {};
      objMessgage.isBotReply = false;
      objMessgage.chatDate = "Today";
      objMessgage.chatTime = new Date().toLocaleTimeString();
      objMessgage.byCustomer = false;
      objMessgage.message = messagecontent;
      messageData.push(objMessgage);
      if (!this.state.isMobileView) {
        this.setState({ cardModal: false });
      }
      this.setState({
        isScroll: true,
        messageData,
        isSendRecomended: false,
        message: "",
        messageSuggestionData: [],
        messageSuggestionTagsData: [],
        selectedTags: 0,

        remainingCount: this.state.tempRemainingCount,
        suggestionModal: false,
        suggestionModalMob: false,
        isMainLoader: false,
      });
    } else {
      if (this.state.productTypeTab == 0) {
        this.state.selectedShoppingBag.forEach((element) => {
          var finleData = this.state.mainProductsData.filter(
            (x) => x.uniqueItemCode === element.uniqueItemCode
          )[0];
          selectedProduct.push(finleData);
        });
      }

      if (this.state.productTypeTab == 1) {
        this.state.selectedWishList.forEach((element) => {
          var finleData = this.state.mainProductsData.filter(
            (x) => x.uniqueItemCode === element.uniqueItemCode
          )[0];
          selectedProduct.push(finleData);
        });
      }
      if (this.state.productTypeTab == 2) {
        this.state.selectedRecommended.forEach((element) => {
          var finleData = this.state.mainProductsData.filter(
            (x) => x.uniqueItemCode === element.uniqueItemCode
          )[0];
          selectedProduct.push(finleData);
        });
      }
    }
    this.setState({ selectedCard: 0 });
    let self = this;

    let inputData = {
      ChatID: this.state.chatId,
      Products: selectedProduct,
      CustomerID: parseInt(this.state.customerId),
      CustomerMobile: this.state.mobileNo ? this.state.mobileNo : encryption(window.localStorage.getItem("customerNum"), 'decr'),
      Source: this.state.sourceType,
      StoreCode: this.state.storeCode,
    };
    this.ChatService.PostWithData(
      "/CustomerChat/SendProductsOnChatNew",
      inputData
    )
      .then((response) => {
        var message = response.message;
        var responseData = response.responseData;

        if (message === "Success" && responseData) {
          if (!isCard) {
            self.setState({
              selectedCard: 0,
              mobileWishProcessMdl: false,
              mobileShopBagProcessMdl: false,
            });

            self.handleGetChatMessagesList(self.state.chatId);
          } else {
            self.setState({
              isCardSend: true,
              isMainLoader: false,
              mobileWishProcessMdl: false,
              mobileShopBagProcessMdl: false,
            });
          }
        } else {
          if (isCard) {
            self.setState({
              isCardSend: true,
              isMainLoader: false,
              mobileWishProcessMdl: false,
              mobileShopBagProcessMdl: false,
            });
            NotificationManager.error("Message Not Send Successfully.");
          }
        }
      })
      .catch((error) => {
        self.setState({ isMainLoader: false });
        console.log(error);
      });
  };
  //handle mobile action menu click
  handleMobileActionMenuClick = (e) => {
    if (e.key == 1) {
      this.handleUpdateStoreManagerChatStatus(3);
    }
  };
  /// handle Pin code change
  handlePinCodeCheck(e) {
    var reg = /^[0-9\b]+$/;

    if (!isNaN(e.target.value)) {
      this.setState({
        shippingPinCode: e.target.value,
        isPinCode: "",
        isPinCodeCheck: "",
      });
      if (e.target.value.length === 6) {
        this.handleCheckCourierAvailibilty(e.target.value);
      }
    } else {
      this.setState({ shippingPinCode: "", isPinCode: "", isPinCodeCheck: "" });
    }
  }
  //handle get check server data
  handleGetCheckServiceData() {
    let self = this;
    if (!this.state.storePinCode) {
      this.OrderService.Post("/HSOrder/GetStorePinCodeByUserID")
        .then((response) => {
          let status = response.message;
          let data = response.responseData;
          if (status === "Success") {
            self.setState({
              storePinCode: data,
            });
          } else {
            self.setState({
              storePinCode: "",
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }
  ///handle check couier availibilty
  handleCheckCourierAvailibilty = (shippingPinCode) => {
    let self = this;
    let inputData = {
      Pickup_postcode: parseInt(this.state.storePinCode),
      Delivery_postcode: parseInt(shippingPinCode),
    };
    this.OrderService.PostWithData(
      "/HSOrder/CheckCourierAvailibilty",
      inputData
    )
      .then((response) => {
        var message = response.message;
        var responseData = response.responseData;

        if (message === "Success" && responseData) {
          var available = responseData.available;
          var statusCode = responseData.statusCode;
          var state = responseData.state;
          if (available === "true" && statusCode === "200") {
            self.setState({ shippingState: state, isPinCodeCheck: "" });
          } else {
            self.setState({
              isPinCodeCheck: "Not Courier Availible",
            });
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  //handle chat modal close on header mane click
  handleChatModalCloseManuClick = () => {
    if (this.state.chatModal) {
      this.setState({ chatModal: false });
    }
  };
  //handle product no image
  handleProductNoImage = (e) => {
    return (e.target.src = NoImage);
  };
  handlePastChatPageChange = (e) => {
    this.setState({ pastChatPageNo: e });
    setTimeout(() => {
      this.handleGetAgentRecentChat(this.state.customerId);
    }, 10);
  };
  handleMessageDivScroll = (element) => {
    let topOff = document.getElementsByClassName("chatcontentDiv")[0].scrollTop;
    let heightOffvar =
      document.getElementsByClassName("chatcontentDiv")[0].scrollHeight;
    let heightPrent = (topOff / heightOffvar) * 100;

    if (topOff === 0) {
      if (this.state.isScrollMessage === true) {
        if (this.state.isCallChatMessgaeApi === false) {
          this.setState({
            isCallChatMessgaeApi: true,
            oldScrollHeight: heightOffvar,
          });

          this.handleGetChatMessagesList(this.state.chatId, 0, true);
          if (document.getElementsByClassName("chatcontentDiv")) {
            document.getElementsByClassName(
              "chatcontentDiv"
            )[0].scrollTop += 3500;
          }
        }
      }
    }
  };
  //handle get card configuration
  handleGetCardConfiguration() {
    let self = this;
    this.ChatService.Post("/CustomerChat/GetCardConfiguration")
      .then((response) => {
        var message = response.message;
        var responseData = response.responseData;
        if (message === "Success") {
          var cardConfigData = {};

          responseData.forEach((element) => {
            cardConfigData[element.cardItem] = element.isEnabled;
          });

          window.localStorage.setItem(
            "cardConfigData",
            JSON.stringify(cardConfigData)
          );
          self.setState({ cardConfigData });
        } else {
          self.setState({ cardConfigData: {} });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  handleSearchChatItemDetailsWB = () => {
    let self = this;
    var inputParam = {};
    if (this.state.cardSearchStoreCode) {
      inputParam.SearchText = this.state.searchItem;
      inputParam.ProgramCode = this.state.programCode;
      inputParam.StoreCode = this.state.storeCode;
    } else {
      inputParam.SearchText = this.state.searchItem;
      inputParam.ProgramCode = this.state.programCode;
    }
    this.setState({
      isCardSearch: true,
      isCardSend: false,
      isSelectedCard: false,
      selectedCard: 0,
    });

    this.ChatService.PostWithParams(
      "/CustomerChat/searchChatItemDetailsWB",
      inputParam
    )
      .then((response) => {
        var message = response.message;
        var searchCardData = response.responseData.items;
        self.setState({ isCardSearch: false });
        if (message == "Success" && searchCardData) {
          if (self.state.isMobileView) {
            self.setState({ cardModal: true });
          }
          searchCardData.forEach((element, i) => {
            element["itemID"] = i + 1;
            element["isChecked"] = false;
            // if (!self.state.cardConfigData.Brand) {
            //   element.brandName = "";
            // }
            // if (!self.state.cardConfigData.Color) {
            //   element.availableColor = [];
            // }
            // if (!self.state.cardConfigData.Category) {
            //   element.categoryName = "";
            // }

            // if (!self.state.cardConfigData.ImageURL) {
            //   element.imageUrl = "";
            // }
            // if (!self.state.cardConfigData.ItemCode) {
            //   element.itemCode = "";
            // }
            // if (!self.state.cardConfigData.ItemName) {
            //   element.name = "";
            // }
            // if (!self.state.cardConfigData.Price) {
            //   element.price = "";
            // }
            // if (!self.state.cardConfigData.Size) {
            //   element.sizeAvailability = [];
            // }
            // if (!self.state.cardConfigData.discount) {
            //   element.discount = "";
            // }
          });
          self.setState({
            isCardMobileUp: self.state.isMobileView ? true : false,
            searchCardData,
            noProductFound: "",
            tempCardSearch: self.state.searchItem,
            isDownbtn: self.state.isMobileView === false ? false : true,
          });
        } else {
          self.setState({
            searchCardData: [],
            noProductFound: "No Product Found",
          });
        }
      })
      .catch((error) => {
        self.setState({ isCardSearch: false });
        console.log(error);
      });
  };

  handleSuggestionTagModalClose = () => {
    this.setState({ suggestionTagModal: false });
  };
  handleRedireactToTicket = () => {
    document.getElementById("MobTicket").click();
  };
  AddWhiteIconOpen = () => {
    this.setState({ AddWhite: true });
  };
  AddWhiteIconClose = () => {
    this.setState({ AddWhite: false });
  };
  handlesuggestionConfModalOpen = () => {
    this.setState({ suggestionModalMob: true });
  };
  handleCloseProcessModal() {
    this.setState({
      mobileShopBagProcessMdl: false,
    });
  }
  handleOpenProcessModal(e) {
    e.stopPropagation();
    if (this.state.selectedShoppingBag.length > 0) {
      this.setState({
        mobileShopBagProcessMdl: true,
      });
    } else {
      return false;
    }
  }
  // Shoppingcartmodal
  handleOpenShoppingCartModal(e) {
    e.stopPropagation();
    if (this.state.selectedShoppingCart.length > 0) {
      this.setState({
        mobileShopCartProcessMdl: true,
      });
    } else {
      return false;
    }
  }

  handleCloseShoppingCartModal() {
    this.setState({
      mobileShopCartProcessMdl: false,
    });
  }

  handleCloseWishListModal() {
    this.setState({
      mobileWishProcessMdl: false,
    });
  }
  handleOpenWishListModal(e) {
    e.stopPropagation();
    if (this.state.selectedWishList.length > 0) {
      this.setState({
        mobileWishProcessMdl: true,
      });
    } else {
      return false;
    }
  }
  handleCloseRecommendedListModal() {
    this.setState({
      mobileRecommendProcessMdl: false,
      mobileChatActive: 0,
    });
  }
  handleOpenRecommendedListModal(e) {
    e.stopPropagation();
    if (this.state.selectedRecommended.length > 0) {
      this.setState({
        mobileRecommendProcessMdl: true,
      });
    } else {
      return false;
    }
  }
  handleMobileCollpseChange(key) {
    this.state.shoppingBagData.forEach((element) => {
      element.isCheck = false;
    });
    this.state.wishListData.forEach((element) => {
      element.isCheck = false;
    });
    this.state.recommendedData.forEach((element) => {
      element.isCheck = false;
    });
    this.setState({
      shoppingBagData: this.state.shoppingBagData,
      recommendedData: this.state.recommendedData,
      wishListData: this.state.wishListData,
      selectedShoppingBag: [],
      selectedWishList: [],
      selectedRecommended: [],
      mobileCollpseActive: [key],
    });
    if (key) {
      if (Number(key) === 1) {
        this.setState({ productTypeTab: 0 });
      }
      if (Number(key) === 2) {
        this.setState({ productTypeTab: 1 });
      }
      if (Number(key) === 3) {
        this.setState({ productTypeTab: 2 });
      }
    } else {
      this.setState({ productTypeTab: 0 });
    }
  }
  /// handle product no image
  handleMobileProductNoImage = (e) => {
    return (e.target.src = Shoppingbag);
  };
  /// handle check tab for mobile
  handleCheckTabMobile(tab) {
    this.setState({
      mobileChatActive: tab,
    });
  }
  /// handle FinalProcess submit data
  handleFinalProcessToOrder(tab) {
    if (tab === "shoppingbag") {
      if (this.state.mobileChatActive === 1) {
        this.handleSendProductsOnChat(false, "", "", "", null);
      } else if (this.state.mobileChatActive === 2) {
        this.handleRemoveProduct(Number(this.state.uniqueItemID), "S");
      } else if (this.state.mobileChatActive === 3) {
        this.handleAddProductsToBagOrWishlist("shoppingbag");
      }
    } else if (tab === "wishlist") {
      if (this.state.mobileChatActive === 1) {
        this.handleSendProductsOnChat(false, "", "", "", null);
      } else if (this.state.mobileChatActive === 2) {
        this.handleRemoveProduct(Number(this.state.uniqueItemID), "W");
      } else if (this.state.mobileChatActive === 3) {
        this.handleAddProductsToBagOrWishlist("wishlist");
      }
    } else {
      if (this.state.mobileChatActive === 1) {
        this.handleSendProductsOnChat(false, "", "", "", null);
      } else if (this.state.mobileChatActive === 2) {
        // this.handleRemoveProduct(Number(this.state.uniqueItemID), "R");
      } else if (this.state.mobileChatActive === 3) {
        this.handleAddProductsToBagOrWishlist("recommended");
      }
    }
  }
  handleFileUploading = async (e) => {
    var fileArray = this.state.AttachementFiles;
    var selectedFiles = e;
    var fileSize = e;
    if (e.length === 0) {
      NotificationManager.error(
        "Only JPG, JPEG, PNG, PDF, PPT, TXT & DOC files are allowed."
      );
    }
    for (let i = 0; i < selectedFiles.length; i++) {
      if (
        !selectedFiles[i].name.match(
          /(\.jpg|\.jpeg|\.png|\.webp|\.pdf|\.doc|\.xlsx|\.docx|\.pptx|\.ppt|\.txt)$/i
        )
      ) {
        NotificationManager.error(
          "Only JPG, JPEG, PNG, PDF, PPT, TXT & DOC files are allowed."
        );
      } else {
        var Maxsize = 1024 * 5;
        if (Maxsize * 1000 < fileSize[i].size) {
          NotificationManager.error(
            "File too Big, please select a file less than 5MB."
          );
        } else {
          var file = selectedFiles[i];
          if (
            selectedFiles[i].name.match(
              /(\.jpg|\.jpeg|\.png|\.webp)$/i
            )
          ) {
            var options = {
              maxSizeMB: 1,
              maxWidthOrHeight: 1920,
              useWebWorker: true
            }
            // let fileData = await imageCompression(imageFile, options);
            await imageCompression(file, options)
              .then(compressedBlob => {
                //console.log(compressedBlob)
                compressedBlob.lastModifiedDate = new Date();
                const convertedBlobFile = new File([compressedBlob], file.name, { type: file.type, lastModified: Date.now() });
                fileArray.push(convertedBlobFile);
              })
              .catch(e => {
              })
          } else {
            fileArray.push(selectedFiles[i]);
          }
          this.setState({
            AttachementFiles: fileArray,
          });
        }
      }
    }
  }
  handleFileUploadingMobile = async (e) => {
    var fileArray = this.state.AttachementFiles;
    var selectedFiles = e.target.files;
    var fileSize = e.target.files;
    this.setState({ mobileFileupload: false });
    for (let i = 0; i < selectedFiles.length; i++) {
      if (
        !selectedFiles[i].name.match(
          /(\.jpg|\.jpeg|\.png|\.webp|\.pdf|\.doc|\.xlsx|\.docx|\.pptx|\.ppt|\.txt)$/i
        )
      ) {
        NotificationManager.error(
          "Only JPG, JPEG, PNG, PDF, PPT, TXT & DOC files are allowed."
        );
      } else {
        var Maxsize = 1024 * 5;
        if (Maxsize * 1000 < fileSize[i].size) {
          NotificationManager.error(
            "File too Big, please select a file less than 5MB."
          );
        } else {
          var file = selectedFiles[i];
          if (
            selectedFiles[i].name.match(
              /(\.jpg|\.jpeg|\.png|\.webp)$/i
            )
          ) {
            var options = {
              maxSizeMB: 1,
              maxWidthOrHeight: 1920,
              useWebWorker: true
            }
            // let fileData = await imageCompression(imageFile, options);
            await imageCompression(file, options)
              .then(compressedBlob => {
                //console.log(compressedBlob)
                compressedBlob.lastModifiedDate = new Date();
                const convertedBlobFile = new File([compressedBlob], file.name, { type: file.type, lastModified: Date.now() });
                fileArray.push(convertedBlobFile);
              })
              .catch(e => {
              })
          } else {
            fileArray.push(selectedFiles[i]);
          }
          this.setState({
            AttachementFiles: fileArray,
          });
        }
      }
    }
    //e.target.value = "";
  }

  handleShowSearch = () => {
    if (this.state.showSearch) {
      this.setState({ showSearch: false, searchChat: "" });
      setTimeout(() => {
        this.handleGetOngoingChat();
      }, 200);
    } else {
      this.setState({ showSearch: true });
    }
  };
  handleOpenFileShow() {
    this.setState({
      fileShowMdl: true,
    });
  }

  handleFileClose() {
    this.setState({
      fileShowMdl: false,
    });
  }
  handleCancelFile(i) {
    if (this.state.AttachementFiles.length > 1) {
      let AttachementFiles = [...this.state.AttachementFiles];
      AttachementFiles.splice(i, 1);
      this.setState({ AttachementFiles });
    } else {
      this.setState({
        fileShowMdl: false,
        AttachementFiles: [],
      });
    }
  }
  handleSendAttachmentFileOnChatAPI = () => {
    var self = this;
    if (this.state.isAttachmentMainLoader) {
      return false;
    }
    for (let i = 0; i < this.state.AttachementFiles.length; i++) {
      this.setState({ isAttachmentMainLoader: true });
      var attachmentMessage = "";
      if (this.state.message) {
        attachmentMessage = this.state.message;
      } else {
        attachmentMessage = this.state.suggestionText;
      }
      var formData = new FormData();
      formData.append("Attachment", this.state.AttachementFiles[i]);
      formData.append("AttachmentMessage", attachmentMessage);
      formData.append("ChatSource", this.state.sourceType);
      formData.append("ChatID", this.state.chatId);
      formData.append("CustomerMobileNo", this.state.mobileNo ? this.state.mobileNo : encryption(window.localStorage.getItem("customerNum"), 'decr'));
      this.ChatService.PostWithData(
        "/CustomerChat/SendAttachmentOnChat",
        formData
      )
        .then((response) => {
          var msg = response.message;
          if (msg === "Success" && response.responseData) {
            var attachmentSendCount = self.state.attachmentSendCount + 1;
            var messageData = self.state.messageData;
            var objMessgage = {};
            objMessgage.isBotReply = false;
            objMessgage.chatDate = "Today";
            objMessgage.chatTime = new Date().toLocaleTimeString();
            objMessgage.byCustomer = false;
            objMessgage.message = attachmentMessage;
            objMessgage.isAttachment = true;
            objMessgage.attachment = response.responseData;
            messageData.push(objMessgage);
            self.setState({
              attachmentSendCount,
              messageData,
              isScroll: true,
            });

            if (self.state.sourceType === "wb") {
              var ObjDoc = {};
              var dataObj = {};
              ObjDoc.attachmentUrl = response.responseData;
              ObjDoc.attachmentType = self.state.AttachementFiles[i].name.match(
                /(\.pdf|\.doc|\.docx|\.pptx|\.ppt|\.xlsx|\.txt)$/i
              )
                ? "Document"
                : "Image";
              ObjDoc.Text = attachmentMessage;
              dataObj = {
                isFromAgent: true,
                chatId: self.state.chatId,
                StoreMgr_ID: self.state.sAgentId,
                userMaster_ID: self.state.AgentID,
                ProgramCode: self.state.programCode,
                StoreCode: self.state.storeCode,
                Source: self.state.sourceType,
                MobileNo: self.state.mobileNo,
                MessageData: attachmentMessage,
                isCard: false,
                cardItem: null,
                isAppointment: false,
                appointmentData: null,
                isAttachment:
                  self.state.AttachementFiles.length > 0 ? true : false,
                attachmentData: ObjDoc,
                sentAt:
                  new Date().getMonth() +
                  1 +
                  "/" +
                  new Date().getDate() +
                  "/" +
                  new Date().getFullYear() +
                  " " +
                  new Date().getHours() +
                  ":" +
                  new Date().getMinutes() +
                  ":" +
                  new Date().getSeconds(),
                HeaderToken: authHeader()["X-Authorized-Token"],
                isSuccess: true,
                message: "OK",
                appVersion: "2.0",
              };
              socket.emit("SendBellReplyV2", dataObj); ///web bot
            }
            if (attachmentSendCount === self.state.AttachementFiles.length) {
              self.setState({
                isAttachmentMainLoader: false,
                AttachementFiles: [],
                message: "",
                suggestionText: "",
                attachmentSendCount: 0,
              });
            } else {
              self.setState({
                isAttachmentMainLoader: true,
              });
            }
          } else {
            var attachmentSendCount = self.state.attachmentSendCount + 1;
            self.setState({
              isAttachmentMainLoader: false,
              attachmentSendCount,
            });
            NotificationManager.error("Attachment sending failed.");
          }
        })
        .catch((error) => {
          NotificationManager.error("Attachment sending failed.");
          self.setState({ isAttachmentMainLoader: false });
          console.log(error);
        });
    }
  };

  handleHideSuggetionWeb = () => {
    this.setState({
      isCheckSuggetion: false,
      messageSuggestionData: [],
      messageSuggestionTagsData: [],
    });
  };
  handleSearchChatItemDetailsButton = () => {
    if (this.state.sourceType === "cb" && !this.state.isAddToCardEnableForCB) {
      this.handleSearchChatItemDetails();
    } else {
      this.handleSearchChatItemDetailsWB();
    }
  };
  //handle get store agent details by agent id
  handleGetStoreAgentDetailsById(agentID) {
    let self = this;
    var checkStoreAgentData =
      window.localStorage.getItem("StoreAgentDetails") || "";
    if (checkStoreAgentData === "" || checkStoreAgentData === null) {
      let inputParams = {
        AgentID: agentID,
      };
      this.SettingsService.PostWithParams(
        "/HSSetting/GetStoreAgentDetailsById",
        inputParams
      )
        .then((response) => {
          let status = response.message;
          let data = response.responseData;
          if (status === "Success") {
            window.localStorage.setItem(
              "StoreAgentDetails",
              JSON.stringify(data)
            );
            self.setState({ storeAgentDetail: data });
          } else {
            self.setState({ storeAgentDetail: [] });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      var checkStoreAgentData =
        window.localStorage.getItem("StoreAgentDetails") || "";
      self.setState({ storeAgentDetail: JSON.parse(checkStoreAgentData) });
    }
  }
  //handle button down click
  handleDownButtonClick() {
    this.setState({ isDownbtn: !this.state.isDownbtn });
  }
  //handle scroll right
  handleScrollRight(num) {
    document.getElementById("schedule-btn-cntr" + num).scrollLeft += 20;
  }
  //handle scroll left
  handleScrollLeft(num) {
    document.getElementById("schedule-btn-cntr" + num).scrollLeft -= 20;
  }
  handleCardModalClose = () => {
    this.setState({ cardModal: false });
  };
  handleBackClickMobile = () => {
    this.setState({ customerName: "", chatId: 0 });
    localStorage.setItem("isOngoingClick", false);
    setTimeout(() => {
      var objNewChat = {
        userMaster_ID: this.state.AgentID,
        tenant_ID: this.state.tenantID,
        ProgramCode: this.state.programCode,
        ChatId: 0,
      };
      socket.emit("CallSetCurrentChatSP", objNewChat);
    }, 2000);
    this.handleGetOngoingChat();
  };
  handleChatNotificationDetails() {
    let self = this;
    var objNotificationData = {
      userMaster_ID: this.state.AgentID,
      tenant_ID: this.state.tenantID,
      ProgramCode: this.state.programCode,
      PageNo: this.state.NotificationPage,
      StoreCode: this.state.storeCode,
    };

    socket.emit("CallChatNotificationDetialsSP", objNotificationData);
    socket.once(
      "CallChatNotificationDetialsSP" +
      this.state.storeCode.toLowerCase() +
      this.state.programCode.toLowerCase(),
      function (data) {
        if (data[0].length > 0) {
          document.getElementById("chatNotificationMomCount").innerText =
            data[1][0].UnReadNotiCount;
        } else {
          document.getElementById("chatNotificationMomCount").innerText = 0;
        }
      }
    );
  }
  handleChatCollapsePanelChange = (e) => {
    if (e.length > 0) {
      var chatTypeCollpase = e[e.length - 1];
      if (chatTypeCollpase == 1) {
        if (this.state.newChatsData.length > 0) {
          this.state.chatTypeCollpase[0] = chatTypeCollpase;
          this.setState({
            chatTypeCollpase: this.state.chatTypeCollpase,
          });
        }
      } else {
        this.state.chatTypeCollpase[0] = chatTypeCollpase;
        this.setState({
          chatTypeCollpase: this.state.chatTypeCollpase,
        });
      }
    } else {
      this.state.chatTypeCollpase[0] = 2;
      this.setState({
        chatTypeCollpase: this.state.chatTypeCollpase,
      });
    }
  };
  handleBackToCurrentChat = () => {
    this.setState({
      mainTabSelect: 1,
      showHistoricalChat: false,
      rowChatId: 0,
    });
  };

  handleAutoCorrection = () => {
    // console.log(this.state.mobileNo, this.state.chatId, "this.state.mobileNo;");
    this.setState({ showAutomaticTextOption: false });
    if (
      this.state.isgrammarlyCheck &&
      this.state.storeAgentDetail.length > 0 &&
      this.state.storeAgentDetail[0].grammarlyCheck === 1
    ) {
      if (this.state.message.length > 0) {
        var inputParam = {};
        inputParam.programCode = this.state.programCode;
        inputParam.text = this.state.message;
        inputParam.storeCode = this.state.storeCode;
        inputParam.MobileNo = this.state.mobileNo ? this.state.mobileNo : encryption(window.localStorage.getItem("customerNum"), 'decr');
        inputParam.SourceType = this.state.sourceType;
        socket.emit("CallAutoCorrectionWrapper", inputParam);
        this.setState({ selectedSuggested_Words: {} });
        let self = this;
        socket.once(
          "CallAutoCorrectionWrapper" +
          this.state.storeCode.toLowerCase() +
          this.state.programCode.toLowerCase(),
          function (data) {
            if (data) {
              // if (data.Profane_Words.length > 0) {
              if (data.Profane_Word) {
                var Profane_Word_Temp = [];
                if (typeof data.Profane_Word === "object") {
                  Profane_Word_Temp = data.Profane_Word;
                } else {
                  Profane_Word_Temp = data.Profane_Word.split(",");
                }

                var profane_Words = [];
                Profane_Word_Temp.forEach((element) => {
                  var objProfan = {};
                  objProfan.Key = element;
                  objProfan.value = element;
                  objProfan.isChecked = false;
                  profane_Words.push(objProfan);
                });

                self.setState({ Profane_Words: profane_Words });
              } else {
                self.setState({ Profane_Words: [] });
              }
              self.setState({ Suggested_Words: data.Suggested_Word });

              if (
                data.Profane_Word.length === 0 &&
                Object.keys(data.Suggested_Word).length === 0
              ) {
                self.handleSendMessageToCustomer(
                  self.state.message,
                  "",
                  "",
                  null
                );
                if (self.state.isMobileView) {
                  self.setState({ grammarlyModal: false });
                }
              } else {
                if (self.state.isMobileView) {
                  self.setState({ grammarlyModal: true });
                } else {
                  if (document.getElementById("propoversugg")) {
                    document.getElementById("propoversugg").click();
                  }
                }
              }
            }
          }
        );
      } else {
        this.handleSendMessageToCustomer(this.state.message, "", "", null);
      }
    } else {
      this.handleSendMessageToCustomer(this.state.message, "", "", null);
    }
  };

  handleSuggetionWordClick = (key, value) => {
    if (this.state.Profane_Words.length > 0) {
      return false;
    }
    var selectedSuggested_Words = this.state.selectedSuggested_Words;
    selectedSuggested_Words[key] = value;
    var replaceMessageVal = "";
    var wordKey = Object.keys(selectedSuggested_Words);
    var finalReplaceMessageVal = "";
    if (this.state.replaceMessageVal) {
      replaceMessageVal = this.state.replaceMessageVal;
    } else {
      replaceMessageVal = this.state.message;
    }
    for (let i = 0; i < wordKey.length; i++) {
      var tempkey = wordKey[i];
      var tempvalue = selectedSuggested_Words[wordKey[i]];

      if (value) {
        finalReplaceMessageVal = replaceAll(
          replaceMessageVal,
          tempkey,
          tempvalue
        );
      }
    }
    this.setState({
      replaceMessageVal: finalReplaceMessageVal,
      selectedSuggested_Words,
    });
  };
  handleGrammarlyModalClose = () => {
    this.setState({
      grammarlyModal: false,
    });
  };

  handleGrammarlyModalOpen = () => {
    this.setState({
      grammarlyModal: true,
    });
  };
  handleGrammarlyApply = async () => {
    if (this.state.replaceMessageVal && this.state.Profane_Words.length === 0) {
      await this.setState({ message: this.state.replaceMessageVal });
      this.handleSendMessageToCustomer(
        this.state.replaceMessageVal,
        "",
        "",
        null
      );
      this.setState({
        Suggested_Words: {},
        selectedSuggested_Words: {},
        Profane_Words: [],
        replaceMessageVal: "",
        grammarlyModal: false,
      });
    }
  };
  handleGrammarlyIgnore = () => {
    if (this.state.Profane_Words.length === 0) {
      this.handleSendMessageToCustomer(this.state.message, "", "", false);
      this.setState({
        Suggested_Words: {},
        selectedSuggested_Words: {},
        Profane_Words: [],
        replaceMessageVal: "",
        grammarlyModal: false,
      });
    }
  };
  handleGetUserProfileData() {
    let self = this;
    this.SettingsService.Post("/StoreUser/GetStoreUserProfileDetail")
      .then((response) => {
        var status = response.message;
        var data = response.responseData;
        if (status === "Success") {
          window.localStorage.setItem("UserData", JSON.stringify(data));
          self.setState({
            storeName: data.storeName,
            storeAddress: data.storeAddress,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  handleSuggetionTypeChange = (e) => {
    this.setState({
      suggestionType: e.target.value,
    });
  };
  handleProfannitySelect = (e) => {
    this.state.Profane_Words.filter((x) => x.value === e)[0].isChecked = true;
    this.setState({ Profane_Words: this.state.Profane_Words });
  };
  handleRemoveProfanity = async () => {
    if (
      this.state.Profane_Words.filter((x) => x.isChecked === true).length > 0
    ) {
      var message = this.state.message;
      for (let i = 0; i < this.state.Profane_Words.length; i++) {
        if (this.state.Profane_Words[i].isChecked) {
          message = replaceAll(message, this.state.Profane_Words[i].value, "");
        }
      }
      await this.setState({
        message,
      });
      if (document.getElementById("propoversugg") && !this.state.isMobileView) {
        document.getElementById("propoversugg").click();
      }
      this.handleAutoCorrection();
    }
  };
  /// handle close image modal
  handleImgMdlClose() {
    this.setState({
      imgModerationMdl: false,
      selectCardColor: 0,
      selectColorCompulsory: "",
    });
  }

  handleOpenAttacment = (url, isImage) => {
    if (isImage) {
      this.setState({
        attachementViewModal: true,
        attachmentUrl: url,
        attachmentDocUrl: "",
      });
    } else {
      this.setState({
        attachementViewModal: true,
        attachmentDocUrl: url,
        attachmentUrl: "",
      });
    }
  };
  handleAttachementViewModalClose = () => {
    this.setState({
      attachementViewModal: false,
      attachmentDocUrl: "",
      attachmentUrl: "",
    });
  };
  handleAttachementdownload = () => {
    if (this.props.isCallRecived) {
      // window.open(this.state.attachmentDocUrl);
      let index = this.state.attachmentDocUrl.lastIndexOf("/");
      let result = this.state.attachmentDocUrl.substring(index + 1);
      window.getCallStatus.downloadAttachment(this.state.attachmentDocUrl, result);
    }
    else {
      const link = document.createElement("a");
      link.href = this.state.attachmentDocUrl;
      link.setAttribute("download", this.state.attachmentDocUrl.split("/").pop());
      document.body.appendChild(link);
      link.click();
    }
    // window.open(this.state.attachmentDocUrl);
    // const link = document.createElement("a");
    // link.href = this.state.attachmentDocUrl;
    // link.setAttribute("download", this.state.attachmentDocUrl.split("/").pop());
    // document.body.appendChild(link);
    // link.click();
  };

  handleSelectVideoCallApppintmentDate = (e) => {
    let selectedDate = e.target.value;
    this.setState({
      selectedAppointmentDate: selectedDate,
    });
  };

  // handle automatic suggestion of message on typing.
  handleSuggestionOnTyping = (textMessage) => {
    let inputParams = {
      SearchText: textMessage,
    };
    this.ChatService.GetWithParams(
      "/CustomerChat/getChatSuggestionsNew",
      inputParams
    )
      .then((response) => {
        let status = response.message;
        if (status === "Success") {
          //console.log("response on chat", response);
          this.setState({
            chatSuggestionValue: response.responseData,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //To handle the automatic message selected value
  handleAutomaticMessageSelected = (optionSelected) => {
    //console.log("optionSelected", optionSelected);
    if (optionSelected) {
      this.setState({
        message: optionSelected.suggestionText,
        showAutomaticTextOption: false,
      });
    }
  };

  //To handle the change in selected option
  changeSelectedLi = (e) => {
    const { selectedCursorValue, automaticChatSuggestion } = this.state;
    if (e.keyCode === 38 && selectedCursorValue > 0) {
      this.setState({
        selectedCursorValue: this.state.selectedCursorValue - 1,
      });
    } else if (
      e.keyCode === 40 &&
      selectedCursorValue < automaticChatSuggestion.length - 1
    ) {
      this.setState({
        selectedCursorValue: this.state.selectedCursorValue + 1,
      });
    }

    if (e.keyCode === 13 && selectedCursorValue >= 0) {
      this.handleAutomaticMessageSelected(
        automaticChatSuggestion[selectedCursorValue]
      );
    }

    if (/^\s*$/.test(this.state.message)) {
      this.setState({
        showAutomaticTextOption: false,
      });
    }
  };

  //To handle the suggestion option list
  handleSuggestionListClick = async () => {
    await this.setState({
      showAutomaticTextOption: true,
    });
    if (this.state.showAutomaticTextOption) {
      document.addEventListener(
        "click",
        this.handleSuggestionListClickOutside,
        false
      );
    }
  };

  //To handle the click outside suggestion option list
  handleSuggestionListClickOutside = (e) => {
    if (this.suggestionUlRef && this.suggestionUlRef.contains(e.target)) {
      return;
    } else {
      document.removeEventListener(
        "click",
        this.handleSuggestionListClickOutside,
        false
      );
      this.setState({
        showAutomaticTextOption: false,
      });
    }
  };

  //To handle video call appointment notification
  handleVideoCallAppointmentNotification = (
    counter,
    customerContactNumber,
    remaingCallTimeToNotify,
    customerName
  ) => {
    //console.log(this.state.newMessageSoundFile);
    notification.open({
      key: 10,
      duration: this.state.notificationTime,
      placement: "bottomRight",
      // message: "Notification Title",
      className: "",
      description: (
        <ChatNotificationComponent
          msgData={
            "You have video call appointment with " +
            customerName +
            " in " +
            remaingCallTimeToNotify +
            " having contact number " +
            customerContactNumber
          }
          chatData={customerContactNumber}
          handleChatNotificationClick={(e) => this.handleNotificationClick(e)}
          notitficationType={"appointmentNotification"}
        />
      ),
    });

    this.checkAppointmentNotification((counter = counter + 1));
  };

  //To call appointment notification on web
  checkAppointmentNotification = (counter) => {
    //console.log("appointMentData ", this.state.appointMentData);
    // let totalTimeSlot = [
    //   { time: 'Tue Nov 09 2021 17:40:32', remaingCallTimeToNotify: 1, customerContactNumber: 321456789 },
    //   { time: 'Tue Nov 09 2021 17:48:32', remaingCallTimeToNotify: 4, customerContactNumber: 432156789 },
    //   { time: 'Tue Nov 09 2021 17:50:32', remaingCallTimeToNotify: 2, customerContactNumber: 543216789 },
    //   { time: 'Tue Nov 09 2021 17:55:32', remaingCallTimeToNotify: 5, customerContactNumber: 678906789 }
    // ]
    let totalTimeSlot = this.state.appointMentData;

    if (counter < totalTimeSlot.length) {
      let timeToCall = totalTimeSlot[counter];
      let currentTime = new Date();
      // let selectedTime = new Date(timeToCall.time);
      let selectedTime = new Date(timeToCall.appointmentDateTime);
      // currentTime.setMinutes(currentTime.getMinutes() + timeToCall.remaingCallTimeToNotify);
      currentTime.setMinutes(
        currentTime.getMinutes() +
        (timeToCall.reminderTime + "").replace(/[^0-9]/g, "")
      );
      // console.log("selected time==>", selectedTime);
      // console.log("current time==>", currentTime);

      if (totalTimeSlot.length > 0) {
        if (currentTime <= selectedTime && selectedTime != "Invalid Date") {
          //call notification
          setTimeout(() => {
            // this.handleVideoCallAppointmentNotification(counter, timeToCall.customerContactNumber, timeToCall.remaingCallTimeToNotify);
            this.handleVideoCallAppointmentNotification(
              counter,
              timeToCall.mobileNo,
              timeToCall.reminderTime,
              timeToCall.customerName
            );
          }, selectedTime - currentTime);
        } else {
          //console.log("item value");
          this.checkAppointmentNotification((counter = counter + 1));
        }
      }
    }
  };

  //To get all the notification with store id
  getVideoCallAppointmentData = (store_id) => {
    let inputParams = {
      StoreID: store_id,
    };
    this.ChatService.PostWithParams(
      "/Appointment/GetAppointmentReminders",
      inputParams
    )
      .then((response) => {
        let status = response.message;
        if (status === "Success") {
          //console.log("response on chat", response);
          this.setState({
            appointMentData: response.responseData,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //To handle the copied button icon
  handleCopiedNumber = (e, contact_number, copiedTextChatId) => {
    let numberValue = encryption(contact_number, "enc");
    window.localStorage.setItem("copiedNumber", numberValue);
    this.setState({
      isNumberCopiedClicked: true,
      isTextCopied: true,
      copiedTextChatId: copiedTextChatId,
    });
    this.state.isNumberCopiedClicked = true;
    setTimeout(() => {
      this.setState({
        isNumberCopiedClicked: false,
        isTextCopied: false,
      });
    }, 3000);
  };

  //handle message itemcode
  handleCopiedCode = (e, searchText, i) => {
    this.setState({
      CopiedItemCode: searchText, isItemCodeCopied: true, ItemCodeKey: i
    })
    window.localStorage.setItem("CopiedItemCode", searchText);
    setTimeout(() => {
      this.setState({
        isItemCodeCopied: false,
      });
    }, 3000);
  }

  handleSearchProductById = async (e, serchtext) => {
    this.setState({ isDownbtn: true, activeTab: 2, isScroll: true });
    await this.setState({ searchItem: serchtext });
    await this.handleSearchChatItemDetailsWB();
  }

  handlePasteItmCode = async () => {
    if (this.state.CopiedItemCode.length > 0) {
      var textmessage = this.state.message + " " + this.state.CopiedItemCode + " ";
      this.setState({ message: textmessage });
    }
  }
  //To handle paste Icon
  handlePasteNumber = async () => {
    let self = this;
    let number = window.localStorage.getItem("copiedNumber");
    let decryptnumber = encryption(number, "decr");
    await self.setState({
      copiedNumber: decryptnumber,
      isNumberPaste: true,
    });
    //console.log(decryptnumber, "Number");
    let muskedNumber = handleMaskingNumber(
      decryptnumber,
      this.state.maskingPrefix,
      this.state.maskingSufix
    );
    self.setState({
      searchChat: muskedNumber,
    });
    this.handleGetOngoingChat();
    return muskedNumber;
  }
  //handle crm role data
  handleCRMRole() {
    this.LoginService.Post("/StoreCRMRole/GetStoreRolesByUserID")
      .then((response) => {
        let msg = response.message;
        if (msg === "Success") {
          let maskedNum = ""
          if (window.localStorage.getItem("customerNum") !== null) {
             maskedNum = handleMaskingNumber(encryption(window.localStorage.getItem("customerNum"), 'decr'), response.responseData.maskingPrefix, response.responseData.maskingSufix)
          }
          console.log(maskedNum, "masked");
          this.setState({
            isNumberMasking: response.responseData.isNumberMasking,
            maskingPrefix: response.responseData.maskingPrefix,
            maskingSufix: response.responseData.maskingSufix,
            tenantMobileMasking: response.responseData.tenantLevelMasking,
            isSuggestionText: response.responseData.suggestionText,
            maskedNumber: maskedNum
          }, () => {
            this.handleGetChatCustomerProfile(this.state.mobileNo)
          });
          this.props.setIsVideoCallEnable(response.responseData.isVideoEnable)
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  //To close the video call appointment screen in mobile view
  handleVideoCallAppointmentDisplayInMobile = () => {
    this.handleTabClick(1);
    this.setState({
      isVideoCallBookedTabCloseInMobile: false,
    });
  };

  /// handle Get Category Data for drop-down
  handleGetCategoryList() {
    let self = this;
    this.ChatService.Post("/HSChatTicketing/GetChatCategory")
      .then((response) => {
        let Msg = response.message;
        let data = response.responseData;
        if (Msg === "Success") {
          self.setState({
            CategoryData: data,
          });
        } else {
          self.setState({
            CategoryData: [],
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  /// handle Get Sub Category Data for drop-down
  handleGetSubCategoryList() {
    let self = this;
    let inputParams = {
      categoryID: this.state.categoryID,
    };
    this.ChatService.PostWithParams(
      "/HSChatTicketing/GetChatSubCategoryByCategoryID",
      inputParams
    )
      .then((response) => {
        let Msg = response.message;
        let data = response.responseData;
        if (Msg === "Success") {
          self.setState({
            SubCategoryData: data,
          });
        } else {
          self.setState({
            SubCategoryData: [],
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  /// handle Get Issue Type list for drop-down
  handleGetIssueTypeList() {
    let self = this;
    let inputParams = {
      subCategoryID: this.state.subCategoryID,
    };
    this.ChatService.PostWithParams(
      "/HSChatTicketing/GetChatIssueTypeBySubcategory",
      inputParams
    )
      .then((response) => {
        let Msg = response.message;
        let data = response.responseData;
        if (Msg === "Success") {
          self.setState({
            IssueTypeData: data,
          });
        } else {
          self.setState({
            IssueTypeData: [],
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  handleGetPriorityList() {
    let self = this;
    let inputParams = 0;
    this.ChatService.GetWithParams("/StorePriority/PriorityList", inputParams)
      .then((response) => {
        let status = response.message;
        let data = response.responseData;
        if (status === "Success" && data !== undefined) {
          self.setState({ priorityData: data, });
          console.log(this.state.priorityData);
        } else {
          self.setState({
            priorityData: [],
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  handleCategoryChange = (e) => {
    let value = parseInt(e.target.value);
    this.setState({ categoryID: value, SubCategoryData: [] });
    setTimeout(() => {
      if (this.state.categoryID) {
        this.handleGetSubCategoryList();
      }
    }, 1);
  };

  handleSubCategoryChange = (e) => {
    let value = parseInt(e.target.value);
    this.setState({ subCategoryID: value, IssueTypeData: [] });
    setTimeout(() => {
      if (this.state.categoryID) {
        this.handleGetIssueTypeList();
      }
    }, 1);
  };

  handleIssueTypeChange = (e) => {
    let value = parseInt(e.target.value);
    this.setState({ issueTypeID: value });
  };

  handlePriorityChange = (e) => {
    let value = parseInt(e.target.value);
    this.setState({ PriorityID: value });
  };

  hanldeOnChangeData = async (e) => {
    await this.setState({
      [e.target.name]: e.target.value,
    });
    //console.log(this.state.TicketDetails);
  };

  //handle Create Ticket
  handleCreateTicket = async () => {
    let self = this;
    if (self.state.issueTypeID > 0 && self.state.categoryID > 0 && self.state.PriorityID > 0) {
      if (self.state.TicketTitle.length > 5) {
        // debugger;
        if (self.state.TicketDetails.length > 10) {
          let inputParams = {
            ChatID: self.state.chatId,
            CustomerMobile: self.state.mobileNo,
            TicketTitle: self.state.TicketTitle,
            TicketDetails: self.state.TicketDetails,
            CategoryID: self.state.categoryID,
            SubCategoryID: self.state.subCategoryID,
            IssueTypeID: self.state.issueTypeID,
            PriorityID: self.state.PriorityID
          };
          await this.ChatService.PostWithData("/CustomerChat/CreateTicekt", inputParams)
            .then((response) => {
              let Msg = response.message;
              if (Msg === "Success") {
                self.setState({
                  TicketTitle: "",
                  TicketDetails: "",
                  categoryID: 0,
                  subCategoryID: 0,
                  issueTypeID: 0,
                  PriorityID: 0,
                  GeneratedTicketId: response.responseData,
                  activeCollapse: 0,
                });
                this.handleGetTicketListByMobile();
                NotificationManager.success(
                  "Ticket Created successfully."
                );
              } else {
                NotificationManager.error(
                  "Ticket Not Created."
                );
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          this.setState({
            CreateTicketDetailValidation: "Please Enter Ticket Details.",
          });
        }
      } else {
        this.setState({
          CreateTicketTitleValidation: "Please Enter Title.",
        });
      }
    }
    else {
      NotificationManager.error(
        "Please select Category, SubCategory,Priority and IssueType!!!"
      );
    }
  }

  //handle get Ticket list
  handleGetTicketListByMobile = async () => {
    let inputParams = {
      CustomerMobile: this.state.mobileNo ? this.state.mobileNo : encryption(window.localStorage.getItem("customerNum"), 'decr'),
    };
    await this.ChatService.PostWithParams("/CustomerChat/GetManualChatTicketsByID", inputParams)
      .then((response) => {
        let msg = response.message;
        if (msg === "Success") {
          this.setState({
            TicketList: response.responseData
          });
          //console.log("TicketList: ",this.state.TicketList)
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  //handle send ShoppingCart HSM
  handleSendCartHSM = async () => {
    let self = this;
    //let itemList = [];
    if (self.state.cartItem.length > 0) {
      // for (let i = 0; i < self.state.cartItem.length; i++) {
      //   itemList.push(self.state.cartItem[i].name);
      // }
      let inputParams = {
        ChatID: self.state.chatId,
        MobileNumber: self.state.mobileNo ? this.state.mobileNo : encryption(window.localStorage.getItem("customerNum"), 'decr'),
        CustomerName: self.state.customerName,
        Items: this.state.cartItem,
        StoreCode: self.state.storeCode
      };

      await this.ChatService.PostWithData("/WebBot/SendWebBotCartHSM", inputParams)
        .then((response) => {
          let Msg = response.message;
          if (Msg === "Success") {
            NotificationManager.success(
              "Shopping Cart Send successfully."
            );
            self.setState({
              ProfileProductTab: 0,
              isShutterOpen: false,
            });
            self.handleGetChatMessagesList(self.state.chatId);
          } else {
            NotificationManager.error(
              "Shopping Cart Send Failed."
            );
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      NotificationManager.error(
        "Shopping Cart is Empty."
      );
    }
  }


  //handle get retarget Ticket list
  handleGetRetargetList = async () => {
    this.setState({
      loading: true
    })
    let inputParams = {
      CustomerMobile: this.state.mobileNo ? this.state.mobileNo : encryption(window.localStorage.getItem("customerNum"), 'decr'),
    };
    await this.ChatService.PostWithParams("/CustomerChat/GetRetargetTickets", inputParams)
      .then((response) => {
        let msg = response.message;
        if (msg === "Success") {

          this.setState({
            loading: false,
            retargetDataList: response.responseData,
            retargetSerch: response.responseData
          });
        } else {
          this.setState({
            loading: false
          })
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  //handle ticket click
  HandleTicketRetarget = (id) => {
    let Id = id;
    localStorage.setItem("ticketNo", Id);
    // window.open('/myTicket', '_blank')
    let self = this;
    self.setState({
      ticketDetailID: Id,
    });
    setTimeout(function () {
      self.props.history.push({
        pathname: "myTicket",
        ticketDetailID: Id,
      });
    }, 1000);
    //console.log(id);
  }

  handleRetargetSearch = (e) => {
    // debugger
    let filtered = []
    if (e.target.value.length !== 0) {
      // filtered = this.state.retargetDataList.filter(entry => Object.values(entry).some(val => typeof val === "string" && val.includes(e.target.value)));
      filtered = this.state.retargetDataList.filter(entry =>
        // Object.values(entry).some(val => console.log(val, "ddd", entry, "rrr", entry.ticketID))
        Object.values(entry).some(val => val !== null && entry.ticketID.toString().includes(e.target.value.toString()))
      );
      // filtered = this.state.retargetDataList.filter(o => o.ticketID.includes(e.target.value));
    }
    else {
      filtered = this.state.retargetDataList
    }
    this.setState({
      retargetSerch: filtered
    })
  }



  render() {
    const {
      showAutomaticTextOption,
      selectedCursorValue,
      automaticChatSuggestion,
      isVideoCallBookedTabCloseInMobile,
    } = this.state;
    const TranslationContext = this.state.translateLanguage.default;
    const PaginationButton = (current, type, originalElement) => {
      if (type === "prev") {
        return (
          <a>
            <button className="butn">Previous</button>
          </a>
        );
      }
      if (type === "next") {
        return (
          <a>
            <button className="butn">Next</button>
          </a>
        );
      }
      return originalElement;
    };

    const CreateTicketCollapse = (
      <>
        <div className="ticket-category-form">
          <div
          // className={`col-12 col-xs-12 col-sm-6   ${this.state.isMobileView ? "col-md-12" : "col-md-6"
          //   } col-lg-4`}
          >
            <div className="form-group">
              <label className="label-4 requried-field">
                {TranslationContext !== undefined
                  ? TranslationContext.label.category
                  : "Category"}
              </label>
              <select
                className="rectangle-9 select-category-placeholder"
                value={this.state.categoryID}
                name="categoryID"
                onChange={this.handleCategoryChange}
              >
                <option value={0}>
                  {TranslationContext !== undefined
                    ? TranslationContext.option.selectcategory
                    : "Select Category"}
                </option>
                {this.state.CategoryData !== null &&
                  this.state.CategoryData.map((item, i) => (
                    <option
                      key={i}
                      value={item.categoryID}
                      className="select-category-placeholder"
                    >
                      {item.categoryName}
                    </option>
                  ))}
              </select>
            </div>
          </div>
          <div
          // className={`col-12 col-xs-12 col-sm-6  ${this.state.isMobileView ? "col-md-12" : "col-md-6"
          //   } col-lg-4 dropdrown`}
          >
            <div className="form-group">
              <label className="label-4 requried-field">
                {TranslationContext !== undefined
                  ? TranslationContext.label.subcategory
                  : "Sub Category"}
              </label>
              <select
                className="rectangle-9 select-category-placeholder"
                value={this.state.subCategoryID}
                onChange={this.handleSubCategoryChange}
                name="subCategoryID"
              >
                <option value={0}>
                  {TranslationContext !== undefined
                    ? TranslationContext.option.selectsubcategory
                    : "Select Sub Category"}
                </option>
                {this.state.SubCategoryData !== null &&
                  this.state.SubCategoryData.map((item, i) => (
                    <option
                      key={i}
                      value={item.subCategoryID}
                      className="select-category-placeholder"
                    >
                      {item.subCategoryName}
                    </option>
                  ))}
              </select>
            </div>
          </div>
        </div>
        <div className="ticket-category-form">
          <div
          // className={`col-12 col-xs-12 col-sm-6   ${this.state.isMobileView ? "col-md-12" : "col-md-6"
          //   }  col-lg-4 dropdrown`}
          >
            <div className="form-group">
              <label className="label-4 requried-field">
                {TranslationContext !== undefined
                  ? TranslationContext.label.issuetype
                  : "Issue Type"}
              </label>
              <select
                className="rectangle-9 select-category-placeholder"
                value={this.state.issueTypeID}
                onChange={this.handleIssueTypeChange}
                name="issueTypeID"
              >
                <option value={0} className="select-sub-category-placeholder">
                  {TranslationContext !== undefined
                    ? TranslationContext.option.selectissuetype
                    : "Select Issue Type"}
                </option>
                {this.state.IssueTypeData !== null &&
                  this.state.IssueTypeData.map((item, i) => (
                    <option
                      key={i}
                      value={item.issueTypeID}
                      className="select-category-placeholder"
                    >
                      {item.issueTypeName}
                    </option>
                  ))}
              </select>
            </div>
          </div>
          <div
          // className={`col-12 col-xs-12 col-sm-6  ${this.state.isMobileView ? "col-md-12" : "col-md-6"
          //   }  col-lg-4 dropdrown`}
          >
            <div className="form-group">
              <label className="label-4 requried-field">
                {TranslationContext !== undefined
                  ? TranslationContext.label.priority
                  : "Priority"}
              </label>
              <select
                className="rectangle-9 select-category-placeholder"
                value={this.state.PriorityID}
                onChange={this.handlePriorityChange}
              >
                <option
                  className="select-category-placeholder"
                  value={0}
                >
                  {TranslationContext !== undefined
                    ? TranslationContext.label.priority
                    : "Select Priority"}
                </option>
                {this.state.priorityData !== null &&
                  this.state.priorityData.map((item, i) => (
                    <option
                      key={i}
                      value={item.priorityID}
                      className="select-category-placeholder"
                    >
                      {item.priortyName}
                    </option>
                  ))}
              </select>
            </div>
          </div>
        </div>
        <div className="div-padding-1">
          <label className="designation-name requried-field">
            {TranslationContext !== undefined
              ? TranslationContext.label.storename
              : "Ticket Title"}
          </label>
          <input
            type="text"
            className="txt-1"
            placeholder={
              TranslationContext !== undefined
                ? TranslationContext.placeholder.enterstorename
                : "Enter Ticket Title"
            }
            maxLength={200}
            name="TicketTitle"
            value={this.state.TicketTitle}
            onChange={this.hanldeOnChangeData}
          />
          {this.state.TicketTitle.length <= 5 && (
            <p
              style={{
                color: "red",
                marginBottom: "0px",
              }}
            >
              {this.state.CreateTicketTitleValidation}
            </p>
          )}
        </div>
        <div
          // className={`col-12   ${this.state.isMobileView && "col-md-12"
          //   }  col-xs-12 col-sm-4`}
          style={{ marginTop: "20px" }}
        >
          <label className="designation-name requried-field">
            {TranslationContext !== undefined
              ? TranslationContext.label.storename
              : "Ticket Detail's"}
          </label>
          <textarea
            className="Ticket-Details-textarea"
            placeholder={
              TranslationContext !== undefined
                ? TranslationContext.placeholder.addnotes
                : "Enter Ticket Detail's"
            }
            name="TicketDetails"
            value={this.state.TicketDetails}
            onChange={this.hanldeOnChangeData}
          ></textarea>
          {this.state.TicketDetails.length <= 10 && (
            <p
              style={{
                color: "red",
                marginBottom: "0px",
              }}
            >
              {this.state.CreateTicketDetailValidation}
            </p>
          )}
        </div>
        <div className="btnSpace">
          <button
            className="addBtn-ticket-hierarchy"
            type="button"
            onClick={this.handleCreateTicket.bind(this)}
          >
            {TranslationContext !== undefined
              ? TranslationContext.button.saveandnext
              : "CREATE TICKET"}
          </button>
        </div>
      </>
    );
    const ShowTicketCollapse = (
      <>
        <div className="TicketList-Container">
          {this.state.TicketList.map((item, i) =>
            <div className="col-sm-13" key={i} style={{ marginBottom: "5px" }} >
              <div className="card" style={{ paddingLeft: "10px", paddingTop: "10px", paddingBottom: "10px" }}>
                <div className="card-body" >
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <p className="card-text"><b>TICKET ID : </b>{item.ticketID}</p>
                    <p className="card-text"><b>CREATED DATE : </b>{item.createdDate}</p>
                  </div>
                  <p className="card-text"><b>TICKET TITLE : </b>{item.ticketTitle}</p>
                  <p className="card-text"><b>TICKET STATUS : </b>
                    <Chip style={{ height: "20px" }} label={item.ticketStatus === 101 ? "New" :
                      item.ticketStatus === 102 ? "Open" : "Resolved"}
                      color={item.ticketStatus === 103 ? "success" : "primary"}
                      variant="outlined"
                    /> </p>
                </div>
              </div>
              <Divider />
            </div>
          )}
          {this.state.TicketList.length == 0 ? (
            <Empty image={Empty.PRESENTED_IMAGE_DEFAULT} />
          ) : null}
        </div>
      </>
    );

    const ShowTargetList = (
      <>
        <div className={this.state.retargetDataList.length === 0 ? "TicketList-Container center_div" : "TicketList-Container"}>
          <Spin spinning={this.state.retargetDataList.length === 0}>
            {
              this.state.retargetDataList.length !== 0 &&
              this.state.retargetSerch.map((item, i) =>
                <div className="col-sm-13" key={i} style={{ marginBottom: "5px" }} >
                  <Collapse
                    accordion={true}
                    onChange={this.handleCollapseChange}
                    bordered={false}
                    activeKey={this.state.activeCollapse}
                  >
                    <Panel
                      key={i}
                      header={
                        <div className="card" style={{ paddingLeft: "10px", paddingTop: "10px", paddingBottom: "10px" }}>
                          <div className="card-body pointer_div">
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                              <p className="card-text"><b>TICKET ID : </b>{item.ticketID}</p>
                              <p className="card-text"><b>CREATED DATE : </b>{item.createdDate}</p>
                            </div>
                            <p className="card-text"><b>TICKET TITLE : </b>{item.ticketTitle}</p>
                            <p className="card-text"><b>TICKET STATUS : </b>
                              <Chip style={{ height: "20px" }} label={item.ticketStatus === 101 ? "New" :
                                item.ticketStatus === 102 ? "Open" : "Resolved"}
                                color={item.ticketStatus === 103 ? "success" : "primary"}
                                variant="outlined"
                              /> </p>
                          </div>
                        </div>
                      }
                    >
                      {item.abandonProducts.map((ele, ind) => {
                        return (
                          <div key={ind} className="ticket_products">

                            <div className="cart-detail-container">
                              <div className="cart-item-detail">
                                <div className="item-image">
                                  <img
                                    src={encodeURI(
                                      ele.imageUrl.length > 0 &&
                                        ele.imageUrl !== undefined ?
                                        ele.imageUrl : null
                                    )}
                                    className="cart-prod-img"
                                  />
                                </div>
                                <div className="item-detail">
                                  <span className="item-name">{ele.title}</span>
                                  <div className="item-size-wrap">
                                    <div className="d-flex justify-content-between">
                                      <p className="item-color">Size: <span>{ele.size}</span></p>
                                      <p className="item-color">Quantity: <span>{ele.quantity}</span></p>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                      <p className="item-color">Color: <span>{ele.color}</span></p>
                                      <span>
                                        {Number(
                                          ele.price
                                        ).toLocaleString(
                                          "en-IN",
                                          {
                                            style: "currency",
                                            currency: "INR",
                                            minimumFractionDigits: 0,
                                          }
                                        )}</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>


                          </div>
                        )
                      })}
                    </Panel>
                  </Collapse>
                  <Divider />
                </div>
              )
            }
            {this.state.retargetSerch.length == 0 ? (
              <Empty image={Empty.PRESENTED_IMAGE_DEFAULT} />
            ) : null}
          </Spin>
        </div>
      </>
    )

    return (
      <React.Fragment>
        <input
          type="hidden"
          id="backButtonClick"
          onClick={this.handleBackClickMobile.bind(this)}
        />
        {!this.state.isMobileView ? (
          <div className="store-chat-header row" style={{ margin: "0" }}>
            <div
              className="col-md-2"
              style={{ display: "inline-flex", padding: "15px" }}
            >
              <img src={Chatw} className="Chatw" alt="Chatw" />

              <h3>
                {this.state.isMobileView && this.state.customerName
                  ? this.state.customerName
                  : TranslationContext !== undefined
                    ? TranslationContext.h3.storechatwindow
                    : "Store chat"}
              </h3>

              {(this.state.isMainLoader || this.state.isAttachmentMainLoader) &&
                !this.state.isMobileView ? (
                <div className="loader"></div>
              ) : null}
            </div>
            <div className="col-md-10">
              <div className="storeHeader-store">
                {this.state.storeName ? (
                  <label
                    className="storeHeader-Name"
                    title={
                      "Store Name:\n" +
                      this.state.storeName +
                      " ( " +
                      this.state.storeCode +
                      " )"
                    }
                  >
                    <span>Store Name:</span>
                    <span>
                      {this.state.storeName +
                        " ( " +
                        this.state.storeCode +
                        " )"}
                    </span>
                  </label>
                ) : null}
                {this.state.storeAddress ? (
                  <label
                    className="storeHeader-address"
                    title={"Store Address:\n" + this.state.storeAddress}
                  >
                    <span>Store Address:</span>
                    <span>{this.state.storeAddress}</span>
                  </label>
                ) : null}
              </div>
            </div>
          </div>
        ) : null}
        <div className="container-fluid">
          <div className="row">
            <div
              style={{
                borderRight: !this.state.isMobileView
                  ? "1px solid #e8e8e8"
                  : "",
              }}
              className={
                this.state.isMobileView &&
                  !this.state.customerName &&
                  !this.state.isHistoricalChat
                  ? "firstbox firstbox-show"
                  : this.state.isMobileView && this.state.isHistoricalChat
                    ? "firstbox firstbox-hide"
                    : this.state.onHoverName
                      ? "firstbox firstbox-hide"
                      : "firstbox firstbox-hide firstbox-full"
              }
            >
              <div
                className="chatbot-left"
                style={{
                  backgroundColor: this.state.isMobileView
                    ? "ecf2f4"
                    : "#ECF2F4",
                }}
              >
                {this.state.isMobileView && this.state.showSearch ? (
                  <div
                    className="chat-cntr"
                    style={{
                      padding: "0px",
                      backgroundColor: "#FFF2E9",
                    }}
                  >
                    <span
                      style={{ textAlign: "center" }}
                      className="input-group-addon seacrh-img-chatsearch chatsearchtxt-span"
                    >
                      {this.state.searchChat === "" ? (
                        <img
                          src={SearchBlueImg}
                          alt="SearchBlueImg"
                          className="srch-imge"
                        />
                      ) : (
                        <img
                          src={CancelBlueImg}
                          alt="SearchBlueImg"
                          className="srch-imge"
                          style={{ width: "35%" }}
                          onClick={this.handleClearChatSearch.bind(this)}
                        />
                      )}
                    </span>
                    {this.state.isNumberPaste ? (
                      <input
                        type="text"
                        style={{ padding: "0px" }}
                        className="search-customerChatSrch"
                        placeholder={
                          TranslationContext !== undefined
                            ? TranslationContext.placeholder.search
                            : "Search by Customer Name/Customer No"
                        }
                        name="search"
                        maxLength="100"
                        autoComplete="off"
                        value={this.state.searchChat}
                        onChange={this.handleGetOngoingChat.bind(this)}
                      />
                    ) : <input
                      type="text"
                      style={{ padding: "0px" }}
                      className="search-customerChatSrch"
                      placeholder={
                        TranslationContext !== undefined
                          ? TranslationContext.placeholder.search
                          : "Search by Customer Name/Customer No"
                      }
                      name="search"
                      maxLength="100"
                      autoComplete="off"
                      value={this.state.searchChat}
                      onChange={this.handleGetOngoingChat.bind(this)}
                    />}
                    {this.state.isNumberMasking === true &&
                      this.state.tenantMobileMasking === true ? (
                      // <img
                      //   className="imgz"
                      //   src={paste}
                      //   onClick={this.handlePasteNumber.bind(this)}
                      // />
                      <ImPaste className="imgz" onClick={this.handlePasteNumber.bind(this)} />
                    ) : null}
                  </div>
                ) : null}
                {!this.state.isMobileView && !this.state.showSearch ? (
                  <div
                    className="chat-cntr"
                    style={{
                      padding: "0px",
                      backgroundColor: "#FFF2E9",
                    }}
                  >
                    <span
                      style={{ textAlign: "center" }}
                      className="input-group-addon seacrh-img-chatsearch chatsearchtxt-span"
                    >
                      {this.state.searchChat === "" ? (
                        <img
                          src={SearchBlueImg}
                          alt="SearchBlueImg"
                          className="srch-imge"
                        // onClick={this.handleGetOngoingChat.bind(this)
                        // }
                        />
                      ) : (
                        <img
                          src={CancelBlueImg}
                          alt="SearchBlueImg"
                          className="srch-imge"
                          style={{ width: "35%" }}
                          onClick={this.handleClearChatSearch.bind(this)}
                        />
                      )}
                    </span>
                    {this.state.isNumberPaste ? (
                      <input
                        type="text"
                        style={{ padding: "0px" }}
                        className="search-customerChatSrch"
                        placeholder={
                          TranslationContext !== undefined
                            ? TranslationContext.placeholder.search
                            : "Search by Customer Name/Customer No"
                        }
                        name="search"
                        maxLength="100"
                        autoComplete="off"
                        value={this.state.searchChat}
                        onChange={this.handleGetOngoingChat.bind(this)}
                      />
                    ) : <input
                      type="text"
                      style={{ padding: "0px" }}
                      className="search-customerChatSrch"
                      placeholder={
                        TranslationContext !== undefined
                          ? TranslationContext.placeholder.search
                          : "Search by Customer Name/Customer No"
                      }
                      name="search"
                      maxLength="100"
                      autoComplete="off"
                      value={this.state.searchChat}
                      onChange={this.handleGetOngoingChat.bind(this)}
                    />}
                    {this.state.isNumberMasking === true &&
                      this.state.tenantMobileMasking === true ? (
                      // <img
                      //   className="imgz"
                      //   src={paste}
                      //   onClick={this.handlePasteNumber.bind(this)}
                      // />
                      <ImPaste className="imgz" onClick={this.handlePasteNumber.bind(this)} />
                    ) : null}
                  </div>
                ) : null}
                <div className="mobile-ongoing-circ-outer">
                  <div className="mobile-ongoing-circ">
                    <div className="mobile-ongoing-circ-inner">
                      <div className="indi-circ indi-circ-all">
                        <div className="chat-initial-brd position-relative">
                          <span className="initial">ALL</span>
                        </div>
                        <div className="name-num">
                          <p className="chat-name">
                            {this.state.ongoingChatsData.length}
                          </p>
                        </div>
                      </div>
                      {this.state.ongoingChatsData
                        ? this.state.ongoingChatsData.map((chat, i) => (
                          <div
                            id={chat.chatID}
                            key={i}
                            className="indi-circ"
                            onClick={this.handleOngoingChatClick.bind(
                              this,
                              chat.chatID,
                              chat.customerName,
                              chat.messageCount,
                              chat.mobileNo,
                              chat.customerID,
                              chat.programCode,
                              chat.storeID,
                              chat.isCustEndChat,
                              chat.storeManagerId,
                              chat.initialColor,
                              chat.isCustTimeout,
                              chat.sourceAbbr
                            )}
                          >
                            {/* <div className="d-flex align-items-center overflow-hidden"> */}
                            <div className="chat-initial-brd position-relative">
                              <span
                                className="initial"
                                style={{
                                  backgroundColor: chat.initialColor,
                                }}
                              >
                                {chat.customerName.charAt(0)}
                              </span>
                              {chat.chatID !== this.state.chatId &&
                                chat.messageCount > 0 ? (
                                <span className="ongoing-message-icon-cnt">
                                  {chat.messageCount}
                                </span>
                              ) : null}
                            </div>
                            <div className="name-num">
                              <p className="chat-name">{chat.customerName}</p>
                            </div>
                            {/* </div> */}
                          </div>
                        ))
                        : null}
                    </div>
                  </div>
                </div>
                <div className="chatbot-left-new">
                  <Collapse
                    activeKey={this.state.chatTypeCollpase}
                    onChange={this.handleChatCollapsePanelChange.bind(this)}
                    bordered={false}
                  >
                    <Panel
                      className="newchatPanel"
                      showArrow={true}
                      header={
                        <div className="chats-heading d-flex justify-content-between align-items-center">
                          <label
                            className={
                              this.state.newChatsData.length > 0
                                ? "newchatLbl"
                                : ""
                            }
                          >
                            {TranslationContext !== undefined
                              ? TranslationContext.p.newchats
                              : "New Chats"}
                            (
                            {this.state.newChatsData.length < 9
                              ? "0" + this.state.newChatsData.length
                              : this.state.newChatsData.length}
                            )
                          </label>
                          {this.state.isMobileView ? (
                            <img
                              src={SearchBlueImg}
                              alt="SearchBlueImg"
                              className="srch-imge newchatSearchnone"
                              onClick={this.handleShowSearch.bind(this)}
                            />
                          ) : null}
                        </div>
                      }
                      key="1"
                    >
                      <div className="chat-left-height">
                        {this.state.newChatsData &&
                          this.state.newChatsData.map((chat, i) => (
                            <div
                              key={i}
                              className={
                                this.state.chatId === chat.chatID
                                  ? "chat-info active"
                                  : "chat-info"
                              }
                              onClick={this.handleUpdateCustomerChatStatus.bind(
                                this,
                                chat.chatID,
                                chat.storeManagerId,
                                chat.storeID,
                                chat.customerName,
                                chat.mobileNo,
                                chat.customerID,
                                chat.programCode,
                                chat.sourceAbbr
                              )}
                            >
                              <div className="d-flex align-items-center overflow-hidden">
                                <span
                                  className="initial"
                                  style={{
                                    backgroundColor: chat.initialColor,
                                  }}
                                >
                                  {chat.customerName.charAt(0)}
                                </span>
                                <div className="name-num mx-2">
                                  <p className="chat-name stop_copying">
                                    {chat.customerName}
                                    {chat.sourceIconUrl ? (
                                      <img
                                        src={chat.sourceIconUrl}
                                        alt="sourcetype"
                                        title={chat.sourceName}
                                        style={{ marginLeft: "5px" }}
                                      />
                                    ) : null}
                                  </p>
                                  {/* <p className="num">{chat.mobileNo}</p> */}
                                  {/* <p className="num">{handleMaskingNumber(chat.mobileNo)}</p>
                                  <p onClick={(e) => this.handleCopiedNumber(e, chat.mobileNo)}>C</p>
                                  <label className="text_copied" style={{ padding: '3px', backgroundColor: 'green', color: 'white' }}>Copied</label> */}
                                  <div className="text_copied_div">
                                    {this.state.isNumberMasking &&
                                      this.state.tenantMobileMasking ? (
                                      <p className="num">
                                        {handleMaskingNumber(
                                          chat.mobileNo,
                                          this.state.maskingPrefix,
                                          this.state.maskingSufix
                                        )}
                                      </p>
                                    ) : (
                                      <p className="num stop_copying">
                                        {chat.mobileNo}
                                      </p>
                                    )}
                                    <div className="text_copied_div">
                                      <img
                                        style={{ maxWidth: "25px" }}
                                        src={copy}
                                        onClick={(e) =>
                                          this.handleCopiedNumber(
                                            e,
                                            chat.mobileNo,
                                            chat.chatID
                                          )
                                        }
                                      />
                                      {this.state.isTextCopied &&
                                        this.state.copiedTextChatId ===
                                        chat.chatID ? (
                                        <label className="text_copied">
                                          {" "}
                                          Copied{" "}
                                        </label>
                                      ) : null}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div>
                                <div className="mess-time">
                                  {!this.state.onHoverName ? (
                                    <p
                                      style={{
                                        fontWeight:
                                          chat.messageCount > 0
                                            ? "bold"
                                            : "400",
                                      }}
                                    >
                                      {chat.messageCount === 0 ? (
                                        "No"
                                      ) : (
                                        <span className="messagecount">
                                          {chat.messageCount}
                                        </span>
                                      )}
                                      {TranslationContext !== undefined
                                        ? TranslationContext.p.newmessages
                                        : "New Messages"}
                                    </p>
                                  ) : null}
                                  <p>{chat.timeAgo}</p>
                                </div>
                              </div>
                            </div>
                          ))}
                        {this.state.newChatsData.length == 0 ? (
                          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                        ) : null}
                      </div>
                    </Panel>
                    <Panel
                      className="ongoingPanel"
                      showArrow={true}
                      key="2"
                      header={
                        <div className="chats-heading d-flex justify-content-between align-items-center">
                          {TranslationContext !== undefined
                            ? TranslationContext.p.ongoingchats
                            : "Ongoing Chats"}
                          (
                          {this.state.ongoingChatsData.length < 9
                            ? "0" + this.state.ongoingChatsData.length
                            : this.state.ongoingChatsData.length}
                          )
                          {!this.state.onHoverName ? (
                            <div onClick={(e) => e.stopPropagation()}>
                              <Select
                                className="agentchatdrop-down"
                                showArrow={true}
                                value={this.state.sAgentId}
                                onChange={this.handleChangeAgentDropdown.bind(
                                  this
                                )}
                              >
                                <Option value={0}>
                                  {TranslationContext !== undefined
                                    ? TranslationContext.option.allstoremember
                                    : "Agent ALL"}
                                </Option>
                                {this.state.agentData !== null &&
                                  this.state.agentData.map((item, i) => {
                                    return (
                                      <Option
                                        key={i}
                                        value={Number(item.storeManagerID)}
                                      >
                                        {item.agentName}
                                      </Option>
                                    );
                                  })}
                              </Select>
                            </div>
                          ) : null}
                        </div>
                      }
                    >
                      <div
                        className={
                          this.state.isMobileView
                            ? this.state.newChatsData.length > 0
                              ? "chat-left-height"
                              : ""
                            : this.state.newChatsData.length > 0
                              ? "chat-left-height"
                              : ""
                        }
                      >
                        {this.state.ongoingChatsData
                          ? this.state.ongoingChatsData.map((chat, i) => (
                            <div
                              id={chat.chatID}
                              key={i}
                              className={
                                this.state.chatId === chat.chatID
                                  ? "chat-info active"
                                  : "chat-info"
                              }
                              onClick={this.handleOngoingChatClick.bind(
                                this,
                                chat.chatID,
                                chat.customerName,
                                chat.messageCount,
                                chat.mobileNo,
                                chat.customerID,
                                chat.programCode,
                                chat.storeID,
                                chat.isCustEndChat,
                                chat.storeManagerId,
                                chat.initialColor,
                                chat.isCustTimeout,
                                chat.sourceAbbr
                              )}
                            >
                              <div className="d-flex align-items-center overflow-hidden">
                                <div className="chat-initial-brd position-relative">
                                  <span
                                    className="initial"
                                    style={{
                                      backgroundColor: chat.initialColor,
                                    }}
                                  >
                                    {chat.customerName.charAt(0)}
                                  </span>
                                  {chat.chatID !== this.state.chatId &&
                                    chat.messageCount > 0 ? (
                                    <span className="ongoing-message-icon-cnt">
                                      {chat.messageCount}
                                    </span>
                                  ) : null}
                                </div>
                                <div className="name-num mx-2">
                                  <div className="chat-name stop_copying">
                                    {chat.customerName}
                                    {chat.sourceIconUrl ? (
                                      <img
                                        src={chat.sourceIconUrl}
                                        alt="sourcetype"
                                        title={chat.sourceName}
                                        style={{ marginLeft: "5px" }}
                                      />
                                    ) : null}
                                  </div>
                                  <div className="text_copied_div">
                                    {this.state.isNumberMasking &&
                                      this.state.tenantMobileMasking ? (
                                      <p className="num">
                                        {handleMaskingNumber(
                                          chat.mobileNo,
                                          this.state.maskingPrefix,
                                          this.state.maskingSufix
                                        )}
                                      </p>
                                    ) : (
                                      <p className="num stop_copying">
                                        {chat.mobileNo}
                                      </p>
                                    )}
                                    {this.state.isNumberMasking === true ? (
                                      <p>
                                        <img
                                          className="imgsz"
                                          src={copy}
                                          onClick={(e) =>
                                            this.handleCopiedNumber(
                                              e,
                                              chat.mobileNo,
                                              chat.chatID
                                            )
                                          }
                                        />
                                      </p>
                                    ) : null}
                                    {this.state.isTextCopied &&
                                      this.state.copiedTextChatId ===
                                      chat.chatID ? (
                                      <label className="text_copied">
                                        {" "}
                                        Copied{" "}
                                      </label>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                              <div>
                                <div className="mess-time">
                                  <p>{chat.timeAgo}</p>
                                  {!this.state.onHoverName ? (
                                    <p
                                      className={"chat-storemng "}
                                      title="Store Manager"
                                    >
                                      {chat.storeManagerName}
                                    </p>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                          ))
                          : null}
                        {this.state.ongoingChatsData.length == 0 ? (
                          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                        ) : null}
                      </div>
                    </Panel>
                  </Collapse>
                </div>
                {!this.state.isMobileView ? (
                  <div
                    className="chat-hist"
                    style={{ backgroundColor: "#ECF2F4" }}
                  >
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        data-toggle="tab"
                        href="#my-historical-chat"
                        role="tab"
                        aria-controls="my-historical-chat"
                        aria-selected="false"
                        onClick={this.handleHistTabClick.bind(this)}
                      >
                        {TranslationContext !== undefined
                          ? TranslationContext.a.myhistoricalchat
                          : "MY HISTORICAL CHAT"}
                      </a>
                    </li>
                  </div>
                ) : null}
              </div>
            </div>

            <div
              className={
                this.state.isCheckSuggetion
                  ? "secondbox suggetion-open"
                  : this.state.onHoverName
                    ? "secondbox"
                    : this.state.customerName &&
                      (this.state.isCustomerProduct ||
                        this.state.isCustomerProfile)
                      ? "secondbox secondbox-open"
                      : (this.state.isMobileView && this.state.customerName) ||
                        (this.state.isMobileView && this.state.isHistoricalChat)
                        ? "secondbox secondbox-open-new-show"
                        : "secondbox-open-new secondbox-open-new-hide"
              }
            >
              {/* <div className={this.state.activeTab===2 || this.state.activeTab===4?"chatbot-right schdulead":"chatbot-right"}> */}
              <div
                className={
                  // (this.state.isCardMobileUp === true &&
                  //   this.state.searchCardData.length > 0) ||
                  this.state.activeTab === 4 || this.state.activeTab === 6
                    ? "chatbot-right schdulead"
                    : "chatbot-right"
                }
              >
                {this.state.isHistoricalChat !== true ? (
                  <div className="row" style={{ margin: "0" }}>
                    <div
                      className="chatdivtitle"
                      style={{ padding: "5px", height: "" }}
                    >
                      <ul
                        className="nav nav-tabs"
                        role="tablist"
                        style={{
                          width: "50%",
                          display: "inline-block",
                          border: "none",
                        }}
                      >
                        {this.state.customerName ? (
                          <>
                            <li className="nav-item">
                              <a
                                className={
                                  this.state.mainTabSelect === 1
                                    ? "nav-link active chatupperbtn"
                                    : "nav-link chatupperbtn"
                                }
                                data-toggle="tab"
                                href="#current-chat"
                                role="tab"
                                aria-controls="current-chat"
                                aria-selected="true"
                                onClick={this.handleMainTabChange.bind(this, 1)}
                              >
                                {/* Current Chat */}
                                {this.state.customerName}
                              </a>
                            </li>

                            <li className="nav-item">
                              <a
                                className={
                                  this.state.mainTabSelect === 2
                                    ? "nav-link active chatupperbtn"
                                    : "nav-link chatupperbtn"
                                }
                                data-toggle="tab"
                                href="#recent-chat"
                                role="tab"
                                aria-controls="recent-chat"
                                aria-selected="true"
                                onClick={this.handleMainTabChange.bind(this, 2)}
                              >
                                {this.state.pastChatCount < 9
                                  ? TranslationContext !== undefined
                                    ? TranslationContext.label.pastchat0 +
                                    this.state.pastChatCount +
                                    ")"
                                    : "Past Chat(0" +
                                    this.state.pastChatCount +
                                    ")"
                                  : TranslationContext !== undefined
                                    ? TranslationContext.label.pastchat +
                                    this.state.pastChatCount +
                                    ")"
                                    : "Past Chat(" +
                                    this.state.pastChatCount +
                                    ")"}
                              </a>
                            </li>
                          </>
                        ) : null}
                      </ul>
                      {this.state.customerName !== "" ? (
                        <button
                          type="button"
                          className="chatactionbtn"
                          onClick={this.handleActionOpen.bind(this)}
                        >
                          <label
                            className="myticket-submit-solve-button-text"
                            style={{ display: "inline" }}
                          >
                            {TranslationContext !== undefined
                              ? TranslationContext.label.action
                              : "Action"}
                          </label>
                          <img
                            src={DownArw}
                            alt="down-icon"
                            className="down-white"
                          />
                        </button>
                      ) : null}
                    </div>
                    <div
                      className="tab-content chattabtitle"
                      style={{
                        backgroundColor: "#f5f5f5",
                        marginTop:
                          this.state.isCustomerProduct === false &&
                            this.state.isCustomerProfile === false
                            ? "0"
                            : "",
                      }}
                    >
                      <div
                        className={
                          this.state.mainTabSelect === 1
                            ? "tab-pane fade show active"
                            : "tab-pane fade"
                        }
                        id="current-chat"
                        role="tabpanel"
                        aria-labelledby="current-chat"
                      >
                        {this.state.isDownbtn ? (
                          <div
                            className="chatcontentRow"
                            style={{
                              height:
                                !this.state.isMessageTabActive &&
                                  !this.state.isCardTabActive &&
                                  !this.state.isRecommendedTabActive &&
                                  !this.state.isSchedualTabActive &&
                                  !this.state.isGeneratePaymentTabActive &&
                                  !this.state.isVideoCallTabActive
                                  ? "80%"
                                  : "",
                            }}
                          >
                            <Spin
                              spinning={
                                this.state.isAttachmentMainLoader ||
                                this.state.messageListLoader
                              }
                              tip={
                                this.state.messageListLoader
                                  ? "Loading..."
                                  : this.state.isAttachmentMainLoader
                                    ? "Sending..."
                                    : ""
                              }
                            >
                              <div
                                style={{
                                  height:
                                    this.state.isMobileView &&
                                      !this.state.isCustomerProfile &&
                                      !this.state.isCustomerProduct
                                      ? "calc(100vh - 218px)"
                                      : "",
                                }}
                                className="chatcontentDiv"
                                ref={(div) => {
                                  this.messageList = div;
                                }}
                                onScroll={this.handleMessageDivScroll}
                              >
                                {this.state.messageData !== null
                                  ? this.state.messageData.map((item, i) => {
                                    return (
                                      <div
                                        key={i}
                                        className={
                                          item.byCustomer === true &&
                                            item.isBotReply !== true
                                            ? "chat-trail-cntr"
                                            : "chat-trail-cntr chat-trail-cntr-right"
                                        }
                                      >
                                        <div className="chat-trail-img">
                                          <span
                                            className="chat-initial"
                                            alt="face image"
                                            title={
                                              item.byCustomer
                                                ? item.customerName
                                                : this.state.UserName
                                            }
                                          >
                                            {item.byCustomer
                                              ? item.customerName
                                                .split(" ")
                                                .map((n) => n[0])
                                                .join("")
                                                .toUpperCase()
                                              : this.state.UserName.split(" ")
                                                .map((n) => n[0])
                                                .join("")
                                                .toUpperCase()}
                                          </span>
                                        </div>
                                        <div className="chat-trail-chat-cntr">
                                          {item.isBotReply && (
                                            <p className="bot-mark">
                                              {TranslationContext !==
                                                undefined
                                                ? TranslationContext.p.bot
                                                : "BOT"}
                                            </p>
                                          )}
                                          {item.isVideoCall && (
                                            <p className="bot-mark">
                                              <img
                                                src={video_call}
                                                style={{ width: "14px" }}
                                                alt={"videoicon"}
                                              />
                                            </p>
                                          )}
                                          <div>
                                            {item.attachment &&
                                              item.attachment.match(
                                                /(\.pdf|\.doc|\.docx|\.pptx|\.ppt|\.xlsx|\.txt)$/
                                              ) ? (
                                              <div className="card docCardAtt">
                                                {this.state.isMobileView ? (
                                                  <div
                                                    className="docFileAttach"
                                                    onClick={this.handleOpenAttacment.bind(
                                                      this,
                                                      item.attachment,
                                                      false
                                                    )}
                                                  >
                                                    <a>
                                                      {item.attachment
                                                        .length > 100
                                                        ? item.attachment
                                                          .substr(0, 100)
                                                          .concat("...")
                                                          .split("/")
                                                          .pop()
                                                        : item.attachment
                                                          .split("/")
                                                          .pop()}
                                                    </a>
                                                  </div>
                                                ) : (
                                                  <div className="docFileAttach">
                                                    <a
                                                      href={item.attachment}
                                                      target={"_blank"}
                                                      download={true}
                                                    >
                                                      {item.attachment
                                                        .length > 100
                                                        ? item.attachment
                                                          .substr(0, 100)
                                                          .concat("...")
                                                          .split("/")
                                                          .pop()
                                                        : item.attachment
                                                          .split("/")
                                                          .pop()}
                                                    </a>
                                                  </div>
                                                )}
                                              </div>
                                            ) : (
                                              <div className="card">
                                                {item.isAttachment && (
                                                  <div
                                                    style={{
                                                      alignSelf: "center",
                                                    }}
                                                  >
                                                    {this.state
                                                      .isMobileView ? (
                                                      <>
                                                        <img
                                                          onClick={this.handleOpenAttacment.bind(
                                                            this,
                                                            item.attachment,
                                                            true
                                                          )}
                                                          alt="attachmentimg"
                                                          src={
                                                            item.attachment
                                                          }
                                                          className="chat-product-img-attachment"
                                                        />
                                                      </>
                                                    ) : (
                                                      <a
                                                        href={item.attachment}
                                                        target={"_blank"}
                                                      >
                                                        <img
                                                          src={
                                                            item.attachment
                                                          }
                                                          alt="down-icon"
                                                          className="chat-product-img-attachment"
                                                        />
                                                      </a>
                                                    )}
                                                  </div>
                                                )}
                                              </div>
                                            )}
                                          </div>
                                          <div
                                            className={
                                              item.isAttachmentDoc
                                                ? "chat-trail-chatDoc pd-0"
                                                : "chat-trail-chat pd-0"
                                            }
                                            style={
                                              item.isAttachment
                                                ? { width: "301px" }
                                                : null
                                            }
                                          >
                                            {!item.isVideoCall
                                              ? ReactHtmlParser(
                                                item.message
                                                  .replace(
                                                    "col-md-2",
                                                    "col-md-4"
                                                  )
                                                  .replace(
                                                    "col-md-10",
                                                    "col-md-8"
                                                  )
                                              )
                                              : "Video Call at " +
                                              item.chatDate +
                                              " " +
                                              item.videoCallStartAt +
                                              "\nDuration:" +
                                              item.callDuration
                                            }
                                          </div>
                                          <div key={i} style={{ display: "flex", justifyContent: "space-between" }}>
                                            <span className="chat-trail-time">
                                              {item.chatDate + " "}
                                              {item.chatTime}
                                            </span>
                                            {item.isCopyIcon ?
                                              <>
                                                <div style={{ display: "flex" }} >
                                                  {this.state.isItemCodeCopied &&
                                                    this.state.ItemCodeKey === i ? (
                                                    <label className="itemcode_copied">
                                                      {" "}
                                                      Copied{" "}
                                                    </label>
                                                  ) : null}
                                                  <img
                                                    className="imgsz"
                                                    style={{ cursor: "pointer" }}
                                                    src={copy}
                                                    onClick={(e) =>
                                                      this.handleCopiedCode(
                                                        e,
                                                        item.searchText,
                                                        i
                                                      )
                                                    }
                                                  />
                                                  <img
                                                    src={SearchBlueImg}
                                                    alt="SearchBlueImg"
                                                    className="src-imge"
                                                    style={{ cursor: "pointer", marginLeft: "15px", marginTop: "2px", height: "20px" }}
                                                    onClick={(e) => this.handleSearchProductById(e, item.searchText)}
                                                  />
                                                </div>
                                              </>
                                              : null}
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  })
                                  : null}

                                {this.state.customerName &&
                                  this.state.messageData.length === 0 ? (
                                  <Empty
                                    style={{ marginTop: "110px" }}
                                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                                  />
                                ) : null}
                                {this.state.isCustEndChat &&
                                  this.state.isCustTimeout === false &&
                                  this.state.customerName !== "" ? (
                                  <label className="endchatlbl">
                                    {TranslationContext !== undefined
                                      ? TranslationContext.label
                                        .customerhasendchat
                                      : "Customer has ended the conversation"}
                                  </label>
                                ) : null}
                                {this.state.isCustTimeout &&
                                  this.state.isCustEndChat === false &&
                                  this.state.customerName !== "" ? (
                                  <label className="endchatlbl">
                                    {TranslationContext !== undefined
                                      ? TranslationContext.label
                                        .customerhasendchattimeout
                                      : "Chat has been timed-out from customer end"}
                                  </label>
                                ) : null}
                              </div>
                            </Spin>
                          </div>
                        ) : null}
                        {this.state.isMobileView &&
                          this.state.AttachementFiles.length > 0 ? (
                          <div className="fileSelectDivMob">
                            <div className="fileSelectMob">
                              <label className="fileuploadlbl">
                                {this.state.AttachementFiles.length +
                                  " File Selected"}
                              </label>
                              <img
                                src={EditIcon}
                                onClick={this.handleOpenFileShow.bind(this)}
                                style={{ width: "18px" }}
                              />
                            </div>
                          </div>
                        ) : null}
                        {this.state.isMessageTabActive ||
                          this.state.isCardTabActive ||
                          this.state.isRecommendedTabActive ||
                          this.state.isSchedualTabActive ||
                          this.state.isGeneratePaymentTabActive ||
                          this.state.isVideoCallTabActive ? (
                          <div
                            className="chatcontentdivtab chat-tabs-desktop mobilechat"
                            style={{
                              // maxHeight: !this.state.isDownbtn ? "531px" : "",
                              maxHeight: !this.state.isDownbtn ? "initial" : "",
                              pointerEvents:
                                this.state.isCustEndChat === true ||
                                  this.state.isCustTimeout
                                  ? "none"
                                  : "all",
                            }}
                          >
                            {this.state.customerName !== "" ? (
                              <ul className="nav nav-tabs" role="tablist">
                                {this.state.isMessageTabActive ? (
                                  <li className="nav-item">
                                    <a
                                      className={
                                        this.state.activeTab === 1 &&
                                          this.state.isMessageTabActive
                                          ? "nav-link active"
                                          : "nav-link"
                                      }
                                      data-toggle="tab"
                                      href="#message-tab"
                                      role="tab"
                                      aria-controls="message-tab"
                                      aria-selected="true"
                                      onClick={this.handleTabClick.bind(
                                        this,
                                        1
                                      )}
                                      id="one"
                                    >
                                      {TranslationContext !== undefined
                                        ? TranslationContext.a.message
                                        : "MESSAGE"}
                                    </a>
                                  </li>
                                ) : null}
                                {this.state.isCardTabActive ? (
                                  <li className="nav-item">
                                    <a
                                      className={
                                        this.state.activeTab === 2
                                          ? "nav-link active"
                                          : "nav-link"
                                      }
                                      data-toggle="tab"
                                      href="#card-tab"
                                      role="tab"
                                      aria-controls="card-tab"
                                      aria-selected="false"
                                      onClick={this.handleTabClick.bind(
                                        this,
                                        2
                                      )}
                                      id="two"
                                    >
                                      {TranslationContext !== undefined
                                        ? TranslationContext.a.card
                                        : "CARD"}
                                    </a>
                                  </li>
                                ) : null}
                                {this.state.isRecommendedTabActive ? (
                                  <li className="nav-item">
                                    <a
                                      className={
                                        this.state.activeTab === 3
                                          ? "nav-link active"
                                          : "nav-link"
                                      }
                                      data-toggle="tab"
                                      href="#recommended-list-tab"
                                      role="tab"
                                      aria-controls="recommended-list-tab"
                                      aria-selected="false"
                                      onClick={this.handleTabClick.bind(
                                        this,
                                        3
                                      )}
                                      id="three"
                                    >
                                      {TranslationContext !== undefined
                                        ? TranslationContext.a.recommendedlist
                                        : "RECOMMENDED LIST"}
                                    </a>
                                  </li>
                                ) : null}
                                {this.state.isSchedualTabActive ? (
                                  <li className="nav-item">
                                    <a
                                      className={
                                        this.state.activeTab === 4
                                          ? "nav-link active"
                                          : "nav-link"
                                      }
                                      data-toggle="tab"
                                      href="#schedule-visit-tab"
                                      role="tab"
                                      aria-controls="schedule-visit-tab"
                                      aria-selected="false"
                                      onClick={this.handleTabClick.bind(
                                        this,
                                        4
                                      )}
                                      id="four"
                                    >
                                      {TranslationContext !== undefined
                                        ? TranslationContext.a.schedulevisit
                                        : "SCHEDULE VISIT"}
                                    </a>
                                  </li>
                                ) : null}
                                {this.state.isGeneratePaymentTabActive ? (
                                  <li className="nav-item">
                                    <a
                                      className={
                                        this.state.activeTab === 5
                                          ? "nav-link active"
                                          : "nav-link"
                                      }
                                      data-toggle="tab"
                                      href="#generate-payment-link-tab"
                                      role="tab"
                                      aria-controls="generate-payment-link-tab"
                                      aria-selected="false"
                                      onClick={this.handleTabClick.bind(
                                        this,
                                        5
                                      )}
                                      id="five"
                                    >
                                      {TranslationContext !== undefined
                                        ? TranslationContext.a.schedulevisit
                                        : "GENERATE PAYMENT LINK"}
                                    </a>
                                  </li>
                                ) : null}
                                {this.state.isVideoCallTabActive ? (
                                  <li className="nav-item">
                                    <a
                                      className={
                                        this.state.activeTab === 6
                                          ? "nav-link active"
                                          : "nav-link"
                                      }
                                      data-toggle="tab"
                                      href="#schedule-visit-tab"
                                      role="tab"
                                      aria-controls="schedule-visit-tab"
                                      aria-selected="false"
                                      onClick={this.handleTabClick.bind(
                                        this,
                                        6
                                      )}
                                      id="six"
                                    >
                                      Video Call Appointment
                                    </a>
                                  </li>
                                ) : null}
                                {this.state.AttachementFiles.length > 0 ? (
                                  <div className="fileImgupl">
                                    <button
                                      className="filebtn order-grid-butn fileclr"
                                      type="button"
                                      onClick={this.handleOpenFileShow.bind(
                                        this
                                      )}
                                    >
                                      {this.state.AttachementFiles.length}
                                      &nbsp; file selected.
                                    </button>
                                  </div>
                                ) : null}
                              </ul>
                            ) : null}

                            <div className="Mobaddcart">
                              <img
                                src={AddWh}
                                alt="Add"
                                className="AddWh AddWh-alone"
                                style={{ transform: "rotate(45deg)" }}
                                onClick={this.AddWhiteIconOpen.bind(this)}
                              />
                              {this.state.AddWhite && (
                                <div className="card">
                                  <img
                                    src={AddWh}
                                    alt="Add"
                                    className="AddWh"
                                    onClick={this.AddWhiteIconClose.bind(this)}
                                  />
                                  <ul className="nav nav-tabs" role="tablist">
                                    {this.state.isMessageTabActive ? (
                                      <li className="nav-item">
                                        <a
                                          className={
                                            this.state.activeTab === 1 &&
                                              this.state.isMessageTabActive
                                              ? "nav-link active"
                                              : "nav-link"
                                          }
                                          data-toggle="tab"
                                          href="#message-tab"
                                          role="tab"
                                          aria-controls="message-tab"
                                          aria-selected="true"
                                          onClick={this.handleTabClick.bind(
                                            this,
                                            1
                                          )}
                                          id="one"
                                        >
                                          {TranslationContext !== undefined
                                            ? TranslationContext.a.message
                                            : "MESSAGE"}
                                        </a>
                                      </li>
                                    ) : null}
                                    {this.state.isCardTabActive ? (
                                      <li className="nav-item">
                                        <a
                                          className={
                                            this.state.activeTab === 2
                                              ? "nav-link active"
                                              : "nav-link"
                                          }
                                          data-toggle="tab"
                                          href="#card-tab"
                                          role="tab"
                                          aria-controls="card-tab"
                                          aria-selected="false"
                                          onClick={this.handleTabClick.bind(
                                            this,
                                            2
                                          )}
                                          id="two"
                                        >
                                          {TranslationContext !== undefined
                                            ? TranslationContext.a.card
                                            : "CARD"}
                                        </a>
                                      </li>
                                    ) : null}
                                    {this.state.isRecommendedTabActive ? (
                                      <li className="nav-item">
                                        <a
                                          className={
                                            this.state.activeTab === 3
                                              ? "nav-link active"
                                              : "nav-link"
                                          }
                                          data-toggle="tab"
                                          href="#recommended-list-tab"
                                          role="tab"
                                          aria-controls="recommended-list-tab"
                                          aria-selected="false"
                                          onClick={this.handleTabClick.bind(
                                            this,
                                            3
                                          )}
                                          id="three"
                                        >
                                          {TranslationContext !== undefined
                                            ? TranslationContext.a
                                              .recommendedlist
                                            : "RECOMMENDED LIST"}
                                        </a>
                                      </li>
                                    ) : null}
                                    {this.state.isSchedualTabActive ? (
                                      <li className="nav-item">
                                        <a
                                          className={
                                            this.state.activeTab === 4
                                              ? "nav-link active"
                                              : "nav-link"
                                          }
                                          data-toggle="tab"
                                          href="#schedule-visit-tab"
                                          role="tab"
                                          aria-controls="schedule-visit-tab"
                                          aria-selected="false"
                                          onClick={this.handleTabClick.bind(
                                            this,
                                            4
                                          )}
                                          id="four"
                                        >
                                          {TranslationContext !== undefined
                                            ? TranslationContext.a.schedulevisit
                                            : "SCHEDULE VISIT"}
                                        </a>
                                      </li>
                                    ) : null}
                                    {this.state.isGeneratePaymentTabActive ? (
                                      <li className="nav-item">
                                        <a
                                          className={
                                            this.state.activeTab === 5
                                              ? "nav-link active"
                                              : "nav-link"
                                          }
                                          data-toggle="tab"
                                          href="#generate-payment-link-tab"
                                          role="tab"
                                          aria-controls="generate-payment-link-tab"
                                          aria-selected="false"
                                          onClick={this.handleTabClick.bind(
                                            this,
                                            5
                                          )}
                                          id="five"
                                        >
                                          {TranslationContext !== undefined
                                            ? TranslationContext.a.schedulevisit
                                            : "GENERATE PAYMENT LINK"}
                                        </a>
                                      </li>
                                    ) : null}
                                    {this.state.isVideoCallTabActive ? (
                                      <li className="nav-item">
                                        <a
                                          className={
                                            this.state.activeTab === 6
                                              ? "nav-link active"
                                              : "nav-link"
                                          }
                                          data-toggle="tab"
                                          href="#schedule-visit-tab"
                                          role="tab"
                                          aria-controls="schedule-visit-tab"
                                          aria-selected="false"
                                          onClick={this.handleTabClick.bind(
                                            this,
                                            6
                                          )}
                                          id="six"
                                        >
                                          Video Call Appointment
                                        </a>
                                      </li>
                                    ) : null}
                                  </ul>
                                </div>
                              )}
                            </div>
                            <div className="tab-content">
                              {/* <div className="tab-content" style={{overflow:this.state.activeTab===1?"auto":"hidden"}}> */}
                              {/* --------Message Tab----- */}
                              {this.state.isMessageTabActive &&
                                this.state.customerName ? (
                                <div
                                  className={
                                    this.state.customerName !== "" &&
                                      this.state.activeTab === 1
                                      ? "tab-pane fade active show"
                                      : "tab-pane fade"
                                  }
                                  style={{
                                    overflow: "auto",
                                    // maxHeight: "calc(100vh - 520px)",
                                  }}
                                  id="message-tab"
                                  role="tabpanel"
                                  aria-labelledby="message-tab"
                                  ref={(suggestionUl) => {
                                    this.suggestionUlRef = suggestionUl;
                                  }}
                                >
                                  {automaticChatSuggestion.length > 0 &&
                                    showAutomaticTextOption !== false &&
                                    this.state.isSuggestionText ? (
                                    <ul className="suggestion_Data">
                                      {automaticChatSuggestion.map(
                                        (option, index) => {
                                          return (
                                            <li
                                              key={index}
                                              className={
                                                selectedCursorValue === index
                                                  ? "suggestion_Data_Active"
                                                  : null
                                              }
                                              onClick={() =>
                                                this.handleAutomaticMessageSelected(
                                                  option
                                                )
                                              }
                                            >
                                              {option.suggestionText}
                                            </li>
                                          );
                                        }
                                      )}
                                    </ul>
                                  ) : null}
                                  <div className="message-div">
                                    <span className="message-initial">
                                      {this.state.UserName.charAt(
                                        0
                                      ).toUpperCase()}
                                    </span>
                                    <textarea
                                      placeholder="Search to get suggestions..."
                                      value={this.state.message}
                                      onKeyDown={this.changeSelectedLi}
                                      onChange={this.handleOnChangeCKEditor.bind(
                                        this
                                      )}
                                    ></textarea>
                                    <p className="cls-charcount">
                                      {this.state.remainingCount + ""}

                                      {TranslationContext !== undefined
                                        ? TranslationContext.label
                                          .charactersremaining
                                        : " characters remaining..."}
                                    </p>
                                    {this.state.isMessage !== "" && (
                                      <p
                                        style={{
                                          color: "red",
                                          marginBottom: "0px",
                                        }}
                                      >
                                        {this.state.isMessage}
                                      </p>
                                    )}

                                    {this.state.isgrammarlyCheck ? (
                                      (this.state.storeAgentDetail.length > 0 &&
                                        this.state.storeAgentDetail[0]
                                          .grammarlyCheck === 1 &&
                                        Object.keys(this.state.Suggested_Words)
                                          .length > 0) ||
                                        this.state.Profane_Words.length > 0 ? (
                                        <div
                                          className="mobile-ck-error"
                                          style={{
                                            right:
                                              this.state.storeAgentDetail[0]
                                                .suggestion === 0 &&
                                                this.state.storeAgentDetail[0]
                                                  .attachment === 0
                                                ? "65px"
                                                : this.state.storeAgentDetail[0]
                                                  .attachment === 0
                                                  ? "135px"
                                                  : this.state.storeAgentDetail[0]
                                                    .suggestion === 0
                                                    ? "65px"
                                                    : "",
                                          }}
                                        >
                                          {!this.state.isMobileView ? (
                                            <Popover
                                              trigger="click"
                                              overlayClassName="textcorretant"
                                              content={
                                                <div>
                                                  <div>
                                                    <label className="textcorrent-header">
                                                      Easy Rewardz-Spelling
                                                      Check{" "}
                                                    </label>
                                                  </div>

                                                  <div
                                                    style={{
                                                      textAlign: "center",
                                                      padding: "10px",
                                                    }}
                                                  >
                                                    <Radio.Group
                                                      optionType="button"
                                                      buttonStyle="solid"
                                                      value={
                                                        this.state
                                                          .suggestionType
                                                      }
                                                      onChange={this.handleSuggetionTypeChange.bind(
                                                        this
                                                      )}
                                                    >
                                                      <Radio.Button value="1">
                                                        All
                                                      </Radio.Button>
                                                      <Radio.Button value="2">
                                                        Profanity Words
                                                      </Radio.Button>
                                                      <Radio.Button value="3">
                                                        Suggested Words
                                                      </Radio.Button>
                                                    </Radio.Group>
                                                  </div>
                                                  <div className="row">
                                                    <div className="col-md">
                                                      <label className="textcorrent-header-l">
                                                        Entered Words
                                                      </label>
                                                    </div>
                                                    <div className="col-md">
                                                      <label className="textcorrent-header-r">
                                                        {this.state
                                                          .suggestionType ===
                                                          "2"
                                                          ? "Profanity Words"
                                                          : "Suggested Words"}
                                                      </label>
                                                    </div>
                                                  </div>
                                                  <div className="textcorret">
                                                    {this.state.Profane_Words
                                                      .length > 0
                                                      ? this.state
                                                        .suggestionType ===
                                                        "1" ||
                                                        this.state
                                                          .suggestionType ===
                                                        "2"
                                                        ? this.state.Profane_Words.map(
                                                          (item, i) => {
                                                            return (
                                                              <div
                                                                className="row"
                                                                key={i}
                                                              >
                                                                <div className="col-md">
                                                                  <span className="oval"></span>
                                                                  <label className="textcorret-lab1">
                                                                    {
                                                                      item.value
                                                                    }{" "}
                                                                    (Profanity)
                                                                  </label>
                                                                  <img
                                                                    src={
                                                                      SchRight
                                                                    }
                                                                    className="textcorret-img"
                                                                  />
                                                                </div>

                                                                <div className="col-md">
                                                                  <label
                                                                    className={
                                                                      !item.isChecked
                                                                        ? "textcorret-lab2"
                                                                        : "textcorret-lab2-active"
                                                                    }
                                                                    onClick={this.handleProfannitySelect.bind(
                                                                      this,
                                                                      item.value
                                                                    )}
                                                                  >
                                                                    {
                                                                      item.value
                                                                    }
                                                                  </label>
                                                                </div>
                                                              </div>
                                                            );
                                                          }
                                                        )
                                                        : null
                                                      : null}

                                                    {Object.keys(
                                                      this.state.Suggested_Words
                                                    ).length > 0
                                                      ? Object.keys(
                                                        this.state
                                                          .Suggested_Words
                                                      ).map((item, i) => {
                                                        return this.state
                                                          .suggestionType ===
                                                          "1" ||
                                                          this.state
                                                            .suggestionType ===
                                                          "3" ? (
                                                          <div className="row">
                                                            <div className="col-md">
                                                              <span className="oval"></span>
                                                              <label className="textcorret-lab1">
                                                                {item}
                                                              </label>
                                                              <img
                                                                src={SchRight}
                                                                className="textcorret-img"
                                                              />
                                                            </div>

                                                            <div className="col-md">
                                                              {this.state
                                                                .Suggested_Words[
                                                                item
                                                              ].length > 0
                                                                ? this.state.Suggested_Words[
                                                                  item
                                                                ].map(
                                                                  (
                                                                    key,
                                                                    j
                                                                  ) => {
                                                                    return (
                                                                      <label
                                                                        className={
                                                                          this
                                                                            .state
                                                                            .selectedSuggested_Words[
                                                                            item
                                                                          ] ===
                                                                            key[0]
                                                                            ? "textcorret-lab2-active"
                                                                            : "textcorret-lab2"
                                                                        }
                                                                        key={
                                                                          j
                                                                        }
                                                                        onClick={this.handleSuggetionWordClick.bind(
                                                                          this,
                                                                          item,
                                                                          key[0]
                                                                        )}
                                                                      >
                                                                        {
                                                                          key[0]
                                                                        }
                                                                      </label>
                                                                    );
                                                                  }
                                                                )
                                                                : null}
                                                            </div>
                                                          </div>
                                                        ) : null;
                                                      })
                                                      : null}
                                                  </div>
                                                  {this.state.Profane_Words
                                                    .length > 0 ? (
                                                    <div>
                                                      <label
                                                        style={{ color: "red" }}
                                                      >
                                                        Please Remove Profanity
                                                        Words.
                                                      </label>
                                                    </div>
                                                  ) : null}

                                                  <div className="row">
                                                    <div className="col-md">
                                                      {this.state.Profane_Words
                                                        .length === 0 ? (
                                                        <button
                                                          className="textcorret-btnignore"
                                                          onClick={this.handleGrammarlyIgnore.bind(
                                                            this
                                                          )}
                                                        >
                                                          Ignore
                                                        </button>
                                                      ) : null}

                                                      {this.state.Profane_Words
                                                        .length === 0 ? (
                                                        <button
                                                          className="textcorret-btnapply"
                                                          onClick={this.handleGrammarlyApply.bind(
                                                            this
                                                          )}
                                                        >
                                                          Apply & Send
                                                        </button>
                                                      ) : null}
                                                      {this.state.Profane_Words
                                                        .length > 0 ? (
                                                        <button
                                                          className="textcorret-btnapply"
                                                          onClick={this.handleRemoveProfanity.bind(
                                                            this
                                                          )}
                                                        >
                                                          Remove Profanity
                                                        </button>
                                                      ) : null}
                                                    </div>
                                                  </div>
                                                </div>
                                              }
                                            >
                                              <div id="propoversugg">
                                                <img
                                                  src={Triangle}
                                                  alt="send img"
                                                />
                                                <label>
                                                  {Object.keys(
                                                    this.state.Suggested_Words
                                                  ).length +
                                                    (this.state.Profane_Words
                                                      .length > 0
                                                      ? 1
                                                      : 0)}{" "}
                                                  Errors
                                                </label>
                                              </div>
                                            </Popover>
                                          ) : (
                                            <div
                                              onClick={this.handleGrammarlyModalOpen.bind(
                                                this
                                              )}
                                            >
                                              <img
                                                src={Triangle}
                                                alt="send img"
                                              />
                                              <label>
                                                {Object.keys(
                                                  this.state.Suggested_Words
                                                ).length +
                                                  (this.state.Profane_Words
                                                    .length > 0
                                                    ? 1
                                                    : 0)}{" "}
                                                Errors
                                              </label>
                                            </div>
                                          )}
                                        </div>
                                      ) : null
                                    ) : null}

                                    {/* To get video call appointment button in mobile view */}
                                    {this.state.isVideoCallTabActive &&
                                      this.state.isMobileView ? (
                                      <div
                                        className="mobile-ck-appointment"
                                        title={"Appointment"}
                                      >
                                        <ul className="appointment_icon">
                                          <li className="nav-item">
                                            <a
                                              className={
                                                this.state.activeTab === 6
                                                  ? "nav-link active icon_style"
                                                  : "nav-link icon_style"
                                              }
                                              data-toggle="tab"
                                              href="#schedule-visit-tab"
                                              role="tab"
                                              aria-controls="schedule-visit-tab"
                                              aria-selected="false"
                                              onClick={this.handleTabClick.bind(
                                                this,
                                                6
                                              )}
                                              id="six"
                                            >
                                              {/* {"Appointment"} */}
                                              <FontAwesomeIcon
                                                icon={faCalendar}
                                                className="circular-loader ml-2"
                                              />
                                            </a>
                                          </li>
                                        </ul>
                                      </div>
                                    ) : null}
                                    {/* {this.state.storeAgentDetail.length !== 0 &&
                                    this.state.storeAgentDetail[0].freeText ===
                                      1 ? ( */}
                                    {
                                      this.state.CopiedItemCode.length > 0 ?
                                        <div
                                          className={
                                            this.state.isMobileView
                                              ? "mobile-ck-paste-mobile"
                                              : "mobile-ck-paste"
                                          }
                                          onClick={this.handlePasteItmCode.bind(
                                            this
                                          )}
                                          title={"Paste"}
                                        >
                                          <ImPaste />
                                        </div>
                                        : null
                                    }
                                    <div
                                      className="mobile-ck-attachment"
                                      title={"Attachment"}
                                      style={{
                                        right:
                                          this.state.storeAgentDetail.length > 0
                                            ? this.state.storeAgentDetail[0]
                                              .suggestion === 0
                                              ? "60px"
                                              : ""
                                            : "",
                                      }}
                                    >
                                      {this.state.isMobileView ? (
                                        <Popover
                                          content={
                                            <div className="fileMobAttch">
                                              <input
                                                id="docImagesUpload"
                                                className="d-none file-uploadprofile"
                                                type="file"
                                                onChange={this.handleFileUploadingMobile.bind(
                                                  this
                                                )}
                                                multiple
                                                accept=".jpg, .jpeg, .png, .webp, .pdf, .doc, .docx, .pptx, .xlsx, .ppt, .txt"
                                              />
                                              <input
                                                id="CameraImgUpload"
                                                className="d-none file-uploadprofile"
                                                type="file"
                                                onChange={this.handleFileUploadingMobile.bind(
                                                  this
                                                )}
                                                accept="image/*"
                                                capture
                                                multiple
                                              />
                                              <input
                                                id="GalleryImgUpload"
                                                className="d-none file-uploadprofile"
                                                type="file"
                                                onChange={this.handleFileUploadingMobile.bind(
                                                  this
                                                )}
                                                accept="image/*"
                                                multiple
                                              />
                                              <input
                                                id="DocumentImgUpload"
                                                className="d-none file-uploadprofile"
                                                type="file"
                                                onChange={this.handleFileUploadingMobile.bind(
                                                  this
                                                )}
                                                accept=".pdf,.doc,.docx, .pptx, .ppt, .xlsx, .txt"
                                                multiple
                                              />
                                              <ul>
                                                <li>
                                                  <div className="imgCircleAtt yellowImg">
                                                    <label htmlFor="DocumentImgUpload">
                                                      <img
                                                        src={AttachmentIcon}
                                                        alt="send img"
                                                      />
                                                    </label>
                                                  </div>

                                                  <label>Document</label>
                                                </li>
                                                <li>
                                                  <div className="imgCircleAtt blueImg">
                                                    <label htmlFor="CameraImgUpload">
                                                      <img
                                                        src={AttachmentIcon}
                                                        alt="send img"
                                                      />
                                                    </label>
                                                  </div>
                                                  <label>Camera</label>
                                                </li>
                                                <li>
                                                  <div className="imgCircleAtt skyBlue">
                                                    <label htmlFor="GalleryImgUpload">
                                                      <img
                                                        src={AttachmentIcon}
                                                        alt="send img"
                                                      />
                                                    </label>
                                                  </div>
                                                  <label>Gallery</label>
                                                </li>
                                              </ul>
                                            </div>
                                          }
                                          trigger="click"
                                          placement="top"
                                          overlayClassName="order-popover-table order-popover shopping-bag-popover-item"
                                          onVisibleChange={(visible) =>
                                            this.setState({
                                              mobileFileupload: visible,
                                            })
                                          }
                                          visible={this.state.mobileFileupload}
                                        >
                                          <img
                                            src={AttachmentIcon}
                                            alt="send img"
                                          />
                                        </Popover>
                                      ) : (
                                        <label htmlFor="docImagesUpload">
                                          <Dropzone
                                            onDrop={this.handleFileUploading.bind(
                                              this
                                            )}
                                            multiple={true}
                                            accept=".jpg, .jpeg, .png, .webp, .pdf, .doc, .docx, .xlsx, .pptx, .ppt, .txt"
                                          >
                                            {({
                                              getRootProps,
                                              getInputProps,
                                            }) => (
                                              <div {...getRootProps()}>
                                                <input
                                                  {...getInputProps()}
                                                  className="file-upload d-none"
                                                />
                                                <img
                                                  src={AttachmentIcon}
                                                  alt="send img"
                                                />
                                              </div>
                                            )}
                                          </Dropzone>
                                        </label>
                                      )}
                                    </div>
                                    {/* ) : null} */}

                                    {this.state.storeAgentDetail.length !== 0 &&
                                      this.state.storeAgentDetail[0]
                                        .suggestion === 1 ? (
                                      <div
                                        className="mobile-ck-send"
                                        onClick={this.handleMessageSuggestion.bind(
                                          this
                                        )}
                                        title={"Search"}
                                      // style={{
                                      //   right:
                                      //     this.state.storeAgentDetail[0]
                                      //       .freeText === 0
                                      //       ? "15px"
                                      //       : "",
                                      // }}
                                      >
                                        <img src={SuggSearch} alt="send img" />
                                      </div>
                                    ) : null}
                                    {/* {this.state.storeAgentDetail.length !== 0 &&
                                    this.state.storeAgentDetail[0].freeText ===
                                      1 ? ( */}
                                    <div
                                      className="mobile-ck-send-btn"
                                      onClick={this.handleAutoCorrection.bind(
                                        this
                                      )}
                                      title={"Send"}
                                    >
                                      <img src={Assign} alt="send img" />
                                    </div>
                                    {/* ) : null} */}
                                  </div>
                                </div>
                              ) : null}
                              {/* --------Card Tab----- */}
                              {this.state.isCardTabActive &&
                                this.state.customerName ? (
                                <div
                                  className={
                                    this.state.activeTab === 2
                                      ? "tab-pane fade active show"
                                      : "tab-pane fade"
                                  }
                                  id="card-tab"
                                  role="tabpanel"
                                  aria-labelledby="card-tab"
                                >
                                  <div>
                                    <Spin
                                      spinning={
                                        this.state.isMobileView
                                          ? this.state.isCardSearch
                                          : false
                                      }
                                    >
                                      <div
                                        className="input-group searchtxt-new"
                                        style={{ background: "none" }}
                                      >
                                        <label className="Cardname">Card</label>
                                        {this.state.isMobileView &&
                                          this.state.searchCardData.length ===
                                          0 && (
                                            <p
                                              style={{
                                                color: "red",
                                                marginTop: "5px",
                                              }}
                                            >
                                              {this.state.noProductFound}
                                            </p>
                                          )}
                                        <input
                                          type="text"
                                          className="search-customerAddSrch searchtxt"
                                          placeholder={
                                            TranslationContext !== undefined
                                              ? TranslationContext.placeholder
                                                .searchitemidarticleskuid
                                              : "Search ItemId/artcile/SKU ID"
                                          }
                                          name="Search"
                                          maxLength="100"
                                          autoComplete="off"
                                          value={this.state.searchItem}
                                          onChange={this.handleSearchItemChange.bind(
                                            this
                                          )}
                                          onKeyPress={this.enterPressed.bind(
                                            this
                                          )}
                                        />
                                        <span
                                          onClick={this.handleSearchChatItemDetailsButton.bind(
                                            this
                                          )}
                                          className="input-group-addon seacrh-img-addsearch searchtxt-span"
                                        >
                                          <img
                                            src={SearchBlueImg}
                                            alt="SearchBlueImg"
                                            className="srch-imge"
                                          />
                                        </span>
                                        {!this.state.isMobileView &&
                                          this.state.searchCardData.length ===
                                          0 && (
                                            <p
                                              style={{
                                                color: "red",
                                                marginBottom: "0px",
                                              }}
                                            >
                                              {this.state.noProductFound}
                                            </p>
                                          )}
                                      </div>
                                    </Spin>
                                  </div>
                                  {!this.state.isMobileView ? (
                                    <Spin
                                      spinning={
                                        this.state.isMobileView
                                          ? false
                                          : this.state.isCardSearch
                                      }
                                    >
                                      <div className="container p-0">
                                        <div
                                          className="row product-card"
                                          style={{
                                            height: !this.state.isDownbtn
                                              ? "100%"
                                              : "",
                                            maxHeight: !this.state.isDownbtn
                                              ? "calc(100vh - 335px)"
                                              : "",
                                          }}
                                        >
                                          {this.state.searchCardData !== null
                                            ? this.state.searchCardData.map(
                                              (item, i) => {
                                                return (
                                                  <div
                                                    className="col-md-6"
                                                    key={i}
                                                  >
                                                    {item.isChecked ? (
                                                      <div className="selectdot">
                                                        <img
                                                          src={CardTick}
                                                          alt={"select-card"}
                                                        />
                                                      </div>
                                                    ) : null}
                                                    <div
                                                      className="card"
                                                      id={
                                                        "card" + item.itemID
                                                      }
                                                    >
                                                      <div className="card-body position-relative">
                                                        <div
                                                          className="row"
                                                          style={{
                                                            margin: "0",
                                                          }}
                                                        >
                                                          <div
                                                            className="col-4 col-md-4 mb-md-0 mb-2"
                                                            style={{
                                                              alignSelf:
                                                                "center",
                                                            }}
                                                          >
                                                            {(item.imageUrl ||
                                                              item.imageURL) &&
                                                              this.state
                                                                .cardConfigData
                                                                .ImageURL ? (
                                                              <img
                                                                className="chat-product-img"
                                                                src={
                                                                  item.imageURL ||
                                                                  item.imageUrl
                                                                }
                                                                alt="Product Image"
                                                                title={
                                                                  item.productName ||
                                                                  item.name
                                                                }
                                                              />
                                                            ) : (
                                                              <Dropzone
                                                                maxSize={
                                                                  5242880
                                                                }
                                                                accept="image/jpeg, image/png,image/jpg"
                                                                onDrop={this.handleCardImageOnchange.bind(
                                                                  this,
                                                                  item.uniqueItemCode ||
                                                                  item.itemCode,
                                                                  item.color ||
                                                                  item
                                                                    .availableColor[0]
                                                                )}
                                                              >
                                                                {({
                                                                  getRootProps,
                                                                  getInputProps,
                                                                }) => (
                                                                  <div
                                                                    {...getRootProps()}
                                                                  >
                                                                    <input
                                                                      {...getInputProps()}
                                                                      className="file-upload d-none"
                                                                    />
                                                                    <span className="addimg">
                                                                      <input
                                                                        type="image"
                                                                        alt="Add Image"
                                                                        src={
                                                                          addimg
                                                                        }
                                                                      />
                                                                    </span>
                                                                  </div>
                                                                )}
                                                              </Dropzone>
                                                            )}
                                                          </div>
                                                          <div
                                                            className="col-8 col-md-8 bkcprdt"
                                                            onClick={this.handleSelectCard.bind(
                                                              this,
                                                              item.itemID,
                                                              item.imageURL ||
                                                              item.imageUrl
                                                            )}
                                                          >
                                                            {(item.productName ||
                                                              item.name) &&
                                                              this.state
                                                                .cardConfigData
                                                                .ItemName ? (
                                                              <div>
                                                                <label className="chat-product-name">
                                                                  {item.productName ||
                                                                    item.name}
                                                                </label>
                                                              </div>
                                                            ) : null}
                                                            <div>
                                                              {item.brandName &&
                                                                this.state
                                                                  .cardConfigData
                                                                  .Brand ? (
                                                                <label className="chat-product-code">
                                                                  {TranslationContext !==
                                                                    undefined
                                                                    ? TranslationContext
                                                                      .label
                                                                      .brand
                                                                    : "Brand"}
                                                                  :
                                                                  {" " +
                                                                    item.brandName}
                                                                </label>
                                                              ) : null}
                                                            </div>
                                                            <div>
                                                              {item.categoryName !==
                                                                "" &&
                                                                item.categoryName !==
                                                                null &&
                                                                this.state
                                                                  .cardConfigData
                                                                  .Category ? (
                                                                <label className="chat-product-code">
                                                                  {TranslationContext !==
                                                                    undefined
                                                                    ? TranslationContext
                                                                      .label
                                                                      .category
                                                                    : "Category"}
                                                                  :
                                                                  {" " +
                                                                    item.categoryName}
                                                                </label>
                                                              ) : null}
                                                            </div>
                                                            <div>
                                                              {item.subCategoryName &&
                                                                this.state
                                                                  .cardConfigData
                                                                  .SubCategory ? (
                                                                <label className="chat-product-code">
                                                                  {TranslationContext !==
                                                                    undefined
                                                                    ? TranslationContext
                                                                      .label
                                                                      .brand
                                                                    : "Brand"}
                                                                  :
                                                                  {" " +
                                                                    item.brandName}
                                                                </label>
                                                              ) : null}
                                                            </div>
                                                            {this.state
                                                              .sourceType ===
                                                              "cb" && !this.state.isAddToCardEnableForCB ? (
                                                              <div>
                                                                {item.color &&
                                                                  this.state
                                                                    .cardConfigData
                                                                    .Color ? (
                                                                  <label className="chat-product-code">
                                                                    {TranslationContext !==
                                                                      undefined
                                                                      ? TranslationContext
                                                                        .label
                                                                        .color
                                                                      : "Color"}
                                                                    :
                                                                    {" " +
                                                                      item.color}
                                                                  </label>
                                                                ) : null}
                                                              </div>
                                                            ) : (
                                                              <div>
                                                                {item.availableColor &&
                                                                  this.state
                                                                    .cardConfigData
                                                                    .Color ? (
                                                                  <label className="chat-product-code">
                                                                    {TranslationContext !==
                                                                      undefined
                                                                      ? TranslationContext
                                                                        .label
                                                                        .color
                                                                      : "Color"}
                                                                    :
                                                                    {
                                                                      [].concat.apply([], item.availableColor).filter((v, i, a) => a.indexOf(v) === i).join(',')
                                                                    }
                                                                  </label>
                                                                ) : null}
                                                              </div>
                                                            )}
                                                            {this.state
                                                              .sourceType ===
                                                              "cb" && !this.state.isAddToCardEnableForCB ? (
                                                              <div>
                                                                {item.size &&
                                                                  this.state
                                                                    .cardConfigData
                                                                    .Size ? (
                                                                  <label className="chat-product-code">
                                                                    {TranslationContext !==
                                                                      undefined
                                                                      ? TranslationContext
                                                                        .label
                                                                        .color
                                                                      : "Size"}
                                                                    :
                                                                    {" " +
                                                                      item.size}
                                                                  </label>
                                                                ) : null}
                                                              </div>
                                                            ) : item.availableSize &&
                                                              this.state
                                                                .cardConfigData
                                                                .Size ? (
                                                              <label className="chat-product-code">
                                                                {TranslationContext !==
                                                                  undefined
                                                                  ? TranslationContext
                                                                    .label
                                                                    .color
                                                                  : "Size"}
                                                                :
                                                                {" " +
                                                                  [].concat.apply([], item.availableSize).filter((v, i, a) => a.indexOf(v) === i).join(',')
                                                                }
                                                              </label>
                                                            ) : null}
                                                            {this.state
                                                              .sourceType ===
                                                              "cb" && !this.state.isAddToCardEnableForCB ? (
                                                              <div>
                                                                {item.uniqueItemCode &&
                                                                  this.state
                                                                    .cardConfigData
                                                                    .ItemCode ? (
                                                                  <label className="chat-product-code">
                                                                    {TranslationContext !==
                                                                      undefined
                                                                      ? TranslationContext
                                                                        .label
                                                                        .itemcode
                                                                      : "Item Code"}
                                                                    :
                                                                    {" " +
                                                                      item.uniqueItemCode}
                                                                  </label>
                                                                ) : null}
                                                              </div>
                                                            ) : (
                                                              <div>
                                                                {item.itemCode &&
                                                                  this.state
                                                                    .cardConfigData
                                                                    .ItemCode ? (
                                                                  <label className="chat-product-code">
                                                                    {TranslationContext !==
                                                                      undefined
                                                                      ? TranslationContext
                                                                        .label
                                                                        .itemcode
                                                                      : "Item Code"}
                                                                    :
                                                                    {" " +
                                                                      item.itemCode}
                                                                  </label>
                                                                ) : null}
                                                              </div>
                                                            )}
                                                            <div>
                                                              {item.discount &&
                                                                this.state
                                                                  .cardConfigData
                                                                  .discount ? (
                                                                <label className="chat-product-code">
                                                                  {TranslationContext !==
                                                                    undefined
                                                                    ? TranslationContext
                                                                      .label
                                                                      .discount
                                                                    : "Discount"}
                                                                  :{" "}
                                                                  {this.state
                                                                    .sourceType ===
                                                                    "cb" && !this.state.isAddToCardEnableForCB
                                                                    ? item.discount
                                                                    : item.discount[0].join()}
                                                                </label>
                                                              ) : null}
                                                            </div>
                                                            <div>
                                                              {item.price !==
                                                                "" &&
                                                                parseFloat(
                                                                  item.price
                                                                ) !== 0 &&
                                                                item.price !==
                                                                null &&
                                                                this.state
                                                                  .cardConfigData
                                                                  .Price ? (
                                                                <label className="chat-product-prize">
                                                                  {TranslationContext !==
                                                                    undefined
                                                                    ? TranslationContext
                                                                      .label
                                                                      .price
                                                                    : "Price"}
                                                                  :
                                                                  {" " +
                                                                    item.price}
                                                                </label>
                                                              ) : null}
                                                            </div>
                                                            {item.url &&
                                                              this.state
                                                                .cardConfigData
                                                                .Url ? (
                                                              <div>
                                                                <a
                                                                  href={
                                                                    item.url
                                                                  }
                                                                  target="_blank"
                                                                  className="chat-product-url"
                                                                >
                                                                  {item.url}
                                                                </a>
                                                              </div>
                                                            ) : (
                                                              ""
                                                            )}
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                );
                                              }
                                            )
                                            : null}
                                        </div>
                                        {this.state.searchCardData.length > 0 &&
                                          this.state.isMobileView === false ? (
                                          <div className="row m-0">
                                            <button
                                              style={{
                                                cursor: "pointer",
                                              }}
                                              className="storeUpbtn"
                                              onClick={this.handleDownButtonClick.bind(
                                                this
                                              )}
                                            >
                                              {this.state.isDownbtn ? (
                                                <img
                                                  src={DownBlue}
                                                  alt="down-arrow"
                                                />
                                              ) : (
                                                <img
                                                  src={UpBlue}
                                                  alt="up-arrow"
                                                />
                                              )}
                                            </button>
                                            {this.state.searchCardData.length >
                                              0 ? (
                                              <label className="cardscountlbl">
                                                {this.state.searchCardData.filter(
                                                  (x) => x.isChecked === true
                                                ).length > 0
                                                  ? "Selected Card(s):" +
                                                  this.state.searchCardData.filter(
                                                    (x) =>
                                                      x.isChecked === true
                                                  ).length
                                                  : null}
                                              </label>
                                            ) : null}
                                            {this.state.isCardSend ? (
                                              <label className="cardsucclbl">
                                                Card(s) Sent Successfully.
                                              </label>
                                            ) : null}
                                            {this.state.isSelectedCard ? (
                                              <label className="cardsucclblNot">
                                                Please Select Card.
                                              </label>
                                            ) : null}
                                            {/* //====================================// */}
                                            <div
                                              style={{
                                                display: "flex",
                                                flexDirection: "row",
                                              }}
                                            >
                                              {this.state.isAddToCardEnableForCB && (this.state.sourceType === 'cb' || this.state.IsShoppingCartTabEnableForWB) ?
                                                <button
                                                  style={{ marginRight: "15px" }}
                                                  className="butn"
                                                  onClick={() =>
                                                    this.handleSelectedCardAddToCard()
                                                  }
                                                >
                                                  {TranslationContext !==
                                                    undefined
                                                    ? TranslationContext.button
                                                      .send
                                                    : "Add To Cart"}
                                                  {/* <img
                                              src={SendUp}
                                              alt="send"
                                              className="send-up float-none"
                                            /> */}
                                                  {this.state.isSendRecomended ? (
                                                    <FontAwesomeIcon
                                                      icon={faCircleNotch}
                                                      className="circular-loader ml-2"
                                                      spin
                                                    />
                                                  ) : (
                                                    ""
                                                  )}
                                                </button>
                                                : null}
                                              {/* //====================================// */}

                                              <button
                                                className="butn"
                                                onClick={this.handleSendCard.bind(
                                                  this
                                                )}
                                              >
                                                {TranslationContext !==
                                                  undefined
                                                  ? TranslationContext.button
                                                    .send
                                                  : "Send"}
                                                <img
                                                  src={SendUp}
                                                  alt="send"
                                                  className="send-up float-none"
                                                />
                                                {this.state.isSendRecomended ? (
                                                  <FontAwesomeIcon
                                                    icon={faCircleNotch}
                                                    className="circular-loader ml-2"
                                                    spin
                                                  />
                                                ) : (
                                                  ""
                                                )}
                                              </button>
                                            </div>
                                          </div>
                                        ) : null}
                                      </div>
                                    </Spin>
                                  ) : null}
                                </div>
                              ) : null}

                              {/* --------Recommended List Tab----- */}
                              {this.state.isRecommendedTabActive &&
                                this.state.customerName ? (
                                <div
                                  className={
                                    this.state.activeTab === 3
                                      ? "tab-pane fade active show"
                                      : "tab-pane fade"
                                  }
                                  id="recommended-list-tab"
                                  role="tabpanel"
                                  aria-labelledby="recommended-list-tab"
                                >
                                  <div className="recommended-cntr">
                                    <label className="mob-app-chat-head">
                                      Recommended List
                                    </label>
                                    <p
                                      style={{
                                        color: "red",
                                        marginBottom: "0px",
                                      }}
                                    >
                                      {this.state.noRecommendedFound}
                                    </p>
                                    <button
                                      disabled={this.state.isSendRecomended}
                                      className="butn mobi-butn"
                                      onClick={this.handleSendRecommendedList.bind(
                                        this
                                      )}
                                    >
                                      {TranslationContext !== undefined
                                        ? TranslationContext.button
                                          .sendrecommendedlist
                                        : "Send Recommended List"}

                                      <img
                                        src={SendUp}
                                        alt="send"
                                        className="send-up float-none"
                                      />
                                      {this.state.isSendRecomended ? (
                                        <FontAwesomeIcon
                                          icon={faCircleNotch}
                                          className="circular-loader ml-2"
                                          spin
                                        />
                                      ) : (
                                        ""
                                      )}
                                    </button>
                                  </div>
                                </div>
                              ) : null}
                              {/* --------Schedule Visit Tab----- */}
                              {(this.state.isSchedualTabActive ||
                                this.state.isVideoCallTabActive) &&
                                this.state.customerName ? (
                                <div
                                  className={
                                    this.state.activeTab === 4
                                      ? "tab-pane fade active show"
                                      : "tab-pane fade"
                                  }
                                  id="schedule-visit-tab"
                                  role="tabpanel"
                                  aria-labelledby="schedule-visit-tab"
                                >
                                  {this.state.availableSlot > 0 ? (
                                    <div className="row">
                                      {this.state.isMobileView ? (
                                        <label
                                          className="s-lable schedule-left-cntr"
                                          style={{
                                            paddingBottom: "5px",
                                            paddingTop: "10px",
                                            width: "100%",
                                          }}
                                        >
                                          {this.state.activeTab === 6
                                            ? "Video Call Appointment"
                                            : "Schedule Visit"}
                                        </label>
                                      ) : null}

                                      <div
                                        className={
                                          this.state.activeTab === 6
                                            ? this.state.isMobileView
                                              ? "col-md-12 schedule-left-cntr correctedOrientationInUi"
                                              : "col-md-5 schedule-left-cntr"
                                            : this.state.isMobileView
                                              ? "col-md-12 schedule-left-cntr"
                                              : "col-md-7 schedule-left-cntr"
                                        }
                                      >
                                        {/* {&& this.state.isMobileView ? !this.state.isVideoCallBookedTabCloseInMobile : true} */}
                                        {this.state.activeTab === 6 ? (
                                          <>
                                            <Select
                                              style={{
                                                width: "100%",
                                                marginBottom: "10px",
                                              }}
                                              value={
                                                this.state.selectedVideocallDate
                                              }
                                              onChange={(e) => {
                                                this.setState({
                                                  selectedVideocallDate: e,
                                                });
                                              }}
                                            >
                                              {this.state.timeSlotData.map(
                                                (item) => {
                                                  return (
                                                    <Option
                                                      key={item.id}
                                                      value={item.id}
                                                    >
                                                      {item.dates}
                                                    </Option>
                                                  );
                                                }
                                              )}
                                            </Select>
                                            <div
                                              className="row"
                                              style={{
                                                marginTop: "10px",
                                                marginLeft: "0px",
                                                margin: "0",
                                              }}
                                            >
                                              <div className="col">
                                                <label className="s-lable">
                                                  Time
                                                </label>
                                              </div>
                                              <div className="col">
                                                <label className="s-lable">
                                                  Store Staff Name
                                                </label>
                                              </div>
                                            </div>
                                          </>
                                        ) : null}
                                        {this.state.timeSlotData !== null
                                          ? this.state.activeTab === 6
                                            ? this.state.timeSlotData
                                              .filter(
                                                (x) =>
                                                  x.id ===
                                                  this.state
                                                    .selectedVideocallDate
                                              )
                                              .map((item, i) => {
                                                return item
                                                  .alreadyScheduleDetails
                                                  .length > 0 ? (
                                                  <div key={i}>
                                                    <div className="schedule-btn-outer-cntr">
                                                      <div
                                                        className="schedule-btn-cntr"
                                                        id={
                                                          "schedule-btn-cntr" +
                                                          i
                                                        }
                                                        style={{
                                                          display: "block",
                                                        }}
                                                      >
                                                        {item
                                                          .alreadyScheduleDetails
                                                          .length > 0 &&
                                                          item.alreadyScheduleDetails.map(
                                                            (data, k) => {
                                                              var selectSlot = false;
                                                              if (
                                                                data ==
                                                                this.state
                                                                  .selectedSlot
                                                              ) {
                                                                selectSlot = true;
                                                              }

                                                              if (
                                                                data.isDisabled ||
                                                                data.visitedCount >
                                                                0
                                                              ) {
                                                                return (
                                                                  <>
                                                                    <div className="row">
                                                                      <div className="col">
                                                                        <button
                                                                          key={
                                                                            k
                                                                          }
                                                                          style={{
                                                                            cursor:
                                                                              data.isDisabled
                                                                                ? "no-drop"
                                                                                : "pointer",
                                                                          }}
                                                                          className={
                                                                            "s-red-active"
                                                                          }
                                                                        >
                                                                          {
                                                                            data.timeSlot
                                                                          }

                                                                          {selectSlot ? (
                                                                            <img
                                                                              className="s-img-select"
                                                                              src={
                                                                                CircleRight
                                                                              }
                                                                              alt="circle-right"
                                                                            />
                                                                          ) : null}
                                                                        </button>
                                                                      </div>

                                                                      <div className="col">
                                                                        <button
                                                                          key={
                                                                            k
                                                                          }
                                                                          style={{
                                                                            cursor:
                                                                              data.isDisabled
                                                                                ? "no-drop"
                                                                                : "pointer",
                                                                          }}
                                                                          className={
                                                                            "s-red-active"
                                                                          }
                                                                        >
                                                                          {
                                                                            data.storeStaffName
                                                                          }

                                                                          {selectSlot ? (
                                                                            <img
                                                                              className="s-img-select"
                                                                              src={
                                                                                CircleRight
                                                                              }
                                                                              alt="circle-right"
                                                                            />
                                                                          ) : null}
                                                                        </button>
                                                                      </div>
                                                                    </div>
                                                                  </>
                                                                );
                                                              }

                                                              if (
                                                                data.visitedCount ===
                                                                0
                                                              ) {
                                                                return (
                                                                  <>
                                                                    <div className="row">
                                                                      <div className="col">
                                                                        <button
                                                                          key={
                                                                            k
                                                                          }
                                                                          style={{
                                                                            cursor:
                                                                              data.isDisabled
                                                                                ? "no-drop"
                                                                                : "pointer",
                                                                          }}
                                                                          className={
                                                                            selectSlot
                                                                              ? "s-green-active"
                                                                              : "s-green-btn"
                                                                          }
                                                                          onClick={this.handleSelectSlot.bind(
                                                                            this,
                                                                            data,
                                                                            item.dates,
                                                                            data.isDisabled
                                                                          )}
                                                                        >
                                                                          {
                                                                            data.timeSlot
                                                                          }
                                                                          {selectSlot ? (
                                                                            <img
                                                                              className="s-img-select"
                                                                              src={
                                                                                CircleRight
                                                                              }
                                                                              alt="circle-right"
                                                                            />
                                                                          ) : null}
                                                                        </button>
                                                                      </div>

                                                                      <div className="col">
                                                                        <button
                                                                          key={
                                                                            k
                                                                          }
                                                                          style={{
                                                                            cursor:
                                                                              data.isDisabled
                                                                                ? "no-drop"
                                                                                : "pointer",
                                                                          }}
                                                                          className={
                                                                            selectSlot
                                                                              ? "s-green-active"
                                                                              : "s-green-btn"
                                                                          }
                                                                          onClick={this.handleSelectSlot.bind(
                                                                            this,
                                                                            data,
                                                                            item.dates,
                                                                            data.isDisabled
                                                                          )}
                                                                        >
                                                                          {
                                                                            data.storeStaffName
                                                                          }
                                                                        </button>
                                                                      </div>
                                                                    </div>
                                                                  </>
                                                                );
                                                              }
                                                            }
                                                          )}
                                                      </div>
                                                    </div>
                                                  </div>
                                                ) : null;
                                              })
                                            : this.state.timeSlotData.map(
                                              (item, i) => {
                                                return item
                                                  .alreadyScheduleDetails
                                                  .length > 0 ? (
                                                  <div key={i}>
                                                    <label className="s-lable">
                                                      {item.day}:{item.dates}
                                                    </label>
                                                    <div className="schedule-btn-outer-cntr">
                                                      {this.state
                                                        .activeTab === 4 && (
                                                          <div
                                                            className="selectdot-blue selectdot-blue-left"
                                                            onClick={this.handleScrollLeft.bind(
                                                              this,
                                                              i
                                                            )}
                                                          >
                                                            <img
                                                              src={SchRight}
                                                              alt="right arrow"
                                                            />
                                                          </div>
                                                        )}

                                                      <div
                                                        className="schedule-btn-cntr"
                                                        id={
                                                          "schedule-btn-cntr" +
                                                          i
                                                        }
                                                        style={{
                                                          display:
                                                            this.state
                                                              .activeTab === 6
                                                              ? "block"
                                                              : "flex",
                                                        }}
                                                      >
                                                        {this.state
                                                          .activeTab ===
                                                          6 && (
                                                            <div className="row mt-3 ml-2">
                                                              <div className="col-md-6">
                                                                <label className="s-lable">
                                                                  Time
                                                                </label>
                                                              </div>
                                                              <div className="col-md-6">
                                                                <label className="s-lable">
                                                                  Store Staff
                                                                </label>
                                                              </div>
                                                            </div>
                                                          )}

                                                        {item
                                                          .alreadyScheduleDetails
                                                          .length > 0 &&
                                                          item.alreadyScheduleDetails.map(
                                                            (data, k) => {
                                                              var selectSlot = false;
                                                              if (
                                                                this.state
                                                                  .timeSlotData[
                                                                  i
                                                                ]
                                                                  .alreadyScheduleDetails[
                                                                k
                                                                ] ===
                                                                this.state
                                                                  .selectedSlot
                                                              ) {
                                                                selectSlot = true;
                                                              }

                                                              if (
                                                                data.maxCapacity ==
                                                                data.visitedCount
                                                              ) {
                                                                return (
                                                                  <Tooltip
                                                                    placement="left"
                                                                    title={
                                                                      data.remaining +
                                                                      " MORE PEOPLE LEFT"
                                                                    }
                                                                  >
                                                                    <div className="row">
                                                                      <div className="col-12 col-md-6">
                                                                        <button
                                                                          key={
                                                                            k
                                                                          }
                                                                          style={{
                                                                            cursor:
                                                                              data.isDisabled
                                                                                ? "no-drop"
                                                                                : "pointer",
                                                                          }}
                                                                          className={
                                                                            "s-red-active"
                                                                          }
                                                                        >
                                                                          {
                                                                            data.timeSlot
                                                                          }

                                                                          {selectSlot ? (
                                                                            <img
                                                                              className="s-img-select"
                                                                              src={
                                                                                CircleRight
                                                                              }
                                                                              alt="circle-right"
                                                                            />
                                                                          ) : null}
                                                                        </button>
                                                                      </div>
                                                                      {this
                                                                        .state
                                                                        .activeTab ===
                                                                        6 && (
                                                                          <div className="col-12 col-md-6">
                                                                            <button
                                                                              key={
                                                                                k
                                                                              }
                                                                              style={{
                                                                                cursor:
                                                                                  data.isDisabled
                                                                                    ? "no-drop"
                                                                                    : "pointer",
                                                                              }}
                                                                              className={
                                                                                "s-red-active"
                                                                              }
                                                                            >
                                                                              {
                                                                                data.storeStaffName
                                                                              }

                                                                              {selectSlot ? (
                                                                                <img
                                                                                  className="s-img-select"
                                                                                  src={
                                                                                    CircleRight
                                                                                  }
                                                                                  alt="circle-right"
                                                                                />
                                                                              ) : null}
                                                                            </button>
                                                                          </div>
                                                                        )}
                                                                    </div>
                                                                  </Tooltip>
                                                                );
                                                              }
                                                              if (
                                                                data.visitedCount >=
                                                                (1 / 2) *
                                                                data.maxCapacity
                                                              ) {
                                                                return (
                                                                  <Tooltip
                                                                    placement="left"
                                                                    title={
                                                                      data.remaining +
                                                                      " MORE PEOPLE LEFT"
                                                                    }
                                                                  >
                                                                    <div className="row">
                                                                      <div className="col-md-6">
                                                                        <button
                                                                          key={
                                                                            k
                                                                          }
                                                                          style={{
                                                                            cursor:
                                                                              data.isDisabled
                                                                                ? "no-drop"
                                                                                : "pointer",
                                                                          }}
                                                                          className={
                                                                            data.isDisabled
                                                                              ? "s-red-active"
                                                                              : data.remaining <=
                                                                                0
                                                                                ? "s-red-active"
                                                                                : selectSlot
                                                                                  ? "s-yellow-active"
                                                                                  : "s-yellow-btn"
                                                                          }
                                                                          onClick={this.handleSelectSlot.bind(
                                                                            this,
                                                                            data,
                                                                            item.dates,
                                                                            data.isDisabled
                                                                          )}
                                                                        >
                                                                          {
                                                                            data.timeSlot
                                                                          }
                                                                          {selectSlot ? (
                                                                            <img
                                                                              className="s-img-select"
                                                                              src={
                                                                                CircleRight
                                                                              }
                                                                              alt="circle-right"
                                                                            />
                                                                          ) : null}
                                                                        </button>
                                                                      </div>
                                                                      {this
                                                                        .state
                                                                        .activeTab ===
                                                                        6 && (
                                                                          <div className="col-md-6">
                                                                            <button
                                                                              key={
                                                                                k
                                                                              }
                                                                              style={{
                                                                                cursor:
                                                                                  data.isDisabled
                                                                                    ? "no-drop"
                                                                                    : "pointer",
                                                                              }}
                                                                              className={
                                                                                data.isDisabled
                                                                                  ? "s-red-active"
                                                                                  : data.remaining <=
                                                                                    0
                                                                                    ? "s-red-active"
                                                                                    : selectSlot
                                                                                      ? "s-yellow-active"
                                                                                      : "s-yellow-btn"
                                                                              }
                                                                              onClick={this.handleSelectSlot.bind(
                                                                                this,
                                                                                data,
                                                                                item.dates,
                                                                                data.isDisabled
                                                                              )}
                                                                            >
                                                                              {
                                                                                data.storeStaffName
                                                                              }
                                                                              {selectSlot ? (
                                                                                <img
                                                                                  className="s-img-select"
                                                                                  src={
                                                                                    CircleRight
                                                                                  }
                                                                                  alt="circle-right"
                                                                                />
                                                                              ) : null}
                                                                            </button>
                                                                          </div>
                                                                        )}
                                                                    </div>
                                                                  </Tooltip>
                                                                );
                                                              }
                                                              if (
                                                                data.visitedCount <
                                                                (1 / 2) *
                                                                data.maxCapacity
                                                              ) {
                                                                return (
                                                                  <Tooltip
                                                                    placement="left"
                                                                    title={
                                                                      data.remaining +
                                                                      " MORE PEOPLE LEFT"
                                                                    }
                                                                  >
                                                                    <div className="row">
                                                                      <div className="col-md-6">
                                                                        <button
                                                                          key={
                                                                            k
                                                                          }
                                                                          style={{
                                                                            cursor:
                                                                              data.isDisabled
                                                                                ? "no-drop"
                                                                                : "pointer",
                                                                          }}
                                                                          className={
                                                                            data.isDisabled
                                                                              ? "s-red-active"
                                                                              : data.remaining <=
                                                                                0
                                                                                ? "s-red-active"
                                                                                : selectSlot
                                                                                  ? "s-green-active"
                                                                                  : "s-green-btn"
                                                                          }
                                                                          onClick={this.handleSelectSlot.bind(
                                                                            this,
                                                                            data,
                                                                            item.dates,
                                                                            data.isDisabled
                                                                          )}
                                                                        >
                                                                          {
                                                                            data.timeSlot
                                                                          }
                                                                          {selectSlot ? (
                                                                            <img
                                                                              className="s-img-select"
                                                                              src={
                                                                                CircleRight
                                                                              }
                                                                              alt="circle-right"
                                                                            />
                                                                          ) : null}
                                                                        </button>
                                                                      </div>
                                                                      {this
                                                                        .state
                                                                        .activeTab ===
                                                                        6 && (
                                                                          <div className="col-md-6">
                                                                            <button
                                                                              key={
                                                                                k
                                                                              }
                                                                              style={{
                                                                                cursor:
                                                                                  data.isDisabled
                                                                                    ? "no-drop"
                                                                                    : "pointer",
                                                                              }}
                                                                              className={
                                                                                data.isDisabled
                                                                                  ? "s-red-active"
                                                                                  : data.remaining <=
                                                                                    0
                                                                                    ? "s-red-active"
                                                                                    : selectSlot
                                                                                      ? "s-green-active"
                                                                                      : "s-green-btn"
                                                                              }
                                                                              onClick={this.handleSelectSlot.bind(
                                                                                this,
                                                                                data,
                                                                                item.dates,
                                                                                data.isDisabled
                                                                              )}
                                                                            >
                                                                              {
                                                                                data.storeStaffName
                                                                              }
                                                                              {selectSlot ? (
                                                                                <img
                                                                                  className="s-img-select"
                                                                                  src={
                                                                                    CircleRight
                                                                                  }
                                                                                  alt="circle-right"
                                                                                />
                                                                              ) : null}
                                                                            </button>
                                                                          </div>
                                                                        )}
                                                                    </div>
                                                                  </Tooltip>
                                                                );
                                                              }
                                                            }
                                                          )}
                                                      </div>
                                                      {this.state
                                                        .activeTab === 4 && (
                                                          <div
                                                            className="selectdot-blue"
                                                            onClick={this.handleScrollRight.bind(
                                                              this,
                                                              i
                                                            )}
                                                          >
                                                            <img
                                                              src={SchRight}
                                                              alt="right arrow"
                                                            />
                                                          </div>
                                                        )}
                                                    </div>
                                                  </div>
                                                ) : null;
                                              }
                                            )
                                          : null}
                                      </div>

                                      {this.state.activeTab === 6 &&
                                        !this.state.isMobileView && (
                                          <div className="col-12 col-md-3 text-align-center">
                                            <label className="s-lable mb-3">
                                              Comments
                                            </label>
                                            <textarea
                                              onChange={(e) => {
                                                this.setState({
                                                  commentVideoAppointment:
                                                    e.target.value,
                                                });
                                              }}
                                              name=""
                                              id=""
                                              cols="20"
                                              rows="2"
                                            ></textarea>
                                          </div>
                                        )}

                                      <div
                                        className={` ${this.state.isMobileView
                                          ? "col-md-12 send_cancel_button_appointment"
                                          : "col-md-4"
                                          } `}
                                      >
                                        <div className="schedule-right-outer-cntr">
                                          <div className="schedule-right-cntr">
                                            <div className="col">
                                              <label className="s-lable">
                                                {TranslationContext !==
                                                  undefined
                                                  ? TranslationContext.label
                                                    .selectedslot
                                                  : "Selected Slot"}
                                              </label>
                                              {Object.keys(
                                                this.state.selectedSlot
                                              ).length !== 0 ? (
                                                <div className="row">
                                                  <div className="mr-3">
                                                    <button
                                                      className={
                                                        this.state.selectedSlot
                                                          .visitedCount <
                                                          (1 / 2) *
                                                          this.state
                                                            .selectedSlot
                                                            .maxCapacity
                                                          ? "s-green-btn s-green-active select-slot-cntr mx-0"
                                                          : this.state
                                                            .selectedSlot
                                                            .visitedCount <
                                                            this.state
                                                              .selectedSlot
                                                              .maxCapacity
                                                            ? "s-yellow-btn s-yellow-active select-slot-cntr mx-0"
                                                            : "s-yellow-btn s-yellow-active select-slot-cntr mx-0"
                                                      }
                                                    >
                                                      {
                                                        this.state.selectedSlot
                                                          .timeSlot
                                                      }
                                                      <img
                                                        className="s-img-select"
                                                        src={CircleRight}
                                                        alt="circle-right"
                                                      />
                                                    </button>
                                                  </div>
                                                  {this.state.activeTab ===
                                                    6 && (
                                                      <div>
                                                        <button
                                                          className={
                                                            this.state
                                                              .selectedSlot
                                                              .visitedCount <
                                                              (1 / 2) *
                                                              this.state
                                                                .selectedSlot
                                                                .maxCapacity
                                                              ? "s-green-btn s-green-active select-slot-cntr mx-0"
                                                              : this.state
                                                                .selectedSlot
                                                                .visitedCount <
                                                                this.state
                                                                  .selectedSlot
                                                                  .maxCapacity
                                                                ? "s-yellow-btn s-yellow-active select-slot-cntr mx-0"
                                                                : "s-yellow-btn s-yellow-active select-slot-cntr mx-0"
                                                          }
                                                        >
                                                          {
                                                            this.state
                                                              .selectedSlot
                                                              .storeStaffName
                                                          }
                                                        </button>
                                                      </div>
                                                    )}
                                                </div>
                                              ) : null}
                                              {this.state.isSelectSlot !==
                                                "" && (
                                                  <p
                                                    style={{
                                                      color: "red",
                                                      marginBottom: "0px",
                                                    }}
                                                  >
                                                    {this.state.isSelectSlot}
                                                  </p>
                                                )}
                                            </div>
                                            {this.state.activeTab !== 6 ? (
                                              <div>
                                                <label className="s-lable">
                                                  No of People
                                                </label>
                                                <input
                                                  type="text"
                                                  value={this.state.noOfPeople}
                                                  onChange={this.handleNoOfPeopleChange.bind(
                                                    this
                                                  )}
                                                />
                                                {this.state.noOfPeopleMax !==
                                                  "" && (
                                                    <p
                                                      style={{
                                                        color: "red",
                                                        marginBottom: "11px",
                                                        width: "131px",
                                                      }}
                                                    >
                                                      {this.state.noOfPeopleMax}
                                                    </p>
                                                  )}
                                              </div>
                                            ) : null}
                                          </div>
                                          <div className="send_cancelForVideoCall_appointment">
                                            <button
                                              className={
                                                this.state.isSendClick
                                                  ? "butn ml-auto isSendClick-dsle"
                                                  : "butn ml-auto"
                                              }
                                              onClick={this.handleScheduleVisit.bind(
                                                this
                                              )}
                                            >
                                              Send
                                              <img
                                                src={SendUp}
                                                alt="send"
                                                className="send-up float-none"
                                              />
                                              {this.state.isSendRecomended ? (
                                                <FontAwesomeIcon
                                                  icon={faCircleNotch}
                                                  className="circular-loader ml-2"
                                                  spin
                                                />
                                              ) : (
                                                ""
                                              )}
                                            </button>

                                            {/* To close the video call appointment in mobiel view */}
                                            {this.state.isMobileView &&
                                              this.state.activeTab === 6 ? (
                                              // <button
                                              //   className={"butn ml-auto cancel_video_call_appointment"}
                                              // >
                                              <div>
                                                <ul
                                                  className="nav nav-tabs "
                                                  role="tablist"
                                                  style={{ display: "block" }}
                                                >
                                                  {this.state
                                                    .isMessageTabActive ? (
                                                    <li className="nav-item">
                                                      <a
                                                        className={
                                                          this.state
                                                            .activeTab === 1 &&
                                                            this.state
                                                              .isMessageTabActive
                                                            ? "nav-link active cancel_video_call_appointment "
                                                            : "nav-link cancel_video_call_appointment "
                                                        }
                                                        data-toggle="tab"
                                                        href="#message-tab"
                                                        role="tab"
                                                        aria-controls="message-tab"
                                                        aria-selected="true"
                                                        onClick={this.handleTabClick.bind(
                                                          this,
                                                          1
                                                        )}
                                                        id="one"
                                                      >
                                                        {TranslationContext !==
                                                          undefined
                                                          ? TranslationContext.a
                                                            .message
                                                          : "Cancel"}
                                                      </a>
                                                    </li>
                                                  ) : null}
                                                </ul>
                                              </div>
                                            ) : // </button>
                                              null}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  ) : (
                                    <div>
                                      <span className="slot-span">
                                        {TranslationContext !== undefined
                                          ? TranslationContext.span
                                            .noslotaddedforthisstore
                                          : "No slot added for this store"}
                                      </span>
                                    </div>
                                  )}
                                </div>
                              ) : null}

                              {/* --------Generate Payment Link Tab----- */}
                              {this.state.isGeneratePaymentTabActive &&
                                this.state.customerName ? (
                                <div
                                  className={
                                    this.state.activeTab === 5
                                      ? "tab-pane fade active show"
                                      : "tab-pane fade"
                                  }
                                  id="generate-payment-link-tab"
                                  role="tabpanel"
                                  aria-labelledby="generate-payment-link-tab"
                                >
                                  <div
                                    className="input-group searchtxt-new"
                                    style={{ background: "none" }}
                                  >
                                    <form style={{ width: "100%" }}>
                                      <input
                                        type="text"
                                        className="search-customerAddSrch searchtxt"
                                        placeholder={
                                          TranslationContext !== undefined
                                            ? TranslationContext.placeholder
                                              .searchorderid
                                            : "Search Order Id"
                                        }
                                        name="Search"
                                        maxLength="100"
                                        autoComplete="off"
                                      />
                                      <span className="input-group-addon seacrh-img-addsearch searchtxt-span">
                                        <img
                                          src={SearchBlueImg}
                                          alt="SearchBlueImg"
                                          className="srch-imge"
                                        />
                                      </span>
                                    </form>
                                  </div>
                                  <div className="payment-details">
                                    <label>
                                      {TranslationContext !== undefined
                                        ? TranslationContext.label.amount
                                        : "Amount"}
                                    </label>
                                    <span>INR 1299</span>
                                  </div>
                                  <div className="payment-link-butn">
                                    <button className="butn">
                                      {TranslationContext !== undefined
                                        ? TranslationContext.button
                                          .sendpaymentlink
                                        : "Send Payment Link"}
                                      <img
                                        src={SendUp}
                                        alt="send"
                                        className="send-up"
                                      />
                                    </button>
                                  </div>
                                  <div className="clearfix"></div>
                                </div>
                              ) : null}
                            </div>
                          </div>
                        ) : null}
                      </div>

                      <div
                        className={
                          this.state.mainTabSelect === 2
                            ? "tab-pane fade show active"
                            : "tab-pane fade"
                        }
                        id="recent-chat"
                        role="tabpanel"
                        aria-labelledby="recent-chat"
                      >
                        {this.state.isMobileView ? (
                          <div>
                            <a
                              className="aprightbck apr"
                              onClick={this.handleBackToCurrentChat.bind(this)}
                            >
                              Back to Current Chat
                            </a>
                          </div>
                        ) : null}
                        <div className="chathistory-tbl histochat">
                          <div
                            className="table-cntr store chat-history chatabcus mg-rm now-rap-tbl-txt"
                            style={{ margin: "10px" }}
                          >
                            <Table
                              loading={this.state.isPastChatLoading}
                              noDataContent="No Record Found"
                              className="components-table-demo-nested antd-table-campaign custom-antd-table"
                              columns={[
                                {
                                  title:
                                    TranslationContext !== undefined
                                      ? TranslationContext.title.chatid
                                      : "Chat ID",
                                  dataIndex: "chatID",
                                  width: "10%",
                                  className: "textnowrap-table",
                                  render: (row, rowData) => {
                                    return (
                                      <>
                                        {rowData.chatID ? rowData.chatID : ""}
                                      </>
                                    );
                                  },
                                },
                                {
                                  title:
                                    TranslationContext !== undefined
                                      ? TranslationContext.title.agent
                                      : "Agent",
                                  dataIndex: "agentName",
                                  width: "20%",
                                  className: "textnowrap-table",
                                  render: (row, rowData) => {
                                    return (
                                      <p>
                                        {rowData.agentName
                                          ? rowData.agentName
                                          : ""}
                                      </p>
                                    );
                                  },
                                },
                                {
                                  title:
                                    TranslationContext !== undefined
                                      ? TranslationContext.title.mobilenumber
                                      : "Mobile No",
                                  dataIndex: "customerMobile",
                                  width: "20%",
                                  className: "textnowrap-table",
                                  render: (row, rowData) => {
                                    return (
                                      // <p
                                      //   title={
                                      //     rowData.customerMobile
                                      //       ? rowData.customerMobile
                                      //       : ""
                                      //   }
                                      // >
                                      //   {rowData.customerMobile
                                      //     ? rowData.customerMobile
                                      //     : ""}
                                      // </p>
                                      <>
                                        {this.state.isNumberMasking &&
                                          this.state.tenantMobileMasking ? (
                                          <p className="num">
                                            {handleMaskingNumber(
                                              rowData.customerMobile,
                                              this.state.maskingPrefix,
                                              this.state.maskingSufix
                                            )}
                                          </p>
                                        ) : (
                                          <p>{rowData.customerMobile}</p>
                                        )}
                                      </>
                                    );
                                  },
                                },
                                {
                                  title:
                                    TranslationContext !== undefined
                                      ? TranslationContext.title.time
                                      : "Time",
                                  dataIndex: "timeAgo",
                                  width: "20%",
                                  className: "textnowrap-table",
                                  render: (row, rowData) => {
                                    return (
                                      <>
                                        {rowData.timeAgo ? rowData.timeAgo : ""}
                                      </>
                                    );
                                  },
                                },
                                {
                                  title:
                                    TranslationContext !== undefined
                                      ? TranslationContext.title.status
                                      : "Status",
                                  dataIndex: "chatStatus",
                                  width: "20%",
                                  className: "textnowrap-table",
                                  render: (row, rowData) => {
                                    return (
                                      <>
                                        {rowData.chatStatus
                                          ? rowData.chatStatus
                                          : ""}
                                      </>
                                    );
                                  },
                                },
                                {
                                  title:
                                    TranslationContext !== undefined
                                      ? TranslationContext.title.message
                                      : "Message",
                                  dataIndex: "message",
                                  width: "30%",
                                  className: "textnowrap-table",
                                  render: (row, rowdata) => {
                                    return (
                                      <div className="d-flex">
                                        <p className="storeaget-chat-ctn">
                                          {rowdata.chatCount
                                            ? rowdata.chatCount
                                            : ""}
                                        </p>
                                        <p
                                          className="msg-text-overlap"
                                          title={row ? row : ""}
                                        >
                                          {row ? row : ""}
                                        </p>
                                      </div>
                                    );
                                  },
                                },
                              ]}
                              dataSource={this.state.agentRecentChatData}
                              onRow={(record, index) => ({
                                onClick: (event) => {
                                  this.handleHistoricalTableRow(
                                    record,
                                    index,
                                    event
                                  );
                                },
                              })}
                              rowKey={(record) => {
                                if (record.chatID) {
                                  uid = uid + 1;
                                  return record.chatID + "p" + uid;
                                } else {
                                  uid = uid + 1;
                                  return "p" + uid;
                                }
                              }}
                              pagination={{
                                pageSize: 5,
                                defaultPageSize: 5,
                                total: this.state.pastChatCount,
                                onChange:
                                  this.handlePastChatPageChange.bind(this),
                              }}
                              rowClassName={this.setRowClassName}
                            ></Table>
                          </div>
                        </div>

                        <div className="chathistory-tbl">
                          {this.state.showHistoricalChat ? (
                            <div className="historychatcontnet">
                              <div className="chathistory-div add-bord">
                                <label className="chat-on-tuesday-jul">
                                  Chat On {this.state.chatTimeAgo}
                                </label>
                                <img
                                  onClick={this.handleHistoryChatClose.bind(
                                    this
                                  )}
                                  src={CancelBlack}
                                  alt="close-icon"
                                  style={{
                                    float: "right",
                                    cursor: "pointer",
                                  }}
                                />
                              </div>
                              <div
                                className="chatcontentDiv pastcht"
                                ref={(div) => {
                                  this.historyMessageList = div;
                                }}
                              >
                                {this.state.messageHistoryChatData !== null &&
                                  this.state.messageHistoryChatData.length > 0 ? (
                                  this.state.messageHistoryChatData.map(
                                    (item, i) => {
                                      return (
                                        <div
                                          key={i}
                                          className={
                                            item.byCustomer === true &&
                                              item.isBotReply !== true
                                              ? "chat-trail-cntr"
                                              : "chat-trail-cntr chat-trail-cntr-right"
                                          }
                                        >
                                          <div className="chat-trail-img">
                                            <span
                                              className="chat-initial"
                                              alt="face image"
                                              title={
                                                item.byCustomer
                                                  ? item.customerName
                                                  : this.state.UserName
                                              }
                                            >
                                              {item.byCustomer
                                                ? item.customerName
                                                  .split(" ")
                                                  .map((n) => n[0])
                                                  .join("")
                                                  .toUpperCase()
                                                : this.state.UserName.split(" ")
                                                  .map((n) => n[0])
                                                  .join("")
                                                  .toUpperCase()}
                                            </span>
                                          </div>
                                          <div className="chat-trail-chat-cntr">
                                            {item.isBotReply && (
                                              <p className="bot-mark">
                                                {TranslationContext !==
                                                  undefined
                                                  ? TranslationContext.p.bot
                                                  : "BOT"}
                                              </p>
                                            )}
                                            <div>
                                              {item.attachment &&
                                                item.attachment.match(
                                                  /(\.pdf|\.doc|\.docx|\.pptx|\.ppt|\.xlsx|\.txt)$/
                                                ) ? (
                                                <div className="card docCardAtt">
                                                  {this.state.isMobileView ? (
                                                    <div
                                                      className="docFileAttach"
                                                      onClick={this.handleOpenAttacment.bind(
                                                        this,
                                                        item.attachment,
                                                        false
                                                      )}
                                                    >
                                                      <a>
                                                        {item.attachment
                                                          .length > 100
                                                          ? item.attachment
                                                            .substr(0, 100)
                                                            .concat("...")
                                                            .split("/")
                                                            .pop()
                                                          : item.attachment
                                                            .split("/")
                                                            .pop()}
                                                      </a>
                                                    </div>
                                                  ) : (
                                                    <div className="docFileAttach">
                                                      <a
                                                        href={item.attachment}
                                                        target={"_blank"}
                                                        download={true}
                                                      >
                                                        {item.attachment
                                                          .length > 100
                                                          ? item.attachment
                                                            .substr(0, 100)
                                                            .concat("...")
                                                            .split("/")
                                                            .pop()
                                                          : item.attachment
                                                            .split("/")
                                                            .pop()}
                                                      </a>
                                                    </div>
                                                  )}
                                                </div>
                                              ) : (
                                                <div className="card attachment-imgdiv">
                                                  {item.isAttachment && (
                                                    <div
                                                      style={{
                                                        alignSelf: "center",
                                                      }}
                                                    >
                                                      {this.state
                                                        .isMobileView ? (
                                                        <>
                                                          <img
                                                            onClick={this.handleOpenAttacment.bind(
                                                              this,
                                                              item.attachment,
                                                              true
                                                            )}
                                                            alt="attachmentimg"
                                                            src={
                                                              item.attachment
                                                            }
                                                            className="chat-product-img-attachment"
                                                          />
                                                        </>
                                                      ) : (
                                                        <a
                                                          href={item.attachment}
                                                          target={"_blank"}
                                                        >
                                                          <img
                                                            src={
                                                              item.attachment
                                                            }
                                                            alt="down-icon"
                                                            className="chat-product-img-attachment"
                                                          />
                                                        </a>
                                                      )}
                                                    </div>
                                                  )}
                                                </div>
                                              )}
                                            </div>
                                            <p className="chat-trail-chat pd-0">
                                              {!item.isVideoCall
                                                ? ReactHtmlParser(
                                                  item.message
                                                    .replace(
                                                      "col-md-2",
                                                      "col-md-4"
                                                    )
                                                    .replace(
                                                      "col-md-10",
                                                      "col-md-8"
                                                    )
                                                )
                                                : "Video Call at " +
                                                item.chatDate +
                                                " " +
                                                item.videoCallStartAt +
                                                "\nDuration:" +
                                                item.callDuration}
                                            </p>
                                            <span className="chat-trail-time">
                                              {item.chatDate + " "}
                                              {item.chatTime}
                                            </span>
                                          </div>
                                        </div>
                                      );
                                    }
                                  )
                                ) : (
                                  <p style={{ margin: "10" }}>
                                    No record found
                                  </p>
                                )}
                              </div>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="row" style={{ margin: "0" }}>
                    <div className="chatdivtitle">
                      <label
                        className="chattitlelbl"
                        style={{ color: "Black" }}
                      >
                        {TranslationContext !== undefined
                          ? TranslationContext.label.myhistoricalchat
                          : "My Historical Chat"}
                      </label>
                    </div>
                    <div className="chathistory-tbl histochat">
                      <div
                        className="table-cntr store chat-history mg-rm now-rap-tbl-txt chatabcus"
                        style={{ margin: "10px" }}
                      >
                        <Table
                          loading={this.state.isHistoricalChatLoading}
                          noDataContent="No Record Found"
                          className="components-table-demo-nested antd-table-campaign custom-antd-table add-cursor"
                          columns={[
                            {
                              title:
                                TranslationContext !== undefined
                                  ? TranslationContext.title.chatid
                                  : "Chat ID",
                              dataIndex: "chatID",
                              width: "10%",
                              className: "textnowrap-table",
                              render: (row, rowData) => {
                                return (
                                  <>
                                    {rowData.chatID ? rowData.chatID : ""}
                                    {rowData.sourceIconUrl ? (
                                      <img
                                        src={rowData.sourceIconUrl}
                                        alt="sourcetype"
                                        title={rowData.sourceName}
                                        style={{ marginLeft: "5px" }}
                                      />
                                    ) : null}
                                  </>
                                );
                              },
                            },
                            {
                              title:
                                TranslationContext !== undefined
                                  ? TranslationContext.title.customername
                                  : "Customer Name",
                              dataIndex: "customerName",
                              width: "20%",
                              className: "textnowrap-table",
                              render: (row, rowData) => {
                                return (
                                  <>
                                    {rowData.customerName
                                      ? rowData.customerName
                                      : ""}
                                  </>
                                );
                              },
                            },
                            {
                              title:
                                TranslationContext !== undefined
                                  ? TranslationContext.title.mobilenumber
                                  : "Mobile No",
                              dataIndex: "customerMobile",
                              width: "20%",
                              className: "textnowrap-table",
                              render: (row, rowData) => {
                                return (
                                  <>
                                    {/* {rowData.customerMobile
                                      ? rowData.customerMobile
                                      : ""} */}
                                    {this.state.isNumberMasking &&
                                      this.state.tenantMobileMasking ? (
                                      <p className="num">
                                        {handleMaskingNumber(
                                          rowData.customerMobile,
                                          this.state.maskingPrefix,
                                          this.state.maskingSufix
                                        )}
                                      </p>
                                    ) : (
                                      <p className="num stop_copying">
                                        {rowData.customerMobile}
                                      </p>
                                    )}
                                  </>
                                );
                              },
                            },
                            {
                              title:
                                TranslationContext !== undefined
                                  ? TranslationContext.title.time
                                  : "Time",
                              dataIndex: "timeAgo",
                              width: "20%",
                              className: "textnowrap-table",
                              render: (row, rowData) => {
                                return (
                                  <>{rowData.timeAgo ? rowData.timeAgo : ""}</>
                                );
                              },
                            },
                            {
                              title:
                                TranslationContext !== undefined
                                  ? TranslationContext.title.status
                                  : "Status",
                              dataIndex: "chatStatus",
                              width: "20%",
                              className: "textnowrap-table",
                              render: (row, rowData) => {
                                return (
                                  <>
                                    {rowData.chatStatus
                                      ? rowData.chatStatus
                                      : ""}
                                  </>
                                );
                              },
                            },
                            {
                              title:
                                TranslationContext !== undefined
                                  ? TranslationContext.title.message
                                  : "Message",
                              dataIndex: "message",
                              width: "30%",
                              className: "textnowrap-table",
                              render: (row) => {
                                return (
                                  <>
                                    <p
                                      className="msg-text-overlap"
                                      title={row ? row : ""}
                                    >
                                      {row ? row : ""}
                                    </p>
                                  </>
                                );
                              },
                            },
                          ]}
                          dataSource={this.state.historicalChatData}
                          onRow={(record, index) => ({
                            onClick: (event) => {
                              this.handleHistoricalTableRow(
                                record,
                                index,
                                event
                              );
                            },
                          })}
                          pagination={{
                            pageSize: 5,
                            defaultPageSize: 5,
                          }}
                          rowKey={(record) => {
                            if (record.chatID) {
                              uid = uid + 1;
                              return record.chatID + "g" + uid;
                            } else {
                              uid = uid + 1;
                              return "h" + uid;
                            }
                          }}
                          rowClassName={this.setRowClassName}
                        ></Table>
                      </div>
                    </div>
                    <div className="chathistory-tbl">
                      {this.state.showHistoricalChat ? (
                        <div className="historychatcontnet">
                          <div className="chathistory-div add-bord">
                            <label className="chat-on-tuesday-jul">
                              Chat On {this.state.chatTimeAgo}
                            </label>
                            <img
                              onClick={this.handleHistoryChatClose.bind(this)}
                              src={CancelBlack}
                              alt="close-icon"
                              style={{
                                float: "right",
                                cursor: "pointer",
                              }}
                            />
                          </div>
                          <div
                            className="chatcontentDiv pastcht historicalChatDiv"
                            ref={(div) => {
                              this.historyMessageList = div;
                            }}
                            id="historicalChatDiv"
                          >
                            {this.state.messageHistoryChatData !== null &&
                              this.state.messageHistoryChatData.length > 0 ? (
                              this.state.messageHistoryChatData.map(
                                (item, i) => {
                                  return (
                                    <div
                                      key={i}
                                      className={
                                        item.byCustomer === true &&
                                          item.isBotReply !== true
                                          ? "chat-trail-cntr"
                                          : "chat-trail-cntr chat-trail-cntr-right"
                                      }
                                    >
                                      <div className="chat-trail-img">
                                        <span
                                          className="chat-initial"
                                          alt="face image"
                                          title={
                                            item.byCustomer
                                              ? item.customerName
                                              : this.state.UserName
                                          }
                                        >
                                          {item.byCustomer
                                            ? item.customerName
                                              .split(" ")
                                              .map((n) => n[0])
                                              .join("")
                                              .toUpperCase()
                                            : this.state.UserName.split(" ")
                                              .map((n) => n[0])
                                              .join("")
                                              .toUpperCase()}
                                        </span>
                                      </div>
                                      <div className="chat-trail-chat-cntr">
                                        {item.isBotReply && (
                                          <p className="bot-mark">
                                            {TranslationContext !== undefined
                                              ? TranslationContext.p.bot
                                              : "BOT"}
                                          </p>
                                        )}
                                        <div>
                                          {item.attachment &&
                                            item.attachment.match(
                                              /(\.pdf|\.doc|\.docx|\.pptx|\.ppt|\.xlsx|\.txt)$/
                                            ) ? (
                                            <div className="card docCardAtt">
                                              {this.state.isMobileView ? (
                                                <div
                                                  className="docFileAttach"
                                                  onClick={this.handleOpenAttacment.bind(
                                                    this,
                                                    item.attachment,
                                                    false
                                                  )}
                                                >
                                                  <a>
                                                    {item.attachment.length >
                                                      100
                                                      ? item.attachment
                                                        .substr(0, 100)
                                                        .concat("...")
                                                        .split("/")
                                                        .pop()
                                                      : item.attachment
                                                        .split("/")
                                                        .pop()}
                                                  </a>
                                                </div>
                                              ) : (
                                                <div className="docFileAttach">
                                                  <a
                                                    href={item.attachment}
                                                    target={"_blank"}
                                                    download={true}
                                                  >
                                                    {item.attachment.length >
                                                      100
                                                      ? item.attachment
                                                        .substr(0, 100)
                                                        .concat("...")
                                                        .split("/")
                                                        .pop()
                                                      : item.attachment
                                                        .split("/")
                                                        .pop()}
                                                  </a>
                                                </div>
                                              )}
                                            </div>
                                          ) : (
                                            <div className="card">
                                              {item.isAttachment && (
                                                <div
                                                  style={{
                                                    alignSelf: "center",
                                                  }}
                                                >
                                                  {this.state.isMobileView ? (
                                                    <>
                                                      <img
                                                        onClick={this.handleOpenAttacment.bind(
                                                          this,
                                                          item.attachment,
                                                          true
                                                        )}
                                                        alt="attachmentimg"
                                                        src={item.attachment}
                                                        className="chat-product-img-attachment"
                                                      />
                                                    </>
                                                  ) : (
                                                    <a
                                                      href={item.attachment}
                                                      target="_blank"
                                                    >
                                                      <img
                                                        src={item.attachment}
                                                        alt="down-icon"
                                                        className="chat-product-img-attachment"
                                                      />
                                                    </a>
                                                  )}
                                                </div>
                                              )}
                                            </div>
                                          )}
                                        </div>
                                        <p className="chat-trail-chat pd-0">
                                          {!item.isVideoCall
                                            ? ReactHtmlParser(
                                              item.message
                                                .replace(
                                                  "col-md-2",
                                                  "col-md-4"
                                                )
                                                .replace(
                                                  "col-md-10",
                                                  "col-md-8"
                                                )
                                            )
                                            : "Video Call at " +
                                            item.chatDate +
                                            " " +
                                            item.videoCallStartAt +
                                            "\nDuration:" +
                                            item.callDuration}
                                        </p>
                                        <span className="chat-trail-time">
                                          {item.chatDate + " "}
                                          {item.chatTime}
                                        </span>
                                      </div>
                                    </div>
                                  );
                                }
                              )
                            ) : (
                              <p style={{ margin: "10px" }}>No record found</p>
                            )}
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                )}
              </div>
            </div>
            {this.state.isCheckSuggetion ? (
              <div className="suggetion-div">
                <div className="d-flex align-items-center web-suggetion-hedaer">
                  <img
                    src={SmartMessageWeb}
                    className="bulb-img"
                    alt="icon missing"
                  />
                  <p className="smart-para">SMART MESSAGING</p>
                  <img
                    src={DownArrowChat}
                    className="bulb-img web-suggetion-arbtn"
                    alt="icon missing"
                    onClick={this.handleHideSuggetionWeb.bind(this)}
                  />
                </div>
                <hr style={{ marginBottom: "0" }} />
                <Spin spinning={this.state.isSuggestionLoad}>
                  <div>
                    <div className="tags-cntr-web" style={{ margin: "5px" }}>
                      <label className="tag-label">Tags</label>
                      {this.state.messageSuggestionTagsData !== null
                        ? this.state.messageSuggestionTagsData.map(
                          (item, i) => {
                            return (
                              <button
                                onClick={this.handleTagsButtonClick.bind(
                                  this,
                                  item.tagID
                                )}
                                className={
                                  this.state.selectedTags === item.tagID
                                    ? "tagsbtn-active"
                                    : "tagsbtn"
                                }
                                id={item.tagID}
                              >
                                {item.tagName}
                              </button>
                            );
                          }
                        )
                        : null}
                    </div>
                    <hr style={{ marginBottom: "0px" }} />
                    <label className="tag-label" style={{ margin: "10px" }}>
                      Top Messages
                    </label>
                    {this.state.messageSuggestionData !== null &&
                      this.state.messageSuggestionData.length > 0 &&
                      this.state.messageSuggestionData.length > 0 && (
                        <div className="suggestions-cntr setpagination web-suggetiondiv">
                          <Table
                            noDataContent="No Record Found"
                            style={{ width: "100%", margin: "5px" }}
                            className="components-table-demo-nested antd-table-campaign custom-antd-table rm-header rm-border"
                            columns={[
                              {
                                dataIndex: "suggestionText",
                                render: (row, rowData) => {
                                  i = i + 1;

                                  return (
                                    <div
                                      className={
                                        this.state.chkSuggestion ===
                                          rowData.suggestionID
                                          ? "suggestions-tick"
                                          : ""
                                      }
                                      style={{
                                        width: "100%",
                                      }}
                                      id={i}
                                      onClick={this.onOpenMobSuggestionModal.bind(
                                        this,
                                        rowData.suggestionText,
                                        rowData.suggestionID
                                      )}
                                    >
                                      <Tooltip
                                        placement="left"
                                        title={rowData.suggestionText}
                                      >
                                        <span>{rowData.suggestionText}</span>
                                      </Tooltip>
                                    </div>
                                  );
                                },
                              },
                            ]}
                            dataSource={this.state.messageSuggestionData.filter(
                              (x) => x.tagID == this.state.selectedTags
                            )}
                            pagination={{
                              pageSize: 10,
                              defaultPageSize: 10,
                            }}
                          ></Table>
                        </div>
                      )}

                    {this.state.messageSuggestionData.length === 0 ? (
                      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    ) : null}
                  </div>
                </Spin>
              </div>
            ) : null}
            {this.state.isCustomerProduct ||
              this.state.isCustomerProfile ||
              this.state.isCustomerComment ||
              this.state.isCreateTicket ||
              this.state.isRetarget ? (
              this.state.customerName ? (
                <div
                  onMouseLeave={this.handleNameHoverLeave.bind()}
                  style={{
                    position: this.state.isMobileView ? "" : "relative",
                  }}
                  className={
                    this.state.onHoverName
                      ? "thirdbox"
                      : this.state.isShutterOpen
                        ? "thirdbox thirdbox-shutter"
                        : this.state.isMobileView
                          ? "thirdbox"
                          : "thirdbox thirdbox-close"
                  }
                >
                  <div className="uptabs">
                    {!this.state.isShutterOpen ? (
                      <img
                        src={Arwdown}
                        className="Arwico"
                        style={{
                          bottom: this.state.isShutterOpen ? "3px" : "-10px",
                        }}
                        alt="Arwico"
                        onClick={this.handleChangeShutterWindow.bind(
                          this,
                          true
                        )}
                      />
                    ) : (
                      <img
                        src={Arwup}
                        className="Arwico"
                        alt="Arwico"
                        style={{
                          bottom: this.state.isShutterOpen ? "3px" : "-10px",
                        }}
                        onClick={this.handleChangeShutterWindow.bind(
                          this,
                          false
                        )}
                      />
                    )}
                    {this.state.isPinClick ? (
                      <img
                        src={Pin}
                        className="pin"
                        alt="Pin"
                        onClick={this.handlePinClick.bind(this)}
                      />
                    ) : (
                      <img
                        src={Pin2}
                        className="pin"
                        alt="Pin"
                        onClick={this.handlePinClick.bind(this)}
                      />
                    )}
                    <div></div>
                    <ul className="nav nav-tabs mob-prod-mar" role="tablist">
                      {this.state.isCustomerProfile ? (
                        <li
                          className="nav-item"
                          style={{
                            padding: this.state.isCustomerProduct ? "" : "2px",
                          }}
                        >
                          <a
                            className={
                              this.state.ProfileProductTab === 0
                                ? "nav-link active"
                                : "nav-link"
                            }
                            data-toggle="tab"
                            href="#customer-profile"
                            role="tab"
                            aria-controls="customer-profile"
                            aria-selected="true"
                            onClick={this.handleProfileProductTabChange.bind(
                              this,
                              0
                            )}
                          >
                            {TranslationContext !== undefined
                              ? TranslationContext.a.profile
                              : "Profile"}
                          </a>
                        </li>
                      ) : null}
                      {this.state.isCustomerProduct ? (
                        <li
                          className="nav-item"
                          style={{
                            padding: this.state.isCustomerProfile ? "" : "2px",
                          }}
                        >
                          <a
                            className={
                              this.state.ProfileProductTab === 1
                                ? "nav-link active"
                                : "nav-link"
                            }
                            data-toggle="tab"
                            href="#customer-product"
                            role="tab"
                            aria-controls="customer-product"
                            aria-selected="true"
                            onClick={this.handleProfileProductTabChange.bind(
                              this,
                              1
                            )}
                          >
                            {TranslationContext !== undefined
                              ? TranslationContext.a.product
                              : "Product"}
                          </a>
                        </li>
                      ) : null}
                      {this.state.isCustomerComment &&
                        this.state.isMobileView ? (
                        <li
                          className="nav-item"
                          style={{
                            padding: this.state.isCustomerProfile ? "" : "2px",
                          }}
                        >
                          <a
                            className={
                              this.state.ProfileProductTab === 2
                                ? "nav-link active"
                                : "nav-link"
                            }
                            data-toggle="tab"
                            href="#customer-comment"
                            role="tab"
                            aria-controls="customer-comment"
                            aria-selected="true"
                            onClick={this.handleProfileProductTabChange.bind(
                              this,
                              2
                            )}
                          >
                            {TranslationContext !== undefined
                              ? TranslationContext.a.product
                              : "Comment"}
                          </a>
                        </li>
                      ) : null}
                      {this.state.isCreateTicket && !this.state.isMobileView ? (
                        <li
                          className="nav-item"
                          style={{
                            padding: this.state.isCustomerProfile ? "" : "2px",
                          }}
                        >
                          <a
                            className={
                              this.state.ProfileProductTab === 3
                                ? "nav-link active"
                                : "nav-link"
                            }
                            data-toggle="tab"
                            href="#customer-ticket"
                            role="tab"
                            aria-controls="customer-ticket"
                            aria-selected="true"
                            onClick={this.handleProfileProductTabChange.bind(
                              this,
                              3
                            )}
                          >
                            {TranslationContext !== undefined
                              ? TranslationContext.a.product
                              : "Ticket"}
                          </a>
                        </li>
                      ) : null}
                      {/* Retarget Code */}
                      {this.state.isRetarget && !this.state.isMobileView ? (
                        <li
                          className="nav-item"
                          style={{
                            padding: this.state.isCustomerProfile ? "" : "2px",
                          }}
                        >
                          <a
                            className={
                              this.state.ProfileProductTab === 4
                                ? "nav-link active"
                                : "nav-link"
                            }
                            data-toggle="tab"
                            href="#retarget_"
                            role="tab"
                            aria-controls="retarget_"
                            aria-selected="true"
                            onClick={this.handleProfileProductTabChange.bind(
                              this,
                              4
                            )}
                          >
                            {TranslationContext !== undefined
                              ? TranslationContext.a.product
                              : "Retarget"}
                          </a>
                        </li>
                      ) : null}

                    </ul>

                    <div className="tab-content">
                      {this.state.isCustomerProfile ? (
                        <div
                          className={
                            this.state.ProfileProductTab === 0
                              ? "tab-pane fade active show"
                              : "tab-pane fade"
                          }
                          id="customer-profile"
                          role="tabpanel"
                          aria-labelledby="customer-profile"
                        >
                          <div className="profilebox">
                            {/* {this.state.onHoverName &&
                              this.state.isProfileLoading ? (
                                <Spin className="spinner-right" />
                              ) : null} */}
                            <div>
                              <ul className="nameplate">
                                <li>
                                  <label
                                    onClick={
                                      this.state.isMobileView
                                        ? this.handleMainTabChange.bind(this, 1)
                                        : null
                                    }
                                    onMouseEnter={this.handleNameHover.bind(
                                      this
                                    )}
                                    className="namelabel"
                                    style={{
                                      backgroundColor: this.state.selectedColor,
                                    }}
                                  >
                                    {this.state.customerName.charAt(0)}
                                  </label>
                                </li>
                                <li
                                  onClick={
                                    this.state.isMobileView
                                      ? this.handleMainTabChange.bind(this, 1)
                                      : null
                                  }
                                >
                                  <h3>
                                    {this.state.customerNameProfile
                                      ? this.state.customerNameProfile
                                      : this.state.customerName}
                                  </h3>
                                  <span>
                                    {TranslationContext !== undefined
                                      ? TranslationContext.span.tier
                                      : "Tier"}
                                    : <b>{this.state.customerTier}</b>
                                  </span>
                                </li>
                                <li className="contactbox">
                                  <div>
                                    <ul>
                                      <li>
                                        <p>{this.state.customerEmailID}</p>
                                      </li>

                                      <li>
                                        {/* <p>
                                          {this.state.customerMobileNo
                                            ? this.state.customerMobileNo
                                            : this.state.mobileNo}
                                        </p> */}
                                        {this.state.isNumberMasking &&
                                          this.state.tenantMobileMasking ? (
                                          <p>
                                            {
                                              // this.state.maskedNumber.length !== 0 ?
                                              this.state.maskedNumber
                                              // :
                                              // handleMaskingNumber
                                              // (
                                              //   this.state.mobileNo,
                                              //   this.state.maskingPrefix,
                                              //   this.state.maskingSufix
                                              // )
                                            }
                                          </p>
                                        ) : (
                                          <p>{this.state.mobileNo}</p>
                                        )}
                                      </li>
                                    </ul>
                                  </div>
                                </li>
                              </ul>
                            </div>
                            <div className="pointstable">
                              <table>
                                <tbody>
                                  <tr>
                                    <td>
                                      <label>
                                        {TranslationContext !== undefined
                                          ? TranslationContext.label.totalpoints
                                          : "Total Points"}
                                      </label>
                                      <span>
                                        {this.state.totalPoints.toLocaleString(
                                          "en-IN"
                                        )}
                                      </span>
                                    </td>
                                    <td>
                                      <label>
                                        {TranslationContext !== undefined
                                          ? TranslationContext.label
                                            .lifetimevalue
                                          : "Lifetime Value"}
                                      </label>
                                      <span>
                                        {this.state.lifetimeValue.toLocaleString(
                                          "en-IN"
                                        )}
                                      </span>
                                    </td>
                                    <td>
                                      <label>
                                        {TranslationContext !== undefined
                                          ? TranslationContext.label.visitcount
                                          : "Visit Count"}
                                      </label>
                                      <span>
                                        {this.state.visitCount.toLocaleString(
                                          "en-IN"
                                        )}
                                      </span>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                            <div className="prodtabl1">
                              {this.state.onHoverName &&
                                this.state.isProfileLoading ? (
                                // <Spin className="spinner-right" />
                                <Spin className="spinnerprofile" />
                              ) : null}
                              <Collapse
                                activeKey={this.state.activeCollpse}
                                onChange={this.handleCollpseChange.bind(this)}
                              >
                                <Panel
                                  header={
                                    TranslationContext !== undefined
                                      ? TranslationContext.panel.insights
                                      : "Insights"
                                  }
                                  key="1"
                                >
                                  <Spin spinning={this.state.insightsLoader}>
                                    <div className="insightsbox">
                                      {this.state.insights
                                        ? this.state.insights.map(
                                          (item, key) => {
                                            return item.insightMessage ? (
                                              <p>
                                                {key + 1}.
                                                {" " + item.insightMessage}
                                              </p>
                                            ) : null;
                                          }
                                        )
                                        : null}
                                      {this.state.insights.length === 0 ? (
                                        <Empty
                                          image={Empty.PRESENTED_IMAGE_SIMPLE}
                                        />
                                      ) : null}
                                    </div>
                                  </Spin>
                                </Panel>
                                <Panel
                                  header={
                                    TranslationContext !== undefined
                                      ? TranslationContext.panel.orders
                                      : "Orders"
                                  }
                                  key="2"
                                >
                                  <Spin
                                    spinning={this.state.orderDetailsLoader}
                                  >
                                    <div className="ordersbox">
                                      <ul>
                                        <li>
                                          <label>
                                            {TranslationContext !== undefined
                                              ? TranslationContext.label
                                                .delivered
                                              : "Delivered"}
                                          </label>
                                          <span>
                                            {this.state.orderDelivered}
                                          </span>
                                        </li>
                                        <li>
                                          <label>
                                            {TranslationContext !== undefined
                                              ? TranslationContext.label
                                                .shoppingbag
                                              : "Shopping Bag"}
                                          </label>
                                          <span>
                                            {this.state.orderShoppingBag}
                                          </span>
                                        </li>
                                      </ul>
                                      <ul>
                                        <li>
                                          <label>
                                            {TranslationContext !== undefined
                                              ? TranslationContext.label
                                                .readytoship
                                              : "Ready to Ship"}
                                          </label>
                                          <span>
                                            {this.state.orderReadyToShip}
                                          </span>
                                        </li>
                                        <li>
                                          <label>
                                            {TranslationContext !== undefined
                                              ? TranslationContext.label.returns
                                              : "Returns"}
                                          </label>
                                          <span>{this.state.orderReturns}</span>
                                        </li>
                                      </ul>
                                    </div>
                                  </Spin>
                                </Panel>
                                <Panel
                                  header={
                                    TranslationContext !== undefined
                                      ? TranslationContext.panel.lasttransaction
                                      : "Last Transaction"
                                  }
                                  key="3"
                                >
                                  <Spin
                                    spinning={this.state.lasttransactionLoader}
                                  >
                                    <div className="lasttransaction">
                                      <ul>
                                        <li>
                                          <label>
                                            {TranslationContext !== undefined
                                              ? TranslationContext.label.billno
                                              : "Bill No"}
                                          </label>
                                          <span>{this.state.billNumber}</span>
                                        </li>
                                        <li>
                                          <label>
                                            {TranslationContext !== undefined
                                              ? TranslationContext.label.Amount
                                              : "Amount"}
                                          </label>
                                          <span>
                                            {this.state.billAmount.toLocaleString(
                                              "en-IN"
                                            )}
                                          </span>
                                        </li>
                                      </ul>
                                      <ul>
                                        <li>
                                          <label>
                                            {TranslationContext !== undefined
                                              ? TranslationContext.label.store
                                              : "Store"}
                                          </label>
                                          <span>{this.state.storeDetails}</span>
                                        </li>
                                        <li>
                                          <label>
                                            {TranslationContext !== undefined
                                              ? TranslationContext.label.date
                                              : "Date"}
                                          </label>
                                          <span>
                                            {this.state.transactionDate}
                                          </span>
                                        </li>
                                      </ul>
                                      <div className="itemtable">
                                        <table>
                                          <thead>
                                            <tr>
                                              <th>
                                                {TranslationContext !==
                                                  undefined
                                                  ? TranslationContext.th.Items
                                                  : "Items"}
                                              </th>
                                              <th>
                                                {TranslationContext !==
                                                  undefined
                                                  ? TranslationContext.th.qty
                                                  : "Qty"}
                                              </th>
                                              <th>
                                                {TranslationContext !==
                                                  undefined
                                                  ? TranslationContext.th.Amount
                                                  : "Amount"}
                                              </th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {this.state.itemDetails
                                              ? this.state.itemDetails.map(
                                                (item, i) => {
                                                  return (
                                                    <tr key={i}>
                                                      <td>
                                                        <label>
                                                          {item.article}
                                                        </label>
                                                      </td>
                                                      <td>
                                                        <label>
                                                          {item.quantity}
                                                        </label>
                                                      </td>
                                                      <td>
                                                        <label>
                                                          {item.amount}
                                                        </label>
                                                      </td>
                                                    </tr>
                                                  );
                                                }
                                              )
                                              : null}
                                          </tbody>
                                        </table>
                                        {this.state.itemDetails.length === 0 ? (
                                          <Empty
                                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                                          />
                                        ) : null}
                                      </div>
                                    </div>
                                  </Spin>
                                </Panel>
                              </Collapse>
                            </div>
                            <button
                              type="button"
                              className="updateprofilelinkbtn pastchatmobbtn"
                              onClick={this.handleMainTabChange.bind(this, 2)}
                            >
                              {this.state.pastChatCount < 9
                                ? this.state.pastChatCount + "0 Past Chat"
                                : this.state.pastChatCount + " Past Chat"}
                            </button>
                            <Dropdown
                              overlay={
                                <Menu
                                  onClick={this.handleMobileActionMenuClick.bind(
                                    this
                                  )}
                                >
                                  <Menu.Item key="1">
                                    {TranslationContext !== undefined
                                      ? TranslationContext.dropdown.closechat
                                      : "Close Chat"}
                                  </Menu.Item>
                                </Menu>
                              }
                            >
                              <button
                                style={{ float: "right" }}
                                type="button"
                                className="updateprofilelinkbtn pastchatmobbtn"
                              >
                                {TranslationContext !== undefined
                                  ? TranslationContext.button.action
                                  : "Action"}
                              </button>
                            </Dropdown>
                          </div>
                        </div>
                      ) : null}
                      {this.state.isCustomerProduct === true ? (
                        <div
                          className={
                            this.state.ProfileProductTab === 1
                              ? "tab-pane fade active show"
                              : "tab-pane fade"
                          }
                          id="customer-product"
                          role="tabpanel"
                          aria-labelledby="customer-product"
                        >
                          <div className="productsbox tabsnme">
                            {this.state.isCustomerProfile === false ? (
                              <div>
                                <ul className="nameplate">
                                  <li>
                                    <label
                                      onClick={
                                        this.state.isMobileView
                                          ? this.handleMainTabChange.bind(
                                            this,
                                            1
                                          )
                                          : null
                                      }
                                      onMouseEnter={this.handleNameHover.bind(
                                        this
                                      )}
                                      className="namelabel"
                                      style={{
                                        backgroundColor:
                                          this.state.selectedColor,
                                      }}
                                    >
                                      {this.state.customerName.charAt(0)}
                                    </label>
                                  </li>
                                  <li
                                    onClick={
                                      this.state.isMobileView
                                        ? this.handleMainTabChange.bind(this, 1)
                                        : null
                                    }
                                  >
                                    <h3>
                                      {this.state.customerNameProfile
                                        ? this.state.customerNameProfile
                                        : this.state.customerName}
                                    </h3>
                                  </li>
                                  <li className="contactbox">
                                    <div>
                                      <ul>
                                        <li>
                                          {this.state.isNumberMasking ===
                                            true &&
                                            this.state.tenantMobileMasking ===
                                            true ? (
                                            <p
                                              title="Mobile No"
                                              className="num"
                                            >
                                              {handleMaskingNumber(
                                                this.state.mobileNo,
                                                this.state.maskingPrefix,
                                                this.state.maskingSufix
                                              )}
                                            </p>
                                          ) : (
                                            <p title="Mobile No">
                                              {this.state.mobileNo}
                                            </p>
                                          )}
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            ) : null}
                            <Tabs
                              onSelect={(index, label) => {
                                this.handleProductTypeTabChange(index);
                              }}
                              selected={this.state.productTypeTab}
                            >
                              {this.state.IsShoppingBagTab ?
                                (<Tab
                                  label={
                                    TranslationContext !== undefined
                                      ? TranslationContext.label.shoppingbag
                                      : "Shopping Bag"
                                  }
                                >
                                  <div className="shoppingbag">
                                    {this.state.shoppingBagData.length > 0 ? (
                                      <label
                                        className="selectalllabel"
                                        onClick={this.handleSelectAllProduct.bind(
                                          this,
                                          1
                                        )}
                                      >
                                        {TranslationContext !== undefined
                                          ? TranslationContext.label.selectall
                                          : "Select All"}
                                      </label>
                                    ) : null}
                                    <div className="prodtabl">
                                      {this.state.shoppingBagData
                                        ? this.state.shoppingBagData.map(
                                          (item, i) => {
                                            return (
                                              <div className="prodboxx" key={i}>
                                                <Checkbox
                                                  checked={
                                                    this.state.shoppingBagData[
                                                      i
                                                    ].isCheck
                                                  }
                                                  onChange={this.handleProductTabsChange.bind(
                                                    this,
                                                    1,
                                                    i,
                                                    0
                                                  )}
                                                >
                                                  <Popover
                                                    overlayClassName="antcustom ant-prodesc"
                                                    content={
                                                      <ProductHoverItem
                                                        cardConfigData={
                                                          this.state
                                                            .cardConfigData
                                                        }
                                                        item={item}
                                                        TranslationContext={
                                                          this.state
                                                            .translateLanguage
                                                            .default
                                                        }
                                                      />
                                                    }
                                                    placement="left"
                                                  >
                                                    <img
                                                      src={encodeURI(
                                                        item.imageURL
                                                      )}
                                                      className="ladyimg"
                                                      alt="product-image"
                                                      onError={this.handleProductNoImage.bind(
                                                        this
                                                      )}
                                                    />
                                                  </Popover>
                                                </Checkbox>
                                                <div
                                                  style={{
                                                    position: "relative",
                                                  }}
                                                >
                                                  {item.brandName &&
                                                    this.state.cardConfigData
                                                      .Brand ? (
                                                    <h3 title={item.brandName}>
                                                      {item.brandName}
                                                    </h3>
                                                  ) : null}
                                                  {item.productName &&
                                                    this.state.cardConfigData
                                                      .ItemName ? (
                                                    <h4
                                                      title={item.productName}
                                                    >
                                                      {item.productName}
                                                    </h4>
                                                  ) : null}
                                                  {item.price &&
                                                    this.state.cardConfigData
                                                      .Price ? (
                                                    <span>
                                                      {Number(
                                                        item.price
                                                      ).toLocaleString(
                                                        "en-IN",
                                                        {
                                                          style: "currency",
                                                          currency: "INR",
                                                          minimumFractionDigits: 0,
                                                        }
                                                      )}
                                                    </span>
                                                  ) : null}
                                                  <img
                                                    src={Cancelico}
                                                    className="cancelico"
                                                    alt="Cancel Ico"
                                                    disabled={
                                                      this.state.isButtonClick
                                                    }
                                                    onClick={this.handleRemoveProduct.bind(
                                                      this,
                                                      item.uniqueItemCode,
                                                      "S"
                                                    )}
                                                  />
                                                </div>
                                              </div>
                                            );
                                          }
                                        )
                                        : null}
                                      {this.state.shoppingBagData.length === 0 &&
                                        this.state.isProductLoading === false ? (
                                        <Empty
                                          image={Empty.PRESENTED_IMAGE_SIMPLE}
                                        />
                                      ) : null}
                                      {this.state.isProductLoading ? (
                                        <Spin
                                          style={{
                                            margin: "20px",
                                            display: "block",
                                          }}
                                        />
                                      ) : null}
                                    </div>
                                    {this.state.selectedShoppingBag.length > 0 ? (
                                      <div className="tabsbotbtn-box">
                                        <button
                                          type="button"
                                          className="tabsbotbtn"
                                          onClick={this.handleSendProductsOnChat.bind(
                                            this,
                                            false,
                                            "",
                                            "",
                                            "",
                                            null
                                          )}
                                        >
                                          {TranslationContext !== undefined
                                            ? TranslationContext.button.send
                                            : "SEND"}
                                        </button>
                                        <button
                                          disabled={
                                            this.state.isButtonClick
                                              ? true
                                              : false
                                          }
                                          type="button"
                                          className="tabsbotbtn"
                                          onClick={this.handleAddProductsToBagOrWishlist.bind(
                                            this,
                                            "shoppingbag"
                                          )}
                                        >
                                          {TranslationContext !== undefined
                                            ? TranslationContext.button
                                              .addtowishlist
                                            : "ADD To WISHLIST"}
                                        </button>
                                        <Popover
                                          overlayClassName="antcustom ant-prodesc"
                                          placement="topRight"
                                          content={
                                            <div
                                              // className="productdesc"
                                              className=""
                                              style={{
                                                display: "inline-flex",
                                              }}
                                            >
                                              <button
                                                type="button"
                                                className="tabsbotbtn"
                                                onClick={this.handleAddressModalOpen.bind(
                                                  this
                                                )}
                                              >
                                                {TranslationContext !== undefined
                                                  ? TranslationContext.button
                                                    .addtoshoppingbag
                                                  : "Add to Shopping Bag"}
                                              </button>
                                            </div>
                                          }
                                        >
                                          <button
                                            type="button"
                                            className="tabsbotbtn"
                                          >
                                            {TranslationContext !== undefined
                                              ? TranslationContext.button.buynow
                                              : "BUY NOW"}
                                          </button>
                                        </Popover>
                                      </div>
                                    ) : null}
                                  </div>
                                </Tab>)
                                : <></>}
                              {this.state.IsWishListTab ?
                                (<Tab
                                  label={
                                    TranslationContext !== undefined
                                      ? TranslationContext.a.wishlist
                                      : "Wishlist"
                                  }
                                >
                                  <div className="shoppingbag">
                                    {this.state.wishListData.length > 0 ? (
                                      <label
                                        className="selectalllabel"
                                        onClick={this.handleSelectAllProduct.bind(
                                          this,
                                          2
                                        )}
                                      >
                                        {TranslationContext !== undefined
                                          ? TranslationContext.label.selectall
                                          : "Select All"}
                                      </label>
                                    ) : null}
                                    <div className="prodtabl">
                                      {this.state.wishListData
                                        ? this.state.wishListData.map(
                                          (item, i) => {
                                            return (
                                              <div className="prodboxx" key={i}>
                                                <Checkbox
                                                  checked={
                                                    this.state.wishListData[i]
                                                      .isCheck
                                                  }
                                                  onChange={this.handleProductTabsChange.bind(
                                                    this,
                                                    2,
                                                    i,
                                                    0
                                                  )}
                                                >
                                                  <Popover
                                                    overlayClassName="antcustom ant-prodesc"
                                                    content={
                                                      <ProductHoverItem
                                                        cardConfigData={
                                                          this.state
                                                            .cardConfigData
                                                        }
                                                        item={item}
                                                        TranslationContext={
                                                          this.state
                                                            .translateLanguage
                                                            .default
                                                        }
                                                      />
                                                    }
                                                    placement="left"
                                                  >
                                                    <img
                                                      src={encodeURI(
                                                        item.imageURL
                                                      )}
                                                      className="ladyimg"
                                                      alt="Lady Img"
                                                      onError={this.handleProductNoImage.bind(
                                                        this
                                                      )}
                                                    />
                                                  </Popover>
                                                </Checkbox>
                                                <div
                                                  style={{
                                                    position: "relative",
                                                  }}
                                                >
                                                  {item.brandName &&
                                                    this.state.cardConfigData
                                                      .Brand ? (
                                                    <h3 title={item.brandName}>
                                                      {item.brandName}
                                                    </h3>
                                                  ) : null}
                                                  {item.productName &&
                                                    this.state.cardConfigData
                                                      .ItemName ? (
                                                    <h4
                                                      title={item.productName}
                                                    >
                                                      {item.productName}
                                                    </h4>
                                                  ) : null}
                                                  {item.price &&
                                                    this.state.cardConfigData
                                                      .Price ? (
                                                    <span>
                                                      {Number(
                                                        item.price
                                                      ).toLocaleString(
                                                        "en-IN",
                                                        {
                                                          style: "currency",
                                                          currency: "INR",
                                                          minimumFractionDigits: 0,
                                                        }
                                                      )}
                                                    </span>
                                                  ) : null}
                                                  <img
                                                    disabled={
                                                      this.state.isButtonClick
                                                    }
                                                    onClick={this.handleRemoveProduct.bind(
                                                      this,
                                                      item.uniqueItemCode,
                                                      "W"
                                                    )}
                                                    src={Cancelico}
                                                    className="cancelico"
                                                    alt="Cancel Ico"
                                                  />
                                                </div>
                                              </div>
                                            );
                                          }
                                        )
                                        : null}

                                      {this.state.wishListData.length === 0 &&
                                        this.state.isProductLoading === false ? (
                                        <Empty
                                          image={Empty.PRESENTED_IMAGE_SIMPLE}
                                        />
                                      ) : null}
                                      {this.state.isProductLoading ? (
                                        <Spin
                                          style={{
                                            margin: "20px",
                                            display: "block",
                                          }}
                                        />
                                      ) : null}
                                    </div>
                                    {this.state.selectedWishList.length > 0 ? (
                                      <div className="tabsbotbtn-box">
                                        <button
                                          type="button"
                                          className="tabsbotbtn"
                                          onClick={this.handleSendProductsOnChat.bind(
                                            this,
                                            false,
                                            "",
                                            "",
                                            "",
                                            null
                                          )}
                                        >
                                          {TranslationContext !== undefined
                                            ? TranslationContext.button.send
                                            : "SEND"}
                                        </button>
                                        <button
                                          type="button"
                                          className="tabsbotbtn"
                                          disabled={
                                            this.state.isButtonClick
                                              ? true
                                              : false
                                          }
                                          onClick={this.handleAddProductsToBagOrWishlist.bind(
                                            this,
                                            "wishlist"
                                          )}
                                        >
                                          {TranslationContext !== undefined
                                            ? TranslationContext.button.addtobag
                                            : "ADD To BAG"}
                                        </button>
                                        <Popover
                                          overlayClassName="antcustom ant-prodesc"
                                          placement="topRight"
                                          content={
                                            <div
                                              // className="productdesc"
                                              className=""
                                              style={{
                                                display: "inline-flex",
                                              }}
                                            >
                                              <button
                                                type="button"
                                                className="tabsbotbtn"
                                                onClick={this.handleAddressModalOpen.bind(
                                                  this
                                                )}
                                              >
                                                {TranslationContext !== undefined
                                                  ? TranslationContext.button
                                                    .addtoshoppingbag
                                                  : "Add to Shopping Bag"}
                                              </button>
                                            </div>
                                          }
                                        >
                                          <button
                                            onClick={this.handleBuyNowButtonClick.bind(
                                              this
                                            )}
                                            type="button"
                                            className="tabsbotbtn"
                                          >
                                            {TranslationContext !== undefined
                                              ? TranslationContext.button.buynow
                                              : "BUY NOW"}
                                          </button>
                                        </Popover>
                                      </div>
                                    ) : null}
                                  </div>
                                </Tab>)
                                : <></>}
                              {this.state.IsRecommendedTab ?
                                (<Tab
                                  //label="Recommended"
                                  label={
                                    TranslationContext !== undefined
                                      ? TranslationContext.a.recommended
                                      : "Recommended"
                                  }
                                >
                                  <div className="shoppingbag">
                                    {this.state.recommendedData.length > 0 ? (
                                      <label
                                        className="selectalllabel"
                                        onClick={this.handleSelectAllProduct.bind(
                                          this,
                                          3
                                        )}
                                      >
                                        {TranslationContext !== undefined
                                          ? TranslationContext.label.selectall
                                          : "Select All"}
                                      </label>
                                    ) : null}
                                    <div className="prodtabl">
                                      {this.state.recommendedData
                                        ? this.state.recommendedData.map(
                                          (item, i) => {
                                            return (
                                              <div className="prodboxx" key={i}>
                                                <Checkbox
                                                  checked={
                                                    this.state.recommendedData[
                                                      i
                                                    ].isCheck
                                                  }
                                                  onChange={this.handleProductTabsChange.bind(
                                                    this,
                                                    3,
                                                    i,
                                                    0
                                                  )}
                                                >
                                                  <Popover
                                                    overlayClassName="antcustom ant-prodesc"
                                                    content={
                                                      <ProductHoverItem
                                                        cardConfigData={
                                                          this.state
                                                            .cardConfigData
                                                        }
                                                        item={item}
                                                        TranslationContext={
                                                          this.state
                                                            .translateLanguage
                                                            .default
                                                        }
                                                      />
                                                    }
                                                    placement="left"
                                                  >
                                                    <img
                                                      src={encodeURI(
                                                        item.imageURL
                                                      )}
                                                      className="ladyimg"
                                                      alt="Product-image"
                                                      onError={this.handleProductNoImage.bind(
                                                        this
                                                      )}
                                                    />
                                                  </Popover>
                                                </Checkbox>
                                                <div
                                                  style={{
                                                    position: "relative",
                                                  }}
                                                >
                                                  {item.brandName &&
                                                    this.state.cardConfigData
                                                      .Brand ? (
                                                    <h3 title={item.brandName}>
                                                      {item.brandName}
                                                    </h3>
                                                  ) : null}
                                                  {item.productName &&
                                                    this.state.cardConfigData
                                                      .ItemName ? (
                                                    <h4
                                                      title={item.productName}
                                                    >
                                                      {item.productName}
                                                    </h4>
                                                  ) : null}
                                                  {item.price &&
                                                    this.state.cardConfigData
                                                      .Price ? (
                                                    <span>
                                                      {Number(
                                                        item.price
                                                      ).toLocaleString(
                                                        "en-IN",
                                                        {
                                                          style: "currency",
                                                          currency: "INR",
                                                          minimumFractionDigits: 0,
                                                        }
                                                      )}
                                                    </span>
                                                  ) : null}
                                                  {/* <img
                                                      disabled={
                                                        this.state.isButtonClick
                                                      }
                                                      onClick={this.handleRemoveProduct.bind(
                                                        this,
                                                        item.uniqueItemCode,
                                                        "R"
                                                      )}
                                                      src={Cancelico}
                                                      className="cancelico"
                                                      alt="Cancel Ico"
                                                    /> */}
                                                </div>
                                              </div>
                                            );
                                          }
                                        )
                                        : null}

                                      {this.state.recommendedData.length === 0 &&
                                        this.state.isProductLoading === false ? (
                                        <Empty
                                          image={Empty.PRESENTED_IMAGE_SIMPLE}
                                        />
                                      ) : null}
                                      {this.state.isProductLoading ? (
                                        <Spin
                                          style={{
                                            margin: "20px",
                                            display: "block",
                                          }}
                                        />
                                      ) : null}
                                    </div>
                                    {this.state.selectedRecommended.length > 0 ? (
                                      <div className="tabsbotbtn-box">
                                        <button
                                          type="button"
                                          className="tabsbotbtn"
                                          onClick={this.handleSendProductsOnChat.bind(
                                            this,
                                            false,
                                            "",
                                            "",
                                            "",
                                            null
                                          )}
                                        >
                                          {TranslationContext !== undefined
                                            ? TranslationContext.button.send
                                            : "SEND"}
                                        </button>
                                        <button
                                          type="button"
                                          className="tabsbotbtn"
                                          disabled={
                                            this.state.isButtonClick
                                              ? true
                                              : false
                                          }
                                          onClick={this.handleAddRecommendationToWishlist.bind(
                                            this
                                          )}
                                        >
                                          {TranslationContext !== undefined
                                            ? TranslationContext.button
                                              .addtowishlist
                                            : "ADD To WISHLIST"}
                                        </button>
                                        <Popover
                                          overlayClassName="antcustom ant-prodesc"
                                          placement="topRight"
                                          content={
                                            <div
                                              // className="productdesc"
                                              className=""
                                              style={{
                                                display: "inline-flex",
                                              }}
                                            >
                                              <button
                                                type="button"
                                                className="tabsbotbtn"
                                                onClick={this.handleAddressModalOpen.bind(
                                                  this
                                                )}
                                              >
                                                {TranslationContext !== undefined
                                                  ? TranslationContext.button
                                                    .addtoshoppingbag
                                                  : "Add to Shopping Bag"}
                                              </button>
                                            </div>
                                          }
                                        >
                                          <button
                                            onClick={this.handleBuyNowButtonClick.bind(
                                              this
                                            )}
                                            type="button"
                                            className="tabsbotbtn"
                                          >
                                            {TranslationContext !== undefined
                                              ? TranslationContext.button.buynow
                                              : "BUY NOW"}
                                          </button>
                                        </Popover>
                                      </div>
                                    ) : null}
                                  </div>
                                </Tab>)
                                : <></>}
                              {this.state.IsShoppingCartTab && (this.state.sourceType === 'cb' || this.state.IsShoppingCartTabEnableForWB) ?
                                (<Tab
                                  //label="ShoppingCart"
                                  label={
                                    TranslationContext !== undefined
                                      ? TranslationContext.a.shoppingcart
                                      : "ShoppingCart"
                                  }
                                >
                                  <div className="shoppingbag">
                                    <div className="prodtabl">
                                      {this.state.cartItem
                                        ? this.state.cartItem.map((item, s) => {
                                          return (
                                            <div className="prodboxx">
                                              <div className="cart-detail-container">
                                                <div className="cart-item-detail">
                                                  <div className="item-image">
                                                    <img
                                                      src={encodeURI(
                                                        item.catalogueUrl.length > 0 &&
                                                          item.catalogueUrl[0] !== undefined ?
                                                          item.catalogueUrl[0] : item.defaultImageUrl
                                                      )}
                                                      className="cart-prod-img"
                                                    />
                                                  </div>
                                                  <div className="item-detail">
                                                    <span className="item-name">
                                                      <span>{item.name}</span>
                                                    </span>
                                                    <div className="item-size-wrap">
                                                      <span className="item-size">
                                                        Size:
                                                        {item.size}
                                                      </span>
                                                      <span className="item-color">
                                                        Color:
                                                        {item.colour}
                                                      </span>
                                                      <span className="item-price">
                                                        {Number(
                                                          item.price
                                                        ).toLocaleString(
                                                          "en-IN",
                                                          {
                                                            style: "currency",
                                                            currency: "INR",
                                                            minimumFractionDigits: 0,
                                                          }
                                                        )}
                                                      </span>
                                                    </div>
                                                    <div className="count-wrap">
                                                      <div className="remove_item">
                                                        <span
                                                          className="remove"
                                                          onClick={this.handleRemoveItem.bind(
                                                            this,
                                                            item.barCode
                                                          )}
                                                        >
                                                          Remove
                                                        </span>
                                                      </div>
                                                      <div className="update-quant">
                                                        <span
                                                          className={
                                                            item.selectedQuantity >
                                                              1
                                                              ? ""
                                                              : "dec_btn_disable"
                                                          }
                                                          onClick={
                                                            item.selectedQuantity >
                                                            1 &&
                                                            this.handleUpdateQuantity.bind(
                                                              this,
                                                              item.barCode,
                                                              "minus"
                                                            )
                                                          }
                                                        >
                                                          <img
                                                            src={minus}
                                                          ></img>
                                                        </span>
                                                        <div className="cart_pro_count">
                                                          {
                                                            item.selectedQuantity
                                                          }
                                                        </div>
                                                        <span
                                                          className={
                                                            item.selectedQuantity !==
                                                              item.availability
                                                              ? ""
                                                              : "dec_btn_disable"
                                                          }
                                                          onClick={
                                                            item.selectedQuantity !==
                                                              item.availability
                                                              ? this.handleUpdateQuantity.bind(
                                                                this,
                                                                item.barCode,
                                                                "plus"
                                                              )
                                                              : null
                                                          }
                                                        >
                                                          <img src={plus}></img>
                                                        </span>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          );
                                        })
                                        : null}
                                      {this.state.cartItem.length === 0 &&
                                        this.state.isProductLoading === false ? (
                                        <Empty
                                          image={Empty.PRESENTED_IMAGE_SIMPLE}
                                        />
                                      ) : null}
                                      {this.state.isProductLoading ? (
                                        <Spin
                                          style={{
                                            margin: "20px",
                                            display: "block",
                                          }}
                                        />
                                      ) : null}
                                    </div>
                                    {this.state.cartItem.length > 0 && this.state.sourceType === 'cb' ?
                                      <button
                                        className="addBtn-cart-hierarchy"
                                        type="button"
                                        onClick={this.handleSendCartHSM.bind(this)}
                                      >
                                        {TranslationContext !== undefined
                                          ? TranslationContext.button.saveandnext
                                          : "SEND CART"}
                                      </button> : null}
                                  </div>
                                </Tab>)
                                : <></>}
                            </Tabs>
                          </div>
                          <div className="productmobile">
                            <div className="profi">
                              <ul className="nameplate">
                                <li>
                                  <label
                                    onClick={
                                      this.state.isMobileView
                                        ? this.handleMainTabChange.bind(this, 1)
                                        : null
                                    }
                                    onMouseEnter={this.handleNameHover.bind(
                                      this
                                    )}
                                    className="namelabel"
                                    style={{
                                      backgroundColor: this.state.selectedColor,
                                    }}
                                  >
                                    {this.state.customerName.charAt(0)}
                                  </label>
                                </li>
                                <li
                                  onClick={
                                    this.state.isMobileView
                                      ? this.handleMainTabChange.bind(this, 1)
                                      : null
                                  }
                                >
                                  <h3>
                                    {this.state.customerNameProfile
                                      ? this.state.customerNameProfile
                                      : this.state.customerName}
                                  </h3>
                                  <span>
                                    {TranslationContext !== undefined
                                      ? TranslationContext.span.tier
                                      : "Tier"}
                                    : <b>{this.state.customerTier}</b>
                                  </span>
                                </li>
                                {this.state.ProfileProductTab === 2 ? (
                                  <li className="contactbox">
                                    <div>
                                      <ul>
                                        {this.state.customerEmailID ? (
                                          <li>
                                            <p>{this.state.customerEmailID}</p>
                                          </li>
                                        ) : null}
                                        <li>
                                          {/* <p>{this.state.customerMobileNo}</p> */}
                                          {this.state.isNumberMasking &&
                                            this.state.tenantMobileMasking ? (
                                            <p>
                                              {handleMaskingNumber(
                                                this.state.mobileNo,
                                                this.state.maskingPrefix,
                                                this.state.maskingSufix
                                              )}
                                            </p>
                                          ) : (
                                            <p>{this.state.mobileNo}</p>
                                          )}
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                ) : null}
                              </ul>
                            </div>
                            <div>
                              <Collapse
                                accordion
                                activeKey={this.state.mobileCollpseActive}
                                onChange={this.handleMobileCollpseChange.bind(
                                  this
                                )}
                              >
                                {this.state.IsShoppingBagTab ?
                                  <Panel
                                    header={
                                      <div>
                                        <label>Shopping Bag</label>
                                        <div style={{ float: "right" }}>
                                          <label className="plushMobile">
                                            ₹{this.state.totalShoppingBag}
                                          </label>
                                          <img
                                            src={Blueplus}
                                            alt="Product"
                                            width="20"
                                            height="20"
                                            onClick={this.handleOpenProcessModal.bind(
                                              this
                                            )}
                                          />
                                        </div>
                                      </div>
                                    }
                                    key="1"
                                  >
                                    <Spin spinning={this.state.insightsLoader}>
                                      <div className="insightsbox">
                                        {this.state.shoppingBagData > 0 ? (
                                          <div style={{ float: "right" }}>
                                            <label className="plushMobile">
                                              ₹{this.state.totalShoppingBag}
                                            </label>
                                            <img
                                              src={Blueplus}
                                              alt="Product"
                                              width="25"
                                              height="25"
                                              onClick={this.handleOpenProcessModal.bind(
                                                this
                                              )}
                                            />
                                          </div>
                                        ) : null}
                                        <ul className="datawish">
                                          {this.state.shoppingBagData
                                            ? this.state.shoppingBagData.map(
                                              (data, s) => {
                                                return (
                                                  <li className="" key={s}>
                                                    <div className="left d-flex">
                                                      <img
                                                        src={encodeURI(
                                                          data.imageURL
                                                        )}
                                                        alt="Product"
                                                        width="25"
                                                        height="25"
                                                        onError={this.handleMobileProductNoImage.bind(
                                                          this
                                                        )}
                                                      />
                                                      <div className="text1">
                                                        <span>
                                                          {data.categoryName}
                                                        </span>
                                                        <span>
                                                          <b>
                                                            {data.productName
                                                              .length > 27
                                                              ? data.productName
                                                                .substr(0, 27)
                                                                .concat("...")
                                                              : data.productName}
                                                          </b>
                                                        </span>
                                                        {data.uniqueItemCode ? (
                                                          <span>
                                                            {
                                                              data.uniqueItemCode
                                                            }
                                                          </span>
                                                        ) : null}
                                                      </div>
                                                    </div>
                                                    <div className="right text2">
                                                      <div
                                                        style={{
                                                          float: "left",
                                                        }}
                                                      >
                                                        <span
                                                          className={
                                                            data.isCheck
                                                              ? "blueClr"
                                                              : ""
                                                          }
                                                        >
                                                          &#8377;
                                                          <b>{data.price}</b>
                                                        </span>
                                                        <b>
                                                          <span
                                                            className={
                                                              data.isCheck
                                                                ? "blueClr"
                                                                : ""
                                                            }
                                                          >
                                                            Q 1
                                                          </span>
                                                        </b>
                                                      </div>
                                                      {data.isCheck ? (
                                                        <img
                                                          src={RightBlue}
                                                          alt="Product"
                                                          width="15"
                                                          height="15"
                                                          className="mobileShapeR"
                                                          onClick={this.handleProductTabsChange.bind(
                                                            this,
                                                            1,
                                                            s,
                                                            data.uniqueItemCode
                                                          )}
                                                        />
                                                      ) : (
                                                        <img
                                                          src={RightShape}
                                                          alt="Product"
                                                          width="15"
                                                          height="15"
                                                          className="mobileShapeR"
                                                          onClick={this.handleProductTabsChange.bind(
                                                            this,
                                                            1,
                                                            s,
                                                            data.uniqueItemCode
                                                          )}
                                                        />
                                                      )}
                                                    </div>
                                                  </li>
                                                );
                                              }
                                            )
                                            : null}
                                          {this.state.shoppingBagData.length ===
                                            0 &&
                                            this.state.isProductLoading ===
                                            false ? (
                                            <Empty
                                              image={Empty.PRESENTED_IMAGE_SIMPLE}
                                            />
                                          ) : null}
                                        </ul>
                                      </div>
                                    </Spin>
                                  </Panel>
                                  : <></>}
                                <div className="tabline"></div>
                                {this.state.IsWishListTab ?
                                  <Panel
                                    header={
                                      <div>
                                        <label>Wish list</label>
                                        <div style={{ float: "right" }}>
                                          <label className="plushMobile">
                                            ₹{this.state.totalWishList}
                                          </label>
                                          <img
                                            src={Blueplus}
                                            alt="Product"
                                            width="20"
                                            height="20"
                                            onClick={this.handleOpenWishListModal.bind(
                                              this
                                            )}
                                          />
                                        </div>
                                      </div>
                                    }
                                    key="2"
                                  >
                                    <Spin
                                      spinning={this.state.orderDetailsLoader}
                                    >
                                      <div className="ordersbox">
                                        <ul className="datawish">
                                          {this.state.wishListData
                                            ? this.state.wishListData.map(
                                              (data, w) => {
                                                return (
                                                  <li className="" key={w}>
                                                    <div className="left d-flex">
                                                      <img
                                                        src={encodeURI(
                                                          data.imageURL
                                                        )}
                                                        alt="Product"
                                                        width="25"
                                                        height="25"
                                                        onError={this.handleMobileProductNoImage.bind(
                                                          this
                                                        )}
                                                      />
                                                      <div className="text1">
                                                        <span>
                                                          {data.categoryName}
                                                        </span>
                                                        <span>
                                                          <b>
                                                            {data.productName}
                                                          </b>
                                                        </span>
                                                        {data.uniqueItemCode ? (
                                                          <span>
                                                            {
                                                              data.uniqueItemCode
                                                            }
                                                          </span>
                                                        ) : null}
                                                      </div>
                                                    </div>
                                                    <div className="right text2">
                                                      <div
                                                        style={{
                                                          float: "left",
                                                        }}
                                                      >
                                                        <span
                                                          className={
                                                            data.isCheck
                                                              ? "blueClr"
                                                              : ""
                                                          }
                                                        >
                                                          &#8377;
                                                          <b>{data.price}</b>
                                                        </span>
                                                        <b>
                                                          <span
                                                            className={
                                                              data.isCheck
                                                                ? "blueClr"
                                                                : ""
                                                            }
                                                          >
                                                            Q 1
                                                          </span>
                                                        </b>
                                                      </div>

                                                      {data.isCheck ? (
                                                        <img
                                                          src={RightBlue}
                                                          alt="Product"
                                                          width="15"
                                                          height="15"
                                                          className="mobileShapeR"
                                                          onClick={this.handleProductTabsChange.bind(
                                                            this,
                                                            2,
                                                            w,
                                                            data.uniqueItemCode
                                                          )}
                                                        />
                                                      ) : (
                                                        <img
                                                          src={RightShape}
                                                          alt="Product"
                                                          width="15"
                                                          height="15"
                                                          className="mobileShapeR"
                                                          onClick={this.handleProductTabsChange.bind(
                                                            this,
                                                            2,
                                                            w,
                                                            data.uniqueItemCode
                                                          )}
                                                        />
                                                      )}
                                                    </div>
                                                  </li>
                                                );
                                              }
                                            )
                                            : null}
                                          {this.state.wishListData.length === 0 &&
                                            this.state.isProductLoading ===
                                            false ? (
                                            <Empty
                                              image={Empty.PRESENTED_IMAGE_SIMPLE}
                                            />
                                          ) : null}
                                        </ul>
                                      </div>
                                    </Spin>
                                  </Panel>
                                  : <></>}
                                {this.state.IsRecommendedTab ?
                                  <Panel
                                    header={
                                      <div>
                                        <label>Recommended</label>
                                        <div style={{ float: "right" }}>
                                          <label className="plushMobile">
                                            ₹{this.state.totalRecommend}
                                          </label>
                                          <img
                                            src={Blueplus}
                                            alt="Product"
                                            width="20"
                                            height="20"
                                            onClick={this.handleOpenRecommendedListModal.bind(
                                              this
                                            )}
                                          />
                                        </div>
                                      </div>
                                    }
                                    key="3"
                                  >
                                    <Spin
                                      spinning={this.state.orderDetailsLoader}
                                    >
                                      <div className="ordersbox">
                                        <ul className="datawish">
                                          {this.state.recommendedData
                                            ? this.state.recommendedData.map(
                                              (data, r) => {
                                                return (
                                                  <li className="" key={r}>
                                                    <div className="left d-flex">
                                                      <img
                                                        src={encodeURI(
                                                          data.imageURL
                                                        )}
                                                        alt="Product"
                                                        width="25"
                                                        height="25"
                                                        onError={this.handleMobileProductNoImage.bind(
                                                          this
                                                        )}
                                                      />
                                                      <div className="text1">
                                                        <span>
                                                          {data.categoryName}
                                                        </span>
                                                        <span>
                                                          <b>
                                                            {data.productName}
                                                          </b>
                                                        </span>
                                                        {data.uniqueItemCode ? (
                                                          <span>
                                                            {
                                                              data.uniqueItemCode
                                                            }
                                                          </span>
                                                        ) : null}
                                                      </div>
                                                    </div>
                                                    <div className="right text2">
                                                      <div
                                                        style={{
                                                          float: "left",
                                                        }}
                                                      >
                                                        <span
                                                          className={
                                                            data.isCheck
                                                              ? "blueClr"
                                                              : ""
                                                          }
                                                        >
                                                          &#8377;
                                                          <b>{data.price}</b>
                                                        </span>
                                                        <b>
                                                          <span
                                                            className={
                                                              data.isCheck
                                                                ? "blueClr"
                                                                : ""
                                                            }
                                                          >
                                                            Q 1
                                                          </span>
                                                        </b>
                                                      </div>

                                                      {data.isCheck ? (
                                                        <img
                                                          src={RightBlue}
                                                          alt="Product"
                                                          width="15"
                                                          height="15"
                                                          className="mobileShapeR"
                                                          onClick={this.handleProductTabsChange.bind(
                                                            this,
                                                            3,
                                                            r,
                                                            data.uniqueItemCode
                                                          )}
                                                        />
                                                      ) : (
                                                        <img
                                                          src={RightShape}
                                                          alt="Product"
                                                          width="15"
                                                          height="15"
                                                          className="mobileShapeR"
                                                          onClick={this.handleProductTabsChange.bind(
                                                            this,
                                                            3,
                                                            r,
                                                            data.uniqueItemCode
                                                          )}
                                                        />
                                                      )}
                                                    </div>
                                                  </li>
                                                );
                                              }
                                            )
                                            : null}
                                          {this.state.recommendedData.length ===
                                            0 &&
                                            this.state.isProductLoading ===
                                            false ? (
                                            <Empty
                                              image={Empty.PRESENTED_IMAGE_SIMPLE}
                                            />
                                          ) : null}
                                        </ul>
                                      </div>
                                    </Spin>
                                  </Panel>
                                  : <></>}
                                {/* SHOPPINGCART MOBILE VIEW */}
                                {this.state.IsShoppingCartTab && (this.state.sourceType === 'cb' || this.state.IsShoppingCartTabEnableForWB) ?
                                  <Panel
                                    header={
                                      <div>
                                        <label>ShoppingCart</label>
                                        <div style={{ float: "right" }}>
                                          <label className="plushMobile">
                                            ₹{this.state.totalShoppingCart}
                                          </label>
                                          <img
                                            src={Blueplus}
                                            alt="Product"
                                            width="20"
                                            height="20"
                                            onClick={this.handleOpenShoppingCartModal.bind(
                                              this
                                            )}
                                          />
                                        </div>
                                      </div>
                                    }
                                    key="4"
                                  >
                                    <Spin spinning={this.state.insightsLoader}>
                                      <div className="ordersbox">
                                        {this.state.cartItem > 0 ? (
                                          <div style={{ float: "right" }}>
                                            {/* <label className="plushMobile">
                                            ₹{this.state.totalShoppingBag}
                                          </label> */}
                                            <img
                                              src={Blueplus}
                                              alt="Product"
                                              width="25"
                                              height="25"
                                              onClick={this.handleOpenShoppingCartModal.bind(
                                                this
                                              )}
                                            />
                                          </div>
                                        ) : null}
                                        <ul className="datawish">
                                          {this.state.cartItem
                                            ? this.state.cartItem.map(
                                              (item, s) => {
                                                return (
                                                  <div
                                                    className="prodboxx"
                                                    key={i}
                                                  >
                                                    <div className="cart-detail-container">
                                                      <div className="cart-item-detail">
                                                        <div className="item-image-mob">
                                                          <img
                                                            src={encodeURI(
                                                              item.catalogueUrl.length > 0 &&
                                                                item.catalogueUrl[0] !== undefined ?
                                                                item.catalogueUrl[0] : item.defaultImageUrl
                                                            )}
                                                            className="cart-prod-img"
                                                          />
                                                        </div>
                                                        <div className="item-detail-mob">
                                                          <span className="item-name-mob">
                                                            <span>
                                                              {item.name}
                                                            </span>
                                                          </span>
                                                          <div className="item-size-wrap-mob">
                                                            <span className="item-size-mob">
                                                              Size:
                                                              {item.size}
                                                            </span>
                                                            <span className="item-color-mob">
                                                              Color:
                                                              {item.colour}
                                                            </span>
                                                            <span className="item-price-mob">
                                                              {Number(
                                                                item.price
                                                              ).toLocaleString(
                                                                "en-IN",
                                                                {
                                                                  style:
                                                                    "currency",
                                                                  currency:
                                                                    "INR",
                                                                  minimumFractionDigits: 0,
                                                                }
                                                              )}
                                                            </span>
                                                          </div>
                                                          <div className="count-wrap-mob">
                                                            <div className="remove_item-mob">
                                                              <span
                                                                className="remove-mob"
                                                                onClick={this.handleRemoveItem.bind(
                                                                  this,
                                                                  item.barCode
                                                                )}
                                                              >
                                                                Remove
                                                              </span>
                                                            </div>
                                                            <div className="update-quant">
                                                              <span
                                                                className={
                                                                  item.selectedQuantity >
                                                                    1
                                                                    ? ""
                                                                    : "dec_btn_disable"
                                                                }
                                                                onClick={
                                                                  item.selectedQuantity >
                                                                  1 &&
                                                                  this.handleUpdateQuantity.bind(
                                                                    this,
                                                                    item.barCode
                                                                  )
                                                                }
                                                              >
                                                                <img
                                                                  src={minus}
                                                                ></img>
                                                              </span>
                                                              <div className="cart_pro_count">
                                                                {
                                                                  item.selectedQuantity
                                                                }
                                                              </div>
                                                              <span
                                                                className={
                                                                  item.selectedQuantity !==
                                                                    item.availability
                                                                    ? ""
                                                                    : "dec_btn_disable"
                                                                }
                                                                onClick={
                                                                  item.selectedQuantity !==
                                                                    item.availability
                                                                    ? this.handleUpdateQuantity.bind(
                                                                      this,
                                                                      item.barCode,
                                                                      "plus"
                                                                    )
                                                                    : null
                                                                }
                                                              >
                                                                <img
                                                                  src={plus}
                                                                ></img>
                                                              </span>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                );
                                              }
                                            )
                                            : null}
                                          {this.state.cartItem.length === 0 &&
                                            this.state.isProductLoading ===
                                            false ? (
                                            <Empty
                                              image={Empty.PRESENTED_IMAGE_SIMPLE}
                                            />
                                          ) : null}
                                        </ul>
                                      </div>
                                    </Spin>
                                    {this.state.cartItem.length > 0 && this.state.sourceType === 'cb' ?
                                      <button
                                        type="button"
                                        className="commentbtn commentbtn-text"
                                        onClick={this.handleSendCartHSM.bind(this)}
                                      //style={{ marginTop: "5px" }}
                                      >
                                        {TranslationContext !== undefined
                                          ? TranslationContext.button.addcomment
                                          : "SEND CART"}
                                      </button> : null}
                                  </Panel>
                                  : <></>}
                              </Collapse>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      {this.state.isCustomerComment === true ? (
                        <div
                          className={
                            this.state.ProfileProductTab === 2
                              ? "tab-pane fade active show"
                              : "tab-pane fade"
                          }
                          id="customer-comment"
                          role="tabpanel"
                          aria-labelledby="customer-comment"
                        >
                          <div className="productmobile">
                            <div className="profi">
                              <ul className="nameplate">
                                <li>
                                  <label
                                    onClick={
                                      this.state.isMobileView
                                        ? this.handleMainTabChange.bind(this, 2)
                                        : null
                                    }
                                    onMouseEnter={this.handleNameHover.bind(
                                      this
                                    )}
                                    className="namelabel"
                                    style={{
                                      backgroundColor: this.state.selectedColor,
                                    }}
                                  >
                                    {this.state.customerName.charAt(0)}
                                  </label>
                                </li>
                                <li
                                  onClick={
                                    this.state.isMobileView
                                      ? this.handleMainTabChange.bind(this, 1)
                                      : null
                                  }
                                >
                                  <h3>
                                    {this.state.customerNameProfile
                                      ? this.state.customerNameProfile
                                      : this.state.customerName}
                                  </h3>
                                  <span>
                                    {TranslationContext !== undefined
                                      ? TranslationContext.span.tier
                                      : "Tier"}
                                    : <b>{this.state.customerTier}</b>
                                  </span>
                                </li>
                                {this.state.ProfileProductTab === 2 ? (
                                  <li className="contactbox">
                                    <div>
                                      <ul>
                                        {this.state.customerEmailID ? (
                                          <li>
                                            <p>{this.state.customerEmailID}</p>
                                          </li>
                                        ) : null}
                                        <li>
                                          {this.state.isNumberMasking &&
                                            this.state.tenantMobileMasking ? (
                                            <p>
                                              {handleMaskingNumber(
                                                this.state.mobileNo,
                                                this.state.maskingPrefix,
                                                this.state.maskingSufix
                                              )}
                                            </p>
                                          ) : (
                                            <p>{this.state.mobileNo}</p>
                                          )}
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                ) : null}
                              </ul>
                            </div>
                          </div>
                          <div
                            className={`col-12   ${this.state.isMobileView && "col-md-12"
                              }  col-xs-12 col-sm-4`}
                            style={{ marginTop: "20px" }}
                          >
                            <label className="addnotes">
                              Please Put your comment
                            </label>
                            <textarea
                              className="Add-Comment-textarea"
                              placeholder={
                                TranslationContext !== undefined
                                  ? TranslationContext.placeholder.addnotes
                                  : "Please Put your comment"
                              }
                              name="NoteAddComment"
                              value={this.state.NoteAddComment}
                              onChange={this.handleNoteOnChange}
                            ></textarea>
                            {this.state.NoteAddComment.length === 0 && (
                              <p
                                style={{
                                  color: "red",
                                  marginBottom: "0px",
                                }}
                              >
                                {this.state.AddNoteValidation}
                              </p>
                            )}
                            <button
                              type="button"
                              className="commentbtn commentbtn-text"
                              onClick={this.handleNoteAddComments.bind(this)}
                              style={{ marginTop: "5px" }}
                            >
                              {TranslationContext !== undefined
                                ? TranslationContext.button.addcomment
                                : "ADD COMMENT"}
                            </button>
                          </div>
                        </div>
                      ) : null}
                      {this.state.isCreateTicket === true ? (
                        <div
                          className={
                            this.state.ProfileProductTab === 3
                              ? "tab-pane fade active show"
                              : "tab-pane fade"
                          }
                          id="customer-ticket"
                          role="tabpanel"
                          aria-labelledby="customer-ticket"
                        >
                          <label className="Create-store-text">
                            {TranslationContext !== undefined
                              ? TranslationContext.label.createusers
                              : "TICKET'S"}
                          </label>
                          {this.state.GeneratedTicketId > 0 ?
                            <label style={{ marginLeft: "20px", color: "blue" }}>
                              ( Ticket Id: {this.state.GeneratedTicketId} )
                            </label>
                            : null}
                          <Collapse
                            accordion={true}
                            onChange={this.handleCollapseChange}
                            bordered={false}
                            activeKey={this.state.activeCollapse}
                          >
                            <Panel
                              key="1"
                              header={
                                <label className="designation-name">
                                  CREATE TICKET
                                </label>
                              }
                            >
                              <div className="collapsePanelTicket">
                                {CreateTicketCollapse}
                              </div>
                            </Panel>
                            <Panel
                              key="2"
                              header={
                                <label className="designation-name">
                                  PAST TICKET'S
                                </label>
                              }
                            >
                              <div className="collapsePanelTicket">
                                {ShowTicketCollapse}
                              </div>
                            </Panel>
                          </Collapse>
                        </div>
                      ) : null}
                      {this.state.isRetarget === true ? (
                        <div
                          className={
                            this.state.ProfileProductTab === 4
                              ? "tab-pane fade active show"
                              : "tab-pane fade"
                          }
                          id="retarget_"
                          role="tabpanel"
                          aria-labelledby="retarget_"
                        >
                          <label className="Create-store-text">
                            {TranslationContext !== undefined
                              ? TranslationContext.label.createusers
                              : "Retarget"}
                          </label>
                          <div className="searchtarget">
                            {/* <img
                              src={searchico}
                              alt="SearchBlueImg"
                              className="srch-imge"
                            /> */}
                            <input type='number' placeholder="Search Ticket" onChange={this.handleRetargetSearch} />
                          </div>
                          <div className="collapsePanelTicket">
                            {ShowTargetList}
                          </div>
                        </div>
                      ) : null}

                    </div>
                  </div>
                </div>
              ) : null
            ) : null}
          </div>
        </div>
        {/* Mobile View Message Suggestion*/}
        <Modal
          open={this.state.suggestionModalMob}
          onClose={this.onCloseMobSuggestionModal}
          center
          modalId={
            this.state.isMobileView
              ? "sharecamp-popup"
              : "desktop-conf-mob-popup"
          }
          overlayId={
            this.state.isMobileView ? "logout-ovrly" : "mobile-tabs-overlay"
          }
          classNames={{
            modal: this.state.isMobileView ? "nexticon-popupbottom" : "",
          }}
        >
          <div className="">
            <div className="input-group" style={{ background: "none" }}>
              <p className="cls-p-conf-mob">
                {TranslationContext !== undefined
                  ? TranslationContext.p.areyousureandwanttosend
                  : "Are you sure & want to send"}
                ?
              </p>
            </div>
            <hr
              style={{
                borderTop: "1px solid #bbb",
              }}
            ></hr>
            <p className="cls-p-sugg">{this.state.suggestionText}</p>
            <div className="chat-btn-conf-mob">
              <button
                className="butn-inv"
                onClick={this.onCloseMobSuggestionModal}
              >
                {TranslationContext !== undefined
                  ? TranslationContext.button.no
                  : "No"}
              </button>
              <button
                className="butn"
                onClick={this.handleSendMessageToCustomer.bind(
                  this,
                  this.state.suggestionText,
                  "",
                  "",
                  null
                )}
              >
                {TranslationContext !== undefined
                  ? TranslationContext.button.yes
                  : "Yes"}
                <img src={SendUp} alt="send" className="send-up float-none" />
                {this.state.isSendRecomended ? (
                  <FontAwesomeIcon
                    icon={faCircleNotch}
                    className="circular-loader ml-2"
                    spin
                  />
                ) : (
                  ""
                )}
              </button>
            </div>
          </div>
        </Modal>
        {/* -----------------------Action Modal----------------- */}
        <Modal
          open={this.state.actionBtn}
          onClose={this.handleActionClose.bind(this)}
          closeIconId="close"
          modalId="actionmodalId"
          overlayId="logout-ovrly"
          classNames={{
            modal: this.state.onHoverName
              ? "actionmodalId-mr-add"
              : "actionmodalId-mr-remove",
          }}
        >
          <div style={{ padding: "0px 15px 0px 10px" }}>
            <div className="row">
              <label
                className={"actionmodallbl"}
                onClick={this.handleUpdateStoreManagerChatStatus.bind(this, 3)}
              >
                {TranslationContext !== undefined
                  ? TranslationContext.label.closechat
                  : "Close Chat"}
              </label>
            </div>
          </div>
        </Modal>
        {/* Address modal popup */}
        <Modal
          open={this.state.addressModal}
          onClose={this.handleAddressModalClose.bind(this)}
          center
          modalId="categoryEditModal"
          classNames={{
            modal:
              "order-popover order-popover-butns order-popover-address customaddpop",
          }}
        >
          <div style={{ padding: "15px" }}>
            <div className="popover-input-cntr">
              <div className="row">
                <div className="col-md-6">
                  <p>Name</p>
                  <input
                    type="text"
                    placeholder={"Enter Name"}
                    name="addressName"
                    autoComplete="off"
                    value={this.state.addressName}
                    onChange={this.handleTextOnchage}
                  // disabled={true}
                  />

                  {this.state.isName ? (
                    <p
                      className="non-deliverable"
                      style={{ marginTop: "0", textAlign: "left" }}
                    >
                      {this.state.isName}
                    </p>
                  ) : null}
                </div>
                <div className="col-md-6">
                  <p>Mobile No</p>
                  <input
                    type="text"
                    placeholder={"Enter Mobile No"}
                    autoComplete="off"
                    name="addressMobileNo"
                    value={this.state.addressMobileNo}
                    onChange={this.handleTextOnchage}
                    disabled={true}
                  />
                  {this.state.isAddressMobileNo ? (
                    <p
                      className="non-deliverable"
                      style={{ marginTop: "0", textAlign: "left" }}
                    >
                      {this.state.isAddressMobileNo}
                    </p>
                  ) : null}
                </div>
              </div>

              <div>
                <p>
                  {TranslationContext !== undefined
                    ? TranslationContext.p.address
                    : "Address"}
                </p>
                <textarea
                  placeholder="Enter Address"
                  name="shippingAddress"
                  autoComplete="off"
                  value={this.state.shippingAddress}
                  onChange={this.handleTextOnchage}
                ></textarea>
                {this.state.isAddress ? (
                  <p
                    className="non-deliverable"
                    style={{ marginTop: "0", textAlign: "left" }}
                  >
                    {this.state.isAddress}
                  </p>
                ) : null}
              </div>
              <div>
                <p>
                  {TranslationContext !== undefined
                    ? TranslationContext.p.landmark
                    : "Landmark"}
                </p>
                <input
                  type="text"
                  placeholder={
                    TranslationContext !== undefined
                      ? TranslationContext.placeholder.enterlandmark
                      : "Enter Landmark"
                  }
                  autoComplete="off"
                  name="shippingLandmark"
                  value={this.state.shippingLandmark}
                  onChange={this.handleTextOnchage}
                />
              </div>
              <div className="row">
                <div className="col-md-6">
                  <p>
                    {TranslationContext !== undefined
                      ? TranslationContext.p.landmark
                      : "Pin Code"}
                  </p>
                  <input
                    type="text"
                    placeholder={
                      TranslationContext !== undefined
                        ? TranslationContext.placeholder.enterpincode
                        : "Enter Pin Code"
                    }
                    name="shippingPinCode"
                    autoComplete="off"
                    maxLength={6}
                    value={this.state.shippingPinCode}
                    // onChange={this.handleTextOnchage}
                    onChange={this.handlePinCodeCheck.bind(this)}
                  />
                  {this.state.isPinCode ? (
                    <p
                      className="non-deliverable"
                      style={{ marginTop: "0", textAlign: "left" }}
                    >
                      {this.state.isPinCode}
                    </p>
                  ) : null}
                  {this.state.isPinCodeCheck ? (
                    <p
                      className="non-deliverable"
                      style={{ marginTop: "0", textAlign: "left" }}
                    >
                      {this.state.isPinCodeCheck}
                    </p>
                  ) : null}
                </div>
                <div className="col-md-6">
                  <p>
                    {TranslationContext !== undefined
                      ? TranslationContext.p.city
                      : "City"}
                  </p>
                  <input
                    type="text"
                    placeholder={
                      TranslationContext !== undefined
                        ? TranslationContext.placeholder.entercity
                        : "Enter City"
                    }
                    autoComplete="off"
                    name="shippingCity"
                    value={this.state.shippingCity}
                    onChange={this.handleTextOnchage}
                  />
                  {this.state.isCity ? (
                    <p
                      className="non-deliverable"
                      style={{ marginTop: "0", textAlign: "left" }}
                    >
                      {this.state.isCity}
                    </p>
                  ) : null}
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <p>
                    {TranslationContext !== undefined
                      ? TranslationContext.p.state
                      : "State"}
                  </p>
                  <input
                    type="text"
                    placeholder={
                      TranslationContext !== undefined
                        ? TranslationContext.placeholder.enterstate
                        : "Enter State"
                    }
                    name="shippingState"
                    autoComplete="off"
                    value={this.state.shippingState}
                    onChange={this.handleTextOnchage}
                  />
                  {this.state.isState ? (
                    <p
                      className="non-deliverable"
                      style={{ marginTop: "0", textAlign: "left" }}
                    >
                      {this.state.isState}
                    </p>
                  ) : null}
                </div>
                <div className="col-md-6">
                  <p>
                    {TranslationContext !== undefined
                      ? TranslationContext.p.country
                      : "Country"}
                  </p>
                  <input
                    type="text"
                    placeholder={
                      TranslationContext !== undefined
                        ? TranslationContext.placeholder.entercountry
                        : "Enter Country"
                    }
                    name="shippingCountry"
                    autoComplete="off"
                    value={this.state.shippingCountry}
                    onChange={this.handleTextOnchage}
                  />
                  {this.state.isCountry ? (
                    <p
                      className="non-deliverable"
                      style={{ marginTop: "0", textAlign: "left" }}
                    >
                      {this.state.isState}
                    </p>
                  ) : null}
                </div>
              </div>
            </div>

            <div className="row">
              <div
                className="ant-popover-buttons"
                style={{ marginLeft: "80px" }}
              >
                <button
                  type="button"
                  className="ant-btn ant-btn-sm"
                  onClick={this.handleAddressModalClose.bind(this)}
                >
                  <span>
                    {TranslationContext !== undefined
                      ? TranslationContext.span.cancel
                      : "Cancel"}
                  </span>
                </button>
                <button
                  type="button"
                  className="ant-btn ant-btn-primary ant-btn-sm"
                  onClick={this.handleBuyProductsOnChat.bind(
                    this,
                    this.state.productTypeTab === 1
                      ? false
                      : this.state.productTypeTab === 0
                        ? false
                        : true,
                    false
                  )}
                >
                  <span>
                    {TranslationContext !== undefined
                      ? TranslationContext.span.proceed
                      : "Proceed"}
                  </span>
                </button>
              </div>
            </div>
          </div>
        </Modal>
        <Modal
          open={this.state.fileShowMdl}
          onClose={this.handleFileClose.bind(this)}
          center
          modalId="fileImagesShow"
          overlayId="logout-ovrly"
        >
          <div style={{ marginTop: "10px" }}>
            <label className="fileAttch">
              {this.state.AttachementFiles.length}&nbsp;Attachment
            </label>
            <img
              src={CancelImg}
              alt="Cancel"
              className="cancelFileimg"
              onClick={this.handleFileClose.bind(this)}
            />
          </div>
          <div
            className="fileAttchment"
            style={{ margin: "0px 20px 20px 20px" }}
          >
            {this.state.AttachementFiles.length > 0
              ? this.state.AttachementFiles.map((item, i) => {
                return (
                  <div className="fileBrdpdg" key={i}>
                    <label className="lblFonr" title={item.name}>
                      {item.name}
                    </label>
                    <img
                      src={CancelImg}
                      alt="Cancel"
                      className="fileDocCancel"
                      onClick={this.handleCancelFile.bind(this, i)}
                    />
                  </div>
                );
              })
              : null}
          </div>
        </Modal>

        <Modal
          open={this.state.mobileShopBagProcessMdl}
          onClose={this.handleCloseProcessModal.bind(this)}
          center
          modalId="mobileShopBagProcessMdl"
          overlayId="logout-ovrly"
        >
          <div className="divShopBagmdl">
            <span
              className={this.state.mobileChatActive === 1 ? "active" : ""}
              onClick={this.handleSendProductsOnChat.bind(
                this,
                false,
                "",
                "",
                "",
                null
              )}
            >
              SEND TO CUSTOMER
            </span>
            <span
              className={this.state.mobileChatActive === 2 ? "active" : ""}
              onClick={this.handleRemoveProduct.bind(
                this,
                Number(this.state.uniqueItemID),
                "S"
              )}
            >
              REMOVE ITEMS
            </span>
            <span
              className={this.state.mobileChatActive === 3 ? "active" : ""}
              onClick={this.handleAddProductsToBagOrWishlist.bind(
                this,
                "shoppingbag"
              )}
            >
              ADD TO WISHLIST
            </span>
            <Popover
              // overlayClassName="antcustom ant-prodesc"
              // placement="topRight"
              content={
                <div
                  // className="productdesc"
                  className=""
                  style={{
                    display: "inline-flex",
                  }}
                >
                  <button
                    type="button"
                    className="tabsbotbtn"
                    onClick={this.handleAddressModalOpen.bind(this)}
                  >
                    {TranslationContext !== undefined
                      ? TranslationContext.button.addtoshoppingbag
                      : "Add to Shopping Bag"}
                  </button>
                </div>
              }
            >
              <button className="butn order-grid-butn">PROCESS TO ORDER</button>
            </Popover>
          </div>
        </Modal>

        <Modal
          open={this.state.mobileWishProcessMdl}
          onClose={this.handleCloseWishListModal.bind(this)}
          center
          modalId="mobileShopBagProcessMdl"
          overlayId="logout-ovrly"
        >
          <div className="divShopBagmdl">
            <span
              className={this.state.mobileChatActive === 1 ? "active" : ""}
              onClick={this.handleSendProductsOnChat.bind(
                this,
                false,
                "",
                "",
                "",
                null
              )}
            >
              SEND TO CUSTOMER
            </span>
            <span
              className={this.state.mobileChatActive === 2 ? "active" : ""}
              onClick={this.handleRemoveProduct.bind(
                this,
                Number(this.state.uniqueItemID),
                "W"
              )}
            >
              REMOVE ITEMS
            </span>
            <span
              className={this.state.mobileChatActive === 3 ? "active" : ""}
              onClick={this.handleAddProductsToBagOrWishlist.bind(
                this,
                "wishlist"
              )}
            >
              ADD TO BAG
            </span>

            <Popover
              // overlayClassName="antcustom ant-prodesc"
              // placement="topRight"
              content={
                <div
                  // className="productdesc"
                  className=""
                  style={{
                    display: "inline-flex",
                  }}
                >
                  <button
                    type="button"
                    className="tabsbotbtn"
                    onClick={this.handleAddressModalOpen.bind(this)}
                  >
                    {TranslationContext !== undefined
                      ? TranslationContext.button.addtoshoppingbag
                      : "Add to Shopping Bag"}
                  </button>
                </div>
              }
            >
              <button className="butn order-grid-butn">PROCESS TO ORDER</button>
            </Popover>
          </div>
        </Modal>

        <Modal
          open={this.state.mobileRecommendProcessMdl}
          onClose={this.handleCloseRecommendedListModal.bind(this)}
          center
          modalId="mobileShopBagProcessMdl"
          overlayId="logout-ovrly"
        >
          <div className="divShopBagmdl">
            <span
              className={this.state.mobileChatActive === 1 ? "active" : ""}
              onClick={this.handleSendProductsOnChat.bind(
                this,
                false,
                "",
                "",
                "",
                null
              )}
            >
              SEND TO CUSTOMER
            </span>
            <span
              className={this.state.mobileChatActive === 2 ? "active" : ""}
              onClick={this.handleAddProductsToBagOrWishlist.bind(
                this,
                "wishlist"
              )}
            >
              ADD TO WISHLIST
            </span>
            <span
              className={this.state.mobileChatActive === 3 ? "active" : ""}
              onClick={this.handleAddProductsToBagOrWishlist.bind(
                this,
                "shoppingbag"
              )}
            >
              ADD TO BAG
            </span>

            <Popover
              // overlayClassName="antcustom ant-prodesc"
              // placement="topRight"
              content={
                <div
                  // className="productdesc"
                  className=""
                  style={{
                    display: "inline-flex",
                  }}
                >
                  <button
                    type="button"
                    className="tabsbotbtn"
                    onClick={this.handleAddressModalOpen.bind(this)}
                  >
                    {TranslationContext !== undefined
                      ? TranslationContext.button.addtoshoppingbag
                      : "Add to Shopping Bag"}
                  </button>
                </div>
              }
            >
              <button className="butn order-grid-butn">PROCESS TO ORDER</button>
            </Popover>
          </div>
        </Modal>
        {/* mobile suggetion modal */}
        <Modal
          open={this.state.suggestionTagModal}
          onClose={this.handleSuggestionTagModalClose.bind(this)}
          center
          modalId="suggetionModalMobile-popup"
          overlayId="logout-ovrly"
          classNames={{ modal: "customername-popupbottom" }}
        >
          <div style={{ padding: "20px", paddingTop: 0, paddingBottom: 0 }}>
            <div
              className="closemob"
              onClick={this.handleSuggestionTagModalClose.bind(this)}
            ></div>
            <div className="d-flex align-items-center justify-content-between mb-3">
              <div className="d-flex align-items-center">
                <img src={Blub} className="bulb-img" alt="icon missing" />
                <p className="smart-para">SMART MESSAGING</p>
              </div>
              <img
                src={DownArrowChat}
                alt="icon missing"
                onClick={this.handleSuggestionTagModalClose.bind(this)}
              />
            </div>
            <Spin spinning={this.state.isSuggestionLoad}>
              <div>
                <label className="tag-label">Tags</label>
                <div className="tags-cntr-mob">
                  {this.state.messageSuggestionTagsData !== null
                    ? this.state.messageSuggestionTagsData.map((item, i) => {
                      return (
                        <button
                          onClick={this.handleTagsButtonClick.bind(
                            this,
                            item.tagID
                          )}
                          className={
                            this.state.selectedTags === item.tagID
                              ? "tagsbtn-active"
                              : "tagsbtn"
                          }
                          id={item.tagID}
                        >
                          {item.tagName}
                        </button>
                      );
                    })
                    : null}
                </div>
                <label className="tag-label">Top Messages</label>
                {this.state.messageSuggestionData !== null &&
                  this.state.messageSuggestionData.length > 0 &&
                  this.state.messageSuggestionData.length > 0 && (
                    <div
                      className="suggestions-cntr setpagination sugg-mob-height"
                      style={{ width: "100%" }}
                    >
                      <Table
                        noDataContent="No Record Found"
                        style={{ width: "100%" }}
                        className="components-table-demo-nested antd-table-campaign custom-antd-table rm-header hidepagebtn"
                        columns={[
                          {
                            dataIndex: "suggestionText",
                            render: (row, rowData) => {
                              i = i + 1;
                              return (
                                <div
                                  className={
                                    this.state.chkSuggestion ===
                                      rowData.suggestionID
                                      ? "suggestions-tick"
                                      : ""
                                  }
                                  style={{
                                    width: "100%",
                                  }}
                                  id={i}
                                  onClick={this.onOpenMobSuggestionModalNew.bind(
                                    this,
                                    rowData.suggestionText,
                                    rowData.suggestionID
                                  )}
                                >
                                  <Tooltip
                                    placement="left"
                                    title={rowData.suggestionText}
                                  >
                                    <span>{rowData.suggestionText}</span>
                                  </Tooltip>
                                </div>
                              );
                            },
                          },
                        ]}
                        dataSource={this.state.messageSuggestionData.filter(
                          (x) => x.tagID == this.state.selectedTags
                        )}
                        pagination={{
                          itemRender: PaginationButton,
                          pageSize: 10,
                          defaultPageSize: 10,
                        }}
                      ></Table>
                    </div>
                  )}
                {this.state.messageSuggestionData.length === 0 ? (
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                ) : null}
              </div>
            </Spin>
            <div>
              <button
                className="suggestionsml-btn-send"
                onClick={this.handlesuggestionConfModalOpen.bind(this)}
              >
                Send
              </button>
            </div>
          </div>
        </Modal>

        {/* mobile card modal */}
        <Modal
          open={this.state.cardModal}
          onClose={this.handleCardModalClose.bind(this)}
          center
          modalId="cardModalMobile-popup"
          overlayId="logout-ovrly"
          classNames={{ modal: "customername-popupbottom" }}
        >
          <div style={{ padding: "20px", paddingTop: 0, paddingBottom: 0 }}>
            <div
              className="closemob"
              onClick={this.handleCardModalClose.bind(this)}
            ></div>
            <Spin spinning={this.state.isCardSearch}>
              <div className="row product-card">
                {this.state.searchCardData !== null
                  ? this.state.searchCardData.map((item, i) => {
                    return (
                      <div className="col-md-6" key={i}>
                        {item.isChecked ? (
                          <div className="selectdot">
                            <img src={CardTick} alt={"select-card"} />
                          </div>
                        ) : null}
                        <div className="card" id={"card" + item.itemID}>
                          <div className="card-body position-relative">
                            <div
                              className="row"
                              style={{
                                margin: "0",
                              }}
                            >
                              <div
                                className="col-4 col-md-4 mb-md-0 mb-2"
                                style={{
                                  alignSelf: "center",
                                }}
                              >
                                {(item.imageUrl || item.imageURL) &&
                                  this.state.cardConfigData.ImageURL ? (
                                  <img
                                    className="chat-product-img"
                                    src={item.imageURL || item.imageUrl}
                                    alt="Product Image"
                                    title={item.productName || item.name}
                                  />
                                ) : (
                                  <Dropzone
                                    maxSize={5242880}
                                    accept="image/jpeg, image/png,image/jpg"
                                    onDrop={this.handleCardImageOnchange.bind(
                                      this,
                                      item.uniqueItemCode || item.itemCode,
                                      item.color || item.availableColor[0]
                                    )}
                                  >
                                    {({ getRootProps, getInputProps }) => (
                                      <div {...getRootProps()}>
                                        <input
                                          {...getInputProps()}
                                          className="file-upload d-none"
                                        />
                                        <span className="addimg">
                                          <input
                                            type="image"
                                            alt="Add Image"
                                            src={addimg}
                                          />
                                        </span>
                                      </div>
                                    )}
                                  </Dropzone>
                                )}
                              </div>
                              <div
                                className="col-8 col-md-8 bkcprdt"
                                onClick={this.handleSelectCard.bind(
                                  this,
                                  item.itemID,
                                  item.imageURL || item.imageUrl
                                )}
                              >
                                {(item.productName || item.name) &&
                                  this.state.cardConfigData.ItemName ? (
                                  <div>
                                    <label className="chat-product-name">
                                      {item.productName || item.name}
                                    </label>
                                  </div>
                                ) : null}
                                <div>
                                  {item.brandName &&
                                    this.state.cardConfigData.Brand ? (
                                    <label className="chat-product-code">
                                      {TranslationContext !== undefined
                                        ? TranslationContext.label.brand
                                        : "Brand"}
                                      :{" " + item.brandName}
                                    </label>
                                  ) : null}
                                </div>
                                <div>
                                  {item.categoryName !== "" &&
                                    item.categoryName !== null &&
                                    this.state.cardConfigData.Category ? (
                                    <label className="chat-product-code">
                                      {TranslationContext !== undefined
                                        ? TranslationContext.label.category
                                        : "Category"}
                                      :{" " + item.categoryName}
                                    </label>
                                  ) : null}
                                </div>
                                <div>
                                  {item.subCategoryName &&
                                    this.state.cardConfigData.SubCategory ? (
                                    <label className="chat-product-code">
                                      {TranslationContext !== undefined
                                        ? TranslationContext.label.subcategory
                                        : "SubCategory"}
                                      :{" " + item.subCategoryName}
                                    </label>
                                  ) : null}
                                </div>
                                {this.state.sourceType === "cb" && !this.state.isAddToCardEnableForCB ? (
                                  <div>
                                    {item.color &&
                                      this.state.cardConfigData.Color ? (
                                      <label className="chat-product-code">
                                        {TranslationContext !== undefined
                                          ? TranslationContext.label.color
                                          : "Color"}
                                        :{" " + item.color}
                                      </label>
                                    ) : null}
                                  </div>
                                ) : (
                                  <div>
                                    {item.availableColor &&
                                      this.state.cardConfigData.Color ? (
                                      <label className="chat-product-code">
                                        {TranslationContext !== undefined
                                          ? TranslationContext.label.color
                                          : "Color"}
                                        :{[].concat.apply([], item.availableColor).filter((v, i, a) => a.indexOf(v) === i).join(',')}
                                      </label>
                                    ) : null}
                                  </div>
                                )}
                                {this.state.sourceType === "cb" && !this.state.isAddToCardEnableForCB ? (
                                  <div>
                                    {item.size &&
                                      this.state.cardConfigData.Size ? (
                                      <label className="chat-product-code">
                                        {TranslationContext !== undefined
                                          ? TranslationContext.label.color
                                          : "Size"}
                                        :{" " + item.size}
                                      </label>
                                    ) : null}
                                  </div>
                                ) : item.availableSize &&
                                  this.state.cardConfigData.Size ? (
                                  <label className="chat-product-code">
                                    {TranslationContext !== undefined
                                      ? TranslationContext.label.color
                                      : "Size"}
                                    :{" " + [].concat.apply([], item.availableSize).filter((v, i, a) => a.indexOf(v) === i).join(',')}
                                  </label>
                                ) : null}
                                {this.state.sourceType === "cb" && !this.state.isAddToCardEnableForCB ? (
                                  <div>
                                    {item.uniqueItemCode &&
                                      this.state.cardConfigData.ItemCode ? (
                                      <label className="chat-product-code">
                                        {TranslationContext !== undefined
                                          ? TranslationContext.label.itemcode
                                          : "Item Code"}
                                        :{" " + item.uniqueItemCode}
                                      </label>
                                    ) : null}
                                  </div>
                                ) : (
                                  <div>
                                    {item.itemCode &&
                                      this.state.cardConfigData.ItemCode ? (
                                      <label className="chat-product-code">
                                        {TranslationContext !== undefined
                                          ? TranslationContext.label.itemcode
                                          : "Item Code"}
                                        :{" " + item.itemCode}
                                      </label>
                                    ) : null}
                                  </div>
                                )}
                                <div>
                                  {item.discount &&
                                    this.state.cardConfigData.discount ? (
                                    <label className="chat-product-code">
                                      {TranslationContext !== undefined
                                        ? TranslationContext.label.discount
                                        : "Discount"}
                                      :{" "}
                                      {this.state.sourceType === "cb" && !this.state.isAddToCardEnableForCB
                                        ? item.discount
                                        : item.discount[0].join()}
                                    </label>
                                  ) : null}
                                </div>
                                <div>
                                  {item.price !== "" &&
                                    parseFloat(item.price) !== 0 &&
                                    item.price !== null &&
                                    this.state.cardConfigData.Price ? (
                                    <label className="chat-product-prize">
                                      {TranslationContext !== undefined
                                        ? TranslationContext.label.price
                                        : "Price"}
                                      :{" " + item.price}
                                    </label>
                                  ) : null}
                                </div>
                                {item.url && this.state.cardConfigData.Url ? (
                                  <div>
                                    <a
                                      href={item.url}
                                      target="_blank"
                                      className="chat-product-url"
                                    >
                                      {item.url}
                                    </a>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })
                  : null}
              </div>
              {this.state.searchCardData.length === 0 ? (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              ) : null}
            </Spin>
            {this.state.searchCardData.length > 0 ? (
              <label className="cardscountlbl">
                {this.state.searchCardData.filter((x) => x.isChecked === true)
                  .length > 0
                  ? "Selected Card(s):" +
                  this.state.searchCardData.filter(
                    (x) => x.isChecked === true
                  ).length
                  : null}
              </label>
            ) : null}
            {this.state.isCardSend ? (
              <label className="cardsucclbl">Card(s) Sent Successfully.</label>
            ) : null}
            {this.state.isSelectedCard ? (
              <label className="cardsucclblNot">Please Select Card.</label>
            ) : null}

            {/* ======================================================= */}
            <div style={{ display: "flex", flexDirection: "row" }}>
              {this.state.isAddToCardEnableForCB && (this.state.sourceType === 'cb' || this.state.IsShoppingCartTabEnableForWB) ?
                <button
                  onClick={() => this.handleSelectedCardAddToCard()}
                  className="suggestionsml-btn-send mr-2"
                  style={{ marginBottom: "10px", outline: "none" }}
                >
                  Add To Cart
                </button>
                : null}
              <button
                onClick={this.handleSendCard.bind(this)}
                className="suggestionsml-btn-send"
                style={{ marginBottom: "10px", outline: "none" }}
              >
                Send
              </button>
            </div>
            {/* ======================================================= */}
          </div>
        </Modal>
        {/* Mobile Grammarly Check Modal  */}
        <Modal
          open={this.state.grammarlyModal}
          onClose={this.handleGrammarlyModalClose.bind(this)}
          center
          modalId="grammarlyModalMobile-popup"
          overlayId="logout-ovrly"
          classNames={{ modal: "customername-popupbottom" }}
        >
          <div className="textcorretant">
            <div
              className="closemob"
              onClick={this.handleGrammarlyModalClose.bind(this)}
            ></div>
            <div>
              <div className="pd-10" style={{ textAlign: "center" }}>
                <label className="textcorrent-header">
                  Easy Rewardz-Spelling Check{" "}
                </label>
              </div>
              <div
                style={{
                  textAlign: "center",
                  padding: "10px",
                }}
              >
                <Radio.Group
                  optionType="button"
                  buttonStyle="solid"
                  value={this.state.suggestionType}
                  onChange={this.handleSuggetionTypeChange.bind(this)}
                >
                  <Radio.Button value="1">All</Radio.Button>
                  <Radio.Button value="2">Profanity Words</Radio.Button>
                  <Radio.Button value="3">Suggested Words</Radio.Button>
                </Radio.Group>
              </div>
              <div className="row" style={{ flexWrap: "nowrap" }}>
                <div className="col-md">
                  <label className="textcorrent-mobheader-l">
                    Entered Words
                  </label>
                </div>
                <div className="col-md">
                  <label className="textcorrent-mobheader-r">
                    {this.state.suggestionType === "2"
                      ? "Profanity Words"
                      : "Suggested Words"}
                  </label>
                </div>
              </div>
              <div className="textcorret pd-10">
                {this.state.Profane_Words.length > 0
                  ? this.state.suggestionType === "1" ||
                    this.state.suggestionType === "2"
                    ? this.state.Profane_Words.map((item, i) => {
                      return (
                        <div className="row" key={i}>
                          <div className="col-md">
                            <span className="oval"></span>
                            <label className="textcorret-lab1">
                              {item.value} (Profanity)
                            </label>
                            <img src={SchRight} className="textcorret-img" />
                          </div>

                          <div className="col-md">
                            <label
                              className={
                                !item.isChecked
                                  ? "textcorret-lab2"
                                  : "textcorret-lab2-active"
                              }
                              onClick={this.handleProfannitySelect.bind(
                                this,
                                item.value
                              )}
                            >
                              {item.value}
                            </label>
                          </div>
                        </div>
                      );
                    })
                    : null
                  : null}

                {Object.keys(this.state.Suggested_Words).length > 0
                  ? Object.keys(this.state.Suggested_Words).map((item, i) => {
                    return this.state.suggestionType === "1" ||
                      this.state.suggestionType === "3" ? (
                      <div className="row">
                        <div className="col-md">
                          <span className="oval"></span>
                          <label className="textcorret-lab1">{item}</label>
                          <img src={SchRight} className="textcorret-img" />
                        </div>

                        <div className="col-md">
                          {this.state.Suggested_Words[item].length > 0
                            ? this.state.Suggested_Words[item].map(
                              (key, j) => {
                                return (
                                  <label
                                    className={
                                      this.state.selectedSuggested_Words[
                                        item
                                      ] === key[0]
                                        ? "textcorret-lab2-active"
                                        : "textcorret-lab2"
                                    }
                                    key={j}
                                    onClick={this.handleSuggetionWordClick.bind(
                                      this,
                                      item,
                                      key[0]
                                    )}
                                  >
                                    {key[0]}
                                  </label>
                                );
                              }
                            )
                            : null}
                        </div>
                      </div>
                    ) : null;
                  })
                  : null}
              </div>
              {this.state.Profane_Words.length > 0 ? (
                <div>
                  <label style={{ color: "red", marginLeft: "10px" }}>
                    Please Remove Profanity Words.
                  </label>
                </div>
              ) : null}
              <div className="row pd-10">
                <div className="col-md">
                  {this.state.Profane_Words.length === 0 ? (
                    <button
                      className="textcorret-btnignore"
                      onClick={this.handleGrammarlyIgnore.bind(this)}
                    >
                      Ignore
                    </button>
                  ) : null}

                  {this.state.Profane_Words.length === 0 ? (
                    <button
                      className="textcorret-btnapply"
                      onClick={this.handleGrammarlyApply.bind(this)}
                    >
                      Apply & Send
                    </button>
                  ) : null}
                  {this.state.Profane_Words.length > 0 ? (
                    <button
                      className="textcorret-btnapply"
                      onClick={this.handleRemoveProfanity.bind(this)}
                    >
                      Remove Profanity
                    </button>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </Modal>
        {/* Card Image moderation modal */}
        <Modal
          center
          open={this.state.imgModerationMdl}
          onClose={this.handleImgMdlClose.bind(this)}
          modalId="cardImageModeration"
          overlayId="logout-ovrly"
        >
          <Spin
            spinning={this.state.imageModerationLoader}
            tip={"Please wait..."}
          >
            <div className="cardImgMdlDiv">
              <div className="row m-b-15">
                <div className="col-md-12 d-flex">
                  <label
                    className="cardLblImg cardMdlDD"
                    style={{ lineHeight: "40px" }}
                  >
                    Choose Color
                  </label>
                  <select
                    value={this.state.selectCardColor}
                    onChange={(e) =>
                      this.setState({ selectCardColor: e.target.value })
                    }
                    className="form-control dropdown-setting"
                    style={{ display: "inline-block" }}
                  >
                    <option value={0}>Select Color</option>
                    {this.state.cardColors !== null &&
                      this.state.cardColors.map((item, i) => (
                        <option value={item} key={i}>
                          {item}
                        </option>
                      ))}
                  </select>
                </div>
                {this.state.selectCardColor === 0 && (
                  <p
                    style={{
                      color: "red",
                      marginBottom: "0px",
                      marginLeft: "130px",
                    }}
                  >
                    {this.state.selectColorCompulsory}
                  </p>
                )}
              </div>
              <div className="row m-b-15">
                <div className="col-md-12">
                  <label className="cardLblImg">File Name</label>
                  <label>{this.state.cardAddImageName}</label>
                </div>
              </div>
              <div className="row m-b-15">
                <div className="col-md-12">
                  <label className="cardLblImg">Item Code</label>
                  <label>{this.state.cardItemCode}</label>
                </div>
              </div>
              <div className="row" style={{ justifyContent: "space-evenly" }}>
                <button
                  className="butn"
                  onClick={this.handleImgMdlClose.bind(this)}
                >
                  Close
                </button>

                <button
                  className="butn"
                  onClick={this.handleInsertCardImageUpload}
                >
                  Submit
                </button>
              </div>
            </div>
          </Spin>
        </Modal>
        <input
          type="hidden"
          id="ongoingDivId"
          onClick={this.handleGetOngoingChat.bind(this)}
        />
        <input
          type="hidden"
          id="ongoingchatList"
          onClick={this.handleGetChatMessagesList.bind(this)}
        />
        {/* <audio id="newChatAudio">
          <source src={this.state.newChatSoundFile} type="audio/mpeg" />
        </audio>
        <audio id="newChatMeaasgeAudio">
          <source src={this.state.newMessageSoundFile} type="audio/mpeg" />
        </audio> */}
        <Modal
          open={this.state.attachementViewModal}
          onClose={this.handleAttachementViewModalClose.bind(this)}
          center
          modalId="cardModalMobile-popup"
          overlayId="logout-ovrly"
          classNames={{ modal: "customername-popupbottom" }}
        >
          <div style={{ padding: "20px", paddingTop: 0, paddingBottom: 0 }}>
            <div
              className="closemob"
              onClick={this.handleAttachementViewModalClose.bind(this)}
            ></div>

            {this.state.attachmentUrl ? (
              <div className="row product-card">
                <img
                  src={this.state.attachmentUrl}
                  style={{
                    width: "-webkit-fill-available",
                  }}
                  alt="modalAttachment"
                />
              </div>
            ) : null}
            {this.state.attachmentDocUrl ? (
              <div className="row product-card" style={{ display: "inline" }}>
                <div className="btn-text-center">
                  <p>Click below button to download file.</p>
                  <button
                    className="btn btn-primary"
                    onClick={this.handleAttachementdownload.bind(this)}
                  >
                    Download
                  </button>
                </div>
              </div>
            ) : null}

            <div>
              <button
                onClick={this.handleAttachementViewModalClose.bind(this)}
                className="suggestionsml-btn-send"
                style={{ marginBottom: "10px", outline: "none" }}
              >
                Close
              </button>
            </div>
          </div>
        </Modal>
        <Dialog
          //fullScreen={fullScreen}
          open={this.state.isOpenClose}
          onClose={this.handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">
            <b> {"Add To Cart"} </b>
          </DialogTitle>
          <DialogContent>
            <div className="cart-dialog-container">
              {this.state.itemlist.map((itemlist, index) => (
                <CardData key={index} item={itemlist} />
              ))}
            </div>
          </DialogContent>
          <DialogActions>
            {this.state.isSelectedCardSizeColor ? (
              <label className="cardsucclblNot">
                Please Select Size and color.
              </label>
            ) : null}
            <Button
              id="add-cart-button"
              autoFocus
              onClick={() => this.handleAddToCart()}
            >
              Add To Cart
              {this.state.isAddedToCartSpin ? (
                <FontAwesomeIcon
                  icon={faCircleNotch}
                  className="circular-loader ml-2"
                  spin
                />
              ) : (
                ""
              )}
            </Button>
            <Button onClick={this.handleClose} autoFocus>
              Cancel
            </Button>
          </DialogActions>
        </Dialog>

        {/* mobile addToCard modal */}
        <Modal
          open={this.state.addToCartModal}
          onClose={this.handleCardModalClose.bind(this)}
          center
          modalId="cardModalMobile-popup"
          overlayId="logout-ovrly"
          classNames={{ modal: "customername-popupbottom" }}
        >
          <div style={{ padding: "20px", paddingTop: 0, paddingBottom: 0 }}>
            <div
              className="closemob"
              onClick={this.handleAddCardModalClose.bind(this)}
            ></div>

            <div className="row product-card">
              {this.state.itemlist.map((itemlist, index) => (
                <CardData key={index} item={itemlist} />
              ))}
            </div>
            {this.state.isSelectedCardSizeColor ? (
              <label className="cardsucclblNot">
                Please Select Size and color.
              </label>
            ) : null}
            <div style={{ display: "flex", flexDirection: "row" }}>
              <button
                onClick={() => this.handleAddToCart()}
                className="suggestionsml-btn-send"
                style={{ marginBottom: "10px", outline: "none" }}
              >
                Add To Cart
                {this.state.isAddedToCartSpin ? (
                  <FontAwesomeIcon
                    icon={faCircleNotch}
                    className="circular-loader ml-2"
                    spin
                  />
                ) : (
                  ""
                )}
              </button>
              <button
                onClick={() => this.handleClose()}
                className="suggestionsml-btn-send"
                style={{ marginBottom: "10px", outline: "none" }}
              >
                Cancel
              </button>
            </div>
            {/* ======================================================= */}
          </div>
        </Modal>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  //console.log(state);
  return {
    cardDetails: state.selectedCardData,
    messageCount: state.messageCount,
    isFullScreen: state.isFullScreen,
    customerMobileNumber: state.customerMobileNumber,
    isCallRecived: state.isCallRecived
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    CardAdded: (tDetails) => dispatch({ type: "ADDTOCART", payload: tDetails }),
    setIsVideoCallEnable: (isVideoCallEnable) => dispatch({ type: "IS_VIDEO_CALL_ENABLE", payload: isVideoCallEnable }),
    setMessageCount: (messageCount) => dispatch({ type: "SET_MESSAGECOUNT", payload: messageCount }),
    setOnGoingMobileNumber: (mobileNumber) => dispatch({ type: "SET_ONGOINGCHAT", payload: mobileNumber }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Chatbot);

// export default Chatbot;

function replaceAll(str, find, replace) {
  return str.replace(new RegExp("\\b" + find + "\\b", "gi"), replace).trim();
}
