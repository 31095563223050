import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import Demo from "../../../store/Hashtag";
import { Popover, Radio, Spin, Popconfirm } from "antd";
import { Collapse, CardBody, Card } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DelBlack from "./../../../assets/Images/del-black.png";
import DownExcel from "./../../../assets/Images/csv.png";
import CancelIcon from "./../../../assets/Images/cancel.png";
import FileUpload from "./../../../assets/Images/file.png";
import BlackInfoIcon from "./../../../assets/Images/Info-black.png";
import Editpencil from "./../../../assets/Images/pencil.png";
import DelBigIcon from "./../../../assets/Images/del-big.png";
import searchico from "./../../../assets/Images/serach-icon-left.png";
import config from "./../../../helpers/config";
import { NotificationManager } from "react-notifications";
import {
  faCircleNotch,
  faPlus,
  faMinus,
} from "@fortawesome/free-solid-svg-icons";
import Modal from "react-bootstrap/Modal";
import { CSVLink } from "react-csv";
import Dropzone from "react-dropzone";
import TimeSlotdropdown from "./TimeSlotDropdown";
import "antd/dist/antd.css";
import * as translationHI from "./../../../translations/hindi";
import * as translationMA from "./../../../translations/marathi";
import { Table, Modal as ModalAntd, Select as SelectAntd } from "antd";
import Select from "react-select";
import DatePicker from "react-datepicker";
import moment from "moment";
import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";
import SettingsService from "../../../Services/Settings/SettingsService";
import { CloseOutline, PlusOutline, MinusOutline } from "@ant-design/icons";
const { confirm } = ModalAntd;
var uid = 0;
class StoreModule extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fileName: "",
      maxAttachSize: [],
      fileFormat: [],
      selectedMaxAttachSize: "0",
      selectedFileFormat: "0",
      maxAttachSizeCompulsion: "",
      fileFormatCompulsion: "",
      claimTabLoading: false,
      addCampaignLoading: false,
      campaignScriptData: [],
      campaignShow: false,
      campaignOvrlayShow: false,
      campaignName: [],
      indiCampaign: "",
      selTab: "Claim",
      scriptDetails: "",
      campaignCompulsion: "",
      scriptDetailsCompulsion: "",
      updateIndiCampaignId: "",
      updateScriptDetails: "",
      editModal: false,
      updateScriptDetailsCompulsion: "",
      updateCampaignId: 0,
      updateCampaignLoading: false,
      sortFiltercampaignName: [],
      sortFiltercreatedBy: [],
      sortFilteristatus: [],
      scampaignNameFilterCheckbox: "",
      screatedByFilterCheckbox: "",
      sstatusFilterCheckbox: "",
      sortcampaignName: [],
      sortcreatedBy: [],
      sortstatus: [],
      isortA: false,
      sortColumn: "",
      sortAllData: [],
      sortHeader: "",
      tempcampaignScriptData: [],
      StatusModel: false,
      filterTxtValue: "",
      fileSize: "",
      file: {},
      fileValidation: "",
      isErrorBulkUpload: false,
      isShowProgress: false,
      isATOZ: true,
      itemData: [],
      campaignChannelData: {},
      AppointConfigData: {},
      BroadCastConfigData: {},
      maxClickValidation: "",
      enabledAfterValidation: "",
      braodCastMaxClickValid: "",
      broadCastEnabledAfterValid: "",
      campProviderValidation: "",
      broadProviderValidation: "",
      TimeSlotData: TimeSlotdropdown(),
      TimeSlotGridData: [],
      storeCodeData: [],
      tempStoreCodeData: [],
      selectStore: 0,
      selectTimeSlot1: 0,
      selectTimeSlot2: 0,
      selectAmPm1: "AM",
      selectAmPm2: "AM",
      orderNumber: "",
      maxCapacity: "",
      storeCodeValidation: "",
      orderNovalidation: "",
      maxCapacityValidation: "",
      editSlotModal: false,
      timeSlotEdit: {},
      editSelectTimeSlot1: 0,
      editSelectTimeSlot2: 0,
      editSelectAmPm1: "",
      editSelectAmPm2: "",
      timeSlotId: 0,
      editStoreCodeValidation: "",
      editOrderNovalidation: "",
      editMaxCapacityValidation: "",
      languageData: [],
      selectLanguage: 0,
      languageValidation: "",
      languageGridData: [],
      translateLanguage: {},
      FilterSelectStore: 0,
      isSlotLoading: false,
      selectedStoreCode: [],
      slotDuration: "0.5",
      selectNOTimeSlot1: "1",
      selectNOTimeSlot2: "1",
      selectNOAmPm1: "AM",
      selectNOAmPm2: "AM",
      appointmentDays: "1",
      slotId: 0,
      editstoreCode: "",
      editmaxCapacity: "",
      editSelectNOTimeSlot1: "",
      editSelectNOTimeSlot2: "",
      editSelectNOAmPm1: "",
      editSelectNOAmPm2: "",
      editAppointmentDays: "0",
      editSlotDuration: "",
      isNextClick: false,
      slotData: [],
      chooseStoreModal: false,
      createTampleteModal: false,
      selectedStoreModal: false,
      slotAutomaticRadio: 1,
      selectedStoreIds: "",
      selectedStoreValues: "",
      shoreSelectedCount: 0,
      operationalDaysData: [],
      slotStoreSearch: "",
      selectedStoreList: [],
      selectedStoreMdl: {},
      slotTemplateData: [],
      videoSlotTemplateData: [],
      defaultStaffList: [],
      showApplyStoreData: false,
      autoTempName: "",
      autoStoreFrom: "",
      autoStoreTo: "",
      autoNonOptFrom: "",
      autoNonOptTo: "",
      AutoSlotDuration: 0,
      AutoSlotGap: 0,
      autoTempNameCompulsory: "",
      AutoSlotDurationCompulsory: "",
      AutoSlotGapCompulsory: "",
      autoStoreFromCompulsory: "",
      autoStoreToCompulsory: "",
      autoNonOptFromCompulsory: "",
      autoNonOptToCompulsory: "",
      automaticaSlotTblData: [],
      manualTempName: "",
      manualStoreFrom: "",
      manualStoreTo: "",
      ManualSlotDuration: 0,
      slotEndTime: "",
      slotStartTime: "",
      manualTempNameCompulsory: "",
      manualStoreFromCompulsory: "",
      manualStoreToCompulsory: "",
      ManualSlotDurationCompulsory: "",
      manualSlotStartCompulsory: "",
      manualSlotEndCompulsory: "",
      manualStoreTblData: [],
      manualStoreData: {},
      operationalDays: [],
      editTotalSlot: "",
      editOperationalDays: "",
      editSlotTemplateName: "",
      editSlotTemplateId: 0,
      storeTimimg: "",
      mimSlotStartTimeChck: "",
      finalSlotTemplateId: 0,
      selectedSlotTemplate: 0,
      SlotTemplateGridData: [],
      editSlotStatus: "",
      slotStatus: 1,
      applicableFromDate: new Date(),
      slotDaysDisplay: 0,
      maxPeopleAppointment: 0,
      SlotDisplayCode: "",
      editSlotDisplayCode: 0,
      editSlotTemplateGridData: [],
      slotTempLoading: false,
      isChooseStore: "",
      isoperationalDay: "",
      isSlotTemplete: "",
      SlotFile: {},
      SlotFileName: "",
      isSlotSaveClick: false,
      bulkuploadLoading: false,
      isSlotDaysDisplay: "",
      AppointMessageTagsData: [],
      isCreateTampleteLoading: false,
      isGenrateSlotLoading: false,
      isSlotEditLoading: false,
      manualSlotTblData: [],
      editMaxCapacity: "",
      isSubmit: false,
      editOperationalDaysName: "",
      filterOptions: [
        { value: 1, label: "By Store Code" },
        { value: 2, label: "By Operational Days" },
        { value: 3, label: "By Slot Template" },
      ],
      filterOptionSelected: {},
      FilterCollapse: false,
      onLoadSlotStores: [],
      onLoadOperationalDays: [],
      onLoadSlotTemplate: [],
      filterChooseStoreModal: false,
      filterSlotStoreSearch: "",
      tempOnLoadSlotStores: [],
      filterSelectedStoreId: "",
      filterSelectedStoreCode: "",
      isFilterSearch: false,
      isTimeSlotGridLoader: false,
      filterOperationalDaysModal: false,
      filterOperationalDays: {},
      filterSelectedOperationalDays: [],
      filterTampleteModal: false,
      filterSlotTemplate: 0,
      filterSlotTemplateGridData: [],
      selectedFilterSlotTemplate: 0,
      filterSlotTempLoading: false,
      operationalDaysList: [],
      WebBotData: [],
      campaignUploadFile: {},
      campaignUploadName: "",
      remindBeforeValueValidation: "",
      isVideoCallAppointmentTab: false,
      videoCallMultipleSlot: 1,
      storeAgentList: [],
      videoCallAppointmentData: {
        isStaffNameSwitch: false,
        isMultipleTimeSlot: false,
        templateName: "",
        slotDuration: "",
        gapSlot: "",
        storeTimingFrom: "",
        storeTimingTo: "",
        nonOperationalHourFrom: "",
        nonOperationalHourTo: "",
        storeStaffName: "",
      },
      videoCallSlotSetttngData: {
        cancelVideoCallTemplate: "",
        successBookTemplate: "",
        paymentLinkTemplate: "",
        notificationSentTo: "",
        reminderNotificationTemplate: "",
        sentPaymentLinkFlag: true,
        reminderForCustomerFlag: true,
        reminderForStoreStaffFlag: true,
        reminderForCustomerTime: "",
        notificationToCustomerSelect: "",
        reminderForStoreStaffTime: "",
        notificationToStoreStaffSelect: "",
        selectedStoreID: 0,
      },
      notificationToAgent: null,
    };
    this.handleClaimTabData = this.handleClaimTabData.bind(this);
    this.handleCampaignScriptGridData =
      this.handleCampaignScriptGridData.bind(this);
    this.handleGetTimeslotGridData = this.handleGetTimeslotGridData.bind(this);
    this.closeSlotEditModal = this.closeSlotEditModal.bind(this);
    this.handleVideoCallInputChange =
      this.handleVideoCallInputChange.bind(this);
    this.handleVideoCallSlotSettingInputChange =
      this.handleVideoCallSlotSettingInputChange.bind(this);
    this.SettingsService = new SettingsService();
    this.handleDefaultStaffList = this.handleDefaultStaffList.bind(this);
    this.incrementSloOccupancy = this.incrementSloOccupancy.bind(this);
    this.decrementSloOccupancy = this.decrementSloOccupancy.bind(this);
    this.handleRemoveVideoSlot = this.handleRemoveVideoSlot.bind(this);
    this.handleEditAgentName = this.handleEditAgentName.bind(this);
  }

  handleFilterCollapse() {
    this.setState((state) => ({ FilterCollapse: !state.FilterCollapse }));
  }

  componentDidMount() {
    this.handleClaimTabData();
    this.handleCampaignScriptGridData();
    this.handleCampaignChannelGridData();
    this.handleGetAppointmentConfigData();
    this.handleGetBroadCastConfigData();
    // this.handleGetTimeslotGridData();
    this.handleGetstoreCodeData();
    this.handleGetLanguageDropdownlist();
    this.handleGetLanguageGridData();
    this.handleGetOperationalDays();
    this.handleGetSlotTemplate();
    this.handleGetVideoSlotTemplate();
    this.handleDefaultStaffList();

    this.handleGetAppointmentMessageTagsData();

    if (window.localStorage.getItem("translateLanguage") === "hindi") {
      this.state.translateLanguage = translationHI;
    } else if (window.localStorage.getItem("translateLanguage") === "marathi") {
      this.state.translateLanguage = translationMA;
    } else {
      this.state.translateLanguage = {};
    }
  }

  setClaimTabData = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    this.setState({
      [name]: value,
    });
  };

  setScriptDetails = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    this.setState({
      [name]: value,
    });
  };

  handleInputOnchange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    this.setState({
      [name]: value,
    });
  };

  handleSlotInputOnchange = (e) => {
    var reg = /^[0-9\b]+$/;
    if (e.target.value === "" || reg.test(e.target.value)) {
      if (Number(e.target.value) <= 30 && Number(e.target.value) >= 1) {
        this.setState({ [e.target.name]: e.target.value });
      } else {
        this.setState({ [e.target.name]: "" });
      }
    } else {
      this.setState({ [e.target.name]: "" });
    }
  };

  handleSlotEditInputOnchange = (e) => {
    var reg = /^[0-9\b]+$/;
    if (e.target.value === "" || reg.test(e.target.value)) {
      if (Number(e.target.value) <= 30 && Number(e.target.value) >= 1) {
        this.setState({
          [e.target.name]: e.target.value,
        });
      } else {
        this.setState({
          [e.target.name]: "",
        });
      }
    } else {
      this.setState({
        [e.target.name]: "",
      });
    }
  };

  handleDrop_downOnchange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    if (name === "selectStore") {
      this.setState({
        selectStore: value,
      });
    } else if (name === "selectTimeSlot1") {
      this.setState({
        selectTimeSlot1: value,
      });
    } else if (name === "selectTimeSlot2") {
      this.setState({
        selectTimeSlot2: value,
      });
    } else if (name === "selectAmPm1") {
      this.setState({
        selectAmPm1: value,
      });
    } else if (name === "selectAmPm2") {
      this.setState({
        selectAmPm2: value,
      });
    } else if (name === "selectLanguage") {
      this.setState({
        selectLanguage: value,
      });
    } else if (name === "slotDuration") {
      this.setState({
        slotDuration: value,
      });
    } else if (name === "selectNOAmPm1") {
      this.setState({
        selectNOAmPm1: value,
      });
    } else if (name === "selectNOAmPm2") {
      this.setState({
        selectNOAmPm2: value,
      });
    } else if (name === "selectNOTimeSlot2") {
      this.setState({
        selectNOTimeSlot2: value,
      });
    } else if (name === "selectNOTimeSlot1") {
      this.setState({
        selectNOTimeSlot1: value,
      });
    } else if (name === "appointmentDays") {
      this.setState({
        appointmentDays: value,
      });
    }
  };

  handleEditDrop_downOnchange = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    var timeSlotEdit = this.state.timeSlotEdit;
    if (name === "editSelectTimeSlot1") {
      this.setState({
        editSelectTimeSlot1: value,
      });
    } else if (name === "editSelectTimeSlot2") {
      this.setState({
        editSelectTimeSlot2: value,
      });
    } else if (name === "editSelectAmPm1") {
      this.setState({
        editSelectAmPm1: value,
      });
    } else if (name === "editSelectAmPm2") {
      this.setState({
        editSelectAmPm2: value,
      });
    } else if (name === "editstoreCode") {
      this.setState({
        editstoreCode: value,
      });
    } else if (name === "editSelectNOAmPm1") {
      this.setState({
        editSelectNOAmPm1: value,
      });
    } else if (name === "editSelectNOAmPm2") {
      this.setState({
        editSelectNOAmPm2: value,
      });
    } else if (name === "editSelectNOTimeSlot1") {
      this.setState({
        editSelectNOTimeSlot1: value,
      });
    } else if (name === "editSelectNOTimeSlot2") {
      this.setState({
        editSelectNOTimeSlot2: value,
      });
    } else if (name === "editSlotDuration") {
      this.setState({
        editSlotDuration: value,
      });
    } else if (name === "editAppointmentDays") {
      this.setState({
        editAppointmentDays: value,
      });
    }
  };

  handleClaimTabData() {
    let self = this;
    this.SettingsService.Post("/ModuleSetting/GetStoreAttachmentSettings")
      .then((response) => {
        let status = response.message;
        let data = response.responseData;
        if (status === "Success" && data) {
          self.setState({
            maxAttachSize: data.arrachementSizeList,
            fileFormat: data.storeAttachmentFileFormatList,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  //// handle Get Campaign channel data
  handleCampaignChannelGridData() {
    let self = this;
    this.SettingsService.Post("/StoreCampaign/GetCampaignSettingList")
      .then((response) => {
        let status = response.message;
        let data = response.responseData;
        if (status === "Success") {
          self.setState({
            campaignChannelData: data.campaignSettingTimer,
          });
        } else {
          self.setState({
            campaignChannelData: {},
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  ////get Store Code for dropdown list
  handleGetstoreCodeData() {
    let self = this;
    this.SettingsService.Post("/Store/StoreList")
      .then((response) => {
        let status = response.message;
        let data = response.responseData;
        if (status === "Success") {
          data.forEach((element) => {
            element.isChecked = false;
          });
          self.setState({ storeCodeData: data, tempStoreCodeData: data });
        } else {
          self.setState({ storeCodeData: [], tempStoreCodeData: [] });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  ////get Language for dropdown list
  handleGetLanguageDropdownlist() {
    let self = this;
    this.SettingsService.Post("/StoreCampaign/GetLanguageDetails")
      .then((response) => {
        let status = response.message;
        let data = response.responseData;
        if (status === "Success") {
          self.setState({ languageData: data });
        } else {
          self.setState({ languageData: [] });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  ////get Language grid data
  handleGetLanguageGridData() {
    let self = this;
    this.SettingsService.Post("/StoreCampaign/GetSelectedLanguageDetails")
      .then((response) => {
        let status = response.message;
        let data = response.responseData;
        if (status === "Success") {
          self.setState({ languageGridData: data });
        } else {
          self.setState({ languageGridData: [] });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  //// handle table record deleted
  handleDeleteTimeSlot(slotId) {
    const TranslationContext = this.state.translateLanguage.default;
    var self = this;
    let inputParams = {
      SlotID: slotId,
    };
    this.SettingsService.PostWithParams(
      "/Appointment/DeleteTimeSlotMaster",
      inputParams
    )
      .then((response) => {
        let status = response.message;
        if (status === "Success") {
          NotificationManager.success(
            TranslationContext !== undefined
              ? TranslationContext.alertmessage.recorddeletedsuccessfully
              : "Record Deleted Successfully."
          );
          // self.handleGetTimeslotGridData();
          self.handleGetTimeslotGridData(
            0,
            "",
            "",
            0,
            false,
            this.state.isVideoCallAppointmentTab
          );
        } else {
          NotificationManager.error(
            TranslationContext !== undefined
              ? TranslationContext.alertmessage.recordnotdeleted
              : "Record Not Deleted."
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  /// handle Delete Language record
  handleDeleteLanguage = (row, type) => {
    const TranslationContext = this.state.translateLanguage.default;

    let languageGridData = [...this.state.languageGridData],
      isActive;

    for (let i = 0; i < languageGridData.length; i++) {
      if (languageGridData[i].languageID === row.languageID) {
        isActive = languageGridData[i].isActive;
        languageGridData[i].isActive = isActive === false ? true : false;
      }
    }

    this.setState({
      languageGridData,
    });

    var self = this;
    let inputParams = {
      selectedLanguageID: row.id,
      isActive: row.isActive,
    };
    this.SettingsService.PostWithParams(
      "/StoreCampaign/DeleteSelectedLanguage",
      inputParams
    )
      .then((response) => {
        let status = response.message;
        if (status === "Success") {
          NotificationManager.success(
            TranslationContext !== undefined
              ? TranslationContext.alertmessage.recordupdatedsuccessfully
              : "Record Updated Successfully."
          );
          self.handleGetLanguageGridData();
        } else {
          NotificationManager.error(
            TranslationContext !== undefined
              ? TranslationContext.alertmessage.recordnotupdated
              : status
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  //// Handle get time slot grid data
  handleGetTimeslotGridData(
    slotID,
    storeId,
    opdays,
    slotTemplateID,
    isFilterSearch,
    isVideoCallAppointmentTab
  ) {
    let self = this;
    this.setState({ isSlotLoading: true });
    if (isFilterSearch) {
      this.setState({ isFilterSearch: true });
    } else {
      this.setState({ isFilterSearch: false });
    }

    let inputParams = {
      SlotID: slotID ? slotID : 0,
      StoreID: storeId || "",
      Opdays: opdays ? opdays : "",
      SlotTemplateID: slotTemplateID ? slotTemplateID : 0,
    };
    let Url = "";
    if (isVideoCallAppointmentTab) {
      Url = "/Appointment/GetStoreSettingVideoTimeSlotNew";
    } else {
      Url = "/Appointment/GetStoreSettingTimeSlotNew";
    }
    this.SettingsService.PostWithParams(Url, inputParams)
      .then((response) => {
        let status = response.message;
        let data = response.responseData;
        if (status === "Success") {
          self.setState({
            TimeSlotGridData: data,
            isSlotLoading: false,
          });
        } else {
          self.setState({
            TimeSlotGridData: [],
            isSlotLoading: false,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  //// handle Get Appointment configuration data
  handleGetAppointmentConfigData() {
    let self = this;
    this.SettingsService.Post("/StoreCampaign/GetAppointmentConfiguration")
      .then((response) => {
        let status = response.message;
        let data = response.responseData;
        if (status === "Success") {
          self.setState({
            AppointConfigData: data,
          });
        } else {
          self.setState({
            AppointConfigData: {},
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  //// handle Get Broad cast configuration data
  handleGetBroadCastConfigData() {
    let self = this;
    this.SettingsService.Post("/StoreCampaign/GetBroadcastConfiguration")
      .then((response) => {
        let status = response.message;
        let data = response.responseData;
        if (status === "Success") {
          self.setState({
            BroadCastConfigData: data,
          });
        } else {
          self.setState({
            BroadCastConfigData: {},
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  handleCampaignScriptGridData() {
    let self = this;
    this.SettingsService.Post("/ModuleSetting/GetCampaignScript")
      .then((response) => {
        let status = response.message;
        let data = response.responseData;
        if (status === "Success" && data) {
          self.setState({
            campaignScriptData: data,
          });

          self.state.sortAllData = data;
          var unique = [];
          var distinct = [];
          for (let i = 0; i < data.length; i++) {
            if (!unique[data[i].campaignName]) {
              distinct.push(data[i].campaignName);
              unique[data[i].campaignName] = 1;
            }
          }
          for (let i = 0; i < distinct.length; i++) {
            if (distinct[i]) {
              self.state.sortcampaignName.push({ campaignName: distinct[i] });
              self.state.sortFiltercampaignName.push({
                campaignName: distinct[i],
              });
            }
          }

          var unique = [];
          var distinct = [];
          for (let i = 0; i < data.length; i++) {
            if (!unique[data[i].createdBy]) {
              distinct.push(data[i].createdBy);
              unique[data[i].createdBy] = 1;
            }
          }
          for (let i = 0; i < distinct.length; i++) {
            if (distinct[i]) {
              self.state.sortcreatedBy.push({ createdBy: distinct[i] });
              self.state.sortFiltercreatedBy.push({ createdBy: distinct[i] });
            }
          }

          var unique = [];
          var distinct = [];
          for (let i = 0; i < data.length; i++) {
            if (!unique[data[i].statusName]) {
              distinct.push(data[i].statusName);
              unique[data[i].statusName] = 1;
            }
          }
          for (let i = 0; i < distinct.length; i++) {
            if (distinct[i]) {
              self.state.sortstatus.push({ status: distinct[i] });
              self.state.sortFilteristatus.push({ status: distinct[i] });
            }
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  handleLoadSlotData(isVideoCallAppointmentTab) {
    let self = this;
    let Url = "";
    if (!isVideoCallAppointmentTab) {
      Url = "/Appointment/GetVideoSlotFilterDetails";
    } else {
      Url = "/Appointment/GetSlotFilterDetails";
    }
    this.SettingsService.Post(Url)
      .then((response) => {
        let status = response.message;
        let responseData = response.responseData;
        let onLoadSlotStores = responseData.slotStores;
        let onLoadOperationalDays = responseData.operationalDays;
        let onLoadSlotTemplate = responseData.slotTemplate;
        if (status === "Success") {
          onLoadSlotStores.forEach((element) => {
            element.isChecked = false;
          });
          self.setState({
            tempOnLoadSlotStores: onLoadSlotStores,
            onLoadSlotStores,
            onLoadOperationalDays,
            onLoadSlotTemplate,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  handleAttachmentSave() {
    const TranslationContext = this.state.translateLanguage.default;
    if (
      parseInt(this.state.selectedMaxAttachSize) != 0 &&
      parseInt(this.state.selectedFileFormat) != 0
    ) {
      let self = this;
      this.setState({
        claimTabLoading: true,
      });
      let inputData = {
        AttachmentSize: this.state.selectedMaxAttachSize,
        FileFomatID: this.state.selectedFileFormat,
      };
      this.SettingsService.PostWithData(
        "/ModuleSetting/ModifyStoreAttachmentSettings",
        inputData
      )
        .then((response) => {
          let status = response.message;
          if (status === "Success") {
            NotificationManager.success(
              TranslationContext !== undefined
                ? TranslationContext.alertmessage.attachmentsavesuccessfully
                : "Attachment saved successfully."
            );
            self.setState({
              selectedMaxAttachSize: "0",
              selectedFileFormat: "0",
              maxAttachSizeCompulsion: "",
              fileFormatCompulsion: "",
              claimTabLoading: false,
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      this.setState({
        maxAttachSizeCompulsion: "Please select max attachment size",
        fileFormatCompulsion: "Please select file format",
      });
    }
  }

  /// handle toggle change data
  CampChannelSmsFlageOnchange = (id) => {
    var CampId = id.target.id;
    if (CampId === "ckSmsCamp1") {
      this.state.campaignChannelData.smsFlag =
        !this.state.campaignChannelData.smsFlag;
    } else if (CampId === "ckWhatCamp2") {
      this.state.campaignChannelData.messengerFlag =
        !this.state.campaignChannelData.messengerFlag;
    } else if (CampId === "ckChatCamp3") {
      this.state.campaignChannelData.botFlag =
        !this.state.campaignChannelData.botFlag;
    } else if (CampId === "ckEmailCamp4") {
      this.state.campaignChannelData.emailFlag =
        !this.state.campaignChannelData.emailFlag;
    } else if (CampId === "ckCmpAutoAssignedCamp5") {
      this.state.campaignChannelData.campaignAutoAssigned =
        !this.state.campaignChannelData.campaignAutoAssigned;
    } else if (CampId === "ckCmpAutoAssignedCamp6") {
      this.state.campaignChannelData.raiseTicketFlag =
        !this.state.campaignChannelData.raiseTicketFlag;
    } else if (CampId === "ckCmpAutoAssignedCamp7") {
      this.state.campaignChannelData.addCommentFlag =
        !this.state.campaignChannelData.addCommentFlag;
    } else if (CampId === "ckStoreFilter") {
      this.state.campaignChannelData.storeFlag =
        !this.state.campaignChannelData.storeFlag;
    } else if (CampId === "ckZoneFilter") {
      this.state.campaignChannelData.zoneFlag =
        !this.state.campaignChannelData.zoneFlag;
    } else if (CampId === "ckUserData") {
      this.state.campaignChannelData.userProductivityReport =
        !this.state.campaignChannelData.userProductivityReport;
    } else if (CampId === "ckStoreData") {
      this.state.campaignChannelData.storeProductivityReport =
        !this.state.campaignChannelData.storeProductivityReport;
    }
    this.setState({ campaignChannelData: this.state.campaignChannelData });
  };

  /// handle Appointment configuration toggle change
  AppoinmentConfigFlageChange = (id) => {
    var AppointConfig = id.target.id;
    if (AppointConfig === "ckAppconfigOTP") {
      this.state.AppointConfigData.generateOTP =
        !this.state.AppointConfigData.generateOTP;
    } else if (AppointConfig === "ckAppconfigCardQR") {
      this.state.AppointConfigData.cardQRcode =
        !this.state.AppointConfigData.cardQRcode;
    } else if (AppointConfig === "ckAppconfigCardBar") {
      this.state.AppointConfigData.cardBarcode =
        !this.state.AppointConfigData.cardBarcode;
    } else if (AppointConfig === "ckAppconfigCard") {
      this.state.AppointConfigData.onlyCard =
        !this.state.AppointConfigData.onlyCard;
    } else if (AppointConfig === "ckAppconfigWhatsApp") {
      this.state.AppointConfigData.viaWhatsApp =
        !this.state.AppointConfigData.viaWhatsApp;
      if (this.state.AppointConfigData.viaWhatsApp === true) {
        this.state.AppointConfigData.viaSMS = false;
      }
    } else if (AppointConfig === "ckAppconfigSMS") {
      this.state.AppointConfigData.viaSMS =
        !this.state.AppointConfigData.viaSMS;
      if (this.state.AppointConfigData.viaSMS === true) {
        this.state.AppointConfigData.viaWhatsApp = false;
      }
    } else if (AppointConfig === "ckReminderViaWhapp") {
      this.state.AppointConfigData.reminderViaWhatsApp =
        !this.state.AppointConfigData.reminderViaWhatsApp;
      if (this.state.AppointConfigData.reminderViaWhatsApp === true) {
        this.state.AppointConfigData.reminderViaSMS = false;
      }
    } else if (AppointConfig === "ckReminderViaSMS") {
      this.state.AppointConfigData.reminderViaSMS =
        !this.state.AppointConfigData.reminderViaSMS;
      if (this.state.AppointConfigData.reminderViaSMS === true) {
        this.state.AppointConfigData.reminderViaWhatsApp = false;
      }
    }

    this.setState({ AppointConfigData: this.state.AppointConfigData });
  };
  /// handle Broadcast configuration toggle change
  handleBroadCongiFlageOnchange = (id) => {
    var BroadConfig = id.target.id;
    if (BroadConfig === "ckbroadSMS") {
      this.state.BroadCastConfigData.smsFlag =
        !this.state.BroadCastConfigData.smsFlag;
    } else if (BroadConfig === "ckbroadwhatsapp") {
      this.state.BroadCastConfigData.whatsappFlag =
        !this.state.BroadCastConfigData.whatsappFlag;
    } else if (BroadConfig === "ckbroadEmail") {
      this.state.BroadCastConfigData.emailFlag =
        !this.state.BroadCastConfigData.emailFlag;
    } else if (BroadConfig === "ckChatSessionActive") {
      this.state.BroadCastConfigData.chatSessionActiveFlag =
        !this.state.BroadCastConfigData.chatSessionActiveFlag;
    }

    this.setState({ BroadCastConfigData: this.state.BroadCastConfigData });
  };

  //Video Call Appointment data saving

  handleStaffNameSwitch() {
    this.setState({
      videoCallAppointmentData: {
        ...this.state.videoCallAppointmentData,
        isStaffNameSwitch:
          !this.state.videoCallAppointmentData.isStaffNameSwitch,
      },
    });
  }
  handleMultipleTimeSlot() {
    this.setState({
      videoCallAppointmentData: {
        ...this.state.videoCallAppointmentData,
        isMultipleTimeSlot:
          !this.state.videoCallAppointmentData.isMultipleTimeSlot,
      },
    });
  }

  handleVideoCallInputChange(e) {
    this.setState({
      videoCallAppointmentData: {
        ...this.state.videoCallAppointmentData,
        [e.target.name]: e.target.value,
      },
    });
  }

  handleVideoCallAppointmentSubmit() {
    console.log(this.state.videoCallAppointmentData);
  }

  //Video call slot booking data saving
  handlePaymentLinkSwitch() {
    this.setState({
      videoCallSlotSetttngData: {
        ...this.state.videoCallSlotSetttngData,
        sentPaymentLinkFlag:
          !this.state.videoCallSlotSetttngData.sentPaymentLinkFlag,
      },
    });
  }
  handleCustomerNotificationSwitch() {
    this.setState({
      videoCallSlotSetttngData: {
        ...this.state.videoCallSlotSetttngData,
        reminderForCustomerFlag:
          !this.state.videoCallSlotSetttngData.reminderForCustomerFlag,
      },
    });
  }
  handleStoreStaffNotificationSwitchSwitch() {
    this.setState({
      videoCallSlotSetttngData: {
        ...this.state.videoCallSlotSetttngData,
        reminderForStoreStaffFlag:
          !this.state.videoCallSlotSetttngData.reminderForStoreStaffFlag,
      },
    });
  }

  handleVideoCallSlotSettingInputChange(e) {
    this.setState({
      videoCallSlotSetttngData: {
        ...this.state.videoCallSlotSetttngData,
        [e.target.name]: e.target.value,
      },
    });
  }
  /// update campaign change data
  CampCannelOnChange(e) {
    const { name, value } = e.target;
    var campaignChannelData = this.state.campaignChannelData;
    if (name === "enableClickAfterValue") {
      if (campaignChannelData["enableClickAfterDuration"] == "M") {
        if (parseInt(value) <= 60) {
          campaignChannelData[name] = value;
          this.setState({ campaignChannelData });
        } else {
          campaignChannelData[name] = "";
          this.setState({ campaignChannelData });
        }
      } else {
        if (parseInt(value) <= 99) {
          campaignChannelData[name] = value;
          this.setState({ campaignChannelData });
        } else {
          campaignChannelData[name] = "";
          this.setState({ campaignChannelData });
        }
      }
    } else {
      if (name === "enableClickAfterDuration") {
        if (value === "M") {
          if (campaignChannelData["enableClickAfterValue"] > 60)
            campaignChannelData["enableClickAfterValue"] = "";
        }

        if (value === "H") {
          if (campaignChannelData["enableClickAfterValue"] > 99)
            campaignChannelData["enableClickAfterValue"] = "";
        }
      }
      campaignChannelData[name] = value;
      this.setState({ campaignChannelData });
    }
  }
  /// Handle Braod cast onchange
  BroadCastOnChange(e) {
    const { name, value } = e.target;
    var BroadCastConfigData = this.state.BroadCastConfigData;
    if (name === "enableClickAfterValue") {
      if (BroadCastConfigData["enableClickAfterDuration"] == "M") {
        if (parseInt(value) <= 60) {
          BroadCastConfigData[name] = value;
          this.setState({ BroadCastConfigData });
        } else {
          BroadCastConfigData[name] = "";
          this.setState({ BroadCastConfigData });
        }
      } else {
        if (parseInt(value) <= 99) {
          BroadCastConfigData[name] = value;
          this.setState({ BroadCastConfigData });
        } else {
          BroadCastConfigData[name] = "";
          this.setState({ BroadCastConfigData });
        }
      }
    } else {
      if (name === "enableClickAfterDuration") {
        if (value === "M") {
          if (BroadCastConfigData["enableClickAfterValue"] > 60)
            BroadCastConfigData["enableClickAfterValue"] = "";
        }

        if (value === "H") {
          if (BroadCastConfigData["enableClickAfterValue"] > 99)
            BroadCastConfigData["enableClickAfterValue"] = "";
        }
      }
      BroadCastConfigData[name] = value;
      this.setState({ BroadCastConfigData });
    }
  }
  handleAppointmentReminder(e) {
    const { name, value } = e.target;
    var AppointConfigData = this.state.AppointConfigData;

    AppointConfigData[name] = value;
    this.setState({ AppointConfigData });
  }
  /// handle camapign validation
  handleCheckCampaignValidation() {
    if (this.state.campaignChannelData.smsFlag) {
      if (this.state.campaignChannelData.providerName !== "") {
        this.handleUpdateCampChannelData();
      } else {
        this.setState({
          campProviderValidation: "Required",
        });
      }
    } else {
      this.handleUpdateCampChannelData();
    }
  }
  /// handle Campaign Channerl update data
  handleUpdateCampChannelData() {
    const TranslationContext = this.state.translateLanguage.default;
    if (
      this.state.campaignChannelData.maxClickAllowed !== "" &&
      this.state.campaignChannelData.enableClickAfterValue !== ""
    ) {
      this.setState({ isSubmit: true });
      let self = this;
      let inputData = {
        ID: this.state.campaignChannelData.id,
        MaxClickAllowed: this.state.campaignChannelData.maxClickAllowed,
        EnableClickAfterValue:
          this.state.campaignChannelData.enableClickAfterValue,
        EnableClickAfterDuration:
          this.state.campaignChannelData.enableClickAfterDuration,
        SmsFlag: this.state.campaignChannelData.smsFlag,
        EmailFlag: this.state.campaignChannelData.emailFlag,
        CampaignAutoAssigned:
          this.state.campaignChannelData.campaignAutoAssigned,
        MessengerFlag: this.state.campaignChannelData.messengerFlag,
        BotFlag: this.state.campaignChannelData.botFlag,
        ProviderName:
          this.state.campaignChannelData.providerName !== ""
            ? this.state.campaignChannelData.providerName
            : "",
        RaiseTicketFlag: this.state.campaignChannelData.raiseTicketFlag,
        AddCommentFlag: this.state.campaignChannelData.addCommentFlag,
        StoreFlag: this.state.campaignChannelData.storeFlag,
        ZoneFlag: this.state.campaignChannelData.zoneFlag,
        UserProductivityReport:
          this.state.campaignChannelData.userProductivityReport,
        StoreProductivityReport:
          this.state.campaignChannelData.storeProductivityReport,
      };
      this.SettingsService.PostWithData(
        "/StoreCampaign/UpdateCampaignMaxClickTimer",
        inputData
      )
        .then((response) => {
          let status = response.message;
          self.setState({ isSubmit: false });
          if (status === "Success") {
            NotificationManager.success(
              TranslationContext !== undefined
                ? TranslationContext.alertmessage.campaignupdatedsuccessfully
                : "Campaign Updated Successfully."
            );
          }
        })
        .catch((error) => {
          self.setState({ isSubmit: false });
          console.log(error);
        });
    } else {
      this.setState({
        maxClickValidation: "Required",
        enabledAfterValidation: "Required",
      });
    }
  }
  /// handle Appointment Configuration update data
  handleUpdateAppointmentConfigData() {
    const TranslationContext = this.state.translateLanguage.default;
    let self = this;
    if (this.state.AppointConfigData.remindBeforeValue !== "") {
      this.setState({ isSubmit: true });
      let inputData = {
        ID: this.state.AppointConfigData.id,
        GenerateOTP: this.state.AppointConfigData.generateOTP,
        CardQRcode: this.state.AppointConfigData.cardQRcode,
        CardBarcode: this.state.AppointConfigData.cardBarcode,
        OnlyCard: this.state.AppointConfigData.onlyCard,
        ViaWhatsApp: this.state.AppointConfigData.viaWhatsApp,
        ViaSMS: this.state.AppointConfigData.viaSMS,
        IsMsgWithin24Hrs: this.state.AppointConfigData.isMsgWithin24Hrs,
        MessageViaWhatsApp: this.state.AppointConfigData.messageViaWhatsApp,
        MessageViaSMS: this.state.AppointConfigData.messageViaSMS,
        ReminderViaWhatsApp: this.state.AppointConfigData.reminderViaWhatsApp,
        ReminderViaSMS: this.state.AppointConfigData.reminderViaSMS,
        ReminderMessageViaSMS:
          this.state.AppointConfigData.reminderMessageViaSMS,
        // RemindBeforeValue: this.state.AppointConfigData.remindBeforeValue,
        RemindBeforeDuration: this.state.AppointConfigData.remindBeforeDuration,
      };
      this.SettingsService.PostWithData(
        "/StoreCampaign/UpdateAppointmentConfiguration",
        inputData
      )
        .then((response) => {
          let status = response.message;
          self.setState({ isSubmit: false });
          if (status === "Success") {
            NotificationManager.success(
              TranslationContext !== undefined
                ? TranslationContext.alertmessage.appointmentupdatedsuccessfully
                : "Appointment Updated Successfully."
            );
          }
        })
        .catch((error) => {
          console.log(error);
          self.setState({ isSubmit: false });
        });
    } else {
      this.setState({
        remindBeforeValueValidation: "Required",
      });
    }
  }
  CheckBroadCastValidation() {
    if (this.state.BroadCastConfigData.smsFlag) {
      if (this.state.BroadCastConfigData.providerName !== "") {
        this.handleUpdateBroadCastConfigData();
      } else {
        this.setState({
          broadProviderValidation: "Required",
        });
      }
    } else {
      this.handleUpdateBroadCastConfigData();
    }
  }
  /// handle Broad cast Configuration update data
  handleUpdateBroadCastConfigData() {
    const TranslationContext = this.state.translateLanguage.default;
    let self = this;
    this.setState({ isSubmit: true });
    if (
      this.state.BroadCastConfigData.maxClickAllowed !== "" &&
      this.state.BroadCastConfigData.enableClickAfterValue !== ""
    ) {
      let inputData = {
        ID: this.state.BroadCastConfigData.id,
        MaxClickAllowed: this.state.BroadCastConfigData.maxClickAllowed,
        EnableClickAfterValue:
          this.state.BroadCastConfigData.enableClickAfterValue,
        EnableClickAfterDuration:
          this.state.BroadCastConfigData.enableClickAfterDuration,
        SmsFlag: this.state.BroadCastConfigData.smsFlag,
        EmailFlag: this.state.BroadCastConfigData.emailFlag,
        WhatsappFlag: this.state.BroadCastConfigData.whatsappFlag,
        ChatSessionActiveFlag:
          this.state.BroadCastConfigData.chatSessionActiveFlag,
        ProviderName: this.state.BroadCastConfigData.providerName,
      };
      this.SettingsService.PostWithData(
        "/StoreCampaign/UpdateBroadcastConfiguration",
        inputData
      )
        .then((response) => {
          let status = response.message;
          self.setState({ isSubmit: false });
          if (status === "Success") {
            NotificationManager.success(
              TranslationContext !== undefined
                ? TranslationContext.alertmessage.broadcastupdatedsuccessfully
                : "Broadcast Updated Successfully."
            );
          }
        })
        .catch((error) => {
          self.setState({ isSubmit: false });
          console.log(error);
        });
    } else {
      this.setState({
        braodCastMaxClickValid: "Required",
        broadCastEnabledAfterValid: "Required",
      });
    }
  }

  /// handle insert and update slot setting
  handleInsertUpdateTimeSlotSetting(isInsert, e) {
    const TranslationContext = this.state.translateLanguage.default;
    var self = this;

    var inputParam = {};
    let finalValidation = false;
    if (isInsert) {
      var SlotTemplateGridData = this.state.SlotTemplateGridData;
      if (this.state.isVideoCallAppointmentTab) {
        for (let i = 0; i < SlotTemplateGridData.length; i++) {
          if (!SlotTemplateGridData[i].storeStaffNameID) {
            NotificationManager.error("Please Select Store Staff Name");
            break;
          } else {
            finalValidation = true;
          }
        }
        if (!finalValidation) {
          return false;
        }
      } else {
        if (
          this.state.SlotDisplayCode == "0" ||
          this.state.SlotDisplayCode == ""
        ) {
          this.setState({
            isSlotDisplayCode:
              TranslationContext !== undefined
                ? TranslationContext.validation.pleaseselectslotdisplaycode
                : "Please Select Slot Display Code.",
          });
        } else {
          this.setState({ isSlotDisplayCode: "" });
        }
        if (parseInt(this.state.maxPeopleAppointment) == 0) {
          this.setState({
            isMaxPeople:
              TranslationContext !== undefined
                ? TranslationContext.validation
                    .pleaseselectmaxpeopleonappointment
                : "Please Select Max People on Appointment.",
          });
        } else {
          this.setState({ isMaxPeople: "" });
        }
        if (parseInt(this.state.slotDaysDisplay) == 0) {
          this.setState({
            isSlotDaysDisplay:
              TranslationContext !== undefined
                ? TranslationContext.validation.pleaseselectslotdaysdisplay
                : "Please Select Slot Days Display.",
          });
        } else {
          this.setState({ isSlotDaysDisplay: "" });
        }
        if (this.state.shoreSelectedCount == 0) {
          this.setState({
            isChooseStore:
              TranslationContext !== undefined
                ? TranslationContext.validation.pleaseselectstore
                : "Please Select Store.",
          });
        } else {
          this.setState({ isChooseStore: "" });
        }
        if (this.state.operationalDays.length === 0) {
          this.setState({
            isoperationalDay:
              TranslationContext !== undefined
                ? TranslationContext.validation.pleaseselectoperationaldays
                : "Please Select Operational Days.",
          });
        } else {
          this.setState({ isoperationalDay: "" });
        }
        if (parseInt(this.state.selectedSlotTemplate) == 0) {
          this.setState({
            isSlotTemplete:
              TranslationContext !== undefined
                ? TranslationContext.validation.pleaseselectslottemplate
                : "Please Select Slot Template.",
          });
        } else {
          this.setState({ isSlotTemplete: "" });
        }

        if (SlotTemplateGridData.length > 0) {
          for (var i = 0; i < SlotTemplateGridData.length; i++) {
            if (
              SlotTemplateGridData[i].slotOccupancy === 0 ||
              SlotTemplateGridData[i].slotOccupancy === "0" ||
              SlotTemplateGridData[i].slotOccupancy === ""
            ) {
              NotificationManager.error("Please enter slot occupancy.");
              return false;
            }
          }
        }
      }
      var StoreIds = "";
      this.state.storeCodeData.forEach((element) => {
        if (element.isChecked) {
          StoreIds += element.storeID + ",";
        }
      });
      var StoreOpdays = "";
      this.state.operationalDays.forEach((element) => {
        StoreOpdays += element.dayID + ",";
      });
      inputParam.SlotId = 0;
      inputParam.StoreIds = StoreIds;
      inputParam.StoreOpdays = StoreOpdays;
      inputParam.SlotTemplateID = this.state.selectedSlotTemplate;
      inputParam.SlotMaxCapacity = this.state.maxPeopleAppointment || 1;
      inputParam.AppointmentDays = this.state.slotDaysDisplay || 1;
      inputParam.ApplicableFromDate = moment(this.state.applicableFromDate)
        .format("YYYY-MM-DD")
        .toString();
      inputParam.IsActive = this.state.slotStatus === 1 ? true : false;
      inputParam.TemplateSlots = this.state.SlotTemplateGridData;
      if (this.state.isVideoCallAppointmentTab) {
        inputParam.SlotDisplayCode = 301;
      } else {
        inputParam.SlotDisplayCode = this.state.SlotDisplayCode;
      }
    } else {
      var editSlotTemplateGridData = this.state.editSlotTemplateGridData;

      if (parseInt(this.state.editSlotDisplayCode) == 0) {
        this.setState({
          isEditSlotDisplayCode:
            TranslationContext !== undefined
              ? TranslationContext.validation.pleaseselectslotdisplaycode
              : "Please Select Slot Display Code.",
        });
      } else {
        this.setState({ isEditSlotDisplayCode: "" });
      }
      if (parseInt(this.state.editMaxCapacity) == 0) {
        this.setState({
          isEditMaxPeople:
            TranslationContext !== undefined
              ? TranslationContext.validation.pleaseselectmaxpeopleonappointment
              : "Please Select Max People on Appointment.",
        });
      } else {
        this.setState({ isEditMaxPeople: "" });
      }
      if (parseInt(this.state.editAppointmentDays) == 0) {
        this.setState({
          isEditSlotDaysDisplay:
            TranslationContext !== undefined
              ? TranslationContext.validation.pleaseselectslotdaysdisplay
              : "Please Select Slot Days Display.",
        });
      } else {
        this.setState({ isEditSlotDaysDisplay: "" });
      }

      if (editSlotTemplateGridData.length > 0) {
        for (var i = 0; i < editSlotTemplateGridData.length; i++) {
          if (
            editSlotTemplateGridData[i].slotOccupancy === 0 ||
            editSlotTemplateGridData[i].slotOccupancy === "0" ||
            editSlotTemplateGridData[i].slotOccupancy === ""
          ) {
            NotificationManager.error("Please enter slot occupancy.");
            return false;
          }
        }
      }

      this.state.operationalDaysList.sort((a, b) => a.dayID - b.dayID);
      var storeOpdays = "";
      this.state.operationalDaysList.forEach((element) => {
        storeOpdays += element.dayID + ",";
      });

      if (this.state.isVideoCallAppointmentTab) {
        for (let i = 0; i < editSlotTemplateGridData.length; i++) {
          if (!editSlotTemplateGridData[i].storeStaffNameID) {
            NotificationManager.error("Please Select Store Staff Name");
            finalValidation = false;
            break;
          } else {
            finalValidation = true;
          }
        }
        if (!finalValidation) {
          return false;
        }
      }

      inputParam.SlotId = this.state.slotId;
      inputParam.AppointmentDays = Number(this.state.editAppointmentDays);
      inputParam.IsActive =
        this.state.editSlotStatus === "Active" ? true : false;

      inputParam.SlotMaxCapacity = this.state.editMaxCapacity;
      inputParam.TemplateSlots = this.state.editSlotTemplateGridData;
      inputParam.SlotTemplateID = this.state.editSlotTemplateId;
      inputParam.StoreOpdays = storeOpdays;
      if (this.state.isVideoCallAppointmentTab) {
        inputParam.SlotDisplayCode = 301;
      } else {
        inputParam.SlotDisplayCode = this.state.editSlotDisplayCode;
      }
    }

    if (this.state.isVideoCallAppointmentTab) {
      if (this.state.notificationToAgent) {
        let defaultUserNotification = this.state.notificationToAgent
          .map((x) => x.agentID)
          .join(",");
        inputParam.DefaultUserNotification = defaultUserNotification;
        finalValidation = true;
      } else {
        NotificationManager.error("Select Default User Notifcation.");

        finalValidation = false;
        return false;
      }
    } else {
      if (
        parseInt(this.state.SlotDisplayCode) !== 0 &&
        parseInt(this.state.editMaxCapacity) !== 0 &&
        parseInt(this.state.slotDaysDisplay) !== 0 &&
        this.state.shoreSelectedCount !== 0 &&
        this.state.operationalDays.length !== 0 &&
        parseInt(this.state.selectedSlotTemplate) !== 0
      ) {
        finalValidation = true;
      } else {
        finalValidation = false;
        return false;
      }
    }

    if (isInsert && finalValidation) {
      this.setState({ isSlotSaveClick: true });
      this.SettingsService.PostWithData(
        `/Appointment/${
          this.state.isVideoCallAppointmentTab
            ? "InsertUpdateVideoTimeSlotSetting"
            : "InsertUpdateTimeSlotSetting"
        }`,
        inputParam
      )
        .then((response) => {
          let status = response.message;

          if (status === "Success") {
            if (isInsert) {
              self.state.storeCodeData.forEach((element) => {
                element.isChecked = false;
              });
              self.state.onLoadSlotStores.forEach((element) => {
                element.isChecked = false;
              });

              self.handleLoadSlotData(self.state.isVideoCallAppointmentTab);
              self.setState({
                onLoadSlotStores: self.state.onLoadSlotStores,
                filterOptionSelected: {},
                filterOperationalDays: {},
                filterSelectedStoreCode: "",
                filterSlotTemplate: 0,
                isFilterSearch: false,
                showApplyStoreData: false,
                SlotDisplayCode: 0,
                SlotTemplateGridData: [],
                slotStatus: 1,
                applicableFromDate: new Date(),
                maxPeopleAppointment: 0,
                slotDaysDisplay: 0,
                selectedSlotTemplate: 0,
                operationalDays: [],
                storeCodeData: self.state.storeCodeData,
                isNextClick: false,
                shoreSelectedCount: 0,
                selectedStoreValues: "",
                isSlotSaveClick: false,
                notificationToAgent: null,
                storeAgentList: [],
              });

              NotificationManager.success(
                TranslationContext !== undefined
                  ? TranslationContext.alertmessage.timeslotaddedsuccessfully
                  : "Time Slot Added Successfully."
              );
            } else {
              self.state.onLoadSlotStores.forEach((element) => {
                element.isChecked = false;
              });
              self.setState({
                onLoadSlotStores: self.state.onLoadSlotStores,
                filterOptionSelected: {},
                filterOperationalDays: {},
                filterSelectedStoreCode: "",
                filterSlotTemplate: 0,
                isFilterSearch: false,
                editSlotModal: false,
                notificationToAgent: null,
                storeAgentList: [],
              });
              NotificationManager.success("Time Slot Updated Successfully.");
            }

            // self.handleGetTimeslotGridData();
            self.handleGetTimeslotGridData(
              0,
              "",
              "",
              0,
              false,
              this.state.isVideoCallAppointmentTab
            );
          } else {
            NotificationManager.error(
              TranslationContext !== undefined
                ? TranslationContext.alertmessage.timeslotnotadded
                : "Time Slot Not Added."
            );

            self.setState({
              isSlotSaveClick: false,
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }

    if (this.state.isVideoCallAppointmentTab) {
      if (
        isInsert === false &&
        parseInt(this.state.editMaxCapacity) !== 0 &&
        parseInt(this.state.editAppointmentDays) !== 0
      ) {
        finalValidation = true;
      } else {
        finalValidation = false;
      }
    } else {
      if (
        isInsert === false &&
        parseInt(this.state.editSlotDisplayCode) !== 0 &&
        parseInt(this.state.editMaxCapacity) !== 0 &&
        parseInt(this.state.editAppointmentDays) !== 0
      ) {
        finalValidation = true;
      } else {
        finalValidation = false;
      }
    }
    if (isInsert === false && finalValidation) {
      this.setState({ isSlotEditLoading: true });
      this.SettingsService.PostWithData(
        `/Appointment/${
          this.state.isVideoCallAppointmentTab
            ? "UpdateVideoTimeSlotSettingNew"
            : "UpdateTimeSlotSettingNew"
        }`,
        inputParam
      )
        .then((response) => {
          let status = response.message;
          self.setState({ isSlotEditLoading: false });
          if (status === "Success") {
            if (isInsert) {
              self.state.storeCodeData.forEach((element) => {
                element.isChecked = false;
              });
              self.state.onLoadSlotStores.forEach((element) => {
                element.isChecked = false;
              });
              self.handleLoadSlotData(self.state.isVideoCallAppointmentTab);

              self.setState({
                onLoadSlotStores: self.state.onLoadSlotStores,
                filterOptionSelected: {},
                filterOperationalDays: {},
                filterSelectedStoreCode: "",
                filterSlotTemplate: 0,
                isFilterSearch: false,
                isSlotEditLoading: false,
                SlotDisplayCode: 0,
                SlotTemplateGridData: [],
                slotStatus: 1,
                applicableFromDate: new Date(),
                maxPeopleAppointment: 0,
                slotDaysDisplay: 0,
                selectedSlotTemplate: 0,
                operationalDays: [],
                storeCodeData: self.state.storeCodeData,
                isNextClick: false,
                shoreSelectedCount: 0,
                selectedStoreValues: "",
                isSlotSaveClick: false,
                notificationToAgent: null,
                storeAgentList: [],
              });
              NotificationManager.success(
                TranslationContext !== undefined
                  ? TranslationContext.alertmessage.timeslotaddedsuccessfully
                  : "Time Slot Added Successfully."
              );
            } else {
              self.state.onLoadSlotStores.forEach((element) => {
                element.isChecked = false;
              });
              self.setState({
                isSlotEditLoading: false,
                editSlotModal: false,
                onLoadSlotStores: self.state.onLoadSlotStores,
                filterOptionSelected: {},
                filterOperationalDays: {},
                filterSelectedStoreCode: "",
                filterSlotTemplate: 0,
                isFilterSearch: false,
                notificationToAgent: null,
                storeAgentList: [],
              });
              NotificationManager.success("Time Slot Updated Successfully.");
            }

            self.handleLoadSlotData(self.state.isVideoCallAppointmentTab);
            // self.handleGetTimeslotGridData();
            self.handleGetTimeslotGridData(
              0,
              "",
              "",
              0,
              false,
              this.state.isVideoCallAppointmentTab
            );
          } else {
            NotificationManager.error(
              TranslationContext !== undefined
                ? TranslationContext.alertmessage.timeslotnotadded
                : "Time Slot Not Added."
            );
          }
        })
        .catch((error) => {
          self.setState({ isSlotEditLoading: false });
          console.log(error);
        });
    }
  }

  handleSubmitLanguageDate() {
    const TranslationContext = this.state.translateLanguage.default;
    var self = this;
    if (parseInt(this.state.selectLanguage) !== 0) {
      this.setState({ isSubmit: true });
      let inputParams = {
        languageID: parseInt(this.state.selectLanguage),
      };
      this.SettingsService.PostWithParams(
        "/StoreCampaign/InsertLanguageDetails",
        inputParams
      )
        .then((response) => {
          let status = response.message;
          this.setState({ isSubmit: false });
          if (status === "Success") {
            NotificationManager.success(
              TranslationContext !== undefined
                ? TranslationContext.alertmessage.languageaddedsuccessfully
                : "Language Added Successfully."
            );
            self.handleGetLanguageGridData();
            self.setState({
              selectLanguage: 0,
            });
          } else {
            NotificationManager.error(
              TranslationContext !== undefined
                ? TranslationContext.alertmessage.languagenotadded
                : status
            );
          }
        })
        .catch((error) => {
          this.setState({ isSubmit: false });
          console.log(error);
        });
    } else {
      this.setState({
        languageValidation: "Please Select Language.",
      });
    }
  }

  closeSlotEditModal() {
    this.setState({
      editSlotModal: false,
    });
  }
  /// handle Edit Time slot
  openSlotEditModal(slotId, storeId) {
    let self = this;
    this.setState({ editSlotModal: true });

    let inputParams = {
      SlotID: slotId ? slotId : 0,
      StoreID: storeId,
    };
    this.SettingsService.PostWithParams(
      `/Appointment/${
        this.state.isVideoCallAppointmentTab
          ? "GetStoreSettingVideoTimeSlotNew"
          : "GetStoreSettingTimeSlotNew"
      }`,
      inputParams
    )
      .then((response) => {
        var message = response.message;
        var data = response.responseData;

        if (message === "Success") {
          var slotId = data[0].slotSettingID;
          var editstoreCode = data[0].storeCode;
          var storeTimimg = data[0].storeTimimg.match(
            /[a-zA-Z]+|[0-9]+(?:\.[0-9]+|)/g
          );
          let agentIds = data[0].defaultUserNotification;
          this.handleEditAgentName(storeId, agentIds);
          var storeTimimg = data[0].storeTimimg;

          var editAppointmentDays = data[0].appointmentDays;
          var editSlotDuration = data[0].storeSlotDuration.split(" ")[0];
          var editTotalSlot = data[0].totalSlot;
          var editOperationalDays = data[0].operationalDaysCount;
          var editOperationalDaysName = data[0].operationalDays;
          var editSlotTemplateName = data[0].slotTemplateName;
          var editSlotTemplateId = data[0].slotTemplateID;
          var editSlotTemplateGridData = data[0].templateSlots;
          var editSlotStatus = data[0].status;
          var editSlotDisplayCode = data[0].slotDisplayCode;
          var editMaxCapacity = data[0].maxCapacity;
          var operationalDaysList = [];

          if (data[0].operationalDaysList) {
            data[0].operationalDaysList.forEach((element) => {
              var objDaysList = {};
              objDaysList.dayID = Number(element.dayIDs);
              objDaysList.dayName = element.dayNames;
              operationalDaysList.push(objDaysList);
            });
          }

          self.setState({
            operationalDaysList,
            editSlotModal: true,
            slotId,
            editstoreCode,
            storeTimimg,
            editAppointmentDays,
            editSlotDuration,
            editTotalSlot,
            editOperationalDaysName,
            editOperationalDays,
            editSlotTemplateName,
            editSlotTemplateId,
            editSlotTemplateGridData,
            editSlotStatus,
            editSlotDisplayCode,
            editMaxCapacity,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  //Handle Edit Agent Name
  handleEditAgentName = (StoreId, agentIds) => {
    let inputParams = {
      BrandID: 0,
      StoreID: StoreId,
    };
    this.SettingsService.PostWithParams(
      "/HSSetting/GetStoreAgentList",
      inputParams
    )
      .then((response) => {
        let status = response.message;
        let data = response.responseData;
        if (status === "Success") {
          let notificationToAgent = [];
          if (agentIds) {
            let tempAgentData = agentIds.split(",");

            tempAgentData.forEach((element) => {
              notificationToAgent.push(
                data.filter((x) => x.agentID == element)[0]
              );
            });
          }
          this.setState({
            notificationToAgent,
            storeAgentList: data,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  handleChangeStoreDropdown(e) {
    this.setState({ FilterSelectStore: e.target.value });
    // this.handleGetTimeslotGridData(0, );
    this.handleGetTimeslotGridData(
      0,
      e.target.value,
      "",
      0,
      false,
      this.state.isVideoCallAppointmentTab
    );
  }
  handleStoreChangeChange = (e) => {
    const TranslationContext = this.state.translateLanguage.default;

    if (this.state.TimeSlotGridData.length > 0) {
      var isExits = this.state.TimeSlotGridData.filter(
        (x) => x.storeId === e[e.length - 1]
      );
      if (isExits.length > 0) {
        NotificationManager.error(
          TranslationContext !== undefined
            ? TranslationContext.alertmessage.slotalreadycreatedofthisstorecode
            : "Slot already created of this store code."
        );
      } else {
        if (e.length > 0) {
          this.setState({
            selectedStoreCode: e,
            storeCodeValidation: "",
          });
        } else {
          this.setState({
            storeCodeValidation: "Required",
            selectedStoreCode: e,
          });
        }
      }
    } else {
      if (e.length > 0) {
        this.setState({
          selectedStoreCode: e,
          storeCodeValidation: "",
        });
      } else {
        this.setState({
          storeCodeValidation: "Required",
          selectedStoreCode: e,
        });
      }
    }
  };
  /////handle next button press to show
  handleNextButtonOpen = () => {
    const TranslationContext = this.state.translateLanguage.default;
    if (
      this.state.shoreSelectedCount > 0 &&
      this.state.operationalDays.length > 0 &&
      this.state.selectedSlotTemplate > 0
    ) {
      this.setState({ isNextClick: true });
      this.handleGetSlotsByTemplateID();
      this.handleGetStoreAgentList();
    } else {
      if (this.state.shoreSelectedCount === 0) {
        this.setState({
          isChooseStore:
            TranslationContext !== undefined
              ? TranslationContext.validation.pleaseselectstore
              : "Please Select Store.",
        });
      } else {
        this.setState({ isChooseStore: "" });
      }
      if (this.state.operationalDays.length === 0) {
        this.setState({
          isoperationalDay:
            TranslationContext !== undefined
              ? TranslationContext.validation.pleaseselectoperationaldays
              : "Please Select Operational Days.",
        });
      } else {
        this.setState({ isoperationalDay: "" });
      }
      if (this.state.selectedSlotTemplate === 0) {
        this.setState({
          isSlotTemplete:
            TranslationContext !== undefined
              ? TranslationContext.validation.pleaseselectslottemplate
              : "Please Select Slot Template.",
        });
      } else {
        this.setState({ isSlotTemplete: "" });
      }
    }
  };
  /////handle next button press to hide
  handleNextButtonClose = () => {
    this.setState({ isNextClick: false });
  };

  ////handle choose store modal open
  handleChooseStoreOpenModal = () => {
    this.setState({ chooseStoreModal: true });
  };
  ////handle choose store modal close
  handleChooseStoreCloseModal = () => {
    this.setState({ chooseStoreModal: false });
  };

  ////handle choose store modal open on filter
  handleFilterChooseStoreOpenModal = () => {
    this.setState({ filterChooseStoreModal: true });
  };
  ////handle choose store modal close on filter
  handleFilterChooseStoreCloseModal = () => {
    this.setState({ filterChooseStoreModal: false });
  };

  ////handle choose store modal open
  handleCreateTempletetOpenModal = () => {
    this.setState({ createTampleteModal: true });
  };
  ////handle choose store modal close
  handleCreateTempletetCloseModal = () => {
    this.setState({ createTampleteModal: false });
  };
  ////handle selected store modal open
  handleSelectedStoreOpenModal = () => {
    this.setState({ selectedStoreModal: true });
  };
  ////handle selected store modal clsoe
  handleSelectedStoreCloseModal = () => {
    this.setState({ selectedStoreModal: false });
  };
  /// handle Slot Radio change
  handleSlotRadioChange = (e) => {
    this.setState({
      slotAutomaticRadio: e.target.value,
    });
  };

  handleSlotOnChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
      isEditSlotDisplayCode: "",
      isEditMaxPeople: "",
      isEditSlotDaysDisplay: "",
    });
  };
  /// handle Select Store Individual
  handleSelectStoresIndividual = async (data, event) => {
    var storeCount = 0;
    var finalValue = "";
    var values = "";

    if (this.state.storeCodeData.filter((x) => x.isChecked).length == 0) {
      if (!this.state.isVideoCallAppointmentTab) {
        this.state.storeCodeData.filter(
          (x) => x.storeID === data.storeID
        )[0].isChecked = !this.state.storeCodeData.filter(
          (x) => x.storeID === data.storeID
        )[0].isChecked;
      } else {
        this.state.storeCodeData.filter(
          (x) => x.storeID === data.storeID
        )[0].isChecked = !this.state.storeCodeData.filter(
          (x) => x.storeID === data.storeID
        )[0].isChecked;
      }
    } else {
      if (!this.state.isVideoCallAppointmentTab) {
        this.state.storeCodeData.filter(
          (x) => x.storeID === data.storeID
        )[0].isChecked = !this.state.storeCodeData.filter(
          (x) => x.storeID === data.storeID
        )[0].isChecked;
      } else {
        if (!event.target.checked) {
          this.state.storeCodeData.filter(
            (x) => x.storeID === data.storeID
          )[0].isChecked = !this.state.storeCodeData.filter(
            (x) => x.storeID === data.storeID
          )[0].isChecked;
        }
      }
    }

    this.state.storeCodeData.forEach((element) => {
      if (this.state.isVideoCallAppointmentTab) {
        if (storeCount === 0) {
          if (element.isChecked) {
            values += element.storeCode + ", ";
            storeCount += 1;
            this.setState({
              selectedStoreID: element.storeID,
            });
          }
        }
      } else if (element.isChecked) {
        values += element.storeCode + ", ";
        storeCount += 1;
      }
    });

    finalValue = values.substring(",", values.length - 1);
    this.setState({
      storeCodeData: this.state.storeCodeData,
      shoreSelectedCount: storeCount,
      selectedStoreValues: finalValue,
    });
  };
  //// handle Select All store name
  handleSelectAllStore = async (isSelectAll) => {
    var storeCount = 0;
    var finalValue = "";
    var values = "";

    this.state.storeCodeData.forEach((element) => {
      element.isChecked = isSelectAll ? true : false;
      if (element.isChecked) {
        values += element.storeName + ",";
        storeCount += 1;
      }
    });

    finalValue = values.substring(",", values.length - 1);
    this.setState({
      storeCodeData: this.state.storeCodeData,
      shoreSelectedCount: storeCount,
      selectedStoreValues: finalValue,
    });
  };

  /// handle apply selected store data
  handleApplySelectedStore() {
    const TranslationContext = this.state.translateLanguage.default;
    if (this.state.shoreSelectedCount == 0) {
      this.setState({
        isChooseStore:
          TranslationContext !== undefined
            ? TranslationContext.validation.pleaseselectstore
            : "please Select Store.",
      });
    } else {
      this.setState({
        isChooseStore: "",
      });
    }
    this.setState({
      chooseStoreModal: false,
      showApplyStoreData: true,
    });
  }
  /// handle filter store data
  handleStoreFilterData(filterData) {
    var tempstore = this.state.tempStoreCodeData;
    tempstore.forEach((element) => {
      element.isChecked = false;
    });
    var finalStore = tempstore.filter((item) =>
      item.storeCode.startsWith(filterData)
    );
    this.setState({
      storeCodeData: finalStore,
    });
  }
  /// handle slot store search
  handleSlotStoreSearchOnchange(e) {
    this.setState({ slotStoreSearch: e.target.value });
  }
  /// handle store search
  handleStoreSearch = (e) => {
    e.preventDefault();
    var tempstore = this.state.tempStoreCodeData;
    var Value = this.state.slotStoreSearch;
    tempstore.forEach((element) => {
      element.isChecked = false;
    });
    var FinalstoreList = tempstore.filter((item) =>
      item.storeCode.toLowerCase().includes(Value.toLowerCase())
    );

    if (FinalstoreList.length > 0) {
      this.setState({ storeCodeData: FinalstoreList });
    } else {
      this.setState({
        storeCodeData: [],
      });
    }
  };

  handleGetOperationalDays() {
    let self = this;
    this.SettingsService.Post("/Appointment/GetStoreOperationalDays")
      .then((response) => {
        let status = response.message;
        let data = response.responseData;
        if (status === "Success") {
          self.setState({ operationalDaysData: data });
        } else {
          self.setState({ operationalDaysData: [] });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  //// handle Remove store from selected store
  handleRemoveSelectedStore(store_id) {
    var storeCount = 0;
    var finalValue = "";
    var values = "";

    this.state.storeCodeData.filter(
      (x) => x.storeID === store_id
    )[0].isChecked = false;

    this.state.storeCodeData.forEach((element) => {
      if (element.isChecked) {
        storeCount += 1;
        values += element.storeName + ",";
      }
    });

    finalValue = values.substring(",", values.length - 1);

    this.setState({
      storeCodeData: this.state.storeCodeData,
      shoreSelectedCount: storeCount,
      selectedStoreValues: finalValue,
    });
  }
  handleRemoveOperationalDay(dayIndex) {
    let editOperationalDaysArray =
      this.state.editOperationalDaysName.split(",");
    editOperationalDaysArray.splice(dayIndex, 1);
    let editOperationalDaysName = editOperationalDaysArray.join(",");
    this.setState({
      editOperationalDaysName,
    });
  }
  handleGetSlotTemplate() {
    let self = this;

    this.SettingsService.Post("/Appointment/GetSlotTemplates")
      .then((response) => {
        let status = response.message;
        let data = response.responseData;
        if (status === "Success") {
          self.setState({ slotTemplateData: data });
        } else {
          self.setState({ slotTemplateData: [] });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  handleGetVideoSlotTemplate() {
    let self = this;

    this.SettingsService.Post("/Appointment/GetVideoSlotTemplates")
      .then((response) => {
        let status = response.message;
        let data = response.responseData;
        if (status === "Success") {
          self.setState({ videoSlotTemplateData: data });
        } else {
          self.setState({ videoSlotTemplateData: [] });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  /// handle Submit automatica data
  handleSubmitAutomaticData() {
    const TranslationContext = this.state.translateLanguage.default;
    if (
      this.state.autoTempName !== "" &&
      this.state.AutoSlotDuration !== 0 &&
      this.state.AutoSlotGap !== 0 &&
      this.state.autoStoreFrom !== "" &&
      this.state.autoStoreTo !== "" &&
      this.state.autoNonOptFrom !== "" &&
      this.state.autoNonOptTo !== ""
    ) {
      this.setState({ isGenrateSlotLoading: true });
      let self = this;
      let inputData = {
        SlotTemplateName: this.state.autoTempName.trim(),
        SlotTemplateType: this.state.slotAutomaticRadio === 1 ? "A" : "M",
        Slotduration: parseFloat(this.state.AutoSlotDuration),
        SlotGaps: parseFloat(this.state.AutoSlotGap),
        StoreOpenAt: moment(this.state.autoStoreFrom).format("HH:mm"),
        StoreCloseAt: moment(this.state.autoStoreTo).format("HH:mm"),
        StoreNonOpFromAt: moment(this.state.autoNonOptFrom).format("HH:mm"),
        StoreNonOpToAt: moment(this.state.autoNonOptTo).format("HH:mm"),
      };
      this.SettingsService.PostWithData(
        `/Appointment/${
          this.state.isVideoCallAppointmentTab
            ? "GetVideoGeneratedSlots"
            : "GetGeneratedSlots"
        }`,
        inputData
      )
        .then((response) => {
          let status = response.message;
          let data = response.responseData;
          if (status === "Success") {
            if (this.state.isVideoCallAppointmentTab) {
              data.forEach((element) => {
                element.slotOccupancy = this.state.videoCallMultipleSlot;
              });
            }
            self.setState({
              isGenrateSlotLoading: false,
              autoTempNameCompulsory: "",
              AutoSlotDurationCompulsory: "",
              AutoSlotGapCompulsory: "",
              autoStoreFromCompulsory: "",
              autoStoreToCompulsory: "",
              autoNonOptFromCompulsory: "",
              autoNonOptToCompulsory: "",
              automaticaSlotTblData: data,
            });

            NotificationManager.success("Slot Generated.");
          } else {
            NotificationManager.error("Slot Generated failed.");
            self.setState({ isGenrateSlotLoading: false });
          }
        })
        .catch((error) => {
          self.setState({ isGenrateSlotLoading: false });
          console.log(error);
        });
    } else {
      this.setState({
        autoTempNameCompulsory:
          TranslationContext !== undefined
            ? TranslationContext.validation.pleaseentertemplatename
            : "Please Enter Template Name.",
        AutoSlotDurationCompulsory:
          TranslationContext !== undefined
            ? TranslationContext.validation.pleaseselectslotduration
            : "Please Select Slot Duration.",
        AutoSlotGapCompulsory:
          TranslationContext !== undefined
            ? TranslationContext.validation.pleaseselectslotgap
            : "Please Select Slot Gap.",
        autoStoreFromCompulsory:
          TranslationContext !== undefined
            ? TranslationContext.validation.pleaseselectfromtime
            : "Please Select From Time.",
        autoStoreToCompulsory:
          TranslationContext !== undefined
            ? TranslationContext.validation.pleaseselecttotime
            : "Please Select To Time.",
        autoNonOptFromCompulsory:
          TranslationContext !== undefined
            ? TranslationContext.validation.pleaseselectfromtime
            : "Please Select From Time.",
        autoNonOptToCompulsory:
          TranslationContext !== undefined
            ? TranslationContext.validation.pleaseselecttotime
            : "Please Select To Time.",
      });
    }
  }
  //// handle finale automatic save data
  handleFinalSaveTemplateData(check) {
    let self = this;
    var inputParam = {};
    var slot_Id = 0;
    if (check === "Automatic") {
      inputParam.SlotTemplateName = this.state.autoTempName.trim();
      inputParam.SlotTemplateType = "A";
      inputParam.Slotduration = parseFloat(this.state.AutoSlotDuration);
      inputParam.SlotGaps = parseFloat(this.state.AutoSlotGap);
      inputParam.StoreOpenAt = moment(this.state.autoStoreFrom).format("HH:mm");
      inputParam.StoreCloseAt = moment(this.state.autoStoreTo).format("HH:mm");
      inputParam.StoreNonOpFromAt = moment(this.state.autoNonOptFrom).format(
        "HH:mm"
      );
      inputParam.StoreNonOpToAt = moment(this.state.autoNonOptTo).format(
        "HH:mm"
      );
      inputParam.TemplateSlots = this.state.automaticaSlotTblData;
    } else {
      var manualSlotDuration = parseFloat(this.state.ManualSlotDuration);
      var slot_Duration =
        manualSlotDuration === 15
          ? 0.25
          : manualSlotDuration === 30
          ? 0.5
          : manualSlotDuration === 45
          ? 0.5
          : manualSlotDuration === 60
          ? 0.75
          : manualSlotDuration === 1
          ? 1
          : manualSlotDuration === 1.5
          ? 1.5
          : manualSlotDuration === 2
          ? 2
          : 0;

      inputParam.SlotTemplateName = this.state.manualTempName.trim();
      inputParam.SlotTemplateType = "M";
      inputParam.Slotduration = slot_Duration;
      inputParam.SlotGaps = 0;
      inputParam.StoreOpenAt = moment(this.state.manualStoreFrom).format(
        "HH:mm"
      );
      inputParam.StoreCloseAt = moment(this.state.manualStoreTo).format(
        "HH:mm"
      );
      inputParam.StoreNonOpFromAt = "";
      inputParam.StoreNonOpToAt = "";
      this.state.manualStoreTblData.forEach((element) => {
        slot_Id += 1;
        element.slotID = slot_Id;
        element.slotOccupancy = 0;
        element.slotTemplateID = 0;
      });
      inputParam.TemplateSlots = this.state.manualStoreTblData;
    }
    if (this.state.isVideoCallAppointmentTab) {
      if (this.state.videoCallAppointmentData.isStaffNameSwitch) {
        if (this.state.videoCallAppointmentData.storeStaffName) {
          inputParam.StoreStaffName_ID = Number(
            this.state.videoCallAppointmentData.storeStaffName
          );
        } else {
          NotificationManager.error("Please select defualt store staff name.");
          return false;
        }
      } else {
        inputParam.StoreStaffName_ID = 0;
      }
    }
    this.setState({ isCreateTampleteLoading: true });
    this.SettingsService.PostWithData(
      `/Appointment/${
        this.state.isVideoCallAppointmentTab
          ? "CreateVideoSlotTemplate"
          : "CreateSlotTemplate"
      }`,
      inputParam
    )
      .then((response) => {
        let status = response.message;
        let data = response.responseData;
        if (status === "Success") {
          self.setState({
            autoTempName: "",
            AutoSlotDuration: 0,
            AutoSlotGap: 0,
            autoStoreFrom: "",
            autoStoreTo: "",
            autoNonOptFrom: "",
            autoNonOptTo: "",
            manualTempName: "",
            manualStoreFrom: "",
            manualStoreTo: "",
            ManualSlotDuration: 0,
            createTampleteModal: false,
            finalSlotTemplateId: data,
            isCreateTampleteLoading: false,
            automaticaSlotTblData: [],
            videoCallAppointmentData: {},
          });
          if (check === "Automatic") {
            NotificationManager.success("Automatic Template Created.");
          } else {
            NotificationManager.success("Manual Template Created.");
          }
          if (this.state.isVideoCallAppointmentTab) {
            self.handleGetVideoSlotTemplate();
          } else {
            self.handleGetSlotTemplate();
          }
        } else {
          if (check === "Automatic") {
            NotificationManager.error("Automatic Template Not Created..");
          } else {
            NotificationManager.error("Manual Template Not Created..");
          }
          self.setState({ isCreateTampleteLoading: false });
        }
      })
      .catch((error) => {
        self.setState({ isCreateTampleteLoading: false });
        console.log(error);
      });
  }

  /// handle manual time onchange
  handleManualTimeOnchange = (time, check) => {
    var manualStoreData = this.state.manualStoreData;
    if (check === "slotStartTime") {
      manualStoreData["slotStartTime"] = time;
      manualStoreData["manualSlotStartTime"] = time;
      this.state.manualStoreData["slotEndTime"] = moment(
        manualStoreData["slotStartTime"]
      )
        .add(Number(this.state.ManualSlotDuration), "m")
        .toDate();
    } else if (check === "slotEndTime") {
      manualStoreData["slotEndTime"] = time;
      manualStoreData["manualSlotEndTime"] = time;
    }
    this.setState({
      manualStoreData,
    });
  };
  /// handle Select automatic date
  handleSelectAutomaticStoreToDate = (time) => {
    if (this.state.autoStoreFrom < time) {
      this.setState({
        autoStoreTo: time,
      });
    } else {
      NotificationManager.error(
        "The To date must be greater than the From date.."
      );
    }
  };

  /// handle Select automatic date
  handleSelectAutomaticToDates = (time) => {
    if (this.state.autoNonOptFrom < time) {
      this.setState({
        autoNonOptTo: time,
      });
    } else {
      NotificationManager.error(
        "The To date must be greater than the From date.."
      );
    }
  };
  /// handle Select manual date
  handleSelectManualStoreTodate = (time) => {
    if (this.state.manualStoreFrom < time) {
      this.setState({
        manualStoreTo: time,
      });
    } else {
      NotificationManager.error(
        "The To date must be greater than the From date.."
      );
    }
  };
  /// handle Manual selct duration
  handleManualSelectDuration = (e) => {
    let values = e.target.value;
    var manualStoreData = this.state.manualStoreData;
    manualStoreData[e.target.name] = e.target.selectedOptions[0].text;
    manualStoreData["Slotduration "] = values;
    this.setState({ ManualSlotDuration: values, manualStoreData });
  };
  /// handle Create Manualy create slot
  handleAddManualySlot() {
    const TranslationContext = this.state.translateLanguage.default;
    if (
      this.state.manualTempName !== "" &&
      this.state.manualStoreFrom !== "" &&
      this.state.manualStoreTo !== "" &&
      this.state.ManualSlotDuration !== 0 &&
      this.state.manualStoreData.slotEndTime !== ""
    ) {
      var manualStoreTblData = [];
      manualStoreTblData = this.state.manualStoreTblData;

      var start = this.state.manualStoreData["slotStartTime"];
      var end = this.state.manualStoreData["slotEndTime"];

      var ObjData = {};
      ObjData.slotStartTime = moment(start).format("LT");
      ObjData.slotEndTime = moment(end).format("LT");

      manualStoreTblData.push(ObjData);

      var manualSlotTblData = [];
      manualSlotTblData = this.state.manualSlotTblData;

      var slotData = {};
      slotData.slotStartTime = moment(start);
      slotData.slotEndTime = moment(end);

      manualSlotTblData.push(slotData);

      this.state.manualStoreData["slotStartTime"] = "";
      this.state.manualStoreData["slotEndTime"] = "";

      this.setState({
        manualStoreTblData,
        mimSlotStartTimeChck: this.state.manualStoreData.slotEndTime,
        manualSlotTblData,
        manualStoreData: this.state.manualStoreData,
      });
      NotificationManager.success("Manual Slot Created.");
    } else {
      this.setState({
        manualTempNameCompulsory:
          TranslationContext !== undefined
            ? TranslationContext.validation.pleaseentertemplatename
            : "Please Enter Template Name.",
        manualStoreFromCompulsory:
          TranslationContext !== undefined
            ? TranslationContext.validation.pleaseselectfromtime
            : "Please Select From Time.",
        manualStoreToCompulsory:
          TranslationContext !== undefined
            ? TranslationContext.validation.pleaseselecttotime
            : "Please Select To Time.",
        ManualSlotDurationCompulsory:
          TranslationContext !== undefined
            ? TranslationContext.validation.pleaseselectslotduration
            : "Please Select Slot Duration.",
        manualSlotStartCompulsory:
          TranslationContext !== undefined
            ? TranslationContext.validation.pleaseselectstarttime
            : "Please Select Start Time.",
        manualSlotEndCompulsory:
          TranslationContext !== undefined
            ? TranslationContext.validation.pleaseselectendtime
            : "Please Select End Time.",
      });
    }
  }
  /// handle manual Delete slot
  handleManualDeleteSlot(id) {
    let manualStoreTblData = [...this.state.manualStoreTblData];
    manualStoreTblData.splice(id, 1);
    this.setState({ manualStoreTblData });
    NotificationManager.success("Slot Deleted.");
  }
  /// handle Get Slots By TemplateID
  handleGetSlotsByTemplateID(isFilterEdit) {
    let self = this;

    var slotTemplateID = 0;
    if (isFilterEdit) {
      slotTemplateID = this.state.selectedFilterSlotTemplate;
      this.setState({ filterSlotTempLoading: true });
    } else {
      slotTemplateID = this.state.selectedSlotTemplate;
      this.setState({ slotTempLoading: true });
    }
    let inputParams = {
      SlotTemplateID: slotTemplateID,
    };
    this.SettingsService.PostWithParams(
      `/Appointment/${
        this.state.isVideoCallAppointmentTab
          ? "GetVideoCallSlotsByTemplateID"
          : "GetSlotsByTemplateID"
      }`,
      inputParams
    )
      .then((response) => {
        let status = response.message;
        let data = response.responseData;
        if (status === "Success") {
          if (this.state.isVideoCallAppointmentTab) {
            data.forEach((data) => {
              data.slotOccupancy = 1;
            });
          }
          if (isFilterEdit) {
            self.setState({
              filterSlotTemplateGridData: data,
              filterSlotTempLoading: false,
            });
          } else {
            self.setState({
              SlotTemplateGridData: data,
              slotTempLoading: false,
            });
          }
        } else {
          if (isFilterEdit) {
            self.setState({
              filterSlotTemplateGridData: [],
              filterSlotTempLoading: false,
            });
          } else {
            self.setState({
              SlotTemplateGridData: [],
              slotTempLoading: false,
            });
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  handleGetStoreAgentList = () => {
    let inputParams = {
      BrandID: 0,
      StoreID: this.state.selectedStoreID,
    };
    this.SettingsService.PostWithParams(
      "/HSSetting/GetStoreAgentList",
      inputParams
    )
      .then((response) => {
        let status = response.message;
        let data = response.responseData;
        if (status === "Success") {
          this.setState({
            storeAgentList: data,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  /// handle Slot bulk upload
  handleSlotFileUpload = (file) => {
    var imageFile = file[0];
    var SlotFileName = file[0].name;
    if (!imageFile.name.match(/\.(csv)$/)) {
      alert("Only csv file allowed.");
      return false;
    } else {
      this.setState({
        SlotFileName,
        SlotFile: imageFile,
      });
      this.handleSlotBulkUpload();
    }
  };
  /// handle slot bulk upload
  handleSlotBulkUpload() {
    const TranslationContext = this.state.translateLanguage.default;
    let self = this;
    this.setState({
      bulkuploadLoading: true,
    });
    const formData = new FormData();
    formData.append("file", this.state.SlotFile);
    this.SettingsService.PostWithData(
      "/Appointment/BulkUploadSlotNew",
      formData
    )
      .then((response) => {
        var status = response.message;
        if (status === "Success") {
          NotificationManager.success(
            TranslationContext !== undefined
              ? TranslationContext.alertmessage.fileuploadedsuccessfully
              : "File uploaded successfully."
          );
          self.setState({
            SlotFile: {},
            SlotFileName: "",
            bulkuploadLoading: false,
          });
          // self.handleGetTimeslotGridData();
          self.handleGetTimeslotGridData(
            0,
            "",
            "",
            0,
            false,
            this.state.isVideoCallAppointmentTab
          );
          self.handleLoadSlotData(self.state.isVideoCallAppointmentTab);
        } else {
          self.setState({
            bulkuploadLoading: false,
          });
          NotificationManager.error(
            TranslationContext !== undefined
              ? TranslationContext.alertmessage.filenotuploaded
              : "File not uploaded."
          );
        }
      })
      .catch((error) => {
        self.setState({
          bulkuploadLoading: false,
        });
        console.log(error);
      });
  }
  ////handle slot occupancy change text in table
  handleslotOccupancyChange = (id, e) => {
    if (Number(e.target.value) <= 30) {
      this.state.SlotTemplateGridData.filter(
        (x) => x.slotID === id
      )[0].slotOccupancy = e.target.value || "";
    } else {
      this.state.SlotTemplateGridData.filter(
        (x) => x.slotID === id
      )[0].slotOccupancy = "";
    }
    this.setState({ SlotTemplateGridData: this.state.SlotTemplateGridData });
  };

  //Create Template
  handleCreateSlotOccupancyChange = (id, e) => {
    if (Number(e.target.value) <= 30) {
      this.state.automaticaSlotTblData.filter(
        (x) => x.slotID === id
      )[0].slotOccupancy = e.target.value || "";
    } else {
      this.state.automaticaSlotTblData.filter(
        (x) => x.slotID === id
      )[0].slotOccupancy = "";
    }
    this.setState({ automaticaSlotTblData: this.state.automaticaSlotTblData });
  };
  ////handle slot active inactive
  handleslotActiveInActive = (id) => {
    this.state.SlotTemplateGridData.filter(
      (x) => x.slotID === id
    )[0].isSlotEnabled = !this.state.SlotTemplateGridData.filter(
      (x) => x.slotID === id
    )[0].isSlotEnabled;
    this.setState({ SlotTemplateGridData: this.state.SlotTemplateGridData });
  };

  handleEnableDisableOnChange(i, e) {
    var name = e.target.name;
    var value = e.target.value;
    if (name === "isSlotEnabled") {
      value = e.target.checked;
    }
    let editSlotTemplateGridData = [...this.state.editSlotTemplateGridData];
    editSlotTemplateGridData[i] = {
      ...editSlotTemplateGridData[i],
      [name]: value,
    };
    this.setState({
      editSlotTemplateGridData,
    });
  }
  ////handle radio status change
  handleRadioStatusChange = (e) => {
    this.setState({ slotStatus: e.target.value });
  };
  ///handle Applicable Form Data
  handleApplicableFormData = (e) => {
    this.setState({
      applicableFromDate: e,
    });
  };
  ////handle change operational days
  handleChangeOperationalDays = (e) => {
    const TranslationContext = this.state.translateLanguage.default;
    if (e) {
      this.setState({
        operationalDays: e,
        isoperationalDay: "",
      });
    } else {
      this.setState({
        operationalDays: e,
        isoperationalDay:
          TranslationContext !== undefined
            ? TranslationContext.validation.pleaseselectoperationaldays
            : "Please Select Operational Days.",
      });
    }
  };
  ////handle cancel slot button click
  handleCancelSlot = () => {
    this.state.storeCodeData.forEach((element) => {
      element.isChecked = false;
    });
    this.setState({
      isNextClick: false,
      storeCodeData: this.state.storeCodeData,
      operationalDays: [],
      selectedSlotTemplate: 0,
      slotDaysDisplay: 0,
      applicableFromDate: new Date(),
      maxPeopleAppointment: 0,
      slotDisplayCode: 0,
      slotStatus: 1,
      SlotTemplateGridData: [],
      selectedStoreValues: "",
      shoreSelectedCount: 0,
    });
  };
  ////handle slot template change.
  handleSlotTemplateChange = (e) => {
    const TranslationContext = this.state.translateLanguage.default;
    if (e.target.value > 0) {
      this.setState({
        selectedSlotTemplate: e.target.value,
        isSlotTemplete: "",
      });
    } else {
      this.setState({
        selectedSlotTemplate: e.target.value,
        isSlotTemplete:
          TranslationContext !== undefined
            ? TranslationContext.validation.pleaseselectslottemplate
            : "Please Select Slot Template.",
      });
    }
  };
  handleEditSlotTemplateChange = (e) => {
    this.setState({
      editSlotTemplateId: e.target.value,
    });
    let self = this;
    let inputParams = {
      SlotTemplateID: e.target.value,
    };
    this.SettingsService.PostWithParams(
      `/Appointment/${
        this.state.isVideoCallAppointmentTab
          ? "GetVideoCallSlotsByTemplateID"
          : "GetSlotsByTemplateID"
      }`,
      inputParams
    )
      .then((response) => {
        let status = response.message;
        let data = response.responseData;
        if (status === "Success") {
          // if (this.state.isVideoCallAppointmentTab) {
          for (let i = 0; i < data.length; i++) {
            data[i].slotOccupancy =
              data[i].slotOccupancy === 0 ? 1 : data[i].slotOccupancy;
          }
          // }

          self.setState({ editSlotTemplateGridData: data });
        } else {
          self.setState({ editSlotTemplateGridData: [] });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  ////handle get appointment count on slot id
  handleGetAppointmentCountOnSlotID = (slotId) => {
    const TranslationContext = this.state.translateLanguage.default;
    let self = this;
    let inputParams = {
      SlotSettingID: slotId,
    };
    this.SettingsService.PostWithParams(
      "/Appointment/GetAppointmentCountOnSlotID",
      inputParams
    )
      .then((response) => {
        var message = response.message;
        if (message === "Success") {
          confirm({
            title:
              TranslationContext !== undefined
                ? TranslationContext.title.doyouwanttodeletetheseslot
                : "Do you Want to delete these slot ?",
            content:
              TranslationContext !== undefined
                ? TranslationContext.title.thisslotalreadycreatedappointment
                : "This slot already created Appointment.",
            onOk() {
              self.handleDeleteTimeSlot(slotId);
              setTimeout(() => {
                window.location.reload();
              }, 1000);
            },
            onCancel() {},
          });
        } else {
          self.handleDeleteTimeSlot(slotId);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  handleRadioOnChange = (e) => {
    this.state.AppointConfigData.isMsgWithin24Hrs =
      !this.state.AppointConfigData.isMsgWithin24Hrs;

    this.setState({
      AppointConfigData: this.state.AppointConfigData,
    });
  };

  AppoinmentConfigTextChange(e) {
    var name = e.target.name;
    var value = e.target.value;

    this.state.AppointConfigData[name] = value;
    this.setState({
      AppointConfigData: this.state.AppointConfigData,
    });
  }

  handleGetAppointmentMessageTagsData() {
    let self = this;
    this.SettingsService.Post("/Appointment/AppointmentMessageTags")
      .then((response) => {
        let status = response.message;
        let data = response.responseData;
        if (status === "Success") {
          self.setState({
            AppointMessageTagsData: data,
          });
        } else {
          self.setState({
            AppointMessageTagsData: [],
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  handlePlaceholdersOnChange(type, e) {
    if (type === "WhatsApp" && e.target.value !== "Select") {
      if (this.state.AppointConfigData.messageViaWhatsApp) {
        this.state.AppointConfigData.messageViaWhatsApp =
          this.state.AppointConfigData.messageViaWhatsApp +
          " " +
          e.target.value;
      } else {
        this.state.AppointConfigData.messageViaWhatsApp =
          this.state.AppointConfigData.messageViaWhatsApp ||
          "" + " " + e.target.value;
      }
    } else if (type === "SMS" && e.target.value !== "Select") {
      if (this.state.AppointConfigData.messageViaSMS) {
        this.state.AppointConfigData.messageViaSMS =
          this.state.AppointConfigData.messageViaSMS + " " + e.target.value;
      } else {
        this.state.AppointConfigData.messageViaSMS =
          this.state.AppointConfigData.messageViaSMS ||
          "" + " " + e.target.value;
      }
    } else if (type === "RemindSMS" && e.target.select !== "Select") {
      if (this.state.AppointConfigData.reminderMessageViaSMS) {
        this.state.AppointConfigData.reminderMessageViaSMS =
          this.state.AppointConfigData.reminderMessageViaSMS +
          " " +
          e.target.value;
      } else {
        this.state.AppointConfigData.reminderMessageViaSMS =
          this.state.AppointConfigData.reminderMessageViaSMS ||
          "" + " " + e.target.value;
      }
    }

    this.setState({
      AppointConfigData: this.state.AppointConfigData,
    });
  }
  //handle slot table modal close
  handleSlotTableModalClose = (id) => {
    document.getElementById(id).click();
  };
  handleFilterChange = (e) => {
    this.setState({ filterOptionSelected: e });
    if (e.value === 1) {
      this.setState({
        // chooseStoreModal: true,
        selectedSlotTemplate: 0,
        operationalDays: [],
        filterOperationalDays: {},
        filterSlotTemplate: 0,
      });
      this.handleFilterOperationalDaysModalClose();
    } else {
      this.state.storeCodeData.forEach((element) => {
        element.isChecked = false;
      });
      this.setState({
        storeCodeData: this.state.storeCodeData,
        showApplyStoreData: false,
        selectedStoreValues: "",
        shoreSelectedCount: 0,
        filterSelectedStoreId: "",
        filterSelectedStoreCode: "",
      });
      if (e.value === 2) {
        this.setState({
          selectedSlotTemplate: 0,
          filterSelectedStoreId: "",
          filterSelectedStoreCode: "",
          filterSlotTemplate: 0,
        });
      } else {
        this.setState({
          operationalDays: [],
          filterSelectedStoreId: "",
          filterSelectedStoreCode: "",
        });
        this.handleFilterOperationalDaysModalClose();
      }
    }
  };
  handleFilterChooseStoreChange = (item, e) => {
    this.state.onLoadSlotStores.filter(
      (x) => x.storeID === item.storeID
    )[0].isChecked = e.target.checked;
    this.setState({ onLoadSlotStores: this.state.onLoadSlotStores });
  };
  /// handle Filter store search
  handleFilterStoreSearch = (e) => {
    e.preventDefault();
    var tempstore = this.state.tempOnLoadSlotStores;
    var Value = this.state.filterSlotStoreSearch;
    tempstore.forEach((element) => {
      element.isChecked = false;
    });
    var FinalstoreList = tempstore.filter((item) =>
      item.storeCode.toLowerCase().includes(Value.toLowerCase())
    );

    if (FinalstoreList.length > 0) {
      this.setState({ onLoadSlotStores: FinalstoreList });
    } else {
      this.setState({
        onLoadSlotStores: [],
      });
    }
  };
  handleFilterSelectAllStore = (isSelectAll, e) => {
    this.state.onLoadSlotStores.forEach((element) => {
      element.isChecked = isSelectAll;
    });
    if (isSelectAll) {
      this.setState({ onLoadSlotStores: this.state.onLoadSlotStores });
    } else {
      this.setState({
        onLoadSlotStores: this.state.onLoadSlotStores,
        filterSelectedStoreCode: "",
        filterSelectedStoreId: "",
      });
    }
  };
  /// handle filter store data
  handleFilterStoreData(filterData) {
    var tempstore = this.state.tempOnLoadSlotStores;
    tempstore.forEach((element) => {
      element.isChecked = false;
    });
    var finalStore = tempstore.filter((item) =>
      item.storeCode.startsWith(filterData)
    );
    this.setState({
      onLoadSlotStores: finalStore,
    });
  }
  handleFilterApplySelectedStore() {
    var filterSelectedStoreCode = "";
    var filterSelectedStoreId = "";
    this.state.onLoadSlotStores.forEach((element) => {
      if (element.isChecked) {
        filterSelectedStoreId += element.storeID + ",";
        filterSelectedStoreCode += element.storeCode + ",";
      }
    });
    this.setState({
      filterSelectedStoreId,
      filterSelectedStoreCode,
      filterChooseStoreModal: false,
    });
  }
  handleFilterOperationalDaysModalOpen = () => {
    if (this.state.filterOperationalDays) {
      var oprId = this.state.filterOperationalDays.dayIDs.split(",");
      var oprName = this.state.filterOperationalDays.dayNames.split(",");
      var filterSelectedOperationalDays = [];
      for (let i = 0; i < oprId.length; i++) {
        var objData = {};
        objData.dayID = Number(oprId[i]);
        objData.dayName = oprName[i];
        filterSelectedOperationalDays.push(objData);
      }
    }
    this.setState({
      filterOperationalDaysModal: true,
      filterSelectedOperationalDays,
    });
  };
  handleFilterOperationalDaysModalClose = () => {
    this.setState({ filterOperationalDaysModal: false });
  };
  handleFilterChangeOperationalDays = (e) => {
    this.setState({ filterOperationalDays: e });
    // if (e) {
    //   setTimeout(() => {
    //     this.handleFilterOperationalDaysModalOpen();
    //   }, 300);
    // }
  };
  handleEditAllData = () => {
    if (this.state.filterOptionSelected.value === 2) {
      this.handleFilterOperationalDaysModalOpen();
    }
    if (this.state.filterOptionSelected.value === 3) {
      this.handleFilterTampleteModalModalOpen();
      this.handleGetSlotsByTemplateID(true);
    }
  };
  //handle filter delete all slot data
  handleFilterDeleteALLSlotData = () => {
    let self = this;
    var slotIDs = "";
    this.state.TimeSlotGridData.forEach((element) => {
      slotIDs += element.slotSettingID + ",";
    });
    let inputParams = {
      SlotIDs: slotIDs,
    };
    this.SettingsService.PostWithParams(
      "/Appointment/BulkDeleteTimeSlotMaster",
      inputParams
    )
      .then((response) => {
        var message = response.message;
        var responseData = response.responseData;
        if (message === "Success" && responseData) {
          // self.handleGetTimeslotGridData(0, "", "", 0);
          self.handleGetTimeslotGridData(
            0,
            "",
            "",
            0,
            false,
            this.state.isVideoCallAppointmentTab
          );

          self.setState({
            filterOptionSelected: {},
            filterOperationalDays: {},
            filterSelectedStoreCode: "",
            filterSlotTemplate: 0,
          });
          self.handleLoadSlotData(self.state.isVideoCallAppointmentTab);
          NotificationManager.success("Slots Delete Successfully.");
        } else {
          NotificationManager.error("Slots Not Delete Successfully.");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  //handle filter delete all slot data
  handleFilterEditALLSlotData = () => {
    let self = this;
    var slotIDs = "";
    var slotTemplateID = 0;
    var storeOpdays = "";
    var templateSlots = [];
    this.state.TimeSlotGridData.forEach((element) => {
      slotIDs += element.slotSettingID + ",";
    });
    if (this.state.filterOptionSelected.value === 2) {
      this.state.filterSelectedOperationalDays.sort(
        (a, b) => a.dayID - b.dayID
      );

      this.state.filterSelectedOperationalDays.forEach((element) => {
        storeOpdays += element.dayID + ",";
      });
      this.setState({ filterEditLoader: true });
    } else {
      var checkSlotOccupancy = [];
      if (this.state.filterSlotTemplateGridData.length > 0) {
        checkSlotOccupancy = this.state.filterSlotTemplateGridData.filter(
          (x) => x.slotOccupancy == 0
        );
      }
      if (checkSlotOccupancy.length > 0) {
        NotificationManager.error("Please Enter Slot Occupancy.");
        return false;
      }
      slotTemplateID = this.state.selectedFilterSlotTemplate;
      templateSlots = this.state.filterSlotTemplateGridData;
      this.setState({ filterEditLoader: true });
    }
    let inputData = {
      SlotSettingIds: slotIDs,
      StoreOpdays: storeOpdays,
      SlotTemplateID: slotTemplateID,
      TemplateSlots: templateSlots,
    };
    this.SettingsService.PostWithData("/Appointment/BulkUpdateSlots", inputData)
      .then((response) => {
        var message = response.message;
        var responseData = response.responseData;
        if (message === "Success" && responseData) {
          // self.handleGetTimeslotGridData(0, "", "", 0);
          self.handleGetTimeslotGridData(
            0,
            "",
            "",
            0,
            false,
            this.state.isVideoCallAppointmentTab
          );

          NotificationManager.success("Slots Update Successfully.");
          self.setState({
            filterOperationalDaysModal: false,
            filterOptionSelected: {},
            filterOperationalDays: {},
            filterTampleteModal: false,
            filterEditLoader: false,
            filterSlotTemplate: 0,
          });
          self.handleLoadSlotData(self.state.isVideoCallAppointmentTab);
        } else {
          NotificationManager.error("Slots Not Update Successfully.");
          self.setState({ filterEditLoader: false });
        }
      })
      .catch((error) => {
        self.setState({ filterEditLoader: false });
        console.log(error);
      });
  };
  handleSelectFilterChangeOperationalDays = (e) => {
    this.setState({ filterSelectedOperationalDays: e });
  };
  handleFilterTampleteModalModalOpen = () => {
    this.setState({
      filterTampleteModal: true,
    });
  };
  handleFilterTampleteModalModalClose = () => {
    this.setState({
      filterTampleteModal: false,
    });
  };
  handleFilterSlotTemplateChange = (e) => {
    this.setState({
      filterSlotTemplate: e.target.value,
      selectedFilterSlotTemplate: e.target.value,
    });
  };
  handleSelectedFilterSlotTemplate = (e) => {
    this.setState({
      selectedFilterSlotTemplate: e.target.value,
    });
    setTimeout(() => {
      this.handleGetSlotsByTemplateID(true);
    }, 1000);
  };
  handleFilterEnableDisableOnChange(i, e) {
    var name = e.target.name;
    var value = e.target.value;
    if (name === "isSlotEnabled") {
      value = e.target.checked;
    }
    let filterSlotTemplateGridData = [...this.state.filterSlotTemplateGridData];
    filterSlotTemplateGridData[i] = {
      ...filterSlotTemplateGridData[i],
      [name]: value,
    };
    this.setState({
      filterSlotTemplateGridData,
    });
  }
  handleSelectEditChangeOperationalDays = (e) => {
    this.setState({ operationalDaysList: e });
  };

  handleFilterClear = () => {
    this.state.onLoadSlotStores.forEach((element) => {
      element.isChecked = false;
    });
    this.setState({
      onLoadSlotStores: this.state.onLoadSlotStores,
      filterOptionSelected: {},
      filterOperationalDays: {},
      filterSelectedStoreCode: "",
      filterSlotTemplate: 0,
      isFilterSearch: false,
    });
    // this.handleGetTimeslotGridData(0, "", "", 0);
    this.handleGetTimeslotGridData(
      0,
      "",
      "",
      0,
      false,
      this.state.isVideoCallAppointmentTab
    );
  };

  //changing the isvideocalltab state

  handleIsVideoAppointmentCallTab = (isVideoCallAppointment) => {
    if (!isVideoCallAppointment) {
      this.state.storeCodeData.forEach((element) => {
        element.isChecked = false;
      });
      this.state.onLoadSlotStores.forEach((element) => {
        element.isChecked = false;
      });
      this.handleGetTimeslotGridData(
        0,
        "",
        "",
        0,
        false,
        isVideoCallAppointment
      );
      this.handleLoadSlotData(true);
      this.setState({
        onLoadSlotStores: this.state.onLoadSlotStores,
        filterOptionSelected: {},
        filterOperationalDays: {},
        filterSelectedStoreCode: "",
        filterSlotTemplate: 0,
        isFilterSearch: false,
        showApplyStoreData: false,
        SlotDisplayCode: 0,
        SlotTemplateGridData: [],
        slotStatus: 1,
        applicableFromDate: new Date(),
        maxPeopleAppointment: 0,
        slotDaysDisplay: 0,
        selectedSlotTemplate: 0,
        operationalDays: [],
        storeCodeData: this.state.storeCodeData,
        isNextClick: false,
        shoreSelectedCount: 0,
        selectedStoreValues: "",
        isSlotSaveClick: false,
        videoSlotTemplateData: [],
      });
    } else {
      this.handleLoadSlotData(false);
      this.handleGetVideoSlotTemplate();
      this.handleGetTimeslotGridData(
        0,
        "",
        "",
        0,
        false,
        isVideoCallAppointment
      );
      this.state.storeCodeData.forEach((element) => {
        element.isChecked = false;
      });
      this.state.onLoadSlotStores.forEach((element) => {
        element.isChecked = false;
      });
      this.setState({
        onLoadSlotStores: this.state.onLoadSlotStores,
        filterOptionSelected: {},
        filterOperationalDays: {},
        filterSelectedStoreCode: "",
        filterSlotTemplate: 0,
        isFilterSearch: false,
        showApplyStoreData: false,
        SlotDisplayCode: 0,
        SlotTemplateGridData: [],
        slotStatus: 1,
        applicableFromDate: new Date(),
        maxPeopleAppointment: 0,
        slotDaysDisplay: 0,
        selectedSlotTemplate: 0,
        operationalDays: [],
        storeCodeData: this.state.storeCodeData,
        isNextClick: false,
        shoreSelectedCount: 0,
        selectedStoreValues: "",
        isSlotSaveClick: false,
      });
    }
    this.setState({
      isVideoCallAppointmentTab: isVideoCallAppointment,
    });
  };

  //handle get web bot select option data
  handleGetWebBotOption = () => {
    let self = this;
    this.SettingsService.Post("/WebBot/GetWebBotOption")
      .then((response) => {
        var message = response.message;
        var responseData = response.responseData;
        if (message === "Success" && responseData) {
          self.setState({ WebBotData: responseData });
        } else {
          self.setState({ WebBotData: [] });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  handleHSMOptionChange = (optionId) => {
    this.state.WebBotData.filter((x) => x.id === optionId)[0].isActive =
      !this.state.WebBotData.filter((x) => x.id === optionId)[0].isActive;
    this.setState({ WebBotData: this.state.WebBotData });
  };
  handleHSMUpdate = () => {
    var activeOptions = "";
    var inActiveOptions = "";

    this.state.WebBotData.forEach((element) => {
      if (element.isActive) {
        activeOptions += element.id + ",";
      } else {
        inActiveOptions += element.id + ",";
      }
    });
    this.setState({ isSubmit: true });
    let self = this;
    const TranslationContext = this.state.translateLanguage.default;
    let inputParams = {
      ActiveOptions: activeOptions,
      InActiveOptions: inActiveOptions,
    };
    this.SettingsService.PostWithParams("/WebBot/UpdateHSMOptions", inputParams)
      .then((response) => {
        var message = response.message;
        var responseData = response.responseData;
        self.setState({ isSubmit: false });
        if (message === "Success" && responseData) {
          self.handleGetWebBotOption();
          NotificationManager.success(
            TranslationContext !== undefined
              ? TranslationContext.alertmessage.recordupdatedsuccessfully
              : "Record Updated Successfully."
          );
        } else {
          NotificationManager.success(
            TranslationContext !== undefined
              ? TranslationContext.alertmessage.recordupdatedsuccessfully
              : "Record Not Updated."
          );
        }
      })
      .catch((error) => {
        self.setState({ isSubmit: false });
        console.log(error);
      });
  };
  /// handle Campaign upload
  handleCampaignFileUpload = (file) => {
    var imageFile = file[0];
    var campaignUploadName = file[0].name;
    if (!imageFile.name.match(/\.(csv)$/)) {
      alert("Only csv file allowed.");
      return false;
    } else {
      this.setState({
        campaignUploadName,
        campaignUploadFile: imageFile,
      });
      // this.handleSlotBulkUpload();
    }
  };

  handleDefaultStaffList() {
    let self = this;
    this.SettingsService.Post("/CustomerChat/GetAgentList")
      .then((response) => {
        var message = response.message;
        var staffData = response.responseData;
        if (message === "Success") {
          self.setState({ defaultStaffList: staffData });
        } else {
          self.setState({ defaultStaffList: [] });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  handleGetVideoAppointmentConfiguration() {
    let self = this;
    this.SettingsService.Post("/StoreCampaign/GetVideoAppointmentConfiguration")
      .then((response) => {
        var message = response.message;
        var videoCallSlotSetttngData = response.responseData;
        if (message === "Success") {
          self.setState({ videoCallSlotSetttngData });
        } else {
          self.setState({ videoCallSlotSetttngData: {} });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  handleUpdateVideoAppointmentConfiguration = () => {
    let inputData = this.state.videoCallSlotSetttngData;
    this.setState({
      isCreateTampleteLoading: true,
    });
    this.SettingsService.PostWithData(
      "/StoreCampaign/UpdateVideoAppointmentConfiguration",
      inputData
    )
      .then((response) => {
        var message = response.message;
        this.setState({
          isCreateTampleteLoading: false,
        });
        if (message === "Success") {
          NotificationManager.success("Record Updated Successfully.");
        } else {
          NotificationManager.error("Record Not Updated.");
        }
      })
      .catch((error) => {
        this.setState({
          isCreateTampleteLoading: false,
        });
        console.log(error);
      });
  };
  handleDeleteVideoTimeSlotMaster = (id) => {
    this.setState({
      isCreateTampleteLoading: true,
    });
    let inputParams = {
      SlotID: id,
    };
    this.SettingsService.PostWithParams(
      "/Appointment/DeleteVideoTimeSlotMaster",
      inputParams
    )
      .then((response) => {
        var message = response.message;
        if (message === "Success") {
          NotificationManager.success("Record Deleted Successfully.");
          this.handleGetTimeslotGridData(
            0,
            "",
            "",
            0,
            false,
            this.state.isVideoCallAppointmentTab
          );
        } else {
          NotificationManager.error("Record Not Deleted.");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  incrementSloOccupancy = (index) => {
    this.state.automaticaSlotTblData[index].slotOccupancy =
      Number(this.state.automaticaSlotTblData[index].slotOccupancy) + 1;
    this.setState({
      automaticaSlotTblData: this.state.automaticaSlotTblData,
    });
  };
  decrementSloOccupancy = (index) => {
    this.state.automaticaSlotTblData[index].slotOccupancy =
      Number(this.state.automaticaSlotTblData[index].slotOccupancy) > 1
        ? Number(this.state.automaticaSlotTblData[index].slotOccupancy) - 1
        : 1;
    this.setState({
      automaticaSlotTblData: this.state.automaticaSlotTblData,
    });
  };

  handleStaffNameChange = (rowData, e) => {
    let tempData = this.state.SlotTemplateGridData.filter(
      (x) =>
        x.slotEndTime === rowData.slotEndTime &&
        x.slotStartTime === rowData.slotStartTime &&
        x.storeStaffNameID == Number(e.target.value)
    );
    if (tempData.length == 0) {
      this.state.SlotTemplateGridData.filter(
        (x) => x.slotID === rowData.slotID
      )[0].storeStaffNameID = e.target.value;
      this.setState({
        SlotTemplateGridData: this.state.SlotTemplateGridData,
      });
    } else {
      NotificationManager.error(
        "Already Store Staff Name Assign to same Time Slot."
      );
    }
  };
  handleEditStaffNameChange = (rowData, e) => {
    let tempData = this.state.editSlotTemplateGridData.filter(
      (x) =>
        x.slotEndTime === rowData.slotEndTime &&
        x.slotStartTime === rowData.slotStartTime &&
        x.storeStaffNameID == Number(e.target.value)
    );
    if (tempData.length == 0) {
      this.state.editSlotTemplateGridData.filter(
        (x) => x.slotID === rowData.slotID
      )[0].storeStaffNameID = e.target.value;
      this.setState({
        editSlotTemplateGridData: this.state.editSlotTemplateGridData,
      });
    } else {
      NotificationManager.error(
        "Already Store Staff Name Assign to same Time Slot."
      );
    }
  };
  handleRemoveVideoSlot = (arrIndex) => {
    let filteredArray = this.state.automaticaSlotTblData.filter(
      (data, index) => {
        return index !== arrIndex;
      }
    );
    this.setState({
      automaticaSlotTblData: filteredArray,
    });
  };

  handleRemoveEditVideoSlot = (arrIndex) => {
    let filteredArray = this.state.editSlotTemplateGridData.filter(
      (data, index) => {
        return index !== arrIndex;
      }
    );
    this.setState({
      editSlotTemplateGridData: filteredArray,
    });
  };
  handleNotificationToChange = (e) => {
    this.setState({
      notificationToAgent: e,
    });
  };
  render() {
    const TranslationContext = this.state.translateLanguage.default;
    var manualSlotData = this.state.manualSlotTblData;
    const { Option } = SelectAntd;
    var excludeTimes = [];
    for (var i = 0; i < manualSlotData.length; i++) {
      var startTime = new Date(manualSlotData[i].slotStartTime);
      var endTime = new Date(manualSlotData[i].slotEndTime);
      for (var slotdate = startTime; slotdate < endTime; ) {
        var hourGet = slotdate.getHours();
        var minuteGet = slotdate.getMinutes();

        var date = new Date();
        date.setMinutes(minuteGet);
        date.setHours(hourGet);
        excludeTimes.push(date);

        slotdate = new Date(slotdate.setMinutes(slotdate.getMinutes() + 15));
      }
    }

    const CustomInput = (props) => {
      return (
        <input
          className="form-control"
          placeholder={
            TranslationContext !== undefined
              ? TranslationContext.option.selecttiming
              : "Select Timing"
          }
          onClick={props.onClick}
          value={props.value}
          type="text"
          readOnly={true}
        />
      );
    };

    return (
      <Fragment>
        <div className="container-fluid setting-title setting-breadcrumb">
          <Link to="/store/settings" className="header-path">
            {TranslationContext !== undefined
              ? TranslationContext.link.setting
              : "Settings"}
          </Link>
          <span>&gt;</span>
          <Link
            to={{
              pathname: "/store/settings",
              tabName: "store-tab",
            }}
            className="header-path"
          >
            {TranslationContext !== undefined
              ? TranslationContext.link.store
              : "Store"}
          </Link>
          <span>&gt;</span>
          <Link to={Demo.BLANK_LINK} className="active header-path">
            {TranslationContext !== undefined
              ? TranslationContext.link.modules
              : "Modules"}
          </Link>
        </div>

        <div className="Store-paddmodule storeModule">
          <div className="module-tabs">
            <section>
              <div>
                <ul className="nav nav-tabs" role="tablist">
                  <li className="nav-item">
                    <a
                      className="nav-link active"
                      data-toggle="tab"
                      href="#Module-Claim-Tab"
                      role="tab"
                      aria-controls="Module-Claim-Tab"
                      aria-selected="true"
                    >
                      {TranslationContext !== undefined
                        ? TranslationContext.a.claim
                        : "Claim"}
                    </a>
                  </li>

                  <li
                    className={
                      config.isHomeShope ? "nav-item" : "nav-item displayNn"
                    }
                  >
                    <a
                      className="nav-link"
                      data-toggle="tab"
                      href="#Module-CampaignChannel-Tab"
                      role="tab"
                      aria-controls="Module-CampaignChannel-Tab"
                      aria-selected="false"
                    >
                      {TranslationContext !== undefined
                        ? TranslationContext.a.campaignchannel
                        : "Campaign Channel"}
                    </a>
                  </li>
                  <li
                    className={
                      config.isHomeShope ? "nav-item" : "nav-item displayNn"
                    }
                  >
                    <a
                      className="nav-link"
                      data-toggle="tab"
                      href="#Module-AppointmentConfig-Tab"
                      role="tab"
                      aria-controls="Module-AppointmentConfig-Tab"
                      aria-selected="false"
                    >
                      {TranslationContext !== undefined
                        ? TranslationContext.a.appointmentconfiguration
                        : "Appointment Configuration"}
                    </a>
                  </li>

                  <li
                    className={
                      config.isHomeShope ? "nav-item" : "nav-item displayNn"
                    }
                  >
                    <a
                      className="nav-link"
                      data-toggle="tab"
                      href="#Module-BroadcastConfig-Tab"
                      role="tab"
                      aria-controls="Module-BroadcastConfig-Tab"
                      aria-selected="false"
                    >
                      {TranslationContext !== undefined
                        ? TranslationContext.a.broadcastconfiguration
                        : "Broadcast Configuration"}
                    </a>
                  </li>
                  <li
                    className={
                      config.isHomeShope ? "nav-item" : "nav-item displayNn"
                    }
                  >
                    <a
                      className="nav-link"
                      data-toggle="tab"
                      href="#Module-SlotSetting-Tab"
                      role="tab"
                      aria-controls="Module-SlotSetting-Tab"
                      aria-selected="false"
                      onClick={() =>
                        this.handleIsVideoAppointmentCallTab(false)
                      }
                    >
                      {TranslationContext !== undefined
                        ? TranslationContext.a.slotsettings
                        : "Slot Settings"}
                    </a>
                  </li>
                  <li
                    className={
                      config.isHomeShope ? "nav-item" : "nav-item displayNn"
                    }
                  >
                    <a
                      className="nav-link"
                      data-toggle="tab"
                      href="#Module-LanguageSetting-Tab"
                      role="tab"
                      aria-controls="Module-LanguageSetting-Tab"
                      aria-selected="false"
                    >
                      {TranslationContext !== undefined
                        ? TranslationContext.a.languagesettings
                        : "Language Settings"}
                    </a>
                  </li>
                  <li
                    className={
                      config.isHomeShope ? "nav-item" : "nav-item displayNn"
                    }
                  >
                    <a
                      onClick={this.handleGetWebBotOption.bind(this)}
                      className="nav-link"
                      data-toggle="tab"
                      href="#HSM-Setting"
                      role="tab"
                      aria-controls="HSM-Setting"
                      aria-selected="false"
                    >
                      {TranslationContext !== undefined
                        ? TranslationContext.tab.hsmsettings
                        : "HSM Settings"}
                    </a>
                  </li>
                  <li
                    className={
                      config.isHomeShope ? "nav-item" : "nav-item displayNn"
                    }
                  >
                    <a
                      onClick={() => this.handleIsVideoAppointmentCallTab(true)}
                      className="nav-link"
                      data-toggle="tab"
                      href="#Module-SlotSetting-Tab"
                      role="tab"
                      aria-controls="Videocall-Appointment"

                      // aria-selected="false"
                    >
                      {TranslationContext !== undefined
                        ? TranslationContext.tab.videocallappointment
                        : "Video Call Appointment"}
                    </a>
                  </li>
                  <li
                    className={
                      config.isHomeShope ? "nav-item" : "nav-item displayNn"
                    }
                    onClick={() => {
                      this.handleGetVideoAppointmentConfiguration();
                    }}
                  >
                    <a
                      className="nav-link"
                      data-toggle="tab"
                      href="#Videocall-Slot-Settings"
                      role="tab"
                      aria-controls="Videocall-Slot-Settings"
                      aria-selected="false"
                    >
                      {TranslationContext !== undefined
                        ? TranslationContext.tab.videocallslotsettings
                        : "Video Call Slot Settings"}
                    </a>
                  </li>
                </ul>
              </div>
              <div className="tab-content p-0">
                <div
                  className="tab-pane fade show active"
                  id="Module-Claim-Tab"
                  role="tabpanel"
                  aria-labelledby="Module-Claim-Tab"
                >
                  <div style={{ height: "100vh" }} className="chatallowedStore">
                    <div className="row">
                      <div className="col-md-4 chatallowed">
                        <label className="claimtab-lbl">
                          {TranslationContext !== undefined
                            ? TranslationContext.label.attachmentsettings
                            : "Attachment Settings"}
                        </label>
                        <label className="claimTab-DDl">
                          {TranslationContext !== undefined
                            ? TranslationContext.label.maximumattachmentsize
                            : "Maximum Attachment Size"}
                        </label>
                        <select
                          name="selectedMaxAttachSize"
                          value={this.state.selectedMaxAttachSize}
                          onChange={this.setClaimTabData}
                        >
                          <option value={0}>
                            {TranslationContext !== undefined
                              ? TranslationContext.option.selectsize
                              : "Select Size"}
                          </option>
                          {this.state.maxAttachSize !== null &&
                            this.state.maxAttachSize.map((item, i) => (
                              <option key={i} value={item.numb}>
                                {item.numbMB}
                              </option>
                            ))}
                        </select>
                        {parseInt(this.state.selectedMaxAttachSize) == 0 && (
                          <p style={{ color: "red", marginBottom: "0px" }}>
                            {this.state.maxAttachSizeCompulsion}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="row claim-mgn">
                      <div className="col-md-3 chatallowed">
                        <label className="claimTab-DDl">
                          {TranslationContext !== undefined
                            ? TranslationContext.label.fileformat
                            : "File Format"}
                        </label>
                        <select
                          name="selectedFileFormat"
                          value={this.state.selectedFileFormat}
                          onChange={this.setClaimTabData}
                        >
                          <option value={0}>
                            {TranslationContext !== undefined
                              ? TranslationContext.option.selectfileformat
                              : "Select File Format"}
                          </option>
                          {this.state.fileFormat !== null &&
                            this.state.fileFormat.map((item, i) => (
                              <option key={i} value={item.formatID}>
                                {item.fileFormaName}
                              </option>
                            ))}
                        </select>
                        {parseInt(this.state.selectedFileFormat) == 0 && (
                          <p style={{ color: "red", marginBottom: "0px" }}>
                            {this.state.fileFormatCompulsion}
                          </p>
                        )}
                        <div className="btn-store mt-4">
                          <button
                            className="Schedulenext1"
                            onClick={this.handleAttachmentSave.bind(this)}
                            disabled={this.state.claimTabLoading}
                          >
                            {this.state.claimTabLoading ? (
                              <FontAwesomeIcon
                                className="circular-loader"
                                icon={faCircleNotch}
                                spin
                              />
                            ) : (
                              ""
                            )}
                            {TranslationContext !== undefined
                              ? TranslationContext.button.save
                              : "SAVE"}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="Module-CampaignChannel-Tab"
                  role="tabpanel"
                  aria-labelledby="Module-CampaignChannel-Tab"
                >
                  <div className="backNone">
                    <div className="row">
                      <div className="col-md-12">
                        <div style={{ background: "white" }}>
                          <div className="row">
                            <div className="col-md-5 m-auto">
                              <div className="right-sect-div">
                                <h3>
                                  {TranslationContext !== undefined
                                    ? TranslationContext.h3.campaignchannel
                                    : "CAMPAIGN CHANNEL"}
                                </h3>
                                <div className="module-switch-cntr">
                                  <div className="module-switch">
                                    <div className="switch switch-primary">
                                      <label className="storeRole-name-text m-0">
                                        {TranslationContext !== undefined
                                          ? TranslationContext.label.sms
                                          : "SMS"}
                                      </label>
                                      <input
                                        type="checkbox"
                                        id="ckSmsCamp1"
                                        name="allModules"
                                        checked={
                                          this.state.campaignChannelData.smsFlag
                                        }
                                        onChange={this.CampChannelSmsFlageOnchange.bind(
                                          this
                                        )}
                                      />
                                      <label
                                        htmlFor="ckSmsCamp1"
                                        className="cr cr-float-auto"
                                      ></label>
                                    </div>
                                  </div>
                                  {this.state.campaignChannelData.smsFlag ? (
                                    <div className="cusinput">
                                      <input
                                        type="text"
                                        name="providerName"
                                        autoComplete="off"
                                        placeholder="Provider name"
                                        maxLength={15}
                                        value={
                                          this.state.campaignChannelData
                                            .providerName
                                        }
                                        onChange={this.CampCannelOnChange.bind(
                                          this
                                        )}
                                      />
                                      {this.state.campaignChannelData
                                        .providerName === "" && (
                                        <p
                                          style={{
                                            color: "red",
                                            marginBottom: "0px",
                                          }}
                                        >
                                          {this.state.campProviderValidation}
                                        </p>
                                      )}
                                    </div>
                                  ) : null}

                                  <div className="module-switch">
                                    <div className="switch switch-primary">
                                      <label className="storeRole-name-text m-0">
                                        {TranslationContext !== undefined
                                          ? TranslationContext.label.whatsapp
                                          : "Whatsapp"}
                                      </label>
                                      <input
                                        type="checkbox"
                                        id="ckWhatCamp2"
                                        name="allModules"
                                        checked={
                                          this.state.campaignChannelData
                                            .messengerFlag
                                        }
                                        onChange={this.CampChannelSmsFlageOnchange.bind(
                                          this
                                        )}
                                      />
                                      <label
                                        htmlFor="ckWhatCamp2"
                                        className="cr cr-float-auto"
                                      ></label>
                                    </div>
                                  </div>
                                  <div className="module-switch">
                                    <div className="switch switch-primary">
                                      <label className="storeRole-name-text m-0">
                                        {TranslationContext !== undefined
                                          ? TranslationContext.label.chatbot
                                          : "Chatbot"}
                                      </label>
                                      <input
                                        type="checkbox"
                                        id="ckChatCamp3"
                                        name="allModules"
                                        checked={
                                          this.state.campaignChannelData.botFlag
                                        }
                                        onChange={this.CampChannelSmsFlageOnchange.bind(
                                          this
                                        )}
                                      />
                                      <label
                                        htmlFor="ckChatCamp3"
                                        className="cr cr-float-auto"
                                      ></label>
                                    </div>
                                  </div>
                                  <div className="module-switch">
                                    <div className="switch switch-primary">
                                      <label className="storeRole-name-text m-0">
                                        {TranslationContext !== undefined
                                          ? TranslationContext.label.email
                                          : "Email"}
                                      </label>
                                      <input
                                        type="checkbox"
                                        id="ckEmailCamp4"
                                        name="allModules"
                                        checked={
                                          this.state.campaignChannelData
                                            .emailFlag
                                        }
                                        onChange={this.CampChannelSmsFlageOnchange.bind(
                                          this
                                        )}
                                      />
                                      <label
                                        htmlFor="ckEmailCamp4"
                                        className="cr cr-float-auto"
                                      ></label>
                                    </div>
                                  </div>
                                  <div className="module-switch">
                                    <div className="switch switch-primary">
                                      <label className="storeRole-name-text m-0">
                                        {TranslationContext !== undefined
                                          ? TranslationContext
                                              .ticketingDashboard
                                              .campaignautoassigned
                                          : "Campaign Auto Assigned"}
                                      </label>
                                      <input
                                        type="checkbox"
                                        id="ckCmpAutoAssignedCamp5"
                                        name="allModules"
                                        checked={
                                          this.state.campaignChannelData
                                            .campaignAutoAssigned
                                        }
                                        onChange={this.CampChannelSmsFlageOnchange.bind(
                                          this
                                        )}
                                      />
                                      <label
                                        htmlFor="ckCmpAutoAssignedCamp5"
                                        className="cr cr-float-auto"
                                      ></label>
                                    </div>
                                  </div>
                                  <div className="module-switch">
                                    <div className="switch switch-primary">
                                      <label className="storeRole-name-text m-0">
                                        {TranslationContext !== undefined
                                          ? TranslationContext
                                              .ticketingDashboard
                                              .campaignraiseticket
                                          : "Campaign Raise Ticket"}
                                      </label>
                                      <input
                                        type="checkbox"
                                        id="ckCmpAutoAssignedCamp6"
                                        name="allModules"
                                        checked={
                                          this.state.campaignChannelData
                                            .raiseTicketFlag
                                        }
                                        onChange={this.CampChannelSmsFlageOnchange.bind(
                                          this
                                        )}
                                      />
                                      <label
                                        htmlFor="ckCmpAutoAssignedCamp6"
                                        className="cr cr-float-auto"
                                      ></label>
                                    </div>
                                  </div>
                                  <div className="module-switch">
                                    <div className="switch switch-primary">
                                      <label className="storeRole-name-text m-0">
                                        {TranslationContext !== undefined
                                          ? TranslationContext
                                              .ticketingDashboard
                                              .campaigncomment
                                          : "Campaign Comment"}
                                      </label>
                                      <input
                                        type="checkbox"
                                        id="ckCmpAutoAssignedCamp7"
                                        name="allModules"
                                        checked={
                                          this.state.campaignChannelData
                                            .addCommentFlag
                                        }
                                        onChange={this.CampChannelSmsFlageOnchange.bind(
                                          this
                                        )}
                                      />
                                      <label
                                        htmlFor="ckCmpAutoAssignedCamp7"
                                        className="cr cr-float-auto"
                                      ></label>
                                    </div>
                                  </div>
                                </div>
                                <table className="cmpaign-channel-table">
                                  <tr>
                                    <td>
                                      {TranslationContext !== undefined
                                        ? TranslationContext.td
                                            .maxclickallowesonanychannelcta
                                        : "Max. click allowed on any channel CTA"}
                                    </td>
                                    <td>
                                      <input
                                        type="text"
                                        name="maxClickAllowed"
                                        value={
                                          this.state.campaignChannelData
                                            .maxClickAllowed
                                        }
                                        autoComplete="off"
                                        maxLength={3}
                                        onChange={this.CampCannelOnChange.bind(
                                          this
                                        )}
                                      />
                                      {this.state.campaignChannelData
                                        .maxClickAllowed === "" && (
                                        <p
                                          style={{
                                            color: "red",
                                            marginBottom: "0px",
                                          }}
                                        >
                                          {this.state.maxClickValidation}
                                        </p>
                                      )}
                                    </td>
                                    <td>
                                      {TranslationContext !== undefined
                                        ? TranslationContext.td.clicktd
                                        : "Click"}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      {TranslationContext !== undefined
                                        ? TranslationContext.td
                                            .clickwillbeenabledafter
                                        : "Click will be enabled after"}
                                    </td>
                                    <td>
                                      <input
                                        type="text"
                                        name="enableClickAfterValue"
                                        autoComplete="off"
                                        maxLength={2}
                                        value={
                                          this.state.campaignChannelData
                                            .enableClickAfterValue
                                        }
                                        onChange={this.CampCannelOnChange.bind(
                                          this
                                        )}
                                      />
                                      {this.state.campaignChannelData
                                        .enableClickAfterValue === "" && (
                                        <p
                                          style={{
                                            color: "red",
                                            marginBottom: "0px",
                                          }}
                                        >
                                          {this.state.enabledAfterValidation}
                                        </p>
                                      )}
                                    </td>
                                    <td>
                                      <select
                                        value={
                                          this.state.campaignChannelData
                                            .enableClickAfterDuration
                                        }
                                        name="enableClickAfterDuration"
                                        onChange={this.CampCannelOnChange.bind(
                                          this
                                        )}
                                      >
                                        <option value="M">
                                          {TranslationContext !== undefined
                                            ? TranslationContext.option.min
                                            : "Min"}
                                        </option>
                                        <option value="H">
                                          {TranslationContext !== undefined
                                            ? TranslationContext.option.hr
                                            : "Hr"}
                                        </option>
                                      </select>
                                    </td>
                                  </tr>
                                </table>
                                <button
                                  className="Schedulenext1 w-100 mb-0 mt-4"
                                  type="button"
                                  disabled={this.state.isSubmit}
                                  onClick={this.handleCheckCampaignValidation.bind(
                                    this
                                  )}
                                >
                                  {this.state.isSubmit ? (
                                    <FontAwesomeIcon
                                      className="circular-loader"
                                      icon={faCircleNotch}
                                      spin
                                    />
                                  ) : null}
                                  {TranslationContext !== undefined
                                    ? TranslationContext.button.update
                                    : "UPDATE"}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="Module-AppointmentConfig-Tab"
                  role="tabpanel"
                  aria-labelledby="Module-AppointmentConfig-Tab"
                >
                  <div className="backNone">
                    <div className="row">
                      <div className="col-md-12">
                        <div style={{ background: "white" }}>
                          <div className="row">
                            <div className="col-md-5 m-auto">
                              <div className="right-sect-div">
                                <h3>
                                  {TranslationContext !== undefined
                                    ? TranslationContext.h3
                                        .appointmentconfiguration
                                    : "APPOINTMENT CONFIGURATION"}
                                </h3>
                                <div className="module-switch-cntr">
                                  <div className="module-switch ord-m-t20">
                                    <div className="switch switch-primary">
                                      <label className="storeRole-name-text m-0 ordSttd-store">
                                        {TranslationContext !== undefined
                                          ? TranslationContext.label
                                              .appointmentcancellation
                                          : "Appointment Cancellation"}
                                      </label>
                                    </div>
                                  </div>
                                  <div className="module-switch ord-m-t20">
                                    <div className="switch switch-primary">
                                      <label className="storeRole-name-text m-0 ordSttd-store">
                                        {TranslationContext !== undefined
                                          ? TranslationContext.label
                                              .communicationviawhatsapp
                                          : "Communication via Whatsapp"}
                                      </label>
                                      <input
                                        type="checkbox"
                                        id="ckAppconfigWhatsApp"
                                        name="allModules"
                                        checked={
                                          this.state.AppointConfigData
                                            .viaWhatsApp
                                        }
                                        onChange={this.AppoinmentConfigFlageChange.bind(
                                          this
                                        )}
                                      />
                                      <label
                                        htmlFor="ckAppconfigWhatsApp"
                                        className="cr cr-float-auto"
                                      ></label>
                                    </div>
                                  </div>
                                  {this.state.AppointConfigData.viaWhatsApp ? (
                                    <div>
                                      <div className="module-switch ord-m-t20">
                                        <div className="switch switch-primary">
                                          <Radio.Group
                                            onChange={this.handleRadioOnChange}
                                            value={
                                              this.state.AppointConfigData
                                                .isMsgWithin24Hrs === true
                                                ? 1
                                                : 2
                                            }
                                          >
                                            <Radio value={1}>
                                              {TranslationContext !== undefined
                                                ? TranslationContext.label
                                                    .checkcustomerlastreceivedmessagewithin24hrs
                                                : "Check customer last received message with in 24 Hrs"}
                                            </Radio>
                                            <Radio value={2}>
                                              {TranslationContext !== undefined
                                                ? TranslationContext.label
                                                    .donotcheckforanytime
                                                : "Do not check for any time"}
                                            </Radio>
                                          </Radio.Group>
                                        </div>
                                      </div>
                                      <div className="module-switch ord-m-t20">
                                        <div className="switch switch-primary">
                                          <label className="storeRole-name-text m-0 ordSttd-store">
                                            {TranslationContext !== undefined
                                              ? TranslationContext.label
                                                  .messageviawhatsapp
                                              : "Message via Whatsapp"}
                                          </label>
                                          <div className="row">
                                            <div className="col-md-4 strm-d-8">
                                              <label className="storeRole-name-text m-0 ordSttd-store">
                                                {TranslationContext !==
                                                undefined
                                                  ? TranslationContext.label
                                                      .placeholders
                                                  : "Placeholders"}
                                              </label>
                                            </div>
                                            <div className="col-md-8">
                                              <select
                                                className="form-control"
                                                name="selectedSlotTemplate"
                                                onChange={this.handlePlaceholdersOnChange.bind(
                                                  this,
                                                  "WhatsApp"
                                                )}
                                              >
                                                <option>Select</option>
                                                {this.state
                                                  .AppointMessageTagsData !==
                                                  null &&
                                                  this.state.AppointMessageTagsData.map(
                                                    (item, s) => (
                                                      <option
                                                        key={s}
                                                        value={item.placeHolder}
                                                      >
                                                        {item.placeHolder}
                                                      </option>
                                                    )
                                                  )}
                                              </select>
                                            </div>
                                          </div>
                                          <textarea
                                            rows="5"
                                            className="appoint-txtarea"
                                            name="messageViaWhatsApp"
                                            value={
                                              this.state.AppointConfigData
                                                .messageViaWhatsApp
                                            }
                                            onChange={this.AppoinmentConfigTextChange.bind(
                                              this
                                            )}
                                          ></textarea>
                                        </div>
                                      </div>
                                    </div>
                                  ) : null}
                                  <div className="module-switch ord-m-t20">
                                    <div className="switch switch-primary">
                                      <label className="storeRole-name-text m-0 ordSttd-store">
                                        {TranslationContext !== undefined
                                          ? TranslationContext.label
                                              .communicationviasms
                                          : "Communication via SMS"}
                                      </label>
                                      <input
                                        type="checkbox"
                                        id="ckAppconfigSMS"
                                        name="allModules"
                                        checked={
                                          this.state.AppointConfigData.viaSMS
                                        }
                                        onChange={this.AppoinmentConfigFlageChange.bind(
                                          this
                                        )}
                                      />
                                      <label
                                        htmlFor="ckAppconfigSMS"
                                        className="cr cr-float-auto"
                                      ></label>
                                    </div>
                                  </div>
                                  {this.state.AppointConfigData.viaSMS ? (
                                    <div className="appoint-conf-dv">
                                      <div className="module-switch ord-m-t20">
                                        <div className="switch switch-primary">
                                          <label className="storeRole-name-text m-0 ordSttd-store">
                                            {TranslationContext !== undefined
                                              ? TranslationContext.label
                                                  .messageviasms
                                              : "Message via SMS"}
                                          </label>
                                          <div className="row">
                                            <div className="col-md-4 strm-d-8">
                                              <label className="storeRole-name-text m-0 ordSttd-store">
                                                {TranslationContext !==
                                                undefined
                                                  ? TranslationContext.label
                                                      .placeholders
                                                  : "Placeholders"}
                                              </label>
                                            </div>
                                            <div className="col-md-8">
                                              <select
                                                className="form-control"
                                                name="selectedSlotTemplate"
                                                onChange={this.handlePlaceholdersOnChange.bind(
                                                  this,
                                                  "SMS"
                                                )}
                                              >
                                                <option>Select</option>
                                                {this.state
                                                  .AppointMessageTagsData !==
                                                  null &&
                                                  this.state.AppointMessageTagsData.map(
                                                    (item, s) => (
                                                      <option
                                                        key={s}
                                                        value={item.placeHolder}
                                                      >
                                                        {item.placeHolder}
                                                      </option>
                                                    )
                                                  )}
                                              </select>
                                            </div>
                                          </div>
                                          <textarea
                                            rows="5"
                                            className="appoint-txtarea"
                                            name="messageViaSMS"
                                            value={
                                              this.state.AppointConfigData
                                                .messageViaSMS
                                            }
                                            onChange={this.AppoinmentConfigTextChange.bind(
                                              this
                                            )}
                                          ></textarea>
                                        </div>
                                      </div>
                                    </div>
                                  ) : null}
                                </div>
                                <div className="module-switch-cntr">
                                  <div className="module-switch ord-m-t20">
                                    <div className="switch switch-primary">
                                      <label className="storeRole-name-text m-0 ordSttd-store">
                                        {TranslationContext !== undefined
                                          ? TranslationContext.label
                                              .appointmentreminder
                                          : "Appointment Reminder"}
                                      </label>
                                    </div>
                                  </div>
                                  <table className="cmpaign-channel-table">
                                    <tr>
                                      <td>
                                        {TranslationContext !== undefined
                                          ? TranslationContext.panel
                                              .appointmentreminderbefore
                                          : "Appointment reminder before"}
                                      </td>

                                      <td>
                                        <select
                                          value={
                                            this.state.AppointConfigData
                                              .remindBeforeDuration
                                          }
                                          name="remindBeforeDuration"
                                          onChange={this.handleAppointmentReminder.bind(
                                            this
                                          )}
                                        >
                                          <option value="1H">1 Hr</option>
                                          <option value="2H">2 Hr</option>
                                          <option value="3H">3 Hr</option>
                                          <option value="4H">4 Hr</option>
                                          <option value="5H">5 Hr</option>
                                          <option value="6H">6 Hr</option>
                                          <option value="7H">7 Hr</option>
                                          <option value="8H">8 Hr</option>
                                          <option value="9H">9 Hr</option>
                                          <option value="10H">10 Hr</option>
                                          <option value="11H">11 Hr</option>
                                          <option value="12H">12 Hr</option>
                                          <option value="13H">13 Hr</option>
                                          <option value="14H">14 Hr</option>
                                          <option value="15H">15 Hr</option>
                                          <option value="16H">16 Hr</option>
                                          <option value="17H">17 Hr</option>
                                          <option value="18H">18 Hr</option>
                                          <option value="19H">19 Hr</option>
                                          <option value="20H">20 Hr</option>
                                          <option value="21H">21 Hr</option>
                                          <option value="22H">22 Hr</option>
                                          <option value="23H">23 Hr</option>
                                          <option value="24H">24 Hr</option>
                                        </select>
                                      </td>
                                    </tr>
                                  </table>
                                  <div className="module-switch">
                                    <div className="switch switch-primary">
                                      <label className="storeRole-name-text m-0 ordSttd-store">
                                        {TranslationContext !== undefined
                                          ? TranslationContext.label
                                              .communicationviawhatsapp
                                          : "Communication via Whatsapp"}
                                      </label>
                                      <input
                                        type="checkbox"
                                        id="ckReminderViaWhapp"
                                        name="allModules"
                                        checked={
                                          this.state.AppointConfigData
                                            .reminderViaWhatsApp
                                        }
                                        onChange={this.AppoinmentConfigFlageChange.bind(
                                          this
                                        )}
                                      />
                                      <label
                                        htmlFor="ckReminderViaWhapp"
                                        className="cr cr-float-auto"
                                      ></label>
                                    </div>
                                  </div>
                                  {/* {this.state.AppointConfigData
                                    .reminderViaWhatsApp ? (
                                    <div className="appoint-conf-dv">
                                      <div className="module-switch">
                                        <div className="switch switch-primary">
                                          <div className="row">
                                            <div className="col-md-4 strm-d-8">
                                              <label className="storeRole-name-text m-0 ordSttd-store">
                                                {TranslationContext !==
                                                undefined
                                                  ? TranslationContext.label
                                                      .placeholders
                                                  : "Placeholders"}
                                              </label>
                                            </div>
                                            <div className="col-md-8">
                                              <select
                                                className="form-control"
                                                name="selectedSlotTemplate"
                                                onChange={this.handlePlaceholdersOnChange.bind(
                                                  this,
                                                  "WhatsApp"
                                                )}
                                              >
                                                <option>Select</option>
                                                {this.state
                                                  .AppointMessageTagsData !==
                                                  null &&
                                                  this.state.AppointMessageTagsData.map(
                                                    (item, s) => (
                                                      <option
                                                        key={s}
                                                        value={item.placeHolder}
                                                      >
                                                        {item.placeHolder}
                                                      </option>
                                                    )
                                                  )}
                                              </select>
                                            </div>
                                          </div>
                                          <textarea
                                            rows="5"
                                            className="appoint-txtarea"
                                            name="reminderMessageViaWhatsApp"
                                            value={
                                              this.state.AppointConfigData
                                                .reminderMessageViaWhatsApp
                                            }
                                            onChange={this.AppoinmentConfigTextChange.bind(
                                              this
                                            )}
                                          ></textarea>
                                        </div>
                                      </div>
                                    </div>
                                  ) : null} */}
                                  <div className="module-switch">
                                    <div className="switch switch-primary">
                                      <label className="storeRole-name-text m-0 ordSttd-store">
                                        {TranslationContext !== undefined
                                          ? TranslationContext.label
                                              .communicationviasms
                                          : "Communication via SMS"}
                                      </label>
                                      <input
                                        type="checkbox"
                                        id="ckReminderViaSMS"
                                        name="allModules"
                                        checked={
                                          this.state.AppointConfigData
                                            .reminderViaSMS
                                        }
                                        onChange={this.AppoinmentConfigFlageChange.bind(
                                          this
                                        )}
                                      />
                                      <label
                                        htmlFor="ckReminderViaSMS"
                                        className="cr cr-float-auto"
                                      ></label>
                                    </div>
                                  </div>
                                  {this.state.AppointConfigData
                                    .reminderViaSMS ? (
                                    <div className="appoint-conf-dv">
                                      <div className="module-switch">
                                        <div className="switch switch-primary">
                                          <div className="row">
                                            <div className="col-md-4 strm-d-8">
                                              <label className="storeRole-name-text m-0 ordSttd-store">
                                                {TranslationContext !==
                                                undefined
                                                  ? TranslationContext.label
                                                      .placeholders
                                                  : "Placeholders"}
                                              </label>
                                            </div>
                                            <div className="col-md-8">
                                              <select
                                                className="form-control"
                                                name="selectedSlotTemplate"
                                                onChange={this.handlePlaceholdersOnChange.bind(
                                                  this,
                                                  "RemindSMS"
                                                )}
                                              >
                                                <option>Select</option>
                                                {this.state
                                                  .AppointMessageTagsData !==
                                                  null &&
                                                  this.state.AppointMessageTagsData.map(
                                                    (item, s) => (
                                                      <option
                                                        key={s}
                                                        value={item.placeHolder}
                                                      >
                                                        {item.placeHolder}
                                                      </option>
                                                    )
                                                  )}
                                              </select>
                                            </div>
                                          </div>
                                          <textarea
                                            rows="5"
                                            className="appoint-txtarea"
                                            name="reminderMessageViaSMS"
                                            value={
                                              this.state.AppointConfigData
                                                .reminderMessageViaSMS
                                            }
                                            onChange={this.AppoinmentConfigTextChange.bind(
                                              this
                                            )}
                                          ></textarea>
                                        </div>
                                      </div>
                                    </div>
                                  ) : null}
                                </div>
                                <button
                                  className="Schedulenext1 w-100 mb-0 mt-4"
                                  type="button"
                                  onClick={this.handleUpdateAppointmentConfigData.bind(
                                    this
                                  )}
                                  disabled={this.state.isSubmit}
                                >
                                  {this.state.isSubmit ? (
                                    <FontAwesomeIcon
                                      className="circular-loader"
                                      icon={faCircleNotch}
                                      spin
                                    />
                                  ) : null}
                                  {TranslationContext !== undefined
                                    ? TranslationContext.button.update
                                    : "UPDATE"}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="tab-pane fade"
                  id="Module-BroadcastConfig-Tab"
                  role="tabpanel"
                  aria-labelledby="Module-BroadcastConfig-Tab"
                >
                  <div className="backNone">
                    <div className="row">
                      <div className="col-md-12">
                        <div style={{ background: "white" }}>
                          <div className="row">
                            <div className="col-md-5 m-auto">
                              <div className="right-sect-div">
                                <h3>
                                  {TranslationContext !== undefined
                                    ? TranslationContext.h3
                                        .broadcastconfiguration
                                    : "BROADCAST CONFIGURATION"}
                                </h3>
                                <div className="module-switch-cntr">
                                  <div className="module-switch">
                                    <div className="switch switch-primary">
                                      <label className="storeRole-name-text m-0">
                                        {TranslationContext !== undefined
                                          ? TranslationContext.label.sms
                                          : "SMS"}
                                      </label>
                                      <input
                                        type="checkbox"
                                        id="ckbroadSMS"
                                        name="allModules"
                                        checked={
                                          this.state.BroadCastConfigData.smsFlag
                                        }
                                        onChange={this.handleBroadCongiFlageOnchange.bind(
                                          this
                                        )}
                                      />
                                      <label
                                        htmlFor="ckbroadSMS"
                                        className="cr cr-float-auto"
                                      ></label>
                                    </div>
                                    {this.state.BroadCastConfigData.smsFlag ? (
                                      <div className="cusinput">
                                        <input
                                          type="text"
                                          name="providerName"
                                          autoComplete="off"
                                          placeholder={
                                            TranslationContext !== undefined
                                              ? TranslationContext.placeholder
                                                  .providername
                                              : "Provider name"
                                          }
                                          maxLength={15}
                                          value={
                                            this.state.BroadCastConfigData
                                              .providerName
                                          }
                                          onChange={this.BroadCastOnChange.bind(
                                            this
                                          )}
                                        />
                                        {this.state.BroadCastConfigData
                                          .providerName === "" && (
                                          <p
                                            style={{
                                              color: "red",
                                              marginBottom: "0px",
                                            }}
                                          >
                                            {this.state.broadProviderValidation}
                                          </p>
                                        )}
                                      </div>
                                    ) : null}
                                  </div>
                                  <div className="module-switch">
                                    <div className="switch switch-primary">
                                      <label className="storeRole-name-text m-0">
                                        {TranslationContext !== undefined
                                          ? TranslationContext.label.whatsapp
                                          : "Whatsapp"}
                                      </label>
                                      <input
                                        type="checkbox"
                                        id="ckbroadwhatsapp"
                                        name="allModules"
                                        checked={
                                          this.state.BroadCastConfigData
                                            .whatsappFlag
                                        }
                                        onChange={this.handleBroadCongiFlageOnchange.bind(
                                          this
                                        )}
                                      />
                                      <label
                                        htmlFor="ckbroadwhatsapp"
                                        className="cr cr-float-auto"
                                      ></label>
                                    </div>
                                  </div>
                                  <div className="module-switch">
                                    <div className="switch switch-primary">
                                      <label className="storeRole-name-text m-0">
                                        {TranslationContext !== undefined
                                          ? TranslationContext.label.email
                                          : "Email"}
                                      </label>
                                      <input
                                        type="checkbox"
                                        id="ckbroadEmail"
                                        name="allModules"
                                        checked={
                                          this.state.BroadCastConfigData
                                            .emailFlag
                                        }
                                        onChange={this.handleBroadCongiFlageOnchange.bind(
                                          this
                                        )}
                                      />
                                      <label
                                        htmlFor="ckbroadEmail"
                                        className="cr cr-float-auto"
                                      ></label>
                                    </div>
                                  </div>
                                  <div className="module-switch">
                                    <div className="switch switch-primary">
                                      <label className="storeRole-name-text m-0">
                                        Chat Session Active
                                      </label>
                                      <input
                                        type="checkbox"
                                        id="ckChatSessionActive"
                                        name="allModules"
                                        checked={
                                          this.state.BroadCastConfigData
                                            .chatSessionActiveFlag
                                        }
                                        onChange={this.handleBroadCongiFlageOnchange.bind(
                                          this
                                        )}
                                      />
                                      <label
                                        htmlFor="ckChatSessionActive"
                                        className="cr cr-float-auto"
                                      ></label>
                                    </div>
                                  </div>
                                </div>
                                <table className="cmpaign-channel-table">
                                  <tr>
                                    <td>
                                      {TranslationContext !== undefined
                                        ? TranslationContext.td
                                            .maxclickallowesonanychannelcta
                                        : "Max. click allowed on any channel CTA"}
                                    </td>
                                    <td>
                                      <input
                                        type="text"
                                        name="maxClickAllowed"
                                        value={
                                          this.state.BroadCastConfigData
                                            .maxClickAllowed
                                        }
                                        autoComplete="off"
                                        maxLength={2}
                                        onChange={this.BroadCastOnChange.bind(
                                          this
                                        )}
                                      />
                                      {this.state.BroadCastConfigData
                                        .maxClickAllowed === "" && (
                                        <p
                                          style={{
                                            color: "red",
                                            marginBottom: "0px",
                                          }}
                                        >
                                          {this.state.braodCastMaxClickValid}
                                        </p>
                                      )}
                                    </td>
                                    <td>
                                      {TranslationContext !== undefined
                                        ? TranslationContext.td.click
                                        : "Click"}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      {TranslationContext !== undefined
                                        ? TranslationContext.td
                                            .clickwillbeenabledafter
                                        : "Click will be enabled after"}
                                    </td>
                                    <td>
                                      <input
                                        type="text"
                                        name="enableClickAfterValue"
                                        autoComplete="off"
                                        maxLength={2}
                                        value={
                                          this.state.BroadCastConfigData
                                            .enableClickAfterValue
                                        }
                                        onChange={this.BroadCastOnChange.bind(
                                          this
                                        )}
                                      />
                                      {this.state.BroadCastConfigData
                                        .enableClickAfterValue === "" && (
                                        <p
                                          style={{
                                            color: "red",
                                            marginBottom: "0px",
                                          }}
                                        >
                                          {
                                            this.state
                                              .broadCastEnabledAfterValid
                                          }
                                        </p>
                                      )}
                                    </td>
                                    <td>
                                      <select
                                        value={
                                          this.state.BroadCastConfigData
                                            .enableClickAfterDuration
                                        }
                                        name="enableClickAfterDuration"
                                        onChange={this.BroadCastOnChange.bind(
                                          this
                                        )}
                                      >
                                        <option value="M">
                                          {TranslationContext !== undefined
                                            ? TranslationContext.option.min
                                            : "Min"}
                                        </option>
                                        <option value="H">
                                          {TranslationContext !== undefined
                                            ? TranslationContext.option.hr
                                            : "Hr"}
                                        </option>
                                      </select>
                                    </td>
                                  </tr>
                                </table>
                                <button
                                  className="Schedulenext1 w-100 mb-0 mt-4"
                                  type="button"
                                  onClick={this.CheckBroadCastValidation.bind(
                                    this
                                  )}
                                  disabled={this.state.isSubmit}
                                >
                                  {this.state.isSubmit ? (
                                    <FontAwesomeIcon
                                      className="circular-loader"
                                      icon={faCircleNotch}
                                      spin
                                    />
                                  ) : null}
                                  {TranslationContext !== undefined
                                    ? TranslationContext.button.update
                                    : "UPDATE"}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="Module-SlotSetting-Tab"
                  role="tabpanel"
                  aria-labelledby="Module-SlotSetting-Tab"
                >
                  <div className="backNone">
                    <div className="row">
                      <div className="col-md-12">
                        <div style={{ background: "white" }}>
                          <div className="row">
                            <div className="col-md-10 m-auto">
                              <div
                                className="right-sect-div slot-newd"
                                style={{ padding: "20px" }}
                              >
                                {!this.state.isVideoCallAppointmentTab && (
                                  <div>
                                    <ul className="nav nav-tabs" role="tablist">
                                      <li className="nav-item">
                                        <a
                                          className="nav-link active"
                                          data-toggle="tab"
                                          href="#Slot-Manual-Tab"
                                          role="tab"
                                          aria-controls="Slot-Manual-Tab"
                                          aria-selected="true"
                                        >
                                          {TranslationContext !== undefined
                                            ? TranslationContext.a.manual
                                            : "Manual"}
                                        </a>
                                      </li>

                                      <li className="nav-item">
                                        <a
                                          className="nav-link"
                                          data-toggle="tab"
                                          href="#Slot-bulkUpl-Tab"
                                          role="tab"
                                          aria-controls="Slot-bulkUpl-Tab"
                                          aria-selected="false"
                                        >
                                          {TranslationContext !== undefined
                                            ? TranslationContext.a.bulkupload
                                            : "Bulk Upload"}
                                        </a>
                                      </li>
                                    </ul>
                                  </div>
                                )}

                                <div className="tab-content p-0">
                                  <div
                                    className="tab-pane fade show active"
                                    id="Slot-Manual-Tab"
                                    role="tabpanel"
                                    aria-labelledby="Slot-Manual-Tab"
                                  >
                                    <div className="manualbox operational-select">
                                      <div className="">
                                        <ul>
                                          <li>
                                            <label>
                                              {TranslationContext !== undefined
                                                ? TranslationContext.label
                                                    .choosestore
                                                : "Choose Store"}
                                            </label>
                                            <div
                                              className="input-group"
                                              onClick={this.handleChooseStoreOpenModal.bind(
                                                this
                                              )}
                                            >
                                              <input
                                                type="text"
                                                className="form-control"
                                                placeholder={
                                                  TranslationContext !==
                                                  undefined
                                                    ? TranslationContext
                                                        .placeholder.search
                                                    : "Search"
                                                }
                                                value={
                                                  this.state.showApplyStoreData
                                                    ? this.state
                                                        .selectedStoreValues
                                                    : ""
                                                }
                                              />
                                              <span className="input-group-append">
                                                <img
                                                  src={searchico}
                                                  alt="search-icon"
                                                  className="cr-pnt"
                                                />
                                              </span>
                                            </div>
                                            <div
                                              style={{
                                                display: "inline-block",
                                              }}
                                            >
                                              {this.state.showApplyStoreData &&
                                                Number(
                                                  this.state.shoreSelectedCount
                                                ) > 0 && (
                                                  <a
                                                    style={{ float: "left" }}
                                                    onClick={this.handleSelectedStoreOpenModal.bind(
                                                      this
                                                    )}
                                                  >
                                                    {Number(
                                                      this.state
                                                        .shoreSelectedCount
                                                    ) > 0
                                                      ? this.state
                                                          .shoreSelectedCount
                                                      : null}
                                                    &nbsp;
                                                    {TranslationContext !==
                                                    undefined
                                                      ? TranslationContext.a
                                                          .storeselected
                                                      : "Store Selected"}
                                                    {">"}
                                                  </a>
                                                )}
                                              {this.state.isChooseStore ? (
                                                <p
                                                  className="non-deliverable"
                                                  style={{
                                                    marginTop: "0",
                                                    textAlign: "left",
                                                  }}
                                                >
                                                  {this.state.isChooseStore}
                                                </p>
                                              ) : null}
                                            </div>
                                          </li>
                                          <li>
                                            <label>
                                              {TranslationContext !== undefined
                                                ? TranslationContext.label
                                                    .operationaldays
                                                : "Operational Days"}
                                            </label>

                                            <Select
                                              className="select-oper"
                                              getOptionLabel={(option) =>
                                                option.dayName
                                              }
                                              getOptionValue={(option) =>
                                                option.dayID
                                              }
                                              options={
                                                this.state.operationalDaysData
                                              }
                                              onChange={this.handleChangeOperationalDays.bind(
                                                this
                                              )}
                                              value={this.state.operationalDays}
                                              placeholder={
                                                TranslationContext !== undefined
                                                  ? TranslationContext
                                                      .validation
                                                      .pleaseselectoperationaldays
                                                  : "Please Select Operational Days"
                                              }
                                              closeMenuOnSelect={false}
                                              isMulti
                                            />
                                            {this.state.isoperationalDay ? (
                                              <p
                                                className="non-deliverable"
                                                style={{
                                                  marginTop: "3px",
                                                  textAlign: "left",
                                                }}
                                              >
                                                {this.state.isoperationalDay}
                                              </p>
                                            ) : null}
                                          </li>
                                          <li>
                                            <label>
                                              {TranslationContext !== undefined
                                                ? TranslationContext.label
                                                    .selectslottemplete
                                                : "Select Slot Template"}
                                            </label>
                                            <select
                                              className="form-control"
                                              name="selectedSlotTemplate"
                                              value={
                                                this.state.selectedSlotTemplate
                                              }
                                              onChange={this.handleSlotTemplateChange.bind(
                                                this
                                              )}
                                            >
                                              <option value={0}>Select</option>
                                              {this.state
                                                .isVideoCallAppointmentTab
                                                ? this.state
                                                    .videoSlotTemplateData !==
                                                    null &&
                                                  this.state.videoSlotTemplateData.map(
                                                    (item, s) => (
                                                      <option
                                                        key={s}
                                                        value={
                                                          item.slotTemplateID
                                                        }
                                                      >
                                                        {item.slotTemplateName}
                                                      </option>
                                                    )
                                                  )
                                                : this.state
                                                    .slotTemplateData !==
                                                    null &&
                                                  this.state.slotTemplateData.map(
                                                    (item, s) => (
                                                      <option
                                                        key={s}
                                                        value={
                                                          item.slotTemplateID
                                                        }
                                                      >
                                                        {item.slotTemplateName}
                                                      </option>
                                                    )
                                                  )}
                                            </select>
                                            <div
                                              style={{
                                                display: "inline-block",
                                                float: "right",
                                              }}
                                            >
                                              <a
                                                onClick={this.handleCreateTempletetOpenModal.bind(
                                                  this
                                                )}
                                              >
                                                +
                                                {TranslationContext !==
                                                undefined
                                                  ? TranslationContext.a
                                                      .createnewtemplate
                                                  : "Create New Template"}
                                              </a>
                                              {this.state.isSlotTemplete ? (
                                                <p
                                                  className="non-deliverable"
                                                  style={{
                                                    marginTop: "0",
                                                    textAlign: "left",
                                                  }}
                                                >
                                                  {this.state.isSlotTemplete}
                                                </p>
                                              ) : null}
                                            </div>
                                          </li>
                                          <li>
                                            {!this.state.isNextClick && (
                                              <button
                                                className="butn"
                                                onClick={this.handleNextButtonOpen.bind(
                                                  this
                                                )}
                                              >
                                                {TranslationContext !==
                                                undefined
                                                  ? TranslationContext.button
                                                      .next
                                                  : "Next" + ">>"}
                                              </button>
                                            )}
                                          </li>
                                        </ul>
                                      </div>
                                      {this.state.isNextClick ? (
                                        <div className="nextbox">
                                          <div className="">
                                            <Table
                                              dataSource={
                                                this.state.SlotTemplateGridData
                                              }
                                              loading={
                                                this.state.slotTempLoading
                                              }
                                              noDataContent="No Record Found"
                                              pagination={false}
                                              className="components-table-demo-nested antd-table-campaign custom-antd-table"
                                              columns={[
                                                {
                                                  title:
                                                    TranslationContext !==
                                                    undefined
                                                      ? TranslationContext.span
                                                          .srno
                                                      : "S.No.",
                                                  render: (row, rowData, i) => {
                                                    return <>{i + 1}</>;
                                                  },
                                                },
                                                {
                                                  title:
                                                    TranslationContext !==
                                                    undefined
                                                      ? TranslationContext.label
                                                          .slotstarttime
                                                      : "Slot Start Time",
                                                  dataIndex: "slotStartTime",
                                                },
                                                {
                                                  title:
                                                    TranslationContext !==
                                                    undefined
                                                      ? TranslationContext.label
                                                          .slotendtime
                                                      : "Slot End Time",
                                                  dataIndex: "slotEndTime",
                                                },
                                                {
                                                  hidden: this.state
                                                    .isVideoCallAppointmentTab
                                                    ? true
                                                    : false,
                                                  title:
                                                    TranslationContext !==
                                                    undefined
                                                      ? TranslationContext.label
                                                          .slotoccupancy
                                                      : this.state
                                                          .isVideoCallAppointmentTab
                                                      ? "No of Slots"
                                                      : "Slot Occupancy",
                                                  dataIndex: "slotOccupancy",
                                                  render: (row, rowData) => {
                                                    return (
                                                      <>
                                                        <input
                                                          type="text"
                                                          className="form-control value"
                                                          value={
                                                            rowData.slotOccupancy
                                                          }
                                                          //disabled
                                                          onChange={this.handleslotOccupancyChange.bind(
                                                            this,
                                                            rowData.slotID
                                                          )}
                                                        />
                                                      </>
                                                    );
                                                  },
                                                },
                                                {
                                                  title:
                                                    TranslationContext !==
                                                    undefined
                                                      ? TranslationContext.label
                                                          .slotstatusunabledisable
                                                      : "Slot Status(Enable/Disable)",
                                                  render: (row, rowData) => {
                                                    return (
                                                      <div className="chrdioclr switch switch-primary d-inline m-r-10 slotcheck">
                                                        <input
                                                          style={{
                                                            position: "fixed",
                                                          }}
                                                          type="checkbox"
                                                          id={
                                                            "slot" +
                                                            rowData.slotID
                                                          }
                                                          name="allModules"
                                                          checked={
                                                            rowData.isSlotEnabled
                                                          }
                                                          onChange={this.handleslotActiveInActive.bind(
                                                            this,
                                                            rowData.slotID
                                                          )}
                                                        />
                                                        <label
                                                          htmlFor={
                                                            "slot" + row.slotID
                                                          }
                                                          className="cr cr-float-auto"
                                                          style={{
                                                            float: "inherit",
                                                          }}
                                                        ></label>
                                                      </div>
                                                    );
                                                  },
                                                },
                                                {
                                                  hidden: this.state
                                                    .isVideoCallAppointmentTab
                                                    ? false
                                                    : true,
                                                  title: "Store Staff Name",
                                                  render: (row, rowData) => {
                                                    return (
                                                      <select
                                                        onChange={this.handleStaffNameChange.bind(
                                                          this,
                                                          rowData
                                                        )}
                                                        value={
                                                          rowData.storeStaffNameID
                                                        }
                                                      >
                                                        {/* {this.state
                                                          .storeAgentList
                                                          .length > 1 && ( */}
                                                          <option value="">
                                                            Select Staff Name
                                                          </option>
                                                        {/* )} */}

                                                        {this.state.storeAgentList.map(
                                                          (agent) => {
                                                            return (
                                                              <option
                                                                value={
                                                                  agent.agentID
                                                                }
                                                              >
                                                                {agent.userName}
                                                              </option>
                                                            );
                                                          }
                                                        )}
                                                      </select>
                                                    );
                                                  },
                                                },
                                              ].filter((item) => !item.hidden)}
                                            ></Table>
                                          </div>

                                          <ul>
                                            {!this.state
                                              .isVideoCallAppointmentTab && (
                                              <li>
                                                <label>
                                                  {TranslationContext !==
                                                  undefined
                                                    ? TranslationContext.label
                                                        .slotdaysneedtodisplay
                                                    : "Slot days need to display"}
                                                </label>
                                                <select
                                                  name=""
                                                  className="form-control"
                                                  value={
                                                    this.state.slotDaysDisplay
                                                  }
                                                  onChange={(e) => {
                                                    this.setState({
                                                      slotDaysDisplay:
                                                        e.target.value,
                                                      isSlotDaysDisplay:
                                                        e.target.value === 0
                                                          ? "Please Select Slot Display Days"
                                                          : "",
                                                    });
                                                  }}
                                                >
                                                  <option value={0}>
                                                    Select
                                                  </option>
                                                  {Array(31)
                                                    .fill(0)
                                                    .map((e, i) => {
                                                      return (
                                                        <option value={i + 1}>
                                                          {i + 1}
                                                        </option>
                                                      );
                                                    })}
                                                </select>
                                                {this.state
                                                  .isSlotDaysDisplay ? (
                                                  <p
                                                    className="non-deliverable"
                                                    style={{
                                                      marginTop: "0",
                                                      textAlign: "left",
                                                    }}
                                                  >
                                                    {
                                                      this.state
                                                        .isSlotDaysDisplay
                                                    }
                                                  </p>
                                                ) : null}
                                              </li>
                                            )}

                                            <li>
                                              <label>
                                                {TranslationContext !==
                                                undefined
                                                  ? TranslationContext.label
                                                      .applicablefromdate
                                                  : "Applicable From (Date)"}
                                              </label>
                                              <div
                                                className="applicateDiv"
                                                style={{
                                                  width: this.state
                                                    .isVideoCallAppointmentTab
                                                    ? 200
                                                    : "100%",
                                                }}
                                              >
                                                <DatePicker
                                                  selected={
                                                    this.state
                                                      .applicableFromDate
                                                  }
                                                  minDate={moment().toDate()}
                                                  dateFormat="dd-MM-yyyy"
                                                  placeholderText="Applicable From (Date)"
                                                  className="form-control"
                                                  onChange={this.handleApplicableFormData.bind(
                                                    this
                                                  )}
                                                />
                                              </div>
                                            </li>
                                            {this.state
                                              .isVideoCallAppointmentTab ? (
                                              <li>
                                                <label>
                                                  Defualt User Notification
                                                </label>
                                                <div>
                                                  <Select
                                                    className="select-oper"
                                                    getOptionLabel={(option) =>
                                                      option.agentName
                                                    }
                                                    getOptionValue={(option) =>
                                                      option.agentID
                                                    }
                                                    options={
                                                      this.state.storeAgentList
                                                    }
                                                    value={
                                                      this.state
                                                        .notificationToAgent
                                                    }
                                                    onChange={this.handleNotificationToChange.bind(
                                                      this
                                                    )}
                                                    placeholder={"Select"}
                                                    closeMenuOnSelect={false}
                                                    isMulti
                                                  />
                                                </div>
                                              </li>
                                            ) : null}
                                            {!this.state
                                              .isVideoCallAppointmentTab && (
                                              <li>
                                                <label>
                                                  {TranslationContext !==
                                                  undefined
                                                    ? TranslationContext.label
                                                        .maxpeopleallowed
                                                    : "Max People allowed in one Appointment"}
                                                </label>
                                                <select
                                                  name=""
                                                  className="form-control"
                                                  value={
                                                    this.state
                                                      .maxPeopleAppointment
                                                  }
                                                  onChange={(e) => {
                                                    this.setState({
                                                      maxPeopleAppointment:
                                                        e.target.value,
                                                      isMaxPeople:
                                                        e.target.value === 0
                                                          ? "Please Select Max People one Appointment."
                                                          : "",
                                                    });
                                                  }}
                                                >
                                                  <option value={0}>
                                                    Select
                                                  </option>
                                                  {Array(50)
                                                    .fill(0)
                                                    .map((e, i) => {
                                                      return (
                                                        <option value={i + 1}>
                                                          {i + 1}
                                                        </option>
                                                      );
                                                    })}
                                                </select>
                                                {this.state.isMaxPeople ? (
                                                  <p
                                                    className="non-deliverable"
                                                    style={{
                                                      marginTop: "0",
                                                      textAlign: "left",
                                                    }}
                                                  >
                                                    {this.state.isMaxPeople}
                                                  </p>
                                                ) : null}
                                              </li>
                                            )}
                                          </ul>
                                          <div className="row">
                                            {!this.state
                                              .isVideoCallAppointmentTab && (
                                              <div className="col-md-4">
                                                <label>
                                                  {TranslationContext !==
                                                  undefined
                                                    ? TranslationContext.label
                                                        .slotdisplay
                                                    : "Slot Display Code"}
                                                </label>
                                                <select
                                                  name=""
                                                  className="form-control"
                                                  value={
                                                    this.state.SlotDisplayCode
                                                  }
                                                  onChange={(e) => {
                                                    this.setState({
                                                      SlotDisplayCode:
                                                        e.target.value,
                                                      isSlotDisplayCode:
                                                        e.target.value === 0
                                                          ? TranslationContext !==
                                                            undefined
                                                            ? TranslationContext
                                                                .validation
                                                                .pleaseselectslotdisplaycode
                                                            : "Please Select Slot Display Code."
                                                          : "",
                                                    });
                                                  }}
                                                >
                                                  <option value={0}>
                                                    {TranslationContext !==
                                                    undefined
                                                      ? TranslationContext
                                                          .option.select
                                                      : "Select"}
                                                  </option>
                                                  <option value={301}>
                                                    {TranslationContext !==
                                                    undefined
                                                      ? TranslationContext
                                                          .option.currentslot
                                                      : "Current Slot"}
                                                  </option>
                                                  <option value={302}>
                                                    {TranslationContext !==
                                                    undefined
                                                      ? TranslationContext
                                                          .option
                                                          .skipcurrentslotshownextslot
                                                      : "Skip Current Slot & Show Next Slot"}
                                                  </option>
                                                  <option value={303}>
                                                    {TranslationContext !==
                                                    undefined
                                                      ? TranslationContext
                                                          .option
                                                          .skipcurrentnextslot
                                                      : "Skip Current & Next Slot"}
                                                  </option>
                                                </select>
                                                {this.state
                                                  .isSlotDisplayCode ? (
                                                  <p
                                                    className="non-deliverable"
                                                    style={{
                                                      marginTop: "0",
                                                      textAlign: "left",
                                                    }}
                                                  >
                                                    {
                                                      this.state
                                                        .isSlotDisplayCode
                                                    }
                                                  </p>
                                                ) : null}
                                              </div>
                                            )}

                                            <div className="col-md-4">
                                              <div
                                                className="statuscheckbox"
                                                style={{ marginTop: "35px" }}
                                              >
                                                <label
                                                  style={{
                                                    marginRight: "15px",
                                                  }}
                                                >
                                                  {TranslationContext !==
                                                  undefined
                                                    ? TranslationContext.label
                                                        .status
                                                    : "Status"}
                                                </label>

                                                <div className="statuscheckbox">
                                                  <Radio.Group
                                                    onChange={this.handleRadioStatusChange.bind(
                                                      this
                                                    )}
                                                    value={
                                                      this.state.slotStatus
                                                    }
                                                  >
                                                    <Radio value={1}>
                                                      {TranslationContext !==
                                                      undefined
                                                        ? TranslationContext
                                                            .label.active
                                                        : "Active"}
                                                    </Radio>
                                                    <Radio value={0}>
                                                      {TranslationContext !==
                                                      undefined
                                                        ? TranslationContext
                                                            .label.inactive
                                                        : "Inactive"}
                                                    </Radio>
                                                  </Radio.Group>
                                                </div>
                                              </div>
                                            </div>
                                          </div>

                                          <div className="del-can">
                                            <a
                                              href={Demo.BLANK_LINK}
                                              onClick={this.handleCancelSlot.bind(
                                                this
                                              )}
                                            >
                                              {TranslationContext !== undefined
                                                ? TranslationContext.a.cancel
                                                : "CANCEL"}
                                            </a>
                                            <button
                                              disabled={
                                                this.state.isSlotSaveClick
                                              }
                                              className="butn"
                                              onClick={this.handleInsertUpdateTimeSlotSetting.bind(
                                                this,
                                                true
                                              )}
                                            >
                                              <label className="sltsave-btn">
                                                {TranslationContext !==
                                                undefined
                                                  ? TranslationContext.button
                                                      .delete
                                                  : "Save"}
                                              </label>
                                              {this.state.isSlotSaveClick ? (
                                                <FontAwesomeIcon
                                                  className="circular-loader"
                                                  icon={faCircleNotch}
                                                  spin
                                                />
                                              ) : null}
                                            </button>
                                          </div>
                                        </div>
                                      ) : null}
                                    </div>
                                  </div>
                                  <div
                                    className="tab-pane fade"
                                    id="Slot-bulkUpl-Tab"
                                    role="tabpanel"
                                    aria-labelledby="Slot-bulkUpl-Tab"
                                  >
                                    <div
                                      className="bulkuploadbox"
                                      style={{ marginTop: "25px" }}
                                    >
                                      <Spin
                                        tip="Please wait..."
                                        spinning={this.state.bulkuploadLoading}
                                      >
                                        <div className="addfilebox">
                                          <Dropzone
                                            onDrop={this.handleSlotFileUpload}
                                          >
                                            {({
                                              getRootProps,
                                              getInputProps,
                                            }) => (
                                              <>
                                                <div {...getRootProps()}>
                                                  <input
                                                    {...getInputProps()}
                                                    className="file-upload d-none"
                                                  />
                                                  <img
                                                    src={FileUpload}
                                                    alt="file-upload"
                                                  />
                                                  <span
                                                    className={
                                                      "fileupload-span"
                                                    }
                                                  >
                                                    {TranslationContext !==
                                                    undefined
                                                      ? TranslationContext.span
                                                          .addfile
                                                      : "Add File"}
                                                  </span>
                                                  {TranslationContext !==
                                                  undefined
                                                    ? TranslationContext.div.or
                                                    : "or"}
                                                  &nbsp;
                                                  {TranslationContext !==
                                                  undefined
                                                    ? TranslationContext.div
                                                        .dropfilehere
                                                    : "Drop File here"}
                                                </div>
                                                <div>
                                                  <p>
                                                    {this.state.SlotFileName}
                                                  </p>
                                                </div>
                                              </>
                                            )}
                                          </Dropzone>
                                        </div>
                                      </Spin>
                                      <div className="slot-down-excel mr-3">
                                        <p>
                                          {TranslationContext !== undefined
                                            ? TranslationContext.p
                                                .sampletemplate
                                            : "Sample Template"}
                                        </p>
                                        <CSVLink
                                          filename={"SlotTemplate.csv"}
                                          data={config.slot_Template}
                                        >
                                          <img
                                            src={DownExcel}
                                            alt="download icon"
                                            style={{ marginLeft: "5px" }}
                                          />
                                        </CSVLink>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-auto d-flex align-items-center mb-2">
                            {this.state.isFilterSearch &&
                            this.state.FilterCollapse ? (
                              <>
                                <p className="font-weight-bold mr-3">
                                  <span className="blue-clr">
                                    {this.state.TimeSlotGridData.length}{" "}
                                  </span>
                                  Results
                                </p>
                                {this.state.filterOptionSelected.value != 1 ? (
                                  <a
                                    href="#!"
                                    className="blue-clr fs-14"
                                    onClick={this.handleEditAllData.bind(this)}
                                  >
                                    EDIT ALL
                                  </a>
                                ) : null}
                                &nbsp; &nbsp; &nbsp;
                                <div className="deleteConfirm">
                                  <Popconfirm
                                    title="Are you sure delete all slot ?"
                                    onConfirm={this.handleFilterDeleteALLSlotData.bind(
                                      this
                                    )}
                                    overlayClassName="deleteConfirm"
                                    // onCancel={cancel}
                                    okText="Yes"
                                    cancelText="No"
                                    icon={<></>}
                                  >
                                    <a href="#!" className="blue-clr fs-14">
                                      DELETE ALL
                                    </a>
                                  </Popconfirm>
                                </div>
                              </>
                            ) : null}
                          </div>
                          <div className="row slottbl">
                            <div className="col-md-12">
                              <Collapse isOpen={this.state.FilterCollapse}>
                                <Card>
                                  <CardBody>
                                    <div className="table-expandable-sctn1">
                                      <div className="filter-btn-slot">
                                        {this.state.isFilterSearch ? (
                                          <button
                                            className="btn-inv"
                                            type="button"
                                            onClick={this.handleFilterClear.bind(
                                              this
                                            )}
                                          >
                                            CLEAR
                                          </button>
                                        ) : null}
                                        <button
                                          className="btn-inv"
                                          type="button"
                                          onClick={this.handleGetTimeslotGridData.bind(
                                            this,
                                            0,
                                            this.state.filterSelectedStoreId,
                                            this.state.filterOperationalDays
                                              .dayIDs || "",
                                            this.state.filterSlotTemplate || 0,
                                            true,
                                            this.state.isVideoCallAppointmentTab
                                          )}
                                        >
                                          FILTER
                                        </button>
                                      </div>
                                      <div className="container-fluid">
                                        <div className="row manualbox py-3">
                                          <div className="col-md-3">
                                            <label>Filter</label>
                                            <Select
                                              // placeholder={"Select Filter"}
                                              className="select-oper"
                                              onChange={this.handleFilterChange.bind(
                                                this
                                              )}
                                              value={
                                                Object.keys(
                                                  this.state
                                                    .filterOptionSelected
                                                ).length === 0 &&
                                                this.state.filterOptionSelected
                                                  .constructor === Object
                                                  ? null
                                                  : this.state
                                                      .filterOptionSelected
                                              }
                                              defaultOptions={false}
                                              options={this.state.filterOptions}
                                              placeholder={
                                                "Please Select Filter Options"
                                              }
                                            />
                                          </div>
                                          {this.state.filterOptionSelected
                                            .value === 1 && (
                                            <div className="col-md-3">
                                              <label>
                                                {TranslationContext !==
                                                undefined
                                                  ? TranslationContext.label
                                                      .choosestore
                                                  : "Choose Store"}
                                              </label>
                                              <div
                                                className="input-group"
                                                style={{ flexWrap: "nowrap" }}
                                                onClick={this.handleFilterChooseStoreOpenModal.bind(
                                                  this
                                                )}
                                              >
                                                <input
                                                  type="text"
                                                  className="form-control"
                                                  placeholder={
                                                    TranslationContext !==
                                                    undefined
                                                      ? TranslationContext
                                                          .placeholder.search
                                                      : "Search"
                                                  }
                                                  value={
                                                    this.state
                                                      .filterSelectedStoreCode
                                                  }
                                                />
                                                <span className="input-group-append">
                                                  <img
                                                    src={searchico}
                                                    alt="search-icon"
                                                    className="cr-pnt"
                                                  />
                                                </span>
                                              </div>
                                            </div>
                                          )}
                                          {this.state.filterOptionSelected
                                            .value === 2 && (
                                            <div className="col-md-3">
                                              <label>
                                                {TranslationContext !==
                                                undefined
                                                  ? TranslationContext.label
                                                      .operationaldays
                                                  : "Operational Days"}
                                              </label>

                                              <Select
                                                // placeholder={
                                                //   "Select Operational Days"
                                                // }
                                                className="select-oper"
                                                getOptionLabel={(option) =>
                                                  option.dayNames
                                                }
                                                getOptionValue={(option) =>
                                                  option.dayIDs
                                                }
                                                options={
                                                  this.state
                                                    .onLoadOperationalDays
                                                }
                                                onChange={this.handleFilterChangeOperationalDays.bind(
                                                  this
                                                )}
                                                value={
                                                  Object.keys(
                                                    this.state
                                                      .filterOperationalDays
                                                  ).length === 0 &&
                                                  this.state
                                                    .filterOperationalDays
                                                    .constructor === Object
                                                    ? null
                                                    : this.state
                                                        .filterOperationalDays
                                                }
                                                placeholder={
                                                  TranslationContext !==
                                                  undefined
                                                    ? TranslationContext
                                                        .validation
                                                        .pleaseselectoperationaldays
                                                    : "Please Select Operational Days"
                                                }
                                              />
                                              {this.state.isoperationalDay ? (
                                                <p
                                                  className="non-deliverable"
                                                  style={{
                                                    marginTop: "3px",
                                                    textAlign: "left",
                                                  }}
                                                >
                                                  {this.state.isoperationalDay}
                                                </p>
                                              ) : null}
                                            </div>
                                          )}
                                          {this.state.filterOptionSelected
                                            .value === 3 && (
                                            <div className="col-md-3">
                                              <label>
                                                {TranslationContext !==
                                                undefined
                                                  ? TranslationContext.label
                                                      .selectslottemplete
                                                  : "Select Slot Template"}
                                              </label>
                                              <select
                                                className="form-control"
                                                name="selectedSlotTemplate"
                                                value={
                                                  this.state.filterSlotTemplate
                                                }
                                                onChange={this.handleFilterSlotTemplateChange.bind(
                                                  this
                                                )}
                                              >
                                                <option hidden value={0}>
                                                  Select
                                                </option>
                                                {this.state
                                                  .onLoadSlotTemplate !==
                                                  null &&
                                                  this.state.onLoadSlotTemplate.map(
                                                    (item, s) => (
                                                      <option
                                                        key={s}
                                                        value={
                                                          item.slotTemplateID
                                                        }
                                                      >
                                                        {item.slotTemplateName}
                                                      </option>
                                                    )
                                                  )}
                                              </select>
                                              <div
                                                style={{
                                                  display: "inline-block",
                                                  float: "right",
                                                }}
                                              ></div>
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </CardBody>
                                </Card>
                              </Collapse>
                              <div
                                className="float-search"
                                style={{ border: "1px solid #ddd", zIndex: 0 }}
                                onClick={this.handleFilterCollapse.bind(this)}
                              >
                                <small>Filter</small>
                                {/* <img
                                  className="search-icon"
                                  src={SearchIcon}
                                  alt="search-icon"
                                /> */}
                              </div>
                              <Table
                                loading={this.state.isSlotLoading}
                                noDataContent="No Record Found"
                                className="components-table-demo-nested antd-table-campaign custom-antd-table"
                                columns={[
                                  {
                                    title:
                                      TranslationContext !== undefined
                                        ? TranslationContext.header.storecode
                                        : "Store Code",
                                    dataIndex: "storeCode",
                                  },
                                  {
                                    title:
                                      TranslationContext !== undefined
                                        ? TranslationContext.header.storetiming
                                        : "Store Timing",
                                    dataIndex: "storeTimimg",
                                  },
                                  {
                                    title:
                                      TranslationContext !== undefined
                                        ? TranslationContext.label
                                            .operationaldays
                                        : "Operational Days",
                                    dataIndex: "operationalDaysCount",
                                    render: (row, item) => {
                                      return (
                                        <Popover
                                          overlayClassName="antcustom operationalbox"
                                          content={
                                            <div>
                                              <img
                                                src={CancelIcon}
                                                alt="cancel-icone"
                                                className="cust-icon2"
                                                onClick={this.handleSlotTableModalClose.bind(
                                                  this,
                                                  "o" + item.slotSettingID
                                                )}
                                              />
                                              <div className="operationaldays">
                                                <div className="row">
                                                  <div className="col-12">
                                                    <h3>
                                                      {TranslationContext !==
                                                      undefined
                                                        ? TranslationContext.h3
                                                            .operationaldays
                                                        : "Operational Days"}
                                                    </h3>
                                                    <ul>
                                                      {item.operationalDays
                                                        .split(",")
                                                        .map((item, i) => (
                                                          <li>
                                                            <label>
                                                              {item}
                                                            </label>
                                                          </li>
                                                        ))}
                                                    </ul>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          }
                                          placement="bottom"
                                          trigger="click"
                                        >
                                          <div className="broadcast-icon">
                                            {item.operationalDaysCount}
                                            <img
                                              id={"o" + item.slotSettingID}
                                              className="info-icon-cp"
                                              src={BlackInfoIcon}
                                              alt="info-icon"
                                            />
                                          </div>
                                        </Popover>
                                      );
                                    },
                                  },
                                  {
                                    title:
                                      TranslationContext !== undefined
                                        ? TranslationContext.label.slottemplate
                                        : "Slot Template",
                                    dataIndex: "slotTemplateName",
                                  },
                                  {
                                    title:
                                      TranslationContext !== undefined
                                        ? TranslationContext.header.totalslot
                                        : "Total Slots",
                                    dataIndex: "totalSlot",
                                    render: (row, item) => {
                                      return (
                                        <Popover
                                          overlayClassName="antcustom appointmentdaysbox"
                                          content={
                                            <div>
                                              <img
                                                src={CancelIcon}
                                                alt="cancel-icone"
                                                className="cust-icon2"
                                                onClick={this.handleSlotTableModalClose.bind(
                                                  this,
                                                  "s" + item.slotSettingID
                                                )}
                                              />
                                              <div className="appointmentdays">
                                                <div className="row">
                                                  <div className="col-12">
                                                    <h3>
                                                      {TranslationContext !==
                                                      undefined
                                                        ? TranslationContext.h3
                                                            .appointmentdays
                                                        : "Appointment Days"}
                                                    </h3>
                                                    <div className="">
                                                      <Table
                                                        dataSource={
                                                          item.templateSlots
                                                        }
                                                        noDataContent="No Record Found"
                                                        pagination={false}
                                                        className="components-table-demo-nested antd-table-campaign custom-antd-table"
                                                        columns={[
                                                          {
                                                            title:
                                                              TranslationContext !==
                                                              undefined
                                                                ? TranslationContext
                                                                    .span.srno
                                                                : "S.No.",
                                                            render: (
                                                              row,
                                                              rowData,
                                                              i
                                                            ) => {
                                                              return (
                                                                <>{i + 1}</>
                                                              );
                                                            },
                                                          },
                                                          {
                                                            title:
                                                              TranslationContext !==
                                                              undefined
                                                                ? TranslationContext
                                                                    .label
                                                                    .slotstarttime
                                                                : "Slot Start Time",
                                                            dataIndex:
                                                              "slotStartTime",
                                                          },
                                                          {
                                                            title:
                                                              TranslationContext !==
                                                              undefined
                                                                ? TranslationContext
                                                                    .label
                                                                    .slotendtime
                                                                : "Slot End Time",
                                                            dataIndex:
                                                              "slotEndTime",
                                                          },
                                                          {
                                                            title:
                                                              TranslationContext !==
                                                              undefined
                                                                ? TranslationContext
                                                                    .label
                                                                    .slotoccupancy
                                                                : "Slot Occupancy",
                                                            dataIndex:
                                                              "slotOccupancy",
                                                          },
                                                          {
                                                            title:
                                                              TranslationContext !==
                                                              undefined
                                                                ? TranslationContext
                                                                    .label
                                                                    .slotstatusunabledisable
                                                                : "Slot Status(Enable/Disable)",
                                                            render: (
                                                              row,
                                                              rowData
                                                            ) => {
                                                              return (
                                                                <>
                                                                  {rowData.isSlotEnabled ===
                                                                  true
                                                                    ? "Enable"
                                                                    : "Disable"}
                                                                </>
                                                              );
                                                            },
                                                          },
                                                        ]}
                                                      ></Table>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          }
                                          placement="bottom"
                                          trigger="click"
                                        >
                                          <div className="broadcast-icon">
                                            {item.templateSlots.length}
                                            <img
                                              className="info-icon-cp"
                                              id={"s" + item.slotSettingID}
                                              src={BlackInfoIcon}
                                              alt="info-icon"
                                            />
                                          </div>
                                        </Popover>
                                      );
                                    },
                                  },
                                  {
                                    title:
                                      TranslationContext !== undefined
                                        ? TranslationContext.header
                                            .appointmentdays
                                        : "Appointment Days",
                                    dataIndex: "appointmentDays",
                                  },
                                  {
                                    title:
                                      TranslationContext !== undefined
                                        ? TranslationContext.label.status
                                        : "Status",
                                    render: (row, item) => {
                                      return (
                                        <div className="tabactive">
                                          {item.status}
                                        </div>
                                      );
                                    },
                                  },
                                  {
                                    title:
                                      TranslationContext !== undefined
                                        ? TranslationContext.header.actions
                                        : "Actions",

                                    render: (row, rowData) => {
                                      var ids = row;
                                      return (
                                        <>
                                          <span>
                                            <img
                                              src={Editpencil}
                                              alt="Edit"
                                              className="del-btn"
                                              // id={p-edit-pop-2}
                                              onClick={this.openSlotEditModal.bind(
                                                this,
                                                rowData.slotSettingID,
                                                rowData.storeId
                                              )}
                                            />
                                            <Popover
                                              content={
                                                <div className="d-flex general-popover popover-body">
                                                  <div className="del-big-icon">
                                                    <img
                                                      src={DelBigIcon}
                                                      alt="del-icon"
                                                    />
                                                  </div>
                                                  <div>
                                                    <p className="font-weight-bold blak-clr">
                                                      {TranslationContext !==
                                                      undefined
                                                        ? TranslationContext.p
                                                            .deleterecord
                                                        : "Delete Record"}
                                                      ?
                                                    </p>
                                                    <p className="mt-1 fs-12">
                                                      {TranslationContext !==
                                                      undefined
                                                        ? TranslationContext.p
                                                            .areyousurewanttodeletethisrecord
                                                        : "Are you sure you want to delete this record?"}
                                                    </p>
                                                    <div className="del-can">
                                                      <a href={Demo.BLANK_LINK}>
                                                        {TranslationContext !==
                                                        undefined
                                                          ? TranslationContext.a
                                                              .cancel
                                                          : "CANCEL"}
                                                      </a>
                                                      <button
                                                        className="butn"
                                                        onClick={() => {
                                                          !this.state
                                                            .isVideoCallAppointmentTab
                                                            ? this.handleGetAppointmentCountOnSlotID(
                                                                rowData.slotSettingID
                                                              )
                                                            : this.handleDeleteVideoTimeSlotMaster(
                                                                rowData.slotSettingID
                                                              );
                                                        }}
                                                      >
                                                        {TranslationContext !==
                                                        undefined
                                                          ? TranslationContext
                                                              .button.delete
                                                          : "Delete"}
                                                      </button>
                                                    </div>
                                                  </div>
                                                </div>
                                              }
                                              placement="bottom"
                                              trigger="click"
                                            >
                                              <img
                                                src={DelBlack}
                                                alt="del-icon"
                                                className="del-btn"
                                                id={ids}
                                              />
                                            </Popover>
                                          </span>
                                        </>
                                      );
                                    },
                                  },
                                ]}
                                rowKey={(record) => {
                                  if (record.slotSettingID) {
                                    uid = uid + 1;
                                    return record.slotSettingID + "i" + uid;
                                  } else {
                                    uid = uid + 1;
                                    return "i" + uid;
                                  }
                                }}
                                pagination={{ defaultPageSize: 10 }}
                                dataSource={this.state.TimeSlotGridData}
                              ></Table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="Module-LanguageSetting-Tab"
                  role="tabpanel"
                  aria-labelledby="Module-LanguageSetting-Tab"
                >
                  <div className="backNone">
                    <div className="row">
                      <div className="col-md-12">
                        <div style={{ background: "white" }}>
                          <div className="row">
                            <div className="col-md-4 m-auto">
                              <div className="right-sect-div">
                                <h3>
                                  {TranslationContext !== undefined
                                    ? TranslationContext.h3.languagesettings
                                    : "LANGUAGE SETTINGS"}
                                </h3>
                                <div className="cmpaign-channel-table slot-setting-options">
                                  <div className="w-100">
                                    <select
                                      name="selectLanguage"
                                      value={this.state.selectLanguage}
                                      onChange={this.handleDrop_downOnchange}
                                    >
                                      <option value={0}>
                                        {TranslationContext !== undefined
                                          ? TranslationContext.option
                                              .selectlanguage
                                          : "Select Language"}
                                      </option>
                                      {this.state.languageData !== null &&
                                        this.state.languageData.map(
                                          (item, s) => (
                                            <option
                                              key={s}
                                              value={item.id}
                                              className="select-category-placeholder"
                                            >
                                              {item.language}
                                            </option>
                                          )
                                        )}
                                    </select>
                                    {parseInt(this.state.selectLanguage) ===
                                      0 && (
                                      <p
                                        style={{
                                          color: "red",
                                          marginBottom: "0px",
                                        }}
                                      >
                                        {this.state.languageValidation}
                                      </p>
                                    )}
                                  </div>
                                </div>
                                <button
                                  className="Schedulenext1 w-100 mb-0 mt-4"
                                  type="button"
                                  onClick={this.handleSubmitLanguageDate.bind(
                                    this
                                  )}
                                  disabled={this.state.isSubmit}
                                >
                                  {this.state.isSubmit ? (
                                    <FontAwesomeIcon
                                      className="circular-loader"
                                      icon={faCircleNotch}
                                      spin
                                    />
                                  ) : null}
                                  {TranslationContext !== undefined
                                    ? TranslationContext.button.submit
                                    : "SUBMIT"}
                                </button>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-12">
                              <Table
                                loading={this.state.loading}
                                noDataContent="No Record Found"
                                className="components-table-demo-nested antd-table-campaign custom-antd-table"
                                columns={[
                                  {
                                    title:
                                      TranslationContext !== undefined
                                        ? TranslationContext.header.languagename
                                        : "Language Name",
                                    dataIndex: "language",
                                  },
                                  {
                                    title:
                                      TranslationContext !== undefined
                                        ? TranslationContext.header.status
                                        : "Status",
                                    dataIndex: "isActive",
                                    render: (row) => {
                                      return (
                                        <>
                                          {row === true ? "Active" : "Inactive"}
                                        </>
                                      );
                                    },
                                  },
                                  {
                                    title:
                                      TranslationContext !== undefined
                                        ? TranslationContext.header.actions
                                        : "Actions",
                                    render: (row, rowData) => {
                                      var ids = rowData["slotId"];

                                      if (rowData.language) {
                                        var langage =
                                          rowData.language.split(" ")[0];
                                        if (
                                          langage.toLowerCase() ==
                                          "English".toLowerCase()
                                        ) {
                                          return <></>;
                                        } else {
                                          return (
                                            <div className="chrdioclr switch switch-primary d-inline m-r-10">
                                              <input
                                                type="checkbox"
                                                id={"lang" + rowData.id}
                                                name="allModules"
                                                checked={row.isActive}
                                                onClick={this.handleDeleteLanguage.bind(
                                                  this,
                                                  rowData
                                                )}
                                              />
                                              <label
                                                htmlFor={"lang" + row.id}
                                                className="cr cr-float-auto"
                                                style={{
                                                  float: "inherit",
                                                }}
                                              ></label>
                                            </div>
                                          );
                                        }
                                      } else {
                                        return <></>;
                                      }
                                    },
                                  },
                                ]}
                                rowKey={(record) => {
                                  if (record.id) {
                                    uid = uid + 1;
                                    return record.id + "l" + uid;
                                  } else {
                                    uid = uid + 1;
                                    return "l" + uid;
                                  }
                                }}
                                pagination={{ defaultPageSize: 10 }}
                                dataSource={this.state.languageGridData}
                              ></Table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="HSM-Setting"
                  role="tabpanel"
                  aria-labelledby="HSM-Setting"
                >
                  <div className="backNone">
                    <div className="row">
                      <div className="col-md-12">
                        <div style={{ background: "white" }}>
                          <div className="row">
                            <div className="col-md-5 m-auto">
                              <div className="right-sect-div">
                                <h3>HSM Settings</h3>
                                <div className="module-switch-cntr">
                                  {this.state.WebBotData.length > 0
                                    ? this.state.WebBotData.map((item, i) => {
                                        return (
                                          <div className="module-switch">
                                            <div className="switch switch-primary">
                                              <label className="storeRole-name-text m-0">
                                                {item.option}
                                              </label>
                                              <input
                                                type="checkbox"
                                                id={"ckWhatCamp2" + item.id}
                                                name="allModules"
                                                checked={item.isActive}
                                                onChange={this.handleHSMOptionChange.bind(
                                                  this,
                                                  item.id
                                                )}
                                              />
                                              <label
                                                htmlFor={
                                                  "ckWhatCamp2" + item.id
                                                }
                                                className="cr cr-float-auto"
                                              ></label>
                                            </div>
                                          </div>
                                        );
                                      })
                                    : null}
                                </div>
                                <button
                                  className="Schedulenext1 w-100 mb-0 mt-4"
                                  type="button"
                                  onClick={this.handleHSMUpdate.bind(this)}
                                >
                                  {this.state.isSubmit ? (
                                    <FontAwesomeIcon
                                      className="circular-loader"
                                      icon={faCircleNotch}
                                      spin
                                    />
                                  ) : null}
                                  {TranslationContext !== undefined
                                    ? TranslationContext.button.update
                                    : "UPDATE"}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="chooseslot-box tab-pane fade"
                  id=""
                  role="tabpanel"
                  aria-labelledby="Videocall-Appointment"
                >
                  {/* <div className="row">
                    <div className="col-12 col-md-10">
                      <h3>
                        {TranslationContext !== undefined
                          ? TranslationContext.h3.chooseslottype
                          : "Choose Slot Type"}
                      </h3>
                    </div>
                  </div> */}
                  {/* <div className="row">
                    <div className="col-12 col-md-10">
                      <div className="statuscheckbox">
                        <Radio.Group
                          onChange={this.handleSlotRadioChange}
                          value={this.state.slotAutomaticRadio}
                        >
                          <Radio value={1}>
                            {TranslationContext !== undefined
                              ? TranslationContext.a.automatic
                              : "Automatic"}
                          </Radio>
                          <Radio value={2}>
                            {TranslationContext !== undefined
                              ? TranslationContext.a.manual
                              : "Manual"}
                          </Radio>
                        </Radio.Group>
                      </div>
                    </div>
                  </div> */}
                  <div
                    classname="automaticbox"
                    style={{
                      display:
                        this.state.slotAutomaticRadio === 1 ? "block" : "none",
                    }}
                  >
                    <div className="row">
                      <div className="col-12 col-md-10">
                        <label>
                          {TranslationContext !== undefined
                            ? TranslationContext.label.templatename
                            : "Template Name"}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder={
                            TranslationContext !== undefined
                              ? TranslationContext.ticketingDashboard
                                  .entertemplatename
                              : "Enter Template Name"
                          }
                          autoComplete="off"
                          maxLength={200}
                          name="templateName"
                          value={
                            this.state.videoCallAppointmentData.templateName
                          }
                          onChange={this.handleVideoCallInputChange}
                        />
                        {/* {this.state.template === "" && (
                          <p style={{ color: "red", marginBottom: "0px" }}>
                            {this.state.autoTempNameCompulsory}
                          </p>
                        )} */}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 col-md-5">
                        <label>
                          {TranslationContext !== undefined
                            ? TranslationContext.label.slotduration
                            : "Slot Duration"}
                        </label>
                        <select
                          name="slotDuration"
                          className="form-control"
                          value={
                            this.state.videoCallAppointmentData.slotDuration
                          }
                          onChange={this.handleVideoCallInputChange}
                        >
                          <option value={0}>
                            {TranslationContext !== undefined
                              ? TranslationContext.option.selecttiming
                              : "Select Timing"}
                          </option>
                          <option value={0.25}>
                            15
                            {TranslationContext !== undefined
                              ? TranslationContext.option.minutes
                              : "Minutes"}
                          </option>
                          <option value={0.5}>
                            30
                            {TranslationContext !== undefined
                              ? TranslationContext.option.minutes
                              : "Minutes"}
                          </option>
                          <option value={0.75}>
                            45
                            {TranslationContext !== undefined
                              ? TranslationContext.option.minutes
                              : "Minutes"}
                          </option>
                          <option value={1}>
                            1
                            {TranslationContext !== undefined
                              ? TranslationContext.option.hour
                              : "Hour"}
                          </option>
                          <option value={1.5}>
                            1.5
                            {TranslationContext !== undefined
                              ? TranslationContext.option.hour
                              : "Hour"}
                          </option>
                          <option value={2}>
                            2
                            {TranslationContext !== undefined
                              ? TranslationContext.option.hours
                              : "Hours"}
                          </option>
                        </select>
                        {/* {this.state.AutoSlotDuration === 0 && (
                          <p style={{ color: "red", marginBottom: "0px" }}>
                            {this.state.AutoSlotDurationCompulsory}
                          </p>
                        )} */}
                      </div>
                      <div className="col-12 col-md-5">
                        <label>
                          {TranslationContext !== undefined
                            ? TranslationContext.label.gapslots
                            : "Gap B/w Slots"}
                        </label>
                        <select
                          name="gapSlot"
                          className="form-control"
                          value={this.state.videoCallAppointmentData.gapSlot}
                          onChange={this.handleVideoCallInputChange}
                        >
                          <option value={0}>
                            {TranslationContext !== undefined
                              ? TranslationContext.option.selecttiming
                              : "Select Timing"}
                          </option>
                          <option value={0.25}>
                            15
                            {TranslationContext !== undefined
                              ? TranslationContext.option.minutes
                              : "Minutes"}
                          </option>
                          <option value={0.5}>
                            30
                            {TranslationContext !== undefined
                              ? TranslationContext.option.minutes
                              : "Minutes"}
                          </option>
                          <option value={0.75}>
                            45
                            {TranslationContext !== undefined
                              ? TranslationContext.option.minutes
                              : "Minutes"}
                          </option>
                          <option value={1}>
                            1
                            {TranslationContext !== undefined
                              ? TranslationContext.option.hour
                              : "Hour"}
                          </option>
                          <option value={1.5}>
                            1.5
                            {TranslationContext !== undefined
                              ? TranslationContext.option.hour
                              : "Hour"}
                          </option>
                          <option value={2}>
                            2
                            {TranslationContext !== undefined
                              ? TranslationContext.option.hours
                              : "Hours"}
                          </option>
                        </select>
                        {/* {this.state.AutoSlotGap === 0 && (
                          <p style={{ color: "red", marginBottom: "0px" }}>
                            {this.state.AutoSlotGapCompulsory}
                          </p>
                        )} */}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 col-md-10">
                        <h3>
                          {TranslationContext !== undefined
                            ? TranslationContext.h3.storetiming
                            : "Store Timing"}
                        </h3>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 col-md-5 slotFrm">
                        <label>
                          {TranslationContext !== undefined
                            ? TranslationContext.label.from
                            : "From"}
                        </label>
                        <DatePicker
                          customInput={<CustomInput />}
                          selected={
                            this.state.videoCallAppointmentData.storeTimingFrom
                          }
                          showTimeSelect
                          showTimeSelectOnly
                          name="storeTimingFrom"
                          timeIntervals={15}
                          timeCaption="Time"
                          dateFormat="hh:mm aa"
                          placeholderText={
                            TranslationContext !== undefined
                              ? TranslationContext.option.selecttiming
                              : "Select Timing"
                          }
                          className="form-control"
                          onChange={(time) =>
                            this.setState({
                              videoCallAppointmentData: {
                                ...this.state.videoCallAppointmentData,
                                storeTimingFrom: time,
                              },
                            })
                          }
                        />
                        {/* {this.state.autoStoreFrom === "" && (
                          <p style={{ color: "red", marginBottom: "0px" }}>
                            {this.state.autoStoreFromCompulsory}
                          </p>
                        )} */}
                      </div>
                      <div className="col-12 col-md-5 slotFrm">
                        <label>
                          {TranslationContext !== undefined
                            ? TranslationContext.label.to
                            : "To"}
                        </label>
                        <DatePicker
                          customInput={<CustomInput />}
                          selected={
                            this.state.videoCallAppointmentData.storeTimingTo
                          }
                          name="storeTimingTo"
                          showTimeSelect
                          showTimeSelectOnly
                          timeIntervals={15}
                          timeCaption="Time"
                          dateFormat="h:mm aa"
                          placeholderText={
                            TranslationContext !== undefined
                              ? TranslationContext.option.selecttiming
                              : "Select Timing"
                          }
                          className="form-control"
                          onChange={(time) =>
                            this.setState({
                              videoCallAppointmentData: {
                                ...this.state.videoCallAppointmentData,
                                storeTimingTo: time,
                              },
                            })
                          }
                        />
                        {/* {this.state.autoStoreTo === "" && (
                          <p style={{ color: "red", marginBottom: "0px" }}>
                            {this.state.autoStoreToCompulsory}
                          </p>
                        )} */}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 col-md-10">
                        <h3>
                          {TranslationContext !== undefined
                            ? TranslationContext.h3.nonoperationalhour
                            : "Non Operational Hour"}
                        </h3>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 col-md-5 slotFrm">
                        <label>
                          {TranslationContext !== undefined
                            ? TranslationContext.label.from
                            : "From"}
                        </label>
                        <DatePicker
                          customInput={<CustomInput />}
                          selected={
                            this.state.videoCallAppointmentData
                              .nonOperationalHourFrom
                          }
                          name="nonOperationalHourFrom"
                          showTimeSelect
                          showTimeSelectOnly
                          timeIntervals={15}
                          timeCaption="Time"
                          dateFormat="h:mm aa"
                          placeholderText={
                            TranslationContext !== undefined
                              ? TranslationContext.option.selecttiming
                              : "Select Timing"
                          }
                          className="form-control"
                          onChange={(time) =>
                            this.setState({
                              videoCallAppointmentData: {
                                ...this.state.videoCallAppointmentData,
                                nonOperationalHourFrom: time,
                              },
                            })
                          }
                          minTime={setHours(
                            setMinutes(new Date(), 0),
                            new Date(
                              this.state.videoCallAppointmentData.storeTimingFrom
                            ).getHours()
                          )}
                          maxTime={setHours(
                            setMinutes(new Date(), 0),
                            new Date(
                              this.state.videoCallAppointmentData.storeTimingTo
                            ).getHours()
                          )}
                        />
                        {/* {this.state.autoNonOptFrom === "" && (
                          <p style={{ color: "red", marginBottom: "0px" }}>
                            {this.state.autoNonOptFromCompulsory}
                          </p>
                        )} */}
                      </div>
                      <div className="col-12 col-md-5 slotFrm">
                        <label>
                          {TranslationContext !== undefined
                            ? TranslationContext.label.to
                            : "To"}
                        </label>
                        <DatePicker
                          customInput={<CustomInput />}
                          selected={
                            this.state.videoCallAppointmentData
                              .nonOperationalHourTo
                          }
                          name="nonOperationalHourTo"
                          showTimeSelect
                          showTimeSelectOnly
                          timeIntervals={15}
                          timeCaption="Time"
                          dateFormat="h:mm aa"
                          placeholderText={
                            TranslationContext !== undefined
                              ? TranslationContext.option.selecttiming
                              : "Select Timing"
                          }
                          className="form-control"
                          onChange={(time) =>
                            this.setState({
                              videoCallAppointmentData: {
                                ...this.state.videoCallAppointmentData,
                                nonOperationalHourTo: time,
                              },
                            })
                          }
                          minTime={setHours(
                            setMinutes(
                              new Date(),
                              this.state.videoCallAppointmentData
                                .nonOperationalHourFrom !== ""
                                ? new Date(
                                    this.state.videoCallAppointmentData.nonOperationalHourFrom
                                  ).getMinutes() +
                                    15 ==
                                  60
                                  ? 0
                                  : new Date(
                                      this.state.videoCallAppointmentData.nonOperationalHourFrom
                                    ).getMinutes() + 15
                                : 0
                            ),
                            new Date(
                              this.state.videoCallAppointmentData
                                .nonOperationalHourFrom !== ""
                                ? new Date(
                                    new Date(
                                      this.state.videoCallAppointmentData.nonOperationalHourFrom
                                    ).setMinutes(
                                      new Date(
                                        this.state.videoCallAppointmentData.nonOperationalHourFrom
                                      ).getMinutes() + 15
                                    )
                                  )
                                : this.state.videoCallAppointmentData
                                    .storeTimingFrom
                            ).getHours()
                          )}
                          maxTime={setHours(
                            setMinutes(new Date(), 0),
                            new Date(
                              this.state.videoCallAppointmentData.storeTimingTo
                            ).getHours()
                          )}
                        />
                        {/* {this.state.autoNonOptTo === "" && (
                          <p style={{ color: "red", marginBottom: "0px" }}>
                            {this.state.autoNonOptToCompulsory}
                          </p>
                        )} */}
                      </div>
                      <div className="col-12 col-md-2">
                        <button
                          className="tabbutn cr-pnt"
                          onClick={this.handleSubmitAutomaticData.bind(this)}
                          disabled={this.state.isGenrateSlotLoading}
                        >
                          {TranslationContext !== undefined
                            ? TranslationContext.button.generateslot
                            : "Generate Slot"}
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-md-10">
                      <div className="chooseslot-table">
                        <Table
                          dataSource={this.state.automaticaSlotTblData}
                          noDataContent="No Record Found"
                          pagination={false}
                          loading={this.state.isGenrateSlotLoading}
                          className="components-table-demo-nested antd-table-campaign custom-antd-table"
                          columns={[
                            {
                              title:
                                TranslationContext !== undefined
                                  ? TranslationContext.span.srno
                                  : "S.No.",
                              render: (row, rowData, i) => {
                                return <>{i + 1}</>;
                              },
                            },
                            {
                              title:
                                TranslationContext !== undefined
                                  ? TranslationContext.label.slotstarttime
                                  : "Slot Start Time",
                              dataIndex: "slotStartTime",
                            },
                            {
                              title:
                                TranslationContext !== undefined
                                  ? TranslationContext.label.slotendtime
                                  : "Slot End Time",
                              dataIndex: "slotEndTime",
                            },
                          ]}
                        ></Table>
                      </div>
                    </div>
                  </div>

                  <div
                    classname="manualbox"
                    style={{
                      display:
                        this.state.slotAutomaticRadio === 2 ? "block" : "none",
                    }}
                  >
                    <div className="row">
                      <div className="col-12 col-md-10">
                        <label>
                          {TranslationContext !== undefined
                            ? TranslationContext.label.templatename
                            : "Template Name"}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Template Name"
                          autoComplete="off"
                          maxLength={200}
                          name="manualTempName"
                          value={this.state.manualTempName}
                          onChange={this.handleInputOnchange}
                        />
                        {this.state.manualTempName === "" && (
                          <p style={{ color: "red", marginBottom: "0px" }}>
                            {this.state.manualTempNameCompulsory}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 col-md-10">
                        <h3>
                          {TranslationContext !== undefined
                            ? TranslationContext.h3.storetiming
                            : "Store Timing"}
                        </h3>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 col-md-5 slotFrm">
                        <label>
                          {TranslationContext !== undefined
                            ? TranslationContext.label.from
                            : "From"}
                        </label>
                        <DatePicker
                          customInput={<CustomInput />}
                          selected={this.state.manualStoreFrom}
                          showTimeSelect
                          showTimeSelectOnly
                          timeIntervals={30}
                          timeCaption="Time"
                          dateFormat="hh:mm a"
                          className="form-control"
                          onChange={(time) =>
                            this.setState({
                              manualStoreFrom: time,
                            })
                          }
                        />
                        {this.state.manualStoreFrom === "" && (
                          <p style={{ color: "red", marginBottom: "0px" }}>
                            {this.state.manualStoreFromCompulsory}
                          </p>
                        )}
                      </div>
                      <div className="col-12 col-md-5 slotFrm">
                        <label>To</label>
                        <DatePicker
                          customInput={<CustomInput />}
                          selected={this.state.manualStoreTo}
                          showTimeSelect
                          showTimeSelectOnly
                          timeIntervals={30}
                          timeCaption="Time"
                          dateFormat="hh:mm a"
                          placeholderText={
                            TranslationContext !== undefined
                              ? TranslationContext.option.selecttiming
                              : "Select Timing"
                          }
                          className="form-control"
                          onChange={(time) =>
                            this.handleSelectManualStoreTodate(time)
                          }
                        />
                        {this.state.manualStoreTo === "" && (
                          <p style={{ color: "red", marginBottom: "0px" }}>
                            {this.state.manualStoreToCompulsory}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 col-md-10">
                        <label>
                          {TranslationContext !== undefined
                            ? TranslationContext.label.slotduration
                            : "Slot Duration"}
                        </label>
                        <select
                          name="ManualSlotDuration"
                          className="form-control"
                          value={this.state.ManualSlotDuration}
                          onChange={this.handleManualSelectDuration}
                        >
                          <option value={0}>
                            {TranslationContext !== undefined
                              ? TranslationContext.option.selecttiming
                              : "Select Timing"}
                          </option>
                          <option value={15}>
                            15
                            {TranslationContext !== undefined
                              ? TranslationContext.option.minutes
                              : "Minutes"}
                          </option>
                          <option value={30}>
                            30
                            {TranslationContext !== undefined
                              ? TranslationContext.option.minutes
                              : "Minutes"}
                          </option>
                          <option value={45}>
                            45
                            {TranslationContext !== undefined
                              ? TranslationContext.option.minutes
                              : "Minutes"}
                          </option>
                          <option value={60}>
                            1
                            {TranslationContext !== undefined
                              ? TranslationContext.option.hour
                              : "Hour"}
                          </option>
                          <option value={90}>
                            1.5
                            {TranslationContext !== undefined
                              ? TranslationContext.option.hour
                              : "Hour"}
                          </option>
                          <option value={120}>
                            2
                            {TranslationContext !== undefined
                              ? TranslationContext.option.hours
                              : "Hours"}
                          </option>
                        </select>
                        {this.state.ManualSlotDuration === 0 && (
                          <p style={{ color: "red", marginBottom: "0px" }}>
                            {this.state.ManualSlotDurationCompulsory}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 col-md-5 slotFrm">
                        <label>
                          {TranslationContext !== undefined
                            ? TranslationContext.label.slotstarttime
                            : "Slot Start Time"}
                        </label>
                        <DatePicker
                          customInput={<CustomInput />}
                          selected={this.state.manualStoreData.slotStartTime}
                          showTimeSelect
                          showTimeSelectOnly
                          timeIntervals={15}
                          // timeIntervals={Number(this.state.ManualSlotDuration)}
                          timeCaption="Time"
                          dateFormat="hh:mm a"
                          placeholderText={
                            TranslationContext !== undefined
                              ? TranslationContext.option.selecttiming
                              : "Select Timing"
                          }
                          className="form-control"
                          onChange={(time) =>
                            this.handleManualTimeOnchange(time, "slotStartTime")
                          }
                          minTime={setHours(
                            setMinutes(new Date(), 0),
                            new Date(this.state.manualStoreFrom).getHours()
                          )}
                          maxTime={setHours(
                            setMinutes(new Date(), 0),
                            new Date(this.state.manualStoreTo).getHours()
                          )}
                          excludeTimes={excludeTimes}
                        />
                        {this.state.manualStoreData.slotStartTime === "" && (
                          <p style={{ color: "red", marginBottom: "0px" }}>
                            {this.state.manualSlotStartCompulsory}
                          </p>
                        )}
                      </div>
                      <div className="col-12 col-md-5 slotFrm">
                        <label>
                          {TranslationContext !== undefined
                            ? TranslationContext.label.slotendtime
                            : "Slot End Time"}
                        </label>
                        <DatePicker
                          // selected={this.state.manualStoreData.slotEndTime}
                          showTimeSelect
                          showTimeSelectOnly
                          timeIntervals={15}
                          timeCaption="Time"
                          dateFormat="hh:mm a"
                          placeholderText={
                            TranslationContext !== undefined
                              ? TranslationContext.option.selecttiming
                              : "Select Timing"
                          }
                          className="form-control"
                          disabled
                          onChange={(time) =>
                            this.handleManualTimeOnchange(time, "slotEndTime")
                          }
                        />
                        {/* {this.state.slotEndTime === "" && (
                        <p style={{ color: "red", marginBottom: "0px" }}>
                          {this.state.manualSlotEndCompulsory}
                        </p>
                      )} */}
                      </div>
                      <div className="col-12 col-md-2">
                        <button
                          className="tabbutn cr-pnt"
                          onClick={this.handleAddManualySlot.bind(this)}
                        >
                          {TranslationContext !== undefined
                            ? TranslationContext.button.addslot
                            : "Add Slot"}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="chooseslot-box tab-pane fade"
                  id="Videocall-Slot-Settings"
                  role="tabpanel"
                  aria-labelledby="Videocall-Slot-Settings"
                >
                  <h6 style={{ fontWeight: "bold" }}>
                    Video Call Slot Booking
                  </h6>
                  <div className="row my-4">
                    <div className="col-12 col-md-6">
                      <div
                        className="video-call-slot-booking"
                        style={{
                          display: "grid",
                          gridTemplateColumns: "auto auto",
                          gridRowGap: "40px",
                        }}
                      >
                        <p style={{ fontWeight: "bold" }}>
                          Cancel Video Call Message Template{" "}
                        </p>
                        <div>
                          <textarea
                            id=""
                            name="cancelVideoCallTemplate"
                            value={
                              this.state.videoCallSlotSetttngData
                                .cancelVideoCallTemplate
                            }
                            onChange={
                              this.handleVideoCallSlotSettingInputChange
                            }
                            cols="30"
                            rows="4"
                            style={{
                              resize: "none",
                              border: "1px solid #bbb",

                              borderRadius: "2px",
                            }}
                            placeholder="Enter Template"
                          ></textarea>
                        </div>
                        <p style={{ fontWeight: "bold" }}>
                          Successful Slot Booking Template
                        </p>
                        <div>
                          <textarea
                            id=""
                            name="successBookTemplate"
                            value={
                              this.state.videoCallSlotSetttngData
                                .successBookTemplate
                            }
                            onChange={
                              this.handleVideoCallSlotSettingInputChange
                            }
                            cols="30"
                            rows="4"
                            style={{
                              resize: "none",
                              border: "1px solid #bbb",

                              borderRadius: "2px",
                            }}
                            placeholder="Enter Template"
                          ></textarea>
                        </div>
                        <p style={{ fontWeight: "bold" }}>Sent Payment Link</p>
                        <div>
                          <div className="module-switch">
                            <div className="switch switch-primary d-flex  align-items-center">
                              <h6 style={{ fontWeight: "bold" }}>No</h6>

                              <input
                                type="checkbox"
                                id="paymentLink"
                                name="allModules"
                                checked={
                                  this.state.videoCallSlotSetttngData
                                    .sentPaymentLinkFlag
                                }
                                onChange={this.handlePaymentLinkSwitch.bind(
                                  this
                                )}
                              />

                              <label
                                style={{ margin: "0" }}
                                htmlFor="paymentLink"
                                className="cr cr-float-auto"
                              ></label>

                              <h6
                                style={{
                                  fontWeight: "bold",
                                  marginLeft: "1rem",
                                  marginRight: "12rem",
                                }}
                              >
                                Yes
                              </h6>
                            </div>
                          </div>
                        </div>
                        <p style={{ fontWeight: "bold" }}>
                          Payment Link Template
                        </p>
                        <div>
                          <textarea
                            id=""
                            cols="30"
                            rows="4"
                            value={
                              this.state.videoCallSlotSetttngData
                                .paymentLinkTemplate
                            }
                            name="paymentLinkTemplate"
                            onChange={
                              this.handleVideoCallSlotSettingInputChange
                            }
                            style={{
                              resize: "none",
                              border: "1px solid #bbb",

                              borderRadius: "2px",
                            }}
                            placeholder="Enter Template"
                          ></textarea>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <div
                        className="video-call-slot-booking"
                        style={{
                          display: "grid",
                          gridTemplateColumns: "auto auto",
                          gridRowGap: "40px",
                        }}
                      >
                        {/* <p style={{ fontWeight: "bold" }}>
                          Notification Sent To
                        </p>
                        <div>
                          <SelectAntd
                            style={{ width: 150 }}
                            name="notificationSentTo"
                            onChange={(name) =>
                              this.setState({
                                videoCallSlotSetttngData: {
                                  ...this.state.videoCallSlotSetttngData,
                                  notificationSentTo: name,
                                },
                              })
                            }
                            placeholder="Default User"
                          >
                            <Option value="">Select Name</Option>
                            {this.state.defaultStaffList.map((staff) => {
                              return (
                                <Option
                                  key={staff.storeManagerID}
                                  value={staff.storeManagerID}
                                >
                                  {staff.agentName}
                                </Option>
                              );
                            })}
                          </SelectAntd>
                          <i
                            className="fas fa-plus-circle ml-2"
                            style={{ color: "purple", fontSize: "1rem" }}
                          ></i>
                        </div>
                        */}
                        <p style={{ fontWeight: "bold" }}>
                          Reminder Notification Template
                        </p>
                        <div>
                          <textarea
                            id=""
                            cols="30"
                            rows="4"
                            name="reminderNotificationTemplate"
                            value={
                              this.state.videoCallSlotSetttngData
                                .reminderNotificationTemplate
                            }
                            onChange={
                              this.handleVideoCallSlotSettingInputChange
                            }
                            style={{
                              resize: "none",
                              border: "1px solid #bbb",

                              borderRadius: "2px",
                            }}
                            placeholder="Enter Template"
                          ></textarea>
                        </div>
                        <p style={{ fontWeight: "bold" }}>
                          Reminder Notification to customer
                        </p>
                        <div className="d-flex justify-content-between">
                          <input
                            style={{
                              resize: "none",
                              width: "50px",
                              border: "1px solid #bbb",

                              borderRadius: "2px",
                            }}
                            value={
                              this.state.videoCallSlotSetttngData
                                .reminderForCustomerTime
                            }
                            name="reminderForCustomerTime"
                            onChange={
                              this.handleVideoCallSlotSettingInputChange
                            }
                          />
                          <SelectAntd
                            defaultValue="Min"
                            value={
                              this.state.videoCallSlotSetttngData
                                .reminderForCustomerDuration
                            }
                            style={{ width: 100 }}
                            onChange={(unit) => {
                              this.setState({
                                videoCallSlotSetttngData: {
                                  ...this.state.videoCallSlotSetttngData,
                                  reminderForCustomerDuration: unit,
                                },
                              });
                            }}
                          >
                            <Option value="M">Min</Option>
                            <Option value="H">Hour</Option>
                            <Option value="D">Day</Option>
                          </SelectAntd>
                          <div className="module-switch">
                            <div className="switch switch-primary d-flex  align-items-center">
                              <input
                                type="checkbox"
                                id="customerNotification"
                                name="allModules"
                                checked={
                                  this.state.videoCallSlotSetttngData
                                    .reminderForCustomerFlag
                                }
                                onChange={this.handleCustomerNotificationSwitch.bind(
                                  this
                                )}
                              />

                              <label
                                style={{ margin: "0" }}
                                htmlFor="customerNotification"
                                className="cr cr-float-auto"
                              ></label>

                              <h6
                                style={{
                                  fontWeight: "bold",
                                  marginLeft: "1rem",
                                }}
                              >
                                Yes
                              </h6>
                            </div>
                          </div>
                        </div>
                        <p style={{ fontWeight: "bold" }}>
                          Reminder Notification to Store Staff
                        </p>
                        <div className="d-flex justify-content-between">
                          <input
                            style={{
                              resize: "none",
                              width: "50px",
                              border: "1px solid #bbb",

                              borderRadius: "2px",
                            }}
                            value={
                              this.state.videoCallSlotSetttngData
                                .reminderForStoreStaffTime
                            }
                            name="reminderForStoreStaffTime"
                            onChange={
                              this.handleVideoCallSlotSettingInputChange
                            }
                          />
                          <SelectAntd
                            defaultValue="Min"
                            style={{ width: 100 }}
                            value={
                              this.state.videoCallSlotSetttngData
                                .reminderForStoreStaffDuration
                            }
                            onChange={(unit) => {
                              this.setState({
                                videoCallSlotSetttngData: {
                                  ...this.state.videoCallSlotSetttngData,
                                  reminderForStoreStaffDuration: unit,
                                },
                              });
                            }}
                          >
                            <Option value="M">Min</Option>
                            <Option value="H">Hour</Option>
                            <Option value="D">Day</Option>
                          </SelectAntd>
                          <div className="module-switch">
                            <div className="switch switch-primary d-flex  align-items-center">
                              <input
                                type="checkbox"
                                id="staffNotification"
                                name="allModules"
                                checked={
                                  this.state.videoCallSlotSetttngData
                                    .reminderForStoreStaffFlag
                                }
                                onChange={this.handleStoreStaffNotificationSwitchSwitch.bind(
                                  this
                                )}
                              />

                              <label
                                style={{ margin: "0" }}
                                htmlFor="staffNotification"
                                className="cr cr-float-auto"
                              ></label>

                              <h6
                                style={{
                                  fontWeight: "bold",
                                  marginLeft: "1rem",
                                }}
                              >
                                Yes
                              </h6>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12 col-md-12">
                          <div className="del-can">
                            <button
                              disabled={this.state.isCreateTampleteLoading}
                              className="butn"
                              onClick={() => {
                                this.handleUpdateVideoAppointmentConfiguration();
                              }}
                            >
                              <label className="sltsave-btn">
                                {TranslationContext !== undefined
                                  ? TranslationContext.button.save
                                  : "Save "}
                              </label>

                              {this.state.isCreateTampleteLoading ? (
                                <FontAwesomeIcon
                                  className="circular-loader"
                                  icon={faCircleNotch}
                                  spin
                                />
                              ) : null}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* edit slot starts */}
            <Modal
              show={this.state.editSlotModal}
              dialogClassName="slotEditModal"
              onHide={this.closeSlotEditModal.bind(this)}
            >
              <img
                src={CancelIcon}
                alt="cancel-icone"
                className="cust-icon2"
                onClick={this.closeSlotEditModal.bind(this)}
              />
              <div className="edtpadding">
                <div className="">
                  <label className="popover-header-text">
                    {TranslationContext !== undefined
                      ? TranslationContext.label.editslotsettings
                      : "Edit"}
                  </label>
                </div>
                <div className="pop-over-div edit-slot">
                  <div className="row">
                    <div className="col-12 col-md-6">
                      <ul>
                        <li>
                          <label>
                            {TranslationContext !== undefined
                              ? TranslationContext.label.storecode
                              : "Store Code"}
                          </label>
                          <span>{this.state.editstoreCode}</span>
                        </li>
                        <li>
                          <label>
                            {TranslationContext !== undefined
                              ? TranslationContext.label.storetiming
                              : "Store Timing"}
                          </label>
                          <span>{this.state.storeTimimg}</span>
                        </li>
                        <li>
                          <label>
                            {TranslationContext !== undefined
                              ? TranslationContext.label.storeoperationaldays
                              : "Store Operational Days"}
                          </label>
                          <span>{this.state.editOperationalDays}</span>
                        </li>
                      </ul>
                    </div>
                    <div className="col-12 col-md-6">
                      <ul className="edit-slot-right">
                        <li className="d-flex">
                          <label className="mt-1">
                            {TranslationContext !== undefined
                              ? TranslationContext.label.slottemplate
                              : "Slot Template"}
                          </label>
                          {/* <span>{this.state.editSlotTemplateName}</span> */}
                          <select
                            className="form-control edit-slot-temp"
                            // name="selectedSlotTemplate"
                            value={this.state.editSlotTemplateId}
                            onChange={this.handleEditSlotTemplateChange.bind(
                              this
                            )}
                          >
                            {/* <option value={0}>Select</option> */}
                            {this.state.isVideoCallAppointmentTab
                              ? this.state.videoSlotTemplateData !== null &&
                                this.state.videoSlotTemplateData.map(
                                  (item, s) => (
                                    <option key={s} value={item.slotTemplateID}>
                                      {item.slotTemplateName}
                                    </option>
                                  )
                                )
                              : this.state.slotTemplateData !== null &&
                                this.state.slotTemplateData.map((item, s) => (
                                  <option key={s} value={item.slotTemplateID}>
                                    {item.slotTemplateName}
                                  </option>
                                ))}
                          </select>
                        </li>
                        <li>
                          <label>
                            {TranslationContext !== undefined
                              ? TranslationContext.label.totalslots
                              : "Total Slots"}
                          </label>
                          <span>{this.state.editTotalSlot}</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <div className="edittabs">
                        <Table
                          dataSource={this.state.editSlotTemplateGridData}
                          noDataContent="No Record Found"
                          pagination={false}
                          className="components-table-demo-nested antd-table-campaign custom-antd-table"
                          columns={[
                            {
                              title:
                                TranslationContext !== undefined
                                  ? TranslationContext.span.srno
                                  : "S.No.",
                              render: (row, rowData, i) => {
                                return <>{i + 1}</>;
                              },
                            },
                            {
                              title:
                                TranslationContext !== undefined
                                  ? TranslationContext.label.slotstarttime
                                  : "Slot Start Time",
                              dataIndex: "slotStartTime",
                            },
                            {
                              title:
                                TranslationContext !== undefined
                                  ? TranslationContext.label.slotendtime
                                  : "Slot End Time",
                              dataIndex: "slotEndTime",
                            },
                            {
                              hidden: true,
                              title:
                                TranslationContext !== undefined
                                  ? TranslationContext.label.slotoccupancy
                                  : "Slot Occupancy",
                              dataIndex: "slotOccupancy",
                              render: (row, rowData, i) => {
                                return (
                                  <>
                                    <input
                                      type="text"
                                      className="form-control value"
                                      name="slotOccupancy"
                                      disabled={
                                        this.state.videoCallAppointmentData
                                          .isMultipleTimeSlot
                                          ? false
                                          : true
                                      }
                                      value={rowData.slotOccupancy}
                                      onChange={this.handleEnableDisableOnChange.bind(
                                        this,
                                        i
                                      )}
                                    />
                                  </>
                                );
                              },
                            },
                            {
                              hidden: this.state.isVideoCallAppointmentTab
                                ? false
                                : true,
                              title: "Store Staff Name",
                              render: (row, rowData) => {
                                return (
                                  <div className="right-sect-div">
                                    <select
                                      onChange={this.handleEditStaffNameChange.bind(
                                        this,
                                        rowData
                                      )}
                                      value={rowData.storeStaffNameID}
                                    >
                                      {/* {this.state.storeAgentList.length > 1 && ( */}
                                        <option value="">
                                          Select Staff Name
                                        </option>
                                      {/* )} */}

                                      {this.state.storeAgentList.map(
                                        (agent) => {
                                          return (
                                            <option value={agent.agentID}>
                                              {agent.userName}
                                            </option>
                                          );
                                        }
                                      )}
                                    </select>
                                  </div>
                                );
                              },
                            },
                            {
                              title:
                                TranslationContext !== undefined
                                  ? TranslationContext.label
                                      .slotstatusunabledisable
                                  : "Slot Status(Enable/Disable)",
                              render: (row, rowData, i) => {
                                return (
                                  <div className="module-switch">
                                    <div className="switch switch-primary">
                                      <input
                                        type="checkbox"
                                        id={"ckStatus" + i}
                                        name="isSlotEnabled"
                                        checked={rowData.isSlotEnabled}
                                        value={rowData.isSlotEnabled}
                                        onChange={this.handleEnableDisableOnChange.bind(
                                          this,
                                          i
                                        )}
                                      />
                                      <label
                                        htmlFor={"ckStatus" + i}
                                        className="cr cr-float-auto"
                                      ></label>
                                    </div>
                                  </div>
                                );
                              },
                            },
                            {
                              title: "Action",
                              hidden: this.state.isVideoCallAppointmentTab
                                ? false
                                : true,
                              render: (row, rowData, i) => {
                                return (
                                  <img
                                    src={DelBlack}
                                    style={{ width: "15px" }}
                                    alt="DelBlack"
                                    onClick={() =>
                                      this.handleRemoveEditVideoSlot(i)
                                    }
                                  ></img>
                                );
                              },
                            },
                          ].filter((item) => !item.hidden)}
                        ></Table>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-md-6">
                      <label>
                        {TranslationContext !== undefined
                          ? TranslationContext.label.slotdays
                          : "Slot days need to display"}
                      </label>
                      <select
                        name="editAppointmentDays"
                        className="form-control"
                        value={this.state.editAppointmentDays}
                        onChange={this.handleSlotOnChange}
                        style={{ padding: 0, paddingLeft: "15px" }}
                      >
                        <option value={0}>Select</option>
                        {Array(31)
                          .fill(0)
                          .map((e, i) => {
                            return <option value={i + 1}>{i + 1}</option>;
                          })}
                      </select>
                      {this.state.isEditSlotDaysDisplay ? (
                        <p
                          className="non-deliverable"
                          style={{
                            marginTop: "0",
                            textAlign: "left",
                          }}
                        >
                          {this.state.isEditSlotDaysDisplay}
                        </p>
                      ) : null}
                    </div>
                    {this.state.isVideoCallAppointmentTab ? (
                      <div className="col-12 col-md-6">
                        <label>Defualt User Notification</label>
                        <div className="selectstores-box oper-days-edit selectOPRFilterModal">
                          <Select
                            className="selectOPRFilterModal select-oper"
                            getOptionLabel={(option) => option.agentName}
                            getOptionValue={(option) => option.agentID}
                            options={this.state.storeAgentList}
                            value={this.state.notificationToAgent}
                            onChange={this.handleNotificationToChange.bind(
                              this
                            )}
                            placeholder={"Select"}
                            closeMenuOnSelect={false}
                            isMulti
                          />
                        </div>
                      </div>
                    ) : null}
                    <div className="col-12 col-md-6">
                      <label>
                        {TranslationContext !== undefined
                          ? TranslationContext.label.status
                          : "Status"}
                      </label>
                      <div className="statuscheckbox">
                        <Radio.Group
                          onChange={this.handleSlotOnChange}
                          name="editSlotStatus"
                          value={this.state.editSlotStatus}
                        >
                          <Radio value="Active">
                            {TranslationContext !== undefined
                              ? TranslationContext.label.active
                              : "Active"}
                          </Radio>
                          <Radio value="InActive">
                            {TranslationContext !== undefined
                              ? TranslationContext.label.inactive
                              : "Inactive"}
                          </Radio>
                        </Radio.Group>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    {!this.state.isVideoCallAppointmentTab && (
                      <div className="col-12 col-md-6">
                        <label>
                          {TranslationContext !== undefined
                            ? TranslationContext.label.slotdisplay
                            : "Slot Display Code"}
                        </label>
                        <select
                          name="editSlotDisplayCode"
                          className="form-control"
                          value={this.state.editSlotDisplayCode}
                          onChange={this.handleSlotOnChange}
                          style={{ padding: 0, paddingLeft: "15px" }}
                        >
                          <option value={0}>
                            {TranslationContext !== undefined
                              ? TranslationContext.option.select
                              : "Select"}
                          </option>
                          <option value={301}>
                            {TranslationContext !== undefined
                              ? TranslationContext.option.currentslot
                              : "Current Slot"}
                          </option>
                          <option value={302}>
                            {TranslationContext !== undefined
                              ? TranslationContext.option
                                  .skipcurrentslotshownextslot
                              : "Skip Current Slot & Show Next Slot"}
                          </option>
                          <option value={303}>
                            {TranslationContext !== undefined
                              ? TranslationContext.option.skipcurrentnextslot
                              : "Skip Current & Next Slot"}
                          </option>
                        </select>
                        {this.state.isEditSlotDisplayCode ? (
                          <p
                            className="non-deliverable"
                            style={{
                              marginTop: "0",
                              textAlign: "left",
                            }}
                          >
                            {this.state.isEditSlotDisplayCode}
                          </p>
                        ) : null}
                      </div>
                    )}
                    {!this.state.isVideoCallAppointmentTab ? (
                      <div className="col-12 col-md-6">
                        <label>
                          {TranslationContext !== undefined
                            ? TranslationContext.label.maxpeopleallowed
                            : "Max People allowed in one Appointment"}
                        </label>
                        <select
                          name="editMaxCapacity"
                          className="form-control"
                          value={this.state.editMaxCapacity}
                          onChange={this.handleSlotOnChange}
                        >
                          <option value={0}>Select</option>
                          {Array(50)
                            .fill(0)
                            .map((e, i) => {
                              return <option value={i + 1}>{i + 1}</option>;
                            })}
                        </select>
                        {this.state.isEditMaxPeople ? (
                          <p
                            className="non-deliverable"
                            style={{
                              marginTop: "0",
                              textAlign: "left",
                            }}
                          >
                            {this.state.isEditMaxPeople}
                          </p>
                        ) : null}
                      </div>
                    ) : null}
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <label>Operational Days</label>
                      <div className="selectstores-box oper-days-edit selectOPRFilterModal">
                        <Select
                          // placeholder={"Select Operational Days"}
                          className="selectOPRFilterModal select-oper"
                          getOptionLabel={(option) => option.dayName}
                          getOptionValue={(option) => option.dayID}
                          options={this.state.operationalDaysData}
                          onChange={this.handleSelectEditChangeOperationalDays.bind(
                            this
                          )}
                          value={this.state.operationalDaysList}
                          closeMenuOnSelect={false}
                          isMulti
                          placeholder={
                            TranslationContext !== undefined
                              ? TranslationContext.validation
                                  .pleaseselectoperationaldays
                              : "Please Select Operational Days"
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <br />
                <div style={{ float: "right" }}>
                  <a
                    className="pop-over-cancle"
                    onClick={this.closeSlotEditModal}
                  >
                    {TranslationContext !== undefined
                      ? TranslationContext.a.cancel
                      : "CANCEL"}
                  </a>
                  <button
                    className="pop-over-button FlNone"
                    onClick={this.handleInsertUpdateTimeSlotSetting.bind(
                      this,
                      false
                    )}
                  >
                    <label className="pop-over-btnsave-text sltsave-btn">
                      {TranslationContext !== undefined
                        ? TranslationContext.label.save
                        : "SAVE"}
                    </label>
                    {this.state.isSlotEditLoading ? (
                      <FontAwesomeIcon
                        className="circular-loader"
                        icon={faCircleNotch}
                        spin
                      />
                    ) : null}
                  </button>
                </div>
              </div>
            </Modal>
            {/* edit slot ends */}

            {/* Choose Store Modal */}
            <Modal
              show={this.state.chooseStoreModal}
              dialogClassName="choosestorebox"
            >
              <img
                src={CancelIcon}
                alt="cancel-icone"
                className="cust-icon2"
                onClick={this.handleChooseStoreCloseModal.bind(this)}
              />
              <div className="choosestore">
                <div className="row">
                  <div className="col-12 col-md-6">
                    <label>
                      {TranslationContext !== undefined
                        ? TranslationContext.label.choosestore
                        : "Choose Store"}
                    </label>
                    <form name="form" onSubmit={this.handleStoreSearch}>
                      <div className="input-group form-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder={
                            TranslationContext !== undefined
                              ? TranslationContext.placeholder.search
                              : "Search"
                          }
                          autoComplete="off"
                          value={this.state.slotStoreSearch}
                          onChange={this.handleSlotStoreSearchOnchange.bind(
                            this
                          )}
                        />
                        <span className="input-group-append">
                          <img
                            src={searchico}
                            alt="search-icon"
                            className="cr-pnt"
                            onClick={this.handleStoreSearch}
                          />
                        </span>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-md-3">
                    <button
                      className="butn-selectall"
                      onClick={this.handleSelectAllStore.bind(this, true)}
                    >
                      {TranslationContext !== undefined
                        ? TranslationContext.button.selectall
                        : "Select All"}
                    </button>
                  </div>
                  <div className="col-12 col-md-9">
                    <ul className="atoz">
                      <li onClick={this.handleStoreFilterData.bind(this, "")}>
                        #
                      </li>
                      <li onClick={this.handleStoreFilterData.bind(this, "A")}>
                        A
                      </li>
                      <li onClick={this.handleStoreFilterData.bind(this, "B")}>
                        B
                      </li>
                      <li onClick={this.handleStoreFilterData.bind(this, "C")}>
                        C
                      </li>
                      <li onClick={this.handleStoreFilterData.bind(this, "D")}>
                        D
                      </li>
                      <li onClick={this.handleStoreFilterData.bind(this, "E")}>
                        E
                      </li>
                      <li onClick={this.handleStoreFilterData.bind(this, "F")}>
                        F
                      </li>
                      <li onClick={this.handleStoreFilterData.bind(this, "G")}>
                        G
                      </li>
                      <li onClick={this.handleStoreFilterData.bind(this, "H")}>
                        H
                      </li>
                      <li onClick={this.handleStoreFilterData.bind(this, "I")}>
                        I
                      </li>
                      <li onClick={this.handleStoreFilterData.bind(this, "J")}>
                        J
                      </li>
                      <li onClick={this.handleStoreFilterData.bind(this, "K")}>
                        K
                      </li>
                      <li onClick={this.handleStoreFilterData.bind(this, "L")}>
                        L
                      </li>
                      <li onClick={this.handleStoreFilterData.bind(this, "M")}>
                        M
                      </li>
                      <li onClick={this.handleStoreFilterData.bind(this, "N")}>
                        N
                      </li>
                      <li onClick={this.handleStoreFilterData.bind(this, "O")}>
                        O
                      </li>
                      <li onClick={this.handleStoreFilterData.bind(this, "P")}>
                        P
                      </li>
                      <li onClick={this.handleStoreFilterData.bind(this, "Q")}>
                        Q
                      </li>
                      <li onClick={this.handleStoreFilterData.bind(this, "R")}>
                        R
                      </li>
                      <li onClick={this.handleStoreFilterData.bind(this, "S")}>
                        S
                      </li>
                      <li onClick={this.handleStoreFilterData.bind(this, "T")}>
                        T
                      </li>
                      <li onClick={this.handleStoreFilterData.bind(this, "U")}>
                        U
                      </li>
                      <li onClick={this.handleStoreFilterData.bind(this, "V")}>
                        V
                      </li>
                      <li onClick={this.handleStoreFilterData.bind(this, "W")}>
                        W
                      </li>
                      <li onClick={this.handleStoreFilterData.bind(this, "Y")}>
                        Y
                      </li>
                      <li onClick={this.handleStoreFilterData.bind(this, "Z")}>
                        Z
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="storetabl">
                  <div className="row">
                    <div className="col-12">
                      <div className="row">
                        {this.state.storeCodeData !== null &&
                          this.state.storeCodeData.map((item, s) => (
                            <div
                              className="col-12 col-sm-4 col-md-3 col-lg-3"
                              key={s}
                            >
                              <div className="slotchckhceck">
                                <input
                                  type="checkbox"
                                  className="form-control"
                                  name="allStore"
                                  id={item.storeID + "_" + s}
                                  checked={item.isChecked}
                                  onChange={this.handleSelectStoresIndividual.bind(
                                    this,
                                    item
                                  )}
                                />
                                <label
                                  htmlFor={item.storeID + "_" + s}
                                  title={item.storeName}
                                >
                                  {item.storeCode}
                                </label>
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-md-12">
                    <div style={{ float: "right" }}>
                      <a
                        style={{ color: "#666", marginRight: "30px" }}
                        href={Demo.BLANK_LINK}
                        onClick={this.handleSelectAllStore.bind(this, false)}
                      >
                        {TranslationContext !== undefined
                          ? TranslationContext.a.clear
                          : "Clear"}
                      </a>
                      <button
                        className="butn"
                        onClick={this.handleApplySelectedStore.bind(this)}
                      >
                        {TranslationContext !== undefined
                          ? TranslationContext.button.apply
                          : "Apply"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Modal>
            {/* Choose store popup on filter */}
            <Modal
              show={this.state.filterChooseStoreModal}
              dialogClassName="choosestorebox"
            >
              <img
                src={CancelIcon}
                alt="cancel-icone"
                className="cust-icon2"
                onClick={this.handleFilterChooseStoreCloseModal.bind(this)}
              />
              <div className="choosestore">
                <div className="row">
                  <div className="col-12 col-md-6">
                    <label>
                      {TranslationContext !== undefined
                        ? TranslationContext.label.choosestore
                        : "Choose Store"}
                    </label>
                    <form name="form" onSubmit={this.handleFilterStoreSearch}>
                      <div className="input-group form-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder={
                            TranslationContext !== undefined
                              ? TranslationContext.placeholder.search
                              : "Search"
                          }
                          autoComplete="off"
                          value={this.state.filterSlotStoreSearch}
                          onChange={(e) => {
                            this.setState({
                              filterSlotStoreSearch: e.target.value,
                            });
                          }}
                        />
                        <span className="input-group-append">
                          <img
                            src={searchico}
                            alt="search-icon"
                            className="cr-pnt"
                            onClick={this.handleFilterStoreSearch}
                          />
                        </span>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-md-3">
                    <button
                      className="butn-selectall"
                      onClick={this.handleFilterSelectAllStore.bind(this, true)}
                    >
                      {TranslationContext !== undefined
                        ? TranslationContext.button.selectall
                        : "Select All"}
                    </button>
                  </div>
                  <div className="col-12 col-md-9">
                    <ul className="atoz">
                      <li onClick={this.handleFilterStoreData.bind(this, "")}>
                        #
                      </li>
                      <li onClick={this.handleFilterStoreData.bind(this, "A")}>
                        A
                      </li>
                      <li onClick={this.handleFilterStoreData.bind(this, "B")}>
                        B
                      </li>
                      <li onClick={this.handleFilterStoreData.bind(this, "C")}>
                        C
                      </li>
                      <li onClick={this.handleFilterStoreData.bind(this, "D")}>
                        D
                      </li>
                      <li onClick={this.handleFilterStoreData.bind(this, "E")}>
                        E
                      </li>
                      <li onClick={this.handleFilterStoreData.bind(this, "F")}>
                        F
                      </li>
                      <li onClick={this.handleFilterStoreData.bind(this, "G")}>
                        G
                      </li>
                      <li onClick={this.handleFilterStoreData.bind(this, "H")}>
                        H
                      </li>
                      <li onClick={this.handleFilterStoreData.bind(this, "I")}>
                        I
                      </li>
                      <li onClick={this.handleFilterStoreData.bind(this, "J")}>
                        J
                      </li>
                      <li onClick={this.handleFilterStoreData.bind(this, "K")}>
                        K
                      </li>
                      <li onClick={this.handleFilterStoreData.bind(this, "L")}>
                        L
                      </li>
                      <li onClick={this.handleFilterStoreData.bind(this, "M")}>
                        M
                      </li>
                      <li onClick={this.handleFilterStoreData.bind(this, "N")}>
                        N
                      </li>
                      <li onClick={this.handleFilterStoreData.bind(this, "O")}>
                        O
                      </li>
                      <li onClick={this.handleFilterStoreData.bind(this, "P")}>
                        P
                      </li>
                      <li onClick={this.handleFilterStoreData.bind(this, "Q")}>
                        Q
                      </li>
                      <li onClick={this.handleFilterStoreData.bind(this, "R")}>
                        R
                      </li>
                      <li onClick={this.handleFilterStoreData.bind(this, "S")}>
                        S
                      </li>
                      <li onClick={this.handleFilterStoreData.bind(this, "T")}>
                        T
                      </li>
                      <li onClick={this.handleFilterStoreData.bind(this, "U")}>
                        U
                      </li>
                      <li onClick={this.handleFilterStoreData.bind(this, "V")}>
                        V
                      </li>
                      <li onClick={this.handleFilterStoreData.bind(this, "W")}>
                        W
                      </li>
                      <li onClick={this.handleFilterStoreData.bind(this, "Y")}>
                        Y
                      </li>
                      <li onClick={this.handleFilterStoreData.bind(this, "Z")}>
                        Z
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="storetabl">
                  <div className="row">
                    <div className="col-12">
                      <div className="row">
                        {this.state.onLoadSlotStores !== null &&
                          this.state.onLoadSlotStores.map((item, f) => (
                            <div
                              className="col-12 col-sm-4 col-md-3 col-lg-3"
                              key={f}
                            >
                              <div className="slotchckhceck">
                                <input
                                  type="checkbox"
                                  className="form-control"
                                  name="allStore"
                                  id={item.storeID + "_" + f}
                                  checked={item.isChecked}
                                  onChange={this.handleFilterChooseStoreChange.bind(
                                    this,
                                    item
                                  )}
                                />
                                <label
                                  htmlFor={item.storeID + "_" + f}
                                  title={item.storeName}
                                >
                                  {item.storeCode}
                                </label>
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-md-12">
                    <div style={{ float: "right" }}>
                      <a
                        style={{ color: "#666", marginRight: "30px" }}
                        href={Demo.BLANK_LINK}
                        onClick={this.handleFilterSelectAllStore.bind(
                          this,
                          false
                        )}
                      >
                        {TranslationContext !== undefined
                          ? TranslationContext.a.clear
                          : "Clear"}
                      </a>
                      <button
                        className="butn"
                        onClick={this.handleFilterApplySelectedStore.bind(this)}
                      >
                        {TranslationContext !== undefined
                          ? TranslationContext.button.apply
                          : "Apply"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Modal>
            {/* Create tamplete Modal */}
            <Modal
              overlayId="chooseslot-main"
              show={this.state.createTampleteModal}
              dialogClassName="chooseslot-main"
            >
              <img
                src={CancelIcon}
                alt="cancel-icone"
                className="cust-icon2"
                onClick={this.handleCreateTempletetCloseModal.bind(this)}
              />
              <div className="chooseslot-box">
                {!this.state.isVideoCallAppointmentTab && (
                  <div className="row">
                    <div className="col-12 col-md-10">
                      <h3>
                        {TranslationContext !== undefined
                          ? TranslationContext.h3.chooseslottype
                          : "Choose Slot Type"}
                      </h3>
                    </div>
                  </div>
                )}
                {!this.state.isVideoCallAppointmentTab && (
                  <div className="row">
                    <div className="col-12 col-md-10">
                      <div className="statuscheckbox">
                        <Radio.Group
                          onChange={this.handleSlotRadioChange}
                          value={this.state.slotAutomaticRadio}
                        >
                          <Radio value={1}>
                            {TranslationContext !== undefined
                              ? TranslationContext.a.automatic
                              : "Automatic"}
                          </Radio>
                          <Radio value={2}>
                            {TranslationContext !== undefined
                              ? TranslationContext.a.manual
                              : "Manual"}
                          </Radio>
                        </Radio.Group>
                      </div>
                    </div>
                  </div>
                )}

                <div
                  classname="automaticbox"
                  style={{
                    display:
                      this.state.slotAutomaticRadio === 1 ? "block" : "none",
                  }}
                >
                  <div className="row">
                    <div className="col-12 col-md-10">
                      <label>
                        {TranslationContext !== undefined
                          ? TranslationContext.label.templatename
                          : "Template Name"}
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder={
                          TranslationContext !== undefined
                            ? TranslationContext.ticketingDashboard
                                .entertemplatename
                            : "Enter Template Name"
                        }
                        autoComplete="off"
                        maxLength={200}
                        name="autoTempName"
                        value={this.state.autoTempName}
                        onChange={this.handleInputOnchange}
                      />
                      {this.state.autoTempName === "" && (
                        <p style={{ color: "red", marginBottom: "0px" }}>
                          {this.state.autoTempNameCompulsory}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-md-5">
                      <label>
                        {TranslationContext !== undefined
                          ? TranslationContext.label.slotduration
                          : "Slot Duration"}
                      </label>
                      <select
                        name="AutoSlotDuration"
                        className="form-control"
                        value={this.state.AutoSlotDuration}
                        onChange={(e) =>
                          this.setState({
                            AutoSlotDuration: e.target.value,
                          })
                        }
                      >
                        <option value={0}>
                          {TranslationContext !== undefined
                            ? TranslationContext.option.selecttiming
                            : "Select Timing"}
                        </option>
                        <option value={0.25}>
                          15
                          {TranslationContext !== undefined
                            ? TranslationContext.option.minutes
                            : "Minutes"}
                        </option>
                        <option value={0.5}>
                          30
                          {TranslationContext !== undefined
                            ? TranslationContext.option.minutes
                            : "Minutes"}
                        </option>
                        <option value={0.75}>
                          45
                          {TranslationContext !== undefined
                            ? TranslationContext.option.minutes
                            : "Minutes"}
                        </option>
                        <option value={1}>
                          1
                          {TranslationContext !== undefined
                            ? TranslationContext.option.hour
                            : "Hour"}
                        </option>
                        <option value={1.5}>
                          1.5
                          {TranslationContext !== undefined
                            ? TranslationContext.option.hour
                            : "Hour"}
                        </option>
                        <option value={2}>
                          2
                          {TranslationContext !== undefined
                            ? TranslationContext.option.hours
                            : "Hours"}
                        </option>
                      </select>
                      {this.state.AutoSlotDuration === 0 && (
                        <p style={{ color: "red", marginBottom: "0px" }}>
                          {this.state.AutoSlotDurationCompulsory}
                        </p>
                      )}
                    </div>
                    <div className="col-12 col-md-5">
                      <label>
                        {TranslationContext !== undefined
                          ? TranslationContext.label.gapslots
                          : "Gap B/w Slots"}
                      </label>
                      <select
                        name="AutoSlotGap"
                        className="form-control"
                        value={this.state.AutoSlotGap}
                        onChange={(e) =>
                          this.setState({
                            AutoSlotGap: e.target.value,
                          })
                        }
                      >
                        <option value={0}>
                          {TranslationContext !== undefined
                            ? TranslationContext.option.selecttiming
                            : "Select Timing"}
                        </option>
                        <option value={0.25}>
                          15
                          {TranslationContext !== undefined
                            ? TranslationContext.option.minutes
                            : "Minutes"}
                        </option>
                        <option value={0.5}>
                          30
                          {TranslationContext !== undefined
                            ? TranslationContext.option.minutes
                            : "Minutes"}
                        </option>
                        <option value={0.75}>
                          45
                          {TranslationContext !== undefined
                            ? TranslationContext.option.minutes
                            : "Minutes"}
                        </option>
                        <option value={1}>
                          1
                          {TranslationContext !== undefined
                            ? TranslationContext.option.hour
                            : "Hour"}
                        </option>
                        <option value={1.5}>
                          1.5
                          {TranslationContext !== undefined
                            ? TranslationContext.option.hour
                            : "Hour"}
                        </option>
                        <option value={2}>
                          2
                          {TranslationContext !== undefined
                            ? TranslationContext.option.hours
                            : "Hours"}
                        </option>
                      </select>
                      {this.state.AutoSlotGap === 0 && (
                        <p style={{ color: "red", marginBottom: "0px" }}>
                          {this.state.AutoSlotGapCompulsory}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-md-10">
                      <h3>
                        {TranslationContext !== undefined
                          ? TranslationContext.h3.storetiming
                          : "Store Timing"}
                      </h3>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-md-5 slotFrm">
                      <label>
                        {TranslationContext !== undefined
                          ? TranslationContext.label.from
                          : "From"}
                      </label>
                      <DatePicker
                        customInput={<CustomInput />}
                        selected={this.state.autoStoreFrom}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        timeCaption="Time"
                        dateFormat="hh:mm aa"
                        placeholderText={
                          TranslationContext !== undefined
                            ? TranslationContext.option.selecttiming
                            : "Select Timing"
                        }
                        className="form-control"
                        onChange={(time) =>
                          this.setState({
                            autoStoreFrom: time,
                          })
                        }
                      />
                      {this.state.autoStoreFrom === "" && (
                        <p style={{ color: "red", marginBottom: "0px" }}>
                          {this.state.autoStoreFromCompulsory}
                        </p>
                      )}
                    </div>
                    <div className="col-12 col-md-5 slotFrm">
                      <label>
                        {TranslationContext !== undefined
                          ? TranslationContext.label.to
                          : "To"}
                      </label>
                      <DatePicker
                        customInput={<CustomInput />}
                        selected={this.state.autoStoreTo}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        timeCaption="Time"
                        dateFormat="h:mm aa"
                        placeholderText={
                          TranslationContext !== undefined
                            ? TranslationContext.option.selecttiming
                            : "Select Timing"
                        }
                        className="form-control"
                        onChange={(time) =>
                          this.handleSelectAutomaticStoreToDate(time)
                        }
                      />
                      {this.state.autoStoreTo === "" && (
                        <p style={{ color: "red", marginBottom: "0px" }}>
                          {this.state.autoStoreToCompulsory}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-md-10">
                      <h3>
                        {TranslationContext !== undefined
                          ? TranslationContext.h3.nonoperationalhour
                          : "Non Operational Hour"}
                      </h3>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-md-5 slotFrm">
                      <label>
                        {TranslationContext !== undefined
                          ? TranslationContext.label.from
                          : "From"}
                      </label>
                      <DatePicker
                        customInput={<CustomInput />}
                        selected={this.state.autoNonOptFrom}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        timeCaption="Time"
                        dateFormat="h:mm aa"
                        placeholderText={
                          TranslationContext !== undefined
                            ? TranslationContext.option.selecttiming
                            : "Select Timing"
                        }
                        className="form-control"
                        onChange={(time) =>
                          this.setState({
                            autoNonOptFrom: time,
                          })
                        }
                        minTime={setHours(
                          setMinutes(new Date(), 0),
                          new Date(this.state.autoStoreFrom).getHours()
                        )}
                        maxTime={setHours(
                          setMinutes(new Date(), 0),
                          new Date(this.state.autoStoreTo).getHours()
                        )}
                      />
                      {this.state.autoNonOptFrom === "" && (
                        <p style={{ color: "red", marginBottom: "0px" }}>
                          {this.state.autoNonOptFromCompulsory}
                        </p>
                      )}
                    </div>
                    <div className="col-12 col-md-5 slotFrm">
                      <label>
                        {TranslationContext !== undefined
                          ? TranslationContext.label.to
                          : "To"}
                      </label>
                      <DatePicker
                        customInput={<CustomInput />}
                        selected={this.state.autoNonOptTo}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        timeCaption="Time"
                        dateFormat="h:mm aa"
                        placeholderText={
                          TranslationContext !== undefined
                            ? TranslationContext.option.selecttiming
                            : "Select Timing"
                        }
                        className="form-control"
                        onChange={(time) =>
                          this.handleSelectAutomaticToDates(time)
                        }
                        minTime={setHours(
                          setMinutes(
                            new Date(),
                            this.state.autoNonOptFrom !== ""
                              ? new Date(
                                  this.state.autoNonOptFrom
                                ).getMinutes() +
                                  15 ==
                                60
                                ? 0
                                : new Date(
                                    this.state.autoNonOptFrom
                                  ).getMinutes() + 15
                              : 0
                          ),
                          new Date(
                            this.state.autoNonOptFrom !== ""
                              ? new Date(
                                  new Date(
                                    this.state.autoNonOptFrom
                                  ).setMinutes(
                                    new Date(
                                      this.state.autoNonOptFrom
                                    ).getMinutes() + 15
                                  )
                                )
                              : this.state.autoStoreFrom
                          ).getHours()
                        )}
                        maxTime={setHours(
                          setMinutes(new Date(), 0),
                          new Date(this.state.autoStoreTo).getHours()
                        )}
                      />
                      {this.state.autoNonOptTo === "" && (
                        <p style={{ color: "red", marginBottom: "0px" }}>
                          {this.state.autoNonOptToCompulsory}
                        </p>
                      )}
                    </div>
                    {!this.state.isVideoCallAppointmentTab && (
                      <div className="col-12 col-md-2">
                        <button
                          className="tabbutn cr-pnt"
                          onClick={this.handleSubmitAutomaticData.bind(this)}
                          disabled={this.state.isGenrateSlotLoading}
                        >
                          {TranslationContext !== undefined
                            ? TranslationContext.button.generateslot
                            : "Generate Slot"}
                        </button>
                      </div>
                    )}
                  </div>
                  {this.state.isVideoCallAppointmentTab && (
                    <div className="row my-3 d-flex align-items-center">
                      <div className="col-12 col-md-4">
                        <h6 style={{ fontWeight: "bold" }}>
                          Multiple Time Slot
                        </h6>
                      </div>
                      <div className="col-12 col-md-2">
                        <div className="module-switch">
                          <div className="switch switch-primary d-flex  align-items-center ">
                            <h6
                              style={{
                                fontWeight: "bold",
                              }}
                            >
                              No
                            </h6>

                            <input
                              type="checkbox"
                              id="multipleTimeSlot"
                              name="allModules"
                              checked={
                                this.state.videoCallAppointmentData
                                  .isMultipleTimeSlot
                              }
                              onChange={this.handleMultipleTimeSlot.bind(this)}
                            />

                            <label
                              style={{ margin: "0" }}
                              htmlFor="multipleTimeSlot"
                              className="cr cr-float-auto"
                            ></label>

                            <h6
                              style={{
                                fontWeight: "bold",
                                marginLeft: "1rem",
                              }}
                            >
                              Yes
                            </h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {this.state.isVideoCallAppointmentTab && (
                    <div className=" row my-3 d-flex align-items-center">
                      <div className="col-12 col-md-5">
                        <h6 style={{ fontWeight: "bold" }}>
                          Number of Slots ( For Multiple Slot )
                        </h6>
                      </div>
                      <div className="col-12 col-md-5 d-flex justify-content-between">
                        <input
                          type="text"
                          className="form-control value"
                          name="slotOccupancy"
                          value={this.state.videoCallMultipleSlot}
                          disabled={
                            this.state.videoCallAppointmentData
                              .isMultipleTimeSlot
                              ? false
                              : true
                          }
                          onChange={(e) =>
                            this.setState({
                              videoCallMultipleSlot: e.target.value,
                            })
                          }
                          style={{ width: 50 }}
                        />
                        <button
                          style={{
                            background: "none",
                            color: "#2561AB",
                            cursor: "pointer",
                          }}
                          onClick={this.handleSubmitAutomaticData.bind(this)}
                          disabled={this.state.isGenrateSlotLoading}
                        >
                          {TranslationContext !== undefined
                            ? TranslationContext.button.generateslot
                            : "Generate Slot"}
                        </button>
                      </div>
                    </div>
                  )}

                  <div className="row">
                    <div className="col-12 col-md-10">
                      <div className="chooseslot-table">
                        <Table
                          dataSource={this.state.automaticaSlotTblData}
                          noDataContent="No Record Found"
                          pagination={false}
                          loading={this.state.isGenrateSlotLoading}
                          className="components-table-demo-nested antd-table-campaign custom-antd-table"
                          columns={[
                            {
                              title:
                                TranslationContext !== undefined
                                  ? TranslationContext.span.srno
                                  : "S.No.",
                              render: (row, rowData, i) => {
                                return <>{i + 1}</>;
                              },
                            },
                            {
                              title:
                                TranslationContext !== undefined
                                  ? TranslationContext.label.slotstarttime
                                  : "Slot Start Time",
                              dataIndex: "slotStartTime",
                            },
                            {
                              title:
                                TranslationContext !== undefined
                                  ? TranslationContext.label.slotoccupancy
                                  : this.state.isVideoCallAppointmentTab
                                  ? "No of Slots"
                                  : "Slot Occupancy",
                              dataIndex: "slotOccupancy",
                              render: (row, rowData, i) => {
                                return (
                                  <div className="d-flex align-items-center">
                                    {this.state.isVideoCallAppointmentTab && (
                                      <FontAwesomeIcon
                                        icon={faMinus}
                                        onClick={() =>
                                          this.decrementSloOccupancy(i)
                                        }
                                      />
                                    )}

                                    <input
                                      type="text"
                                      className="form-control value mx-3"
                                      value={rowData.slotOccupancy}
                                      disabled={
                                        this.state.videoCallAppointmentData
                                          .isMultipleTimeSlot
                                          ? false
                                          : true
                                      }
                                      onChange={this.handleCreateSlotOccupancyChange.bind(
                                        this,
                                        rowData.slotID
                                      )}
                                      style={{ width: 40 }}
                                    />
                                    {this.state.isVideoCallAppointmentTab && (
                                      <FontAwesomeIcon
                                        icon={faPlus}
                                        onClick={() =>
                                          this.incrementSloOccupancy(i)
                                        }
                                      />
                                    )}
                                  </div>
                                );
                              },
                            },
                            {
                              title:
                                TranslationContext !== undefined
                                  ? TranslationContext.label.slotendtime
                                  : "Slot End Time",
                              dataIndex: "slotEndTime",
                            },
                            {
                              title: "Action",
                              hidden: this.state.isVideoCallAppointmentTab
                                ? false
                                : true,
                              render: (row, rowData, i) => {
                                return (
                                  <img
                                    src={DelBlack}
                                    alt="DelBlack"
                                    style={{ width: "15px" }}
                                    onClick={() =>
                                      this.handleRemoveVideoSlot(i)
                                    }
                                  ></img>
                                );
                              },
                            },
                          ].filter((item) => !item.hidden)}
                        ></Table>
                      </div>
                    </div>
                  </div>
                  {/* {this.state.isVideoCallAppointmentTab && (
                    <div className="row my-3 d-flex align-items-center">
                      <div className="col-12 col-md-4">
                        <h6 style={{ fontWeight: "bold" }}>
                          Default Store Staff Name
                        </h6>
                      </div>
                      {this.state.videoCallAppointmentData
                        .isStaffNameSwitch && (
                        <div className="col-12 col-md-2 mr-5">
                          <select
                            className="form-control edit-slot-temp"
                            style={{ width: 150 }}
                            placeholder="Select Name"
                            name="storeStaffName"
                            disabled={
                              !this.state.videoCallAppointmentData
                                .isStaffNameSwitch
                            }
                            onChange={(staffName) =>
                              this.setState({
                                videoCallAppointmentData: {
                                  ...this.state.videoCallAppointmentData,
                                  storeStaffName: staffName.target.value,
                                },
                              })
                            }
                          >
                            <option value="">Select Name</option>
                            {this.state.defaultStaffList.map((staff) => {
                              return (
                                <option
                                  key={staff.storeManagerID}
                                  value={staff.storeManagerID}
                                >
                                  {staff.agentName}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      )}

                      <div className="col-12 col-md-2">
                        <div className="module-switch">
                          <div className="switch switch-primary d-flex  align-items-center ">
                            <h6
                              style={{
                                fontWeight: "bold",
                              }}
                            >
                              No
                            </h6>

                            <input
                              type="checkbox"
                              id="staffName"
                              name="allModules"
                              checked={
                                this.state.videoCallAppointmentData
                                  .isStaffNameSwitch
                              }
                              onChange={this.handleStaffNameSwitch.bind(this)}
                            />

                            <label
                              style={{ margin: "0" }}
                              htmlFor="staffName"
                              className="cr cr-float-auto"
                            ></label>

                            <h6
                              style={{
                                fontWeight: "bold",
                                marginLeft: "1rem",
                              }}
                            >
                              Yes
                            </h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                   */}

                  <div className="row">
                    <div className="col-12 col-md-10">
                      <div className="del-can">
                        <a
                          href={Demo.BLANK_LINK}
                          onClick={this.handleCreateTempletetCloseModal.bind(
                            this
                          )}
                        >
                          {TranslationContext !== undefined
                            ? TranslationContext.a.cancel
                            : "CANCEL"}
                        </a>
                        <button
                          disabled={this.state.isCreateTampleteLoading}
                          className="butn"
                          onClick={this.handleFinalSaveTemplateData.bind(
                            this,
                            "Automatic"
                          )}
                        >
                          <label className="sltsave-btn">
                            {TranslationContext !== undefined
                              ? TranslationContext.button.save
                              : "Save "}
                          </label>

                          {this.state.isCreateTampleteLoading ? (
                            <FontAwesomeIcon
                              className="circular-loader"
                              icon={faCircleNotch}
                              spin
                            />
                          ) : null}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  classname="manualbox"
                  style={{
                    display:
                      this.state.slotAutomaticRadio === 2 ? "block" : "none",
                  }}
                >
                  <div className="row">
                    <div className="col-12 col-md-10">
                      <label>
                        {TranslationContext !== undefined
                          ? TranslationContext.label.templatename
                          : "Template Name"}
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Template Name"
                        autoComplete="off"
                        maxLength={200}
                        name="manualTempName"
                        value={this.state.manualTempName}
                        onChange={this.handleInputOnchange}
                      />
                      {this.state.manualTempName === "" && (
                        <p style={{ color: "red", marginBottom: "0px" }}>
                          {this.state.manualTempNameCompulsory}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-md-10">
                      <h3>
                        {TranslationContext !== undefined
                          ? TranslationContext.h3.storetiming
                          : "Store Timing"}
                      </h3>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-md-5 slotFrm">
                      <label>
                        {TranslationContext !== undefined
                          ? TranslationContext.label.from
                          : "From"}
                      </label>
                      <DatePicker
                        customInput={<CustomInput />}
                        selected={this.state.manualStoreFrom}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={30}
                        timeCaption="Time"
                        dateFormat="hh:mm a"
                        className="form-control"
                        onChange={(time) =>
                          this.setState({
                            manualStoreFrom: time,
                          })
                        }
                      />
                      {this.state.manualStoreFrom === "" && (
                        <p style={{ color: "red", marginBottom: "0px" }}>
                          {this.state.manualStoreFromCompulsory}
                        </p>
                      )}
                    </div>
                    <div className="col-12 col-md-5 slotFrm">
                      <label>To</label>
                      <DatePicker
                        customInput={<CustomInput />}
                        selected={this.state.manualStoreTo}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={30}
                        timeCaption="Time"
                        dateFormat="hh:mm a"
                        placeholderText={
                          TranslationContext !== undefined
                            ? TranslationContext.option.selecttiming
                            : "Select Timing"
                        }
                        className="form-control"
                        onChange={(time) =>
                          this.handleSelectManualStoreTodate(time)
                        }
                      />
                      {this.state.manualStoreTo === "" && (
                        <p style={{ color: "red", marginBottom: "0px" }}>
                          {this.state.manualStoreToCompulsory}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-md-10">
                      <label>
                        {TranslationContext !== undefined
                          ? TranslationContext.label.slotduration
                          : "Slot Duration"}
                      </label>
                      <select
                        name="ManualSlotDuration"
                        className="form-control"
                        value={this.state.ManualSlotDuration}
                        onChange={this.handleManualSelectDuration}
                      >
                        <option value={0}>
                          {TranslationContext !== undefined
                            ? TranslationContext.option.selecttiming
                            : "Select Timing"}
                        </option>
                        <option value={15}>
                          15
                          {TranslationContext !== undefined
                            ? TranslationContext.option.minutes
                            : "Minutes"}
                        </option>
                        <option value={30}>
                          30
                          {TranslationContext !== undefined
                            ? TranslationContext.option.minutes
                            : "Minutes"}
                        </option>
                        <option value={45}>
                          45
                          {TranslationContext !== undefined
                            ? TranslationContext.option.minutes
                            : "Minutes"}
                        </option>
                        <option value={60}>
                          1
                          {TranslationContext !== undefined
                            ? TranslationContext.option.hour
                            : "Hour"}
                        </option>
                        <option value={90}>
                          1.5
                          {TranslationContext !== undefined
                            ? TranslationContext.option.hour
                            : "Hour"}
                        </option>
                        <option value={120}>
                          2
                          {TranslationContext !== undefined
                            ? TranslationContext.option.hours
                            : "Hours"}
                        </option>
                      </select>
                      {this.state.ManualSlotDuration === 0 && (
                        <p style={{ color: "red", marginBottom: "0px" }}>
                          {this.state.ManualSlotDurationCompulsory}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-md-5 slotFrm">
                      <label>
                        {TranslationContext !== undefined
                          ? TranslationContext.label.slotstarttime
                          : "Slot Start Time"}
                      </label>
                      <DatePicker
                        customInput={<CustomInput />}
                        selected={this.state.manualStoreData.slotStartTime}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        // timeIntervals={Number(this.state.ManualSlotDuration)}
                        timeCaption="Time"
                        dateFormat="hh:mm a"
                        placeholderText={
                          TranslationContext !== undefined
                            ? TranslationContext.option.selecttiming
                            : "Select Timing"
                        }
                        className="form-control"
                        onChange={(time) =>
                          this.handleManualTimeOnchange(time, "slotStartTime")
                        }
                        minTime={setHours(
                          setMinutes(new Date(), 0),
                          new Date(this.state.manualStoreFrom).getHours()
                        )}
                        maxTime={setHours(
                          setMinutes(new Date(), 0),
                          new Date(this.state.manualStoreTo).getHours()
                        )}
                        excludeTimes={excludeTimes}
                      />
                      {this.state.manualStoreData.slotStartTime === "" && (
                        <p style={{ color: "red", marginBottom: "0px" }}>
                          {this.state.manualSlotStartCompulsory}
                        </p>
                      )}
                    </div>
                    <div className="col-12 col-md-5 slotFrm">
                      <label>
                        {TranslationContext !== undefined
                          ? TranslationContext.label.slotendtime
                          : "Slot End Time"}
                      </label>
                      <DatePicker
                        selected={this.state.manualStoreData.slotEndTime}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        timeCaption="Time"
                        dateFormat="hh:mm a"
                        placeholderText={
                          TranslationContext !== undefined
                            ? TranslationContext.option.selecttiming
                            : "Select Timing"
                        }
                        className="form-control"
                        disabled
                        onChange={(time) =>
                          this.handleManualTimeOnchange(time, "slotEndTime")
                        }
                      />
                      {/* {this.state.slotEndTime === "" && (
                        <p style={{ color: "red", marginBottom: "0px" }}>
                          {this.state.manualSlotEndCompulsory}
                        </p>
                      )} */}
                    </div>
                    <div className="col-12 col-md-2">
                      <button
                        className="tabbutn cr-pnt"
                        onClick={this.handleAddManualySlot.bind(this)}
                      >
                        {TranslationContext !== undefined
                          ? TranslationContext.button.addslot
                          : "Add Slot"}
                      </button>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-md-10">
                      <div className="chooseslot-table">
                        <Table
                          dataSource={this.state.manualStoreTblData}
                          noDataContent="No Record Found"
                          pagination={false}
                          className="components-table-demo-nested antd-table-campaign custom-antd-table"
                          columns={[
                            {
                              title:
                                TranslationContext !== undefined
                                  ? TranslationContext.span.srno
                                  : "S.No.",
                              dataIndex: "slotID",
                              render: (text, record, index) => index + 1,
                            },
                            {
                              title:
                                TranslationContext !== undefined
                                  ? TranslationContext.label.slotstarttime
                                  : "Slot Start Time",
                              dataIndex: "slotStartTime",
                            },
                            {
                              title:
                                TranslationContext !== undefined
                                  ? TranslationContext.label.slotendtime
                                  : "Slot End Time",
                              dataIndex: "slotEndTime",
                            },
                            {
                              title: "Actions",
                              render: (text, record, index) => {
                                return (
                                  <>
                                    <img
                                      src={DelBlack}
                                      alt="del-icon"
                                      className="del-btn cr-pnt"
                                      onClick={this.handleManualDeleteSlot.bind(
                                        this,
                                        index
                                      )}
                                    />
                                  </>
                                );
                              },
                            },
                          ]}
                        ></Table>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-md-10">
                      <div className="del-can">
                        <a
                          href={Demo.BLANK_LINK}
                          onClick={this.handleCreateTempletetCloseModal.bind(
                            this
                          )}
                        >
                          {TranslationContext !== undefined
                            ? TranslationContext.a.cancel
                            : "CANCEL"}
                        </a>
                        <button
                          disabled={this.state.isCreateTampleteLoading}
                          className="butn"
                          onClick={this.handleFinalSaveTemplateData.bind(
                            this,
                            "Manual"
                          )}
                        >
                          <label className="sltsave-btn">
                            {TranslationContext !== undefined
                              ? TranslationContext.button.save
                              : "Save"}
                          </label>

                          {this.state.isCreateTampleteLoading ? (
                            <FontAwesomeIcon
                              className="circular-loader"
                              icon={faCircleNotch}
                              spin
                            />
                          ) : null}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Modal>

            {/* Selected Store Modal  */}
            <Modal
              show={this.state.selectedStoreModal}
              dialogClassName="selectstores-main"
            >
              <img
                src={CancelIcon}
                alt="cancel-icone"
                className="selected-store-cancel"
                onClick={this.handleSelectedStoreCloseModal.bind(this)}
              />
              <div className="selectstores-box">
                <div className="row">
                  <div className="col-12">
                    <h3>
                      {TranslationContext !== undefined
                        ? TranslationContext.h3.selectedstores
                        : "Selected Stores"}
                    </h3>
                    <ul>
                      {this.state.storeCodeData !== null &&
                        this.state.storeCodeData.map((item, s) => {
                          return (
                            <>
                              {item.isChecked && (
                                <li key={s}>
                                  <div className="input-group">
                                    <label>{item.storeCode}</label>
                                    <span className="input-group-append">
                                      <img
                                        src={CancelIcon}
                                        alt="cancel-icone"
                                        className="cust-ic cr-pnt"
                                        onClick={this.handleRemoveSelectedStore.bind(
                                          this,
                                          item.storeID
                                        )}
                                      />
                                    </span>
                                  </div>
                                </li>
                              )}
                            </>
                          );
                        })}
                    </ul>
                  </div>
                </div>
              </div>
            </Modal>
            {/* ----- filter Operational Days Modal-----*/}
            <Modal
              show={this.state.filterOperationalDaysModal}
              dialogClassName="chooseopreationbox"
              onHide={this.handleFilterOperationalDaysModalClose.bind(this)}
            >
              <img
                src={CancelIcon}
                alt="cancel-icone"
                className="cust-icon2"
                onClick={this.handleFilterOperationalDaysModalClose.bind(this)}
              />
              <div className="choosestore selectOPRFilterModal">
                <div className="">
                  <label>Operational Days</label>
                  <Select
                    classNamePrefix="select"
                    // placeholder={"Select Operational Days"}
                    className="selectOPRFilterModal select-oper"
                    getOptionLabel={(option) => option.dayName}
                    getOptionValue={(option) => option.dayID}
                    options={this.state.operationalDaysData}
                    onChange={this.handleSelectFilterChangeOperationalDays.bind(
                      this
                    )}
                    value={this.state.filterSelectedOperationalDays}
                    closeMenuOnSelect={false}
                    isMulti
                    placeholder={
                      TranslationContext !== undefined
                        ? TranslationContext.validation
                            .pleaseselectoperationaldays
                        : "Please Select Operational Days"
                    }
                  />
                  <div className="opreditSelect">
                    <a
                      className="pop-over-cancle"
                      onClick={this.handleFilterOperationalDaysModalClose.bind(
                        this
                      )}
                    >
                      {TranslationContext !== undefined
                        ? TranslationContext.a.cancel
                        : "CANCEL"}
                    </a>
                    <button
                      className="pop-over-button FlNone"
                      onClick={this.handleFilterEditALLSlotData.bind(this)}
                      disabled={this.state.filterEditLoader}
                    >
                      <label className="pop-over-btnsave-text sltsave-btn">
                        {this.state.filterEditLoader ? (
                          <FontAwesomeIcon
                            className="circular-loader"
                            icon={faCircleNotch}
                            spin
                          />
                        ) : null}
                        Apply
                      </label>
                      {this.state.isSlotEditLoading ? (
                        <FontAwesomeIcon
                          className="circular-loader"
                          icon={faCircleNotch}
                          spin
                        />
                      ) : null}
                    </button>
                  </div>
                </div>
              </div>
            </Modal>
            {/* ----- filter tamplete Modal-----*/}
            <Modal
              show={this.state.filterTampleteModal}
              dialogClassName="slotEditModal"
              onHide={this.handleFilterTampleteModalModalClose.bind(this)}
            >
              <img
                src={CancelIcon}
                alt="cancel-icone"
                className="cust-icon2"
                onClick={this.handleFilterTampleteModalModalClose.bind(this)}
              />
              <div className="edtpadding">
                <div className="">
                  <label className="popover-header-text">
                    {TranslationContext !== undefined
                      ? TranslationContext.label.editslotsettings
                      : "Edit"}
                  </label>
                </div>
                <div className="pop-over-div edit-slot">
                  <div className="row">
                    <div className="col-12 col-md-6">
                      <label>
                        {TranslationContext !== undefined
                          ? TranslationContext.label.selectslottemplete
                          : "Select Slot Template"}
                      </label>
                      <select
                        className="form-control mb-4"
                        name="selectedSlotTemplate"
                        value={this.state.selectedFilterSlotTemplate}
                        onChange={this.handleSelectedFilterSlotTemplate.bind(
                          this
                        )}
                      >
                        <option value={0}>Select</option>
                        {this.state.slotTemplateData !== null &&
                          this.state.slotTemplateData.map((item, s) => (
                            <option key={s} value={item.slotTemplateID}>
                              {item.slotTemplateName}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <div className="edittabs">
                        <Table
                          loading={this.state.filterSlotTempLoading}
                          dataSource={this.state.filterSlotTemplateGridData}
                          noDataContent="No Record Found"
                          pagination={false}
                          className="components-table-demo-nested antd-table-campaign custom-antd-table"
                          columns={[
                            {
                              title:
                                TranslationContext !== undefined
                                  ? TranslationContext.span.srno
                                  : "S.No.",
                              render: (row, rowData, i) => {
                                return <>{i + 1}</>;
                              },
                            },
                            {
                              title:
                                TranslationContext !== undefined
                                  ? TranslationContext.label.slotstarttime
                                  : "Slot Start Time",
                              dataIndex: "slotStartTime",
                            },
                            {
                              title:
                                TranslationContext !== undefined
                                  ? TranslationContext.label.slotendtime
                                  : "Slot End Time",
                              dataIndex: "slotEndTime",
                            },
                            {
                              title:
                                TranslationContext !== undefined
                                  ? TranslationContext.label.slotoccupancy
                                  : "Slot Occupancy",
                              dataIndex: "slotOccupancy",
                              render: (row, rowData, i) => {
                                return (
                                  <>
                                    <input
                                      type="text"
                                      className="form-control value"
                                      name="slotOccupancy"
                                      value={rowData.slotOccupancy}
                                      disabled={true}
                                      onChange={this.handleFilterEnableDisableOnChange.bind(
                                        this,
                                        i
                                      )}
                                    />
                                  </>
                                );
                              },
                            },
                            {
                              title:
                                TranslationContext !== undefined
                                  ? TranslationContext.label
                                      .slotstatusunabledisable
                                  : "Slot Status(Enable/Disable)",
                              render: (row, rowData, i) => {
                                return (
                                  <div className="module-switch">
                                    <div className="switch switch-primary">
                                      <input
                                        type="checkbox"
                                        id={"ckStatus" + i}
                                        name="isSlotEnabled"
                                        checked={rowData.isSlotEnabled}
                                        value={rowData.isSlotEnabled}
                                        onChange={this.handleFilterEnableDisableOnChange.bind(
                                          this,
                                          i
                                        )}
                                      />
                                      <label
                                        htmlFor={"ckStatus" + i}
                                        className="cr cr-float-auto"
                                      ></label>
                                    </div>
                                  </div>
                                );
                              },
                            },
                          ]}
                        ></Table>
                      </div>
                    </div>
                  </div>
                </div>
                <br />
                <div style={{ float: "right" }}>
                  <a
                    className="pop-over-cancle"
                    onClick={this.handleFilterTampleteModalModalClose.bind(
                      this
                    )}
                  >
                    {TranslationContext !== undefined
                      ? TranslationContext.a.cancel
                      : "CANCEL"}
                  </a>
                  <button
                    className="pop-over-button FlNone"
                    onClick={this.handleFilterEditALLSlotData.bind(this)}
                    disabled={this.state.filterEditLoader}
                  >
                    <label className="pop-over-btnsave-text sltsave-btn">
                      {this.state.filterEditLoader ? (
                        <FontAwesomeIcon
                          className="circular-loader"
                          icon={faCircleNotch}
                          spin
                        />
                      ) : null}
                      Apply
                    </label>
                    {this.state.isSlotEditLoading ? (
                      <FontAwesomeIcon
                        className="circular-loader"
                        icon={faCircleNotch}
                        spin
                      />
                    ) : null}
                  </button>
                </div>
              </div>
            </Modal>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default StoreModule;
