import React, { useEffect, useRef, useState } from "react";
import Draggable from "react-draggable";
import io from "socket.io-client";
import Peer from "simple-peer";
import styled from "styled-components";
import ringtone from "../../assets/mp3/ringtone.mp3";
import callEnd from "../../assets/Images/callEnd.png";
import downwardArrow from "../../assets/Images/downwardArrow.svg";
import stopVideoIcon from "../../assets/Images/stopVideoIcon.svg";
import startVideoIcon from "../../assets/Images/startVideoIcon.svg";
import unmuteIcon from "../../assets/Images/unmuteIcon.svg";
import muteIcon from "../../assets/Images/muteIcon.svg";
import searchWhite from "../../assets/Images/searchWhite.svg";
import messenger from "../../assets/Images/chat.svg";
import cameraflip from "../../assets/Images/cameraflip.svg";
import camera_rotate from "../../assets/Images/camera_rotate.png";
import { Howl } from "howler";
import { Link } from "react-router-dom";
import ChatService from "../../Services/Chats/ChatService";
import config from "../../helpers/config";
import { Modal } from "antd";
import { connect } from "react-redux";
import { videoCallSocket } from "../../helpers/SocketConnection";
import APICaller from "../../APICaller/APICaller";
import createTkt from "../../assets/Images/createTkt.svg";
import { JitsiMeeting } from '@jitsi/react-sdk';
import { event } from "jquery";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import Badge from "@mui/material/Badge";
import '../../assets/css/video.css';
import FullScreenIcon from '../../assets/Images/fullscreen-svgrepo-com.svg';
import { Message } from "@mui/icons-material";

const VideoCall = (props) => {

  let agentValue = JSON.parse(localStorage.getItem('UserProfile'))
  const socketRef = useRef();
  const userVideo = useRef();
  let agentIdValue = useRef();
  let reciverCode = useRef();
  let roomIDRef = useRef();
  // let CustomerMobileNumber = useRef();
  let receivingCallTemp = useRef();
  let callerTemp = useRef();
  agentIdValue.current = agentValue.agentId;
  let roomID = agentValue.programCode + "" + agentValue.storeCode;
  let mangerCode = agentValue.agentId + "";
  let mangerValue = roomID + mangerCode;
  // socket.send("hi:" + roomID + mangerCode);
  videoCallSocket.send("hi:" + roomID + mangerCode);
  const [managerStatus, setManagerStatus] = useState(false);
  var managerDetails = {
    managerCode: agentValue.agentId + "",
    programCode: agentValue.programCode,
    storeCode: agentValue.storeCode,
    isBusy: localStorage.getItem("CallAccepted") ? localStorage.getItem("CallAccepted") === "true" ? true : managerStatus : managerStatus,
  };
  const [stream, setStream] = useState();
  const [receivingCall, setReceivingCall] = useState(false);
  const [caller, setCaller] = useState("");
  const [callerSignal, setCallerSignal] = useState();
  const [callAccepted, setCallAccepted] = useState(true);
  const [callRejected, setCallRejected] = useState(false);
  const [isFullScreen, setFullScreen] = useState(true);
  const [cameraText, setCameraText] = useState("Stop Video");
  const [audioText, setAudioText] = useState("Mute");
  const [mobileNo, setMobileNo] = useState(null);
  const [customerName, setCustomerName] = useState(null);
  const [storeCode, setStoreCode] = useState(props.storeCode || null);
  const [customreId, setCustomreId] = useState(null);
  const [isVideoUpdate, setIsVideoUpdate] = useState(false);
  const [isAudioUpdate, setIsAudioUpdate] = useState(false);
  const [isFlipCamera, setIsFlipCamera] = useState(false);
  const [finishStatus, setfinishStatus] = useState(false);
  const [CallBackStatus, setCallBackStatus] = useState(false);
  const [chatID, setChatID] = useState(0);
  const myRingtoneSound = useRef();

  let [isSocketConnectModel, setIsSocketConnectModel] = useState(false);
  let [iceStateValue, setIceStateChange] = useState('connecting...');
  let [isConnectionPopUp, setConnectionPopUp] = useState(true);
  let [isRejectCall, setIsRejectCall] = useState(false);
  let [isSmallScreenVisual, setSmallScreenVisual] = useState(false);
  let [yourSocketId, setYourSocketId] = useState();
  let [remarks, setRemarks] = useState("");
  const [CreateTicketDetailValidation, setCreateTicketDetailValidation]=useState("");
  let iceServerRef = useRef();

  const [peers, setPeers] = useState([]);
  const peersRef = useRef([]);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [ticketID, setTicketID] = useState(0);


  const handeBrowserBackButton = (e) => {
    var ua = window.navigator.userAgent;
    var isIpad = ua.indexOf("(iPad") > -1;
    var isIphone = ua.indexOf("(iPhone") > -1 || ua.indexOf("(iPod") > -1;
    var isChrome = ua.indexOf("Chrome/") > -1 || ua.indexOf("CriOS/") > -1;
    var isSafari = !isChrome && ua.indexOf("Safari/") > -1;
    var isIosSafari = (isIpad || isIphone) && isSafari;
    e.preventDefault();
    let tempCallAccepted = localStorage.getItem("CallAccepted");
    if (!finishStatus) {
      if (tempCallAccepted == true || tempCallAccepted == "true") {
        setfinishStatus(true);
        if (window.confirm("Do you want to end the call ?")) {
          myRingtoneSound.current.unload();
          if (userVideo.current && userVideo.current.srcObject) {
            if (userVideo.current.srcObject.getTracks()) {
              userVideo.current.srcObject.getTracks().forEach(function (track) {
                track.stop();
              });
              userVideo.current.srcObject = null;
            }
          }
          socketRef.current.off("signal");
          let closeData = {
            programCode: agentValue.programCode,
            storeCode: agentValue.storeCode,
            agentId: agentValue.agentId,
          };
          socketRef.current.emit("close", closeData);
          managerDetails.isBusy = false;
          managerDetails.isCalling = false;
          receivingCallTemp.current = null;
          callerTemp.current = null;
          setFullScreen(false);
          props.hideChatDuringCall(false)
          setCallAccepted(false);
          localStorage.setItem("CallAccepted", false);
          setReceivingCall(false);
          setCallRejected(true);
          setCustomerName(null);
          setIsVideoUpdate(false);
          setIsAudioUpdate(false);
          setIsFlipCamera(false);
          setStream(null);
          handleUpdateVideoCall();
          let isOngoingClick = localStorage.getItem("isOngoingClick");
          if (isOngoingClick === "true") {
            let ongoingChatsData = JSON.parse(
              localStorage.getItem("ongoingChatsData")
            );
            if (ongoingChatsData) {
              let chatId = ongoingChatsData.filter(
                (x) => x.mobileNo === mobileNo
              )[0]
                ? ongoingChatsData.filter((x) => x.mobileNo === mobileNo)[0]
                  .chatID
                : null;
              if (chatId) {
                if (document.getElementById(chatId)) {
                  localStorage.setItem("ongoingChatId", chatId);
                  document.getElementById("ongoingchatList").click();
                }
              }
            }
          }
          setTimeout(() => {
            setMobileNo(null);
          }, 100);
        } else {
          window.history.pushState(null, null, window.location.pathname);
          setfinishStatus(false);
        }
      }
    }
    if (isSafari) {
      setCallBackStatus(true);
    }
  };

  useEffect(() => {
    window.addEventListener("eventToEndCallFromAndroid", function () {
      //stopCall();
      setCallBackStatus(true);
    }, false);
  }, [])

  useEffect(() => {
    getCallStatus(true);
    // getCallStatus.showToast(true);
    socketRef.current = videoCallSocket;

    setMobileNo(props.customerMobileNumber);
    reciverCode.current = mangerValue;
    roomIDRef.current = roomID;
    console.log(videoCallSocket);

    // window.history.pushState(null, null, window.location.pathname);
    // window.addEventListener("popstate", handeBrowserBackButton);

    // return () => {
    //   window.removeEventListener("popstate", handeBrowserBackButton);
    //   // handleStoreManageroffline();
    // };
  }, []);

  useEffect(() => {
    setTimeout(() => {
      if (!socketRef.current.connected) setIsSocketConnectModel(true);
    }, 3000)

    window.addEventListener("online", function () {
      setIsSocketConnectModel(false)
    }, false);

    window.addEventListener("offline", function () {
      setIsSocketConnectModel(true)
    }, false);

  }, [isSocketConnectModel]);

  useEffect(() => {
    if (callAccepted) {
      if (iceStateValue == 'connected') {
        setConnectionPopUp(false)
      } else {
        setConnectionPopUp(true)
        setIceStateChange(iceStateValue)
      }
    }

  }, [iceStateValue])

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth <= 200 && window.innerHeight <= 300) {
        setSmallScreenVisual(true);
        handleReturntoCall();
      }
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [isSmallScreenVisual])

  function closePrompt(event) {
    event.preventDefault();
    setfinishStatus(true);
    setCallBackStatus(false);
    // stopCall("");
  }

  useEffect(() => {

    videoCallSocket.emit("HSA2S_currentCallAnswered",
      {
        customerNumber: props.customerMobileNumber,
        programCode: agentValue.programCode,
        storeCode: agentValue.storeCode,
        agentId: agentValue.agentId
      }
    );
    // eslint-disable-next-line
  }, []);

  function concatenateStrings(arg1, arg2) {
    alert(arg1 + arg2);
    return 'ABC';
  }

  function stopCall(isStopcall) {

    setIsAudioUpdate(false);
    setIsVideoUpdate(false);
    props.setIsCallRecived(false);
    //close event is emited before because we want to send some details to the user
    let closeData = {
      programCode: agentValue.programCode,
      storeCode: agentValue.storeCode,
      agentId: agentValue.agentId
    };
    socketRef.current.emit("close", closeData);
    socketRef.current.off("signal");

    receivingCallTemp.current = null;
    setFullScreen(false);
    props.hideChatDuringCall(false)
    setCallAccepted(false);
    localStorage.setItem("CallAccepted", false);
    setReceivingCall(false);
    setCallRejected(true);
    setCustomerName(null);
    setIsVideoUpdate(false);
    setIsAudioUpdate(false);
    setIsFlipCamera(false);
    setStream(null);
    handleUpdateVideoCall();
    let isOngoingClick = localStorage.getItem("isOngoingClick");
    if (isOngoingClick === "true") {
      let ongoingChatsData = JSON.parse(
        localStorage.getItem("ongoingChatsData")
      );
      if (ongoingChatsData) {
        let chatId = ongoingChatsData.filter((x) => x.mobileNo === mobileNo)[0]
          ? ongoingChatsData.filter((x) => x.mobileNo === mobileNo)[0].chatID
          : null;
        if (chatId) {
          if (document.getElementById(chatId)) {
            localStorage.setItem("ongoingChatId", chatId);
            document.getElementById("ongoingchatList").click();
          }
        }
      }
    }
    getCallStatus(false)
    // getCallStatus.showToast(false);
    setTimeout(() => {
      setMobileNo(null);
    }, 100);
  }

  // function handleStoreManageroffline() {
  //   managerDetails.isOnline = false;
  //   socketRef.current.emit("storeOnline", roomID);
  //   socketRef.current.emit("storeMangerDetail", managerDetails);
  // }

  function handleInsertVideoCall() {
    let inputParams = {
      StoreCode:
        storeCode || JSON.parse(localStorage.getItem("UserProfile")).storeCode,
      MobileNumber: mobileNo,
    };
    new ChatService()
      .PostWithParams("/CustomerChat/InsertVideoCall", inputParams)
      .then((response) => {
        var message = response.message;
        var responseData = response.responseData;
        if (message === "Success" && responseData) {
          localStorage.setItem("customreId", responseData.customerID);
          setChatID(responseData.chatID);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function handleUpdateVideoCall() {
    if (localStorage.getItem("customreId", customreId)) {
      let inputParams = {
        ID: localStorage.getItem("customreId", customreId),
      };
      new ChatService()
        .PostWithParams("/CustomerChat/UpdateVideoCall", inputParams)
        .then((response) => {
          var message = response.message;
          var responseData = response.responseData;
          if (message === "Success" && responseData) {
            localStorage.removeItem("customreId");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  function handleStoreStaffEndCall() {
    videoCallSocket.emit("HSA2S_DROPPED_FROM_STORE_END",
      {
        programCode: agentValue.programCode,
        storeCode: agentValue.storeCode,
        mobileNumber: props.customerMobileNumber
      }
    );
  }

  //will help us to get value in our android app.
  function getCallStatus(status) {
    if (window.getCallStatus) {
      window.getCallStatus.showToast(status)
    }
  }

  async function handleReturntoCall() {
    console.log('clicked inside handle return to call')
    await setFullScreen(true);
    await props.hideChatDuringCall(true)
  }

  // function handlerReconnect() {
  //   console.log('reconnect')
  //   socketRef.current.emit("storeOnline", roomID);
  //   socketRef.current.emit("storeMangerDetail", managerDetails);
  // }

  function handleIFrameRef(iframeRef) {
    iframeRef.style.border = '10px solid #3d3d3d';
    iframeRef.style.background = '#3d3d3d';
    iframeRef.style.height = '100%';
    iframeRef.style.display = 'block';
    //iframeRef.style.position = 'absolute';
    //iframeRef.style.width = '-webkit-fill-available';
    iframeRef.style.width = '100%';
    // iframeRef.style.bottom = props.isFullScreen ? 'unset' : '0';
    iframeRef.style.borderTop = 'none';

  };

  function handleReadyToClose() {
    /* eslint-disable-next-line no-alert */
    // alert('Ready to close...');
  };

  function handleTextArea(e) {
    setRemarks(e.target.value);
  }

  const eventHandler = (e, data) => {
    console.log('Event Type', e.type);
    console.log({e, data});
  }

  function handleSaveTicket() {
    console.log(remarks);
    let inputParams = {
      ChatID: chatID,
      CustomerMobile: props.customerMobileNumber,
      TicketTitle: "VideoCall Ticket",
      // TicketDetails: ,
      CategoryID: 2,
      SubCategoryID: 4,
      IssueTypeID: 16,
      PriorityID: 104
    };
    if(remarks.length > 2){
      if(ticketID !== 0){
        let inputParams = {
          ticketID: ticketID,
          comment: remarks,
        };
        new ChatService().PostWithParams(
          "/HSChatTicketing/AddChatTicketNotes",
          inputParams
        )
          .then((response) => {
            if (response.message === "Success") {
              NotificationManager.success(
                "Ticket Created successfully."
              );
            } else {
              NotificationManager.error(
                "Ticket Not Created."
              );
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
      else{
        new ChatService().PostWithData("/CustomerChat/CreateTicekt", inputParams)
      .then((response) => {
        let Msg = response.message;
        if (Msg === "Success") {
          setTicketID(response.responseData)
          let inputParams = {
            ticketID: response.responseData,
            comment: remarks,
          };
          new ChatService().PostWithParams(
            "/HSChatTicketing/AddChatTicketNotes",
            inputParams
          )
            .then((response) => {
              if (Msg === "Success") {
                NotificationManager.success(
                  "Ticket Created successfully."
                );
                setCreateTicketDetailValidation("")
                handleClose()
              } else {
                NotificationManager.error(
                  "Ticket Not Created."
                );
              }
            })
            .catch((error) => {
              console.log(error);
            });
          
        } 
        else {
          NotificationManager.error(
            "Ticket Not Created."
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
      }
    setRemarks("");
    setShow(false);
  }
  else{
    setCreateTicketDetailValidation("Please Enter Remarks.")  
  }
}
    

  return (
    <React.Fragment>
      {callAccepted && isFullScreen ? (
        <>
          <div
            //className="row"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              height: "6vh",
              width: "100%",
              position: "absolute"
            }}
          >
            <div className="arrow_button" style={{width: "25%"}}  onClick={() => {
                  if(props.onGoingChatMobileNo === props.customerMobileNumber){
                    props.setMessageCount(0);
                  }
                  setFullScreen(false);
                  props.hideChatDuringCall(false);
                }}>
              
                <img src={downwardArrow} alt="downwardArrow" />
              
            </div>
            <div className="arrow_button" style={{width: "25%"}}>
              
                <Link
                  onClick={() => {
                    props.setMessageCount(0);
                    setFullScreen(false);
                    props.hideChatDuringCall(false);
                  }}
                  to={{
                    pathname: "Chatbot",
                    state: {
                      isSearch: false,
                      isMessage: true,
                      isVideoCall: true,
                      MobileNo: props.customerMobileNumber,
                      tenantID: JSON.parse(
                        localStorage.getItem("ChatSoundNotiSetting")
                      ).tenantID,
                      agentId: JSON.parse(localStorage.getItem("UserProfile"))
                        .agentId,
                      storeCode: JSON.parse(localStorage.getItem("UserProfile"))
                        .storeCode,
                      programCode: JSON.parse(
                        localStorage.getItem("UserProfile")
                      ).programCode,
                      UserName: JSON.parse(localStorage.getItem("UserProfile"))
                        .agentName,
                    },
                  }}
                >
                  <Badge
                    color="secondary"
                    anchorOrigin={{ vertical: "top", horizontal: "right" }}
                    badgeContent={props.messageCount > 0 ? props.messageCount : null}
                  >
                    <img src={messenger} alt="messenger" />
                  </Badge>
                  {/* <img src={messenger} alt="messenger" /> */}
                </Link>
            </div>
            <div className="arrow_button" style={{width: "25%"}}>
              
                <Link
                  onClick={() => {
                    props.setMessageCount(0);
                    setFullScreen(false);
                    props.hideChatDuringCall(false);
                  }}
                  to={{
                    pathname: "Chatbot",
                    state: {
                      isMessage: false,
                      isSearch: true,
                      isVideoCall: true,
                      MobileNo: mobileNo,
                      tenantID: JSON.parse(
                        localStorage.getItem("ChatSoundNotiSetting")
                      ).tenantID,
                      agentId: JSON.parse(localStorage.getItem("UserProfile"))
                        .agentId,
                      storeCode: JSON.parse(localStorage.getItem("UserProfile"))
                        .storeCode,
                      programCode: JSON.parse(
                        localStorage.getItem("UserProfile")
                      ).programCode,
                      UserName: JSON.parse(localStorage.getItem("UserProfile"))
                        .agentName,
                    },
                  }}
                >
                  <img src={searchWhite} alt="searchWhite" />
                  {/* <label>
                  <span>Search</span>
                </label> */}
                </Link>
            </div>
            <div className="arrow_button" style={{width: "25%"}}>
              <div
                //type="button"
                //className="btn"
                data-toggle="modal"
                data-target="#exampleModal"
                //style={{ height: "5px" }}
              >
                <img
                  src={createTkt}
                  alt="searchWhite"
                  // onClick={() => setShow(true)}
                />
              </div>
               
              <div className="custom_modal_content">
                <div
                  className="modal fade"
                  id="exampleModal"
                  tabIndex="-1"
                  role="dialog"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  <div
                    className="modal-dialog modal-dialog-centered modal-sm"
                    role="document"
                  >
                    <div className="modal-content">
                      <div
                        className="modal-header"
                        style={{ padding: "10px !important" }}
                      >
                        <h5 className="modal-title" id="exampleModalLabel">
                          Create Ticket
                        </h5>
                        <button
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body p-2">
                        <div className="textarea_container">
                          <textarea className="textarea" placeholder="Enter Remarks" onChange={(e) => handleTextArea(e)} value={remarks}></textarea>
                        </div>
                        {remarks.length < 3 && (
                          <p
                            style={{
                              color: "red",
                              marginBottom: "0px",
                            }}
                          >
                            {CreateTicketDetailValidation}
                          </p>
                        )}
                      </div>
                      <div className="modal-footer">
                        <button type="button" className="btn btn-primary" onClick={() => handleSaveTicket()} >
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="video-return-call">
          <button className="backtocall" onClick={() => handleReturntoCall()}>
            <label>RETURN TO CALL</label>
          </button>
        </div>
      )}
      <div
        id="emptyDiv"
        className={isFullScreen ? "" : "positionUserVideo"}
        style={{ display: callAccepted ? "block" : "none" }}
      >
      </div>
      <Draggable bounds={".main-content"} 
        onStart={eventHandler}
        onDrag={eventHandler}
        onStop={eventHandler} >
        <div
          className={callAccepted && isFullScreen ? "fullScreenCss" : ""}
          id="videoComponentToDrag"
          style={{
            display: "block",
            position: "absolute",
            cursor: "move",
            zIndex: "9999",
            height: callAccepted && isFullScreen ? "95vh" : "30vh",
            width: callAccepted && isFullScreen ? "100%" : "20vh",
            bottom: isFullScreen ? "0" : "unset"
          }}
        >
          {!isFullScreen && (
            <div
              className="wholeSmallScreenDivPartOne"
              style={{
                height: callAccepted && isFullScreen ? "" : "100%",
                width: callAccepted && isFullScreen ? "" : "20vh",
                position: "absolute",
                zIndex: "9999",
                background: "transparent",
              }}
            >
              {/* <div className="full-screen" onMouseDown={() => handleReturntoCall()} >
                <img src={FullScreenIcon} alt="no-img" />
              </div> */}
              {/* <span style={{ color: "white" }}>Drag</span> */}
            </div>
          )}
          <div className="vedio_call_component">
            <div id="fullScreenView">
              <div className="fullScreen_dragable">
                <div className="fullScreen">

                  <div className="jitsi-fullscreen" style={{
                    //border: "10px solid rgb(61, 61, 61)",
                    // bottom: props.isFullScreen ? "unset" : "0px",
                    position: "absolute",
                    borderTop: isFullScreen ? "none" : "10px solid rgb(61, 61, 61)",
                    height: "100%",
                    width: "100%"
                    }}>
                    <JitsiMeeting
                      // domain='ercx.staging.com'
                      // domain="easybackend.staging02.com"
                      domain="easybackend.shopster.chat"
                      roomName={props.roomName}
                      configOverwrite={{
                        disableDeepLinking: true,
                        prejoinPageEnabled: false,
                        enableClosePage: true,
                        enableWelcomePage: false,
                      }}
                      interfaceConfigOverwrite={{
                        MOBILE_APP_PROMO: false,
                        TILE_VIEW_MAX_COLUMNS: 4,
                      }}
                      userInfo={{
                        displayName: "Store Manager",
                      }}
                      getIFrameRef={handleIFrameRef}
                      onReadyToClose={handleReadyToClose}
                      onApiReady={(externalApi) => {
                        handleInsertVideoCall();
                        externalApi.executeCommand("subject", " ");
                        externalApi.addListener(
                          "videoConferenceLeft",
                          (eventData) => {
                            console.log("eventData", eventData);
                            handleStoreStaffEndCall();
                            externalApi.dispose();
                            stopCall();
                          }
                        );
                        externalApi.addListener(
                          "participantLeft",
                          (eventData) => {
                            console.log("eventData", eventData);
                            handleUpdateVideoCall();
                            externalApi.dispose();
                            stopCall();
                          }
                        );
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Draggable>

      <Modal visible={CallBackStatus} footer={null}>
        <div className="confirm">
          <h1>Confirm your action</h1>
          <p>
            Do you want to <strong>End</strong> the call?
          </p>
          <button
            onClick={() => {
              setCallBackStatus(false);
            }}
          >
            Cancel
          </button>
          <button onClick={(event) => closePrompt(event)} autofocus>
            Confirm
          </button>
        </div>
      </Modal>
      {(isAudioUpdate || isVideoUpdate) && callAccepted && isFullScreen ? (
        <div className="vido_audioPopUp">
          <div className="cancel_ok_button vido_audioPopUpMessage iconMuntVideo">
            {isAudioUpdate ? (
              <>
                <div className="icon_text_popUp">
                  <img
                    src={isAudioUpdate ? unmuteIcon : muteIcon}
                    alt="call-reject"
                  />
                  <p className="small_screen_overlay">{audioText}</p>
                </div>
              </>
            ) : null}
            {isVideoUpdate ? (
              <>
                <div className="icon_text_popUp">
                  <img
                    src={isVideoUpdate ? stopVideoIcon : startVideoIcon}
                    alt="call-reject"
                  />
                  <p className="small_screen_overlay">{cameraText}</p>
                </div>
              </>
            ) : null}
          </div>
        </div>
      ) : null}
      {isRejectCall ? (
        <div
          className="shocket_disconnect_div"
          onClick={() => setIsRejectCall(false)}
        >
          <div className="cancel_ok_button">
            <p>You can not disconnect the call</p>
          </div>
        </div>
      ) : null}
    </React.Fragment>
  );
};

const mapStateToProps = state => {
  return {
    customerMobileNumber: state.customerMobileNumber,
    roomName: state.roomName,
    messageCount: state.messageCount,
    onGoingChatMobileNo: state.onGoingChatMobileNo,
    isFullScreen: state.isFullScreen
  };
}

const mapDispatchToProps = dispatch => {
  return {
    hideChatDuringCall: (isFullScreen) => dispatch({ type: 'IS_FULL_SCREEN', payload: isFullScreen }),
    setIsCallRecived: (isCallRecived) => dispatch({ type: "IS_CALL_RIECIVED", payload: isCallRecived }),
    setMessageCount: (messageCount) => dispatch({ type: "SET_MESSAGECOUNT", payload: messageCount }),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(VideoCall);
