import React, { Component } from "react";

class SessionExpired extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isMobileView: false,
    };
  }
  componentDidMount() {
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();

    setTimeout(() => {
      this.handleMobileLogOut();
    }, 3000);
  }
  /// check mobile size
  resize() {
    if (window.innerWidth <= 1000) {
      this.setState({ isMobileView: true });
    } else {
      this.setState({ isMobileView: false });
    }
  }
  /// handle mobile logout
  handleMobileLogOut() {
    // if (window.innerWidth <= 1280) {
    //   localStorage.clear();
    //   sessionStorage.clear();
    //   this.props.history.push("/");
    // }
      localStorage.clear();
      sessionStorage.clear();
      this.props.history.push("/");
  }
  render() {
    return (
      <div className="storeLogOut">
        {this.state.isMobileView ? (
          <div>
            <label>You Session is Expired.Try to Login Again.</label>
          </div>
        ) :<h3>User Loggedin in another Device. Try to Login Again.</h3>}
      </div>
    );
  }
}

export default SessionExpired;
