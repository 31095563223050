/* eslint-disable no-lone-blocks */
import React, { Component, Fragment, PureComponent, useState } from "react";
import {
  Table,
  Select,
  Collapse,
  Spin,
  DatePicker as DatePickerAnt,
} from "antd";
import moment from "moment";
import { NotificationManager } from "react-notifications";
import { encryption } from "../../helpers/encryption";
import * as translationHI from "../../translations/hindi";
import * as translationMA from "../../translations/marathi";
import WhiteRightArrow from "./../../assets/Images/down-white.png";
import SchRight from "./../../assets/Images/sch-right.png";
import LeftWhi from "./../../assets/Images/down.png";
import DatePicker from "react-datepicker";
import copy from "./../../assets/Images/copy.png";
import "react-pagination-js/dist/styles.css";
import "react-datepicker/dist/react-datepicker.css";
import Filter from "./../../assets/Images/icons8-filter.svg";
import CancalImg from "./../../assets/Images/cancal blue.png";
import SearchIcon from "./../../assets/Images/search-icon.png";
import AppointmentService from "../../Services/Appointment/AppointmentService";
import ChatService from "../../Services/Chats/ChatService";
import paste from "./../../assets/Images/paste.png";
import { handleMaskingNumber } from "../../helpers/maskingNumber";
import { CarouselItem } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTable } from "@fortawesome/free-solid-svg-icons";
import LoginService from "../../Services/Login/LoginService";

const { Panel } = Collapse;
const { Option } = Select;

class AppointmentExpandComponent extends PureComponent {
  state = {
    appointmentCustomerListData: this.props.appointmentCustomerListData,
    status: 0,
    activePanel: [],
    isNumberMasking: false,
    maskingPrefix: 0,
    maskingSufix: 0,
    tenantMobileMasking: false,
  };

  handleStatusChange = (e) => {
    this.setState({ status: e });
  };

  PaginationOnChange = (e) => {
    // this.setState({pageNo:})
  };
  handleUpdateAppointment = (appointmentID) => {
    if (this.state.status) {
      this.props.handleUpdateAppointmentMobile(
        appointmentID,
        Number(this.state.status)
      );
    } else {
      NotificationManager.error("please select status.");

      this.forceUpdate();
    }
  };
  handlecollapseChange = (e) => {
    this.state.activePanel = e[e.length - 1];
    this.setState({ activePanel: this.state.activePanel });
    this.forceUpdate();
  };

  render() {
    const TranslationContext = this.props.translateLanguage.default;
    return (
      <div style={{ marginTop: "25px" }}>
        {/* <Pagination
            currentPage={this.state.pageNo}
            totalSize={this.state.pageCount}
            sizePerPage={this.state.pageSize}
            changeCurrentPage={this.PaginationOnChange}
          /> */}

        <Collapse
          bordered={false}
          className="site-collapse-custom-collapse"
          expandIconPosition={"right"}
          destroyInactivePanel={true}
          onChange={this.handlecollapseChange.bind(this)}
          activeKey={this.state.activePanel}
        >
          {this.state.appointmentCustomerListData.length > 0
            ? this.state.appointmentCustomerListData.map((item, key) => {
              return (
                <Panel
                  header={
                    <>
                      <div className="appcusname">
                        <label className="ite1">{item.customerName}</label>
                        {/* <label className="ite2">{item.customerNumber}</label> */}
                        <p className="order-small-font">
                          {this.state.isNumberMasking === true &&
                            this.state.tenantMobileMasking === true ? (
                            <p className="num">
                              {handleMaskingNumber(
                                item.customerNumber,
                                this.state.maskingPrefix,
                                this.state.maskingSufix
                              )}
                            </p>
                          ) : (
                            <p className="num stop_copying">
                              {item.customerNumber}
                            </p>
                          )}
                        </p>
                      </div>
                    </>
                  }
                  key={key}
                  className="site-collapse-custom-panel"
                >
                  <div className="extendap">
                    <div className="row mb-2">
                      <div className="col-5">
                        <label>ID</label>
                      </div>
                      <div className="col-7">
                        <label>{item.appointmentID}</label>
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-5">
                        <label>Select Status</label>
                      </div>
                      <div className="col-7">
                        {!item.status ? (
                          <div className="select">
                            <Select
                              placeholder={
                                TranslationContext !== undefined
                                  ? TranslationContext.placeholder
                                    .selectstatus
                                  : "Select Status"
                              }
                              dropdownClassName="appt-status-dropdown"
                              onChange={this.handleStatusChange.bind(this)}
                            >
                              <Option value="0">
                                {TranslationContext !== undefined
                                  ? TranslationContext.option.cancel
                                  : "Cancel"}
                              </Option>
                              <Option value="1">
                                {TranslationContext !== undefined
                                  ? TranslationContext.option.visited
                                  : "Visited"}
                              </Option>
                              <Option value="2">
                                {TranslationContext !== undefined
                                  ? TranslationContext.option.notvisited
                                  : "Not Visited"}
                              </Option>
                            </Select>
                          </div>
                        ) : (
                          <button
                            className="statusBtn"
                            type="button"
                            style={{ marginRight: "10px" }}
                          >
                            <label className="statusLabel">
                              {item.status}
                            </label>
                          </button>
                        )}
                      </div>
                    </div>
                    {!item.status ? (
                      <div className="row mb-2">
                        <div className="col-5"></div>
                        <div className="col-7">
                          <button
                            onClick={this.handleUpdateAppointment.bind(
                              this,
                              item.appointmentID
                            )}
                          >
                            Update
                          </button>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </Panel>
              );
            })
            : null}
        </Collapse>
      </div>
    );
  }
}

class VideoCallAppointment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      appointmentGridData: [],
      rowExpandedCount: 0,
      todayCount: 0,
      tomorrowCount: 0,
      dayAfterTomorrowCount: 0,
      tomorrowDay: "",
      dayAfterTomorrowDay: "",
      status: [],
      tabFor: 1,
      translateLanguage: {},
      appointmentDaysData: [],
      isMobileView: false,
      isTableRowClick: false,
      appointmentDate: "",
      appointmentCustomerListData: [],
      isSearch: false,
      endDate: null,
      startDate: null,
      appointmentID: "",
      customerNumber: "",
      isSearchData: false,
      collapseSearch: false,
      agentData: [],
      storeStaffId: null,
      isFilterSearchInMobileVisual: false,
      isNumberMasking: false,
      maskingPrefix: 0,
      maskingSufix: 0,
      isLoading: true,
      copiedTextChatId: null,
      tenantMobileMasking: false,
    };
    this.onRowExpand = this.onRowExpand.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
    this.AppointmentService = new AppointmentService();
    this.ChatService = new ChatService();
    this.LoginService = new LoginService();
  }

  componentDidMount() {
    this.handleCRMRole();

    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
    // this.handleAppointmentCount();
    this.handleAppointmentGridData();
    this.handleGetAgentList();
    if (window.localStorage.getItem("translateLanguage") === "hindi") {
      this.state.translateLanguage = translationHI;
    } else if (window.localStorage.getItem("translateLanguage") === "marathi") {
      this.state.translateLanguage = translationMA;
    } else {
      this.state.translateLanguage = {};
    }
  }
  resize() {
    if (window.innerWidth <= 1000) {
      this.setState({ isMobileView: window.innerWidth <= 1000 });
    } else {
      this.setState({ isTableRowClick: false, isMobileView: false });
    }
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.resize.bind(this));
  }
  //handle crm role data
  handleCRMRole() {
    this.LoginService.Post("/StoreCRMRole/GetStoreRolesByUserID")
      .then((response) => {
        let msg = response.message;
        if (msg === "Success") {
          this.setState({
            isNumberMasking: response.responseData.isNumberMasking,
            maskingPrefix: response.responseData.maskingPrefix,
            maskingSufix: response.responseData.maskingSufix,
            tenantMobileMasking:
              response.responseData.tenantLevelMasking,
            isSuggestionText: response.responseData.suggestionText,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  handleAppointmentGridData(appointmentId, storeStaffId, aptDate, mobileNo) {
    document.addEventListener("contextmenu", (event) => event.preventDefault());
    let self = this;
    this.setState({
      loading: true,
    });

    let inputParams = {
      AppointmentId: appointmentId || 0,
      AptDate: aptDate || "",
      MobileNo: mobileNo || "",
      StoreStaffId: storeStaffId || 0,
    };
    this.AppointmentService.PostWithParams(
      "/Appointment/GetVideoAppointmentList",
      inputParams
    )
      .then((response) => {
        let status = response.message;
        let data = response.responseData;
        if (status === "Success" && data) {
          data.forEach((element) => {
            if (element.status == 0) {
              element.isDisable = true;
            } else {
              element.isDisable = false;
            }
          });
          self.setState({
            appointmentGridData: data,
            isLoading: false,
          });
        } else {
          self.setState({
            appointmentGridData: [],
            isLoading: false,
          });
        }
        self.setState({
          loading: false,
        });
      })
      .catch((error) => {
        self.setState({
          loading: false,
        });
        console.log(error);
      });
  }

  handleUpdateAppointmentMobile = (
    appointmentID,
    statusId,
    storeStaffNameId,
    comment
  ) => {
    const TranslationContext = this.state.translateLanguage.default;
    let self = this;
    let inputData = {
      AppointmentId: appointmentID || 0,
      AppointmentStatus: statusId || 0,
      StoreStaffNameId: storeStaffNameId || 0,
      Comment: comment || "",
    };
    this.AppointmentService.PostWithData(
      "/Appointment/UpdateVideoAppointmentDetails",
      inputData
    )
      .then((response) => {
        let status = response.message;
        if (status === "Success") {
          self.setState({
            isSearch: false,
            isSearchData: false,
            isTableRowClick: false,
          });
          self.handleAppointmentGridData();
          NotificationManager.success(
            TranslationContext !== undefined
              ? TranslationContext.alertmessage.recordupdatedsuccessfully
              : "Record updated successFully."
          );
        } else {
          NotificationManager.error(status);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  handleUpdateAppointment(appointmentID) {
    const TranslationContext = this.state.translateLanguage.default;

    let self = this;
    if (
      this.state.status[appointmentID] !== "" &&
      this.state.status[appointmentID] !== undefined
    ) {
      let inputData = {
        AppointmentID: appointmentID,
        Status: parseInt(this.state.status[appointmentID]),
      };
      this.AppointmentService.PostWithData(
        "/Appointment/UpdateAppointmentStatus",
        inputData
      )
        .then((response) => {
          let status = response.message;
          if (status === "Success") {
            NotificationManager.success(
              TranslationContext !== undefined
                ? TranslationContext.alertmessage.recordupdatedsuccessfully
                : "Record updated successFully."
            );
          } else {
            NotificationManager.error(status);
          }
          self.handleAppointmentGridData();
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      NotificationManager.error(
        TranslationContext !== undefined
          ? TranslationContext.alertmessage.pleaseselectstatus
          : "Please select status."
      );
    }
  }

  onRowExpand(expanded, record) {
    let rowExpandedCount;
    if (expanded) {
      rowExpandedCount = this.state.rowExpandedCount + 1;
      this.setState({
        rowExpandedCount,
      });
    } else {
      rowExpandedCount = this.state.rowExpandedCount - 1;
      this.setState({
        rowExpandedCount,
      });
    }
  }

  handleOnChange(value, index) {
    const val = value;
    this.setState((oldState) => {
      const newStatus = oldState.status.slice();
      newStatus[index] = val;
      return {
        status: newStatus,
      };
    });
  }

  //To handle the copied button icon
  handleCopiedNumber = (e, contact_number, copiedTextChatId) => {
    let numberValue = encryption(contact_number, "enc");
    window.localStorage.setItem("copiedNumber", numberValue);
    this.setState({
      isNumberCopiedClicked: true,
      isTextCopied: true,
      copiedTextChatId: copiedTextChatId,
    });
    this.state.isNumberCopiedClicked = true;
    setTimeout(() => {
      this.setState({
        isNumberCopiedClicked: false,
        isTextCopied: false,
      });
    }, 3000);
  };

  handlePasteNumber() {
    let self = this;
    let number = window.localStorage.getItem("copiedNumber");
    let decryptnumber = encryption(number, "decr");
    self.setState({
      customerNumber: decryptnumber,
    });
    let muskedNumber = handleMaskingNumber(
      decryptnumber,
      this.state.maskingPrefix,
      this.state.maskingSufix
    );
    self.setState({
      customerNumber2: muskedNumber,
    });
    return muskedNumber;
  }

  ////handle scroll right
  handleScrollRight(num) {
    document.getElementById("AppointmentDiv").scrollLeft += 20;
  }
  ////handle scroll left
  handleScrollLeft(num) {
    document.getElementById("AppointmentDiv").scrollLeft -= 20;
  }

  handelAppointmentRowClick = (data) => {
    if (this.state.isMobileView) {
      this.setState({
        isTableRowClick: true,
        appointmentDate: data.appointmentDate,
        appointmentCustomerListData: data.appointmentCustomerList,
      });
    } else {
    }
  };
  handleAppointmentBackClick = () => {
    if (this.state.isMobileView) {
      this.setState({
        isTableRowClick: false,
        isSearch: false,
      });
    }
  };
  handleSearchDatePickerChange = (dates) => {
    if (this.state.isMobileView) {
      this.setState({
        startDate: dates,
      });
    } else {
      this.setState({
        startDate: moment(dates).toString(),
      });
    }
  };

  handleAppointmentGridDataSearch() {
    let self = this;

    this.setState({
      loading: true,
      isSearch: false,
      isFilterSearchInMobileVisual: false,
    });
    var selectedDate = "";
    var startDate = moment(this.state.startDate)
      .format("DD-MM-YYYY")
      .toString();
    if (this.state.endDate) {
      var endDate = moment(this.state.endDate).format("DD-MM-YYYY").toString();
      selectedDate = startDate + "," + endDate;
    } else {
      if (this.state.startDate) {
        selectedDate = startDate + "," + startDate;
      }
    }
    let inputParams = {
      AppointmentID: this.state.appointmentID || 0,
      MobileNo: this.state.customerNumber || "",
      AptDate: selectedDate,
      StoreStaffId: this.state.storeStaffId || 0,
    };
    this.AppointmentService.PostWithParams(
      "/Appointment/GetVideoAppointmentList",
      inputParams
    )
      .then((response) => {
        var message = response.message;
        var responseData = response.responseData;
        self.setState({ loading: false });
        if (message === "Success") {
          self.setState({
            appointmentGridData: responseData,
            isSearchData: true,
          });
          self.handleAppointmentBackClick();
        } else {
          self.setState({
            appointmentGridData: [],
            isSearchData: true,
          });
        }
        this.setState({
          isFilterSearchInMobileVisual: false,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }
  handleSeachTextChange = (e) => {
    var name = e.target.name;
    var value = e.target.value;

    if (name === "appointmentID") {
      this.setState({
        appointmentID: value,
      });
    }
    if (name === "customerNumber") {
      this.setState({
        customerNumber: value,
        customerNumber2: "",
      });
    }
  };
  handleAppointmentclearSearch = () => {
    this.setState({
      isSearch: false,
      isSearchData: false,
      isTableRowClick: false,
      appointmentID: "",
      customerNumber2: "",
      startDate: null,
      endDate: null,
      storeStaffId: null,
    });
    this.handleAppointmentGridData();
  };

  handleSearchDatePickerEndChange = (date) => {
    if (this.state.isMobileView) {
      this.setState({
        endDate: date,
      });
    } else {
      this.setState({
        endDate: moment(date).toString(),
      });
    }
  };
  handleFilterSearchClick = () => {
    this.setState({
      isSearch: true,
    });
  };
  HandleToggleSearch = () => {
    this.setState({ collapseSearch: !this.state.collapseSearch });
  };

  //handle get agent list
  handleGetAgentList() {
    let self = this;
    if (window.localStorage.getItem("AgentList")) {
      var agentData = JSON.parse(window.localStorage.getItem("AgentList"));
      this.setState({ agentData });
    } else {
      this.ChatService.Post("/CustomerChat/GetAgentList")
        .then((response) => {
          var message = response.message;
          var agentData = response.responseData;
          if (message === "Success" && agentData) {
            window.localStorage.setItem("AgentList", JSON.stringify(agentData));
            self.setState({ agentData });
          } else {
            self.setState({ agentData: [] });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }
  handleTableFiledChange = (name, item, e) => {
    let tempAppointmentData = this.state.appointmentGridData;
    if (name === "StaffName") {
      tempAppointmentData.filter(
        (x) => x.appointmentID === item.appointmentID
      )[0].storeStaffNameID = e;
    } else if (name === "Status") {
      tempAppointmentData.filter(
        (x) => x.appointmentID === item.appointmentID
      )[0].status = e;
    }
    this.setState({
      appointmentGridData: tempAppointmentData,
    });
  };
  handleChangeComment = (item, e) => {
    let tempAppointmentData = this.state.appointmentGridData;
    tempAppointmentData.filter(
      (x) => x.appointmentID === item.appointmentID
    )[0].comment = e.target.value;
    this.setState({
      appointmentGridData: tempAppointmentData,
    });
  };
  handleStoreStaffNameChange = (e) => {
    this.setState({
      storeStaffId: e,
    });
  };

  handleVideoCallAppointmentSearch = (e) => {
    console.log(
      "isFilterSearchInMobileVisual ",
      this.state.isFilterSearchInMobileVisual
    );
    this.setState({
      isFilterSearchInMobileVisual: !this.state.isFilterSearchInMobileVisual,
    });
  };

  handleStoreStaffNameChangeInMobileView = (event) => {
    // console.log(event.target.value);
    this.setState({
      storeStaffId: event,
    });
  };

  handleAppointmentclearSearchInMobile = () => {
    this.setState({
      isSearch: false,
      isSearchData: false,
      isTableRowClick: false,
      appointmentID: "",
      customerNumber: "",
      startDate: null,
      endDate: null,
      storeStaffId: null,
    });
  };

  handleTableFiledChangeInMobile = (name, item, e) => {
    let tempAppointmentData = this.state.appointmentGridData;
    if (name === "StaffName") {
      tempAppointmentData.filter(
        (x) => x.appointmentID === item.appointmentID
      )[0].storeStaffNameID = e;
    } else if (name === "Status") {
      tempAppointmentData.filter(
        (x) => x.appointmentID === item.appointmentID
      )[0].status = e;
    }
    this.setState({
      appointmentGridData: tempAppointmentData,
    });
  };

  handleUpdateAppointmentMobileInMobile = (
    appointmentID,
    statusId,
    storeStaffNameId,
    comment
  ) => {
    let self = this;
    let inputData = {
      AppointmentId: appointmentID || 0,
      AppointmentStatus: statusId || 0,
      StoreStaffNameId: storeStaffNameId || 0,
      Comment: comment || "",
    };
    this.AppointmentService.PostWithData(
      "/Appointment/UpdateVideoAppointmentDetails",
      inputData
    )
      .then((response) => {
        let status = response.message;
        if (status === "Success") {
          self.setState({
            isSearch: false,
            isSearchData: false,
            isTableRowClick: false,
          });
          NotificationManager.success("Record updated successFully.");
          self.handleAppointmentGridData();
        } else {
          NotificationManager.error(status);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  render() {
    const { Option } = Select;
    const TranslationContext = this.state.translateLanguage.default;
    return (
      // <>
      //   {this.state.isLoading ? (
      //     <div className="programcodepgnldr">
      //       <br />
      //       <br />
      //       <Spin wrapperClassName="programcodepgnldr" tip="Loading..."></Spin>
      //     </div>
      //   ) :
      <>
        {!this.state.isMobileView ? (
          <div
            className="custom-tableak custom-table-ck custom-table-bg apoinmentmobvi"
            style={{
              maxHeight: this.state.isMobileView
                ? this.state.isSearch
                  ? ""
                  : "calc(100vh - 130px)"
                : "",
              overflow: this.state.isMobileView
                ? this.state.isSearch
                  ? ""
                  : "auto"
                : "",
            }}
          >
            {this.state.isMobileView && !this.state.isSearch ? (
              <div
                className="filter"
                onClick={this.handleFilterSearchClick.bind(this)}
              >
                <img src={Filter} className="fil" alt="Filter" width="15px" />
              </div>
            ) : null}
            <div className="appcusto">
              <span className="apleft apl">
                {this.state.isTableRowClick
                  ? "Appointment: " + this.state.appointmentDate
                  : this.state.isSearch
                    ? "Search Appointment"
                    : "Appointment:"}
              </span>
              {!this.state.isTableRowClick &&
                !this.state.isSearch &&
                !this.state.isSearchData ? (
                <span className="apright apl">Customers</span>
              ) : this.state.isTableRowClick || this.state.isSearch ? (
                <a
                  className="aprightbck apr"
                  onClick={this.handleAppointmentBackClick.bind(this)}
                >
                  Back
                </a>
              ) : null}
              {this.state.isSearchData ? (
                <a
                  className="aprightbck apr"
                  onClick={this.handleAppointmentclearSearch.bind(this)}
                >
                  {TranslationContext !== undefined
                    ? TranslationContext.label.clearsearch
                    : "CLEAR SEARCH"}
                </a>
              ) : null}
            </div>

            <div
              className="table-cntr store extendappoin"
              style={{ marginTop: this.state.isSearch ? "25px" : "" }}
            >
              {!this.state.isMobileView ? (
                <div
                  className="float-search"
                  onClick={this.HandleToggleSearch.bind(this)}
                >
                  <small>
                    {this.state.collapseSearch
                      ? "Close Search"
                      : "Search Appointment"}
                  </small>
                  {this.state.collapseSearch ? (
                    <img
                      className="search-icon"
                      src={CancalImg}
                      alt="search-icon"
                    />
                  ) : (
                    <img
                      className="search-icon"
                      src={SearchIcon}
                      alt="search-icon"
                    />
                  )}
                </div>
              ) : null}
              <div>
                {this.state.collapseSearch ? (
                  <Collapse
                    bordered={false}
                    activeKey={this.state.collapseSearch ? 1 : 0}
                    className="appointmentSearchcollp"
                  >
                    <Panel showArrow={false} key={1}>
                      <div className="myticlist-expand-sect">
                        <div className="position-relative">
                          <ul
                            className="nav nav-tabs lower-tabs"
                            role="tablist"
                          ></ul>
                          <div className="container-fluid">
                            <div
                              className="row all-row"
                              style={{ paddingTop: "25px" }}
                            >
                              <div className="col-md-3 col-sm-6 myticket-text m-10">
                                <input
                                  type="text"
                                  placeholder="Enter Appointment ID"
                                  name="appointmentID"
                                  autoComplete="off"
                                  value={this.state.appointmentID}
                                  onChange={this.handleSeachTextChange.bind(
                                    this
                                  )}
                                />
                              </div>
                              <div className="col-md-3 col-sm-6 myticket-text m-10">
                                <div className="fake-input" id="fake">
                                  <input
                                    type="text"
                                    placeholder="Enter Customer No"
                                    name="customerNumber"
                                    autoComplete="off"
                                    value={this.state.customerNumber2}
                                    onChange={this.handleSeachTextChange.bind(
                                      this
                                    )}
                                  />
                                  {this.state.isNumberMasking === true &&
                                    this.state.tenantMobileMasking === true ? (
                                    <img
                                      className="imgsz"
                                      src={paste}
                                      onClick={this.handlePasteNumber.bind(
                                        this
                                      )}
                                    />
                                  ) : null}
                                </div>
                              </div>
                              <div className="col-md-3 col-sm-6 m-10">
                                <DatePickerAnt
                                  id={"AppointmentStartDate"}
                                  size="large"
                                  style={{ width: "100%" }}
                                  placeholder={"Appointment Start Date"}
                                  className="ant-calendar-date-picker-input"
                                  onChange={this.handleSearchDatePickerChange}
                                  value={
                                    this.state.startDate
                                      ? moment(this.state.startDate)
                                      : null
                                  }
                                />
                              </div>
                              <div className="col-md-3 col-sm-6 m-10">
                                <DatePickerAnt
                                  id={"Appointment End Date"}
                                  size="large"
                                  style={{ width: "100%" }}
                                  value={
                                    this.state.endDate
                                      ? moment(this.state.endDate)
                                      : null
                                  }
                                  placeholder={"Appointment End Date"}
                                  className="ant-calendar-date-picker-input"
                                  onChange={this.handleSearchDatePickerEndChange.bind(
                                    this
                                  )}
                                />
                              </div>
                              <div className="col-md-3 col-sm-6 m-10">
                                <Select
                                  placeholder="Store Staff Name"
                                  style={{ width: "100%" }}
                                  value={this.state.storeStaffId}
                                  onChange={this.handleStoreStaffNameChange.bind(
                                    this
                                  )}
                                >
                                  <Option value={null}>
                                    Select Store Staff Name
                                  </Option>
                                  {this.state.agentData
                                    ? this.state.agentData.map((item, i) => {
                                      return (
                                        <Option
                                          value={Number(item.storeManagerID)}
                                        >
                                          {item.agentName}
                                        </Option>
                                      );
                                    })
                                    : null}
                                </Select>
                              </div>
                            </div>
                          </div>
                          <div
                            className="save-view-search mobileticket1"
                            style={{ top: "38%" }}
                          >
                            <button
                              type="button"
                              className="btn-inv"
                              onClick={this.handleAppointmentGridDataSearch.bind(
                                this
                              )}
                            >
                              {TranslationContext !== undefined
                                ? TranslationContext.button.viewsearch
                                : "View Search"}
                            </button>
                          </div>
                        </div>

                        {!this.state.isMobileView && this.state.isSearchData ? (
                          <div className="container-fluid myticlist-expand-sect">
                            <div className="row common-adv-padd justify-content-between">
                              <div className="col-auto d-flex align-items-center">
                                <p className="font-weight-bold mr-3">
                                  <span
                                    className="blue-clr"
                                    style={{ cursor: "default" }}
                                  >
                                    &nbsp;
                                  </span>
                                  {TranslationContext !== undefined
                                    ? TranslationContext.p.results
                                    : "Results : "}
                                  {this.state.appointmentGridData.length}
                                </p>
                                <label
                                  className="blue-clr fs-14"
                                  onClick={this.handleAppointmentclearSearch.bind(
                                    this
                                  )}
                                >
                                  {TranslationContext !== undefined
                                    ? TranslationContext.label.clearsearch
                                    : "CLEAR SEARCH"}
                                </label>
                              </div>
                            </div>
                          </div>
                        ) : null}
                      </div>
                    </Panel>
                  </Collapse>
                ) : null}
              </div>
              {!this.state.isTableRowClick && !this.state.isSearch ? (
                <Table
                  className="components-table-demo-nested antd-table-campaign custom-antd-table apptab"
                  columns={[
                    {
                      title:
                        TranslationContext !== undefined
                          ? TranslationContext.title.date
                          : "Date",
                      dataIndex: "appointmentDate",
                      render: (row, item) => {
                        return (
                          <div className="backcolo bord1">
                            <div className="appdatemob">
                              {item.appointmentDate}
                              {/* <div className="apptimemob">{item.timeSlot}</div> */}
                            </div>
                          </div>
                        );
                      },
                    },
                    {
                      title: "Appointment ID",
                      dataIndex: "appointmentID",
                    },
                    {
                      title:
                        TranslationContext !== undefined
                          ? TranslationContext.title.time
                          : "Time",
                      dataIndex: "timeSlot",
                      render: (row, item) => {
                        return (
                          <div className="backcolo bord1">
                            <div className="appdatemob">
                              {item.slotStart + " - " + item.slotEnd}
                            </div>
                          </div>
                        );
                      },
                    },
                    {
                      title: "Customer Info",
                      dataIndex: "mobileNo",
                      className: "appointment-desktop",
                      render: (row, item) => {
                        return (
                          // <div className="d-flex align-items-center overflow-hidden namenumbermain">
                          //   <div className="namenumber">
                          //     <p>{item.customerName},</p>
                          //     <p className="order-small-font">{item.mobileNo}</p>
                          //   </div>
                          // </div>
                          <div className="d-flex align-items-center overflow-hidden namenumbermain">
                            <div className="namenumber">
                              <p>{item.customerName},</p>
                              <div className="text_copied_div">
                                <p className="order-small-font">
                                  {this.state.isNumberMasking === true &&
                                    this.state.tenantMobileMasking === true ? (
                                    <p className="num">
                                      {handleMaskingNumber(
                                        item.mobileNo,
                                        this.state.maskingPrefix,
                                        this.state.maskingSufix
                                      )}
                                    </p>
                                  ) : (
                                    <p className="num stop_copying">
                                      {item.mobileNo}
                                    </p>
                                  )}
                                </p>
                                {this.state.isNumberMasking === true &&
                                  this.state.tenantMobileMasking === true ? (
                                  <p>
                                    <img
                                      className="imgsz"
                                      src={copy}
                                      onClick={(e) =>
                                        this.handleCopiedNumber(
                                          e,
                                          item.mobileNo,
                                          item.appointmentID
                                        )
                                      }
                                    />
                                  </p>
                                ) : null}
                                {this.state.isTextCopied &&
                                  this.state.copiedTextChatId ===
                                  item.appointmentID ? (
                                  <label className="text_copied">
                                    {" "}
                                    Copied{" "}
                                  </label>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        );
                      },
                    },

                    {
                      title: "Note",
                      dataIndex: "note",
                    },
                    {
                      title: "Store Staff Name",
                      className: "appointment-desktop",
                      render: (row, item) => {
                        return (
                          <div className="backcolo bord1">
                            <div className="appdatemob">
                              <Select
                                key={item.appointmentID}
                                value={item.storeStaffNameID}
                                style={{ width: "100%" }}
                                disabled={item.isDisable ? true : false}
                                onChange={this.handleTableFiledChange.bind(
                                  this,
                                  "StaffName",
                                  item
                                )}
                              >
                                {this.state.agentData
                                  ? this.state.agentData.map((aID, i) => {
                                    return (
                                      <Option
                                        value={Number(aID.storeManagerID)}
                                      >
                                        {aID.agentName}
                                      </Option>
                                    );
                                  })
                                  : null}
                              </Select>
                            </div>
                          </div>
                        );
                      },
                    },
                    {
                      title: "Comments",
                      dataIndex: "comment",
                      render: (row, item) => {
                        return (
                          <div className="backcolo bord1">
                            <div className="appdatemob">
                              <textarea
                                disabled={item.isDisable ? true : false}
                                onChange={this.handleChangeComment.bind(
                                  this,
                                  item
                                )}
                                value={item.comment}
                              ></textarea>
                            </div>
                          </div>
                        );
                      },
                    },
                    {
                      title:
                        TranslationContext !== undefined
                          ? TranslationContext.title.actions
                          : "Actions",
                      width: "20%",
                      render: (row, item) => {
                        return (
                          <div className="backcolo bord2">
                            <div className="d-flex justify-content-end">
                              <Select
                                disabled={item.isDisable ? true : false}
                                value={item.status}
                                placeholder="Status"
                                style={{ width: "100%", marginRight: "10px" }}
                                onChange={this.handleTableFiledChange.bind(
                                  this,
                                  "Status",
                                  item
                                )}
                              >
                                <Option value={0}>Cancel</Option>
                                <Option value={1}>Edit</Option>
                              </Select>
                              <button
                                disabled={item.isDisable ? true : false}
                                className={
                                  item.isDisable
                                    ? "butn btnClickDisabled"
                                    : "butn"
                                }
                                onClick={this.handleUpdateAppointmentMobile.bind(
                                  this,
                                  item.appointmentID,
                                  item.status,
                                  item.storeStaffNameID,
                                  item.comment
                                )}
                              >
                                Update
                              </button>
                            </div>
                          </div>
                        );
                      },
                    },
                  ]}
                  pagination={{ defaultPageSize: 10, showSizeChanger: true }}
                  showSizeChanger={true}
                  onShowSizeChange={true}
                  loading={this.state.loading}
                  dataSource={this.state.appointmentGridData}
                />
              ) : this.state.isSearch ? (
                <div className="myticlist-expand-sect">
                  <div className="container-fluid">
                    <div className="row all-row">
                      <div className="col-md-3 col-sm-6 myticket-text m-10">
                        <input
                          type="text"
                          placeholder="Enter Appointment ID"
                          name="appointmentID"
                          autoComplete="off"
                          onChange={this.handleSeachTextChange.bind(this)}
                        />
                      </div>
                      <div className="col-md-3 col-sm-6 myticket-text m-10">
                        <input
                          type="text"
                          placeholder="Enter Customer No"
                          name="customerNumber"
                          autoComplete="off"
                          onChange={this.handleSeachTextChange.bind(this)}
                        />
                      </div>
                      <div className="col-md-3 col-sm-6 m-10">
                        <DatePicker
                          selectsRange
                          autoComplete="off"
                          className="ant-calendar-date-picker-input"
                          placeholderText={"Appointment Date"}
                          // startDate={this.state.startDate}
                          // endDate={this.state.endDate}
                          selected={this.state.startDate}
                          dateFormat="dd/MM/yyyy"
                          onChange={this.handleSearchDatePickerChange.bind(
                            this
                          )}
                        />
                      </div>
                      <div className="col-md-3 col-sm-6 m-10">
                        <Select
                          placeholder="Store Staff Name"
                          className="ant-calendar-date-picker-input"
                        >
                          <Option>Select</Option>
                          {this.state.agentData
                            ? this.state.agentData.map((item, i) => {
                              return (
                                <Option value={Number(item.storeManagerID)}>
                                  {item.agentName}
                                </Option>
                              );
                            })
                            : null}
                        </Select>
                      </div>
                      <div
                        className="save-view-search mobileticket"
                        style={{ transform: "none", width: "100%" }}
                      >
                        <button
                          type="button"
                          className="btn-inv"
                          onClick={this.handleAppointmentGridDataSearch.bind(
                            this
                          )}
                        >
                          {TranslationContext !== undefined
                            ? TranslationContext.button.viewsearch
                            : "View Search"}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <AppointmentExpandComponent
                  appointmentCustomerListData={
                    this.state.appointmentCustomerListData
                  }
                  translateLanguage={this.state.translateLanguage}
                  handleUpdateAppointmentMobile={
                    this.handleUpdateAppointmentMobile
                  }
                />
              )}
            </div>
          </div>
        ) : (
          <div className="mobile_tabel_div">
            {!this.state.isFilterSearchInMobileVisual ? (
              <div
                className="filter_mobile_view"
                onClick={(e) => this.handleVideoCallAppointmentSearch(e)}
              >
                <img src={Filter} className="fil" alt="Filter" width="15px" />
              </div>
            ) : null}
            {!this.state.isFilterSearchInMobileVisual ? (
              <div>
                {this.state.appointmentGridData.length !== 0 ? (
                  this.state.appointmentGridData.map((item, index) => {
                    return (
                      <div key={index} className="table_view_mobile">
                        <label>
                          Date:
                          <span className="table_view_mobile_text">
                            {" "}
                            {item.appointmentDate}
                          </span>
                        </label>
                        <label>
                          Appointment ID:
                          <span className="table_view_mobile_text">
                            {" "}
                            {item.appointmentID}
                          </span>
                        </label>
                        <label>
                          Time:
                          <span className="table_view_mobile_text">
                            {" "}
                            {item.slotStart} - {item.slotEnd}
                          </span>
                        </label>
                        <label className="table_mobile_view_customer_info">
                          Customer Info:-<br></br>
                          Customer Name:
                          <span className="table_view_mobile_text">
                            {" "}
                            {item.customerName}
                          </span>
                          <br></br>
                          <label className="d-flex">
                            Mobile No.:
                            <span className="table_view_mobile_text d-flex justify-content-center align-items-center ">
                              {/* {item.mobileNo} */}
                              {this.state.isNumberMasking === true &&
                                this.state.tenantMobileMasking === true ? (
                                <p className="num">
                                  {handleMaskingNumber(
                                    item.mobileNo,
                                    this.state.maskingPrefix,
                                    this.state.maskingSufix
                                  )}
                                  <img
                                    className="imgsz"
                                    src={copy}
                                    onClick={(e) =>
                                      this.handleCopiedNumber(
                                        e,
                                        item.mobileNo,
                                        item.appointmentID
                                      )
                                    }
                                  />
                                  {this.state.isTextCopied &&
                                    this.state.copiedTextChatId ===
                                    item.appointmentID ? (
                                    <label className="text_copied">
                                      {" "}
                                      Copied{" "}
                                    </label>
                                  ) : null}
                                </p>
                              ) : (
                                <p className="num stop_copying">
                                  {item.mobileNo}
                                </p>
                              )}
                            </span>
                            <br></br>
                          </label>
                          Note:{" "}
                          <span className="table_view_mobile_text">
                            {" "}
                            {item.note}
                          </span>
                        </label>
                        <label></label>
                        <label>Store Staff Name:</label>
                        <div className="backcolo bord1">
                          <div className="">
                            <Select
                              key={item.appointmentID}
                              value={item.storeStaffNameID}
                              style={{ width: "100%" }}
                              disabled={item.isDisable ? true : false}
                              onChange={this.handleTableFiledChange.bind(
                                this,
                                "StaffName",
                                item
                              )}
                            >
                              {this.state.agentData
                                ? this.state.agentData.map((aID, i) => {
                                  return (
                                    <Option
                                      value={Number(aID.storeManagerID)}
                                    >
                                      {aID.agentName}
                                    </Option>
                                  );
                                })
                                : null}
                            </Select>
                          </div>
                        </div>
                        <label>
                          Comments:
                          {/* <span className='table_view_mobile_text'> {item.comment}</span> */}
                        </label>
                        <div className="">
                          <div className="">
                            <textarea
                              className="text_area_in_mobile_tabel"
                              disabled={item.isDisable ? true : false}
                              onChange={this.handleChangeComment.bind(
                                this,
                                item
                              )}
                              value={item.comment}
                            ></textarea>
                          </div>
                        </div>
                        <label>Actions</label>
                        <div className="backcolo bord2">
                          <div className="d-flex justify-content-end">
                            <Select
                              disabled={item.isDisable ? true : false}
                              value={item.status}
                              placeholder="Status"
                              style={{ width: "100%", marginRight: "10px" }}
                              onChange={this.handleTableFiledChange.bind(
                                this,
                                "Status",
                                item
                              )}
                            >
                              <Option value={0}>Cancel</Option>
                              <Option value={1}>Edit</Option>
                            </Select>

                            <button
                              disabled={item.isDisable ? true : false}
                              className={
                                item.isDisable
                                  ? "butn btnClickDisabled"
                                  : "butn"
                              }
                              onClick={this.handleUpdateAppointmentMobileInMobile.bind(
                                this,
                                item.appointmentID,
                                item.status,
                                item.storeStaffNameID,
                                item.comment
                              )}
                            >
                              Update
                            </button>
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : this.state.isLoading ? (
                  <div className="data_loader_div">
                    <div className="data_loading_loader"></div>
                    Loading...
                  </div>
                ) : (
                  <div className="data_loader_div">
                    <label className="label_icon_span">
                      <span>
                        <FontAwesomeIcon
                          icon={faTable}
                          className="circular-loader ml-2"
                        />
                      </span>
                      <span>No Data</span>
                    </label>
                  </div>
                )}
              </div>
            ) : (
              <div>
                <div className="div_back_clear">
                  <label
                    className="filter_back_mobile label_back_clear"
                    onClick={(e) => this.handleVideoCallAppointmentSearch(e)}
                  >
                    Back
                  </label>
                  <label
                    className="label_back_clear"
                    onClick={() => this.handleAppointmentclearSearchInMobile()}
                  >
                    Clear
                  </label>
                </div>
                <div>
                  <div className="myticlist-expand-sect">
                    <div className="container-fluid">
                      <div className="row all-row">
                        <div className={`myticket-text m-10 w-100`}>
                          <input
                            type="text"
                            placeholder="Enter Appointment ID"
                            name="appointmentID"
                            autoComplete="off"
                            onChange={this.handleSeachTextChange.bind(this)}
                          />
                        </div>
                        <div className={`myticket-text m-10 w-100`}>
                          <div className="fake-input" id="fake">
                            <input
                              type="text"
                              placeholder="Enter Customer No"
                              name="customerNumber"
                              autoComplete="off"
                              value={this.state.customerNumber2}
                              onChange={this.handleSeachTextChange.bind(this)}
                            />
                            {this.state.isNumberMasking === true &&
                              this.state.tenantMobileMasking === true ? (
                              <img
                                className="imgsz"
                                src={paste}
                                onClick={this.handlePasteNumber.bind(this)}
                              />
                            ) : null}
                          </div>
                        </div>
                        <div className={`m-10 w-100`}>
                          <DatePickerAnt
                            id={"AppointmentStartDate"}
                            size="large"
                            style={{ width: "100%" }}
                            placeholder={"Appointment Start Date"}
                            className="ant-calendar-date-picker-input"
                            onChange={this.handleSearchDatePickerChange}
                            value={
                              this.state.startDate
                                ? moment(this.state.startDate)
                                : null
                            }
                          />
                        </div>
                        <div className={`m-10 w-100`}>
                          <DatePickerAnt
                            id={"Appointment End Date"}
                            size="large"
                            style={{ width: "100%" }}
                            value={
                              this.state.endDate
                                ? moment(this.state.endDate)
                                : null
                            }
                            placeholder={"Appointment End Date"}
                            className="ant-calendar-date-picker-input"
                            onChange={this.handleSearchDatePickerEndChange.bind(
                              this
                            )}
                          />
                        </div>
                        <div className={`m-10 w-100`}>
                          <Select
                            className="rectangle-9"
                            // placeholder="Store Staff Name"
                            style={{ width: "100%" }}
                            value={this.state.storeStaffId}
                            onChange={
                              this.handleStoreStaffNameChangeInMobileView
                            }
                          >
                            <Option value={null} hidden>
                              Select Store Staff Name
                            </Option>
                            {this.state.agentData
                              ? this.state.agentData.map((item, i) => {
                                return (
                                  <Option
                                    key={i}
                                    value={Number(item.storeManagerID)}
                                  >
                                    {item.agentName}
                                  </Option>
                                );
                              })
                              : null}
                          </Select>
                        </div>
                        <div
                          className="save-view-search mobileticket"
                          style={{ transform: "none", width: "100%" }}
                        >
                          <button
                            type="button"
                            className="btn-inv"
                            onClick={this.handleAppointmentGridDataSearch.bind(
                              this
                            )}
                          >
                            {TranslationContext !== undefined
                              ? TranslationContext.button.viewsearch
                              : "View Search"}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </>
      //   }
      // </>
    );
  }
}
export default VideoCallAppointment;
