import React, { Component } from "react";
import { Table, Popconfirm, Collapse, Spin, Empty, Select } from "antd";
import Pagination from "react-pagination-js";
import "react-pagination-js/dist/styles.css";
import { NotificationManager } from "react-notifications";
import * as translationHI from "../../../translations/hindi";
import * as translationMA from "../../../translations/marathi";
import OrderService from "../../../Services/Orders/OrderService";
import config from "../../../helpers/config";
const { Panel } = Collapse;
const { Option } = Select;
class ShipmentAssignedTab extends Component {
  constructor(props) {
    super(props);

    this.state = {
      shipmentAssignedGridData: [],
      totalCount: 0,
      assignCurrentPage: 1,
      assignPostsPerPage: 10,
      orderPopoverOverlay: false,
      ShipAssignLoading: false,
      translateLanguage: {},
      orderIDs: [41814156],
      orderSearchText: "",
      PartnerFilterData: [],
      strPartner: "",
      filterShipmentPartner: false,
      orderShipmentAssignData: {},
      isMobileView: false,
      activePanel: [],
      lastUpdateRow: null,
      UpdateOn: null,
    };
    this.OrderService = new OrderService();
  }
  componentDidMount() {
    this.handleGetShipmentAssignedData();
    this.handleGetShipmentAssignPartnerFilterData();
    if (window.localStorage.getItem("translateLanguage") === "hindi") {
      this.state.translateLanguage = translationHI;
    } else if (window.localStorage.getItem("translateLanguage") === "marathi") {
      this.state.translateLanguage = translationMA;
    } else {
      this.state.translateLanguage = {};
    }
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
  }
  resize() {
    if (window.innerWidth <= 1000) {
      this.setState({ isMobileView: true });
    } else {
      this.setState({ isMobileView: false });
    }
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.resize.bind(this));
    clearTimeout(this.intervalID);
  }
  handleShipmentAssignSearch = (searchData) => {
    this.setState({
      orderSearchText: searchData,
      assignCurrentPage: 1,
    });
    setTimeout(() => {
      this.handleGetShipmentAssignedData();
    }, 5);
  };
  /// ----------------API function start----------------------
  /// Handle Get grid data
  handleGetShipmentAssignedData() {
    let self = this;
    this.setState({
      ShipAssignLoading: true,
    });
    this.intervalID = setTimeout(
      this.handleGetShipmentAssignedData.bind(this),
      config.pageRefreshTiming
    );
    let inputData = {
      SearchText: this.state.orderSearchText,
      PageNo: this.state.assignCurrentPage,
      PageSize: this.state.assignPostsPerPage,
      FilterReferenceNo: "",
      CourierPartner: this.state.strPartner,
    };
    this.OrderService.PostWithData(
      "/HSOrder/GetShipmentAssignedDetails",
      inputData
    )
      .then((response) => {
        let status = response.message;
        let data = response.responseData;
        self.setState({ ShipAssignLoading: false });
        if (status === "Success") {
          data.shipmentAssigned.forEach((element) => {
            if (
              element.storeName &&
              element.staffName &&
              element.mobileNumber
            ) {
              element["isStaffDetails"] = true;
            } else {
              element["isStaffDetails"] = false;
            }
          });

          self.setState({
            shipmentAssignedGridData: data.shipmentAssigned,
            totalCount: data.totalCount,
            ShipAssignLoading: false,
            filterShipmentPartner: false,
          });
        } else {
          self.setState({
            shipmentAssignedGridData: [],
            totalCount: 0,
            ShipAssignLoading: false,
            filterShipmentPartner: false,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  /// get shipment assign partner filter data
  handleGetShipmentAssignPartnerFilterData() {
    let self = this;
    let inputParams = {
      pageID: 5,
    };
    this.OrderService.PostWithParams(
      "/HSOrder/GetCourierPartnerFilter",
      inputParams
    )
      .then((response) => {
        let status = response.message;
        let data = response.responseData;
        if (status === "Success") {
          self.setState({
            PartnerFilterData: data,
          });
        } else {
          self.setState({
            PartnerFilterData: [],
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  handleUpdateShipmentAssignedData(row, IsProceed, functionname) {
    const TranslationContext = this.state.translateLanguage.default;
    let self = this;
    if (row.awbNo !== "" && row.courierPartner.toLowerCase() !== "store") {
      if (row.referenceNo === "") {
        NotificationManager.error(
          TranslationContext !== undefined
            ? TranslationContext.alertmessage.pleaseenterpod
            : "Please enter POD."
        );
        return false;
      }
    } else if (functionname === 1) {
      if (row.storeName === "") {
        NotificationManager.error(
          TranslationContext !== undefined
            ? TranslationContext.alertmessage.pleaseenterstorename
            : "Please enter store name."
        );
        return false;
      }

      if (row.staffName === "") {
        NotificationManager.error(
          TranslationContext !== undefined
            ? TranslationContext.alertmessage.pleaseenterstaffname
            : "Please enter staff name."
        );
        return false;
      }

      if (row.mobileNumber === "") {
        NotificationManager.error(
          TranslationContext !== undefined
            ? TranslationContext.alertmessage.pleaseentermobilenumber
            : "Please enter mobile number."
        );
        return false;
      }
    } else if (functionname === 2) {
      if (row.courierstaffname === undefined) {
        NotificationManager.error(
          TranslationContext !== undefined
            ? TranslationContext.alertmessage.pleaseentercourierstaffname
            : "Please Enter Courier Staff Name."
        );
        return false;
      }
      if (row.courierstaffnumber === undefined) {
        NotificationManager.error(
          TranslationContext !== undefined
            ? TranslationContext.alertmessage.pleaseentercourierstaffnumber
            : "Please enter Courier Staff number."
        );
        return false;
      }
      if (row.remarks === undefined) {
        NotificationManager.error(
          TranslationContext !== undefined
            ? TranslationContext.alertmessage.pleaseenterremarks
            : "Please enter Courier Remarks."
        );
        return false;
      }
    }
    let inputData = {
      ShipmentAWBID: row.shipmentAWBID,
      OrderID: row.orderID,
      ReferenceNo: row.referenceNo,
      StoreName: row.storeName,
      StaffName: row.staffName,
      MobileNumber: row.mobileNumber,
      IsProceed: IsProceed,
      Remarks: row.remarks,
      CourierStaffName: row.courierstaffname,
      CourierStaffNumber: row.courierstaffnumber,
      UpdateOn: functionname,
    };
    this.OrderService.PostWithData(
      "/HSOrder/UpdateShipmentAssignedData",
      inputData
    )
      .then((response) => {
        let status = response.message;
        if (status === "Success") {
          self.handleGetShipmentAssignedData();
          NotificationManager.success(
            TranslationContext !== undefined
              ? TranslationContext.alertmessage.recordupdatedsuccessfully
              : "Record Updated Successfully."
          );

          self.setState({
            lastUpdateRow: row,
            orderPopoverOverlay: false,
          });
        } else {
          NotificationManager.error(status);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  handleUpdateDeliveredByShipAssigned(orderID, awbNo) {
    const TranslationContext = this.state.translateLanguage.default;
    let self = this;
    let inputParams = {
      orderID: orderID,
      AWBNo: awbNo,
    };
    this.OrderService.PostWithParams(
      "/HSOrder/UpdateShipmentAssignedDelivered",
      inputParams
    )
      .then((response) => {
        let status = response.message;
        if (status === "Success") {
          self.handleGetShipmentAssignedData();
          NotificationManager.success(
            TranslationContext !== undefined
              ? TranslationContext.alertmessage.recordupdatedsuccessfully
              : "Record Updated Successfully."
          );
          self.setState({
            orderPopoverOverlay: false,
          });
        } else {
          NotificationManager.error(status);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  handleUpdateShipmentAssignedRTO(orderID, awbNo) {
    const TranslationContext = this.state.translateLanguage.default;
    let self = this;
    let inputParams = {
      orderID: orderID,
      AWBNo: awbNo,
    };
    this.OrderService.PostWithParams(
      "/HSOrder/UpdateShipmentAssignedRTO",
      inputParams
    )
      .then((response) => {
        let status = response.message;
        if (status === "Success") {
          self.handleGetShipmentAssignedData();
          NotificationManager.success(
            TranslationContext !== undefined
              ? TranslationContext.alertmessage.recordupdatedsuccessfully
              : "Record Updated Successfully."
          );
          self.setState({
            orderPopoverOverlay: false,
          });
        } else {
          NotificationManager.error(status);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  handlePrintManifest(orderIds, awbNo) {
    let inputParams = {
      OrderIds: orderIds,
      AWBNo: awbNo,
    };
    this.OrderService.PostWithParams(
      "/HSOrder/ShipmentAssignedPrintManifest",
      inputParams
    )
      .then((response) => {
        let status = response.message;
        if (status === "Success") {
          window.location.href = response.responseData.manifestUrl;
        } else {
          NotificationManager.error(status);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  handlePrintLabelHtml(oderId, awbNo) {
    let inputParams = {
      orderId: oderId,
      AWBNo: awbNo,
    };
    this.OrderService.PostWithParams(
      "/HSOrder/GetPrintLabelDetails",
      inputParams
    )
      .then((response) => {
        let status = response.message;
        let data = JSON.stringify(response.responseData);
        if (status === "Success") {
          window.localStorage.setItem("OrderShipment", data);
          window.open("/ShipmentPrintHtml", "_blank");
        } else {
          NotificationManager.error(status);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  handlePrintLabel(shipmentId) {
    let inputParams = {
      ShipmentId: shipmentId,
    };
    this.OrderService.PostWithParams(
      "/HSOrder/ShipmentAssignedPrintLabel",
      inputParams
    )
      .then((response) => {
        let status = response.message;
        if (status === "Success") {
          window.location.href = response.responseData.label_url;
        } else {
          NotificationManager.error(status);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  /// handle Pr5int Invoice data
  handlePrintInvoice(orderIds) {
    let inputParams = {
      OrderIds: orderIds,
    };
    this.OrderService.PostWithParams(
      "/HSOrder/ShipmentAssignedPrintInvoice",
      inputParams
    )
      .then((response) => {
        let status = response.message;
        if (status === "Success") {
          window.location.href = response.responseData.invoice_url;
        } else {
          NotificationManager.error(status);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  /// ----------------API function end----------------------
  AssignedPaginationOnChange = async (numPage) => {
    await this.setState({
      assignCurrentPage: numPage,
    });

    this.handleGetShipmentAssignedData();
  };

  handleAssignedPageItemchange = async (e) => {
    await this.setState({
      assignPostsPerPage: e,
      assignCurrentPage: 1,
    });

    this.handleGetShipmentAssignedData();
  };
  /// handle Close Partner filter
  handleClosePartnerFilter() {
    this.setState({
      filterShipmentPartner: false,
    });
  }

  handlechange(i, e) {
    var name = e.target.name;
    let shipmentAssignedGridData = [...this.state.shipmentAssignedGridData];
    if (name === "mobileNumber") {
      var reg = /^[0-9\b]+$/;
      if (e.target.value === "" || reg.test(e.target.value)) {
        shipmentAssignedGridData[i] = {
          ...shipmentAssignedGridData[i],
          [e.target.name]: e.target.value,
        };
        this.setState({
          shipmentAssignedGridData,
        });
      } else {
        e.target.value = "";
      }
    } else {
      shipmentAssignedGridData[i] = {
        ...shipmentAssignedGridData[i],
        [e.target.name]: e.target.value,
      };
      this.setState({
        shipmentAssignedGridData,
      });
    }
  }

  /// handle check partner status
  handleCheckPartnerOnchange() {
    var checkboxes = document.getElementsByName("ShipmentPartner");
    var strPartner = "";
    for (var i in checkboxes) {
      if (isNaN(i) === false) {
        if (checkboxes[i].checked === true) {
          if (checkboxes[i].getAttribute("attrIds") !== null)
            strPartner += checkboxes[i].getAttribute("attrIds") + ",";
        }
      }
    }
    this.setState({
      strPartner,
      currentPage: 1,
    });
  }
  handlecollapseChange = (e) => {
    this.state.activePanel = e[e.length - 1];
    this.setState({ activePanel: this.state.activePanel });
  };
  render() {
    const TranslationContext = this.state.translateLanguage.default;
    return (
      <>
        {this.state.orderPopoverOverlay && (
          <div className="order-popover-overlay"></div>
        )}
        <div className="table-cntr store dv-table-paging shipassign">
          <p className="shopi">
            {TranslationContext !== undefined
              ? TranslationContext.a.shipmentassigned
              : "Shipment Assigned"}
          </p>
          {!this.state.isMobileView ? (
            <Table
              className="components-table-demo-nested antd-table-campaign antd-table-order custom-antd-table"
              columns={[
                {
                  title:
                    TranslationContext !== undefined
                      ? TranslationContext.title.awbno
                      : "AWB No.",
                  dataIndex: "awbNo",
                  key: "awbNo",
                  className: "awbnone",
                },
                {
                  title:
                    TranslationContext !== undefined
                      ? TranslationContext.label.orderid
                      : "Order ID",
                  dataIndex: "invoiceNo",
                  render: (row, item) => {
                    return (
                      <div className="namenumbermain">
                        {item.invoiceNo}
                        <div className="namenumber">{item.courierPartner}</div>
                      </div>
                    );
                  },
                },
                {
                  title:
                    TranslationContext !== undefined
                      ? TranslationContext.title.courierpartner
                      : "Courier Partner",
                  dataIndex: "courierPartner",
                  className:
                    "shopping-delivery-header camp-status-header courier-shipment-header camp-status-header-statusFilter table-coloum-hide order-status-header",
                  filterDropdown: (data, row) => {
                    return (
                      <div className="campaign-status-drpdwn">
                        <ul>
                          {this.state.PartnerFilterData !== null &&
                            this.state.PartnerFilterData.map((item, p) => {
                              return (
                                <li key={p}>
                                  <input
                                    type="checkbox"
                                    id={"New" + item}
                                    className="ch1"
                                    onChange={this.handleCheckPartnerOnchange.bind(
                                      this
                                    )}
                                    name="ShipmentPartner"
                                    attrIds={item}
                                  />
                                  <label htmlFor={"New" + item}>
                                    <span className="ch1-text">{item}</span>
                                  </label>
                                </li>
                              );
                            })}
                        </ul>
                        <div className="dv-status">
                          <button
                            className="btn-apply-status"
                            onClick={this.handleGetShipmentAssignedData.bind(
                              this
                            )}
                          >
                            {TranslationContext !== undefined
                              ? TranslationContext.button.apply
                              : "Apply"}
                          </button>
                          <button
                            className="btn-cancel-status"
                            onClick={this.handleClosePartnerFilter.bind(this)}
                          >
                            {TranslationContext !== undefined
                              ? TranslationContext.button.cancel
                              : "Cancel"}
                          </button>
                        </div>
                      </div>
                    );
                  },
                  filterDropdownVisible: this.state.filterShipmentPartner,
                  onFilterDropdownVisibleChange: (visible) =>
                    this.setState({ filterShipmentPartner: visible }),
                  filterIcon: (filtered) => (
                    <span
                      style={{ color: filtered ? "#1890ff" : undefined }}
                    ></span>
                  ),
                },
                {
                  title:
                    TranslationContext !== undefined
                      ? TranslationContext.title.actions
                      : "Action",
                  className: "cus-strecth",
                  render: (row, item, index) => {
                    return item.awbNo !== "" &&
                      item.courierPartner.toLowerCase() !== "store" ? (
                      <div className="d-flex acbtn">
                        <>
                          {item.showOnlyPrintLabel ? (
                            <button
                              className="butn order-grid-butn order-grid-butn-yellow assign-grid-btn"
                              onClick={this.handlePrintLabelHtml.bind(
                                this,
                                item.orderID,
                                item.awbNo
                              )}
                            >
                              {TranslationContext !== undefined
                                ? TranslationContext.button.printlabel
                                : "Print Label"}
                            </button>
                          ) : (
                            <>
                              <button
                                className="butn order-grid-butn order-grid-butn-yellow assign-grid-btn"
                                onClick={this.handlePrintLabel.bind(
                                  this,
                                  item.courierPartnerShipmentID
                                )}
                              >
                                {TranslationContext !== undefined
                                  ? TranslationContext.button.printlabel
                                  : "Print Label"}
                              </button>
                              <button
                                className="butn order-grid-butn assign-grid-btn"
                                onClick={this.handlePrintManifest.bind(
                                  this,
                                  item.courierPartnerOrderID
                                )}
                              >
                                {TranslationContext !== undefined
                                  ? TranslationContext.button.printmanifest
                                  : "Print Manifest"}
                              </button>

                              {/* ---------------------------Please don't remove this code----------------------- */}
                              {/* <button
                        className="butn order-grid-butn order-grid-butn-green assign-grid-btn"
                        onClick={this.handlePrintInvoice.bind(
                          this,
                          item.courierPartnerOrderID
                        )}
                      >
                        {TranslationContext !== undefined
                          ? TranslationContext.button.printinvoice
                          : "Print Invoice"}
                      </button> */}
                              {/* ---------------------------Please don't remove this code----------------------- */}
                            </>
                          )}
                        </>
                      </div>
                    ) : (
                      <div className="d-flex acbtn">
                        <button
                          className="butn order-grid-butn order-grid-butn-yellow assign-grid-btn"
                          onClick={this.handlePrintLabelHtml.bind(
                            this,
                            item.orderID,
                            item.awbNo
                          )}
                        >
                          {TranslationContext !== undefined
                            ? TranslationContext.button.printlabel
                            : "Print Label"}
                        </button>
                        <Popconfirm
                          title={
                            <>
                              <div className="">
                                <label>
                                  {TranslationContext !== undefined
                                    ? TranslationContext.label.storename
                                    : "Store Name"}
                                </label>
                                <input
                                  type="text"
                                  name="storeName"
                                  className="form-control"
                                  placeholder={
                                    TranslationContext !== undefined
                                      ? TranslationContext.placeholder
                                        .enterstorename
                                      : "Enter Store Name"
                                  }
                                  value={item.storeName}
                                  onChange={this.handlechange.bind(this, index)}
                                />
                                <label>
                                  {TranslationContext !== undefined
                                    ? TranslationContext.label.staffname
                                    : "Staff Name"}
                                </label>
                                <input
                                  type="text"
                                  name="staffName"
                                  className="form-control"
                                  placeholder={
                                    TranslationContext !== undefined
                                      ? TranslationContext.placeholder
                                        .enterstaffname
                                      : "Enter Staff Name"
                                  }
                                  value={item.staffName}
                                  onChange={this.handlechange.bind(this, index)}
                                />
                                <label>
                                  {TranslationContext !== undefined
                                    ? TranslationContext.label.mobileno
                                    : "Mobile No."}
                                </label>
                                <input
                                  type="text"
                                  name="mobileNumber"
                                  className="form-control"
                                  placeholder={
                                    TranslationContext !== undefined
                                      ? TranslationContext.placeholder
                                        .entermobileno
                                      : "Enter Mobile No."
                                  }
                                  value={item.mobileNumber}
                                  onChange={this.handlechange.bind(this, index)}
                                />
                                <label>Remarks</label>
                                <textarea
                                  name="remarks"
                                  value={item.remarks}
                                  onChange={this.handlechange.bind(this, index)}
                                ></textarea>
                              </div>
                            </>
                          }
                          overlayClassName="order-popover order-popover-butns order-popover-address"
                          placement="topLeft"
                          onVisibleChange={(visible) =>
                            this.setState({ orderPopoverOverlay: visible })
                          }
                          icon={false}
                          okText="Done"
                          onConfirm={this.handleUpdateShipmentAssignedData.bind(
                            this,
                            item,
                            false,
                            1
                          )}
                        >
                          <button className="butn order-grid-butn assign-grid-btn">
                            {TranslationContext !== undefined
                              ? TranslationContext.button.staffdetails
                              : "Staff Details"}
                          </button>
                        </Popconfirm>

                        {/* <Popconfirm title={
                          <>
                            <div className="">
                              <label>
                                {TranslationContext !== undefined
                                  ? TranslationContext.label.courierstaffname
                                  : "Courier Staff Name"}
                              </label>
                              <input
                                type="text"
                                name="courierstaffname"
                                className="form-control"
                                placeholder={
                                  TranslationContext !== undefined
                                    ? TranslationContext.placeholder
                                      .entercourierstaffname
                                    : "Enter Courier Staff Name"
                                }
                                value={item.courierstaffname}
                                onChange={this.handlechange.bind(this, index)}
                              />
                              <label>
                                {TranslationContext !== undefined
                                  ? TranslationContext.label.courierstaffnumber
                                  : "Courier Staff Number."}
                              </label>
                              <input
                                type="text"
                                name="courierstaffnumber"
                                className="form-control"
                                placeholder={
                                  TranslationContext !== undefined
                                    ? TranslationContext.placeholder
                                      .entercourierstaffnumber
                                    : "Enter Courier Staff Nnumber."
                                }
                                value={item.courierstaffnumber}
                                onChange={this.handlechange.bind(this, index)}
                              />
                              <label>Remarks</label>
                              <textarea
                                name="remarks"
                                value={item.remarks}
                                placeholder="Courier Remarks"
                                onChange={this.handlechange.bind(this, index)}
                              ></textarea>
                            </div>
                          </>
                        }
                          overlayClassName="order-popover order-popover-butns order-popover-address"
                          placement="topLeft"
                          onVisibleChange={(visible) =>
                            this.setState({ orderPopoverOverlay: visible })
                          }
                          icon={false}
                          okText="Done"
                          onConfirm={this.handleUpdateShipmentAssignedData.bind(
                            this,
                            item,
                            false,
                            2
                          )}
                        >
                          <button className="butn order-grid-butn assign-grid-btn">
                            {TranslationContext !== undefined
                              ? TranslationContext.button.dispatchcourier
                              : "Dispatch courier"}
                          </button>
                        </Popconfirm> */}
                        <button
                          className={
                            item.isStaffDetails &&
                              item.storeName !== "" &&
                              item.staffName !== "" &&
                              item.mobileNumber !== ""
                              ? "butn order-grid-butn order-grid-butn-yellow assign-grid-btn"
                              : "butn order-grid-butn order-grid-butn-yellow assign-grid-btn order-grid-btn-disable"
                          }
                          onClick={this.handleUpdateShipmentAssignedRTO.bind(
                            this,
                            item.orderID,
                            item.awbNo
                          )}
                          disabled={
                            item.isStaffDetails &&
                              item.storeName !== "" &&
                              item.staffName !== "" &&
                              item.mobileNumber !== ""
                              ? false
                              : true
                          }
                        >
                          {TranslationContext !== undefined
                            ? TranslationContext.button.rto
                            : "RTO"}
                        </button>
                        <button
                          className={
                            item.isStaffDetails &&
                              item.storeName !== "" &&
                              item.staffName !== "" &&
                              item.mobileNumber !== ""
                              ? "butn order-grid-butn order-grid-butn-green assign-grid-btn"
                              : "butn order-grid-butn order-grid-butn-green assign-grid-btn order-grid-btn-disable"
                          }
                          onClick={this.handleUpdateDeliveredByShipAssigned.bind(
                            this,
                            item.orderID,
                            item.awbNo
                          )}
                          disabled={
                            item.isStaffDetails &&
                              item.storeName !== "" &&
                              item.staffName !== "" &&
                              item.mobileNumber !== ""
                              ? false
                              : true
                          }
                        >
                          {TranslationContext !== undefined
                            ? TranslationContext.button.delivered
                            : "Delivered"}
                        </button>
                      </div>
                    );
                  },
                  width: 120,
                },
              ]}
              pagination={false}
              showSizeChanger={true}
              onShowSizeChange={true}
              dataSource={this.state.shipmentAssignedGridData}
              loading={this.state.ShipAssignLoading}
            />
          ) : (
            <>
              <Pagination
                currentPage={this.state.assignCurrentPage}
                totalSize={this.state.totalCount}
                sizePerPage={this.state.assignPostsPerPage}
                changeCurrentPage={this.AssignedPaginationOnChange}
                theme="bootstrap"
              />
              <div className="position-relative">
                <div className="item-selection Camp-pagination">
                  <Select
                    value={this.state.assignPostsPerPage}
                    onChange={this.handleAssignedPageItemchange}
                  >
                    <Option value={10}>10</Option>
                    <Option value={20}>20</Option>
                    <Option value={30}>30</Option>
                  </Select>
                  <p>
                    {TranslationContext !== undefined
                      ? TranslationContext.p.itemsperpage
                      : "Items per page"}
                  </p>
                </div>
              </div>
              <Spin spinning={this.state.ShipAssignLoading}>
                <Collapse
                  bordered={false}
                  className="site-collapse-custom-collapse"
                  expandIconPosition={"right"}
                  destroyInactivePanel={true}
                  onChange={this.handlecollapseChange.bind(this)}
                  activeKey={this.state.activePanel}
                >
                  {this.state.shipmentAssignedGridData.length > 0
                    ? this.state.shipmentAssignedGridData.map((item, index) => {
                      return (
                        <Panel
                          header={
                            <div className="mobdevice">
                              <p className="itemaw">{item.awbNo}</p>
                              <div className="partner">
                                <p className="orderno">{item.invoiceNo}</p>
                                <p>Partner :&nbsp;</p>
                                <p>{item.courierPartner}</p>
                              </div>
                            </div>
                          }
                        >
                          {item.awbNo !== "" &&
                            item.courierPartner.toLowerCase() !== "store" ? (
                            <div className="d-flex justify-content-center">
                              <>
                                {item.showOnlyPrintLabel ? (
                                  <button
                                    className="butn order-grid-butn order-grid-butn-yellow assign-grid-btn"
                                    onClick={this.handlePrintLabelHtml.bind(
                                      this,
                                      item.orderID,
                                      item.awbNo
                                    )}
                                  >
                                    {TranslationContext !== undefined
                                      ? TranslationContext.button.printlabel
                                      : "Print Label"}
                                  </button>
                                ) : (
                                  <>
                                    <button
                                      className="butn order-grid-butn order-grid-butn-yellow assign-grid-btn"
                                      onClick={this.handlePrintLabel.bind(
                                        this,
                                        item.courierPartnerShipmentID
                                      )}
                                    >
                                      {TranslationContext !== undefined
                                        ? TranslationContext.button.printlabel
                                        : "Print Label"}
                                    </button>
                                    <button
                                      className="butn order-grid-butn assign-grid-btn"
                                      onClick={this.handlePrintManifest.bind(
                                        this,
                                        item.courierPartnerOrderID
                                      )}
                                    >
                                      {TranslationContext !== undefined
                                        ? TranslationContext.button
                                          .printmanifest
                                        : "Print Manifest"}
                                    </button>

                                    {/* ---------------------------Please don't remove this code----------------------- */}
                                    {/* <button className="butn order-grid-butn order-grid-butn-green assign-grid-btn"
                                               onClick={this.handlePrintInvoice.bind(
                                                 this,
                                                 item.courierPartnerOrderID
                                               )}
                                             >
                                               {TranslationContext !== undefined
                                                 ? TranslationContext.button.printinvoice
                                                 : "Print Invoice"}
                                        </button> */}
                                    {/* ---------------------------Please don't remove this code----------------------- */}
                                  </>
                                )}
                              </>
                            </div>
                          ) : (
                            <div className="d-flex justify-content-center">
                              <button
                                className="butn order-grid-butn order-grid-butn-yellow assign-grid-btn"
                                onClick={this.handlePrintLabelHtml.bind(
                                  this,
                                  item.orderID,
                                  item.awbNo
                                )}
                              >
                                {TranslationContext !== undefined
                                  ? TranslationContext.button.printlabel
                                  : "Print Label"}
                              </button>
                              <Popconfirm
                                title={
                                  <>
                                    <div className="">
                                      <label>
                                        {TranslationContext !== undefined
                                          ? TranslationContext.label.storename
                                          : "Store Name"}
                                      </label>
                                      <input
                                        type="text"
                                        name="storeName"
                                        className="form-control"
                                        placeholder={
                                          TranslationContext !== undefined
                                            ? TranslationContext.placeholder
                                              .enterstorename
                                            : "Enter Store Name"
                                        }
                                        value={item.storeName}
                                        onChange={this.handlechange.bind(
                                          this,
                                          index
                                        )}
                                      />
                                      <label>
                                        {TranslationContext !== undefined
                                          ? TranslationContext.label.staffname
                                          : "Staff Name"}
                                      </label>
                                      <input
                                        type="text"
                                        name="staffName"
                                        className="form-control"
                                        placeholder={
                                          TranslationContext !== undefined
                                            ? TranslationContext.placeholder
                                              .enterstaffname
                                            : "Enter Staff Name"
                                        }
                                        value={item.staffName}
                                        onChange={this.handlechange.bind(
                                          this,
                                          index
                                        )}
                                      />
                                      <label>
                                        {TranslationContext !== undefined
                                          ? TranslationContext.label.mobileno
                                          : "Mobile No."}
                                      </label>
                                      <input
                                        type="text"
                                        name="mobileNumber"
                                        className="form-control"
                                        placeholder={
                                          TranslationContext !== undefined
                                            ? TranslationContext.placeholder
                                              .entermobileno
                                            : "Enter Mobile No."
                                        }
                                        value={item.mobileNumber}
                                        onChange={this.handlechange.bind(
                                          this,
                                          index
                                        )}
                                      />
                                      <label>Remarks</label>
                                      <textarea
                                        name="remarks"
                                        value={item.remarks}
                                        onChange={this.handlechange.bind(
                                          this,
                                          index
                                        )}
                                      ></textarea>
                                    </div>
                                  </>
                                }
                                overlayClassName="order-popover order-popover-butns order-popover-address"
                                placement="topLeft"
                                onVisibleChange={(visible) =>
                                  this.setState({
                                    orderPopoverOverlay: visible,
                                  })
                                }
                                icon={false}
                                okText="Done"
                                onConfirm={this.handleUpdateShipmentAssignedData.bind(
                                  this,
                                  item,
                                  false,
                                  1
                                )}
                              >
                                <button cslassName="butn order-grid-butn assign-grid-btn">
                                  {TranslationContext !== undefined
                                    ? TranslationContext.button.staffdetails
                                    : "Staff Details"}
                                </button>
                              </Popconfirm>
                              {/* ---------------------------Please don't remove this code----------------------- */}
                              {/* <Popconfirm
                                title={
                                  <>
                                    <div className="">
                                      <label>
                                        {TranslationContext !== undefined
                                          ? TranslationContext.label.courierstaffname
                                          : "Courier Staff Name"}
                                      </label>
                                      <input
                                        type="text"
                                        name="courierstaffName"
                                        className="form-control"
                                        placeholder={
                                          TranslationContext !== undefined
                                            ? TranslationContext.placeholder
                                              .entercourierstaffname
                                            : "Enter Courier Staff Name"
                                        }
                                        value={item.courierstaffname}
                                        onChange={this.handlechange.bind(
                                          this,
                                          index
                                        )}
                                      />
                                      <label>
                                        {TranslationContext !== undefined
                                          ? TranslationContext.label.courierstaffnumber
                                          : "Courier Staff Number."}
                                      </label>
                                      <input
                                        type="text"
                                        name="courierstaffnumber"
                                        className="form-control"
                                        placeholder={
                                          TranslationContext !== undefined
                                            ? TranslationContext.placeholder
                                              .entercourierstaffnumber
                                            : "Enter Courier Staff Number."
                                        }
                                        value={item.courierstaffnumber}
                                        onChange={this.handlechange.bind(
                                          this,
                                          index
                                        )}
                                      />
                                      <label>Remarks</label>
                                      <textarea
                                        name="remarks"
                                        value={item.remarks}
                                        onChange={this.handlechange.bind(
                                          this,
                                          index
                                        )}
                                      ></textarea>
                                    </div>
                                  </>
                                }
                                overlayClassName="order-popover order-popover-butns order-popover-address"
                                placement="topLeft"
                                onVisibleChange={(visible) =>
                                  this.setState({
                                    orderPopoverOverlay: visible,
                                  })
                                }
                                icon={false}
                                okText="Done"
                                onConfirm={this.handleUpdateShipmentAssignedData.bind(
                                  this,
                                  item,
                                  false,
                                  2
                                )}
                              >
                                <button className="butn order-grid-butn assign-grid-btn">
                                  {TranslationContext !== undefined
                                    ? TranslationContext.button.dispatchcourier
                                    : "Dispatch Courier"}
                                </button>
                              </Popconfirm> */}
                                {/* ---------------------------Please don't remove this code----------------------- */}
                                <button
                                  className={
                                    item.storeName !== "" &&
                                    item.staffName !== "" &&
                                    item.mobileNumber !== ""
                                    ? "butn order-grid-butn order-grid-butn-yellow assign-grid-btn"
                                    : "butn order-grid-butn order-grid-butn-yellow assign-grid-btn order-grid-btn-disable"
                                }
                                onClick={this.handleUpdateShipmentAssignedRTO.bind(
                                  this,
                                  item.orderID,
                                  item.awbNo
                                )}
                                disabled={
                                  item.storeName !== "" &&
                                    item.staffName !== "" &&
                                    item.mobileNumber !== ""
                                    ? false
                                    : true
                                }
                              >
                                {TranslationContext !== undefined
                                  ? TranslationContext.button.rto
                                  : "RTO"}
                              </button>
                              <button
                                className={
                                  item.storeName !== "" &&
                                    item.staffName !== "" &&
                                    item.mobileNumber !== ""
                                    ? "butn order-grid-butn order-grid-butn-green assign-grid-btn"
                                    : "butn order-grid-butn order-grid-butn-green assign-grid-btn order-grid-btn-disable"
                                }
                                onClick={this.handleUpdateDeliveredByShipAssigned.bind(
                                  this,
                                  item.orderID,
                                  item.awbNo
                                )}
                                disabled={
                                  item.storeName !== "" &&
                                    item.staffName !== "" &&
                                    item.mobileNumber !== ""
                                    ? false
                                    : true
                                }
                              >
                                {TranslationContext !== undefined
                                  ? TranslationContext.button.delivered
                                  : "Delivered"}
                              </button>
                            </div>
                          )}
                        </Panel>
                      );
                    })
                    : null}
                </Collapse>

                {this.state.shipmentAssignedGridData.length === 0 ? (
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                ) : null}
              </Spin>
            </>
          )}
          {!this.state.isMobileView ? (
            <>
              <Pagination
                currentPage={this.state.assignCurrentPage}
                totalSize={this.state.totalCount}
                sizePerPage={this.state.assignPostsPerPage}
                changeCurrentPage={this.AssignedPaginationOnChange}
                theme="bootstrap"
              />
              <div className="position-relative">
                <div className="item-selection Camp-pagination">
                  <Select
                    value={this.state.assignPostsPerPage}
                    onChange={this.handleAssignedPageItemchange}
                  >
                    <Option value={10}>10</Option>
                    <Option value={20}>20</Option>
                    <Option value={30}>30</Option>
                  </Select>
                  <p>
                    {TranslationContext !== undefined
                      ? TranslationContext.p.itemsperpage
                      : "Items per page"}
                  </p>
                </div>
              </div>
            </>
          ) : null}
        </div>
      </>
    );
  }
}

export default ShipmentAssignedTab;
