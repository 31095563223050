import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter as Router, Route } from "react-router-dom";
import "react-app-polyfill/ie9";
import "react-app-polyfill/ie11";

//Css
import "./assets/css/style.css";
import "./assets/css/settings.css";
import "./assets/css/store.css";
import "./assets/css/custome.css";
import "react-table/react-table.css";
import "react-datepicker/dist/react-datepicker.css";
import "react-pagination-js/dist/styles.css";
import "react-notifications/lib/notifications.css";

//JS
import "./../node_modules/popper.js/dist/popper.js";
import "./../node_modules/bootstrap/dist/js/bootstrap.js";

/////js
import "./../node_modules/bootstrap/dist/js/bootstrap.min.js";

///Component

import StoreApp from "./routes/StoreManager/index";

import StoreProgramCode from "./Component/Store/StoreProgramCode";
import StoreSignIn from "./Component/Store/StoreSignIn";
import StoreForgotPassword from "./Component/Store/StoreForgotPassword";
import StoreUserForgotPassword from "./Component/Store/StoreUserForgotPassword";
import LanguageSelection from "./Component/Store/LanguageSelection";
import ShipmentPrintHtml from "./routes/StoreManager/OrderTabs/ShipmentPrintHtml";
import Logout from "./../src/routes/StoreManager/Logout";
import SessionExpired from "./../src/routes/StoreManager/SessionExpired";

import VideoSolution from "./routes/StoreManager/VideoSolution"

import { createStore } from 'redux';
import { Provider } from 'react-redux';
import reducers from './Reducer/Reducer'
import VideoCall from "./routes/StoreManager/VideoCall";

const store = createStore(reducers);

/**
* For mobile application to set token in local storage
*/
const queryParams = new URLSearchParams(window.location.search);
let authToken = queryParams.get('auth');
if (authToken) {
  authToken = authToken.split(' ').join('+');
  localStorage.setItem('token', authToken);
  let callUrl = queryParams.get('CallURL');
  // window.location.href = `https://${window.location.host}/`;
  if (window.location.hostname && callUrl) {
    if (window.location.hostname === 'localhost') {
      window.location.href = `http://${window.location.host}/${callUrl}`;
    } else {
      window.location.href = `https://${window.location.host}/${callUrl}`;
    }
  } else {
    if (window.location.hostname === 'localhost') {
      window.location.href = `https://${window.location.host}${window.location.pathname}`;
    }
    else {
      window.location.href = `https://${window.location.host}${window.location.pathname}`;
    }
  }
}

ReactDOM.render(
  <Provider store={store}>
    <Router>
      <Route path="/store" component={StoreApp} />

      <Route exact path="/languageSelection" component={LanguageSelection} />
      <Route exact path="/" component={StoreProgramCode} />
      <Route exact path="/storeSignIn" component={StoreSignIn} />
      <Route exact path="/storeForgotpassword" component={StoreForgotPassword} />
      <Route
        exact
        path="/storeUserForgotPassword"
        component={StoreUserForgotPassword}
      />
      <Route path="/ShipmentPrintHtml" component={ShipmentPrintHtml} />
      <Route path="/logout" component={Logout} />
      <Route path="/SessionExpired" component={SessionExpired} />
      {/* <Route exact path={`/video`} component={VideoSolution} /> */}
      {/* <Route exact path={`/video`} component={VideoCall} /> */}
    </Router>
  </Provider>,
  document.getElementById("root")
);

serviceWorker.unregister();
