import React, { Component } from "react";
import "react-app-polyfill/ie9";
import "react-app-polyfill/ie11";
import "./../../assets/css/custome.css";
import Logo from "./../../assets/Images/logo.jpg";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import ShopSter from "./../../assets/Images/Shopster.png";
import { encryption } from "../../helpers/encryption";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import SimpleReactValidator from "simple-react-validator";
import LoginService from "../../Services/Login/LoginService";
import CRMMasterService from "../../Services/Settings/CRMMasterService";
import config from "../../helpers/config";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import { connect } from "react-redux";
import ReCAPTCHA from "react-google-recaptcha";
//import DialogTitle from '@mui/material/DialogTitle';

class StoreSignIn extends Component {
  constructor(props) {
    super(props);

    this.state = {
      emailID: "",
      password: "",
      loading: false,
      programCode: "",
      isMobileView: false,
      emailIdValidation: "",
      passwordValidation: "",
      open: false,
      token: "",
      captchaValue:"",
      captchaResult:0,
      captchaInput:"",
      captchaValidation: false,
      reCaptchaValid: false,
      recaptchaLoaded: false,
    };
    this.LoginService = new LoginService();
    this.CRMMasterService = new CRMMasterService();
    this.hanleChange = this.hanleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.validator = new SimpleReactValidator();
    this.handleCRMRole = this.handleCRMRole.bind(this);
    this.handleonChange = this.handleonChange.bind(this);
    this._reCaptchaRef = React.createRef();
  }
  hanleChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
    if (!e.target.value) {
      this.setState({ emailIdValidation: "Enter User ID/EmailID." });
    } else {
      this.setState({ emailIdValidation: "" });
    }
  }

  handlePasswordChange = (e) => {
    this.setState({
      password: e.target.value,
    });
    if (!e.target.value) {
      this.setState({ passwordValidation: "Enter Password." });
    }
  };

  // handleOpen = () => {
  //   this.setState({ open : true });
  // };

  handleClose = () => {
    this.setState({ open: false, loading: false });
  };

  componentDidMount() {
    if (this.props.location.encProgramCode) {
      var finalEncProgramCode = this.props.location.encProgramCode;

      if (finalEncProgramCode) {
        this.setState({
          programCode: finalEncProgramCode,
        });
      } else {
        this.props.history.push("/");
      }
    } else {
      this.props.history.push("/");
    }
    //this.handleGetCaptaValue();
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
  }
  resize() {
    if (window.innerWidth <= 1000) {
      this.setState({ isMobileView: true });
    } else {
      this.setState({ isMobileView: false });
    }
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.resize.bind(this));
  }
  async handleSubmit(event) {
    event.preventDefault();
    //reCaptcha validation
    var grToken = await this._reCaptchaRef.current.executeAsync();
    //let validCaptcha = false;
    if (this.state.emailID) {
      this.setState({ emailIdValidation: "" });
    } else {
      this.setState({ emailIdValidation: "Enter User ID/EmailID." });
    }
    if (this.state.password) {
      this.setState({ emailIdValidation: "" });
    } else {
      this.setState({ passwordValidation: "Enter Password." });
    }
    let self = this;
    if (this.state.emailID && this.state.password && this.state.reCaptchaValid) {
      const { emailID, password } = this.state;
      var X_Authorized_userId = encryption(emailID, "enc");

      let X_Authorized_password = encryption(password, "enc");

      let X_Authorized_Devicesource = encryption(
        this.state.isMobileView ? "M" : "W",
        "enc"
      );
      var X_Authorized_GRToken = encryption(grToken, "enc");
      // let X_Authorized_Domainname = encryption(config.url, "enc");
      let X_Authorized_Domainname = encryption(window.location.origin, "enc");
      let X_Authorized_Programcode = this.state.programCode;
      if (X_Authorized_userId !== null && X_Authorized_password !== null) {
        this.LoginService.PostWithHeader("/StoreAccount/authenticateUser", {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "X-Authorized-Programcode": X_Authorized_Programcode,
          "X-Authorized-userId": X_Authorized_userId,
          "X-Authorized-password": X_Authorized_password,
          "X-Authorized-Domainname": X_Authorized_Domainname,
          "X-Authorized-FBID": "",
          "X-Authorized-DeviceID": "",
          "X-Authorized-Devicesource": X_Authorized_Devicesource,
          "X-Authorized-GRToken": X_Authorized_GRToken,
        })
          .then((response) => {
            //console.log(response);
            let resValid = response.message;
            self.setState({
              loading: true,
            });
            if (resValid === "Valid Login") {
              window.localStorage.setItem("token", response.responseData.token);
              window.localStorage.setItem("ERS", true);
              window.localStorage.setItem(
                "Programcode",
                this.state.programCode
              );
              window.localStorage.setItem("BellUserID", X_Authorized_userId);
              self.handleCRMRole();
              // self.props.history.push("languageSelection");
            } else if (
              response.responseData.message ===
              "User Already Loggedin In Another Device"
            ) {
              this.setState({ open: true, token: response.responseData.token });
              NotificationManager.error(
                response.responseData.message,
                "",
                1500
              );
            } else if(response.message === "Invalid Google ReCaptcha Token"){
                NotificationManager.error(
                  "Captcha Varifiaction Failed.",
                  "",
                  1500
                );
                self.setState({
                  loading: false,
                });
            } 
            else {
              NotificationManager.error(
                "Username or password is invalid.",
                "",
                1500
              );
              self.setState({
                loading: false,
              });
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  }

  handleLogin = () => {
    if (this.state.emailID) {
      this.setState({ emailIdValidation: "" });
    } else {
      this.setState({ emailIdValidation: "Enter User ID/EmailID." });
    }
    if (this.state.password) {
      this.setState({ emailIdValidation: "" });
    } else {
      this.setState({ passwordValidation: "Enter Password." });
    }
    let self = this;
    if (this.state.emailID && this.state.password && this.state.reCaptchaValid) {
      const { emailID, password } = this.state;
      var X_Authorized_userId = encryption(emailID, "enc");

      let X_Authorized_password = encryption(password, "enc");

      let X_Authorized_Devicesource = encryption(
        this.state.isMobileView ? "M" : "W",
        "enc"
      );
      // let X_Authorized_Domainname = encryption(config.url, "enc");
      let X_Authorized_Domainname = encryption(window.location.origin, "enc");
      let X_Authorized_Programcode = this.state.programCode;
      if (X_Authorized_userId !== null && X_Authorized_password !== null) {
        this.LoginService.PostWithHeader("/StoreAccount/authenticateUser", {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "X-Authorized-Programcode": X_Authorized_Programcode,
          "X-Authorized-userId": X_Authorized_userId,
          "X-Authorized-password": X_Authorized_password,
          "X-Authorized-Domainname": X_Authorized_Domainname,
          "X-Authorized-FBID": "",
          "X-Authorized-DeviceID": "",
          "X-Authorized-Devicesource": X_Authorized_Devicesource,
        })
          .then((response) => {
            let resValid = response.message;
            self.setState({
              loading: true,
            });
            if (resValid === "Valid Login") {
              window.localStorage.setItem("token", response.responseData.token);
              window.localStorage.setItem("ERS", true);
              window.localStorage.setItem(
                "Programcode",
                this.state.programCode
              );
              window.localStorage.setItem("BellUserID", X_Authorized_userId);
              self.handleCRMRole();
              // self.props.history.push("languageSelection");
            }
            // else if (response.responseData.token !== "" || response.responseData.token !== null) {
            //   this.setState({ open : true })
            //   window.localStorage.setItem("token", response.responseData.token);
            //}
            else {
              NotificationManager.error(
                "Username or password is invalid.",
                "",
                1500
              );
              self.setState({
                loading: false,
              });
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    } else {
      this.validator.showMessages();
      this.forceUpdate();
      this.handleClose();
    }
  };

  handleMISDashboardNavigation = () => {
    var UserData = JSON.parse(window.localStorage.getItem("UserData"));
    var ProgramCode = window.localStorage.getItem("Programcode");
    var MISDashboardLink =
      config.misUrl + UserData["userId"] + "&Programcode=" + ProgramCode;
    window.open(MISDashboardLink, "blank");
  };

  handleCRMRole() {
    let self = this;
    this.CRMMasterService.Post("/StoreCRMRole/GetStoreRolesByUserID")
      .then((response) => {
        let msg = response.message;
        let data = response.responseData.modules;
        if (msg === "Success") {
          self.setState({
            isNumberMasking: window.localStorage.setItem(
              "isNumberMasking",
              response.responseData.isNumberMasking
            ),
            maskingPrefix: window.localStorage.setItem(
              "maskingPrefix",
              response.responseData.maskingPrefix
            ),
            maskingSufix: window.localStorage.setItem(
              "maskingSufix",
              response.responseData.maskingSufix
            ),
            tenantMobileMasking: window.localStorage.setItem(
              "tenantMobileMasking",
              response.responseData.tenantLevelMasking
            ),
          });

          if (data !== null) {
            var isCallStorePayAPI = false;
            var isCallMisDashboard = false;
            for (var i = 0; i <= data.length; i++) {
              if (i === data.length) {
                if (isCallStorePayAPI) {
                  self.handleGenerateStorePayLink();
                } else {
                  // NotificationManager.error(
                  //   "You don't have any sufficient page access. Please contact administrator for access.",
                  //   "",
                  //   2000
                  // );
                  self.props.history.push("languageSelection");
                }
                if (isCallMisDashboard) {
                  self.handleMISDashboardNavigation();
                }
                self.setState({
                  loading: false,
                });
              } else if (
                data[i].moduleName === "Chat" &&
                data[i].modulestatus === true
              ) {
                self.props.history.push("languageSelection");
                return;
              } else if (
                data[i].moduleName === "Dashboard" &&
                data[i].modulestatus === true
              ) {
                self.props.history.push("languageSelection");
                return;
              } else if (
                data[i].moduleName === "Tasks" &&
                data[i].modulestatus === true
              ) {
                self.props.history.push("languageSelection");

                return;
              } else if (
                data[i].moduleName === "Claim" &&
                data[i].modulestatus === true
              ) {
                self.props.history.push("languageSelection");

                return;
              } else if (
                data[i].moduleName === "Campaign" &&
                data[i].modulestatus === true
              ) {
                self.props.history.push("languageSelection");

                return;
              } else if (
                data[i].moduleName === "Appointment" &&
                data[i].modulestatus === true
              ) {
                self.props.history.push("languageSelection");

                return;
              } else if (
                data[i].moduleName === "MyTicket" &&
                data[i].modulestatus === true
              ) {
                self.props.history.push("languageSelection");

                return;
              } else if (
                data[i].moduleName === "Orders" &&
                data[i].modulestatus === true
              ) {
                self.props.history.push("languageSelection");

                return;
              } else if (
                data[i].moduleName === "StorePay" &&
                data[i].modulestatus === true
              ) {
                isCallStorePayAPI = true;
              } else if (
                data[i].moduleName === "MISDashboard" &&
                data[i].modulestatus === true
              ) {
                isCallMisDashboard = true;
              }
            }

            // if (!isCallStorePayAPI) {
            //   self.handleGenerateStorePayLink();
            // }

            //To check store manager have access to video call or not
            
            this.props.setIsVideoCallEnable(response.responseData.isVideoEnable);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  ////handle genrate store pay link
  handleGenerateStorePayLink = () => {
    let self = this;
    this.LoginService.Post("/StorePay/GenerateStorePayLink")
      .then((response) => {
        var message = response.message;
        //var storePayURL = response.responseData;
        var storePayURL = encryption(response.responseData, "decr");
        if (message === "Success" && storePayURL) {
          // self.setState({ storePayURL });
          window.open(storePayURL, "blank");
        } else {
          // window.location = "http://www.google.com/";
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //handle logout method
  handleForcedLogin = () => {
    var self = this;
    if(self.state.reCaptchaValid){
    self.LoginService.PostWithHeader("/StoreAccount/Logout", {
      "Content-Type": "application/json",
      "X-Authorized-Token": this.state.token,
    })
      .then((response) => {
        var status = response.status;
        if (status === true) {
          localStorage.clear();
          self.handleLogin();
        }
      })
      .catch((error) => {
        console.log(error);
      });
    }else{
      this.handleClose();
    }
  };

  // handleGetCaptaValue() {
  //   let firstValue = Math.floor((Math.random() * 99) + 10);
  //   let secondValue = Math.floor((Math.random() * 9) + 1);
  //   let result = 0;
  //   let operator = Math.floor((Math.random() * 2) + 1);
  //   if(operator === 1)
  //   {
  //     let randomResult = firstValue+ " + " + secondValue + " = ";
  //     result = firstValue + secondValue;
  //     this.setState({captchaValue: randomResult});
  //   }
  //   else
  //   {
  //     let randomResult = firstValue+ " - " + secondValue + " = ";
  //     result = firstValue - secondValue;
  //     this.setState({captchaValue: randomResult});
  //   }
  //   this.setState({captchaResult : result});
  //   //console.log(firstValue +"and"+ secondValue);
  // }

  // handleCaptchaChange = (e) => {
  //   this.setState({
  //     captchaInput: e.target.value,
  //     captchaValidation: false,
  //   });
  // };

  handleonChange(value) {
    var self = this;
    if(value !== undefined && value !== null)
    {
      self.setState({reCaptchaValid : true});
      //console.log("Captcha value:", value);
    } else {
      self.setState({reCaptchaValid : false});
    }
  }

  asyncScriptOnLoad () {
    this.setState({recaptchaLoaded: true});
  }

  render() {
    return (
      <div className="auth-wrapper box-center Mainpro">
        <Dialog
          onClose={this.handleClose}
          open={this.state.open}
          PaperProps={{
            style: {
              height: "35%",
              width: !this.state.isMobileView ? "35%" : "100%",
            },
          }}
        >
          <DialogTitle>
            <label className="sign-in">SIGN IN</label>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <label>User Already Loggedin In another Device!!! </label>
              <label>Do you want to Login here</label>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <button className="program-code-button" onClick={this.handleClose}>
              No
            </button>
            <button
              className="program-code-button"
              onClick={this.handleForcedLogin}
              autoFocus
            >
              Yes
            </button>
          </DialogActions>
        </Dialog>

        <div className="Shopster">
          <img src={ShopSter} alt="ShopSter" />
        </div>
        <h3 className="logintxt">Log in</h3>
        <div className="auth-content">
          <NotificationContainer />
          <div className="card">
            <div className="card-body text-center">
              <div className="mb-4 logohi">
                <img
                  src={Logo}
                  alt="logo"
                  className="main-logo"
                  style={{ width: "210px" }}
                />
              </div>
              <form name="form" onSubmit={this.handleSubmit}>
                <label className="sign-in">SIGN IN</label>
                <div className="input-group mb-3">
                  <label className="stprocode">Enter User ID/Email ID</label>
                  <input
                    type="text"
                    className="program-code-textbox"
                    placeholder="Enter User ID/Email ID"
                    name="emailID"
                    onChange={this.hanleChange}
                    value={this.state.emailId}
                    autoComplete="off"
                    maxLength={100}
                  />

                  <p style={{ color: "red", marginBottom: "0px" }}>
                    {this.state.emailIdValidation}
                  </p>
                </div>
                <div className="input-group mb-3">
                  <label className="stprocode">Password</label>
                  <input
                    type="password"
                    className="program-code-textbox"
                    placeholder="Password*"
                    onChange={this.handlePasswordChange}
                    autoComplete="off"
                    maxLength={25}
                    value={this.state.password}
                  />
                  {this.state.password === "" && (
                    <p style={{ color: "red", marginBottom: "0px" }}>
                      {this.state.passwordValidation}
                    </p>
                  )}
                </div>
                <div className="input-group mb-3" >
                  <ReCAPTCHA
                    ref={this._reCaptchaRef}
                    size="invisible"
                    sitekey= {config.sitekey}
                    //sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI" //test key only for testing.
                    onChange={this.handleonChange}
                    asyncScriptOnLoad={this.asyncScriptOnLoad.bind(this)}
                  />
                  {/* {this.state.captchaValidation && (
                    <p style={{ color: "red", marginBottom: "0px" }}>
                      Please validate captcha.
                    </p>
                  )} */}
                </div>
                <button
                  type="submit"
                  disabled={!this.state.recaptchaLoaded}
                  className="program-code-button"
                  onClick={this.handleSubmit.bind(this)}
                >
                  {this.state.loading ? (
                    <FontAwesomeIcon
                      className="circular-loader"
                      icon={faCircleNotch}
                      spin
                    />
                  ) : (
                    ""
                  )}
                  {this.state.loading ? "Please Wait..." : "LOGIN"}
                </button>
              </form>
              <div>
                <br />
                <p className="mb-0 text-muted forg">
                  <Link
                    to={{
                      pathname: "storeForgotpassword",
                      state: {
                        programCode: this.state.programCode,
                      },
                    }}
                    style={{ color: "#246ec3", letterSpacing: "0.5px" }}
                  >
                    FORGOT PASSWORD
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

// export default StoreSignIn;
const mapStateToProps = (state) => {
};

const mapDispatchToProps = (dispatch) => {
  return {
    setIsVideoCallEnable: (isVideoCallEnable) => dispatch({ type: "IS_VIDEO_CALL_ENABLE", payload: isVideoCallEnable }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StoreSignIn);
