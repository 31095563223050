import React, { Component } from "react";
import { Link } from "react-router-dom";
import Demo from "../../store/Hashtag.js";
import DelBigIcon from "./../../assets/Images/del-big.png";
import FileUpload from "./../../assets/Images/file.png";
import DelBlack from "./../../assets/Images/del-black.png";
import UploadCancel from "./../../assets/Images/upload-cancel.png";
import DownExcel from "./../../assets/Images/csv.png";
import { ProgressBar } from "react-bootstrap";
import { UncontrolledPopover, PopoverBody } from "reactstrap";
import { Spin } from "antd";
import ReactTable from "react-table";
import config from "../../helpers/config";
import { NotificationManager } from "react-notifications";
import { CSVLink } from "react-csv";
import { formatSizeUnits } from "../../helpers/CommanFuncation";
import Dropzone from "react-dropzone";
import * as translationHI from "../../translations/hindi";
import * as translationMA from "../../translations/marathi";
import { Tabs, Tab } from "react-bootstrap-tabs";
import LoginService from "../../Services/Login/LoginService";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class RetargetFlow extends Component {
  constructor(props) {
    super(props);

    this.state = {
      bulkuploadLoading: false,
      fileSize: "",
      file: {},
      fileName: "",
      fileValidation: "",
      isErrorBulkUpload: false,
      isShowProgress: false,
      translateLanguage: {},
      selectedTab: "uploadstatus",
      isloading: false,
      uploadData: [],
      TotalCheckoutInserted: 0,
      isShopifyButton: false,
      showTotalInserted: false,
    };
    this.LoginService = new LoginService();
  }

  componentDidMount() {
    this.handleCRMRole();
    this.handleFileUploadStatus();
    if (window.localStorage.getItem("translateLanguage") === "hindi") {
      this.state.translateLanguage = translationHI;
    } else if (window.localStorage.getItem("translateLanguage") === "marathi") {
      this.state.translateLanguage = translationMA;
    } else {
      this.state.translateLanguage = {};
    }
  }

  //handle crm role data
  handleCRMRole() {
    this.LoginService.Post("/StoreCRMRole/GetStoreRolesByUserID")
      .then((response) => {
        let msg = response.message;
        if (msg === "Success") {
          this.setState({
            isNumberMasking: response.responseData.isNumberMasking,
            maskingPrefix: response.responseData.maskingPrefix,
            maskingSufix: response.responseData.maskingSufix,
            tenantMobileMasking:
              response.responseData.tenantLevelMasking,
            isSuggestionText: response.responseData.suggestionText,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  fileUpload = (file) => {
    if (file.length > 0) {
      var fileName = file[0].name;
      var fileSize = formatSizeUnits(file[0].size);
      this.setState({
        fileName,
        fileSize,
        file: file[0],
        fileValidation: "",
      });
    } else {
      NotificationManager.error("File accept only csv, xlsx, xml type.");
    }
  };

  DeleteBulkUploadFile = () => {
    const TranslationContext = this.state.translateLanguage.default;

    this.setState({
      file: [],
      fileName: "",
      fileSize: "",
      isErrorBulkUpload: false,
      isShowProgress: false,
    });
    NotificationManager.success(
      TranslationContext !== undefined
        ? TranslationContext.alertmessage.filedeletedsuccessfully
        : "File deleted successfully."
    );
  };
  //handle tab change
  handleTabChange = (lable) => {
    this.setState({
      selectedTab: lable,
    });
    if(this.state.selectedTab == "Shopify Abandoned Checkout's"){
      this.setState({
        showTotalInserted: false,
      });
    }
  };

  //handle Upload File
  handleFileUpload = (formFile) => {
    const TranslationContext = this.state.translateLanguage.default;
    let self = this;
    if (this.state.fileName) {
      this.setState({
        bulkuploadLoading: true,
      });
      const formFile = new FormData();
      formFile.append("formFile", this.state.file);
      this.LoginService.PostWithData(
        "/RetargetFlow/uploadRetargetFile",
        formFile
      )
        .then((response) => {
          var status = response.message;
          if (status === "Success") {
            NotificationManager.success(
              TranslationContext !== undefined
                ? TranslationContext.alertmessage.fileuploadedsuccessfully
                : "File uploaded successfully."
            );
            self.setState({
              fileName: "",
              fileSize: "",
              fileN: [],
              bulkuploadLoading: false,
            });
            self.handleFileUploadStatus();
            self.setState({ isErrorBulkUpload: false, isShowProgress: false });
          } else if (status === "Record Uploaded Partially") {
            NotificationManager.error(
              "File uploaded partially.Please check the log."
            );
            self.setState({
              fileName: "",
              fileSize: "",
              fileN: [],
              bulkuploadLoading: false,
            });
            self.setState({ isErrorBulkUpload: false, isShowProgress: false });
          } else {
            self.setState({ bulkuploadLoading: false });
            NotificationManager.error(
              TranslationContext !== undefined
                ? TranslationContext.alertmessage.filenotupdated
                : "File not uploaded."
            );
          }
        })
        .catch((error) => {
          self.setState({ isErrorBulkUpload: true, bulkuploadLoading: false });
          console.log(error);
        });
    } else {
      this.setState({ fileValidation: "Please Select File." });
    }
  };

  handleFileUploadStatus() {
    let self = this;
    this.setState({ isloading: true });
    this.LoginService.Post("/RetargetFlow/GetRetargetFileUploadLog").then(
      (response) => {
        let message = response.message;
        let data = response.responseData;
        if (message === "Success") {
          self.setState({
            uploadData: data,
            isloading: false,
          });
        }
      }
    );
  }

  handleShopifyAPICalling = () => {
    const TranslationContext = this.state.translateLanguage.default;
    let self = this;
    self.setState({ isShopifyButton: true });
      this.LoginService.Post(
        "/RetargetFlow/GetShopifyAbandonedCheckouts")
        .then((response) => {
          var status = response.message;
          var TotalInserted = response.responseData.TotalCheckoutInserted;
          if (status === "Success") {
            NotificationManager.success(
              TranslationContext !== undefined
                ? TranslationContext.alertmessage.fileuploadedsuccessfully
                : "Shopify API Called Successfully."
            );
            self.setState({
              TotalCheckoutInserted: TotalInserted,
              isShopifyButton : false,
              showTotalInserted: true,
            });
          } else {
            self.setState({ isShopifyButton: false, showTotalInserted: false, TotalCheckoutInserted: 0 });
            NotificationManager.error(
              TranslationContext !== undefined
                ? TranslationContext.alertmessage.filenotupdated
                : "Shopify API Call Failed."
            );
          }
        })
        .catch((error) => {
          self.setState({ isShopifyButton: false });
          console.log(error);
        });
  };

  downloadFile = (csvFile) => {
    window.open(csvFile);
  };

  render() {
    const TranslationContext = this.state.translateLanguage?.default;
    const columns = [
      { Header: "File Name", accessor: "fileName" },
      // { Header: "FileRead", accessor: "isFileRead" },
      { Header: "UploadedBy", accessor: "fileUploadedBy" },
      { Header: "Date", accessor: "uploadedDateTime" },
      {
        Header: "File Path",
        accessor: "filePath",
        Cell: (row) => (
          <div>
            <button
              className="downloadBtn"
              onClick={this.downloadFile.bind(this, row.original.filePath)}
            >
              {TranslationContext !== undefined
                ? TranslationContext.button.download
                : "DOWNLOAD"}
            </button>
          </div>
        ),
      },
    ];

    return (
      <React.Fragment>
        {/* <div className="container-fluid setting-title setting-breadcrumb">
          <Link to="/store/settings" className="header-path">
            {TranslationContext !== undefined
              ? TranslationContext.link.setting
              : "Settings"}
          </Link>
          <span>&gt;</span>
          <Link
            to={{
              pathname: "/store/settings",
              tabName: "store-tab",
            }}
            className="header-path"
          >
            {TranslationContext !== undefined
              ? TranslationContext.link.store
              : "Store"}
          </Link>
          <span>&gt;</span>
          <Link to={Demo.BLANK_LINK} className="active header-path">
            {TranslationContext !== undefined
              ? TranslationContext.link.chatsetting
              : "Retarget Flow"}
          </Link>
        </div> */}
        <div className="module-tabs chat-tabslst">
          <section>
            <Tabs
              onSelect={(index, label) => {
                this.handleTabChange(label);
              }}
              selected={this.state.selectedTab}
            >
              <Tab
                label={
                  TranslationContext !== undefined
                    ? TranslationContext.label.uploadstatus
                    : "Upload Status"
                }
              >
                <div className="react_table">
                  <ReactTable
                    data={this.state.uploadData}
                    columns={columns}
                    defaultPageSize={6}
                    pageSizeOptions={[10]}
                  />
                </div>
              </Tab>
              <Tab
                label={
                  TranslationContext !== undefined
                    ? TranslationContext.label.fileupload
                    : "File Upload"
                }
              >
                <div className="right-sect-div">
                  <div className="d-flex justify-content-between align-items-center pb-2">
                    <h3 className="pb-0">
                      {TranslationContext !== undefined
                        ? TranslationContext?.h3?.bulkupload
                        : "File Upload"}
                    </h3>
                    <div className="down-excel">
                      <p>
                        {TranslationContext !== undefined
                          ? TranslationContext?.p?.template
                          : "Template"}
                      </p>
                      <CSVLink
                        filename={"RetargetTemplate.csv"}
                        data={config.retargetFlowTemplate}
                      >
                        <img src={DownExcel} alt="download icon" />
                      </CSVLink>
                    </div>
                  </div>
                  <div className="container blk_upload">
                    <Spin
                      tip="Please wait..."
                      spinning={this.state.bulkuploadLoading}
                    >
                      <div className="mainfileUpload">
                        <Dropzone
                          accept=".xml,.csv,.xlsx"
                          onDrop={this.fileUpload}
                        >
                          {({ getRootProps, getInputProps }) => (
                            <div {...getRootProps()}>
                              <input
                                {...getInputProps()}
                                className="file-upload d-none"
                              />
                              <div className="file-icon">
                                <img src={FileUpload} alt="file-upload" />
                              </div>
                              <span className={"fileupload-span"}>
                                {TranslationContext !== undefined
                                  ? TranslationContext?.span?.addfile
                                  : "Add File"}
                              </span>
                              {TranslationContext !== undefined
                                ? TranslationContext?.div?.or
                                : "or"}
                              {TranslationContext !== undefined
                                ? TranslationContext?.div?.dropfilehere
                                : "Drop File here"}
                            </div>
                          )}
                        </Dropzone>
                      </div>
                      {this.state.fileValidation ? (
                        <p style={{ color: "red", marginBottom: "0px" }}>
                          {this.state.fileValidation}
                        </p>
                      ) : null}
                      {this.state.fileName && (
                        <div className="file-info">
                          <div className="file-cntr">
                            <div className="file-dtls">
                              <p className="file-name">{this.state.fileName}</p>
                              <div className="del-file" id="del-file-1">
                                <img src={DelBlack} alt="delete-black" />
                              </div>
                              <UncontrolledPopover
                                trigger="legacy"
                                placement="auto"
                                target="del-file-1"
                                className="general-popover delete-popover"
                              >
                                <PopoverBody className="d-flex">
                                  <div className="del-big-icon">
                                    <img src={DelBigIcon} alt="del-icon" />
                                  </div>
                                  <div>
                                    <p className="font-weight-bold blak-clr">
                                      {TranslationContext !== undefined
                                        ? TranslationContext?.p?.deletefile
                                        : "Delete file"}
                                      ?
                                    </p>
                                    <p className="mt-1 fs-12">
                                      {TranslationContext !== undefined
                                        ? TranslationContext?.p
                                            ?.areyousureyouwanttodeletethisfile
                                        : "Are you sure you want to delete this file"}
                                      ?
                                    </p>
                                    <div className="del-can">
                                      <a href={Demo.BLANK_LINK}>
                                        {TranslationContext !== undefined
                                          ? TranslationContext?.a?.cancel
                                          : "CANCEL"}
                                      </a>
                                      <button
                                        className="butn"
                                        onClick={this.DeleteBulkUploadFile}
                                      >
                                        {TranslationContext !== undefined
                                          ? TranslationContext?.button?.delete
                                          : "Delete"}
                                      </button>
                                    </div>
                                  </div>
                                </PopoverBody>
                              </UncontrolledPopover>
                            </div>
                            <div>
                              <span className="file-size">
                                {this.state.fileSize}
                              </span>
                            </div>
                          </div>
                          {this.state.isErrorBulkUpload ? (
                            <div className="file-cntr">
                              <div className="file-dtls">
                                <p className="file-name">
                                  {this.state.fileName}
                                </p>
                                <span
                                  className="file-retry"
                                  onClick={this.handleFileUpload.bind(this)}
                                >
                                  {TranslationContext !== undefined
                                    ? TranslationContext?.span?.retry
                                    : "Retry"}
                                </span>
                              </div>
                              <div>
                                <span className="file-failed">
                                  {TranslationContext !== undefined
                                    ? TranslationContext?.span?.failed
                                    : "Failed"}
                                </span>
                              </div>
                            </div>
                          ) : null}
                          {this.state.isShowProgress ? (
                            <div className="file-cntr">
                              <div className="file-dtls">
                                <p className="file-name pr-0">
                                  {this.state.fileName}
                                </p>
                              </div>
                              <div>
                                <div className="d-flex align-items-center mt-2">
                                  <ProgressBar
                                    className="file-progress"
                                    now={60}
                                  />
                                  <div className="cancel-upload">
                                    <img
                                      src={UploadCancel}
                                      alt="upload cancel"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : null}
                        </div>
                      )}
                      <button
                        className="butn"
                        onClick={this.handleFileUpload.bind(this)}
                      >
                        {TranslationContext !== undefined
                          ? TranslationContext?.button?.add
                          : "UPLOAD"}
                      </button>
                    </Spin>
                  </div>
                </div>
              </Tab>
              <Tab
                  label={
                    TranslationContext !== undefined
                      ? TranslationContext.label.charditemconfiguration
                      : "Shopify Abandoned Checkout's"
                  }
                >
                  <div
                    className="row chattab-card"
                    style={{ marginBottom: "15px" }}
                  >
                    <div className="col-md-12">
                      <div className="card" style={{ height: "auto" }}>
                        <div className="row">
                          <div className="col-md-5 m-auto">
                            <div className="right-sect-div">
                              <h3>
                                {TranslationContext !== undefined
                                  ? TranslationContext.h3.charditemconfiguration
                                  : "Shopify Abandoned Checkout's"}
                              </h3>
                              { this.state.showTotalInserted ? 
                                <div className="right-sect-div">
                                  <label
                                    className="storeRole-name-text"
                                    style={{ width: "70%" }}
                                  >
                                    Total Abandoned Checkout's Received : {this.state.TotalCheckoutInserted}
                                  </label>
                                </div> : null
                              }
                              <button
                                className="Schedulenext1 w-100 mb-0 mt-4"
                                type="button"
                                disabled={this.state.isLoadingUpdate}
                                onClick={this.handleShopifyAPICalling.bind(this)}
                              >
                                {TranslationContext !== undefined
                                  ? TranslationContext.button.update
                                  : "Get Shopify Abandoned Checkout's For Retarget"}
                                {this.state.isShopifyButton ? (
                                  <FontAwesomeIcon
                                    className="circular-loader chatsettingload"
                                    icon={faCircleNotch}
                                    spin
                                  />
                                ) : (
                                  ""
                                )}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab>
            </Tabs>
          </section>
        </div>
        {/* <div className="container-fluid setting-title setting-breadcrumb"></div> */}
      </React.Fragment>
    );
  }
}

export default RetargetFlow;
