import React, { useEffect, useRef, useState } from "react";
import Draggable from "react-draggable";
import io from "socket.io-client";
import Peer from "simple-peer";
import styled from "styled-components";
import ringtone from "../../assets/mp3/ringtone.mp3";
import callEnd from "../../assets/Images/callEnd.png";
import downwardArrow from "../../assets/Images/downwardArrow.svg";
import stopVideoIcon from "../../assets/Images/stopVideoIcon.svg";
import startVideoIcon from "../../assets/Images/startVideoIcon.svg";
import unmuteIcon from "../../assets/Images/unmuteIcon.svg";
import muteIcon from "../../assets/Images/muteIcon.svg";
import searchWhite from "../../assets/Images/searchWhite.svg";
import messenger from "../../assets/Images/messenger.png";
import cameraflip from "../../assets/Images/cameraflip.svg";
import camera_rotate from "../../assets/Images/camera_rotate.png";
import { Howl } from "howler";
import { Link } from "react-router-dom";
import ChatService from "../../Services/Chats/ChatService";
import config from "../../helpers/config";
import { Modal } from "antd";
import { connect } from "react-redux";
import { videoCallSocket } from "../../helpers/SocketConnection";
import '../../assets/css/video.css';

import { JitsiMeeting } from '@jitsi/react-sdk';

const StyledVideoDiv = styled.div`
height: 100%;
display: block;
position: absolute;
width: -webkit-fill-available;`;

let wurl = window.location.href;
wurl = wurl.split('roomName=');
wurl = wurl[1];

class VideoSolution extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isFullScreen: true
        }
    }

    handleIFrameRef = iframeRef => {
        iframeRef.style.border = '10px solid #3d3d3d';
        iframeRef.style.background = '#3d3d3d';
        iframeRef.style.height = '100%';
        iframeRef.style.display = 'block';
        iframeRef.style.position = 'absolute';
        iframeRef.style.width = '-webkit-fill-available';
    };

    handleReadyToClose = () => {
        /* eslint-disable-next-line no-alert */
        // alert('Ready to close...');
    };

    handleClick = () => {
        this.setState({ isFullScreen: false });
        this.props.hideChatDuringCall(false);
    }

    componentDidMount() {
        console.log(this.props.location)
        if(this.props.location.pathname==='/video'){
            console.log((this.props.location.search).split("%")[1]);
        }
    }

    render() {
        return (
            <>
                <JitsiMeeting
                    domain='easybackend.staging02.com'
                    roomName='EZ003'
                    configOverwrite={{
                        disableDeepLinking: true,
                        prejoinPageEnabled: false,
                        enableClosePage: true,
                        enableWelcomePage: false,
                    }}
                    interfaceConfigOverwrite={{
                        MOBILE_APP_PROMO: false,
                        TILE_VIEW_MAX_COLUMNS: 4
                    }}
                    userInfo={{
                        displayName: 'Store Manager'
                    }}
                    getIFrameRef={this.handleIFrameRef}
                    onReadyToClose={this.handleReadyToClose}
                    onApiReady={externalApi => {
                        externalApi.executeCommand('subject', ' ');
                        externalApi.addListener('videoConferenceLeft', (eventData) => {
                            console.log('eventData', eventData);
                            window.location.href = '/'
                        })
                        externalApi.addListener('participantLeft', (eventData) => {
                            console.log('eventData', eventData);
                            window.location.href = '/'
                        })
                    }}

                />
                <div className="chat-container" >
                    <div className="image-container">
                        <Link
                            onClick={this.handleClick}
                            to={{
                                pathname: "/store/Chatbot",
                                state: {
                                    isSearch: false,
                                    isMessage: true,
                                    isVideoCall: true,
                                    MobileNo: (this.props.location.search).split("%")[1],
                                    tenantID: JSON.parse(
                                        localStorage.getItem("ChatSoundNotiSetting")
                                    ).tenantID,
                                    agentId: JSON.parse(localStorage.getItem("UserProfile"))
                                        .agentId,
                                    storeCode: JSON.parse(
                                        localStorage.getItem("UserProfile")
                                    ).storeCode,
                                    programCode: JSON.parse(
                                        localStorage.getItem("UserProfile")
                                    ).programCode,
                                    UserName: JSON.parse(
                                        localStorage.getItem("UserProfile")
                                    ).agentName,
                                },
                            }}
                        >
                            <img className="messenger-logo" src={messenger} alt="messenger" />

                        </Link>
                    </div>
                </div>
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        customerMobileNumber: state.customerMobileNumber,
    };
}

const mapDispatchToProps = dispatch => {
    return {
        hideChatDuringCall: (isFullScreen) => dispatch({ type: 'IS_FULL_SCREEN', payload: isFullScreen }),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(VideoSolution);