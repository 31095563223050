import React, { Component } from "react";
import { NotificationManager } from "react-notifications";
import * as translationHI from "../../../translations/hindi";
import * as translationMA from "../../../translations/marathi";
import OrderService from "../../../Services/Orders/OrderService";
import config from "../../../helpers/config";
class CheckService extends Component {
  constructor(props) {
    super(props);

    this.state = {
      storePinCode: 0,
      pin_code: 0,
      pinCodeValidation: "",
      translateLanguage: {},
      btnSubmitData: false,
    };
    this.OrderService = new OrderService();
  }

  componentDidMount() {
    this.handleGetCheckServiceData();

    if (window.localStorage.getItem("translateLanguage") === "hindi") {
      this.state.translateLanguage = translationHI;
    } else if (window.localStorage.getItem("translateLanguage") === "marathi") {
      this.state.translateLanguage = translationMA;
    } else {
      this.state.translateLanguage = {};
    }
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resize.bind(this));
    clearTimeout(this.intervalID);
  }

  /// handle Get Check service data
  handleGetCheckServiceData() {
    let self = this;
    this.intervalID = setTimeout(
      this.handleGetCheckServiceData.bind(this),
      config.pageRefreshTiming
    );

    this.OrderService.Post("/HSOrder/GetStorePinCodeByUserID")
      .then((response) => {
        let status = response.message;
        let data = response.responseData;
        if (status === "Success") {
          self.setState({
            storePinCode: data,
          });
        } else {
          self.setState({
            storePinCode: "",
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  /// handle Submit Check service data
  handleUpdateCheckServiceData() {
    const TranslationContext = this.state.translateLanguage.default;
    var self = this;
    if (this.state.pin_code !== "") {
      this.setState({
        btnSubmitData: true,
      });
      let inputData = {
        Pickup_postcode: parseInt(this.state.storePinCode),
        Delivery_postcode: parseInt(this.state.pin_code),
      };
      this.OrderService.PostWithData("/HSOrder/CheckCourierAvailibilty",inputData)
        .then((response) => {
          let status = response.responseData.available;
          let check = response.responseData;
          if (status === "true") {
            NotificationManager.success(
              TranslationContext !== undefined
                ? TranslationContext.alertmessage.deliveryavailable
                : "Delivery Available."
            );
            self.setState({
              btnSubmitData: false,
            });
          } else {
            if (check.statusCode === "301") {
              NotificationManager.error(
                TranslationContext !== undefined
                  ? TranslationContext.ticketingDashboard.pincodeisnotmapped
                  : "Pincode is not mapped."
              );
            } else {
              NotificationManager.error(
                TranslationContext !== undefined
                  ? TranslationContext.alertmessage
                      .servicenotavailableonenteredpincode
                  : "Service not available on entered Pincode."
              );
            }

            self.setState({
              btnSubmitData: false,
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      self.setState({
        pinCodeValidation: "Please Enter Pin Code",
      });
    }
  }
  /// handle Text onchage
  handleTextOnchange = (e) => {
    var reg = /^[0-9\b]+$/;
    if (e.target.value === "" || reg.test(e.target.value)) {
      this.setState({ [e.target.name]: e.target.value });
    } else {
      e.target.value = "";
    }
  };

  resize() {
    if (window.innerWidth <= 1000) {
      this.setState({ isMobileView: true });
    } else {
      this.setState({ isMobileView: false });
    }
  }
  render() {
    const TranslationContext = this.state.translateLanguage.default;
    return (
      <>
        <div className="check-svr mobcheck">
          <div className="row m-b-10 mx-0">
            <div
              className={`col-12 ${
                this.state.isMobileView ? "col-md-12" : "col-md-5"
              } `}
            >
              <label className="naman">
                {TranslationContext !== undefined
                  ? TranslationContext.label.storepincode
                  : "Store Pin Code"}
              </label>
            </div>
            <div
              className={`col-12 ${
                this.state.isMobileView ? "col-md-12" : "col-md-7"
              }`}
            >
              <input
                type="text"
                className="txt-1"
                placeholder="Store PIN Code"
                value={this.state.storePinCode}
                disabled
              />
            </div>
          </div>
          <div className="row mx-0">
            <div
              className={`col-12  ${
                this.state.isMobileView ? "col-md-12" : "col-md-5"
              }`}
            >
              <label className="naman">
                {TranslationContext !== undefined
                  ? TranslationContext.label.enterpincode
                  : "Enter Pin Code"}
              </label>
            </div>
            <div
              className={`col-12  ${
                this.state.isMobileView ? "col-md-12" : "col-md-7"
              }`}
            >
              <input
                type="text"
                className="txt-1"
                placeholder={
                  TranslationContext !== undefined
                    ? TranslationContext.label.pincode
                    : "PIN Code"
                }
                name="pin_code"
                value={this.state.pin_code}
                maxLength={6}
                autoComplete="off"
                onChange={this.handleTextOnchange}
              />
              {this.state.pin_code === "" && (
                <p
                  style={{
                    color: "red",
                    marginBottom: "0px",
                  }}
                >
                  {this.state.pinCodeValidation}
                </p>
              )}
            </div>
          </div>

          <div className="row mx-0">
            <div className="Col-12 col-md-12 btor">
              <button
                className={
                  this.state.btnSubmitData
                    ? "check-svcBtn order-grid-btn-disable"
                    : "check-svcBtn"
                }
                onClick={this.handleUpdateCheckServiceData.bind(this)}
              >
                {TranslationContext !== undefined
                  ? TranslationContext.button.submit
                  : "Submit"}
              </button>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default CheckService;
