import React, { Component } from "react";
import logo from "../../assets/Images/logo.jpg";
import Inform from "../../assets/Images/order-info.png";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import "react-notifications/lib/notifications.css";
import SimpleReactValidator from "simple-react-validator";
import { encryption } from "../../helpers/encryption";
import { Tooltip } from "antd";
import StoreUserService from "../../Services/Settings/StoreUserService";
import LoginService from "../../Services/Login/LoginService";
class StoreChangePassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      newPassword: "",
      confimPassword: "",
      oldPassword: "",
      ProfileData: [],
      oldPasswordCompulsion: "",
      newPasswordCompulsoryRex: "",
    };
    this.handleCheckPassword = this.handleCheckPassword.bind(this);
    this.handlechange = this.handlechange.bind(this);
    this.handleChangePassword = this.handleChangePassword.bind(this);
    this.handleGetUserProfileData = this.handleGetUserProfileData.bind(this);
    this.validator = new SimpleReactValidator();
    this.StoreUserService = new StoreUserService();
    this.LoginService = new LoginService();
  }
  componentDidMount() {
    this.handleGetUserProfileData();
  }

  handleGetUserProfileData() {
    let self = this;
    this.StoreUserService.Post("/StoreUser/GetStoreUserProfileDetail")
      .then((response) => {
        var status = response.message;
        var userdata = response.responseData;
        if (status === "Success") {
          self.setState({
            ProfileData: userdata,
          });
        } else {
          self.setState({
            ProfileData: "",
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  handlechange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  handleCheckValidationRegX = () => {
    if (this.state.newPassword !== "") {
      var regX = /^(?=\S*[a-z])(?=\S*[A-Z])(?=\S*\d)(?=\S*[^\w\s])\S{8,}$/;
      if (!regX.test(this.state.newPassword)) {
        this.setState({
          newPasswordCompulsoryRex: "Incomplete Password",
        });
      } else {
        this.setState({
          newPasswordCompulsoryRex: "",
        });
      }
    }
  };

  handleCheckPassword(e) {
    e.preventDefault();

    if (this.validator.allValid()) {
      const { newPassword, confimPassword } = this.state;
      if (newPassword === confimPassword) {
        this.handleChangePassword(newPassword);
      } else {
        NotificationManager.error(
          "The new password and confirm password do not match.",
          "",
          1500
        );
      }
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  }
  handleChangePassword(newPassword) {
    let self = this;

    // let emaiId=encryption(EmailID, "enc");
    // let emaiId = window.location.href
    //   .slice(window.location.href.indexOf("?") + 1)
    //   .split(":")[1];
    let emaiId = window.location.href
      .slice(window.location.href.indexOf("?") + 1)
      .split(":")[1];

    let encPassword = encryption(newPassword, "enc");

    var field = "Id";
    var changePasswordType = "system";
    var emailIDsystem = "";
    let email = this.state.ProfileData.emailId;
    var url = window.location.href;
    if (url.indexOf("?" + field + ":") !== -1) {
      changePasswordType = "mail";
      emailIDsystem = emaiId;
    } else {
      changePasswordType = "system";
      emailIDsystem = email;
    }
    let X_Authorized_Domainname = encryption(window.location.origin, "enc");
    var _token = window.localStorage.getItem("token");
    // change password
    if (this.state.newPasswordCompulsoryRex === "") {
      this.LoginService.PostWithHeaderandData(
        "/StoreUser/StoreChangePassword",
        {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "X-Authorized-Token": _token,
          "X-Authorized-Domainname": X_Authorized_Domainname,
        },
        {
          EmailID: emailIDsystem,
          Password: this.state.oldPassword,
          NewPassword: encPassword,
          ChangePasswordType: changePasswordType,
        }
      )
        .then((response) => {
          let Msg = response.responseData;
          if (Msg === true) {
            NotificationManager.success(
              "Password Changed successfully.",
              "",
              1500
            );
            setTimeout(function () {
              self.props.history.push("/SignIn");
            }, 1500);
          } else {
            NotificationManager.error("Old password is wrong.", "", 1500);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }
  render() {
    return (
      <div className="auth-wrapper box-center change-password-auth-wrapper authpaswd mobauthpaswd">
        <div className="auth-content">
          <div
            className="card forgotpass-card changepass-card"
            // style={{ height: "500px" }}
          >
            <div className="card-body text-center">
              <div className="mb-4">
                <img src={logo} className="initial-logo" alt="logo" />
              </div>
              <div style={{ marginBottom: "15px" }}>
                <h3 className="m-0" style={{ textAlign: "left" }}>
                  <label
                    className="col-mb-3 col-form-label col-form-label p-0 forgot-pass-text"
                    style={{ fontWeight: "300" }}
                  >
                    CHANGE PASSWORD
                  </label>
                </h3>
              </div>
              <form name="form" onSubmit={this.handleCheckPassword}>
                <div className="input-group sb-2">
                  <label className="col-mb-3 col-form-label col-form-label pt-0 chpass">
                    Enter Old Password
                  </label>
                </div>
                <div className="input-group mb-3">
                  <input
                    type="password"
                    name="oldPassword"
                    placeholder="Old Password"
                    className="program-code-textbox"
                    value={this.state.oldPassword}
                    onChange={this.handlechange}
                    maxLength={25}
                  />
                  {this.validator.message(
                    "Old Password",
                    this.state.oldPassword,
                    "required"
                  )}
                </div>
                <div className="input-group sb-2">
                  <label className="col-mb-3 col-form-label col-form-label pt-0 chpass">
                    Enter New Password
                  </label>
                </div>
                <div className="input-group mb-3">
                  <input
                    type="password"
                    name="newPassword"
                    placeholder="New Password"
                    className="program-code-textbox"
                    onChange={this.handlechange}
                    maxLength={25}
                    onBlur={this.handleCheckValidationRegX.bind(this)}
                  />
                  <Tooltip
                    placement="right"
                    title="Min 8 Chars - One capital, One small, One special character & One numeric character is mandatory."
                  >
                    <img src={Inform} className="passwrdInfor" alt="Inform" />
                  </Tooltip>
                  {this.validator.message(
                    "New Password",
                    this.state.newPassword,
                    "required"
                  )}
                  {this.state.newPasswordCompulsoryRex ? (
                    <p style={{ color: "red" }}>
                      {this.state.newPasswordCompulsoryRex}
                    </p>
                  ) : null}
                </div>
                <div className="input-group sb-2">
                  <label className="col-mb-3 col-form-label col-form-label pt-0 chpass">
                    Enter Confirm Password
                  </label>
                </div>
                <div className="input-group mb-3">
                  <input
                    type="password"
                    name="confimPassword"
                    placeholder="Confirm Password"
                    className="program-code-textbox"
                    onChange={this.handlechange}
                    maxLength={25}
                  />
                  {this.validator.message(
                    "Confirm Password",
                    this.state.confimPassword,
                    "required"
                  )}
                </div>
                <div className="input-group">
                  <button
                    type="submit"
                    className="recovery-pass-button program-code-button"
                  >
                    SET PASSWORD
                  </button>
                </div>
              </form>
              <NotificationContainer />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default StoreChangePassword;
