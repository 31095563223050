import React, { Component } from "react";
import DashboardLogo from "./../../assets/Images/store-black.png";
import TicketLogo from "./../../assets/Images/ticket.png";

import ChatLogo from "./../../assets/Images/chat.png";
import ChatLogoBlue from "./../../assets/Images/chat-blue.png";
import MobileChatIcon from "./../../assets/Images/MobileChatIcon.svg";
import ChatLogow from "./../../assets/Images/chatw.png";
import NotificationLogo from "./../../assets/Images/Notification.png";
import SettingLogo from "./../../assets/Images/setting.png";
import CancelIconW from "./../../assets/Images/cancel-white.png";
import Dots from "./../../assets/Images/dotsw.png";
import BackIcon from "./../../assets/Images/black-left-arrow.png";
import WhiteIcon from "./../../assets/Images/white-left-arrow.png";
import CampaignLogo from "./../../assets/Images/campaign.svg";
import CampaignLogoBlue from "./../../assets/Images/campaign.svg";
import SettingLogoBlue from "./../../assets/Images/setting-blue.png";
import OrderLogoBlue from "./../../assets/Images/order-icon-blue.png";
import OrderLogoBlack from "./../../assets/Images/order.png";
import TicketLogoBlue from "./../../assets/Images/ticket-blue.png";
import Logout from "./../../assets/Images/logout.png";
import { Link, withRouter } from "react-router-dom";
import Modal from "react-responsive-modal";
import PencilImg from "./../../assets/Images/pencil.png";
import ProfileImg from "./../../assets/Images/UserLogin.png";
import { Drawer } from "antd";
import { ProgressBar } from "react-bootstrap";
import { transferData } from "./../../helpers/transferData";
import AppointmentLogo from "./../../assets/Images/appointments.svg";
import BellIcon from "./../../assets/Images/bell-icon.svg";
import "react-pagination-js/dist/styles.css";
import * as translationHI from "../../translations/hindi";
import * as translationMA from "../../translations/marathi";
import "antd/dist/antd.css";
import "./../../assets/css/store-chat.css";
import SearchWh from "./../../assets/Images/search.svg";
import WebBotLink from "./../../assets/Images/WebBotLink.png";
import { socket, videoCallSocket } from "../../helpers/SocketConnection";
import VideoCall from "./VideoCall";
import LoginService from "../../Services/Login/LoginService";
import VideocallAppointment from "./../../assets/Images/videocallAppointment.png";
import Retarget from "./../../assets/Images/retarget.png";
import config from "../../helpers/config";
import { encryption } from "../../helpers/encryption";
import Dropdown from "react-bootstrap/Dropdown";
import CallOutlinedIcon from "@mui/icons-material/CallSharp";
import EventAvailableOutlinedIcon from "@mui/icons-material/EventAvailableSharp";
import ShopSter from "./../../assets/Images/Shopster.png";
import StoreHeaderService from "../../Services/StoreHeader/StoreHeaderService";
import Button from "@mui/material/Button";
// import CheckCircleIcon from "@mui/icons-material/CheckCircle";
// import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import CheckCircleIcon from "../../assets/Images/icons8-ok.svg";
import CancelOutlinedIcon from "../../assets/Images/icons8-close-60.png";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ClickAwayListener from "material-ui/internal/ClickAwayListener";
import Badge from "@mui/material/Badge";
import Avatar from "@mui/material/Avatar";
import { connect } from "react-redux";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: false,
      open: false,
      notificationModal: false,
      notificationCount: 0,
      notificationData: [],
      TaskID: 0,
      Email: "",
      UserName: "",
      LoginTime: "",
      LoggedInDuration: "",
      SLAScore: "",
      CSatScore: "",
      AvgResponse: "",
      LogoutTime: "",
      NameTag: "",
      userProfile: "",
      percentLog: 0,
      workTime: 0,
      workTimeHours: "0H 0M",
      selectedUserProfilePicture: "",
      cont: [],
      chatModal: false,
      ongoingChatsData: [],
      newChatsData: [],
      searchCardData: [],
      chatId: 0,
      isDownbtn: true,
      customerName: "",
      messageData: [],
      message: "",
      chatMessageCount: 0,
      activeTab: 1,
      cardModal: false,
      searchItem: "",
      timeSlotData: [],
      selectedSlot: {},
      noOfPeople: "",
      selectedDate: "",
      noOfPeopleMax: "",
      isSelectSlot: "",
      customerId: 0,
      mobileNo: "",
      messageSuggestionData: [],
      messageSuggestion: "",
      scheduleModal: false,
      recommendedModal: false,
      paymentModal: false,
      selectedCard: 0,
      chkSuggestion: 0,
      programCode: "",
      storeID: "",
      notificationAccess: "none",
      settingAccess: "none",
      storeAgentDetail: [],
      AgentID: 0,
      searchChat: "",
      isSendRecomended: false,
      chatAccess: "none",
      noProductFound: "",
      remainingCount: "",
      tempRemainingCount: "",
      noRecommendedFound: "",
      suggestionModal: false,
      suggestionText: "",
      suggestionModalMob: false,
      availableSlot: 0,
      isHistoricalChat: false,
      visible: false,
      historicalChatData: [],
      agentRecentChatData: [],
      agentData: [],
      sAgentId: 0,
      isScroll: false,
      selectedSugpage: 1,
      tempmessageSuggestionData: [],
      translateLanguage: {},
      storeCode: "",
      actionBtn: false,
      isCustEndChat: false,
      mainTabSelect: 1,
      isPastChatLoading: false,
      isHistoricalChatLoading: false,
      showHistoricalChat: false,
      chatTimeAgo: "",
      rowChatId: 0,
      storeManagerId: 0,
      tempCardSearch: "",
      reportAccess: "none",
      mobileHeading: "",
      messageHistoryChatData: [],
      isMainLoader: true,
      messageSuggestionTagsData: [],
      selectedTags: 0,
      newChatSoundVolume: 0,
      newMessageSoundVolume: 0,
      isNotiNewChat: false,
      isNotiNewMessage: false,
      newChatSoundFile: "",
      newMessageSoundFile: "",
      storePayURL: "",
      newTicketChatId: 0,
      onHoverName: false,
      isPinClick: false,
      customerTier: "",
      customerMobileNo: "",
      customerEmailID: "",
      totalPoints: 0,
      lifetimeValue: 0,
      visitCount: 0,
      billNumber: "",
      billAmount: 0,
      transactionDate: "",
      insights: [],
      orderDelivered: 0,
      orderShoppingBag: 0,
      orderReadyToShip: 0,
      orderReturns: 0,
      isMobileView: false,
      isShutterOpen: false,
      shoppingBagData: [],
      wishListData: [],
      recommendedData: [],
      ProfileProductTab: 0,
      activeCollpse: [0],
      productTypeTab: 0,
      selectedRecommended: [],
      selectedShoppingBag: [],
      selectedWishList: [],
      isButtonClick: false,
      buyNowClick: false,
      colorCode: [
        "#E8E8E8",
        "#FFE8A7",
        "#DAF3C0",
        "#DDF6FC",
        "#CDE4FF",
        "#FFDEE2",
      ],
      notificationTime: 0,
      shippingAddress: "",
      shippingLandmark: "",
      shippingPinCode: "",
      shippingCity: "",
      shippingState: "",
      shippingCountry: "",
      isMessageTabActive: false,
      isCardTabActive: false,
      isRecommendedTabActive: false,
      isSchedualTabActive: false,
      isGeneratePaymentTabActive: false,
      isCustomerProfile: false,
      isCustomerProduct: false,
      selectedColor: "",
      mainProductsData: [],
      storePinCode: "",
      isAddress: "",
      isPinCode: "",
      isCity: "",
      isState: "",
      isCountry: "",
      isPinCodeCheck: "",
      maxPeopleAllow: 0,
      itemDetails: [],
      isCustTimeout: false,
      customerChatSessionValue: "",
      customerChatSessionDuration: "",
      agentChatSessionValue: "",
      agentChatSessionDuration: "",
      isEmptyArray: false,
      tmpOngoingChatsData: [],
      sourceType: "",
      pastChatCount: 0,
      pageNumberCurrentChat: 1,
      pastChatPageNo: 1,
      historyPage: 1,
      isProductLoading: false,
      isScrollMessage: false,
      isCallChatMessgaeApi: false,
      tenantID: 0,
      isProfileLoading: false,
      cardSearchStoreCode: false,
      isAddressName: "",
      isAddressMobileNo: "",
      addressMobileNo: "",
      addressName: "",
      oldScrollHeight: 0,
      alertMessageModal: false,
      alertMessageContent: "",
      storeDetails: "",
      insightsLoader: false,
      lasttransactionLoader: false,
      cardConfigData: {},
      isCardSearch: false,
      orderDetailsLoader: false,
      suggestionTagModal: false,
      ticketIds: false,
      ticketNo: "",
      AddWhite: false,
      isCardMobileUp: false,
      totalShoppingBag: 0,
      totalWishList: 0,
      totalRecommend: 0,
      mobileCollpseActive: [1],
      mobileShopBagProcessMdl: false,
      mobileWishProcessMdl: false,
      mobileRecommendProcessMdl: false,
      mobileChatActive: 0,
      uniqueItemID: 0,
      mobileprofilePic: "",
      NotificationPage: 1,
      chatNotificationModal: false,
      chatNotificationData: [],
      showSearch: false,
      chatNotificationMomCount: 0,
      userLoginMobileNo: "",
      isHSMShow: false,
      AttachementFiles: [],
      fileShowMdl: false,
      fileAttachDocWeb: "",
      isAttachmentMainLoader: false,
      isCheckSuggetion: false,
      isSelectedCard: false,
      mobileFileupload: false,
      customerNameProfile: "",
      attachmentSendCount: 0,
      storeName: "",
      storeAddress: "",
      isCLose: false,
      users: {},
      receivingCall: false,
      caller: "",
      callAccepted: false,
      callRejected: false,
      isFullScreen: false,
      cameraText: "",
      audioText: "",
      callStatus: "",
      partnerVideoSRC: undefined,
      userVideoSRC: undefined,
      isDropdownPopUpOpen: false,
      checked: false,
      trackStatus: "",
      emails: [
        {
          status: "Available",
          icon: <img src={CheckCircleIcon} alt="No image" className="status-icon" />,
        },
        {
          status: "Offline",
          icon: <img src={CancelOutlinedIcon} alt="No image" className="status-icon" />,
        },
      ],
    };

    this.handleLoggedInUserDetails = this.handleLoggedInUserDetails.bind(this);
    this.handleGetUserProfileData = this.handleGetUserProfileData.bind(this);
    this.handleCRMRole = this.handleCRMRole.bind(this);
    this.setAccessUser = this.setAccessUser.bind(this);
    this.LoginService = new LoginService();
    this.StoreHeaderService = new StoreHeaderService();
  }

  componentDidMount() {
    // this.LoginService.Post("/StoreCRMRole/GetStoreRolesByUserID")
    //   .then((response) => {
    //     let msg = response.message;
    //     let data = response.responseData.modules;
    //     if (msg === "Success") {
    //       console.log("role");
    //       this.props.setIsVideoCallEnable(response.responseData.isVideoEnable);
    //       this.props.setIsOnline(response.responseData.isOnline);
    //     }
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });
    console.log("Video Call Flag Props : ", this.props.isVideoCallEnable);
    // console.log(
    //   "Video Call Flag Storage : ",
    //   window.localStorage.getItem("isFlag")
    // );
    // if (window.localStorage.getItem("isFlag")) {
    //   if (window.localStorage.getItem("isFlag") == "true") {
    //     this.setState({ trackStatus: "Available" });
    //   } else {
    //     this.setState({ trackStatus: "Offline" });
    //   }
    // } else {
    //   if (this.props.isVideoCallEnable) {
    //     this.setState({ trackStatus: "Available" });

    //     window.localStorage.setItem("isFlag", "true");
    //   } else {
    //     this.setState({ trackStatus: "Offline" });

    //     window.localStorage.setItem("isFlag", "false");
    //   }
    // }
    this.subscription = transferData.getProfilePic().subscribe((pic) => {
      if (pic.profilePic) {
        if (pic.profilePic === "") {
          this.setState({
            selectedUserProfilePicture: "",
          });
        } else if (pic.profilePic.length > 0) {
          this.setState({
            selectedUserProfilePicture: pic.profilePic,
          });
        }
      } else if (pic.profilePic === "") {
        this.setState({ selectedUserProfilePicture: "" });
      }
    });
    var _token = window.localStorage.getItem("token");
    if (_token === null) {
      this.props.history.push("/");
    } else {
      this.handleLoggedInUserDetails();
      this.handleGetUserProfileData();

      var userProfile = JSON.parse(window.localStorage.getItem("UserProfile"));
      if (userProfile !== null && userProfile.agentName) {
        var strTag = userProfile.agentName.split(" ");
        var nameTag = strTag[0].charAt(0).toUpperCase();
        if (strTag.length > 0) {
          nameTag += strTag[1].charAt(0).toUpperCase();
        }
        let nume =
          userProfile.loggedInDurationInHours * 60 +
          userProfile.loggedInDurationInMinutes;
        let deno =
          userProfile.shiftDurationInHour * 60 +
          userProfile.shiftDurationInMinutes;
        let percentLog = ((nume / deno) * 100).toFixed(2);
        var profile = userProfile.profilePicture;
        var finalPath = profile.substring(
          profile.lastIndexOf("\\") + 1,
          profile.length
        );

        this.setState({
          AgentID: userProfile.agentId,
          Email: userProfile.agentEmailId,
          UserName: userProfile.agentName,
          LoginTime: userProfile.loginTime,
          LoggedInDuration: userProfile.loggedInDuration,
          SLAScore: userProfile.slaScore,
          CSatScore: userProfile.csatScore,
          AvgResponse: userProfile.avgResponseTime,
          LogoutTime: userProfile.logoutTime,
          NameTag: nameTag,
          userProfile: finalPath,
          percentLog,
          workTime: userProfile.workTimeInPercentage,
          workTimeHours: userProfile.totalWorkingTime,
          programCode: userProfile.programCode ? userProfile.programCode : "",
          storeCode: userProfile.storeCode ? userProfile.storeCode : "",
        });
        console.log(this.props.location)
        console.log((this.props.location.search).split(",")[1]);
        this.props.setIsCallRecived(`?${userProfile.programCode}` === ((this.props.location.search).split(","))[0] ? true : false)
        this.props.setCustomerNumber(this.props.location.search ? ((this.props.location.search).split(","))[1] : null)
        this.props.setVideoCallRoomName(this.props.location.search ? ((this.props.location.search).split("?"))[1] : null)
        localStorage.setItem('contactNumberUser', this.props.location.search ? ((this.props.location.search).split(","))[1] : null)
      }

      let pageName, lastOne, lastValue, arr;
      arr = [...this.state.cont];

      setTimeout(
        function () {
          pageName = window.location.pathname;
          lastOne = pageName.split("/");
          lastValue = lastOne[lastOne.length - 1];
          arr.forEach((i) => {
            i.activeClass = "single-menu";
            if (i.urls === lastValue) i.activeClass = "active single-menu";
          });
          this.setState({ cont: arr });
        }.bind(this),
        1
      );
    }
    window.addEventListener("resize", this.handleCheckView.bind(this));

    this.handleCheckView();
    this.handleGetChatSoundNotiSetting();

    setTimeout(() => {
      if (/iPhone|iPad|iPod|Android/i.test(navigator.userAgent)) {
        this.handleChatNotificationDetails();
      }
      this.handleGetChatNotificationCount();
    }, 4000);

    if (window.localStorage.getItem("translateLanguage") === "hindi") {
      this.state.translateLanguage = translationHI;
    } else if (window.localStorage.getItem("translateLanguage") === "marathi") {
      this.state.translateLanguage = translationMA;
    } else {
      this.state.translateLanguage = {};
    }

    if (document.getElementById("chatMessageCount")) {
      document.getElementById("chatMessageCount").innerText = 0;
    }
    if (document.getElementById("chatMessageCountMobile")) {
      document.getElementById("chatMessageCountMobile").innerText = 0;
    }
    if (document.getElementById("chatNotificationMomCount")) {
      document.getElementById("chatNotificationMomCount").innerText = 0;
    }
  }
  //handle close manu
  handleCloseManu() {
    this.setState({
      visible: false,
    });
  }

  //handle set access user
  setAccessUser(data) {
    var path = window.location.pathname;
    var page = path.split("/").pop();
    var accessdata = [];
    var chatBot = {
      data:
        this.state.translateLanguage.default !== undefined
          ? this.state.translateLanguage.default.label.chat
          : "Chat",
      urls: "Chatbot",
      logoBlack: ChatLogo,
      logoBlue: ChatLogoBlue,
      imgAlt: "Chatbot icon",
      imgClass: "storeChatbt",
      activeClass:
        page.toLowerCase() === "Chatbot".toLowerCase()
          ? "active single-menu"
          : "single-menu",
    };
    var campaign = {
      data:
        this.state.translateLanguage.default !== undefined
          ? this.state.translateLanguage.default.nav.campaign
          : "Campaign",
      urls: "campaign",
      logoBlack: CampaignLogo,
      logoBlue: CampaignLogoBlue,
      imgAlt: "campaign icon",
      imgClass: "campaign-icon",
      activeClass:
        page.toLowerCase() === "Campaign".toLowerCase()
          ? "active single-menu"
          : "single-menu",
    };
    var appointment = {
      data:
        this.state.translateLanguage.default !== undefined
          ? this.state.translateLanguage.default.nav.appointment
          : "Appointment",
      urls: "appointment",
      logoBlack: AppointmentLogo,
      logoBlue: AppointmentLogo,
      imgAlt: "campaign icon",
      imgClass: "campaign-icon",
      activeClass:
        page.toLowerCase() === "Appointment".toLowerCase()
          ? "active single-menu"
          : "single-menu",
    };
    var myTicket = {
      data:
        this.state.translateLanguage.default !== undefined
          ? this.state.translateLanguage.default.nav.myticket
          : "MyTicket",
      urls: "myTicketList",
      logoBlack: TicketLogo,
      logoBlue: TicketLogoBlue,
      imgAlt: "ticket icon",
      imgClass: "myTicket",
      activeClass:
        page.toLowerCase() === "MyTicket".toLowerCase()
          ? "active single-menu"
          : "single-menu",
    };
    var orders = {
      data:
        this.state.translateLanguage.default !== undefined
          ? this.state.translateLanguage.default.nav.orders
          : "Orders",
      urls: "orders",
      logoBlack: OrderLogoBlack,
      logoBlue: OrderLogoBlue,
      imgAlt: "Order Icon",
      imgClass: "myTicket",
      activeClass:
        page.toLowerCase() === "Orders".toLowerCase()
          ? "active single-menu"
          : "single-menu",
    };
    var storePay = {
      data:
        this.state.translateLanguage.default !== undefined
          ? this.state.translateLanguage.default.nav.storePay
          : "Store Pay",
      // urls: "orders",
      // logoBlack: OrderLogoBlack,
      // logoBlue: OrderLogoBlue,
      imgAlt: "store pay",
      imgClass: "myTicket",
      // activeClass:
      //   page.toLowerCase() === "".toLowerCase()
      //     ? "active single-menu"
      //     : "single-menu",
    };

    let videoCallAppointment = {
      data: "VideoCall",
      urls: "VideoCallAppointment",
      logoBlack: VideocallAppointment,
      logoBlue: VideocallAppointment,
      imgAlt: "Chatbot icon",
      imgClass: "storeChatbt",
      activeClass:
        page.toLowerCase() === "Chatbot".toLowerCase()
          ? "active single-menu"
          : "single-menu",
    };
    var RetargetFlow = {
      data:
        this.state.translateLanguage.default !== undefined
          ? this.state.translateLanguage.default.nav.myticket
          : "RetargetFlow",
      urls: "retargetFlow",
      logoBlack: Retarget,
      logoBlue: Retarget,
      imgAlt: "ticket icon",
      imgClass: "myTicket",
      activeClass:
        page.toLowerCase() === "RetargetFlow".toLowerCase()
          ? "active single-menu"
          : "single-menu",
    };
    var MISDashboard = {
      data:
        this.state.translateLanguage.default !== undefined
          ? this.state.translateLanguage.default.nav.Dashboard
          : "MISDashboard",
      //  urls: "MISDashboard",
      //  logoBlack: OrderLogoBlack,
      //  logoBlue: OrderLogoBlue,
      imgAlt: "Chatbot icon",
      imgClass: "storeChatbt",
    };
    if (data !== null) {
      for (var i = 0; i < data.length; i++) {
        if (data[i].moduleName === "Chat" && data[i].modulestatus === true) {
          this.setState({
            chatAccess: "block",
          });
          accessdata.push(chatBot);
        } else if (
          data[i].moduleName === "Campaign" &&
          data[i].modulestatus === true
        ) {
          accessdata.push(campaign);
        } else if (
          data[i].moduleName === "Appointment" &&
          data[i].modulestatus === true
        ) {
          accessdata.push(appointment);
        } else if (
          data[i].moduleName === "MyTicket" &&
          data[i].modulestatus === true
        ) {
          accessdata.push(myTicket);
        } else if (
          data[i].moduleName === "Orders" &&
          data[i].modulestatus === true
        ) {
          accessdata.push(orders);
        } else if (
          data[i].moduleName === "Settings" &&
          data[i].modulestatus === true
        ) {
          this.setState({
            settingAccess: "block",
          });
        } else if (
          data[i].moduleName === "Notification" &&
          data[i].modulestatus === true
        ) {
          this.setState({
            notificationAccess: "block",
          });
        } else if (
          data[i].moduleName === "Chat" &&
          data[i].modulestatus === true
        ) {
          this.setState({
            chatAccess: "block",
          });
        } else if (
          data[i].moduleName === "Reports" &&
          data[i].modulestatus === true
        ) {
          this.setState({
            reportAccess: "block",
          });
        } else if (
          data[i].moduleName === "StorePay" &&
          data[i].modulestatus === true
        ) {
          accessdata.push(storePay);
          // this.handleGenerateStorePayLink();
        } else if (
          data[i].moduleName === "MISDashboard" &&
          data[i].modulestatus === true
        ) {
          accessdata.push(MISDashboard);
        } else if (
          data[i].moduleName === "HSM" &&
          data[i].modulestatus === true
        ) {
          this.setState({ isHSMShow: true });
        } else if (
          data[i].moduleName === "VideoCall" &&
          data[i].modulestatus === true
        ) {
          accessdata.push(videoCallAppointment);
        }else if (
          data[i].moduleName === "RetargetFlow" &&
          data[i].modulestatus === true
        ) {
          accessdata.push(RetargetFlow);
        }
      }
    }

    for (let i = 0; i < accessdata.length; i++) {
      if (accessdata[i].activeClass === "active single-menu") {
        this.setState({
          mobileHeading: accessdata[i].data,
        });
      }
    }
    this.setState({
      cont: accessdata,
    });
  }

  componentWillUnmount() {
    // unsubscribe to ensure no memory leaks
    // this.subscription.unsubscribe();
    window.removeEventListener("resize", this.handleCheckView.bind(this));
  }
  componentDidUpdate() {
    var mobileHeading = window.location.pathname.split("/")[2];
    var ticketNo = "";
    var ticketIds = false;
    if (mobileHeading === "campaign") {
      mobileHeading = "Campaign";
      localStorage.removeItem("ticketNo");
    }
    if (mobileHeading === "appointment") {
      mobileHeading = "Appointment";
      localStorage.removeItem("ticketNo");
    }
    if (mobileHeading === "myTicketList") {
      mobileHeading = "Ticket";
      localStorage.removeItem("ticketNo");
    }
    if (mobileHeading === "notification") {
      mobileHeading = "Notification";
      localStorage.removeItem("ticketNo");
    }
    if (mobileHeading === "myTicket") {
      mobileHeading = "ID:";
      ticketIds = true;
      ticketNo = localStorage.getItem("ticketNo");
    }
    if (mobileHeading === "orders") {
      mobileHeading = "Orders";
      localStorage.removeItem("ticketNo");
    }
    if (mobileHeading === "mobileAppSetting") {
      mobileHeading = "Mobile App Setting";
      localStorage.removeItem("ticketNo");
    }
    if (this.state.isMobileView && this.state.mobileHeading !== mobileHeading) {
      this.setState({ mobileHeading, ticketIds, ticketNo });
    }
  }
  //handle crm role data
  handleCRMRole() {
    let self = this;

    this.LoginService.Post("/StoreCRMRole/GetStoreRolesByUserID")
      .then((response) => {
        let msg = response.message;
        let data = response.responseData.modules;
        if (msg === "Success") {
          self.setAccessUser(data);
          this.props.setIsVideoCallEnable(response.responseData.isVideoEnable);
          this.setState({ trackStatus: response.responseData.isOnline ? "Available" : "Offline" });
          // let managerDetails = {
          //   agentId: response.responseData.userID,
          //   programCode: response.responseData.programCode,
          //   storeCode: response.responseData.storeCode
          // };
          // videoCallSocket.emit("storeMangerDetail", managerDetails);
          //videoCallSocket.emit("storeManagerOnlineOffline", { managerDetails: managerDetails, isOnline: response.responseData.isOnline });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  //handle get user profile data
  handleGetUserProfileData() {
    let self = this;
    this.LoginService.Post("/StoreUser/GetStoreUserProfileDetail")
      .then((response) => {
        var status = response.message;
        var data = response.responseData;
        if (status === "Success") {
          window.localStorage.setItem("UserData", JSON.stringify(data));
          var userdata = data.profilePicture;
          var mobileprofilePic = data.profilePicture;
          var userLoginMobileNo = data.mobileNo;
          var storeName = data.storeName;
          var storeAddress = data.storeAddress;

          self.setState({
            mobileprofilePic,
            userLoginMobileNo,
            storeAddress,
            storeName,
          });
          if (userdata) {
            var image = userdata.split("/");
            if (image[image.length - 1] == "") {
              self.setState({
                selectedUserProfilePicture: "",
              });
            } else {
              self.setState({
                selectedUserProfilePicture: userdata,
              });
            }
          }

          self.handleCRMRole();
        } else {
          self.setState({
            selectedUserProfilePicture: "",
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  //handle opne modal
  onOpenModal = () => {
    this.setState({ open: true });
  };
  //handle close modal
  onCloseModal = () => {
    this.setState({ open: false });
  };
  //handle logged in user deatils
  handleLoggedInUserDetails = () => {
    let self = this;
    this.LoginService.Post("/StoreDashboard/StoreLoggedInAccountDetails")
      .then((response) => {
        var data = response.responseData;
        var status = response.message;
        if (status === "Success") {
          window.localStorage.setItem("UserProfile", JSON.stringify(data));

          var strTag = data.agentName.split(" ");
          var nameTag = strTag[0].charAt(0).toUpperCase();
          if (strTag.length > 0) {
            nameTag += strTag[1].charAt(0).toUpperCase();
          }
          let nume =
            data.loggedInDurationInHours * 60 + data.loggedInDurationInMinutes;
          let deno =
            data.shiftDurationInHour * 60 + data.shiftDurationInMinutes;
          let percentLog = ((nume / deno) * 100).toFixed(2);
          var profile = data.profilePicture;
          var finalPath = profile.substring(
            profile.lastIndexOf("\\") + 1,
            profile.length
          );

          self.setState({
            AgentID: data.agentId,
            Email: data.agentEmailId,
            UserName: data.agentName,
            LoginTime: data.loginTime,
            LoggedInDuration: data.loggedInDuration,
            SLAScore: data.slaScore,
            CSatScore: data.csatScore,
            AvgResponse: data.avgResponseTime,
            LogoutTime: data.logoutTime,
            NameTag: nameTag,
            userProfile: finalPath,
            percentLog,
            workTime: data.workTimeInPercentage,
            workTimeHours: data.totalWorkingTime,
            programCode: data.programCode ? data.programCode : "",
            storeCode: data.storeCode ? data.storeCode : "",
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  //handle get store agent details by agent id
  handleGetStoreAgentDetailsById(agentID) {
    let self = this;
    var checkStoreAgentData =
      window.localStorage.getItem("StoreAgentDetails") || "";
    if (checkStoreAgentData === "" || checkStoreAgentData === null) {
      let inputParams = {
        AgentID: agentID,
      };
      this.LoginService.PostWithParams(
        "/HSSetting/GetStoreAgentDetailsById",
        inputParams
      )
        .then((response) => {
          let status = response.message;
          let data = response.responseData;
          if (status === "Success") {
            window.localStorage.setItem(
              "StoreAgentDetails",
              JSON.stringify(data)
            );
            self.setState({ storeAgentDetail: data });
          } else {
            self.setState({ storeAgentDetail: [] });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      var checkStoreAgentData =
        window.localStorage.getItem("StoreAgentDetails") || "";
      self.setState({ storeAgentDetail: JSON.parse(checkStoreAgentData) });
    }
  }

  //handle logout method
  handleLogoutMethod() {
    //To update manager list on logout
    let userData = JSON.parse(window.localStorage.getItem("UserProfile"));
    var storeManger = {
      programCode: userData.programCode,
      storeCode: userData.storeCode,
      agentId: userData.agentId
    };
    videoCallSocket.emit("storeLogOut", storeManger);

    var self = this;
    this.LoginService.Post("/StoreAccount/Logout")
      .then((response) => {
        var status = response.status;
        if (status === true) {
          if (self.state.isMobileView) {
            window.location.href = "/logout";
          } else {
            localStorage.clear();
            window.location.href = "/";
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  //handle get notification
  handleGetNotigfication() {
    let self = this;
    this.LoginService.Post("/StoreNotification/GetStoreNotificationsNew")
      .then((response) => {
        var message = response.message;
        var responseData = response.responseData;
        var Noticount = responseData.notiCount;
        if (message === "Success" && responseData) {
          self.setState({
            notificationData: responseData.storeNotificationModel,
            notificationCount: Noticount,
          });
        } else {
          self.setState({
            notificationData: [],
            notificationCount: 0,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  actives = (e) => {
    var pageName = "";
    const contDummy = [...this.state.cont];
    contDummy.forEach((i) => {
      i.activeClass = "single-menu";
      if (i.data === e.target.textContent) {
        i.activeClass = "active single-menu";
        pageName = i.urls;
      }
    });

    if (this.state.chatModal && !this.state.isMobileView) {
      this.setState({ chatModal: false });
    }
    this.setState({
      cont: contDummy,
      visible: false,
      mobileHeading: e.target.textContent,
    });

    if (pageName === "Chatbot") {
      this.props.history.push({
        pathname: "Chatbot",
        state: {
          programCode: this.state.programCode,
          storeCode: this.state.storeCode,
          agentId: this.state.AgentID,
          tenantID: this.state.tenantID,
          UserName: this.state.UserName,
        },
      });
    }
  };

  //handle get chat sound notification setting
  handleGetChatSoundNotiSetting = () => {
    let self = this;
    this.LoginService.Post("/CustomerChat/GetChatSoundNotiSetting")
      .then((response) => {
        var message = response.message;
        var responseData = response.responseData;
        if (message === "Success" && responseData) {
          var reader = new FileReader();
          var reader1 = new FileReader();
          self.setState({
            newChatSoundVolume: responseData.newChatSoundVolume || 0,
            newMessageSoundVolume: responseData.newMessageSoundVolume || 0,
            isNotiNewChat: responseData.isNotiNewChat || false,
            isNotiNewMessage: responseData.isNotiNewMessage || false,
            notificationTime: responseData.notificationTime,
            tenantID: responseData.tenantID,
          });
          fetch(responseData.newMessageSoundFile).then(function (res) {
            res.blob().then(function (blob) {
              reader.addEventListener("loadend", function () {
                var base64FileData = reader.result.toString();
                localStorage.setItem(
                  "newMessageSoundFile",
                  JSON.stringify(base64FileData)
                );
              });
              reader.readAsDataURL(blob);
            });
          });

          fetch(responseData.newChatSoundFile).then(function (res) {
            res.blob().then(function (blob) {
              reader1.addEventListener("loadend", function () {
                var base64FileData = reader1.result.toString();
                localStorage.setItem(
                  "newChatSoundFile",
                  JSON.stringify(base64FileData)
                );
              });
              reader1.readAsDataURL(blob);
            });
          });
          window.localStorage.setItem(
            "ChatSoundNotiSetting",
            JSON.stringify(responseData)
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  //handle check is mobile view active or not
  handleCheckView() {
    var isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    if (isMobile) {
      this.setState({ isMobileView: true });
      this.props.setIsMobileView(true)
    } else {
      this.setState({ isMobileView: false, ticketNo: "" });
      this.props.setIsMobileView(false)
    }
  }
  handleRedireactToTicket = () => {
    document.getElementById("MobTicket").click();
  };

  handleOpenChatbotPage = (isBack) => {
    if (isBack) {
      document.getElementById("chatBackIcon").style.display = "none";
      document.getElementById("store-footre-main").style.display = "block";
      document.getElementById("backButtonClick").click();
    }
    this.props.history.push({
      pathname: "Chatbot",
      state: {
        programCode: this.state.programCode,
        storeCode: this.state.storeCode,
        agentId: this.state.AgentID,
        tenantID: this.state.tenantID,
        UserName: this.state.UserName,
      },
    });
  };
  handleChatNotificationDetails() {
    let self = this;
    this.setState({ chatNotificationLoader: true });
    let inputParams = {
      Pageno: 1,
    };
    this.LoginService.PostWithParams(
      "/CustomerChat/GetMobileNotificationsDetails",
      inputParams
    )
      .then((response) => {
        var message = response.message;
        var responseData = response.responseData;
        self.setState({
          chatNotificationLoader: false,
        });
        if (message === "Success" && responseData) {
          if (document.getElementById("chatNotificationMomCount")) {
            document.getElementById("chatNotificationMomCount").innerText =
              responseData.unReadNotiCount;
          }
        } else {
          if (document.getElementById("chatNotificationMomCount")) {
            document.getElementById("chatNotificationMomCount").innerText = 0;
          }
        }

        self.forceUpdate();
      })
      .catch((error) => {
        self.setState({ chatNotificationLoader: false });
        console.log(error);
      });
  }
  //handle get chat notification count
  async handleGetChatNotificationCount() {
    let self = this;
    var objNotiCount = {
      userMaster_ID: this.state.AgentID,
      tenant_ID: this.state.tenantID,
      ProgramCode: this.state.programCode,
      StoreCode: this.state.storeCode,
    };

    socket.emit("CallChatNotificationCount", objNotiCount);

    socket.once(
      "CallChatNotificationCount" +
      this.state.storeCode.toLowerCase() +
      this.state.programCode.toLowerCase(),
      function (data) {
        // console.log(data, "---CallChatNotificationCount");
        if (data) {
          self.setState({
            chatMessageCount: data[0].TotalUnreadChatCount,
          });
          if (document.getElementById("chatMessageCount")) {
            document.getElementById("chatMessageCount").innerText =
              data[0].TotalUnreadChatCount;
          }
          if (document.getElementById("chatMessageCountMobile")) {
            document.getElementById("chatMessageCountMobile").innerText =
              data[0].TotalUnreadChatCount;
          }
        } else {
          self.setState({
            chatMessageCount: data[0].TotalUnreadChatCount,
          });
          if (document.getElementById("chatMessageCount")) {
            document.getElementById("chatMessageCount").innerText =
              data[0].TotalUnreadChatCount;
          }
          if (document.getElementById("chatMessageCountMobile")) {
            document.getElementById("chatMessageCountMobile").innerText =
              data[0].TotalUnreadChatCount;
          }
        }
      }
    );
  }
  handleUpdateChatMessageCount(count) {
    this.setState({
      chatNotificationMomCount: count || 0,
    });
  }
  handleGetChatCountForMobile() {
    if (document.getElementById("chatBackIcon")) {
      document.getElementById("chatBackIcon").style.display = "none";
    }
    if (document.getElementById("store-footre-main")) {
      document.getElementById("store-footre-main").style.display = "block";
    }
  }
  //handle genrate store pay link
  handleGenerateStorePayLink = () => {
    let self = this;
    if (this.state.chatModal && !this.state.isMobileView) {
      this.setState({ chatModal: false });
    }
    this.LoginService.Post("/StorePay/GenerateStorePayLink")
      .then((response) => {
        var message = response.message;
        //var storePayURL = response.responseData;
        var storePayURL = encryption(response.responseData, "decr");
        if (message === "Success" && storePayURL) {
          self.setState({
            storePayURL,
          });
          window.open(storePayURL, "blank");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  handleMISDashboardNavigation = () => {
    var UserData = JSON.parse(window.localStorage.getItem("UserData"));
    var ProgramCode = window.localStorage.getItem("Programcode");
    var MISDashboardLink =
      config.misUrl +
      encryption(UserData["userId"]) +
      "&Programcode=" +
      ProgramCode;
    window.open(MISDashboardLink, "blank");
  };
  handleClickOpen = () => {
    this.setState({ checked: !this.state.checked });
  };

  handleClose = () => {
    this.setState({ checked: false });
  };
  handleListItemClick = (value) => {
    this.setState({ trackStatus: value, checked: false });
    let isOnline = true;
    let userData = JSON.parse(window.localStorage.getItem("UserProfile"));
    let managerDetails = {
      agentId: userData.agentId,
      programCode: userData.programCode,
      storeCode: userData.storeCode,
      // isBusy: localStorage.getItem("CallAccepted")
      //   ? localStorage.getItem("CallAccepted") === true
      //     ? true
      //     : false
      //   : false,
    };
    if (value === "Available") {
      isOnline = true;
      // videoCallSocket.connect();
      // console.log("Available", managerDetails);
      // videoCallSocket.emit(
      //   "storeOnline",
      //   userData.programCode + userData.storeCode
      // );
      // videoCallSocket.emit("mobileNumber", {
      //   roomID: userData.programCode + userData.storeCode + userData.agentId,
      //   sotreId: userData.programCode + userData.storeCode,
      // });
    } else {
      isOnline = false;
      // let inputParams = {
      //   isFlag: false, //// asset approve for 1 and upload log  for 2
      // };
      // this.StoreHeaderService.PostWithParams("/StoreAccount/UpdateOnlineOfflineFlag", inputParams)
      // .then((response) => {
      //   console.log(response);
      // })
      // .catch((error) => {
      //   console.log(error);
      // });
      // var storeManger = {
      //   managerCode: userData.agentId + "",
      //   storeCode: userData.programCode + userData.storeCode,
      //   isOnline: false,
      //   isBusy: false,
      //   isCalling: false,
      // };
      // console.log("Offline", storeManger);
      // videoCallSocket.emit("storeLogOut", storeManger);
      // videoCallSocket.disconnect();
    }
    videoCallSocket.emit("storeManagerOnlineOffline", { managerDetails: managerDetails, isOnline: isOnline });
    let inputParams = {
      isFlag: isOnline, //// asset approve for 1 and upload log  for 2
    };
    this.StoreHeaderService.PostWithParams("/StoreAccount/UpdateOnlineOfflineFlag", inputParams)
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  render() {
    const TranslationContext = this.state.translateLanguage.default;
    let userDataItem = JSON.parse(window.localStorage.getItem("UserProfile"));
    // if (userDataItem) {
    //   let managerDetails = {
    //     agentId: userDataItem.agentId,
    //     programCode: userDataItem.programCode,
    //     storeCode: userDataItem.storeCode,
    //     isBusy: localStorage.getItem("CallAccepted") ? (localStorage.getItem("CallAccepted") === true ? true : false) : false,
    //   }
    //   videoCallSocket.emit("storeOnline", userDataItem.programCode + userDataItem.storeCode);
    //   videoCallSocket.emit("storeMangerDetail", managerDetails);
    // }
    if ("?userEndCallWhileRinging" === this.props.location.search) {
      this.props.setIsCallRecived(false);
      let closeData = {
        // socketIDToRemove: '',
        // isStopCall: 'callEndDuringRinging',
        // from: { from: 'store', managerCode: userDataItem.agentId },
        // userDetails: {
        programCode: userDataItem.programCode,
        storeCode: userDataItem.storeCode,
        agentId: userDataItem.agentId
        // },
        // roomID: userDataItem.programCode + userDataItem.storeCode + userDataItem.agentId
      };
      videoCallSocket.emit("close", closeData);
    }

    return (
      <React.Fragment>
        {
          this.state.isMobileView &&
            this.props.isCallRecived ? (
            <VideoCall />
          ) : null}
        <div
          className="small_screen_overlay d-flex align-items-center justify-content-between d-nonemob"
          style={{ background: "white" }}
        >
          <div className="d-flex">
            <div className="er bell-icon">
              <img src={BellIcon} alt="bell icon" />
            </div>
            <div className="headers-menu">
              {this.state.cont.map((item) => {
                if (item.data === "MISDashboard") {
                  return (
                    <a
                      key={item.data}
                      className="storepay-a single-menu"
                      onClick={this.handleMISDashboardNavigation.bind(this)}
                    >
                      {item.logoBlack ? (
                        <div className="header-icons-cntr">
                          <img
                            src={item.logoBlack}
                            alt={item.imgAlt}
                            className={item.imgClass}
                          />
                          <img
                            src={item.logoBlue}
                            alt={item.imgAlt}
                            className={item.imgClass}
                          />
                        </div>
                      ) : null}
                      <label>{item.data}</label>
                    </a>
                  );
                }
                if (item.data === "Store Pay" || item.data === "स्टोर पे") {
                  return (
                    <a
                      key={item.data}
                      className="storepay-a single-menu"
                      onClick={this.handleGenerateStorePayLink.bind(this)}
                    >
                      {item.logoBlack ? (
                        <div className="header-icons-cntr">
                          <img
                            src={item.logoBlack}
                            alt={item.imgAlt}
                            className={item.imgClass}
                          />
                          <img
                            src={item.logoBlue}
                            alt={item.imgAlt}
                            className={item.imgClass}
                          />
                        </div>
                      ) : null}
                      <label>{item.data}</label>
                    </a>
                  );
                } else if (item.data === "Chat") {
                  return (
                    <a
                      onClick={this.actives}
                      key={item.data}
                      className={
                        window.location.pathname
                          .split("/")
                          .pop()
                          .toLowerCase() === item.urls.toLowerCase()
                          ? "active single-menu"
                          : "single-menu"
                      }
                    >
                      <div className="header-icons-cntr">
                        <img
                          src={item.logoBlack}
                          alt={item.imgAlt}
                          className={item.imgClass}
                        />
                        <img
                          src={item.logoBlue}
                          alt={item.imgAlt}
                          className={item.imgClass}
                        />
                      </div>
                      <label className="cusheade">{item.data}</label>
                    </a>
                  );
                } else if (item.data === "VideoCall") {
                  return (
                    <>
                      <a
                        className={
                          window.location.pathname
                            .split("/")
                            .pop()
                            .toLowerCase() === item.urls.toLowerCase()
                            ? "active single-menu"
                            : "single-menu"
                        }
                        id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        {item.data}
                      </a>
                      <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        <Link className="dropdown-item" to="/store/Call">
                          <CallOutlinedIcon
                            style={{ fontsize: "small" }}
                          />
                          Call
                        </Link>
                        <Link className="dropdown-item" to="/store/VideoCallAppointment">
                          <EventAvailableOutlinedIcon
                            style={{ fontsize: "small" }}
                          />
                          Appointment
                        </Link>
                      </div>
                    </>
                  );
                } else if (item.data === "Retarget Flow") {
                  return (
                    <a
                      onClick={this.actives}
                      key={item.data}
                      className={
                        window.location.pathname
                          .split("/")
                          .pop()
                          .toLowerCase() === item.urls.toLowerCase()
                          ? "active single-menu"
                          : "single-menu"
                      }
                    >
                      <div className="header-icons-cntr">
                        <img
                          src={item.logoBlack}
                          alt={item.imgAlt}
                          className={item.imgClass}
                        />
                        <img
                          src={item.logoBlue}
                          alt={item.imgAlt}
                          className={item.imgClass}
                        />
                      </div>
                      <label className="cusheade">{item.data}</label>
                    </a>
                  );
                }
                else {
                  return (
                    <Link
                      onClick={this.actives}
                      key={item.data}
                      to={{
                        pathname: item.urls,
                        state: {
                          userMaster_ID: this.state.userMaster_ID,
                          tenant_ID: this.state.tenant_ID,
                          ProgramCode: this.state.ProgramCode,
                          StoreCode: this.state.StoreCode,
                        },
                      }}
                      // className={item.activeClass}
                      className={
                        window.location.pathname
                          .split("/")
                          .pop()
                          .toLowerCase() === item.urls.toLowerCase()
                          ? "active single-menu"
                          : "single-menu"
                      }
                    >
                      <div className="header-icons-cntr">
                        <img
                          src={item.logoBlack}
                          alt={item.imgAlt}
                          className={item.imgClass}
                        />
                        <img
                          src={item.logoBlue}
                          alt={item.imgAlt}
                          className={item.imgClass}
                        />
                      </div>
                      <label className="cusheade">{item.data}</label>
                    </Link>
                  );
                }
              })}
            </div>
          </div>

          <div className="header-right-icons" id="mobileHederIcon">
            {this.state.isHSMShow ? (
              <Link to="/store/WebBotLink">
                <img
                  src={WebBotLink}
                  alt="WebBotLink"
                  className="setting"
                  style={{ width: "28px" }}
                />
              </Link>
            ) : null}
            <a
              onClick={this.handleOpenChatbotPage.bind(this, false)}
              style={{ display: this.state.chatAccess }}
            >
              <div className="position-relative">
                {this.state.isMobileView ? (
                  <img src={MobileChatIcon} alt="logo" className="chatImg" />
                ) : (
                  <img src={ChatLogo} alt="logo" className="chatImg" />
                )}
                <img
                  src={ChatLogoBlue}
                  alt="logo"
                  className="chatImg"
                  style={{ display: "none" }}
                />
                <span id="chatMessageCount" className="message-icon-cnt">
                  {/* {this.state.chatMessageCount || 0} */}
                </span>
              </div>
            </a>
            <Link
              to="/store/settings"
              style={{ display: this.state.settingAccess }}
            >
              <img src={SettingLogo} alt="logo" className="setting" />
              <img
                src={SettingLogoBlue}
                alt="logo"
                className="setting"
                style={{ display: "none" }}
              />
              <span style={{ display: "none" }} className="icon-fullname">
                Settings
              </span>
            </Link>
            <input
              type="hidden"
              value={this.state.reportAccess}
              id="isReport"
            />
            <input
              type="hidden"
              value={this.state.newTicketChatId}
              id="newTicketChatId"
            />
            <a href="#!" className="bitmap5 useric" onClick={this.onOpenModal}>
              {this.state.UserName.split(" ")
                .map((n) => n[0])
                .join("")
                .toUpperCase()}
            </a>
          </div>
        </div>
        <div className="small_screen_overlay mob-header">
          <label className="cusheadermob">
            <img
              id="chatBackIcon"
              src={WhiteIcon}
              alt="back"
              style={{
                display: "none",
                width: "26px",
                marginRight: "10px",
                marginBottom: "5px",
              }}
              onClick={this.handleOpenChatbotPage.bind(this, true)}
            />
            {this.state.ticketIds ? (
              <img
                src={BackIcon}
                alt="back"
                style={{ width: "15px", marginRight: "10px" }}
                onClick={this.handleRedireactToTicket.bind(this)}
              />
            ) : null}
            {this.state.ticketIds
              ? "ID :" + this.state.ticketNo
              : this.state.mobileHeading}
          </label>
          {/* {!this.state.ticketNo ? ( */}
          <div
            className="header-right-icons"
            style={{
              display: this.state.ticketNo ? "none" : "",
            }}
          >
            {this.state.isHSMShow ? (
              <Link
                to="/store/WebBotLink"
                onClick={this.handleGetChatCountForMobile.bind(this)}
              >
                <div className="position-relative backnotifi">
                  <img
                    src={WebBotLink}
                    alt="WebBotLink"
                    className="webbotImg"
                  />
                </div>
              </Link>
            ) : null}
            {/* -------------------------Mobile chat notification ---------------*/}
            {this.state.isMobileView ? (
              <Link
                to={{
                  pathname: "notification",
                  state: {
                    programCode: this.state.programCode,
                    storeCode: this.state.storeCode,
                    tenantID: this.state.tenantID,
                    AgentID: this.state.AgentID,
                    UserName: this.state.UserName,
                  },
                  handleUpdateChatMessageNotificationCount:
                    this.handleUpdateChatMessageCount.bind(this),
                }}
              >
                <div
                  className="position-relative backnotifi"
                  id="MobileChatNotifiCount"
                  onClick={this.handleGetChatCountForMobile.bind(this)}
                >
                  <img
                    src={NotificationLogo}
                    alt="logoMobile"
                    className="notifi"
                    style={{ width: "15px" }}
                  />
                  <span style={{ display: "none" }} className="icon-fullname">
                    Notifications
                  </span>

                  <span
                    className="upper-noti-count"
                    id="chatNotificationMomCount"
                  >
                    {/* {this.state.chatNotificationMomCount} */}0
                  </span>

                  <span style={{ display: "none" }} className="icon-fullname">
                    Notifications
                  </span>
                </div>
              </Link>
            ) : null}
            <a
              id="chatwindow"
              onClick={this.handleOpenChatbotPage.bind(this, false)}
              style={{ display: this.state.chatAccess }}
            >
              <div className="position-relative backnotifi">
                {this.state.isMobileView ? (
                  <img
                    src={MobileChatIcon}
                    alt="logo"
                    className="chatImg mobic"
                  />
                ) : (
                  <img src={ChatLogow} alt="logo" className="chatImg" />
                )}
                <img
                  src={ChatLogoBlue}
                  alt="logo"
                  className="chatImg"
                  style={{ display: "none" }}
                />
                <span id="chatMessageCountMobile" className="message-icon-cnt">
                  {/* {this.state.chatMessageCount} */}
                </span>
              </div>
            </a>
            {/* <a>
              <img src={SearchWh} alt="logo" className="searchIcon" />
            </a> */}

            {!this.state.isMobileView ? (
              <a
                href="#!"
                className="bitmap5 useric"
                onClick={this.onOpenModal}
              >
                {this.state.UserName.split(" ")
                  .map((n) => n[0])
                  .join("")
                  .toUpperCase()}
              </a>
            ) : this.props.isVideoCallEnable && this.state.isMobileView ? (
              <>
                <Badge
                  overlap="circular"
                  anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                  badgeContent={
                    this.state.trackStatus == "Available" ? (
                      <img src={CheckCircleIcon} alt="No image" className="status-icon" />
                    ) : this.state.trackStatus != "" && (
                      <img src={CancelOutlinedIcon} alt="No Image" className="status-icon" />
                    )
                  }
                >
                  <Avatar
                    className="profile-avatar"
                    alt="User"
                    onClick={this.onOpenModal}
                    src={
                      this.state.selectedUserProfilePicture.length > 0
                        ? this.state.selectedUserProfilePicture
                        : ProfileImg
                    }
                  />
                </Badge>
              </>
            ) : (
              <img
                className="bitmap5 useric"
                src={
                  this.state.mobileprofilePic === ""
                    ? ProfileImg
                    : this.state.mobileprofilePic
                }
                onClick={this.onOpenModal}
                alt={this.state.UserName.split(" ")
                  .map((n) => n[0])
                  .join("")
                  .toUpperCase()}
              />
            )}
            <a className="verticaldots">
              <img src={Dots} alt="Dots" />
            </a>
          </div>
          {/* ) : null} */}
        </div>
        <Modal
          open={this.state.open}
          onClose={this.onCloseModal}
          center
          modalId="logout-popup"
          overlayId="logout-ovrly"
          className='small_screen_overlay'
        >
          <div className="logout-block">
            <div>
              <div className="user-img">
                <Link to="userprofile">
                  {/* <img
                    src={
                      this.state.selectedUserProfilePicture.length > 0
                        ? this.state.selectedUserProfilePicture
                        : ProfileImg
                    }
                    alt="User"
                    style={{ width: "90px" }}
                    title="Edit Profile"
                    onClick={this.onCloseModal.bind(this)}
                  /> */}
                  {this.props.isVideoCallEnable && this.state.isMobileView ? (
                    <Badge
                      overlap="circular"
                      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                      badgeContent={
                        this.state.trackStatus == "Available" ? (
                          <img src={CheckCircleIcon} alt="No image" className="status-icon" />
                        ) : (
                          <img src={CancelOutlinedIcon} alt="No Image" className="status-icon" />
                        )
                      }
                    >
                      <Avatar
                        className="profile-avatar"
                        alt="User"
                        onClick={this.onCloseModal.bind(this)}
                        src={
                          this.state.selectedUserProfilePicture.length > 0
                            ? this.state.selectedUserProfilePicture
                            : ProfileImg
                        }
                      />
                    </Badge>
                  ) : (
                    <img
                      src={
                        this.state.selectedUserProfilePicture.length > 0
                          ? this.state.selectedUserProfilePicture
                          : ProfileImg
                      }
                      alt="User"
                      style={{ width: "90px" }}
                      title="Edit Profile"
                      onClick={this.onCloseModal.bind(this)}
                    />
                  )}
                </Link>
              </div>
              <div className="logout-flex">
                <div>
                  <p style={{ fontSize: "16px", fontWeight: "600" }}>
                    {this.state.UserName}
                    &nbsp;
                    <Link
                      to="userprofile"
                      onClick={this.handleGetChatCountForMobile.bind(this)}
                    >
                      <img
                        src={PencilImg}
                        alt="Pencile"
                        className="pencilImg"
                        title="Edit Profile"
                        onClick={this.onCloseModal.bind(this)}
                      />
                    </Link>
                  </p>

                  <p className="mail-id">{this.state.Email}</p>
                  <>
                    {this.state.checked ? (
                      <ClickAwayListener onClickAway={() => this.handleClose()}>
                        <div className="status-container">
                          <div className="status-container-box">
                            {this.state.emails.map((email) => (
                              <div
                                className="status-info"
                                onClick={() =>
                                  this.handleListItemClick(email.status)
                                }
                              >
                                <div className="status-logo">{email.icon}</div>
                                <div className="status-indicator">
                                  {email.status}
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </ClickAwayListener>
                    ) : (
                      <></>
                    )}
                    {this.props.isVideoCallEnable && this.state.isMobileView ? (
                      <Button onClick={() => this.handleClickOpen()}>
                        {this.state.trackStatus} <KeyboardArrowDownIcon />
                      </Button>
                    ) : (
                      <></>
                    )}
                  </>
                </div>
                <button
                  type="button"
                  className="logout logi"
                  onClick={this.handleLogoutMethod.bind(this)}
                >
                  {TranslationContext !== undefined
                    ? TranslationContext.button.logout
                    : "LOGOUT"}
                </button>
              </div>
            </div>
            {this.state.isMobileView ? (
              <div
                style={{
                  display: "inline-grid",
                }}
              >
                {this.state.storeName ? (
                  <label className="storeHeader-Name">
                    {this.state.storeName + " ( " + this.state.storeCode + " )"}
                  </label>
                ) : null}
                {this.state.storeAddress ? (
                  <label className="storeHeader-address">
                    {this.state.storeAddress}
                  </label>
                ) : null}
              </div>
            ) : null}
            <div className="d-block">
              <div className="d-flex justify-content-between">
                <div>
                  <p className="logout-label">
                    {TranslationContext !== undefined
                      ? TranslationContext.p.logintime
                      : "Login Time"}
                  </p>
                  <p className="font-weight-bold" style={{ fontSize: "16px" }}>
                    {this.state.LoginTime}
                  </p>
                </div>
                <div>
                  <p className="logout-label">
                    {TranslationContext !== undefined
                      ? TranslationContext.p.logouttime
                      : "Logout Time"}
                  </p>
                  <p
                    className="font-weight-bold"
                    style={{ fontSize: "16px", float: "right" }}
                  >
                    {this.state.LogoutTime}
                  </p>
                </div>
              </div>
              <ProgressBar
                className="logout-progress"
                now={this.state.workTime}
              />
              <p
                className="logout-label font-weight-bold prog-indi"
                style={{
                  width: this.state.workTime + "%",
                  textTransform: "uppercase",
                }}
              >
                {this.state.workTimeHours}
              </p>
            </div>

            <div className="butnlog">
              <button
                type="button"
                className="logout logi1"
                onClick={this.handleLogoutMethod.bind(this)}
              >
                {TranslationContext !== undefined
                  ? TranslationContext.button.logout
                  : "LOGOUT"}
              </button>
            </div>
            {this.state.isMobileView ? (
              <div className="switch_platform butnlog">
                <Dropdown className="switch_platformDropDown">
                  <Dropdown.Toggle className="logout logi1">
                    Switch Platform
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item href="#" className="active p-2">
                      Bell
                    </Dropdown.Item>
                    <Dropdown.Item href="/switchplatform" className="p-2">
                      Collecta
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            ) : null}
          </div>
        </Modal>
        <Drawer
          placement="left"
          closable={false}
          onClose={this.handleCloseManu.bind(this)}
          visible={this.state.visible}
          overlayClassName="cussidebar"
          className="small_screen_overlay"
        >
          <img
            src={CancelIconW}
            alt="cancel-icone"
            className="cust-icon"
            onClick={this.handleCloseManu.bind(this)}
          />
          <div className="mobsidebar">
            <div className="uppersec">
              <ul>
                <li>{/* <img src={BellIcon} alt="Icon" /> */}</li>
                <li>
                  <h3>{this.state.UserName}</h3>
                  <p>{this.state.storeCode}</p>
                </li>
              </ul>
            </div>
            <div className="lowersec">
              <ul>
                {this.state.cont.map((item) => {
                  if (item.data === "Store Pay" || item.data === "स्टोर पे") {
                    return (
                      <>
                        <li key={item.data}>
                          <a className="storepay-a single-menu">
                            {item.logoBlack ? (
                              <span className="header-icons-cntr mr-0">
                                <img
                                  src={item.logoBlack}
                                  alt={item.imgAlt}
                                  className={item.imgClass}
                                />
                                <img
                                  src={item.logoBlue}
                                  alt={item.imgAlt}
                                  className={item.imgClass}
                                />
                              </span>
                            ) : null}

                            <label
                              onClick={this.handleGenerateStorePayLink.bind(
                                this
                              )}
                            >
                              {item.data}
                            </label>
                          </a>
                        </li>
                      </>
                    );
                  } else {
                    return (
                      <li key={item.data}>
                        <Link
                          onClick={this.actives}
                          to={{
                            pathname: item.urls,
                          }}
                          className={item.activeClass}
                        >
                          <span className="header-icons-cntr mr-0">
                            <img
                              src={item.logoBlack}
                              alt={item.imgAlt}
                              className={item.imgClass}
                            />
                            <img
                              src={item.logoBlue}
                              alt={item.imgAlt}
                              className={item.imgClass}
                            />
                          </span>
                          {item.data === "Task" ? "Campaign" : item.data}
                        </Link>
                      </li>
                    );
                  }
                })}
              </ul>
            </div>
            <div className="logoutbox">
              <ul>
                <li onClick={this.handleLogoutMethod.bind(this)}>
                  <img src={Logout} alt="Logout" />
                  {TranslationContext !== undefined
                    ? TranslationContext.span.logout
                    : "Logout"}
                </li>
              </ul>
            </div>
          </div>
        </Drawer>
        {/* {this.state.isMobileView &&
          this.state.storeCode &&
          this.state.programCode &&
          this.state.AgentID &&
          this.props.isCallRecived ? (
          <VideoCall
            storeCode={this.state.storeCode}
            programCode={this.state.programCode}
            agentId={this.state.AgentID + ""}
          />
        ) : null} */}
        {/* {console.log(this.props)} */}
        {localStorage.getItem("CallAccepted") !== 'true' ?
          <div className="small_screen_onlyVideoCall">
            <div className="Shopster_icon_videoCall">
              <img src={ShopSter} alt="ShopSter" style={{ width: '80%' }} />
            </div>
          </div> : null}
        <audio id="newChatAudio">
          <source
            src={JSON.parse(window.localStorage.getItem("newChatSoundFile"))}
            type="audio/mpeg"
          />
        </audio>
        <audio id="newChatMeaasgeAudio">
          <source
            src={JSON.parse(window.localStorage.getItem("newMessageSoundFile"))}
            type="audio/mpeg"
          />
        </audio>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isVideoCallEnable: state.isVideoCallEnable,
    isCallRecived: state.isCallRecived,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setIsVideoCallEnable: (isVideoCallEnable) => dispatch({ type: "IS_VIDEO_CALL_ENABLE", payload: isVideoCallEnable }),
    setIsCallRecived: (isCallRecived) => dispatch({ type: "IS_CALL_RIECIVED", payload: isCallRecived }),
    setCustomerNumber: (customerMobileNumber) => dispatch({ type: "SET_CUSTOMER_NUMBER", payload: customerMobileNumber }),
    setVideoCallRoomName: (roomName) => dispatch({ type: "SET_ROOMNAME", payload: roomName }),
    setIsMobileView: (isMobileView) => dispatch({ type: "IS_MOBILE_VIEW", payload: isMobileView }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Header));
