export function handleMaskingNumber(
  contact_number,
  maskingPrefix,
  maskingSufix
) {
  if (contact_number !== null && contact_number !== undefined) {
    var number = contact_number.split('');
    let maxLength = 0;
    for (let i = 0; i < number.length; i++) {
      if (number[i].length > maxLength) {
        maxLength = number[i].length;
      }
    }
    let frontNumber = number.splice(0, Number(maskingPrefix));
    let lastNumber = number.splice(Number(-maskingSufix));
    let frontNumberString = frontNumber.join("");
    number.forEach((item) => {
      frontNumberString = frontNumberString + "*";
    });
    return frontNumberString + lastNumber.join("");
  } else {
    return contact_number;
  }
}
