import React, { Component } from "react";
import RedDeleteIcon from "./../../../assets/Images/red-delete-icon.png";
import { UncontrolledPopover, PopoverBody } from "reactstrap";
import DelBigIcon from "./../../../assets/Images/del-big.png";
import FileUpload from "./../../../assets/Images/file.png";
import DelBlack from "./../../../assets/Images/del-black.png";
import DownExcel from "./../../../assets/Images/csv.png";
import UploadCancel from "./../../../assets/Images/upload-cancel.png";
import BlackInfoIcon from "./../../../assets/Images/Info-black.png";
import { ProgressBar } from "react-bootstrap";
import Demo from "./../../../store/Hashtag.js";
import { Link } from "react-router-dom";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Popover, Spin, TimePicker } from "antd";
import ReactTable from "react-table";
import config from "../../../helpers/config";
import Select from "react-select";
import { NotificationManager } from "react-notifications";
import ActiveStatus from "../../activeStatus";
import ZoneType from "./ZoneType";
import { CSVLink } from "react-csv";
import Modal from "react-responsive-modal";
import Sorting from "./../../../assets/Images/sorting.png";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import matchSorter from "match-sorter";
import { formatSizeUnits } from "./../../../helpers/CommanFuncation";
import Dropzone from "react-dropzone";
import * as translationHI from "./../../../translations/hindi";
import * as translationMA from "./../../../translations/marathi";
import { DatePicker, Collapse, Checkbox } from "antd";
import moment from "moment";
import SettingsService from "../../../Services/Settings/SettingsService";
import { Tabs, Tab } from "react-bootstrap-tabs/dist";
import * as timezone from "../../../helpers/timezone";
import AsyncSelect from "react-select/async";

class StoreMaster extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectState: 0,
      selectCity: 0,
      selectedBrand: [],
      EditBrand: [],
      selectStatus: "",
      storeData: [],
      storeEditData: {},
      brandData: [],
      stateData: [],
      cityData: [],
      regionData: [],
      storeTypeData: [],
      activeData: ActiveStatus(),
      zoneData: ZoneType(),
      store_code: "",
      store_name: "",
      pin_code: "",
      store_Address: "",
      selectRegion: 0,
      selectZone: 0,
      store_type: 0,
      contact_email: "",
      contact_Phone: "",
      loading: false,
      userEditData: {},
      store_codeCompulsion: "",
      store_nameCompulsion: "",
      pin_codeCompulsion: "",
      store_AddressCompulsion: "",
      RegionCompulsion: "",
      ZoneCompulsion: "",
      store_typeCompulsion: "",
      contact_emailCompulsion: "",
      contact_PhoneCompulsion: "",
      StateCompulsion: "",
      CityCompulsion: "",
      brandCompulsion: "",
      statusCompulsion: "",
      StatusModel: false,
      sortAllData: [],
      sortStoreName: [],
      sortStoreCode: [],
      sortCity: [],
      sortState: [],
      sortPincode: [],
      sortBrandName: [],
      sortColumn: "",
      editmodel: false,
      modalSelectedBrand: [],
      editSaveLoading: false,
      emailFlag: true,
      EditEmailFlag: true,
      pinCodeFlag: true,
      phoneFlag: true,
      EditPhoneFlag: true,
      editBrandValidation: "",
      editStoreCodeValidation: "",
      editStoreNameValidation: "",
      editStateValidation: "",
      editCityValidation: "",
      editPinCodeValidation: "",
      editStoreAddressValidation: "",
      editRegionValidation: "",
      editZoneValidation: "",
      editStoreTypeValidation: "",
      editContactEmailValidation: "",
      editContactPhoneValidation: "",
      editStatusValidation: "",
      storeNameColor: "",
      storeCodecolor: "",
      cityColor: "",
      stateColor: "",
      pincodeColor: "",
      brandnameColor: "",
      sortHeader: "",
      bulkuploadCompulsion: "",
      fileName: "",
      fileN: [],
      sortFilterStoreName: [],
      sortFilterStoreCode: [],
      sortFilterCity: [],
      sortFilterState: [],
      sortFilterPincode: [],
      sortFilterBrandName: [],
      filterTxtValue: "",
      sFilterCheckbox: "",
      tempstoreData: [],
      isFileUploadFail: false,
      progressValue: 0,
      fileSize: "",
      showProgress: false,
      sstoreNameFilterCheckbox: "",
      sstoreCodeFilterCheckbox: "",
      scityNameFilterCheckbox: "",
      sstateNameFilterCheckbox: "",
      sstrPinCodeFilterCheckbox: "",
      isortA: false,
      sortregionName: [],
      sortzone: [],
      sortstoreTypeName: [],
      sortemail: [],
      sortphoneNumber: [],
      sortstatus: [],
      sortFilterregionName: [],
      sortFilterzone: [],
      sortFilterstoreTypeName: [],
      sortFilteremail: [],
      sortFilterphoneNumber: [],
      sortFilterstatus: [],
      sregionNameFilterCheckbox: "",
      szoneFilterCheckbox: "",
      sstoreTypeNameFilterCheckbox: "",
      semailFilterCheckbox: "",
      sphoneNumberFilterCheckbox: "",
      sstatusFilterCheckbox: "",
      bulkuploadLoading: false,
      translateLanguage: {},
      newStoreFiled: {},
      selTab: "Demographic",
      timezoneData: [],
      storeManagerMobileNoCompulsion: "",
      storeManagerNameCompulsion: "",
      latitudeCompulsion: "",
      longitudeCompulsion: "",
      timeZoneCompulsion: "",
      isWhatsappCompulsion: "",
      isStorePayEnableCompulsion: "",
      editstoreManagerMobileValidation: "",
      editstoreManagerNameValidation: "",
      editlatitudeValidation: "",
      editlongitudeValidation: "",
      editIsWhatsAppValidation: "",
      editIsStorePayEnableValidation: "",
      activeCollapse: "1",
    };
    this.handleGetStoreMasterData = this.handleGetStoreMasterData.bind(this);
    this.handleGetBrandList = this.handleGetBrandList.bind(this);
    this.handleGetStateList = this.handleGetStateList.bind(this);
    this.handleGetCityList = this.handleGetCityList.bind(this);
    this.handleGetRegionList = this.handleGetRegionList.bind(this);
    this.handleGetStoreTypeList = this.handleGetStoreTypeList.bind(this);
    this.handleUpdateData = this.handleUpdateData.bind(this);
    this.StatusOpenModel = this.StatusOpenModel.bind(this);
    this.StatusCloseModel = this.StatusCloseModel.bind(this);
    this.toggleEditModal = this.toggleEditModal.bind(this);
    this.hanldeAddBulkUpload = this.hanldeAddBulkUpload.bind(this);
    this.SettingsService = new SettingsService();
    this.closeEditModals = this.closeEditModals.bind(this);
  }
  componentDidMount() {
    this.handleGetStoreMasterData();
    this.handleGetBrandList();
    this.handleGetStateList();
    this.handleGetRegionList();
    this.handleGetStoreTypeList();
    if (window.localStorage.getItem("translateLanguage") === "hindi") {
      this.state.translateLanguage = translationHI;
    } else if (window.localStorage.getItem("translateLanguage") === "marathi") {
      this.state.translateLanguage = translationMA;
    } else {
      this.state.translateLanguage = {};
    }
    this.setState({
      timezoneData: timezone.default,
    });
  }
  closeEditModals() {
    this.setState({
      editmodel: false,
      selTab: "Demographic Store Details",
    });
  }
  hanldeAddBulkUpload() {
    const TranslationContext = this.state.translateLanguage.default;
    if (this.state.fileN.length > 0 && this.state.fileN !== []) {
      if (this.state.fileN[0].path.split(".")[1] === "csv") {
        let self = this;
        this.setState({
          bulkuploadLoading: true,
        });
        const formData = new FormData();

        formData.append("file", this.state.fileN[0]);
        this.SettingsService.PostWithData("/Store/BulkUploadStore", formData)
          .then((response) => {
            let status = response.message;
            if (status === "Success") {
              NotificationManager.success(
                TranslationContext !== undefined
                  ? TranslationContext.alertmessage.fileuploadedsuccessfully
                  : "File uploaded successfully."
              );
              self.setState({
                fileName: "",
                fileSize: "",
                fileN: [],
                bulkuploadLoading: false,
              });
              self.handleGetStoreMasterData();
            } else {
              self.setState({
                bulkuploadLoading: false,
              });
              NotificationManager.error(
                TranslationContext !== undefined
                  ? TranslationContext.alertmessage.filenotuploaded
                  : "File not uploaded."
              );
            }
          })
          .catch((error) => {
            console.log(error);
            if (error.message) {
              this.setState({
                showProgress: false,
                isFileUploadFail: true,
                bulkuploadLoading: false,
              });
            }
          });
      } else {
        NotificationManager.error("Only CSV files allowed.");
      }
    } else {
      this.setState({
        bulkuploadCompulsion: "Please select file.",
      });
    }
  }
  sortStatusZtoA() {
    var itemsArray = [];
    itemsArray = this.state.storeData;
    var headerName = "";

    if (this.state.sortColumn === "storeName") {
      itemsArray.sort((a, b) => {
        if (a.storeName < b.storeName) return 1;
        if (a.storeName > b.storeName) return -1;
        return 0;
      });
    }
    if (this.state.sortColumn === "storeCode") {
      itemsArray.sort((a, b) => {
        if (a.storeCode < b.storeCode) return 1;
        if (a.storeCode > b.storeCode) return -1;
        return 0;
      });
    }
    if (this.state.sortColumn === "cityName") {
      itemsArray.sort((a, b) => {
        if (a.cityName < b.cityName) return 1;
        if (a.cityName > b.cityName) return -1;
        return 0;
      });
    }
    if (this.state.sortColumn === "stateName") {
      itemsArray.sort((a, b) => {
        if (a.stateName < b.stateName) return 1;
        if (a.stateName > b.stateName) return -1;
        return 0;
      });
    }
    if (this.state.sortColumn === "strPinCode") {
      itemsArray.sort((a, b) => {
        if (a.strPinCode < b.strPinCode) return 1;
        if (a.strPinCode > b.strPinCode) return -1;
        return 0;
      });
    }
    if (this.state.sortColumn === "regionName") {
      itemsArray.sort((a, b) => {
        if (a.regionName < b.regionName) return 1;
        if (a.regionName > b.regionName) return -1;
        return 0;
      });
    }
    if (this.state.sortColumn === "zone") {
      itemsArray.sort((a, b) => {
        if (a.zone < b.zone) return 1;
        if (a.zone > b.zone) return -1;
        return 0;
      });
    }
    if (this.state.sortColumn === "storeTypeName") {
      itemsArray.sort((a, b) => {
        if (a.storeTypeName < b.storeTypeName) return 1;
        if (a.storeTypeName > b.storeTypeName) return -1;
        return 0;
      });
    }
    if (this.state.sortColumn === "email") {
      itemsArray.sort((a, b) => {
        if (a.email < b.email) return 1;
        if (a.email > b.email) return -1;
        return 0;
      });
    }
    if (this.state.sortColumn === "phoneNumber") {
      itemsArray.sort((a, b) => {
        if (a.phoneNumber < b.phoneNumber) return 1;
        if (a.phoneNumber > b.phoneNumber) return -1;
        return 0;
      });
    }
    if (this.state.sortColumn === "status") {
      itemsArray.sort((a, b) => {
        if (a.status < b.status) return 1;
        if (a.status > b.status) return -1;
        return 0;
      });
    }

    this.setState({
      isortA: true,
      storeData: itemsArray,
    });
    setTimeout(() => {
      this.StatusCloseModel();
    }, 10);
  }

  sortStatusAtoZ() {
    var itemsArray = [];
    itemsArray = this.state.storeData;
    var headerName = "";

    if (this.state.sortColumn === "storeName") {
      itemsArray.sort((a, b) => {
        if (a.storeName < b.storeName) return -1;
        if (a.storeName > b.storeName) return 1;
        return 0;
      });
    }
    if (this.state.sortColumn === "storeCode") {
      itemsArray.sort((a, b) => {
        if (a.storeCode < b.storeCode) return -1;
        if (a.storeCode > b.storeCode) return 1;
        return 0;
      });
    }
    if (this.state.sortColumn === "cityName") {
      itemsArray.sort((a, b) => {
        if (a.cityName < b.cityName) return -1;
        if (a.cityName > b.cityName) return 1;
        return 0;
      });
    }
    if (this.state.sortColumn === "stateName") {
      itemsArray.sort((a, b) => {
        if (a.stateName < b.stateName) return -1;
        if (a.stateName > b.stateName) return 1;
        return 0;
      });
    }
    if (this.state.sortColumn === "strPinCode") {
      itemsArray.sort((a, b) => {
        if (a.strPinCode < b.strPinCode) return -1;
        if (a.strPinCode > b.strPinCode) return 1;
        return 0;
      });
    }
    if (this.state.sortColumn === "regionName") {
      itemsArray.sort((a, b) => {
        if (a.regionName < b.regionName) return -1;
        if (a.regionName > b.regionName) return 1;
        return 0;
      });
    }
    if (this.state.sortColumn === "zone") {
      itemsArray.sort((a, b) => {
        if (a.zone < b.zone) return -1;
        if (a.zone > b.zone) return 1;
        return 0;
      });
    }
    if (this.state.sortColumn === "storeTypeName") {
      itemsArray.sort((a, b) => {
        if (a.storeTypeName < b.storeTypeName) return -1;
        if (a.storeTypeName > b.storeTypeName) return 1;
        return 0;
      });
    }
    if (this.state.sortColumn === "email") {
      itemsArray.sort((a, b) => {
        if (a.email < b.email) return -1;
        if (a.email > b.email) return 1;
        return 0;
      });
    }
    if (this.state.sortColumn === "phoneNumber") {
      itemsArray.sort((a, b) => {
        if (a.phoneNumber < b.phoneNumber) return -1;
        if (a.phoneNumber > b.phoneNumber) return 1;
        return 0;
      });
    }
    if (this.state.sortColumn === "status") {
      itemsArray.sort((a, b) => {
        if (a.status < b.status) return -1;
        if (a.status > b.status) return 1;
        return 0;
      });
    }
    this.setState({
      isortA: true,
      storeData: itemsArray,
    });
    setTimeout(() => {
      this.StatusCloseModel();
    }, 10);
  }
  StatusOpenModel(data, header) {
    if (
      this.state.sortFilterStoreName.length === 0 ||
      this.state.sortFilterStoreCode.length === 0 ||
      this.state.sortFilterCity.length === 0 ||
      this.state.sortFilterState.length === 0 ||
      this.state.sortFilterPincode.length === 0 ||
      this.state.sortFilterregionName === 0 ||
      this.state.sortFilterzone === 0 ||
      this.state.sortFilterstoreTypeName === 0 ||
      this.state.sortFilteremail === 0 ||
      this.state.sortFilterphoneNumber === 0 ||
      this.state.sortFilterstatus === 0
    ) {
      return false;
    }
    if (data === "storeName") {
      if (
        this.state.sstoreCodeFilterCheckbox !== "" ||
        this.state.scityNameFilterCheckbox !== "" ||
        this.state.sstateNameFilterCheckbox !== "" ||
        this.state.sstrPinCodeFilterCheckbox !== "" ||
        this.state.sregionNameFilterCheckbox != "" ||
        this.state.szoneFilterCheckbox != "" ||
        this.state.sstoreTypeNameFilterCheckbox != "" ||
        this.state.semailFilterCheckbox != "" ||
        this.state.sphoneNumberFilterCheckbox != "" ||
        this.state.sstatusFilterCheckbox != ""
      ) {
        this.setState({
          StatusModel: true,
          sortColumn: data,
          sortHeader: header,
        });
      } else {
        this.setState({
          sstoreCodeFilterCheckbox: "",
          scityNameFilterCheckbox: "",
          sstateNameFilterCheckbox: "",
          sstrPinCodeFilterCheckbox: "",
          sregionNameFilterCheckbox: "",
          szoneFilterCheckbox: "",
          sstoreTypeNameFilterCheckbox: "",
          semailFilterCheckbox: "",
          sphoneNumberFilterCheckbox: "",
          sstatusFilterCheckbox: "",

          StatusModel: true,
          sortColumn: data,
          sortHeader: header,
        });
      }
    }
    if (data === "storeCode") {
      if (
        this.state.sstoreNameFilterCheckbox !== "" ||
        this.state.scityNameFilterCheckbox !== "" ||
        this.state.sstateNameFilterCheckbox !== "" ||
        this.state.sstrPinCodeFilterCheckbox !== "" ||
        this.state.sregionNameFilterCheckbox != "" ||
        this.state.szoneFilterCheckbox != "" ||
        this.state.sstoreTypeNameFilterCheckbox != "" ||
        this.state.semailFilterCheckbox != "" ||
        this.state.sphoneNumberFilterCheckbox != "" ||
        this.state.sstatusFilterCheckbox != ""
      ) {
        this.setState({
          StatusModel: true,
          sortColumn: data,
          sortHeader: header,
        });
      } else {
        this.setState({
          sstoreNameFilterCheckbox: "",
          scityNameFilterCheckbox: "",
          sstateNameFilterCheckbox: "",
          sstrPinCodeFilterCheckbox: "",
          sregionNameFilterCheckbox: "",
          szoneFilterCheckbox: "",
          sstoreTypeNameFilterCheckbox: "",
          semailFilterCheckbox: "",
          sphoneNumberFilterCheckbox: "",
          sstatusFilterCheckbox: "",
          StatusModel: true,
          sortColumn: data,
          sortHeader: header,
        });
      }
    }
    if (data === "cityName") {
      if (
        this.state.sstoreNameFilterCheckbox !== "" ||
        this.state.sstoreCodeFilterCheckbox !== "" ||
        this.state.sstateNameFilterCheckbox !== "" ||
        this.state.sstrPinCodeFilterCheckbox !== "" ||
        this.state.sregionNameFilterCheckbox !== "" ||
        this.state.szoneFilterCheckbox !== "" ||
        this.state.sstoreTypeNameFilterCheckbox !== "" ||
        this.state.semailFilterCheckbox !== "" ||
        this.state.sphoneNumberFilterCheckbox !== "" ||
        this.state.sstatusFilterCheckbox !== ""
      ) {
        this.setState({
          StatusModel: true,
          sortColumn: data,
          sortHeader: header,
        });
      } else {
        this.setState({
          sstoreNameFilterCheckbox: "",
          sstoreCodeFilterCheckbox: "",
          sstateNameFilterCheckbox: "",
          sstrPinCodeFilterCheckbox: "",
          sregionNameFilterCheckbox: "",
          szoneFilterCheckbox: "",
          sstoreTypeNameFilterCheckbox: "",
          semailFilterCheckbox: "",
          sphoneNumberFilterCheckbox: "",
          sstatusFilterCheckbox: "",

          StatusModel: true,
          sortColumn: data,
          sortHeader: header,
        });
      }
    }
    if (data === "stateName") {
      if (
        this.state.sstoreNameFilterCheckbox !== "" ||
        this.state.scityNameFilterCheckbox !== "" ||
        this.state.sstoreCodeFilterCheckbox !== "" ||
        this.state.sstrPinCodeFilterCheckbox !== "" ||
        this.state.sregionNameFilterCheckbox !== "" ||
        this.state.szoneFilterCheckbox !== "" ||
        this.state.sstoreTypeNameFilterCheckbox !== "" ||
        this.state.semailFilterCheckbox !== "" ||
        this.state.sphoneNumberFilterCheckbox !== "" ||
        this.state.sstatusFilterCheckbox !== ""
      ) {
        this.setState({
          StatusModel: true,
          sortColumn: data,
          sortHeader: header,
        });
      } else {
        this.setState({
          sstrPinCodeFilterCheckbox: "",
          sstoreCodeFilterCheckbox: "",
          scityNameFilterCheckbox: "",
          sstoreNameFilterCheckbox: "",
          sregionNameFilterCheckbox: "",
          szoneFilterCheckbox: "",
          sstoreTypeNameFilterCheckbox: "",
          semailFilterCheckbox: "",
          sphoneNumberFilterCheckbox: "",
          sstatusFilterCheckbox: "",

          StatusModel: true,
          sortColumn: data,
          sortHeader: header,
        });
      }
    }
    if (data === "strPinCode") {
      if (
        this.state.sstateNameFilterCheckbox !== "" ||
        this.state.scityNameFilterCheckbox !== "" ||
        this.state.sstoreCodeFilterCheckbox !== "" ||
        this.state.sstoreNameFilterCheckbox !== "" ||
        this.state.sregionNameFilterCheckbox !== "" ||
        this.state.szoneFilterCheckbox !== "" ||
        this.state.sstoreTypeNameFilterCheckbox !== "" ||
        this.state.semailFilterCheckbox !== "" ||
        this.state.sphoneNumberFilterCheckbox !== "" ||
        this.state.sstatusFilterCheckbox !== ""
      ) {
        this.setState({
          StatusModel: true,
          sortColumn: data,
          sortHeader: header,
        });
      } else {
        this.setState({
          sstoreNameFilterCheckbox: "",
          sstoreCodeFilterCheckbox: "",
          scityNameFilterCheckbox: "",
          sstateNameFilterCheckbox: "",
          sregionNameFilterCheckbox: "",
          szoneFilterCheckbox: "",
          sstoreTypeNameFilterCheckbox: "",
          semailFilterCheckbox: "",
          sphoneNumberFilterCheckbox: "",
          sstatusFilterCheckbox: "",
          StatusModel: true,
          sortColumn: data,
          sortHeader: header,
        });
      }
    }
    if (data === "regionName") {
      if (
        this.state.sstateNameFilterCheckbox !== "" ||
        this.state.scityNameFilterCheckbox !== "" ||
        this.state.sstoreCodeFilterCheckbox !== "" ||
        this.state.sstoreNameFilterCheckbox !== "" ||
        this.state.sstrPinCodeFilterCheckbox !== "" ||
        this.state.szoneFilterCheckbox !== "" ||
        this.state.sstoreTypeNameFilterCheckbox !== "" ||
        this.state.semailFilterCheckbox !== "" ||
        this.state.sphoneNumberFilterCheckbox !== "" ||
        this.state.sstatusFilterCheckbox !== ""
      ) {
        this.setState({
          StatusModel: true,
          sortColumn: data,
          sortHeader: header,
        });
      } else {
        this.setState({
          sstoreNameFilterCheckbox: "",
          sstoreCodeFilterCheckbox: "",
          scityNameFilterCheckbox: "",
          sstateNameFilterCheckbox: "",
          sstrPinCodeFilterCheckbox: "",
          szoneFilterCheckbox: "",
          sstoreTypeNameFilterCheckbox: "",
          semailFilterCheckbox: "",
          sphoneNumberFilterCheckbox: "",
          sstatusFilterCheckbox: "",
          StatusModel: true,
          sortColumn: data,
          sortHeader: header,
        });
      }
    }
    if (data === "zone") {
      if (
        this.state.sstateNameFilterCheckbox !== "" ||
        this.state.scityNameFilterCheckbox !== "" ||
        this.state.sstoreCodeFilterCheckbox !== "" ||
        this.state.sstoreNameFilterCheckbox !== "" ||
        this.state.sstrPinCodeFilterCheckbox !== "" ||
        this.state.sregionNameFilterCheckbox !== "" ||
        this.state.sstoreTypeNameFilterCheckbox !== "" ||
        this.state.semailFilterCheckbox !== "" ||
        this.state.sphoneNumberFilterCheckbox !== "" ||
        this.state.sstatusFilterCheckbox !== ""
      ) {
        this.setState({
          StatusModel: true,
          sortColumn: data,
          sortHeader: header,
        });
      } else {
        this.setState({
          sstoreNameFilterCheckbox: "",
          sstoreCodeFilterCheckbox: "",
          scityNameFilterCheckbox: "",
          sstateNameFilterCheckbox: "",
          sstrPinCodeFilterCheckbox: "",
          sregionNameFilterCheckbox: "",
          sstoreTypeNameFilterCheckbox: "",
          semailFilterCheckbox: "",
          sphoneNumberFilterCheckbox: "",
          sstatusFilterCheckbox: "",
          StatusModel: true,
          sortColumn: data,
          sortHeader: header,
        });
      }
    }
    if (data === "storeTypeName") {
      if (
        this.state.sstateNameFilterCheckbox !== "" ||
        this.state.scityNameFilterCheckbox !== "" ||
        this.state.sstoreCodeFilterCheckbox !== "" ||
        this.state.sstoreNameFilterCheckbox !== "" ||
        this.state.sstrPinCodeFilterCheckbox !== "" ||
        this.state.szoneFilterCheckbox !== "" ||
        this.state.sregionNameFilterCheckbox !== "" ||
        this.state.semailFilterCheckbox !== "" ||
        this.state.sphoneNumberFilterCheckbox !== "" ||
        this.state.sstatusFilterCheckbox !== ""
      ) {
        this.setState({
          StatusModel: true,
          sortColumn: data,
          sortHeader: header,
        });
      } else {
        this.setState({
          sstoreNameFilterCheckbox: "",
          sstoreCodeFilterCheckbox: "",
          scityNameFilterCheckbox: "",
          sstateNameFilterCheckbox: "",
          sstrPinCodeFilterCheckbox: "",
          szoneFilterCheckbox: "",
          sregionNameFilterCheckbox: "",
          semailFilterCheckbox: "",
          sphoneNumberFilterCheckbox: "",
          sstatusFilterCheckbox: "",
          StatusModel: true,
          sortColumn: data,
          sortHeader: header,
        });
      }
    }
    if (data === "email") {
      if (
        this.state.sstateNameFilterCheckbox !== "" ||
        this.state.scityNameFilterCheckbox !== "" ||
        this.state.sstoreCodeFilterCheckbox !== "" ||
        this.state.sstoreNameFilterCheckbox !== "" ||
        this.state.sstrPinCodeFilterCheckbox !== "" ||
        this.state.szoneFilterCheckbox !== "" ||
        this.state.sregionNameFilterCheckbox !== "" ||
        this.state.sstoreTypeNameFilterCheckbox !== "" ||
        this.state.sphoneNumberFilterCheckbox !== "" ||
        this.state.sstatusFilterCheckbox !== ""
      ) {
        this.setState({
          StatusModel: true,
          sortColumn: data,
          sortHeader: header,
        });
      } else {
        this.setState({
          sstoreNameFilterCheckbox: "",
          sstoreCodeFilterCheckbox: "",
          scityNameFilterCheckbox: "",
          sstateNameFilterCheckbox: "",
          sstrPinCodeFilterCheckbox: "",
          szoneFilterCheckbox: "",
          sregionNameFilterCheckbox: "",
          sstoreTypeNameFilterCheckbox: "",
          sphoneNumberFilterCheckbox: "",
          sstatusFilterCheckbox: "",
          StatusModel: true,
          sortColumn: data,
          sortHeader: header,
        });
      }
    }
    if (data === "phoneNumber") {
      if (
        this.state.sstateNameFilterCheckbox !== "" ||
        this.state.scityNameFilterCheckbox !== "" ||
        this.state.sstoreCodeFilterCheckbox !== "" ||
        this.state.sstoreNameFilterCheckbox !== "" ||
        this.state.sstrPinCodeFilterCheckbox !== "" ||
        this.state.szoneFilterCheckbox !== "" ||
        this.state.sregionNameFilterCheckbox !== "" ||
        this.state.sstoreTypeNameFilterCheckbox !== "" ||
        this.state.semailFilterCheckbox !== "" ||
        this.state.sstatusFilterCheckbox !== ""
      ) {
        this.setState({
          StatusModel: true,
          sortColumn: data,
          sortHeader: header,
        });
      } else {
        this.setState({
          sstoreNameFilterCheckbox: "",
          sstoreCodeFilterCheckbox: "",
          scityNameFilterCheckbox: "",
          sstateNameFilterCheckbox: "",
          sstrPinCodeFilterCheckbox: "",
          szoneFilterCheckbox: "",
          sregionNameFilterCheckbox: "",
          sstoreTypeNameFilterCheckbox: "",
          semailFilterCheckbox: "",
          sstatusFilterCheckbox: "",
          StatusModel: true,
          sortColumn: data,
          sortHeader: header,
        });
      }
    }
    if (data === "status") {
      if (
        this.state.sstateNameFilterCheckbox !== "" ||
        this.state.scityNameFilterCheckbox !== "" ||
        this.state.sstoreCodeFilterCheckbox !== "" ||
        this.state.sstoreNameFilterCheckbox !== "" ||
        this.state.sstrPinCodeFilterCheckbox !== "" ||
        this.state.szoneFilterCheckbox !== "" ||
        this.state.sregionNameFilterCheckbox !== "" ||
        this.state.sstoreTypeNameFilterCheckbox !== "" ||
        this.state.semailFilterCheckbox !== "" ||
        this.state.sphoneNumberFilterCheckbox !== ""
      ) {
        this.setState({
          StatusModel: true,
          sortColumn: data,
          sortHeader: header,
        });
      } else {
        this.setState({
          sstoreNameFilterCheckbox: "",
          sstoreCodeFilterCheckbox: "",
          scityNameFilterCheckbox: "",
          sstateNameFilterCheckbox: "",
          sstrPinCodeFilterCheckbox: "",
          szoneFilterCheckbox: "",
          sregionNameFilterCheckbox: "",
          sstoreTypeNameFilterCheckbox: "",
          semailFilterCheckbox: "",
          sphoneNumberFilterCheckbox: "",
          StatusModel: true,
          sortColumn: data,
          sortHeader: header,
        });
      }
    }
  }
  StatusCloseModel() {
    if (this.state.tempstoreData.length > 0) {
      this.setState({
        StatusModel: false,
        storeData: this.state.tempstoreData,
        filterTxtValue: "",
        sortFilterStoreName: this.state.sortStoreName,
        sortFilterStoreCode: this.state.sortStoreCode,
        sortFilterCity: this.state.sortCity,
        sortFilterState: this.state.sortState,
        sortFilterPincode: this.state.sortPincode,
        sortFilterregionName: this.state.sortregionName,
        sortFilterzone: this.state.sortzone,
        sortFilterstoreTypeName: this.state.sortstoreTypeName,
        sortFilteremail: this.state.sortemail,
        sortFilterphoneNumber: this.state.sortphoneNumber,
        sortFilterstatus: this.state.sortstatus,
      });
      if (this.state.sortColumn === "storeName") {
        if (this.state.sstoreNameFilterCheckbox === "") {
        } else {
          this.setState({
            sstoreCodeFilterCheckbox: "",
            scityNameFilterCheckbox: "",
            sstateNameFilterCheckbox: "",
            sstrPinCodeFilterCheckbox: "",
            sregionNameFilterCheckbox: "",
            szoneFilterCheckbox: "",
            sstoreTypeNameFilterCheckbox: "",
            semailFilterCheckbox: "",
            sphoneNumberFilterCheckbox: "",
            sstatusFilterCheckbox: "",
          });
        }
      }
      if (this.state.sortColumn === "storeCode") {
        if (this.state.sstoreCodeFilterCheckbox === "") {
        } else {
          this.setState({
            sstoreNameFilterCheckbox: "",
            scityNameFilterCheckbox: "",
            sstateNameFilterCheckbox: "",
            sstrPinCodeFilterCheckbox: "",
            sregionNameFilterCheckbox: "",
            szoneFilterCheckbox: "",
            sstoreTypeNameFilterCheckbox: "",
            semailFilterCheckbox: "",
            sphoneNumberFilterCheckbox: "",
            sstatusFilterCheckbox: "",
          });
        }
      }
      if (this.state.sortColumn === "cityName") {
        if (this.state.scityNameFilterCheckbox === "") {
        } else {
          this.setState({
            sstoreNameFilterCheckbox: "",
            sstoreCodeFilterCheckbox: "",
            sstateNameFilterCheckbox: "",
            sstrPinCodeFilterCheckbox: "",
            sregionNameFilterCheckbox: "",
            szoneFilterCheckbox: "",
            sstoreTypeNameFilterCheckbox: "",
            semailFilterCheckbox: "",
            sphoneNumberFilterCheckbox: "",
            sstatusFilterCheckbox: "",
          });
        }
      }
      if (this.state.sortColumn === "stateName") {
        if (this.state.sstateNameFilterCheckbox === "") {
        } else {
          this.setState({
            sstoreNameFilterCheckbox: "",
            sstoreCodeFilterCheckbox: "",
            scityNameFilterCheckbox: "",
            sstrPinCodeFilterCheckbox: "",
            sregionNameFilterCheckbox: "",
            szoneFilterCheckbox: "",
            sstoreTypeNameFilterCheckbox: "",
            semailFilterCheckbox: "",
            sphoneNumberFilterCheckbox: "",
            sstatusFilterCheckbox: "",
          });
        }
      }
      if (this.state.sortColumn === "strPinCode") {
        if (this.state.sstrPinCodeFilterCheckbox === "") {
        } else {
          this.setState({
            sstoreNameFilterCheckbox: "",
            sstoreCodeFilterCheckbox: "",
            scityNameFilterCheckbox: "",
            sstateNameFilterCheckbox: "",
            sregionNameFilterCheckbox: "",
            szoneFilterCheckbox: "",
            sstoreTypeNameFilterCheckbox: "",
            semailFilterCheckbox: "",
            sphoneNumberFilterCheckbox: "",
            sstatusFilterCheckbox: "",
          });
        }
      }
      if (this.state.sortColumn === "regionName") {
        if (this.state.sregionNameFilterCheckbox === "") {
        } else {
          this.setState({
            sstoreNameFilterCheckbox: "",
            sstoreCodeFilterCheckbox: "",
            scityNameFilterCheckbox: "",
            sstateNameFilterCheckbox: "",
            sstrPinCodeFilterCheckbox: "",
            szoneFilterCheckbox: "",
            sstoreTypeNameFilterCheckbox: "",
            semailFilterCheckbox: "",
            sphoneNumberFilterCheckbox: "",
            sstatusFilterCheckbox: "",
          });
        }
      }
      if (this.state.sortColumn === "zone") {
        if (this.state.szoneFilterCheckbox === "") {
        } else {
          this.setState({
            sstoreNameFilterCheckbox: "",
            sstoreCodeFilterCheckbox: "",
            scityNameFilterCheckbox: "",
            sstateNameFilterCheckbox: "",
            sstrPinCodeFilterCheckbox: "",
            sregionNameFilterCheckbox: "",
            sstoreTypeNameFilterCheckbox: "",
            semailFilterCheckbox: "",
            sphoneNumberFilterCheckbox: "",
            sstatusFilterCheckbox: "",
          });
        }
      }
      if (this.state.sortColumn === "storeTypeName") {
        if (this.state.sstoreTypeNameFilterCheckbox === "") {
        } else {
          this.setState({
            sstoreNameFilterCheckbox: "",
            sstoreCodeFilterCheckbox: "",
            scityNameFilterCheckbox: "",
            sstateNameFilterCheckbox: "",
            sstrPinCodeFilterCheckbox: "",
            sregionNameFilterCheckbox: "",
            szoneFilterCheckbox: "",
            semailFilterCheckbox: "",
            sphoneNumberFilterCheckbox: "",
            sstatusFilterCheckbox: "",
          });
        }
      }
      if (this.state.sortColumn === "email") {
        if (this.state.semailFilterCheckbox === "") {
        } else {
          this.setState({
            sstoreNameFilterCheckbox: "",
            sstoreCodeFilterCheckbox: "",
            scityNameFilterCheckbox: "",
            sstateNameFilterCheckbox: "",
            sstrPinCodeFilterCheckbox: "",
            sregionNameFilterCheckbox: "",
            szoneFilterCheckbox: "",
            sstoreTypeNameFilterCheckbox: "",
            sphoneNumberFilterCheckbox: "",
            sstatusFilterCheckbox: "",
          });
        }
      }
      if (this.state.sortColumn === "phoneNumber") {
        if (this.state.sphoneNumberFilterCheckbox === "") {
        } else {
          this.setState({
            sstoreNameFilterCheckbox: "",
            sstoreCodeFilterCheckbox: "",
            scityNameFilterCheckbox: "",
            sstateNameFilterCheckbox: "",
            sstrPinCodeFilterCheckbox: "",
            sregionNameFilterCheckbox: "",
            szoneFilterCheckbox: "",
            sstoreTypeNameFilterCheckbox: "",
            semailFilterCheckbox: "",
            sstatusFilterCheckbox: "",
          });
        }
      }
      if (this.state.sortColumn === "status") {
        if (this.state.sstatusFilterCheckbox === "") {
        } else {
          this.setState({
            sstoreNameFilterCheckbox: "",
            sstoreCodeFilterCheckbox: "",
            scityNameFilterCheckbox: "",
            sstateNameFilterCheckbox: "",
            sstrPinCodeFilterCheckbox: "",
            sregionNameFilterCheckbox: "",
            szoneFilterCheckbox: "",
            sstoreTypeNameFilterCheckbox: "",
            semailFilterCheckbox: "",
            sphoneNumberFilterCheckbox: "",
          });
        }
      }
    } else {
      if (this.state.isortA) {
        this.setState({
          StatusModel: false,
          storeData: this.state.storeData,
          filterTxtValue: "",
          sortFilterStoreName: this.state.sortStoreName,
          sortFilterStoreCode: this.state.sortStoreCode,
          sortFilterCity: this.state.sortCity,
          sortFilterState: this.state.sortState,
          sortFilterPincode: this.state.sortPincode,
          sortFilterregionName: this.state.sortregionName,
          sortFilterzone: this.state.sortzone,
          sortFilterstoreTypeName: this.state.sortstoreTypeName,
          sortFilteremail: this.state.sortemail,
          sortFilterphoneNumber: this.state.sortphoneNumber,
          sortFilterstatus: this.state.sortstatus,
        });
      } else {
        this.setState({
          StatusModel: false,
          storeData: this.state.sortAllData,
          filterTxtValue: "",
          sortFilterStoreName: this.state.sortStoreName,
          sortFilterStoreCode: this.state.sortStoreCode,
          sortFilterCity: this.state.sortCity,
          sortFilterState: this.state.sortState,
          sortFilterPincode: this.state.sortPincode,
          sortFilterregionName: this.state.sortregionName,
          sortFilterzone: this.state.sortzone,
          sortFilterstoreTypeName: this.state.sortstoreTypeName,
          sortFilteremail: this.state.sortemail,
          sortFilterphoneNumber: this.state.sortphoneNumber,
          sortFilterstatus: this.state.sortstatus,
        });
      }
    }
  }

  setSortCheckStatus = (column, type, e) => {
    var itemsArray = [];

    var sstoreNameFilterCheckbox = this.state.sstoreNameFilterCheckbox;
    var sstoreCodeFilterCheckbox = this.state.sstoreCodeFilterCheckbox;
    var scityNameFilterCheckbox = this.state.scityNameFilterCheckbox;
    var sstateNameFilterCheckbox = this.state.sstateNameFilterCheckbox;
    var sstrPinCodeFilterCheckbox = this.state.sstrPinCodeFilterCheckbox;
    var sregionNameFilterCheckbox = this.state.sregionNameFilterCheckbox;
    var szoneFilterCheckbox = this.state.szoneFilterCheckbox;
    var sstoreTypeNameFilterCheckbox = this.state.sstoreTypeNameFilterCheckbox;
    var semailFilterCheckbox = this.state.semailFilterCheckbox;
    var sphoneNumberFilterCheckbox = this.state.sphoneNumberFilterCheckbox;
    var sstatusFilterCheckbox = this.state.sstatusFilterCheckbox;

    if (column === "storeName" || column === "all") {
      if (type === "value" && type !== "All") {
        sstoreNameFilterCheckbox = sstoreNameFilterCheckbox.replace("all", "");
        sstoreNameFilterCheckbox = sstoreNameFilterCheckbox.replace("all,", "");
        if (sstoreNameFilterCheckbox.includes(e.currentTarget.value)) {
          sstoreNameFilterCheckbox = sstoreNameFilterCheckbox.replace(
            e.currentTarget.value + ",",
            ""
          );
        } else {
          sstoreNameFilterCheckbox += e.currentTarget.value + ",";
        }
      } else {
        if (sstoreNameFilterCheckbox.includes("all")) {
          sstoreNameFilterCheckbox = "";
        } else {
          if (this.state.sortColumn === "storeName") {
            for (let i = 0; i < this.state.sortStoreName.length; i++) {
              sstoreNameFilterCheckbox +=
                this.state.sortStoreName[i].storeName + ",";
            }
            sstoreNameFilterCheckbox += "all";
          }
        }
      }
    }
    if (column === "storeCode" || column === "all") {
      if (type === "value" && type !== "All") {
        sstoreCodeFilterCheckbox = sstoreCodeFilterCheckbox.replace("all", "");
        sstoreCodeFilterCheckbox = sstoreCodeFilterCheckbox.replace("all,", "");
        if (sstoreCodeFilterCheckbox.includes(e.currentTarget.value)) {
          sstoreCodeFilterCheckbox = sstoreCodeFilterCheckbox.replace(
            e.currentTarget.value + ",",
            ""
          );
        } else {
          sstoreCodeFilterCheckbox += e.currentTarget.value + ",";
        }
      } else {
        if (sstoreCodeFilterCheckbox.includes("all")) {
          sstoreCodeFilterCheckbox = "";
        } else {
          if (this.state.sortColumn === "storeCode") {
            for (let i = 0; i < this.state.sortStoreCode.length; i++) {
              sstoreCodeFilterCheckbox +=
                this.state.sortStoreCode[i].storeCode + ",";
            }
            sstoreCodeFilterCheckbox += "all";
          }
        }
      }
    }
    if (column === "cityName" || column === "all") {
      if (type === "value" && type !== "All") {
        scityNameFilterCheckbox = scityNameFilterCheckbox.replace("all", "");
        scityNameFilterCheckbox = scityNameFilterCheckbox.replace("all,", "");
        if (scityNameFilterCheckbox.includes(e.currentTarget.value)) {
          scityNameFilterCheckbox = scityNameFilterCheckbox.replace(
            e.currentTarget.value + ",",
            ""
          );
        } else {
          scityNameFilterCheckbox += e.currentTarget.value + ",";
        }
      } else {
        if (scityNameFilterCheckbox.includes("all")) {
          scityNameFilterCheckbox = "";
        } else {
          if (this.state.sortColumn === "cityName") {
            for (let i = 0; i < this.state.sortCity.length; i++) {
              scityNameFilterCheckbox += this.state.sortCity[i].cityName + ",";
            }
            scityNameFilterCheckbox += "all";
          }
        }
      }
    }
    if (column === "stateName" || column === "all") {
      if (type === "value" && type !== "All") {
        sstateNameFilterCheckbox = sstateNameFilterCheckbox.replace("all", "");
        sstateNameFilterCheckbox = sstateNameFilterCheckbox.replace("all,", "");
        if (sstateNameFilterCheckbox.includes(e.currentTarget.value)) {
          sstateNameFilterCheckbox = sstateNameFilterCheckbox.replace(
            e.currentTarget.value + ",",
            ""
          );
        } else {
          sstateNameFilterCheckbox += e.currentTarget.value + ",";
        }
      } else {
        if (sstateNameFilterCheckbox.includes("all")) {
          sstateNameFilterCheckbox = "";
        } else {
          if (this.state.sortColumn === "stateName") {
            for (let i = 0; i < this.state.sortState.length; i++) {
              sstateNameFilterCheckbox +=
                this.state.sortState[i].stateName + ",";
            }
            sstateNameFilterCheckbox += "all";
          }
        }
      }
    }
    if (column === "strPinCode" || column === "all") {
      if (type === "value" && type !== "All") {
        sstrPinCodeFilterCheckbox = sstrPinCodeFilterCheckbox.replace(
          "all",
          ""
        );
        sstrPinCodeFilterCheckbox = sstrPinCodeFilterCheckbox.replace(
          "all,",
          ""
        );
        if (sstrPinCodeFilterCheckbox.includes(e.currentTarget.value)) {
          sstrPinCodeFilterCheckbox = sstrPinCodeFilterCheckbox.replace(
            e.currentTarget.value + ",",
            ""
          );
        } else {
          sstrPinCodeFilterCheckbox += e.currentTarget.value + ",";
        }
      } else {
        if (sstrPinCodeFilterCheckbox.includes("all")) {
          sstrPinCodeFilterCheckbox = "";
        } else {
          if (this.state.sortColumn === "strPinCode") {
            for (let i = 0; i < this.state.sortPincode.length; i++) {
              sstrPinCodeFilterCheckbox +=
                this.state.sortPincode[i].strPinCode + ",";
            }
            sstrPinCodeFilterCheckbox += "all";
          }
        }
      }
    }
    if (column === "regionName" || column === "all") {
      if (type === "value" && type !== "All") {
        sregionNameFilterCheckbox = sregionNameFilterCheckbox.replace(
          "all",
          ""
        );
        sregionNameFilterCheckbox = sregionNameFilterCheckbox.replace(
          "all,",
          ""
        );
        if (sregionNameFilterCheckbox.includes(e.currentTarget.value)) {
          sregionNameFilterCheckbox = sregionNameFilterCheckbox.replace(
            e.currentTarget.value + ",",
            ""
          );
        } else {
          sregionNameFilterCheckbox += e.currentTarget.value + ",";
        }
      } else {
        if (sregionNameFilterCheckbox.includes("all")) {
          sregionNameFilterCheckbox = "";
        } else {
          if (this.state.sortColumn === "regionName") {
            for (let i = 0; i < this.state.sortregionName.length; i++) {
              sregionNameFilterCheckbox +=
                this.state.sortregionName[i].regionName + ",";
            }
            sregionNameFilterCheckbox += "all";
          }
        }
      }
    }
    if (column === "zone" || column === "all") {
      if (type === "value" && type !== "All") {
        szoneFilterCheckbox = szoneFilterCheckbox.replace("all", "");
        szoneFilterCheckbox = szoneFilterCheckbox.replace("all,", "");
        if (szoneFilterCheckbox.includes(e.currentTarget.value)) {
          szoneFilterCheckbox = szoneFilterCheckbox.replace(
            e.currentTarget.value + ",",
            ""
          );
        } else {
          szoneFilterCheckbox += e.currentTarget.value + ",";
        }
      } else {
        if (szoneFilterCheckbox.includes("all")) {
          szoneFilterCheckbox = "";
        } else {
          if (this.state.sortColumn === "zone") {
            for (let i = 0; i < this.state.sortzone.length; i++) {
              szoneFilterCheckbox += this.state.sortzone[i].zone + ",";
            }
            szoneFilterCheckbox += "all";
          }
        }
      }
    }
    if (column === "storeTypeName" || column === "all") {
      if (type === "value" && type !== "All") {
        sstoreTypeNameFilterCheckbox = sstoreTypeNameFilterCheckbox.replace(
          "all",
          ""
        );
        sstoreTypeNameFilterCheckbox = sstoreTypeNameFilterCheckbox.replace(
          "all,",
          ""
        );
        if (sstoreTypeNameFilterCheckbox.includes(e.currentTarget.value)) {
          sstoreTypeNameFilterCheckbox = sstoreTypeNameFilterCheckbox.replace(
            e.currentTarget.value + ",",
            ""
          );
        } else {
          sstoreTypeNameFilterCheckbox += e.currentTarget.value + ",";
        }
      } else {
        if (sstoreTypeNameFilterCheckbox.includes("all")) {
          sstoreTypeNameFilterCheckbox = "";
        } else {
          if (this.state.sortColumn === "storeTypeName") {
            for (let i = 0; i < this.state.sortzone.length; i++) {
              sstoreTypeNameFilterCheckbox +=
                this.state.sortzone[i].storeTypeName + ",";
            }
            sstoreTypeNameFilterCheckbox += "all";
          }
        }
      }
    }
    if (column === "email" || column === "all") {
      if (type === "value" && type !== "All") {
        semailFilterCheckbox = semailFilterCheckbox.replace("all", "");
        semailFilterCheckbox = semailFilterCheckbox.replace("all,", "");
        if (semailFilterCheckbox.includes(e.currentTarget.value)) {
          semailFilterCheckbox = semailFilterCheckbox.replace(
            e.currentTarget.value + ",",
            ""
          );
        } else {
          semailFilterCheckbox += e.currentTarget.value + ",";
        }
      } else {
        if (semailFilterCheckbox.includes("all")) {
          semailFilterCheckbox = "";
        } else {
          if (this.state.sortColumn === "email") {
            for (let i = 0; i < this.state.sortemail.length; i++) {
              semailFilterCheckbox += this.state.sortemail[i].email + ",";
            }
            semailFilterCheckbox += "all";
          }
        }
      }
    }
    if (column === "phoneNumber" || column === "all") {
      if (type === "value" && type !== "All") {
        sphoneNumberFilterCheckbox = sphoneNumberFilterCheckbox.replace(
          "all",
          ""
        );
        sphoneNumberFilterCheckbox = sphoneNumberFilterCheckbox.replace(
          "all,",
          ""
        );
        if (sphoneNumberFilterCheckbox.includes(e.currentTarget.value)) {
          sphoneNumberFilterCheckbox = sphoneNumberFilterCheckbox.replace(
            e.currentTarget.value + ",",
            ""
          );
        } else {
          sphoneNumberFilterCheckbox += e.currentTarget.value + ",";
        }
      } else {
        if (sphoneNumberFilterCheckbox.includes("all")) {
          sphoneNumberFilterCheckbox = "";
        } else {
          if (this.state.sortColumn === "phoneNumber") {
            for (let i = 0; i < this.state.sortemail.length; i++) {
              sphoneNumberFilterCheckbox +=
                this.state.sortemail[i].phoneNumber + ",";
            }
            sphoneNumberFilterCheckbox += "all";
          }
        }
      }
    }
    if (column === "status" || column === "all") {
      if (type === "value" && type !== "All") {
        sstatusFilterCheckbox = sstatusFilterCheckbox.replace("all", "");
        sstatusFilterCheckbox = sstatusFilterCheckbox.replace("all,", "");
        if (sstatusFilterCheckbox.includes(e.currentTarget.value)) {
          sstatusFilterCheckbox = sstatusFilterCheckbox.replace(
            e.currentTarget.value + ",",
            ""
          );
        } else {
          sstatusFilterCheckbox += e.currentTarget.value + ",";
        }
      } else {
        if (sstatusFilterCheckbox.includes("all")) {
          sstatusFilterCheckbox = "";
        } else {
          if (this.state.sortColumn === "status") {
            for (let i = 0; i < this.state.sortemail.length; i++) {
              sstatusFilterCheckbox += this.state.sortemail[i].status + ",";
            }
            sstatusFilterCheckbox += "all";
          }
        }
      }
    }

    var allData = this.state.sortAllData;

    this.setState({
      storeNameColor: "",
      storeCodecolor: "",
      cityColor: "",
      stateColor: "",
      pincodeColor: "",
      brandnameColor: "",
      sstoreNameFilterCheckbox,
      sstoreCodeFilterCheckbox,
      scityNameFilterCheckbox,
      sstateNameFilterCheckbox,
      sstrPinCodeFilterCheckbox,
      sregionNameFilterCheckbox,
      szoneFilterCheckbox,
      sstoreTypeNameFilterCheckbox,
      semailFilterCheckbox,
      sphoneNumberFilterCheckbox,
      sstatusFilterCheckbox,
    });
    if (column === "all") {
      itemsArray = this.state.sortAllData;
    } else if (column === "storeName") {
      var sItems = sstoreNameFilterCheckbox.split(",");
      if (sItems.length > 0) {
        for (let i = 0; i < sItems.length; i++) {
          if (sItems[i] !== "") {
            var tempFilterData = allData.filter(
              (a) => a.storeName === sItems[i]
            );
            if (tempFilterData.length > 0) {
              for (let j = 0; j < tempFilterData.length; j++) {
                itemsArray.push(tempFilterData[j]);
              }
            }
          }
        }
      }
    } else if (column === "storeCode") {
      var sItems = sstoreCodeFilterCheckbox.split(",");

      if (sItems.length > 0) {
        for (let i = 0; i < sItems.length; i++) {
          if (sItems[i] !== "") {
            var tempFilterData = allData.filter(
              (a) => a.storeCode === sItems[i]
            );
            if (tempFilterData.length > 0) {
              for (let j = 0; j < tempFilterData.length; j++) {
                itemsArray.push(tempFilterData[j]);
              }
            }
          }
        }
      }
    } else if (column === "cityName") {
      var sItems = scityNameFilterCheckbox.split(",");
      if (sItems.length > 0) {
        for (let i = 0; i < sItems.length; i++) {
          if (sItems[i] !== "") {
            var tempFilterData = allData.filter(
              (a) => a.cityName === sItems[i]
            );
            if (tempFilterData.length > 0) {
              for (let j = 0; j < tempFilterData.length; j++) {
                itemsArray.push(tempFilterData[j]);
              }
            }
          }
        }
      }
    } else if (column === "stateName") {
      var sItems = sstateNameFilterCheckbox.split(",");
      if (sItems.length > 0) {
        for (let i = 0; i < sItems.length; i++) {
          if (sItems[i] !== "") {
            var tempFilterData = allData.filter(
              (a) => a.stateName === sItems[i]
            );
            if (tempFilterData.length > 0) {
              for (let j = 0; j < tempFilterData.length; j++) {
                itemsArray.push(tempFilterData[j]);
              }
            }
          }
        }
      }
    } else if (column === "strPinCode") {
      var sItems = sstrPinCodeFilterCheckbox.split(",");
      if (sItems.length > 0) {
        for (let i = 0; i < sItems.length; i++) {
          if (sItems[i] !== "") {
            var tempFilterData = allData.filter(
              (a) => a.strPinCode === sItems[i]
            );
            if (tempFilterData.length > 0) {
              for (let j = 0; j < tempFilterData.length; j++) {
                itemsArray.push(tempFilterData[j]);
              }
            }
          }
        }
      }
    } else if (column === "regionName") {
      var sItems = sregionNameFilterCheckbox.split(",");
      if (sItems.length > 0) {
        for (let i = 0; i < sItems.length; i++) {
          if (sItems[i] !== "") {
            var tempFilterData = allData.filter(
              (a) => a.regionName === sItems[i]
            );
            if (tempFilterData.length > 0) {
              for (let j = 0; j < tempFilterData.length; j++) {
                itemsArray.push(tempFilterData[j]);
              }
            }
          }
        }
      }
    } else if (column === "zone") {
      var sItems = szoneFilterCheckbox.split(",");
      if (sItems.length > 0) {
        for (let i = 0; i < sItems.length; i++) {
          if (sItems[i] !== "") {
            var tempFilterData = allData.filter((a) => a.zone === sItems[i]);
            if (tempFilterData.length > 0) {
              for (let j = 0; j < tempFilterData.length; j++) {
                itemsArray.push(tempFilterData[j]);
              }
            }
          }
        }
      }
    } else if (column === "storeTypeName") {
      var sItems = sstoreTypeNameFilterCheckbox.split(",");
      if (sItems.length > 0) {
        for (let i = 0; i < sItems.length; i++) {
          if (sItems[i] !== "") {
            var tempFilterData = allData.filter(
              (a) => a.storeTypeName === sItems[i]
            );
            if (tempFilterData.length > 0) {
              for (let j = 0; j < tempFilterData.length; j++) {
                itemsArray.push(tempFilterData[j]);
              }
            }
          }
        }
      }
    } else if (column === "email") {
      var sItems = semailFilterCheckbox.split(",");
      if (sItems.length > 0) {
        for (let i = 0; i < sItems.length; i++) {
          if (sItems[i] !== "") {
            var tempFilterData = allData.filter((a) => a.email === sItems[i]);
            if (tempFilterData.length > 0) {
              for (let j = 0; j < tempFilterData.length; j++) {
                itemsArray.push(tempFilterData[j]);
              }
            }
          }
        }
      }
    } else if (column === "phoneNumber") {
      var sItems = sphoneNumberFilterCheckbox.split(",");
      if (sItems.length > 0) {
        for (let i = 0; i < sItems.length; i++) {
          if (sItems[i] !== "") {
            var tempFilterData = allData.filter(
              (a) => a.phoneNumber === sItems[i]
            );
            if (tempFilterData.length > 0) {
              for (let j = 0; j < tempFilterData.length; j++) {
                itemsArray.push(tempFilterData[j]);
              }
            }
          }
        }
      }
    } else if (column === "status") {
      var sItems = sstatusFilterCheckbox.split(",");
      if (sItems.length > 0) {
        for (let i = 0; i < sItems.length; i++) {
          if (sItems[i] !== "") {
            var tempFilterData = allData.filter((a) => a.status === sItems[i]);
            if (tempFilterData.length > 0) {
              for (let j = 0; j < tempFilterData.length; j++) {
                itemsArray.push(tempFilterData[j]);
              }
            }
          }
        }
      }
    }

    this.setState({
      tempstoreData: itemsArray,
    });
    // this.StatusCloseModel();
  };

  HandlecheckDemographicStoreDetails() {
    if (
      this.state.userEditData.store_Name.length > 0 &&
      this.state.userEditData.phoneNumber_.length > 0 &&
      this.state.userEditData.store_Code.length > 0 &&
      this.state.userEditData.email_.length > 0 && 
      this.state.userEditData.storeManagerMobile.length > 0 &&
      this.state.userEditData.storeManagerName.length > 0 ) {
      this.setState({
        selTab: "Geographical",
      });
    } else {
      NotificationManager.error("Please fill all required fields");
      this.setState({
        selTab:"Demographic",
        editBrandValidation: "Please Select Brand.",
        editStoreCodeValidation: "Please Enter Store Code.",
        editStoreNameValidation: "Please Enter Store Name",
        editContactEmailValidation: "Please Enter EmailID.",
        editContactPhoneValidation: "Please Enter Phone Number.",
        editstoreManagerMobileValidation:
          "Please Enter Manager Contact.",
        editstoreManagerNameValidation: "Please Enter Manager Name.",
      });
    }
  }

  HandlecheckGeographicalStoreDetails() {
    if (
      this.state.userEditData.city_ID !== "0" &&
      this.state.userEditData.state_ID !== "0" &&
      this.state.userEditData.timeZoneID !== "0" &&
      this.state.userEditData.strPin_Code.length > 0 &&
      this.state.userEditData.latitude.length > 0 &&
      this.state.userEditData.longitude.length > 0 &&
      this.state.userEditData.region_ID  !="0"
    ) {
      this.setState({
        selTab: "Store Functionality",
      });
    } else {
      NotificationManager.error("Please fill all required fields");
      this.setState({
        selTab:"Geographical",
        editStateValidation: "Please Select State.",
        editCityValidation: "Please Select City.",
        editPinCodeValidation: "Please Enter Pin Code.",
        editTimeZoneValidation:"Please Select TimeZone.",
        editlatitudeValidation: "Please Enter Latitude.",
        editlongitudeValidation: "Please Enter longitude.",
        editRegionValidation:"Please Select Region."
      });
    }
  }

  HandleStoreFunctionality() {
    if (this.state.userEditData.status_ID !== "0") {
      this.setState({
        selTab: "Optional Store Details",
      });
    } else {
      this.setState({
        editStatusValidation: "Please Choose Store Status",
      });
    }
  }
  HandleGeographicalBackBtn() {
    this.setState({
      selTab: "Demographic Store Details",
    });
  }
  HandleStoreFunctionalityBackBtn() {
    this.setState({
      selTab: "Geographical Store Details",
    });
  }
  handleGetStoreMasterData() {
    let self = this;
    this.setState({ loading: true });
    this.SettingsService.Post("/Store/StoreList")
      .then((response) => {
        let status = response.message;
        let data = response.responseData;

        if (data !== null) {
          self.state.sortAllData = data;
          var unique = [];
          var distinct = [];
          for (let i = 0; i < data.length; i++) {
            if (!unique[data[i].storeName]) {
              distinct.push(data[i].storeName);
              unique[data[i].storeName] = 1;
            }
          }
          for (let i = 0; i < distinct.length; i++) {
            if (distinct[i]) {
              self.state.sortStoreName.push({ storeName: distinct[i] });
              self.state.sortFilterStoreName.push({ storeName: distinct[i] });
            }
          }

          var unique = [];
          var distinct = [];
          for (let i = 0; i < data.length; i++) {
            if (!unique[data[i].storeCode]) {
              distinct.push(data[i].storeCode);
              unique[data[i].storeCode] = 1;
            }
          }

          for (let i = 0; i < distinct.length; i++) {
            if (distinct[i]) {
              self.state.sortStoreCode.push({ storeCode: distinct[i] });
              self.state.sortFilterStoreCode.push({ storeCode: distinct[i] });
            }
          }

          var unique = [];
          var distinct = [];
          for (let i = 0; i < data.length; i++) {
            if (!unique[data[i].cityName]) {
              distinct.push(data[i].cityName);
              unique[data[i].cityName] = 1;
            }
          }
          for (let i = 0; i < distinct.length; i++) {
            if (distinct[i]) {
              self.state.sortCity.push({ cityName: distinct[i] });
              self.state.sortFilterCity.push({ cityName: distinct[i] });
            }
          }

          var unique = [];
          var distinct = [];
          for (let i = 0; i < data.length; i++) {
            if (!unique[data[i].stateName]) {
              distinct.push(data[i].stateName);
              unique[data[i].stateName] = 1;
            }
          }
          for (let i = 0; i < distinct.length; i++) {
            if (distinct[i]) {
              self.state.sortState.push({ stateName: distinct[i] });
              self.state.sortFilterState.push({ stateName: distinct[i] });
            }
          }

          var unique = [];
          var distinct = [];
          for (let i = 0; i < data.length; i++) {
            if (!unique[data[i].strPinCode]) {
              distinct.push(data[i].strPinCode);
              unique[data[i].strPinCode] = 1;
            }
          }
          for (let i = 0; i < distinct.length; i++) {
            if (distinct[i]) {
              self.state.sortPincode.push({ strPinCode: distinct[i] });
              self.state.sortFilterPincode.push({ strPinCode: distinct[i] });
            }
          }

          var unique = [];
          var distinct = [];
          for (let i = 0; i < data.length; i++) {
            if (!unique[data[i].brandNames]) {
              distinct.push(data[i].brandNames);
              unique[data[i].brandNames] = 1;
            }
          }
          for (let i = 0; i < distinct.length; i++) {
            self.state.sortBrandName.push({ brandNames: distinct[i] });
            self.state.sortFilterBrandName.push({ brandNames: distinct[i] });
          }

          var unique = [];
          var distinct = [];
          for (let i = 0; i < data.length; i++) {
            if (!unique[data[i].regionName]) {
              distinct.push(data[i].regionName);
              unique[data[i].regionName] = 1;
            }
          }
          for (let i = 0; i < distinct.length; i++) {
            if (distinct[i]) {
              self.state.sortregionName.push({ regionName: distinct[i] });
              self.state.sortFilterregionName.push({ regionName: distinct[i] });
            }
          }

          var unique = [];
          var distinct = [];
          for (let i = 0; i < data.length; i++) {
            if (!unique[data[i].zone]) {
              distinct.push(data[i].zone);
              unique[data[i].zone] = 1;
            }
          }
          for (let i = 0; i < distinct.length; i++) {
            if (distinct[i]) {
              self.state.sortzone.push({ zone: distinct[i] });
              self.state.sortFilterzone.push({ zone: distinct[i] });
            }
          }

          var unique = [];
          var distinct = [];
          for (let i = 0; i < data.length; i++) {
            if (!unique[data[i].storeTypeName]) {
              distinct.push(data[i].storeTypeName);
              unique[data[i].storeTypeName] = 1;
            }
          }
          for (let i = 0; i < distinct.length; i++) {
            if (distinct[i]) {
              self.state.sortstoreTypeName.push({ storeTypeName: distinct[i] });
              self.state.sortFilterstoreTypeName.push({
                storeTypeName: distinct[i],
              });
            }
          }

          var unique = [];
          var distinct = [];
          for (let i = 0; i < data.length; i++) {
            if (!unique[data[i].email]) {
              distinct.push(data[i].email);
              unique[data[i].email] = 1;
            }
          }
          for (let i = 0; i < distinct.length; i++) {
            if (distinct[i]) {
              self.state.sortemail.push({ email: distinct[i] });
              self.state.sortFilteremail.push({ email: distinct[i] });
            }
          }

          var unique = [];
          var distinct = [];
          for (let i = 0; i < data.length; i++) {
            if (!unique[data[i].phoneNumber]) {
              distinct.push(data[i].phoneNumber);
              unique[data[i].phoneNumber] = 1;
            }
          }
          for (let i = 0; i < distinct.length; i++) {
            if (distinct[i]) {
              self.state.sortphoneNumber.push({ phoneNumber: distinct[i] });
              self.state.sortFilterphoneNumber.push({
                phoneNumber: distinct[i],
              });
            }
          }

          var unique = [];
          var distinct = [];
          for (let i = 0; i < data.length; i++) {
            if (!unique[data[i].status]) {
              distinct.push(data[i].status);
              unique[data[i].status] = 1;
            }
          }
          for (let i = 0; i < distinct.length; i++) {
            if (distinct[i]) {
              self.state.sortstatus.push({ status: distinct[i] });
              self.state.sortFilterstatus.push({ status: distinct[i] });
            }
          }
        }
        if (status === "Success") {
          if (data !== null) {
            self.setState({
              storeData: data,
              loading: false,
            });
          } else {
            self.setState({
              storeData: [],
              loading: false,
            });
          }
        } else {
          self.setState({
            storeData: [],
            loading: false,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  handleGetBrandList() {
    let self = this;
    this.SettingsService.Post("/Brand/GetBrandList")
      .then((response) => {
        let status = response.message;
        let data = response.responseData;
        if (status === "Success") {
          self.setState({ brandData: data });
          if (data) {
            this.setState({ selectedBrand: data[0] });
          }
        } else {
          self.setState({ brandData: [] });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  handleGetStateList() {
    let self = this;
    this.SettingsService.Post("/Master/getstatelist")
      .then((response) => {
        let status = response.message;
        let data = response.responseData;
        if (status === "Success") {
          self.setState({ stateData: data });
        } else {
          self.setState({ stateData: [] });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  handleGetCityList(id) {
    let self = this;
    var stateId;
    if (id) {
      stateId = id;
    } else {
      stateId = this.state.selectState;
    }
    let inputParams = {
      StateId: stateId,
    };
    this.SettingsService.PostWithParams("/Master/getcitylist", inputParams)
      .then((response) => {
        let status = response.message;
        let data = response.responseData;
        if (status === "Success") {
          self.setState({ cityData: data });
        } else {
          self.setState({ cityData: [] });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  handleGetRegionList() {
    let self = this;
    this.SettingsService.Post("/Master/getregionlist")
      .then((response) => {
        let status = response.message;
        let data = response.responseData;
        if (status === "Success") {
          self.setState({ regionData: data });
        } else {
          self.setState({ regionData: [] });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  handleGetStoreTypeList() {
    let self = this;
    this.SettingsService.Post("/Master/getstoretypelist")
      .then((response) => {
        let status = response.message;
        let data = response.responseData;
        if (status === "Success") {
          self.setState({ storeTypeData: data });
        } else {
          self.setState({ storeTypeData: [] });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  handleDeleteStore(store_Id) {
    const TranslationContext = this.state.translateLanguage.default;

    let self = this;
    let inputParams = {
      StoreID: store_Id,
    };
    this.SettingsService.PostWithParams("/Store/deleteStore", inputParams)
      .then((response) => {
        let status = response.message;
        if (status === "Record deleted Successfully") {
          self.handleGetStoreMasterData();
          NotificationManager.success(
            TranslationContext !== undefined
              ? TranslationContext.alertmessage.storedeletedsuccessfully
              : "Store deleted successfully."
          );
        } else {
          NotificationManager.error(status);
        }
      })
      .catch((error) => {
        console.log((error) => {
          console.log(error);
        });
      });
  }
  handleSubmitData() {
    const TranslationContext = this.state.translateLanguage.default;
    
    if (
      this.state.selectedBrand !== null &&
      this.state.store_code.length > 0 &&
      this.state.store_name.length > 0 &&
      this.state.pin_code.length > 0 &&
      this.state.contact_email.length > 0 &&
      this.state.contact_Phone.length > 0 &&
      this.state.selectState > 0 &&
      this.state.selectCity > 0 &&
      this.state.selectStatus !== "" &&
      this.state.emailFlag === true &&
      this.state.phoneFlag === true &&
      this.state.pinCodeFlag === true &&
      this.state.newStoreFiled.storeManagerName &&
      this.state.newStoreFiled.storeManagerMobile &&
      this.state.newStoreFiled.Latitude &&
      this.state.newStoreFiled.Longitude &&
       this.state.newStoreFiled.timeZone &&
      this.state.selectRegion > 0
    ) {
      let self = this;
      var activeStatus = false;
      var finalBrandId = "";
      var status = this.state.selectStatus;
      if (status === "Active") {
        activeStatus = true;
      } else {
        activeStatus = false;
      }
      if (this.state.selectedBrand) {
        // for (let i = 0; i < this.state.selectedBrand.length; i++) {
        //   finalBrandId += this.state.selectedBrand[i].brandID + ",";
        // }
        finalBrandId += this.state.selectedBrand.brandID;
      }
      let storeTimeZoneOffset = "";
      let storeTimeZone = "";
      if (this.state.newStoreFiled.timeZone) {
        storeTimeZone = this.state.newStoreFiled.timeZone.value;
        let storeTimeZoneTemp = this.state.newStoreFiled.timeZone.text;
        if (storeTimeZoneTemp.includes("+")) {
          let strtemp = storeTimeZoneTemp.split(")")[0];
          storeTimeZoneOffset = strtemp.substr(
            strtemp.indexOf("+"),
            strtemp.length
          );
        } else {
          let strtemp = storeTimeZoneTemp.split(")")[0];
          storeTimeZoneOffset = strtemp.substr(
            strtemp.indexOf("-"),
            strtemp.length
          );
        }
      }
      let inputData = {
        BrandIDs: finalBrandId,
        StoreCode: this.state.store_code.trim(),
        StoreName: this.state.store_name.trim(),
        StateID: this.state.selectState,
        CityID: this.state.selectCity,
        Pincode: this.state.pin_code,
        Address: this.state.store_Address.trim(),
        RegionID: this.state.selectRegion,
        ZoneID: this.state.selectZone,
        StoreTypeID: this.state.store_type,
        StoreEmailID: this.state.contact_email.trim(),
        StorePhoneNo: this.state.contact_Phone,
        IsActive: activeStatus,
        lpaasStore: this.state.store_code.trim() || null,
        skuStore: this.state.store_code.trim() || null,
        alternateStoreCode: this.state.store_code.trim() || null,
        StoreManagerName: this.state.newStoreFiled.storeManagerName || null,
        StoreManagerMobile: this.state.newStoreFiled.storeManagerMobile || null,
        designation: this.state.newStoreFiled.Designation || null,
        Shoppingassissted: this.state.newStoreFiled.ShoppingAssissted || null,
        storetiming: this.state.newStoreFiled.StoreTiming || null,
        dayStatus: this.state.newStoreFiled.DayStatus || null,
        openDays: this.state.newStoreFiled.OpenDays || null,
        openingTime: this.state.newStoreFiled.OpeningTime
          ? moment(this.state.newStoreFiled.OpeningTime)
            .format("HH:mm:ss")
            .toString()
          : null,
        closingTime: this.state.newStoreFiled.ClosingTime
          ? moment(this.state.newStoreFiled.ClosingTime)
            .format("HH:mm:ss")
            .toString()
          : null,
        startDate: this.state.newStoreFiled.StartDate
          ? moment(this.state.newStoreFiled.StartDate)
            .format("YYYY-MM-DD")
            .toString()
          : null,
        endDate: this.state.newStoreFiled.EndDate
          ? moment(this.state.newStoreFiled.EndDate)
            .format("YYYY-MM-DD")
            .toString()
          : null,
        storevalue: this.state.newStoreFiled.Storevalue || null,
        storeOwnership: this.state.newStoreFiled.StoreOwnership || null,
        storeChannel: this.state.newStoreFiled.StoreChannel || null,
        tier: this.state.newStoreFiled.Tier || null,
        latitude: this.state.newStoreFiled.Latitude || null,
        longitude: this.state.newStoreFiled.Longitude || null,
        timeZone:this.state.newStoreFiled.TimeZone|| null,
        storeGSTNo: this.state.newStoreFiled.StoreGSTNo || null,
        storeCIN: this.state.newStoreFiled.StoreCIN || null,
        storePan: this.state.newStoreFiled.StorePan || null,
        lpaasdatecreated: this.state.newStoreFiled.LpaasCreatedDate
          ? moment(this.state.newStoreFiled.LpaasCreatedDate)
            .format("YYYY-MM-DD")
            .toString()
          : null,
        isWhatsApp: this.state.newStoreFiled.IsWhatsApp ? "Y" : "N",
        isBell: this.state.newStoreFiled.IsBell ? "Y" : "N",
        isWebBot: this.state.newStoreFiled.IsWebBot ? "Y" : "N",
        isShoppingBag: this.state.newStoreFiled.IsShoppingBag ? "Y" : "N",
        isERPayment: this.state.newStoreFiled.IsERPayment ? "Y" : "N",
        isERShipping: this.state.newStoreFiled.IsERShipping ? "Y" : "N",
        isOrderSync: this.state.newStoreFiled.IsOrderSync ? "Y" : "N",
        isSendToShipmentManual: this.state.newStoreFiled.IsSendToShipmentManual
          ? "Y"
          : "N",
        isDigitalReceipt: this.state.newStoreFiled.IsDigitalReceipt ? "Y" : "N",
        isStateHub: this.state.newStoreFiled.IsStateHub ? "1" : "0",
        isCityHub: this.state.newStoreFiled.IsCityHub ? "1" : "0",
        isBrandHub: this.state.newStoreFiled.IsBrandHub ? "1" : "0",
        isStorePayEnable: this.state.newStoreFiled.IsStorePayEnable ? "1" : "0",
        isVideoCallEnable: this.state.newStoreFiled.IsVideoCallEnable || false,
        pickupLocation: this.state.newStoreFiled.PickupLocation || null,
        StoreTimeZone: storeTimeZone || null,
        StoreTimeZoneOffset: storeTimeZoneOffset || null,
      };
      this.SettingsService.PostWithData("/Store/createstore", inputData)
        .then((response) => {
          let status = response.message;
          if (status === "Success") {
            NotificationManager.success(
              TranslationContext !== undefined
                ? TranslationContext.alertmessage.storeaddedsuccessfully
                : "Store added successfully."
            );
            window.location.reload();

            self.setState({
              newStoreFiled: {},
              store_code: "",
              store_name: "",
              selectedBrand: [],
              pin_code: "",
              store_Address: "",
              selectCity: 0,
              selectState: 0,
              selectRegion: 0,
              selectZone: 0,
              store_type: 0,
              selectStatus: "",
              contact_email: "",
              contact_Phone: "",
              store_codeCompulsion: "",
              store_nameCompulsion: "",
              pin_codeCompulsion: "",
              store_AddressCompulsion: "",
              RegionCompulsion: "",
              ZoneCompulsion: "",
              store_typeCompulsion: "",
              contact_emailCompulsion: "",
              contact_PhoneCompulsion: "",
              StateCompulsion: "",
              CityCompulsion: "",
              brandCompulsion: "",
              statusCompulsion: "",
              timeZoneCompulsion:"",
              cityData: [],
            });
            // self.handleGetStoreMasterData();
          } else {
            NotificationManager.error(
              TranslationContext !== undefined
                ? TranslationContext.alertmessage.storenotadded
                : "Store Not added."
            );
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      NotificationManager.error(
        TranslationContext !== undefined
          ? TranslationContext.alertmessage.storenotadded
          : "Please fill all mandetory fields from each tab"
      );
      this.setState({
        store_codeCompulsion: "Please Enter Store Code.",
        store_nameCompulsion: "Please Enter Store Name.",
        pin_codeCompulsion: "Please Enter PinCode.",
        store_AddressCompulsion: "Please Enter Address.",
        RegionCompulsion: "Please Select Region.",
        ZoneCompulsion: "Please Select Zone.",
        contact_emailCompulsion: "Please Enter EmailID.",
        contact_PhoneCompulsion: "Please Enter Phone Number.",
        storeManagerMobileNoCompulsion: "Please Enter Store Manager Mobile No.",
        storeManagerNameCompulsion: "Please Enter Store Manager Name.",
        latitudeCompulsion: "Please Enter Latitude.",
        longitudeCompulsion: "Please Enter Longitude.",
        timeZoneCompulsion: "Please Select TimeZone.",
        StateCompulsion: "Please Select State.",
        CityCompulsion: "Please Select City.",
        brandCompulsion: "Please Select Brand.",
        statusCompulsion: "Please Select Status.",
      });
    }
  }

  handleUpdateData() {
    const TranslationContext = this.state.translateLanguage.default;
    if (
      this.state.modalSelectedBrand !== null &&
      this.state.modalSelectedBrand.length > 0 &&
      this.state.userEditData.store_Code.length > 0 &&
      this.state.userEditData.store_Name.length > 0 &&
      this.state.userEditData.state_ID !== "0" &&
      this.state.userEditData.city_ID !== "0" &&
      this.state.userEditData.strPin_Code.length > 0 &&
      this.state.userEditData.email_.length > 0 &&
      this.state.EditEmailFlag === true &&
      this.state.EditPhoneFlag === true &&
      this.state.userEditData.phoneNumber_.length > 0 &&
      this.state.userEditData.storeManagerMobile.length > 0 &&
      this.state.userEditData.storeManagerName.length > 0 &&
      this.state.userEditData.latitude.length > 0 &&
      this.state.userEditData.timeZoneID &&
      this.state.userEditData.longitude.length > 0
    ) {
      let self = this;
      var activeStatus = false;
      var finalBrandId = "";
      var status = this.state.userEditData.status_ID;
      if (status === true || status === "true") {
        activeStatus = true;
      } else {
        activeStatus = false;
      }
      if (this.state.modalSelectedBrand.length > 0) {
        for (let i = 0; i < this.state.modalSelectedBrand.length; i++) {
          finalBrandId += this.state.modalSelectedBrand[i].brandID + ",";
        }
      }

      this.setState({ editSaveLoading: true });
      let tempstartDate = this.state.userEditData.startDate
        ? moment(this.state.userEditData.startDate, "DD-MM-YYYY hh:mm:ss")
          .format("YYYY-MM-DD")
          .toString()
        : null;
      let tempendDate = this.state.userEditData.endDate
        ? moment(this.state.userEditData.endDate, "DD-MM-YYYY hh:mm:ss")
          .format("YYYY-MM-DD")
          .toString()
        : null;
      let tempOpeningTime = this.state.userEditData.openingTime
        ? this.state.userEditData.openingTime
        : null;
      let tempCloseingTime = this.state.userEditData.closingTime
        ? this.state.userEditData.closingTime
        : null;

      let inputData = {
        StoreID: this.state.userEditData.storeID,
        BrandIDs: finalBrandId,
        StoreCode: this.state.userEditData.store_Code,
        StoreName: this.state.userEditData.store_Name,
        StateID: this.state.userEditData.state_ID,
        CityID: this.state.userEditData.city_ID,
        Pincode: this.state.userEditData.strPin_Code,
        Address: this.state.userEditData.address_,
        RegionID: this.state.userEditData.region_ID,
        ZoneID: this.state.userEditData.zone_ID,
        StoreTypeID: this.state.userEditData.storeType_ID,
        StoreEmailID: this.state.userEditData.email_,
        StorePhoneNo: this.state.userEditData.phoneNumber_,
        IsActive: activeStatus,
        lpaasStore: this.state.userEditData.store_Code || null,
        skuStore: this.state.userEditData.store_Code || null,
        alternateStoreCode: this.state.userEditData.store_Code || null,
        storeManagerName: this.state.userEditData.storeManagerName || null,
        StoreManagerMobile: this.state.userEditData.storeManagerMobile || null,
        designation: this.state.userEditData.designation || null,
        shoppingassissted: this.state.userEditData.shoppingassissted || null,
        storetiming: this.state.userEditData.storetiming || null,
        dayStatus: this.state.userEditData.dayStatus || null,
        openDays: this.state.userEditData.openDays || null,
        openingTime: tempOpeningTime,
        closingTime: tempCloseingTime,
        startDate: tempstartDate,
        endDate: tempendDate,
        storevalue: this.state.userEditData.storevalue || null,
        storeOwnership: this.state.userEditData.storeOwnership || null,
        storeChannel: this.state.userEditData.storeChannel || null,
        tier: this.state.userEditData.tier || null,
        latitude: this.state.userEditData.latitude || null,
        longitude: this.state.userEditData.longitude || null,
        timeZone: this.state.userEditData.timeZone || null,
        storeGSTNo: this.state.userEditData.storeGSTNo || null,
        storeCIN: this.state.userEditData.storeCIN || null,
        storePan: this.state.userEditData.storePan || null,
        lpaasdatecreated: this.state.userEditData.lpaasdatecreated
          ? moment(this.state.userEditData.lpaasdatecreated)
            .format("YYYY-MM-DD")
            .toString()
          : null,
        isWhatsApp: this.state.userEditData.isWhatsApp ? "Y" : "N",
        isBell: this.state.userEditData.isBell ? "Y" : "N",
        isWebBot: this.state.userEditData.isWebBot ? "Y" : "N",
        isShoppingBag: this.state.userEditData.isShoppingBag ? "Y" : "N",
        isERPayment: this.state.userEditData.isERPayment ? "Y" : "N",
        isERShipping: this.state.userEditData.isERShipping ? "Y" : "N",
        isOrderSync: this.state.userEditData.isOrderSync ? "Y" : "N",
        isSendToShipmentManual: this.state.userEditData.isSendToShipmentManual
          ? "Y"
          : "N",
        isDigitalReceipt: this.state.userEditData.isDigitalReceipt ? "Y" : "N",
        isStateHub: this.state.userEditData.isStateHub ? "1" : "0",
        isCityHub: this.state.userEditData.isCityHub ? "1" : "0",
        isBrandHub: this.state.userEditData.isBrandHub ? "1" : "0",
        isStorePayEnable: this.state.userEditData.isStorePayEnable ? "1" : "0",
        isVideoCallEnable: this.state.userEditData.isVideoCallEnable || false,
        pickupLocation: this.state.userEditData.pickupLocation || null,
        StoreTimeZone: this.state.userEditData.storeTimeZone || null,
        StoreTimeZoneOffset:
          this.state.userEditData.storeTimeZoneOffset || null,
      };
      this.SettingsService.PostWithData("/Store/editstore", inputData)
        .then((response) => {
          let status = response.message;
          if (status === "Success") {
            self.handleGetStoreMasterData();
            NotificationManager.success(
              TranslationContext !== undefined
                ? TranslationContext.alertmessage.storeupdatedsuccessfully
                : "Store updated successfully."
            );

            self.setState({
              userEditData: {},
              editSaveLoading: false,
              editmodel: false,
              store_code: "",
              store_name: "",
              selectedBrand: [],
              pin_code: "",
              address_: "",
              selectCcity_IDity: 0,
              region_ID: 0,
              zone_ID: 0,
              storeType_ID: 0,
              email_: "",
              phoneNumber_: "",
              editBrandValidation: "",
              editStoreCodeValidation: "",
              editStoreNameValidation: "",
              editStateValidation: "",
              editCityValidation: "",
              editPinCodeValidation: "",
              editStoreAddressValidation: "",
              editRegionValidation: "",
              editZoneValidation: "",
              editStoreTypeValidation: "",
              editContactEmailValidation: "",
              editContactPhoneValidation: "",
              selTab: "Demographic",
              editstoreManagerMobileValidation: "",
              editstoreManagerNameValidation: "",
              editlatitudeValidation: "",
              editlongitudeValidation: "",
              editTimeZoneValidation: "",
              editIsWhatsAppValidation: "",
              editIsStorePayEnableValidation: "",
            });
          }
        })
        .catch((error) => {
          this.setState({
            editSaveLoading: false
          })
          console.log(error);
        });
    } else {
      NotificationManager.error("please check all tabs and ensure that all required fields are completed ");
      this.setState({
        editBrandValidation: "Please Select Brand.",
        editStoreCodeValidation: "Please Enter Store Code.",
        editStoreNameValidation: "Please Enter Store Name",
        editStateValidation: "Please Select State.",
        editCityValidation: "Please Select City.",
        editPinCodeValidation: "Please Enter PinCode.",
        editStoreAddressValidation: "Please Enter Store Address.",
        editRegionValidation: "Please Select Region.",
        editZoneValidation: "Please Select Zone.",
        editstoreManagerMobileValidation:
          "Please Enter Manager Contact.",
        editstoreManagerNameValidation: "Please Enter Manager Name.",
        editlatitudeValidation: "Please Enter Latitude.",
        editlongitudeValidation: "Please Enter longitude.",
        editTimeZoneValidation: "Please Select TimeZone",
        editContactEmailValidation: "Please Enter EmailID.",
        editContactPhoneValidation: "Please Enter Phone Number.",
      });
    }
  }
  fileUpload = (e) => {
    var allFiles = [];
    var selectedFiles = e;
    if (selectedFiles) {
      allFiles.push(selectedFiles[0]);

      var fileSize = formatSizeUnits(selectedFiles[0].size);
      this.setState({
        fileSize,
        fileN: allFiles,
        fileName: allFiles[0].name,
        bulkuploadCompulsion: "",
      });
    }
  };
  fileDrop = (e) => {
    var allFiles = [];
    var selectedFiles = e.target.files;
    allFiles.push(selectedFiles[0]);
    this.setState({
      fileN: allFiles,
      fileName: allFiles[0].name,
    });
    e.preventDefault();
  };
  fileDragOver = (e) => {
    e.preventDefault();
  };
  fileDragEnter = (e) => {
    e.preventDefault();
  };
  handleBrandChange = (e) => {
    if (e === null) {
      e = [];
    }
    this.setState({ selectedBrand: e });
  };
  handleEditBrandChange = (e) => {
    let value = e.target.value;
    this.setState({ EditBrand: value });
  };

  handleStateChange = (e) => {
    let value = parseInt(e.target.value);
    this.setState({ selectState: value, cityData: [] });
    setTimeout(() => {
      if (this.state.selectState) {
        this.handleGetCityList();
      }
    }, 1);
  };

  handleEditStoreMasterData(data) {
    this.setStoreUpdateData(data);
  }
  setStoreUpdateData(individualData) {
    var userEditData = {};

    userEditData = individualData;
    userEditData.isWhatsApp = individualData.isWhatsApp === "Y" ? true : false;
    userEditData.isBell = individualData.isBell === "Y" ? true : false;
    userEditData.isWebBot = individualData.isWebBot === "Y" ? true : false;
    userEditData.isShoppingBag =
      individualData.isShoppingBag === "Y" ? true : false;
    userEditData.isERPayment =
      individualData.isERPayment === "Y" ? true : false;
    userEditData.isERShipping =
      individualData.isERShipping === "Y" ? true : false;
    userEditData.isOrderSync =
      individualData.isOrderSync === "Y" ? true : false;
    userEditData.isSendToShipmentManual =
      individualData.isSendToShipmentManual === "Y" ? true : false;
    userEditData.isDigitalReceipt =
      individualData.isDigitalReceipt === "Y" ? true : false;
    userEditData.isStateHub = individualData.isStateHub === "1" ? true : false;
    userEditData.isCityHub = individualData.isCityHub === "1" ? true : false;
    userEditData.isBrandHub = individualData.isBrandHub === "1" ? true : false;
    userEditData.isStorePayEnable =
      individualData.isStorePayEnable === "1" ? true : false;
    userEditData.store_Name = individualData.storeName;
    userEditData.store_Code = individualData.storeCode;
    userEditData.brand_Name = individualData.brandName;
    userEditData.city_Name = individualData.cityName;
    userEditData.state_Name = individualData.stateName;
    userEditData.pin_Code = individualData.pinCode;
    userEditData.status_ = individualData.status;
    userEditData.strPin_Code = individualData.strPinCode;
    userEditData.city_ID = individualData.cityID;
    userEditData.state_ID = individualData.stateID;
    userEditData.region_ID = individualData.regionID;
    userEditData.zone_ID = individualData.zoneID;
    userEditData.storeType_ID = individualData.storeTypeID;
    userEditData.status_ID = individualData.statusID;
    userEditData.brand_IDs = individualData.brandIDs;
    userEditData.brandNames_ = individualData.brandNames;
    userEditData.brand_Names_ = individualData.brand_Names;
    userEditData.address_ = individualData.address;
    userEditData.email_ = individualData.email;
    userEditData.phoneNumber_ = individualData.phoneNumber;

    if (individualData.storeTimeZone) {
      userEditData.storeTimeZoneOffset = individualData.storeTimeZoneOffset;
      userEditData.storeTimeZone = individualData.storeTimeZone;
      let timeZoneID = this.state.timezoneData.filter(
        (x) => x.value == individualData.storeTimeZone
      )
        ? this.state.timezoneData.filter(
          (x) => x.value == individualData.storeTimeZone
        )[0]
        : undefined;
      userEditData.timeZoneID = timeZoneID;
    }

    var mBrandData = individualData.brandIDs.split(",");
    var modalSelectedBrand = [];
    for (let i = 0; i < mBrandData.length; i++) {
      var data = this.state.brandData.filter((x) => x.brandID == mBrandData[i]);
      if (data.length > 0) {
        modalSelectedBrand.push(data[0]);
      }
    }

    this.handleGetCityList(individualData.stateID);

    this.setState({
      editmodel: true,
      userEditData,
      modalSelectedBrand,
    });
  }
  handleOnChangeEditData = (e) => {
    var name = e.target.name;
    var value = e.target.value;

    var data = this.state.userEditData;
    data[name] = value;

    this.setState({
      EditTemp: data,
    });
    setTimeout(() => {
      if (this.state.userEditData.status_ID) {
        this.handleGetCityList();
      }
    }, 1);
  };
  handleCityChange = (e) => {
    let value = parseInt(e.target.value);
    this.setState({ selectCity: value });
  };

  // handleZoneChange = (e) => {
  //   let value = parseInt(e.target.value);
  //   this.setState({ selectZone: value });
  // };

  handleRegionChange = (e) => {
    let value = parseInt(e.target.value);
    this.setState({ selectRegion: value });
  };

  handleStoreTypeChange = (e) => {
    let value = parseInt(e.target.value);
    this.setState({ store_type: value });
  };

  handleStatusChange = (e) => {
    let value = e.target.value;
    this.setState({ selectStatus: value });
  };

  hanldeOnChangeData = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  hanldeOnEmailChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
    var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    if (e.target.value == "") {
      this.setState({
        emailFlag: true,
      });
    } else if (reg.test(e.target.value) == false) {
      this.setState({
        emailFlag: false,
      });
    } else {
      this.setState({
        emailFlag: true,
      });
    }
  };
  hanldeOnPhoneChange = (e) => {
    var name = e.target.name;
    if (name === "phoneNumber_") {
      var reg = /^[0-9\b]+$/;
      if (e.target.value === "" || reg.test(e.target.value)) {
        var value = e.target.value;
        var userEditData = this.state.userEditData;
        userEditData[name] = value;
        this.setState({ userEditData });
      } else {
        e.target.value = "";
      }
      if ((e.target.value.length >= 10 && e.target.value.length <= 14) || e.target.value.length == 0) {
        this.setState({
          phoneFlag: true,
        });
      } else {
        this.setState({
          phoneFlag: false,
        });
      }
    } else {
      var reg = /^[0-9\b]+$/;
      if (e.target.value === "" || reg.test(e.target.value)) {
        this.setState({ [e.target.name]: e.target.value });
      } else {
        e.target.value = "";
      }
      if ((e.target.value.length >= 10 && e.target.value.length <= 14) || e.target.value.length == 0) {
        this.setState({
          phoneFlag: true,
        });
      } else {
        this.setState({
          phoneFlag: false,
        });
      }
    }
  };
  hanldeOnPinCodeChange = (e) => {
    var reg = /^[0-9\b]+$/;
    if (e.target.value === "" || reg.test(e.target.value)) {
      this.setState({ [e.target.name]: e.target.value });
    } else {
      e.target.value = "";
    }
    if (e.target.value.length == 6 || e.target.value.length == 0) {
      this.setState({
        pinCodeFlag: true,
      });
    } else {
      this.setState({
        pinCodeFlag: false,
      });
    }
  };
  toggleEditModal() {
    this.setState({
      editmodel: false,
      editSaveLoading: false,
      selTab: "Demographic Store Details",
    });
  }

  handleModalEditData = (e) => {
    var name = e.target.name;
    var value = e.target.value;
    var userEditData = this.state.userEditData;
    userEditData[name] = value;
    this.setState({ userEditData });
    if (name == "state_ID") {
      this.handleGetCityList(value);
    }
    // Email validation
    if (name == "email_") {
      var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
      if (e.target.value == "") {
        this.setState({
          EditEmailFlag: true,
        });
      } else if (reg.test(e.target.value) == false) {
        this.setState({
          EditEmailFlag: false,
        });
      } else {
        this.setState({
          EditEmailFlag: true,
        });
      }
    }
  };

  handleModalBrandChange = (e) => {
    this.setState({ modalSelectedBrand: e });
  };

  filteTextChange(e) {
    this.setState({ filterTxtValue: e.target.value });

    if (this.state.sortColumn === "storeName") {
      var sortFilterStoreName = matchSorter(
        this.state.sortStoreName,
        e.target.value,
        { keys: ["storeName"] }
      );
      if (sortFilterStoreName.length > 0) {
        this.setState({ sortFilterStoreName });
      } else {
        this.setState({
          sortFilterStoreName: this.state.sortStoreName,
        });
      }
    }
    if (this.state.sortColumn === "storeCode") {
      var sortFilterStoreCode = matchSorter(
        this.state.sortStoreCode,
        e.target.value,
        { keys: ["storeCode"] }
      );
      if (sortFilterStoreCode.length > 0) {
        this.setState({ sortFilterStoreCode });
      } else {
        this.setState({
          sortFilterStoreCode: this.state.sortStoreCode,
        });
      }
    }
    if (this.state.sortColumn === "cityName") {
      var sortFilterCity = matchSorter(this.state.sortCity, e.target.value, {
        keys: ["cityName"],
      });
      if (sortFilterCity.length > 0) {
        this.setState({ sortFilterCity });
      } else {
        this.setState({
          sortFilterCity: this.state.sortCity,
        });
      }
    }
    if (this.state.sortColumn === "stateName") {
      var sortFilterState = matchSorter(this.state.sortState, e.target.value, {
        keys: ["stateName"],
      });
      if (sortFilterState.length > 0) {
        this.setState({ sortFilterState });
      } else {
        this.setState({
          sortFilterState: this.state.sortState,
        });
      }
    }
    if (this.state.sortColumn === "strPinCode") {
      var sortFilterPincode = matchSorter(
        this.state.sortPincode,
        e.target.value,
        {
          keys: ["strPinCode"],
        }
      );
      if (sortFilterPincode.length > 0) {
        this.setState({ sortFilterPincode });
      } else {
        this.setState({
          sortFilterPincode: this.state.sortPincode,
        });
      }
    }
    if (this.state.sortColumn === "regionName") {
      var sortFilterregionName = matchSorter(
        this.state.sortregionName,
        e.target.value,
        {
          keys: ["regionName"],
        }
      );
      if (sortFilterregionName.length > 0) {
        this.setState({ sortFilterregionName });
      } else {
        this.setState({
          sortFilterregionName: this.state.sortregionName,
        });
      }
    }
    if (this.state.sortColumn === "zone") {
      var sortFilterzone = matchSorter(this.state.sortzone, e.target.value, {
        keys: ["zone"],
      });
      if (sortFilterzone.length > 0) {
        this.setState({ sortFilterzone });
      } else {
        this.setState({
          sortFilterzone: this.state.sortzone,
        });
      }
    }
    if (this.state.sortColumn === "storeTypeName") {
      var sortFilterstoreTypeName = matchSorter(
        this.state.sortstoreTypeName,
        e.target.value,
        {
          keys: ["storeTypeName"],
        }
      );
      if (sortFilterstoreTypeName.length > 0) {
        this.setState({ sortFilterstoreTypeName });
      } else {
        this.setState({
          sortFilterstoreTypeName: this.state.sortstoreTypeName,
        });
      }
    }
    if (this.state.sortColumn === "email") {
      var sortFilteremail = matchSorter(this.state.sortemail, e.target.value, {
        keys: ["storeTypeName"],
      });
      if (sortFilteremail.length > 0) {
        this.setState({ sortFilteremail });
      } else {
        this.setState({
          sortFilteremail: this.state.sortemail,
        });
      }
    }
    if (this.state.sortColumn === "phoneNumber") {
      var sortFilterphoneNumber = matchSorter(
        this.state.sortphoneNumber,
        e.target.value,
        {
          keys: ["phoneNumber"],
        }
      );
      if (sortFilterphoneNumber.length > 0) {
        this.setState({ sortFilterphoneNumber });
      } else {
        this.setState({
          sortFilterphoneNumber: this.state.sortphoneNumber,
        });
      }
    }
    if (this.state.sortColumn === "status") {
      var sortFilterstatus = matchSorter(
        this.state.sortstatus,
        e.target.value,
        {
          keys: ["status"],
        }
      );
      if (sortFilterstatus.length > 0) {
        this.setState({ sortFilterstatus });
      } else {
        this.setState({
          sortFilterstatus: this.state.sortstatus,
        });
      }
    }
  }
  updateUploadProgress(value) {
    this.setState({ progressValue: value });
  }
  handleDeleteBulkupload = (e) => {
    const TranslationContext = this.state.translateLanguage.default;

    this.setState({
      fileN: [],
      fileName: "",
    });
    NotificationManager.success(
      TranslationContext !== undefined
        ? TranslationContext.alertmessage.filedeletedsuccessfully
        : "File deleted successfully."
    );
  };

  hanldeNewStoreChangeData = (e) => {
    const { name, value, checked } = e.target;
    let newStoreFiled = this.state.newStoreFiled;

    if (
      name === "IsWhatsApp" ||
      name === "IsBell" ||
      name === "IsWebBot" ||
      name === "IsShoppingBag" ||
      name === "IsERPayment" ||
      name === "IsERShipping" ||
      name === "IsOrderSync" ||
      name === "IsSendToShipmentManual" ||
      name === "IsDigitalReceipt" ||
      name === "IsStateHub" ||
      name === "IsCityHub" ||
      name === "IsBrandHub" ||
      name === "IsStorePayEnable" ||
      name === "IsVideoCallEnable"
    ) {
      newStoreFiled[name] = checked;
    } else {
      newStoreFiled[name] = value;
    }

    this.setState({
      newStoreFiled,
    });
  };
  hanldeNewStoreChangeDate = (name, e) => {
    let newStoreFiled = this.state.newStoreFiled;
    newStoreFiled[name] = e;

    this.setState({
      newStoreFiled,
    });
  };
  hanldeEditNewStoreChangeDate = (name, e) => {
    let userEditData = this.state.userEditData;
    if (e !== null) {
      if (name === "lpaasdatecreated") {
        userEditData[name] = moment(e).format("DD-MM-YYYY").toString();
      } else if (name === "openingTime") {
        userEditData[name] = moment(e).format("HH:mm:ss").toString();
      } else if (name === "closingTime") {
        userEditData[name] = moment(e).format("HH:mm:ss").toString();
      } else {
        userEditData[name] = moment(e).format("DD-MM-YYYY hh:mm:ss").toString();
      }
    } else {
      userEditData[name] = e;
    }

    this.setState({
      userEditData,
    });
  };
  handleEditNewStoreChange = (e) => {
    const { name, value, checked } = e.target;
    let userEditData = this.state.userEditData;
    if (name === "timeZoneID") {
      if (value) {
        userEditData["storeTimeZone"] = this.state.timezoneData.filter(
          (x) => x.abbr == value
        )[0].value;
        let storeTimeZoneTemp = this.state.timezoneData.filter(
          (x) => x.abbr == value
        )[0].text;
        if (storeTimeZoneTemp.includes("+")) {
          let strtemp = storeTimeZoneTemp.split(")")[0];
          userEditData["storeTimeZoneOffset"] = strtemp.substr(
            strtemp.indexOf("+"),
            strtemp.length
          );
        } else {
          let strtemp = storeTimeZoneTemp.split(")")[0];
          userEditData["storeTimeZoneOffset"] = strtemp.substr(
            strtemp.indexOf("-"),
            strtemp.length
          );
        }
      }
    }
    if (
      name === "isWhatsApp" ||
      name === "isBell" ||
      name === "isWebBot" ||
      name === "isShoppingBag" ||
      name === "isERPayment" ||
      name === "isERShipping" ||
      name === "isOrderSync" ||
      name === "isSendToShipmentManual" ||
      name === "isDigitalReceipt" ||
      name === "isStateHub" ||
      name === "isCityHub" ||
      name === "isBrandHub" ||
      name === "isStorePayEnable" ||
      name === "isVideoCallEnable"
    ) {
      userEditData[name] = checked;
    } else {
      userEditData[name] = value;
    }
    this.setState({
      userEditData,
    });
  };
  handleTimeZoneChange = (e) => {
    let newStoreFiled = this.state.newStoreFiled;
    newStoreFiled["timeZone"] = e;
    this.setState({ newStoreFiled });
  };
  handleCollapseChange = (e) => {
    this.setState({
      activeCollapse: e,
    });
  };
  handleInputChange = (newValue) => {
    const inputValue = newValue.replace(/\W/g, "");
    this.setState({ inputValue });
    return inputValue;
  };
  handleEditTimeZoneChange = (e) => {
    let userEditData = this.state.userEditData;
    userEditData["timeZoneID"] = e;
    if (e) {
      let temptext = e.text;
      let storeTimeZoneOffset = "";
      if (temptext.includes("+")) {
        let strtemp = temptext.split(")")[0];
        storeTimeZoneOffset = strtemp.substr(
          strtemp.indexOf("+"),
          strtemp.length
        );
      } else {
        let strtemp = temptext.split(")")[0];
        storeTimeZoneOffset = strtemp.substr(
          strtemp.indexOf("-"),
          strtemp.length
        );
      }
      userEditData["storeTimeZone"] = e.value;
      userEditData["storeTimeZoneOffset"] = storeTimeZoneOffset;
    }
    this.setState({ userEditData });
  };
  render() {
    const TranslationContext = this.state.translateLanguage.default;
    const { storeData } = this.state;
    const filterTimezone = (inputValue) => {
      return this.state.timezoneData.filter((i) =>
        i.text.toLowerCase().includes(inputValue.toLowerCase())
      );
    };
    const loadOptions = (inputValue, callback) => {
      setTimeout(() => {
        callback(filterTimezone(inputValue));
      }, 1000);
    };
    const demographicStoreDetails = (
      <>
        <div className="div-padding-1">
          <label className="designation-name required-field">
            {TranslationContext !== undefined
              ? TranslationContext.label.brand
              : "Brand"}
            <Popover content={null} placement="bottom">
              <img
                className="info-icon-cp"
                src={BlackInfoIcon}
                alt="info-icon"
              />
            </Popover>
          </label>
          <Select
            getOptionLabel={(option) => option.brandName}
            getOptionValue={(option) => option.brandID}
            options={this.state.brandData}
            placeholder={
              TranslationContext !== undefined
                ? TranslationContext.placeholder.select
                : "Select"
            }
            onChange={this.handleBrandChange}
            value={this.state.selectedBrand}
          />
          {this.state.selectedBrand.length === 0 && (
            <p style={{ color: "red", marginBottom: "0px" }}>
              {this.state.brandCompulsion}
            </p>
          )}
        </div>

        <div className="div-padding-1">
          <label className="designation-name requried-field">
            {TranslationContext !== undefined
              ? TranslationContext.label.storename
              : "Store Name"}
          </label>
          <Popover content={null} placement="bottom">
            <img
              className="info-icon-cp"
              src={BlackInfoIcon}
              alt="info-icon"
            />
          </Popover>
          <input
            type="text"
            className="txt-1"
            placeholder={
              TranslationContext !== undefined
                ? TranslationContext.placeholder.enterstorename
                : "Enter Store Name"
            }
            maxLength={100}
            name="store_name"
            value={this.state.store_name}
            onChange={this.hanldeOnChangeData}
          />
          {this.state.store_name.length === 0 && (
            <p style={{ color: "red", marginBottom: "0px" }}>
              {this.state.store_nameCompulsion}
            </p>
          )}
        </div>

        <div className="div-padding-1">
          <label className="designation-name requried-field">
            {TranslationContext !== undefined
              ? TranslationContext.label.storecode
              : "Store Code"}
          </label>
          <Popover content={null} placement="bottom">
            <img
              className="info-icon-cp"
              src={BlackInfoIcon}
              alt="info-icon"
            />
          </Popover>
          <input
            type="text"
            className="txt-1"
            placeholder={
              TranslationContext !== undefined
                ? TranslationContext.placeholder.enterstorecode
                : "Enter Store Code"
            }
            maxLength={10}
            name="store_code"
            value={this.state.store_code}
            onChange={this.hanldeOnChangeData}
          />
          {this.state.store_code.length === 0 && (
            <p style={{ color: "red", marginBottom: "0px" }}>
              {this.state.store_codeCompulsion}
            </p>
          )}
        </div>

        <div className="div-padding-1">
          <label className="designation-name requried-field">
            Store Email ID
          </label>
          <Popover content={null} placement="bottom">
            <img
              className="info-icon-cp"
              src={BlackInfoIcon}
              alt="info-icon"
            />
          </Popover>
          <input
            type="text"
            className="txt-1"
            placeholder={
              TranslationContext !== undefined
                ? TranslationContext.placeholder.enteremailid
                : "Enter email id"
            }
            maxLength={100}
            name="contact_email"
            value={this.state.contact_email}
            onChange={this.hanldeOnEmailChange}
          />
          {this.state.emailFlag === false && (
            <p style={{ color: "red", marginBottom: "0px" }}>
              Please enter valid Email Id.
            </p>
          )}
          {this.state.contact_email.length === 0 && (
            <p style={{ color: "red", marginBottom: "0px" }}>
              {this.state.contact_emailCompulsion}
            </p>
          )}
        </div>

        <div className="div-padding-1">
          <label className="designation-name requried-field">
            Store Phone No
          </label>
          <Popover content={null} placement="bottom">
            <img
              className="info-icon-cp"
              src={BlackInfoIcon}
              alt="info-icon"
            />
          </Popover>
          <input
            type="text"
            className="txt-1"
            placeholder={
              TranslationContext !== undefined
                ? TranslationContext.placeholder.enterphoneno
                : "Enter phone no"
            }
            maxLength={14}
            name="contact_Phone"
            value={this.state.contact_Phone}
            onChange={this.hanldeOnPhoneChange}
          />
          {this.state.phoneFlag === false && (
            <p style={{ color: "red", marginBottom: "0px" }}>
              Please enter valid Phone Number.
            </p>
          )}
          {this.state.contact_Phone.length === 0 && (
            <p style={{ color: "red", marginBottom: "0px" }}>
              {this.state.contact_PhoneCompulsion}
            </p>
          )}
        </div>

        <div className="div-padding-1">
          <label className="designation-name requried-field">
            Store Manager Name
          </label>
          <Popover content={null} placement="bottom">
            <img
              className="info-icon-cp"
              src={BlackInfoIcon}
              alt="info-icon"
            />
          </Popover>
          <input
            type="text"
            className="txt-1"
            placeholder={"Enter Store Manager Name"}
            maxLength={100}
            name="storeManagerName"
            value={this.state.newStoreFiled.storeManagerName}
            onChange={this.hanldeNewStoreChangeData}
          />
          {!this.state.newStoreFiled.storeManagerName && (
            <p style={{ color: "red", marginBottom: "0px" }}>
              {this.state.storeManagerNameCompulsion}
            </p>
          )}
        </div>
        <div className="div-padding-1">
          <label className="designation-name requried-field">
            Store Manager Mobile No
          </label>
          <Popover content={null} placement="bottom">
            <img
              className="info-icon-cp"
              src={BlackInfoIcon}
              alt="info-icon"
            />
          </Popover>
          <input
            type="text"
            className="txt-1"
            placeholder={
              TranslationContext !== undefined
                ? TranslationContext.placeholder.enterphoneno
                : "Enter Mobile No"
            }
            maxLength={14}
            name="storeManagerMobile"
            value={this.state.newStoreFiled.storeManagerMobile || null}
            onChange={this.hanldeNewStoreChangeData}
          />
          {!this.state.newStoreFiled.storeManagerMobile && (
            <p style={{ color: "red", marginBottom: "0px" }}>
              {this.state.storeManagerMobileNoCompulsion}
            </p>
          )}
        </div>
        {/* <div className="div-padding-1">
          <label className="designation-name">
            {TranslationContext !== undefined
              ? TranslationContext.label.storetype
              : "Store Type"}
          </label>
          <select
            className="store-create-select"
            value={this.state.store_type}
            onChange={this.handleStoreTypeChange}
          >
            <option value="0">
              {TranslationContext !== undefined
                ? TranslationContext.option.select
                : "Select"}
            </option>
            {this.state.storeTypeData !== null &&
              this.state.storeTypeData.map((item, t) => (
                <option key={t} value={item.storeTypeID}>
                  {item.storeTypeName}
                </option>
              ))}
          </select>
        </div>
        <div className="div-padding-1">
          <label className="designation-name">Designation</label>
          <input
            type="text"
            className="txt-1"
            placeholder={"Enter Designation"}
            maxLength={100}
            name="Designation"
            value={this.state.newStoreFiled.Designation || null}
            onChange={this.hanldeNewStoreChangeData}
          />
        </div> */}
        <div className="div-padding-1">
          <label className="designation-name">Opening Time</label>

          <TimePicker
            style={{ display: "inherit", width: "100%" }}
            format="HH:mm:ss"
            value={this.state.newStoreFiled.OpeningTime || null}
            onChange={this.hanldeNewStoreChangeDate.bind(this, "OpeningTime")}
          />
        </div>
        <div className="div-padding-1">
          <label className="designation-name">Closing Time</label>

          <TimePicker
            style={{ display: "inherit", width: "100%" }}
            format="HH:mm:ss"
            value={this.state.newStoreFiled.ClosingTime || null}
            onChange={this.hanldeNewStoreChangeDate.bind(this, "ClosingTime")}
          />
        </div>
        {/* <div className="div-padding-1">
          <label className="designation-name">Store Timing</label>
          <input
            type="text"
            className="txt-1"
            placeholder={"Enter Store Timing"}
            maxLength={100}
            name="StoreTiming"
            value={this.state.newStoreFiled.OpeningTime + `-`+ this.state.newStoreFiled.ClosingTime|| null}
            onChange={this.hanldeNewStoreChangeData}
          />
        </div> */}
        <div className="div-padding-1">
          <label className="designation-name">Start Date</label>

          <DatePicker
            style={{ display: "inherit", width: "100%" }}
            placeholderText="Select Start Date"
            format="YYYY-MM-DD"
            value={
              this.state.newStoreFiled.StartDate
                ? moment(this.state.newStoreFiled.StartDate)
                : null
            }
            onChange={this.hanldeNewStoreChangeDate.bind(this, "StartDate")}
          />
        </div>
        <div className="div-padding-1">
          <label className="designation-name">End Date</label>

          <DatePicker
            style={{ display: "inherit" }}
            placeholderText="Select End Date"
            dateFormat="YYYY-MM-DD"
            value={
              this.state.newStoreFiled.EndDate
                ? moment(this.state.newStoreFiled.EndDate)
                : null
            }
            onChange={this.hanldeNewStoreChangeDate.bind(this, "EndDate")}
          />
        </div>
        {/* <div className="div-padding-1">
          <label className="designation-name">Store value</label>
          <input
            type="text"
            className="txt-1"
            placeholder={"Enter Store value"}
            maxLength={100}
            name="Storevalue"
            value={this.state.newStoreFiled.Storevalue || null}
            onChange={this.hanldeNewStoreChangeData}
          />
        </div>
        <div className="div-padding-1">
          <label className="designation-name">Store Ownership</label>
          <input
            type="text"
            className="txt-1"
            placeholder={"Enter Store Ownership"}
            maxLength={100}
            name="StoreOwnership"
            value={this.state.newStoreFiled.StoreOwnership || null}
            onChange={this.hanldeNewStoreChangeData}
          />
        </div>
        <div className="div-padding-1">
          <label className="designation-name">Store Channel</label>
          <input
            type="text"
            className="txt-1"
            placeholder={"Enter Store Channel"}
            maxLength={100}
            name="StoreChannel"
            value={this.state.newStoreFiled.StoreChannel || null}
            onChange={this.hanldeNewStoreChangeData}
          />
          </div> */}
        <div className="div-padding-1">
          <label className="designation-name">Day Status</label>
          <select
            placeholder="Select Day Status"
            name="DayStatus"
            className="store-create-select"
            value={this.state.newStoreFiled.DayStatus || null}
            onChange={this.hanldeNewStoreChangeData}
          >
            <option>Select</option>
            <option value="Open">Open</option>
            <option value="closed">Close</option>
          </select>
        </div>
        <div className="div-padding-1">
          <label className="designation-name">Open Days</label>
          <input
            type="text"
            className="txt-1"
            placeholder={"Enter Open Days"}
            maxLength={100}
            name="OpenDays"
            value={this.state.newStoreFiled.OpenDays || null}
            onChange={this.hanldeNewStoreChangeData}
          />
        </div>
        {/* <div className="div-padding-1">
          <label className="designation-name">Shopping Assissted</label>
          <input
            type="text"
            className="txt-1"
            placeholder={"Enter Shopping Assissted"}
            maxLength={100}
            name="ShoppingAssissted"
            value={this.state.newStoreFiled.ShoppingAssissted || null}
            onChange={this.hanldeNewStoreChangeData}
          />
        </div> */}

        {/* <div className="div-padding-1">
          <label className="designation-name">Tier</label>
          <input
            type="text"
            className="txt-1"
            placeholder={"Enter Tier"}
            maxLength={100}
            name="Tier"
            value={this.state.newStoreFiled.Tier || null}
            onChange={this.hanldeNewStoreChangeData}
          />
        </div> */}

        {/* <div className="div-padding-1">
          <label className="designation-name">Store GST No</label>
          <input
            type="text"
            className="txt-1"
            placeholder={"Enter Store GST No"}
            maxLength={100}
            name="StoreGSTNo"
            value={this.state.newStoreFiled.StoreGSTNo || null}
            onChange={this.hanldeNewStoreChangeData}
          />
        </div>
        <div className="div-padding-1">
          <label className="designation-name">Store CIN</label>
          <input
            type="text"
            className="txt-1"
            placeholder={"Enter Store CIN"}
            maxLength={100}
            name="StoreCIN"
            value={this.state.newStoreFiled.StoreCIN || null}
            onChange={this.hanldeNewStoreChangeData}
          />
        </div>
        <div className="div-padding-1">
          <label className="designation-name">Store Pan</label>
          <input
            type="text"
            className="txt-1"
            placeholder={"Enter Store Pan"}
            maxLength={100}
            name="StorePan"
            value={this.state.newStoreFiled.StorePan || null}
            onChange={this.hanldeNewStoreChangeData}
          />
        </div>
        <div className="div-padding-1">
          <label className="designation-name">Pickup Location</label>
          <input
            type="text"
            className="txt-1"
            placeholder={"Enter Pickup Location"}
            maxLength={100}
            name="PickupLocation"
            value={this.state.newStoreFiled.PickupLocation || null}
            onChange={this.hanldeNewStoreChangeData}
          />
        </div> */}

        <div className="div-padding-1">
          <label className="designation-name requried-field">
            {TranslationContext !== undefined
              ? TranslationContext.label.status
              : "Status"}
          </label>
          <select
            className="form-control dropdown-setting"
            value={this.state.selectStatus}
            onChange={this.handleStatusChange}
          >
            <option value="">
              {TranslationContext !== undefined
                ? TranslationContext.option.select
                : "select"}
            </option>
            {this.state.activeData !== null &&
              this.state.activeData.map((item, j) => (
                <option key={j} value={item.ActiveID}>
                  {item.ActiveName}
                </option>
              ))}
          </select>

          {this.state.selectStatus === "" && (
            <p style={{ color: "red", marginBottom: "0px" }}>
              {this.state.statusCompulsion}
            </p>
          )}
        </div>
      </>
    );

    const geographicalStoreDetails = (
      <>
        <div className="div-padding-1">
          <label className="designation-name requried-field">
            {TranslationContext !== undefined
              ? TranslationContext.label.storename
              : "State"}
          </label>
          <Popover content={null} placement="bottom">
            <img
              className="info-icon-cp"
              src={BlackInfoIcon}
              alt="info-icon"
            />
          </Popover>
          <select
            className="store-create-select"
            value={this.state.selectState}
            onChange={this.handleStateChange}
          >
            <option value={0}>
              {TranslationContext !== undefined
                ? TranslationContext.option.select
                : "Select"}
            </option>
            {this.state.stateData !== null &&
              this.state.stateData.map((item, i) => (
                <option
                  key={i}
                  value={item.stateID}
                  className="select-category-placeholder"
                >
                  {item.stateName}
                </option>
              ))}
          </select>
          {this.state.selectState === 0 && (
            <p style={{ color: "red", marginBottom: "0px" }}>
              {this.state.StateCompulsion}
            </p>
          )}
        </div>

        <div className="div-padding-1">
          <label className="designation-name requried-field">
            {TranslationContext !== undefined
              ? TranslationContext.label.city
              : "City"}
          </label>
          <Popover
            content={
              //   <div className="settings-created-by-popover">
              //     <div>
              //       <p className="title"></p>
              //     </div>
              //   </div>
              null
            }
            placement="bottom"
          >
            <img
              className="info-icon-cp"
              src={BlackInfoIcon}
              alt="info-icon"
            />
          </Popover>
          <select
            className="store-create-select"
            value={this.state.selectCity}
            onChange={this.handleCityChange}
          >
            <option value="0">
              {TranslationContext !== undefined
                ? TranslationContext.option.select
                : "Select"}
            </option>
            {this.state.cityData !== null &&
              this.state.cityData.map((item, i) => (
                <option
                  key={i}
                  value={item.cityID}
                  className="select-category-placeholder"
                >
                  {item.cityName}
                </option>
              ))}
          </select>
          {this.state.selectCity === 0 && (
            <p style={{ color: "red", marginBottom: "0px" }}>
              {this.state.CityCompulsion}
            </p>
          )}
        </div>

        <div className="div-padding-1">
          <label className="designation-name requried-field">
            {TranslationContext !== undefined
              ? TranslationContext.label.pincode
              : "Pin Code"}
          </label>
          <Popover
            content={
              //   <div className="settings-created-by-popover">
              //     <div>
              //       <p className="title"></p>
              //     </div>
              //   </div>
              null
            }
            placement="bottom"
          >
            <img
              className="info-icon-cp"
              src={BlackInfoIcon}
              alt="info-icon"
            />
          </Popover>
          <input
            type="text"
            className="txt-1"
            placeholder={
              TranslationContext !== undefined
                ? TranslationContext.placeholder.enterpincode
                : "Enter Pin Code"
            }
            maxLength={6}
            name="pin_code"
            value={this.state.pin_code}
            onChange={this.hanldeOnPinCodeChange}
          />
          {this.state.pinCodeFlag === false && (
            <p style={{ color: "red", marginBottom: "0px" }}>
              Please enter valid Pin Code.
            </p>
          )}
          {this.state.pin_code.length === 0 && (
            <p style={{ color: "red", marginBottom: "0px" }}>
              {this.state.pin_codeCompulsion}
            </p>
          )}
        </div>

        <div className="div-padding-1">
          <label className="designation-name">
            {TranslationContext !== undefined
              ? TranslationContext.label.address
              : "Address"}
          </label>
          <textarea
            cols="31"
            rows="3"
            className="store-create-textarea"
            placeholder={
              TranslationContext !== undefined
                ? TranslationContext.placeholder.enteraddress
                : "Enter address"
            }
            maxLength={300}
            name="store_Address"
            value={this.state.store_Address}
            onChange={this.hanldeOnChangeData}
          ></textarea>
        </div>

        <div className="div-padding-1">
          <label className="designation-name  requried-field">
            {TranslationContext !== undefined
              ? TranslationContext.label.region
              : "Region"}
          </label>
          <select
            className="store-create-select"
            value={this.state.selectRegion}
            onChange={this.handleRegionChange}
          >
            <option value="0">Select</option>
            {this.state.regionData !== null &&
              this.state.regionData.map((item, s) => (
                <option key={s} value={item.regionID}>
                  {item.regionName}
                </option>
              ))}
          </select>
          {this.state.selectRegion === 0 && (
            <p style={{ color: "red", marginBottom: "0px" }}>
              {this.state.RegionCompulsion}
            </p>
          )}
        </div>
        {/*<div className="div-padding-1">
          <label className="designation-name">
            {TranslationContext !== undefined
              ? TranslationContext.label.zone
              : "Zone"}
          </label>
          <select
            className="store-create-select"
            value={this.state.selectZone}
            onChange={this.handleZoneChange}
          >
            <option value="0">
              {TranslationContext !== undefined
                ? TranslationContext.option.select
                : "Select"}
            </option>
            {this.state.zoneData !== null &&
              this.state.zoneData.map((item, s) => (
                <option key={s} value={item.zoneID}>
                  {item.zoneName}
                </option>
              ))}
          </select>
        </div> */}
        <div className="div-padding-1">
          <label className="designation-name requried-field">Time Zone</label>
          <div>
            <AsyncSelect
              getOptionLabel={(option) => option.text}
              getOptionValue={(option) => option.value}
              cacheOptions
              loadOptions={loadOptions}
              defaultOptions
              onInputChange={this.handleInputChange}
              onChange={this.handleTimeZoneChange}
              value={this.state.newStoreFiled.timeZone}
            />
            {!this.state.newStoreFiled.timeZone && (
              <p style={{ color: "red", marginBottom: "0px" }}>
                {this.state.timeZoneCompulsion}
              </p>
            )}
          </div>
        </div>
        <div className="div-padding-1">
          <label className="designation-name requried-field">
            Latitude{" "}
          </label>
          <Popover
            content={
              //   <div className="settings-created-by-popover">
              //     <div>
              //       <p className="title"></p>
              //     </div>
              //   </div>
              null
            }
            placement="bottom"
          >
            <img
              className="info-icon-cp"
              src={BlackInfoIcon}
              alt="info-icon"
            />
          </Popover>
          <input
            type="text"
            className="txt-1"
            placeholder={"Enter Latitude"}
            maxLength={100}
            name="Latitude"
            value={this.state.newStoreFiled.Latitude || null}
            onChange={this.hanldeNewStoreChangeData}
          />
          {!this.state.newStoreFiled.Latitude && (
            <p style={{ color: "red", marginBottom: "0px" }}>
              {this.state.latitudeCompulsion}
            </p>
          )}
        </div>
        <div className="div-padding-1">
          <label className="designation-name requried-field">
            Longitude{" "}
          </label>
          <Popover
            content={
              //   <div className="settings-created-by-popover">
              //     <div>
              //       <p className="title"></p>
              //     </div>
              //   </div>
              null
            }
            placement="bottom"
          >
            <img
              className="info-icon-cp"
              src={BlackInfoIcon}
              alt="info-icon"
            />
          </Popover>
          <input
            type="text"
            className="txt-1"
            placeholder={"Enter Longitude"}
            maxLength={100}
            name="Longitude"
            value={this.state.newStoreFiled.Longitude || null}
            onChange={this.hanldeNewStoreChangeData}
          />
          {!this.state.newStoreFiled.Longitude && (
            <p style={{ color: "red", marginBottom: "0px" }}>
              {this.state.longitudeCompulsion}
            </p>
          )}
        </div>
      </>
    );

    const storeFunctonalityData = (
      <>
        <div className="div-padding-1">
          <label className="designation-name">
            <Checkbox
              name="IsWhatsApp"
              onChange={this.hanldeNewStoreChangeData}
              checked={this.state.newStoreFiled.IsWhatsApp}
            >
              WhatsApp
            </Checkbox>
          </label>
          {/* {!this.state.newStoreFiled.IsWhatsApp && (
            <p style={{ color: "red", marginBottom: "0px" }}>
              {this.state.isWhatsappCompulsion}
            </p>
          )} */}
        </div>
        <div className="div-padding-1">
          <label className="designation-name">
            <Checkbox
              name="IsStorePayEnable"
              onChange={this.hanldeNewStoreChangeData}
              checked={this.state.newStoreFiled.IsStorePayEnable}
            >
              StorePayEnable
            </Checkbox>
          </label>
          {/* {!this.state.newStoreFiled.IsStorePayEnable && (
            <p style={{ color: "red", marginBottom: "0px" }}>
              {this.state.isStorePayEnableCompulsion}
            </p>
          )} */}
        </div>
        <div className="div-padding-1">
          <label className="designation-name">
            <Checkbox
              name="IsBell"
              onChange={this.hanldeNewStoreChangeData}
              checked={this.state.newStoreFiled.IsBell}
            >
              Bell
            </Checkbox>
          </label>
        </div>
        <div className="div-padding-1">
          <label className="designation-name">
            <Checkbox
              name="IsWebBot"
              onChange={this.hanldeNewStoreChangeData}
              checked={this.state.newStoreFiled.IsWebBot}
            >
              WebBot
            </Checkbox>
          </label>
        </div>
        {/* <div className="div-padding-1">
          <label className="designation-name">
            <Checkbox
              name="IsShoppingBag"
              onChange={this.hanldeNewStoreChangeData}
              checked={this.state.newStoreFiled.IsShoppingBag}
            >
              ShoppingBag
            </Checkbox>
          </label>
        </div> */}
        <div className="div-padding-1">
          <label className="designation-name">
            <Checkbox
              name="IsERPayment"
              onChange={this.hanldeNewStoreChangeData}
              checked={this.state.newStoreFiled.IsERPayment}
            >
              ERPayment
            </Checkbox>
          </label>
        </div>
        {/* <div className="div-padding-1">
          <label className="designation-name">
            <Checkbox
              name="IsERShipping"
              onChange={this.hanldeNewStoreChangeData}
              checked={this.state.newStoreFiled.IsERShipping}
            >
              ERShipping
            </Checkbox>
          </label>
        </div>
        <div className="div-padding-1">
          <label className="designation-name">
            <Checkbox
              name="IsOrderSync"
              onChange={this.hanldeNewStoreChangeData}
              checked={this.state.newStoreFiled.IsOrderSync}
            >
              OrderSync
            </Checkbox>
          </label>
        </div>
        <div className="div-padding-1">
          <label className="designation-name">
            <Checkbox
              name="IsSendToShipmentManual"
              onChange={this.hanldeNewStoreChangeData}
              checked={this.state.newStoreFiled.IsSendToShipmentManual}
            >
              SendToShipmentManual
            </Checkbox>
          </label>
        </div> */}
        {/* <div className="div-padding-1">
          <label className="designation-name">
            <Checkbox
              name="IsDigitalReceipt"
              onChange={this.hanldeNewStoreChangeData}
              checked={this.state.newStoreFiled.IsDigitalReceipt}
            >
              IsDigitalReceipt
            </Checkbox>
          </label>
        </div>
        <div className="div-padding-1">
          <label className="designation-name">
            <Checkbox
              name="IsStateHub"
              onChange={this.hanldeNewStoreChangeData}
              checked={this.state.newStoreFiled.IsStateHub}
            >
              IsStateHub
            </Checkbox>
          </label>
        </div>
        <div className="div-padding-1">
          <label className="designation-name">
            <Checkbox
              name="IsCityHub"
              onChange={this.hanldeNewStoreChangeData}
              checked={this.state.newStoreFiled.IsCityHub}
            >
              IsCityHub
            </Checkbox>
          </label>
        </div>
        <div className="div-padding-1">
          <label className="designation-name">
            <Checkbox
              name="IsBrandHub"
              onChange={this.hanldeNewStoreChangeData}
              checked={this.state.newStoreFiled.IsBrandHub}
            >
              IsBrandHub
            </Checkbox>
          </label>
        </div> */}
        <div className="div-padding-1">
          <label className="designation-name">
            <Checkbox
              name="IsVideoCallEnable"
              onChange={this.hanldeNewStoreChangeData}
              checked={this.state.newStoreFiled.IsVideoCallEnable}
            >
              VideoCallEnable
            </Checkbox>
          </label>
        </div>
      </>
    );

    const { Panel } = Collapse;
    return (
      <React.Fragment>
        <Modal
          onClose={this.StatusCloseModel}
          open={this.state.StatusModel}
          modalId="Status-popup"
          overlayId="logout-ovrly"
        >
          <div className="status-drop-down">
            <div className="sort-sctn">
              <label style={{ color: "#0066cc", fontWeight: "bold" }}>
                {this.state.sortHeader}
              </label>
              <div className="d-flex">
                <a
                  href="#!"
                  onClick={this.sortStatusAtoZ.bind(this)}
                  className="sorting-icon"
                >
                  <img src={Sorting} alt="sorting-icon" />
                </a>
                <p>
                  {TranslationContext !== undefined
                    ? TranslationContext.p.sortatoz
                    : "SORT BY A TO Z"}
                </p>
              </div>
              <div className="d-flex">
                <a
                  href="#!"
                  onClick={this.sortStatusZtoA.bind(this)}
                  className="sorting-icon"
                >
                  <img src={Sorting} alt="sorting-icon" />
                </a>
                <p>
                  {TranslationContext !== undefined
                    ? TranslationContext.p.sortztoa
                    : "SORT BY Z TO A"}
                </p>
              </div>
            </div>
            <a
              href=""
              style={{ margin: "0 25px", textDecoration: "underline" }}
              onClick={this.setSortCheckStatus.bind(this, "all")}
            >
              {TranslationContext !== undefined
                ? TranslationContext.a.clearsearch
                : "clear search"}
            </a>
            <div className="filter-type">
              <p>
                {TranslationContext !== undefined
                  ? TranslationContext.p.filterbytype
                  : "FILTER BY TYPE"}
              </p>
              <input
                type="text"
                style={{ display: "block" }}
                value={this.state.filterTxtValue}
                onChange={this.filteTextChange.bind(this)}
              />
              <div className="FTypeScroll">
                <div className="filter-checkbox">
                  <input
                    type="checkbox"
                    name="filter-type"
                    id={"fil-open"}
                    value="all"
                    checked={
                      this.state.sstoreNameFilterCheckbox.includes("all") ||
                      this.state.sstoreCodeFilterCheckbox.includes("all") ||
                      this.state.scityNameFilterCheckbox.includes("all") ||
                      this.state.sstateNameFilterCheckbox.includes("all") ||
                      this.state.sstrPinCodeFilterCheckbox.includes("all") ||
                      this.state.sortFilterregionName.includes("all") ||
                      this.state.sortFilterzone.includes("all") ||
                      this.state.sortFilterstoreTypeName.includes("all") ||
                      this.state.sortFilteremail.includes("all") ||
                      this.state.sortFilterphoneNumber.includes("all") ||
                      this.state.sortFilterstatus.includes("all")
                    }
                    onChange={this.setSortCheckStatus.bind(this, "all")}
                  />
                  <label htmlFor={"fil-open"}>
                    <span className="table-btn table-blue-btn">ALL</span>
                  </label>
                </div>
                {this.state.sortColumn === "storeName"
                  ? this.state.sortFilterStoreName !== null &&
                  this.state.sortFilterStoreName.map((item, i) => (
                    <div className="filter-checkbox">
                      <input
                        type="checkbox"
                        name="filter-type"
                        id={"fil-open" + item.storeName}
                        value={item.storeName}
                        checked={this.state.sstoreNameFilterCheckbox.includes(
                          item.storeName
                        )}
                        onChange={this.setSortCheckStatus.bind(
                          this,
                          "storeName",
                          "value"
                        )}
                      />
                      <label htmlFor={"fil-open" + item.storeName}>
                        <span className="table-btn table-blue-btn">
                          {item.storeName}
                        </span>
                      </label>
                    </div>
                  ))
                  : null}

                {this.state.sortColumn === "storeCode"
                  ? this.state.sortFilterStoreCode !== null &&
                  this.state.sortFilterStoreCode.map((item, i) => (
                    <div className="filter-checkbox">
                      <input
                        type="checkbox"
                        name="filter-type"
                        id={"fil-open" + item.storeCode}
                        value={item.storeCode}
                        checked={this.state.sstoreCodeFilterCheckbox.includes(
                          item.storeCode
                        )}
                        onChange={this.setSortCheckStatus.bind(
                          this,
                          "storeCode",
                          "value"
                        )}
                      />
                      <label htmlFor={"fil-open" + item.storeCode}>
                        <span className="table-btn table-blue-btn">
                          {item.storeCode}
                        </span>
                      </label>
                    </div>
                  ))
                  : null}

                {this.state.sortColumn === "cityName"
                  ? this.state.sortFilterCity !== null &&
                  this.state.sortFilterCity.map((item, i) => (
                    <div className="filter-checkbox">
                      <input
                        type="checkbox"
                        name="filter-type"
                        id={"fil-open" + item.cityName}
                        value={item.cityName}
                        checked={this.state.scityNameFilterCheckbox.includes(
                          item.cityName
                        )}
                        onChange={this.setSortCheckStatus.bind(
                          this,
                          "cityName",
                          "value"
                        )}
                      />
                      <label htmlFor={"fil-open" + item.cityName}>
                        <span className="table-btn table-blue-btn">
                          {item.cityName}
                        </span>
                      </label>
                    </div>
                  ))
                  : null}

                {this.state.sortColumn === "stateName"
                  ? this.state.sortFilterState !== null &&
                  this.state.sortFilterState.map((item, i) => (
                    <div className="filter-checkbox">
                      <input
                        type="checkbox"
                        name="filter-type"
                        id={"fil-open" + item.stateName}
                        value={item.stateName}
                        checked={this.state.sstateNameFilterCheckbox.includes(
                          item.stateName
                        )}
                        onChange={this.setSortCheckStatus.bind(
                          this,
                          "stateName",
                          "value"
                        )}
                      />
                      <label htmlFor={"fil-open" + item.stateName}>
                        <span className="table-btn table-blue-btn">
                          {item.stateName}
                        </span>
                      </label>
                    </div>
                  ))
                  : null}

                {this.state.sortColumn === "strPinCode"
                  ? this.state.sortFilterPincode !== null &&
                  this.state.sortFilterPincode.map((item, i) => (
                    <div className="filter-checkbox">
                      <input
                        type="checkbox"
                        name="filter-type"
                        id={"fil-open" + item.strPinCode}
                        value={item.strPinCode}
                        checked={this.state.sstrPinCodeFilterCheckbox.includes(
                          item.strPinCode
                        )}
                        onChange={this.setSortCheckStatus.bind(
                          this,
                          "strPinCode",
                          "value"
                        )}
                      />
                      <label htmlFor={"fil-open" + item.strPinCode}>
                        <span className="table-btn table-blue-btn">
                          {item.strPinCode}
                        </span>
                      </label>
                    </div>
                  ))
                  : null}
                {this.state.sortColumn === "regionName"
                  ? this.state.sortFilterregionName !== null &&
                  this.state.sortFilterregionName.map((item, i) => (
                    <div className="filter-checkbox">
                      <input
                        type="checkbox"
                        name="filter-type"
                        id={"fil-open" + item.regionName}
                        value={item.regionName}
                        checked={this.state.sregionNameFilterCheckbox.includes(
                          item.regionName
                        )}
                        onChange={this.setSortCheckStatus.bind(
                          this,
                          "regionName",
                          "value"
                        )}
                      />
                      <label htmlFor={"fil-open" + item.regionName}>
                        <span className="table-btn table-blue-btn">
                          {item.regionName}
                        </span>
                      </label>
                    </div>
                  ))
                  : null}
                {this.state.sortColumn === "zone"
                  ? this.state.sortFilterzone !== null &&
                  this.state.sortFilterzone.map((item, i) => (
                    <div className="filter-checkbox">
                      <input
                        type="checkbox"
                        name="filter-type"
                        id={"fil-open" + item.zone}
                        value={item.zone}
                        checked={this.state.szoneFilterCheckbox.includes(
                          item.zone
                        )}
                        onChange={this.setSortCheckStatus.bind(
                          this,
                          "zone",
                          "value"
                        )}
                      />
                      <label htmlFor={"fil-open" + item.zone}>
                        <span className="table-btn table-blue-btn">
                          {item.zone}
                        </span>
                      </label>
                    </div>
                  ))
                  : null}
                {this.state.sortColumn === "storeTypeName"
                  ? this.state.sortFilterstoreTypeName !== null &&
                  this.state.sortFilterstoreTypeName.map((item, i) => (
                    <div className="filter-checkbox">
                      <input
                        type="checkbox"
                        name="filter-type"
                        id={"fil-open" + item.storeTypeName}
                        value={item.storeTypeName}
                        checked={this.state.sstoreTypeNameFilterCheckbox.includes(
                          item.storeTypeName
                        )}
                        onChange={this.setSortCheckStatus.bind(
                          this,
                          "storeTypeName",
                          "value"
                        )}
                      />
                      <label htmlFor={"fil-open" + item.storeTypeName}>
                        <span className="table-btn table-blue-btn">
                          {item.storeTypeName}
                        </span>
                      </label>
                    </div>
                  ))
                  : null}
                {this.state.sortColumn === "email"
                  ? this.state.sortFilteremail !== null &&
                  this.state.sortFilteremail.map((item, i) => (
                    <div className="filter-checkbox">
                      <input
                        type="checkbox"
                        name="filter-type"
                        id={"fil-open" + item.email}
                        value={item.email}
                        checked={this.state.semailFilterCheckbox.includes(
                          item.email
                        )}
                        onChange={this.setSortCheckStatus.bind(
                          this,
                          "email",
                          "value"
                        )}
                      />
                      <label htmlFor={"fil-open" + item.email}>
                        <span className="table-btn table-blue-btn">
                          {item.email}
                        </span>
                      </label>
                    </div>
                  ))
                  : null}
                {this.state.sortColumn === "phoneNumber"
                  ? this.state.sortFilterphoneNumber !== null &&
                  this.state.sortFilterphoneNumber.map((item, i) => (
                    <div className="filter-checkbox">
                      <input
                        type="checkbox"
                        name="filter-type"
                        id={"fil-open" + item.phoneNumber}
                        value={item.phoneNumber}
                        checked={this.state.sphoneNumberFilterCheckbox.includes(
                          item.phoneNumber
                        )}
                        onChange={this.setSortCheckStatus.bind(
                          this,
                          "phoneNumber",
                          "value"
                        )}
                      />
                      <label htmlFor={"fil-open" + item.phoneNumber}>
                        <span className="table-btn table-blue-btn">
                          {item.phoneNumber}
                        </span>
                      </label>
                    </div>
                  ))
                  : null}
                {this.state.sortColumn === "status"
                  ? this.state.sortFilterstatus !== null &&
                  this.state.sortFilterstatus.map((item, i) => (
                    <div className="filter-checkbox">
                      <input
                        type="checkbox"
                        name="filter-type"
                        id={"fil-open" + item.status}
                        value={item.status}
                        checked={this.state.sstatusFilterCheckbox.includes(
                          item.status
                        )}
                        onChange={this.setSortCheckStatus.bind(
                          this,
                          "status",
                          "value"
                        )}
                      />
                      <label htmlFor={"fil-open" + item.status}>
                        <span className="table-btn table-blue-btn">
                          {item.status}
                        </span>
                      </label>
                    </div>
                  ))
                  : null}
              </div>
            </div>
          </div>
        </Modal>

        <div className="container-fluid setting-title setting-breadcrumb">
          <Link to="settings" className="header-path">
            {TranslationContext !== undefined
              ? TranslationContext.link.setting
              : "Settings"}
          </Link>
          <span>&gt;</span>
          <Link
            to={{
              pathname: "/store/settings",
              tabName: "store-tab",
            }}
            className="header-path"
          >
            {TranslationContext !== undefined
              ? TranslationContext.link.store
              : "Store"}
          </Link>
          <span>&gt;</span>
          <Link to={Demo.BLANK_LINK} className="header-path active">
            {TranslationContext !== undefined
              ? TranslationContext.link.storemaster
              : "Store Master"}
          </Link>
        </div>
        <div className="container-fluid">
          <div className="store-settings-cntr settingtable">
            <div className="row">
              <div className="col-md-8">
                {this.state.loading === true ? (
                  <div className="loader-icon loader-icon-height"></div>
                ) : (
                  <div className="table-cntr table-height TicketStoreReact settings-align">
                    <ReactTable
                      data={storeData}
                      columns={[
                        {
                          Header: (
                            <span
                              className={this.state.storeNameColor}
                              onClick={this.StatusOpenModel.bind(
                                this,
                                "storeName",
                                TranslationContext !== undefined
                                  ? TranslationContext.span.storename
                                  : "Store Name"
                              )}
                            >
                              {TranslationContext !== undefined
                                ? TranslationContext.span.storename
                                : "Store Name"}

                              <FontAwesomeIcon icon={faCaretDown} />
                            </span>
                          ),
                          sortable: false,
                          accessor: "storeName",
                          width: 120,
                        },
                        {
                          Header: (
                            <span
                              className={this.state.storeCodecolor}
                              onClick={this.StatusOpenModel.bind(
                                this,
                                "storeCode",
                                TranslationContext !== undefined
                                  ? TranslationContext.span.storecode
                                  : "Store Code"
                              )}
                            >
                              {TranslationContext !== undefined
                                ? TranslationContext.span.storecode
                                : "Store Code"}
                              <FontAwesomeIcon icon={faCaretDown} />
                            </span>
                          ),
                          sortable: false,
                          accessor: "storeCode",
                          width: 120,
                        },
                        {
                          Header: (
                            <span className={this.state.brandnameColor}>
                              {TranslationContext !== undefined
                                ? TranslationContext.span.brandname
                                : "Brand Name"}
                            </span>
                          ),
                          accessor: "brand_Names",
                          width: 125,
                          sortable: false,
                          Cell: (row) => {
                            if (isNaN(row.original.brand_Names)) {
                              return (
                                <div>
                                  <span className="one-liner">
                                    {row.original["brand_Names"]}

                                    <Popover
                                      content={
                                        <div className="settings-created-by-popover">
                                          <div>
                                            <p className="title">
                                              {TranslationContext !== undefined
                                                ? TranslationContext.p.brandname
                                                : "Brand Name"}
                                              : &nbsp;
                                              <b>
                                                {row.original["brandNames"]}
                                              </b>
                                            </p>
                                          </div>
                                        </div>
                                      }
                                      placement="bottom"
                                    >
                                      <img
                                        className="info-icon-cp"
                                        src={BlackInfoIcon}
                                        alt="info-icon"
                                      />
                                    </Popover>
                                  </span>
                                </div>
                              );
                            } else {
                              return null;
                            }
                          },
                        },
                        {
                          Header: (
                            <span
                              className={this.state.cityColor}
                              onClick={this.StatusOpenModel.bind(
                                this,
                                "cityName",
                                TranslationContext !== undefined
                                  ? TranslationContext.span.city
                                  : "City"
                              )}
                            >
                              {TranslationContext !== undefined
                                ? TranslationContext.span.city
                                : "City"}
                              <FontAwesomeIcon icon={faCaretDown} />
                            </span>
                          ),
                          sortable: false,
                          accessor: "cityName",
                        },
                        {
                          Header: (
                            <span
                              className={this.state.stateColor}
                              onClick={this.StatusOpenModel.bind(
                                this,
                                "stateName",
                                TranslationContext !== undefined
                                  ? TranslationContext.span.state
                                  : "State"
                              )}
                            >
                              {TranslationContext !== undefined
                                ? TranslationContext.span.state
                                : "State"}

                              <FontAwesomeIcon icon={faCaretDown} />
                            </span>
                          ),
                          accessor: "stateName",
                        },
                        {
                          Header: (
                            <span
                              className={this.state.pincodeColor}
                              onClick={this.StatusOpenModel.bind(
                                this,
                                "strPinCode",
                                TranslationContext !== undefined
                                  ? TranslationContext.span.pincode
                                  : "Pin code"
                              )}
                            >
                              {TranslationContext !== undefined
                                ? TranslationContext.span.pincode
                                : "Pin code"}

                              <FontAwesomeIcon icon={faCaretDown} />
                            </span>
                          ),
                          sortable: false,
                          accessor: "strPinCode",
                        },
                        // {
                        //   Header: (
                        //     <span>
                        //       {TranslationContext !== undefined
                        //         ? TranslationContext.span.address
                        //         : "Address"}
                        //       <FontAwesomeIcon icon={faCaretDown} />
                        //     </span>
                        //   ),
                        //   sortable: false,
                        //   accessor: "address",
                        //   minWidth: 250,
                        // },
                        // {
                        //   Header: (
                        //     <span
                        //       onClick={this.StatusOpenModel.bind(
                        //         this,
                        //         "regionName",
                        //         TranslationContext !== undefined
                        //           ? TranslationContext.span.region
                        //           : "Region"
                        //       )}
                        //     >
                        //       {TranslationContext !== undefined
                        //         ? TranslationContext.span.region
                        //         : "Region"}

                        //       <FontAwesomeIcon icon={faCaretDown} />
                        //     </span>
                        //   ),
                        //   sortable: false,
                        //   accessor: "regionName",
                        // },
                        // {
                        //   Header: (
                        //     <span
                        //       onClick={this.StatusOpenModel.bind(
                        //         this,
                        //         "zone",
                        //         TranslationContext !== undefined
                        //           ? TranslationContext.span.zone
                        //           : "Zone"
                        //       )}
                        //     >
                        //       {TranslationContext !== undefined
                        //         ? TranslationContext.span.zone
                        //         : "Zone"}
                        //       <FontAwesomeIcon icon={faCaretDown} />
                        //     </span>
                        //   ),
                        //   sortable: false,
                        //   accessor: "zone",
                        // },
                        // {
                        //   Header: (
                        //     <span
                        //       onClick={this.StatusOpenModel.bind(
                        //         this,
                        //         "storeTypeName",
                        //         TranslationContext !== undefined
                        //           ? TranslationContext.span.storetype
                        //           : "Store Type"
                        //       )}
                        //     >
                        //       {TranslationContext !== undefined
                        //         ? TranslationContext.span.storetype
                        //         : "Store Type"}
                        //       <FontAwesomeIcon icon={faCaretDown} />
                        //     </span>
                        //   ),
                        //   sortable: false,
                        //   width: 115,
                        //   accessor: "storeTypeName",
                        // },
                        {
                          Header: (
                            <span
                              onClick={this.StatusOpenModel.bind(
                                this,
                                "email",
                                TranslationContext !== undefined
                                  ? TranslationContext.span.emailid
                                  : "Email ID"
                              )}
                            >
                              {TranslationContext !== undefined
                                ? TranslationContext.span.emailid
                                : "Email ID"}

                              <FontAwesomeIcon icon={faCaretDown} />
                            </span>
                          ),
                          sortable: false,
                          accessor: "email",
                          minWidth: 220,
                        },
                        {
                          Header: (
                            <span
                              onClick={this.StatusOpenModel.bind(
                                this,
                                "phoneNumber",
                                TranslationContext !== undefined
                                  ? TranslationContext.span.phonenumber
                                  : "Phone No"
                              )}
                            >
                              {TranslationContext !== undefined
                                ? TranslationContext.span.phonenumber
                                : "Phone No"}
                              <FontAwesomeIcon icon={faCaretDown} />
                            </span>
                          ),
                          sortable: false,
                          accessor: "phoneNumber",
                          minWidth: 120,
                        },
                        // {
                        //   Header: <span>lpaas Store</span>,
                        //   sortable: false,
                        //   accessor: "lpaasStore",
                        // },
                        // {
                        //   Header: <span>SKU Store</span>,
                        //   sortable: false,
                        //   accessor: "skuStore",
                        // },
                        // {
                        //   Header: <span>Alternate Store Code</span>,
                        //   sortable: false,
                        //   accessor: "alternateStoreCode",
                        // },
                        {
                          Header: <span>Manager Name</span>,
                          sortable: false,
                          accessor: "storeManagerName",
                        },
                        // {
                        //   Header: <span>Designation</span>,
                        //   sortable: false,
                        //   accessor: "designation",
                        // },
                        // {
                        //   Header: <span>Shopping Assissted</span>,
                        //   sortable: false,
                        //   accessor: "shoppingassissted",
                        // },
                        // {
                        //   Header: <span>Store Timing</span>,
                        //   sortable: false,
                        //   accessor: "storetiming",
                        // },
                        // {
                        //   Header: <span>Day Status</span>,
                        //   sortable: false,
                        //   accessor: "dayStatus",
                        // },
                        // {
                        //   Header: <span>Open Days</span>,
                        //   sortable: false,
                        //   accessor: "openDays",
                        // },
                        // {
                        //   Header: <span>Opening Time</span>,
                        //   sortable: false,
                        //   accessor: "openingTime",
                        // },
                        // {
                        //   Header: <span>Closing Time</span>,
                        //   sortable: false,
                        //   accessor: "closingTime",
                        // },
                        // {
                        //   Header: <span>Start Date</span>,
                        //   sortable: false,
                        //   accessor: "startDate",
                        // },
                        // {
                        //   Header: <span>End Date</span>,
                        //   sortable: false,
                        //   accessor: "endDate",
                        // },
                        // {
                        //   Header: <span>Store value</span>,
                        //   sortable: false,
                        //   accessor: "storevalue",
                        // },
                        // {
                        //   Header: <span>Store Ownership</span>,
                        //   sortable: false,
                        //   accessor: "storeOwnership",
                        // },
                        // {
                        //   Header: <span>Store Channel</span>,
                        //   sortable: false,
                        //   accessor: "storeChannel",
                        // },
                        {
                          Header: <span>Whatsapp</span>,
                          sortable: false,
                          accessor: "isWhatsapp",
                          Cell: (row) => {
                            return row.original.isWhatsApp ? "Yes" : "No";
                          },
                        },
                        // {
                        //   Header: <span>Tier</span>,
                        //   sortable: false,
                        //   accessor: "tier",
                        // },
                        {
                          Header: <span>Latitude</span>,
                          sortable: false,
                          accessor: "latitude",
                        },
                        {
                          Header: <span>Longitude</span>,
                          sortable: false,
                          accessor: "longitude",
                        },
                        // {
                        //   Header: <span>Store GST No</span>,
                        //   sortable: false,
                        //   accessor: "storeGSTNo",
                        // },
                        // {
                        //   Header: <span>Store CIN</span>,
                        //   sortable: false,
                        //   accessor: "storeCIN",
                        // },
                        // {
                        //   Header: <span>Store Pan</span>,
                        //   sortable: false,
                        //   accessor: "storePan",
                        // },
                        // // {
                        // //   Header: <span>Lpaas date Created</span>,
                        // //   sortable: false,
                        // //   accessor: "lpaasdatecreated",
                        // // },
                        // {
                        //   Header: <span>IsWhatsApp</span>,
                        //   sortable: false,
                        //   accessor: "isWhatsApp",
                        //   Cell: (row) => {
                        //     return row.original.isWhatsApp == "Y"
                        //       ? "Yes"
                        //       : "No";
                        //   },
                        // },
                        // {
                        //   Header: <span>IsBell</span>,
                        //   sortable: false,
                        //   accessor: "isBell",
                        //   Cell: (row) => {
                        //     return row.original.isBell == "Y" ? "Yes" : "No";
                        //   },
                        // },
                        // {
                        //   Header: <span>IsWebBot</span>,
                        //   sortable: false,
                        //   accessor: "isWebBot",
                        //   Cell: (row) => {
                        //     return row.original.isWebBot == "Y" ? "Yes" : "No";
                        //   },
                        // },
                        // {
                        //   Header: <span>IsShoppingBag</span>,
                        //   sortable: false,
                        //   accessor: "isShoppingBag",
                        //   Cell: (row) => {
                        //     return row.original.isShoppingBag == "Y"
                        //       ? "Yes"
                        //       : "No";
                        //   },
                        // },
                        // {
                        //   Header: <span>IsERPayment</span>,
                        //   sortable: false,
                        //   accessor: "isERPayment",
                        //   Cell: (row) => {
                        //     return row.original.isERPayment == "Y"
                        //       ? "Yes"
                        //       : "No";
                        //   },
                        // },
                        // {
                        //   Header: <span>IsERShipping</span>,
                        //   sortable: false,
                        //   accessor: "isERShipping",
                        //   Cell: (row) => {
                        //     return row.original.isERShipping == "Y"
                        //       ? "Yes"
                        //       : "No";
                        //   },
                        // },
                        // {
                        //   Header: <span>IsOrderSync</span>,
                        //   sortable: false,
                        //   accessor: "isOrderSync",
                        //   Cell: (row) => {
                        //     return row.original.isOrderSync == "Y"
                        //       ? "Yes"
                        //       : "No";
                        //   },
                        // },
                        // {
                        //   Header: <span>IsSendToShipmentManual</span>,
                        //   sortable: false,
                        //   accessor: "isSendToShipmentManual",
                        //   Cell: (row) => {
                        //     return row.original.isSendToShipmentManual == "Y"
                        //       ? "Yes"
                        //       : "No";
                        //   },
                        // },
                        // {
                        //   Header: <span>IsDigitalReceipt</span>,
                        //   sortable: false,
                        //   accessor: "isDigitalReceipt",
                        //   Cell: (row) => {
                        //     return row.original.isDigitalReceipt == "Y"
                        //       ? "Yes"
                        //       : "No";
                        //   },
                        // },
                        // {
                        //   Header: <span>IsStateHub</span>,
                        //   sortable: false,
                        //   accessor: "isStateHub",
                        //   Cell: (row) => {
                        //     return row.original.isStateHub == 1 ? "Yes" : "No";
                        //   },
                        // },
                        // {
                        //   Header: <span>IsCityHub</span>,
                        //   sortable: false,
                        //   accessor: "isCityHub",
                        //   Cell: (row) => {
                        //     return row.original.isCityHub == 1 ? "Yes" : "No";
                        //   },
                        // },
                        // {
                        //   Header: <span>IsBrandHub</span>,
                        //   sortable: false,
                        //   accessor: "isBrandHub",
                        //   Cell: (row) => {
                        //     return row.original.isBrandHub == 1 ? "Yes" : "No";
                        //   },
                        // },
                        {
                          Header: <span>StorePayEnable</span>,
                          sortable: false,
                          accessor: "isStorePayEnable",
                          Cell: (row) => {
                            return row.original.isStorePayEnable == "1"
                              ? "Yes"
                              : "No";
                          },
                        },
                        {
                          Header: (
                            <span
                              onClick={this.StatusOpenModel.bind(
                                this,
                                "status",
                                TranslationContext !== undefined
                                  ? TranslationContext.span.status
                                  : "Status"
                              )}
                            >
                              {TranslationContext !== undefined
                                ? TranslationContext.span.status
                                : "Status"}
                              <FontAwesomeIcon icon={faCaretDown} />
                            </span>
                          ),
                          sortable: false,
                          accessor: "status",
                        },
                        {
                          Header: (
                            <span>
                              {TranslationContext !== undefined
                                ? TranslationContext.span.actions
                                : "Actions"}
                            </span>
                          ),
                          accessor: "actiondept",
                          sortable: false,
                          minWidth: 140,
                          Cell: (row) => {
                            var ids = row.original["storeID"];
                            return (
                              <>
                                <span className="settings-align-actions">
                                  <Popover
                                    content={
                                      <div className="d-flex general-popover popover-body">
                                        <div className="del-big-icon">
                                          <img
                                            src={DelBigIcon}
                                            alt="del-icon"
                                          />
                                        </div>
                                        <div>
                                          <p className="font-weight-bold blak-clr">
                                            {TranslationContext !== undefined
                                              ? TranslationContext.p.deletefile
                                              : "Delete file"}
                                            ?
                                          </p>
                                          <p className="mt-1 fs-12">
                                            {TranslationContext !== undefined
                                              ? TranslationContext.p
                                                .areyousureyouwanttodeletethisfile
                                              : "Are you sure you want to delete this file"}
                                            ?
                                          </p>
                                          <div className="del-can">
                                            <a href={Demo.BLANK_LINK}>
                                              {TranslationContext !== undefined
                                                ? TranslationContext.a.cancel
                                                : "CANCEL"}
                                            </a>
                                            <button
                                              className="butn"
                                              type="button"
                                              onClick={this.handleDeleteStore.bind(
                                                this,
                                                ids
                                              )}
                                            >
                                              {TranslationContext !== undefined
                                                ? TranslationContext.button
                                                  .delete
                                                : "Delete"}
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    }
                                    placement="bottom"
                                    trigger="click"
                                  >
                                    <img
                                      src={RedDeleteIcon}
                                      alt="del-icon"
                                      className="del-btn"
                                      id={ids}
                                    />
                                  </Popover>

                                  <button
                                    className="react-tabel-button ReNewBtn"
                                    type="button"
                                    onClick={this.handleEditStoreMasterData.bind(
                                      this,
                                      row.original
                                    )}
                                  >
                                    {TranslationContext !== undefined
                                      ? TranslationContext.mybutton.edit
                                      : "EDIT"}
                                  </button>
                                </span>
                              </>
                            );
                          },
                        },
                      ]}
                      resizable={false}
                      minRows={2}
                      defaultPageSize={10}
                      showPagination={true}
                    />
                  </div>
                )}
              </div>

              <div className="col-md-4">
                <div className="createHierarchyMask storeSettingcreateDiv">
                  <div className="createSpace">
                    <label className="Create-store-text">
                      {TranslationContext !== undefined
                        ? TranslationContext.label.createstore
                        : "CREATE STORE"}
                    </label>
                    {/* <div className="div-padding-1">
                      <label className="designation-name">Lpaas Store</label>
                      <input
                        type="text"
                        className="txt-1"
                        placeholder={"Enter Lpaas Store"}
                        maxLength={100}
                        name="LpaasStore"
                        value={
                          this.state.newStoreFiled
                            ? this.state.newStoreFiled.LpaasStore
                            : null
                        }
                        onChange={this.hanldeNewStoreChangeData}
                      />
                    </div>
                    <div className="div-padding-1">
                      <label className="designation-name">SKU Store</label>
                      <input
                        type="text"
                        className="txt-1"
                        placeholder={"Enter SKU Store"}
                        maxLength={100}
                        name="SKUStore"
                        value={this.state.newStoreFiled.SKUStore || null}
                        onChange={this.hanldeNewStoreChangeData}
                      />
                    </div> */}

                    {/* <div className="div-padding-1">
                      <label className="designation-name">
                        Alternate Store Code
                      </label>
                      <input
                        type="text"
                        className="txt-1"
                        placeholder={"Enter Alternate Store Code"}
                        maxLength={100}
                        name="AlternateStoreCode"
                        value={
                          this.state.newStoreFiled.AlternateStoreCode || null
                        }
                        onChange={this.hanldeNewStoreChangeData}
                      
                    </div>/> */}
                    <Collapse
                      accordion={true}
                      onChange={this.handleCollapseChange}
                      bordered={false}
                      activeKey={this.state.activeCollapse}
                    >
                      <Panel
                        key="1"
                        header={
                          <label className="designation-name">
                            Demographic
                          </label>
                        }
                        style={{ background: "#fff" }}
                      >
                        {demographicStoreDetails}
                      </Panel>
                      {/* </Collapse>
                    <Collapse bordered={false}> */}
                      <Panel
                        key="2"
                        header={
                          <label className="designation-name">
                            Geographical
                          </label>
                        }
                        style={{ background: "#fff" }}
                      >
                        {geographicalStoreDetails}
                      </Panel>
                      {/* </Collapse>
                    <Collapse bordered={false}> */}
                      <Panel
                        key="3"
                        header={
                          <label className="designation-name">
                            Store Functionality
                          </label>
                        }
                        style={{ background: "#fff" }}
                      >
                        {storeFunctonalityData}
                      </Panel>
                    </Collapse>
                    <div className="btnSpace">
                      <button
                        className="addBtn-ticket-hierarchy"
                        type="button"
                        onClick={this.handleSubmitData.bind(this)}
                      >
                        {TranslationContext !== undefined
                          ? TranslationContext.button.add
                          : "ADD"}
                      </button>
                    </div>
                  </div>
                </div>
                <br />
                <div className="right-sect-div">
                  <div className="d-flex justify-content-between align-items-center pb-2">
                    <h3 className="pb-0">
                      {TranslationContext !== undefined
                        ? TranslationContext.h3.bulkupload
                        : "Bulk Upload"}
                    </h3>
                    <div className="down-excel">
                      <p>
                        {TranslationContext !== undefined
                          ? TranslationContext.p.template
                          : "Template"}
                      </p>
                      <CSVLink
                        filename={"Store.csv"}
                        data={config.storeTemplate}
                      >
                        <img src={DownExcel} alt="download icon" />
                      </CSVLink>
                    </div>
                  </div>
                  <Spin
                    tip={
                      TranslationContext !== undefined
                        ? TranslationContext.tip.pleasewait
                        : "Please wait..."
                    }
                    spinning={this.state.bulkuploadLoading}
                  >
                    <div className="mainfileUpload">
                      <Dropzone onDrop={this.fileUpload.bind(this)}>
                        {({ getRootProps, getInputProps }) => (
                          <div {...getRootProps()}>
                            <input
                              {...getInputProps()}
                              className="file-upload d-none"
                            />
                            <div className="file-icon">
                              <img src={FileUpload} alt="file-upload" />
                            </div>
                            <span className={"fileupload-span"}>
                              {TranslationContext !== undefined
                                ? TranslationContext.span.addfile
                                : "Add File"}
                            </span>
                            {TranslationContext !== undefined
                              ? TranslationContext.div.or
                              : "or"}
                            {TranslationContext !== undefined
                              ? TranslationContext.div.dropfilehere
                              : "Drop File here"}
                          </div>
                        )}
                      </Dropzone>
                    </div>
                    {this.state.fileN.length === 0 && (
                      <p style={{ color: "red", marginBottom: "0px" }}>
                        {this.state.bulkuploadCompulsion}
                      </p>
                    )}
                    {this.state.fileName && (
                      <div className="file-info">
                        <div className="file-cntr">
                          <div className="file-dtls">
                            <p className="file-name">{this.state.fileName}</p>
                            <div className="del-file" id="del-file-1">
                              <img src={DelBlack} alt="delete-black" />
                            </div>
                            <UncontrolledPopover
                              trigger="legacy"
                              placement="auto"
                              target="del-file-1"
                              className="general-popover delete-popover"
                            >
                              <PopoverBody className="d-flex">
                                <div className="del-big-icon">
                                  <img src={DelBigIcon} alt="del-icon" />
                                </div>
                                <div>
                                  <p className="font-weight-bold blak-clr">
                                    {TranslationContext !== undefined
                                      ? TranslationContext.p.deletefile
                                      : "Delete file"}
                                    ?
                                  </p>
                                  <p className="mt-1 fs-12">
                                    {TranslationContext !== undefined
                                      ? TranslationContext.p
                                        .areyousureyouwanttodeletethisfile
                                      : "Are you sure you want to delete this file"}
                                    ?
                                  </p>
                                  <div className="del-can">
                                    <a href={Demo.BLANK_LINK}>
                                      {TranslationContext !== undefined
                                        ? TranslationContext.a.cancel
                                        : "CANCEL"}
                                    </a>
                                    <button
                                      className="butn"
                                      onClick={this.handleDeleteBulkupload}
                                    >
                                      {TranslationContext !== undefined
                                        ? TranslationContext.button.delete
                                        : "Delete"}
                                    </button>
                                  </div>
                                </div>
                              </PopoverBody>
                            </UncontrolledPopover>
                          </div>
                          <div>
                            <span className="file-size">
                              {this.state.fileSize}
                            </span>
                          </div>
                        </div>
                        {this.state.fileN.length > 0 &&
                          this.state.isFileUploadFail ? (
                          <div className="file-cntr">
                            <div className="file-dtls">
                              <p className="file-name">{this.state.fileName}</p>
                              <a
                                className="file-retry"
                                onClick={this.hanldeAddBulkUpload.bind(this)}
                              >
                                {TranslationContext !== undefined
                                  ? TranslationContext.span.retry
                                  : "Retry"}
                              </a>
                            </div>
                            <div>
                              <span className="file-failed">
                                {TranslationContext !== undefined
                                  ? TranslationContext.span.failed
                                  : "Failed"}
                              </span>
                            </div>
                          </div>
                        ) : null}
                        {this.state.showProgress ? (
                          <div className="file-cntr">
                            <div className="file-dtls">
                              <p className="file-name pr-0">
                                {this.state.fileName}
                              </p>
                            </div>
                            <div>
                              <div className="d-flex align-items-center mt-2">
                                <ProgressBar
                                  className="file-progress"
                                  now={this.state.progressValue}
                                />
                                <div className="cancel-upload">
                                  <img src={UploadCancel} alt="upload cancel" />
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : null}
                      </div>
                    )}
                    <button
                      className="butn"
                      onClick={this.hanldeAddBulkUpload.bind(this)}
                    >
                      {TranslationContext !== undefined
                        ? TranslationContext.button.add
                        : "ADD"}
                    </button>
                  </Spin>
                </div>
              </div>
            </div>
          </div>
          <Modal
            open={this.state.editmodel}
            onClose={this.toggleEditModal}
            modalId="storeEditModal"
          >
            <div>
              <Tabs
                onSelect={(index, label) => this.setState({ selTab: label })}
                selected={this.state.selTab}
              >
                <Tab
                  label={
                    TranslationContext !== undefined
                      ? TranslationContext.label.storedetails
                      : "Demographic"
                  }
                >
                  {/* <h4 style={{ textAlign: "center" }}>
                    {TranslationContext !== undefined
                      ? TranslationContext.h4.storedetails
                      : "Demographic Store Details"}
                  </h4> */}
                  <div className="row" style={{ padding: "0px 20px" }}>
                    <div className="col-md-6">
                      <div className="div-padding-1">
                        <label className="edit-label-1">
                          {TranslationContext !== undefined
                            ? TranslationContext.label.brand
                            : "Brand"}
                        </label>
                        <Select
                          getOptionLabel={(option) => option.brandName}
                          getOptionValue={(option) => option.brandID}
                          options={this.state.brandData}
                          placeholder={
                            TranslationContext !== undefined
                              ? TranslationContext.placeholder.select
                              : "Select"
                          }
                          name="brand_IDs"
                          // closeMenuOnSelect={false}
                          onChange={this.handleModalBrandChange}
                          value={this.state.modalSelectedBrand}
                          showNewOptionAtTop={false}
                        // isMulti
                        />
                        {this.state.modalSelectedBrand.length === 0 && (
                          <p style={{ color: "red", marginBottom: "0px" }}>
                            {this.state.editBrandValidation}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="pop-over-div">
                        <label className="edit-label-1 requried-field">
                          {TranslationContext !== undefined
                            ? TranslationContext.label.status
                            : "Status"}
                        </label>
                        <select
                          className="form-control dropdown-setting"
                          name="status_ID"
                          value={this.state.userEditData.status_ID}
                          onChange={this.handleModalEditData}
                        >
                          <option value={null}>
                            {TranslationContext !== undefined
                              ? TranslationContext.option.select
                              : "select"}
                          </option>
                          <option value={true}>
                            {TranslationContext !== undefined
                              ? TranslationContext.option.active
                              : "Active"}
                          </option>
                          <option value={false}>
                            {TranslationContext !== undefined
                              ? TranslationContext.option.inactive
                              : "Inactive"}
                          </option>
                        </select>
                        {this.state.userEditData.status_ID === "0" && (
                          <p style={{ color: "red", marginBottom: "0px" }}>
                            {this.state.editStatusValidation}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="pop-over-div">
                        <label className="edit-label-1 requried-field">
                          {TranslationContext !== undefined
                            ? TranslationContext.label.storename
                            : "Store Name"}
                        </label>
                        <input
                          type="text"
                          className="txt-1"
                          placeholder={
                            TranslationContext !== undefined
                              ? TranslationContext.placeholder.enterstorename
                              : "Enter Store Name"
                          }
                          name="store_Name"
                          maxLength={100}
                          value={this.state.userEditData.store_Name}
                          onChange={this.handleModalEditData}
                        />
                        {this.state.userEditData.store_Name === "" && (
                          <p style={{ color: "red", marginBottom: "0px" }}>
                            {this.state.editStoreNameValidation}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="pop-over-div">
                        <label className="edit-label-1 requried-field">
                          {TranslationContext !== undefined
                            ? TranslationContext.label.storecode
                            : "Store Code"}
                        </label>
                        <input
                          type="text"
                          className="txt-1"
                          placeholder={
                            TranslationContext !== undefined
                              ? TranslationContext.placeholder.enterstorecode
                              : "Enter Store Code"
                          }
                          name="store_Code"
                          value={this.state.userEditData.store_Code}
                          onChange={this.handleModalEditData}
                        />
                        {this.state.userEditData.store_Code === "" && (
                          <p style={{ color: "red", marginBottom: "0px" }}>
                            {this.state.editStoreCodeValidation}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="pop-over-div">
                        <label className="edit-label-1 requried-field">Store Contact</label>
                        <input
                          type="text"
                          className="txt-1"
                          placeholder={
                            TranslationContext !== undefined
                              ? TranslationContext.placeholder.enterphoneno
                              : "Enter Contact Number"
                          }
                          name="phoneNumber_"
                          maxLength={14}
                          value={this.state.userEditData.phoneNumber_}
                          onChange={this.hanldeOnPhoneChange}
                        />
                        {this.state.EditPhoneFlag === false && (
                          <p style={{ color: "red", marginBottom: "0px" }}>
                            {TranslationContext !== undefined
                              ? TranslationContext.ticketingDashboard
                                .pleaseentervalidphonenumber
                              : "Please enter valid Phone Number."}
                          </p>
                        )}
                        {this.state.userEditData.phoneNumber_ === "" && (
                          <p style={{ color: "red", marginBottom: "0px" }}>
                            {this.state.editContactPhoneValidation}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="pop-over-div">
                        <label className="edit-label-1 requried-field">
                          Store Email</label>
                        <input
                          type="text"
                          className="txt-1"
                          placeholder={
                            TranslationContext !== undefined
                              ? TranslationContext.placeholder.enteremailid
                              : "Enter Email id"
                          }
                          name="email_"
                          maxLength={100}
                          value={this.state.userEditData.email_}
                          onChange={this.handleModalEditData}
                        />
                        {this.state.EditEmailFlag === false && (
                          <p style={{ color: "red", marginBottom: "0px" }}>
                            Please enter valid Email Id.
                          </p>
                        )}
                        {this.state.userEditData.email_ === "" && (
                          <p style={{ color: "red", marginBottom: "0px" }}>
                            {this.state.editContactEmailValidation}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="pop-over-div">
                        <label className="edit-label-1 requried-field">
                          Manager Contact
                        </label>
                        <input
                          maxLength={14}
                          type="text"
                          className="txt-1"
                          placeholder={"Enter Store Manager Mobile No"}
                          name="storeManagerMobile"
                          value={this.state.userEditData.storeManagerMobile}
                          onChange={this.handleEditNewStoreChange.bind(this)}
                        />
                        {this.state.userEditData.storeManagerMobile === "" && (
                          <p style={{ color: "red", marginBottom: "0px" }}>
                            {this.state.editstoreManagerMobileValidation}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="pop-over-div">
                        <label className="edit-label-1 requried-field">
                          Manager Name
                        </label>
                        <input
                          type="text"
                          className="txt-1"
                          placeholder={"Enter Store Manager Name"}
                          name="storeManagerName"
                          value={this.state.userEditData.storeManagerName}
                          onChange={this.handleEditNewStoreChange.bind(this)}
                        />
                        {this.state.userEditData.storeManagerName === "" && (
                          <p style={{ color: "red", marginBottom: "0px" }}>
                            {this.state.editstoreManagerNameValidation}
                          </p>
                        )}
                      </div>
                    </div>
                    {/* <div className="col-md-6">
                      <div className="pop-over-div">
                        <label className="edit-label-1">
                          {TranslationContext !== undefined
                            ? TranslationContext.label.storetype
                            : "Store Type"}
                        </label>
                        <select
                          className="store-create-select"
                          name="storeType_ID"
                          value={this.state.userEditData.storeType_ID}
                          onChange={this.handleModalEditData}
                        >
                          <option value={0}>
                            {TranslationContext !== undefined
                              ? TranslationContext.option.select
                              : "Select"}
                          </option>
                          {this.state.storeTypeData !== null &&
                            this.state.storeTypeData.map((item, t) => (
                              <option key={t} value={item.storeTypeID}>
                                {item.storeTypeName}
                              </option>
                            ))}
                        </select>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="pop-over-div">
                        <label className="edit-label-1">Designation</label>
                        <input
                          type="text"
                          className="txt-1"
                          placeholder={"Enter Designation"}
                          name="designation"
                          value={this.state.userEditData.designation}
                          onChange={this.handleEditNewStoreChange.bind(this)}
                        />
                      </div>
                    </div> */}
                    {/* <div className="col-md-6">
                      <div className="pop-over-div">
                        <label className="edit-label-1">
                          Shopping Assissted
                        </label>
                        <input
                          type="text"
                          className="txt-1"
                          placeholder={"Enter Shopping Assissted"}
                          name="shoppingassissted"
                          value={this.state.userEditData.shoppingassissted}
                          onChange={this.handleEditNewStoreChange.bind(this)}
                        />
                      </div>
                    </div> */}
                    <div className="col-md-6">
                      <div className="pop-over-div">
                        <label className="edit-label-1">Open Time</label>

                        <TimePicker
                          style={{ display: "inherit", width: "100%" }}
                          value={
                            this.state.userEditData.openingTime
                              ? moment(
                                this.state.userEditData.openingTime,
                                "HH:mm:ss"
                              )
                              : null
                          }
                          format="HH:mm:ss"
                          onChange={this.hanldeEditNewStoreChangeDate.bind(
                            this,
                            "openingTime"
                          )}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="pop-over-div">
                        <label className="edit-label-1">Close Time</label>
                        <TimePicker
                          style={{ display: "inherit", width: "100%" }}
                          value={
                            this.state.userEditData.closingTime
                              ? moment(
                                this.state.userEditData.closingTime,
                                "HH:mm:ss"
                              )
                              : null
                          }
                          format="HH:mm:ss"
                          onChange={this.hanldeEditNewStoreChangeDate.bind(
                            this,
                            "closingTime"
                          )}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="pop-over-div">
                        <label className="edit-label-1">Start Date</label>

                        <DatePicker
                          style={{ display: "inherit", width: "100%" }}
                          placeholderText="Select Start Date"
                          format="YYYY-MM-DD"
                          value={
                            this.state.userEditData.startDate
                              ? moment(
                                this.state.userEditData.startDate,
                                "DD-MM-YYYY hh:mm:ss"
                              )
                              : null
                          }
                          onChange={this.hanldeEditNewStoreChangeDate.bind(
                            this,
                            "startDate"
                          )}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="pop-over-div">
                        <label className="edit-label-1">End Date</label>

                        <DatePicker
                          style={{ display: "inherit", width: "100%" }}
                          placeholderText="Select Start Date"
                          format="YYYY-MM-DD"
                          value={
                            this.state.userEditData.endDate
                              ? moment(
                                this.state.userEditData.endDate,
                                "DD-MM-YYYY hh:mm:ss"
                              )
                              : null
                          }
                          onChange={this.hanldeEditNewStoreChangeDate.bind(
                            this,
                            "endDate"
                          )}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="pop-over-div">
                        <label className="edit-label-1">Open Days</label>
                        <input
                          type="text"
                          className="txt-1"
                          placeholder={"Enter Open Days"}
                          name="openDays"
                          value={this.state.userEditData.openDays}
                          onChange={this.handleEditNewStoreChange.bind(this)}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="pop-over-div">
                        <label className="edit-label-1">Day Status</label>
                        <select
                          className="store-create-select"
                          placeholder="Select Day Status"
                          name="dayStatus"
                          value={this.state.userEditData.dayStatus}
                          onChange={this.handleEditNewStoreChange.bind(this)}
                        >
                          <option>Select</option>
                          <option value="open">Open</option>
                          <option value="closed">Closed</option>
                        </select>
                      </div>
                    </div>

                    {/* <div className="col-md-6">
                      <div className="pop-over-div">
                        <label className="edit-label-1">Store Timing</label>
                        <input
                          type="text"
                          className="txt-1"
                          placeholder={"Enter Store Timing"}
                          name="storetiming"
                          value={this.state.userEditData.storetiming}
                          onChange={this.handleEditNewStoreChange.bind(this)}
                          disabled
                        />
                      </div>
                    </div> */}

                    {/* <div className="col-md-6">
                      <div className="pop-over-div">
                        <label className="edit-label-1">Store Value</label>
                        <input
                          type="text"
                          className="txt-1"
                          placeholder={"Enter Store Value"}
                          name="storevalue"
                          value={this.state.userEditData.storevalue}
                          onChange={this.handleEditNewStoreChange.bind(this)}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="pop-over-div">
                        <label className="edit-label-1">Store Ownership</label>
                        <input
                          type="text"
                          className="txt-1"
                          placeholder={"Enter Store Ownership"}
                          name="storeOwnership"
                          value={this.state.userEditData.storeOwnership}
                          onChange={this.handleEditNewStoreChange.bind(this)}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="pop-over-div">
                        <label className="edit-label-1">Store Channel</label>
                        <input
                          type="text"
                          className="txt-1"
                          placeholder={"Enter Store Channel"}
                          name="storeChannel"
                          value={this.state.userEditData.storeChannel}
                          onChange={this.handleEditNewStoreChange.bind(this)}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="pop-over-div">
                        <label className="edit-label-1">Tier</label>
                        <input
                          type="text"
                          className="txt-1"
                          placeholder={"Enter Tier"}
                          name="tier"
                          value={this.state.userEditData.tier}
                          onChange={this.handleEditNewStoreChange.bind(this)}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="pop-over-div">
                        <label className="edit-label-1">Store GSTNo</label>
                        <input
                          type="text"
                          className="txt-1"
                          placeholder={"Enter Store GSTNo"}
                          name="storeGSTNo"
                          value={this.state.userEditData.storeGSTNo}
                          onChange={this.handleEditNewStoreChange.bind(this)}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="pop-over-div">
                        <label className="edit-label-1">Store CIN</label>
                        <input
                          type="text"
                          className="txt-1"
                          placeholder={"Enter Store CIN"}
                          name="storeCIN"
                          value={this.state.userEditData.storeCIN}
                          onChange={this.handleEditNewStoreChange.bind(this)}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="pop-over-div">
                        <label className="edit-label-1">Store Pan</label>
                        <input
                          type="text"
                          className="txt-1"
                          placeholder={"Enter Store Pan"}
                          name="storePan"
                          value={this.state.userEditData.storePan}
                          onChange={this.handleEditNewStoreChange.bind(this)}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="pop-over-div">
                        <label className="edit-label-1">Pickup Location</label>
                        <input
                          type="text"
                          className="txt-1"
                          placeholder={"Enter PickupLocation"}
                          maxLength={100}
                          name="pickupLocation"
                          value={this.state.userEditData.pickupLocation || null}
                          onChange={this.handleEditNewStoreChange.bind(this)}
                        />
                      </div>
                    </div> */}

                    <div className="col-md-12">
                      <div className="text-center mt-3">
                        <div
                          style={{
                            textAlign: "center",
                            margin: "20px 0px 0px 0px",
                          }}
                        >
                          <a
                            className="pop-over-cancle canblue"
                            onClick={this.closeEditModals.bind(this)}
                          >
                            {TranslationContext !== undefined
                              ? TranslationContext.a.cancel
                              : "CANCEL"}
                          </a>
                          <button
                            className="Save-Use"
                            onClick={this.HandlecheckDemographicStoreDetails.bind(
                              this
                            )}
                            style={{ marginLeft: "30px" }}
                          >
                            {TranslationContext !== undefined
                              ? TranslationContext.button.next
                              : "NEXT"}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab>
                <Tab
                  label={
                    TranslationContext !== undefined
                      ? TranslationContext.label.storedetails
                      : "Geographical"
                  }
                >
                  <div className="row" style={{ padding: "0px 20px" }}>
                    <div className="col-md-6">
                      <div className="div-cntr cus-drp">
                        <label className="edit-label-1 requried-field">
                          {TranslationContext !== undefined
                            ? TranslationContext.label.storename
                            : "State"}
                        </label>
                        <select
                          className="store-create-select"
                          name="state_ID"
                          value={this.state.userEditData.state_ID}
                          onChange={this.handleModalEditData}
                        >
                          <option value={0}>
                            {TranslationContext !== undefined
                              ? TranslationContext.option.select
                              : "Select"}
                          </option>
                          {this.state.stateData !== null &&
                            this.state.stateData.map((item, i) => (
                              <option
                                key={i}
                                value={item.stateID}
                                className="select-category-placeholder"
                              >
                                {item.stateName}
                              </option>
                            ))}
                        </select>
                        {this.state.userEditData.state_ID === "0" && (
                          <p style={{ color: "red", marginBottom: "0px" }}>
                            {this.state.editStateValidation}
                          </p>
                        )}
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="div-cntr cus-drp">
                        <label className="edit-label-1 requried-field">
                          {TranslationContext !== undefined
                            ? TranslationContext.label.city
                            : "City"}
                        </label>
                        <select
                          className="edit-dropDwon dropdown-setting"
                          name="city_ID"
                          value={this.state.userEditData.city_ID}
                          onChange={this.handleModalEditData}
                        >
                          <option value={0}>
                            {TranslationContext !== undefined
                              ? TranslationContext.option.select
                              : "Select"}
                          </option>
                          {this.state.cityData !== null &&
                            this.state.cityData.map((item, i) => (
                              <option
                                key={i}
                                value={item.cityID}
                                className="select-category-placeholder"
                              >
                                {item.cityName}
                              </option>
                            ))}
                        </select>
                        {this.state.userEditData.city_ID === "0" && (
                          <p style={{ color: "red", marginBottom: "0px" }}>
                            {this.state.editCityValidation}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className=".pop-over-div1">
                        <label className="edit-label-1">
                          {TranslationContext !== undefined
                            ? TranslationContext.label.address
                            : "Address"}
                        </label>
                        <textarea
                          cols="31"
                          rows="3"
                          className="store-create-textarea"
                          placeholder={
                            TranslationContext !== undefined
                              ? TranslationContext.placeholder.enteraddress
                              : "Enter address"
                          }
                          name="address_"
                          maxLength={250}
                          value={this.state.userEditData.address_}
                          onChange={this.handleModalEditData}
                        ></textarea>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="pop-over-div">
                        <label className="edit-label-1 requried-field">
                          {TranslationContext !== undefined
                            ? TranslationContext.label.region
                            : "Region"}
                        </label>
                        <select
                          className="store-create-select"
                          name="region_ID"
                          value={this.state.userEditData.region_ID}
                          onChange={this.handleModalEditData}
                        >
                          <option value={0}>
                            {TranslationContext !== undefined
                              ? TranslationContext.option.select
                              : "select"}
                          </option>
                          {this.state.regionData !== null &&
                            this.state.regionData.map((item, s) => (
                              <option key={s} value={item.regionID}>
                                {item.regionName}
                              </option>
                            ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="div-cntr cus-drp">
                        <label className="edit-label-1 requried-field">
                          {TranslationContext !== undefined
                            ? TranslationContext.label.pincode
                            : "PinCode"}
                        </label>
                        <input
                          type="text"
                          className="txt-1"
                          placeholder={
                            TranslationContext !== undefined
                              ? TranslationContext.placeholder.enterpincode
                              : "Enter Pin Code"
                          }
                          name="strPin_Code"
                          maxLength={11}
                          value={this.state.userEditData.strPin_Code}
                          onChange={this.handleModalEditData}
                        />
                        {this.state.userEditData.strPin_Code === "" && (
                          <p style={{ color: "red", marginBottom: "0px" }}>
                            {this.state.editPinCodeValidation}
                          </p>
                        )}
                      </div>
                    </div>
                    {/* <div className="col-md-6">
                      <div className="pop-over-div">
                        <label className="edit-label-1">
                          {TranslationContext !== undefined
                            ? TranslationContext.label.zone
                            : "Zone"}
                        </label>
                        <select
                          className="store-create-select"
                          name="zone_ID"
                          value={this.state.userEditData.zone_ID}
                          onChange={this.handleModalEditData}
                        >
                          <option value={0}>
                            {TranslationContext !== undefined
                              ? TranslationContext.option.select
                              : "select"}
                          </option>
                          {this.state.zoneData !== null &&
                            this.state.zoneData.map((item, s) => (
                              <option key={s} value={item.zoneID}>
                                {item.zoneName}
                              </option>
                            ))}
                        </select>
                      </div>
                    </div> */}

                    <div className="col-md-6">
                      <div className="pop-over-div">
                        <label className="edit-label-1 requried-field">
                          Latitude</label>
                        <input
                          type="text"
                          className="txt-1"
                          placeholder={"Enter Latitude"}
                          name="latitude"
                          value={this.state.userEditData.latitude}
                          onChange={this.handleEditNewStoreChange.bind(this)}
                        />
                        {!this.state.userEditData.latitude && (
                          <p style={{ color: "red", marginBottom: "0px" }}>
                            {this.state.editlatitudeValidation}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="pop-over-div">
                        <label className="edit-label-1 requried-field">
                          Longitude</label>
                        <input
                          type="text"
                          className="txt-1"
                          placeholder={"Enter Longitude"}
                          name="longitude"
                          value={this.state.userEditData.longitude}
                          onChange={this.handleEditNewStoreChange.bind(this)}
                        />
                        {!this.state.userEditData.longitude && (
                          <p style={{ color: "red", marginBottom: "0px" }}>
                            {this.state.editlongitudeValidation}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="pop-over-div">
                        <label className="edit-label-1 requried-field">
                          Time Zone</label>
                        <div>
                          <AsyncSelect
                            getOptionLabel={(option) => option.text}
                            getOptionValue={(option) => option.value}
                            cacheOptions
                            loadOptions={loadOptions}
                            defaultOptions
                            onInputChange={this.handleInputChange}
                            onChange={this.handleEditTimeZoneChange.bind(this)}
                            value={this.state.userEditData.timeZoneID}
                          />
                          {!this.state.userEditData.timeZoneID && (
                            <p style={{ color: "red", marginBottom: "0px" }}>
                              {this.state.editTimeZoneValidation}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="text-center mt-3">
                        <div
                          style={{
                            textAlign: "center",
                            margin: "20px 0px 0px 0px",
                          }}
                        >
                          <a
                            className="pop-over-cancle canblue"
                            onClick={this.HandleGeographicalBackBtn.bind(this)}
                          >
                            {TranslationContext !== undefined
                              ? TranslationContext.a.cancel
                              : "BACK"}
                          </a>
                          <button
                            className="Save-Use"
                            onClick={this.HandlecheckGeographicalStoreDetails.bind(
                              this
                            )}
                            style={{ marginLeft: "30px" }}
                          >
                            {TranslationContext !== undefined
                              ? TranslationContext.button.next
                              : "NEXT"}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab>
                <Tab
                  label={
                    TranslationContext !== undefined
                      ? TranslationContext.label.storedetails
                      : "Store Functionality"
                  }
                >
                  <div className="row" style={{ padding: "0px 20px" }}>
                    <div className="col-md-6">
                      <div className="pop-over-div">
                        <label className="edit-label-1">
                          <Checkbox
                            name="isWhatsApp"
                            onChange={this.handleEditNewStoreChange}
                            checked={this.state.userEditData.isWhatsApp}
                          >
                            WhatsApp
                          </Checkbox>
                        </label>
                        {/* {!this.state.userEditData.isWhatsApp && (
                          <p style={{ color: "red", marginBottom: "0px" }}>
                            {this.state.editIsWhatsAppValidation}
                          </p>
                        )} */}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="pop-over-div">
                        <label className="edit-label-1">
                          <Checkbox
                            name="isStorePayEnable"
                            onChange={this.handleEditNewStoreChange}
                            checked={this.state.userEditData.isStorePayEnable ? true : false}
                          >
                            StorePayEnable
                          </Checkbox>
                        </label>
                        {/* {!this.state.userEditData.isStorePayEnable && (
                          <p style={{ color: "red", marginBottom: "0px" }}>
                            {this.state.editIsStorePayEnableValidation}
                          </p>
                        )} */}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="pop-over-div">
                        <label className="edit-label-1">
                          {" "}
                          <Checkbox
                            name="isBell"
                            onChange={this.handleEditNewStoreChange}
                            checked={this.state.userEditData.isBell}
                          >
                            Bell
                          </Checkbox>
                        </label>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="pop-over-div">
                        <label className="edit-label-1">
                          <Checkbox
                            name="isWebBot"
                            onChange={this.handleEditNewStoreChange}
                            checked={this.state.userEditData.isWebBot}
                          >
                            WebBot
                          </Checkbox>
                        </label>
                      </div>
                    </div>
                    {/* <div className="col-md-6">
                      <div className="pop-over-div">
                        <label className="edit-label-1">
                          <Checkbox
                            name="isShoppingBag"
                            onChange={this.handleEditNewStoreChange}
                            checked={this.state.userEditData.isShoppingBag}
                          >
                            ShoppingBag
                          </Checkbox>
                        </label>
                      </div>
                    </div> */}
                    <div className="col-md-6">
                      <div className="pop-over-div">
                        <label className="edit-label-1">
                          <Checkbox
                            name="isERPayment"
                            onChange={this.handleEditNewStoreChange}
                            checked={this.state.userEditData.isERPayment}
                          >
                            ERPayment
                          </Checkbox>
                        </label>
                      </div>
                    </div>
                    {/* <div className="col-md-6">
                      <div className="pop-over-div">
                        <label className="edit-label-1">
                          <Checkbox
                            name="isERShipping"
                            onChange={this.handleEditNewStoreChange}
                            checked={this.state.userEditData.isERShipping}
                          >
                            ERShipping
                          </Checkbox>
                        </label>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="pop-over-div">
                        <label className="edit-label-1">
                          <Checkbox
                            name="isOrderSync"
                            onChange={this.handleEditNewStoreChange}
                            checked={this.state.userEditData.isOrderSync}
                          >
                            OrderSync
                          </Checkbox>
                        </label>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="pop-over-div">
                        <label className="edit-label-1">
                          <Checkbox
                            name="isSendToShipmentManual"
                            onChange={this.handleEditNewStoreChange}
                            checked={
                              this.state.userEditData.isSendToShipmentManual
                            }
                          >
                            SendToShipmentManual
                          </Checkbox>
                        </label>
                      </div>
                    </div> */}
                    {/* <div className="col-md-6">
                      <div className="pop-over-div">
                        <label className="edit-label-1">
                          <Checkbox
                            name="isDigitalReceipt"
                            onChange={this.handleEditNewStoreChange}
                            checked={this.state.userEditData.isDigitalReceipt}
                          >
                            IsDigitalReceipt
                          </Checkbox>
                        </label>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="pop-over-div">
                        <label className="edit-label-1">
                          <Checkbox
                            name="isStateHub"
                            onChange={this.handleEditNewStoreChange}
                            checked={this.state.userEditData.isStateHub}
                          >
                            IsStateHub
                          </Checkbox>
                        </label>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="pop-over-div">
                        <label className="edit-label-1">
                          <Checkbox
                            name="isCityHub"
                            onChange={this.handleEditNewStoreChange}
                            checked={this.state.userEditData.isCityHub}
                          >
                            IsCityHub
                          </Checkbox>
                        </label>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="pop-over-div">
                        <label className="edit-label-1">
                          <Checkbox
                            name="isBrandHub"
                            onChange={this.handleEditNewStoreChange}
                            checked={this.state.userEditData.isBrandHub}
                          >
                            IsBrandHub
                          </Checkbox>
                        </label>
                      </div>
                    </div> */}
                    <div className="col-md-6">
                      <div className="pop-over-div">
                        <label className="edit-label-1">
                          <Checkbox
                            name="isVideoCallEnable"
                            onChange={this.handleEditNewStoreChange}
                            checked={this.state.userEditData.isVideoCallEnable}
                          >
                            VideoCallEnable
                          </Checkbox>
                        </label>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="text-center mt-3">
                        <span
                          className="pop-over-cancle"
                          onClick={this.toggleEditModal}
                        >
                          {TranslationContext !== undefined
                            ? TranslationContext.button.cancel
                            : "CANCEL"}
                        </span>
                        <button
                          className="pop-over-button FlNone"
                          onClick={this.handleUpdateData.bind(this)}
                        >
                          <label className="pop-over-btnsave-text">
                            {this.state.editSaveLoading ? (
                              <FontAwesomeIcon
                                className="circular-loader"
                                icon={faCircleNotch}
                                spin
                              />
                            ) : (
                              ""
                            )}
                            {TranslationContext !== undefined
                              ? TranslationContext.button.save
                              : "SAVE"}
                          </label>
                        </button>
                      </div>
                    </div>
                  </div>
                </Tab>
              </Tabs>
            </div>
            {/* <div className="edtpadding">
              <label className="popover-header-text">
                {TranslationContext !== undefined
                  ? TranslationContext.label.editstore
                  : "EDIT STORE"}
              </label>
              <div className="row"> */}
            {/* <div className="col-md-6">
                  <div className="div-padding-1">
                    <label className="edit-label-1">
                      {TranslationContext !== undefined
                        ? TranslationContext.label.brand
                        : "Brand"}
                    </label>
                    <Select
                      getOptionLabel={(option) => option.brandName}
                      getOptionValue={(option) => option.brandID}
                      options={this.state.brandData}
                      placeholder={
                        TranslationContext !== undefined
                          ? TranslationContext.placeholder.select
                          : "Select"
                      }
                      name="brand_IDs"
                      closeMenuOnSelect={false}
                      onChange={this.handleModalBrandChange}
                      value={this.state.modalSelectedBrand}
                      showNewOptionAtTop={false}
                      isMulti
                    />
                    {this.state.modalSelectedBrand.length === 0 && (
                      <p style={{ color: "red", marginBottom: "0px" }}>
                        {this.state.editBrandValidation}
                      </p>
                    )}
                  </div>
                </div> */}
            {/* <div className="col-md-6">
                  <div className="pop-over-div">
                    <label className="edit-label-1">
                      {TranslationContext !== undefined
                        ? TranslationContext.label.storecode
                        : "Store Code"}
                    </label>
                    <input
                      type="text"
                      className="txt-1"
                      placeholder={
                        TranslationContext !== undefined
                          ? TranslationContext.placeholder.enterstorecode
                          : "Enter Store Code"
                      }
                      name="store_Code"
                      maxLength={10}
                      value={this.state.userEditData.store_Code}
                      onChange={this.handleModalEditData}
                    />
                    {this.state.userEditData.store_Code === "" && (
                      <p style={{ color: "red", marginBottom: "0px" }}>
                        {this.state.editStoreCodeValidation}
                      </p>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="pop-over-div">
                    <label className="edit-label-1">
                      {TranslationContext !== undefined
                        ? TranslationContext.label.storename
                        : "Store Name"}
                    </label>
                    <input
                      type="text"
                      className="txt-1"
                      placeholder={
                        TranslationContext !== undefined
                          ? TranslationContext.placeholder.enterstorename
                          : "Enter Store Name"
                      }
                      name="store_Name"
                      maxLength={100}
                      value={this.state.userEditData.store_Name}
                      onChange={this.handleModalEditData}
                    />
                    {this.state.userEditData.store_Name === "" && (
                      <p style={{ color: "red", marginBottom: "0px" }}>
                        {this.state.editStoreNameValidation}
                      </p>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="pop-over-div">
                    <label className="edit-label-1">
                      {TranslationContext !== undefined
                        ? TranslationContext.label.storename
                        : "State"}
                    </label>
                    <select
                      className="store-create-select"
                      name="state_ID"
                      value={this.state.userEditData.state_ID}
                      onChange={this.handleModalEditData}
                    >
                      <option value={0}>
                        {TranslationContext !== undefined
                          ? TranslationContext.option.select
                          : "Select"}
                      </option>
                      {this.state.stateData !== null &&
                        this.state.stateData.map((item, i) => (
                          <option
                            key={i}
                            value={item.stateID}
                            className="select-category-placeholder"
                          >
                            {item.stateName}
                          </option>
                        ))}
                    </select>
                    {this.state.userEditData.state_ID === "0" && (
                      <p style={{ color: "red", marginBottom: "0px" }}>
                        {this.state.editStateValidation}
                      </p>
                    )}
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="pop-over-div">
                    <label className="edit-label-1">
                      {TranslationContext !== undefined
                        ? TranslationContext.label.city
                        : "City"}
                    </label>
                    <select
                      className="edit-dropDwon dropdown-setting"
                      name="city_ID"
                      value={this.state.userEditData.city_ID}
                      onChange={this.handleModalEditData}
                    >
                      <option value={0}>
                        {TranslationContext !== undefined
                          ? TranslationContext.option.select
                          : "Select"}
                      </option>
                      {this.state.cityData !== null &&
                        this.state.cityData.map((item, i) => (
                          <option
                            key={i}
                            value={item.cityID}
                            className="select-category-placeholder"
                          >
                            {item.cityName}
                          </option>
                        ))}
                    </select>
                    {this.state.userEditData.city_ID === "0" && (
                      <p style={{ color: "red", marginBottom: "0px" }}>
                        {this.state.editCityValidation}
                      </p>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="pop-over-div">
                    <label className="edit-label-1">
                      {TranslationContext !== undefined
                        ? TranslationContext.label.pincode
                        : "Pin Code"}
                    </label>
                    <input
                      type="text"
                      className="txt-1"
                      placeholder={
                        TranslationContext !== undefined
                          ? TranslationContext.placeholder.enterpincode
                          : "Enter Pin Code"
                      }
                      name="strPin_Code"
                      maxLength={11}
                      value={this.state.userEditData.strPin_Code}
                      onChange={this.handleModalEditData}
                    />
                    {this.state.userEditData.strPin_Code === "" && (
                      <p style={{ color: "red", marginBottom: "0px" }}>
                        {this.state.editPinCodeValidation}
                      </p>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="pop-over-div">
                    <label className="edit-label-1">
                      {TranslationContext !== undefined
                        ? TranslationContext.label.status
                        : "Status"}
                    </label>
                    <select
                      className="form-control dropdown-setting"
                      name="status_ID"
                      value={this.state.userEditData.status_ID}
                      onChange={this.handleModalEditData}
                    >
                      <option value={null}>
                        {TranslationContext !== undefined
                          ? TranslationContext.option.select
                          : "select"}
                      </option>
                      <option value={true}>
                        {TranslationContext !== undefined
                          ? TranslationContext.option.active
                          : "Active"}
                      </option>
                      <option value={false}>
                        {TranslationContext !== undefined
                          ? TranslationContext.option.inactive
                          : "Inactive"}
                      </option>
                    </select>
                    {this.state.userEditData.status_ID === "0" && (
                      <p style={{ color: "red", marginBottom: "0px" }}>
                        {this.state.editStatusValidation}
                      </p>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="pop-over-div">
                    <label className="edit-label-1">
                      {TranslationContext !== undefined
                        ? TranslationContext.label.region
                        : "Region"}
                    </label>
                    <select
                      className="store-create-select"
                      name="region_ID"
                      value={this.state.userEditData.region_ID}
                      onChange={this.handleModalEditData}
                    >
                      <option value={0}>
                        {TranslationContext !== undefined
                          ? TranslationContext.option.select
                          : "select"}
                      </option>
                      {this.state.regionData !== null &&
                        this.state.regionData.map((item, s) => (
                          <option key={s} value={item.regionID}>
                            {item.regionName}
                          </option>
                        ))}
                    </select>
                    {this.state.userEditData.region_ID === "0" && (
                      <p style={{ color: "red", marginBottom: "0px" }}>
                        {this.state.editRegionValidation}
                      </p>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="pop-over-div">
                    <label className="edit-label-1">
                      {TranslationContext !== undefined
                        ? TranslationContext.label.zone
                        : "Zone"}
                    </label>
                    <select
                      className="store-create-select"
                      name="zone_ID"
                      value={this.state.userEditData.zone_ID}
                      onChange={this.handleModalEditData}
                    >
                      <option value={0}>
                        {TranslationContext !== undefined
                          ? TranslationContext.option.select
                          : "select"}
                      </option>
                      {this.state.zoneData !== null &&
                        this.state.zoneData.map((item, s) => (
                          <option key={s} value={item.zoneID}>
                            {item.zoneName}
                          </option>
                        ))}
                    </select>
                    {this.state.userEditData.zone_ID === "0" && (
                      <p style={{ color: "red", marginBottom: "0px" }}>
                        {this.state.editZoneValidation}
                      </p>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="pop-over-div">
                    <label className="edit-label-1">
                      {TranslationContext !== undefined
                        ? TranslationContext.label.storetype
                        : "Store Type"}
                    </label>
                    <select
                      className="store-create-select"
                      name="storeType_ID"
                      value={this.state.userEditData.storeType_ID}
                      onChange={this.handleModalEditData}
                    >
                      <option value={0}>
                        {TranslationContext !== undefined
                          ? TranslationContext.option.select
                          : "Select"}
                      </option>
                      {this.state.storeTypeData !== null &&
                        this.state.storeTypeData.map((item, t) => (
                          <option key={t} value={item.storeTypeID}>
                            {item.storeTypeName}
                          </option>
                        ))}
                    </select>
                    {this.state.userEditData.storeType_ID === "0" && (
                      <p style={{ color: "red", marginBottom: "0px" }}>
                        {this.state.editStoreTypeValidation}
                      </p>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="pop-over-div">
                    <label className="edit-label-1">
                      {TranslationContext !== undefined
                        ? TranslationContext.label.contactdetailsemails
                        : "Contact Details: Email ID"}
                    </label>
                    <input
                      type="text"
                      className="txt-1"
                      placeholder={
                        TranslationContext !== undefined
                          ? TranslationContext.placeholder.enteremailid
                          : "Enter email id"
                      }
                      name="email_"
                      maxLength={100}
                      value={this.state.userEditData.email_}
                      onChange={this.handleModalEditData}
                    />
                    {this.state.EditEmailFlag === false && (
                      <p style={{ color: "red", marginBottom: "0px" }}>
                        Please enter valid Email Id.
                      </p>
                    )}
                    {this.state.userEditData.email_ === "" && (
                      <p style={{ color: "red", marginBottom: "0px" }}>
                        {this.state.editContactEmailValidation}
                      </p>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="pop-over-div">
                    <label className="edit-label-1">
                      {TranslationContext !== undefined
                        ? TranslationContext.label.contactdetailsphones
                        : "Contact Details: Phone No"}
                    </label>
                    <input
                      type="text"
                      className="txt-1"
                      placeholder={
                        TranslationContext !== undefined
                          ? TranslationContext.placeholder.enterphoneno
                          : "Enter phone no"
                      }
                      name="phoneNumber_"
                      maxLength={10}
                      value={this.state.userEditData.phoneNumber_}
                      onChange={this.hanldeOnPhoneChange}
                    />
                    {this.state.EditPhoneFlag === false && (
                      <p style={{ color: "red", marginBottom: "0px" }}>
                        {TranslationContext !== undefined
                          ? TranslationContext.ticketingDashboard
                              .pleaseentervalidphonenumber
                          : "Please enter valid Phone Number."}
                      </p>
                    )}
                    {this.state.userEditData.phoneNumber_ === "" && (
                      <p style={{ color: "red", marginBottom: "0px" }}>
                        {this.state.editContactPhoneValidation}
                      </p>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="pop-over-div">
                    <label className="edit-label-1">
                      {TranslationContext !== undefined
                        ? TranslationContext.label.address
                        : "Address"}
                    </label>
                    <textarea
                      cols="31"
                      rows="3"
                      className="store-create-textarea"
                      placeholder={
                        TranslationContext !== undefined
                          ? TranslationContext.placeholder.enteraddress
                          : "Enter address"
                      }
                      name="address_"
                      maxLength={250}
                      value={this.state.userEditData.address_}
                      onChange={this.handleModalEditData}
                    ></textarea>
                    {this.state.userEditData.address_ === "" && (
                      <p style={{ color: "red", marginBottom: "0px" }}>
                        {this.state.editStoreAddressValidation}
                      </p>
                    )}
                  </div>
                </div>
                {/* <div className="col-md-6">
                  <div className="pop-over-div">
                    <label className="edit-label-1">Lpaas Store</label>
                    <input
                      type="text"
                      className="txt-1"
                      placeholder={"Enter Lpaas Store"}
                      name="lpaasStore"
                      value={this.state.userEditData.lpaasStore}
                      onChange={this.handleEditNewStoreChange.bind(this)}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="pop-over-div">
                    <label className="edit-label-1">SKU Store</label>
                    <input
                      type="text"
                      className="txt-1"
                      placeholder={"Enter SKU Store"}
                      name="skuStore"
                      value={this.state.userEditData.skuStore}
                      onChange={this.handleEditNewStoreChange.bind(this)}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="pop-over-div">
                    <label className="edit-label-1">Alternate Store Code</label>
                    <input
                      type="text"
                      className="txt-1"
                      placeholder={"Enter Alternate Store Code"}
                      name="alternateStoreCode"
                      value={this.state.userEditData.alternateStoreCode}
                      onChange={this.handleEditNewStoreChange.bind(this)}
                    />
                  </div>
                </div> */}
            {/* <div className="col-md-6">
                  <div className="pop-over-div">
                    <label className="edit-label-1">Store Manager Name</label>
                    <input
                      type="text"
                      className="txt-1"
                      placeholder={"Enter Store Manager Name"}
                      name="storeManagerName"
                      value={this.state.userEditData.storeManagerName}
                      onChange={this.handleEditNewStoreChange.bind(this)}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="pop-over-div">
                    <label className="edit-label-1">Designation</label>
                    <input
                      type="text"
                      className="txt-1"
                      placeholder={"Enter Designation"}
                      name="designation"
                      value={this.state.userEditData.designation}
                      onChange={this.handleEditNewStoreChange.bind(this)}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="pop-over-div">
                    <label className="edit-label-1">Shopping Assissted</label>
                    <input
                      type="text"
                      className="txt-1"
                      placeholder={"Enter Shopping Assissted"}
                      name="shoppingassissted"
                      value={this.state.userEditData.shoppingassissted}
                      onChange={this.handleEditNewStoreChange.bind(this)}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="pop-over-div">
                    <label className="edit-label-1">Store Timing</label>
                    <input
                      type="text"
                      className="txt-1"
                      placeholder={"Enter Store Timing"}
                      name="storetiming"
                      value={this.state.userEditData.storetiming}
                      onChange={this.handleEditNewStoreChange.bind(this)}
                    />
                  </div>
                </div> 
                <div className="col-md-6">
                  <div className="pop-over-div">
                    <label className="edit-label-1">Day Status</label>
                    <input
                      type="text"
                      className="txt-1"
                      placeholder={"Enter Day Status"}
                      name="dayStatus"
                      value={this.state.userEditData.dayStatus}
                      onChange={this.handleEditNewStoreChange.bind(this)}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="pop-over-div">
                    <label className="edit-label-1">Open Days</label>
                    <input
                      type="text"
                      className="txt-1"
                      placeholder={"Enter Open Days"}
                      name="openDays"
                      value={this.state.userEditData.openDays}
                      onChange={this.handleEditNewStoreChange.bind(this)}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="pop-over-div">
                    <label className="edit-label-1">Opening Time</label>

                    <TimePicker
                      style={{ display: "inherit", width: "100%" }}
                      value={
                        this.state.userEditData.openingTime
                          ? moment(
                              this.state.userEditData.openingTime,
                              "HH:mm:ss"
                            )
                          : null
                      }
                      format="HH:mm:ss"
                      onChange={this.hanldeEditNewStoreChangeDate.bind(
                        this,
                        "openingTime"
                      )}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="pop-over-div">
                    <label className="edit-label-1">Closing Time</label>
                    <TimePicker
                      style={{ display: "inherit", width: "100%" }}
                      value={
                        this.state.userEditData.closingTime
                          ? moment(
                              this.state.userEditData.closingTime,
                              "HH:mm:ss"
                            )
                          : null
                      }
                      format="HH:mm:ss"
                      onChange={this.hanldeEditNewStoreChangeDate.bind(
                        this,
                        "closingTime"
                      )}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="pop-over-div">
                    <label className="edit-label-1">Start Date</label>

                    <DatePicker
                      style={{ display: "inherit", width: "100%" }}
                      placeholderText="Select Start Date"
                      format="YYYY-MM-DD"
                      value={
                        this.state.userEditData.startDate
                          ? moment(
                              this.state.userEditData.startDate,
                              "DD-MM-YYYY hh:mm:ss"
                            )
                          : null
                      }
                      onChange={this.hanldeEditNewStoreChangeDate.bind(
                        this,
                        "startDate"
                      )}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="pop-over-div">
                    <label className="edit-label-1">End Date</label>

                    <DatePicker
                      style={{ display: "inherit", width: "100%" }}
                      placeholderText="Select Start Date"
                      format="YYYY-MM-DD"
                      value={
                        this.state.userEditData.endDate
                          ? moment(
                              this.state.userEditData.endDate,
                              "DD-MM-YYYY hh:mm:ss"
                            )
                          : null
                      }
                      onChange={this.hanldeEditNewStoreChangeDate.bind(
                        this,
                        "endDate"
                      )}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="pop-over-div">
                    <label className="edit-label-1">Store Value</label>
                    <input
                      type="text"
                      className="txt-1"
                      placeholder={"Enter Store Value"}
                      name="storevalue"
                      value={this.state.userEditData.storevalue}
                      onChange={this.handleEditNewStoreChange.bind(this)}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="pop-over-div">
                    <label className="edit-label-1">Store Ownership</label>
                    <input
                      type="text"
                      className="txt-1"
                      placeholder={"Enter Store Ownership"}
                      name="storeOwnership"
                      value={this.state.userEditData.storeOwnership}
                      onChange={this.handleEditNewStoreChange.bind(this)}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="pop-over-div">
                    <label className="edit-label-1">Store Channel</label>
                    <input
                      type="text"
                      className="txt-1"
                      placeholder={"Enter Store Channel"}
                      name="storeChannel"
                      value={this.state.userEditData.storeChannel}
                      onChange={this.handleEditNewStoreChange.bind(this)}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="pop-over-div">
                    <label className="edit-label-1">IsWhatsappEnable</label>
                    <select
                      name="isWhatsappenable"
                      className="form-control dropdown-setting"
                      value={this.state.newStoreFiled.isWhatsappenable || null}
                      onChange={this.handleEditNewStoreChange.bind(this)}
                    >
                      <option value={null}>
                        {TranslationContext !== undefined
                          ? TranslationContext.option.select
                          : "select"}
                      </option>
                      <option value="1">Yes</option>
                      <option value="0">No</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="pop-over-div">
                    <label className="edit-label-1">Tier</label>
                    <input
                      type="text"
                      className="txt-1"
                      placeholder={"Enter Tier"}
                      name="tier"
                      value={this.state.userEditData.tier}
                      onChange={this.handleEditNewStoreChange.bind(this)}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="pop-over-div">
                    <label className="edit-label-1">Latitude</label>
                    <input
                      type="text"
                      className="txt-1"
                      placeholder={"Enter Latitude"}
                      name="latitude"
                      value={this.state.userEditData.latitude}
                      onChange={this.handleEditNewStoreChange.bind(this)}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="pop-over-div">
                    <label className="edit-label-1">Longitude</label>
                    <input
                      type="text"
                      className="txt-1"
                      placeholder={"Enter Longitude"}
                      name="longitude"
                      value={this.state.userEditData.longitude}
                      onChange={this.handleEditNewStoreChange.bind(this)}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="pop-over-div">
                    <label className="edit-label-1">Store GSTNo</label>
                    <input
                      type="text"
                      className="txt-1"
                      placeholder={"Enter Store GSTNo"}
                      name="storeGSTNo"
                      value={this.state.userEditData.storeGSTNo}
                      onChange={this.handleEditNewStoreChange.bind(this)}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="pop-over-div">
                    <label className="edit-label-1">Store CIN</label>
                    <input
                      type="text"
                      className="txt-1"
                      placeholder={"Enter Store CIN"}
                      name="storeCIN"
                      value={this.state.userEditData.storeCIN}
                      onChange={this.handleEditNewStoreChange.bind(this)}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="pop-over-div">
                    <label className="edit-label-1">Store Pan</label>
                    <input
                      type="text"
                      className="txt-1"
                      placeholder={"Enter Store Pan"}
                      name="storePan"
                      value={this.state.userEditData.storePan}
                      onChange={this.handleEditNewStoreChange.bind(this)}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="pop-over-div">
                    <label className="edit-label-1">IsWhatsApp</label>
                    <select
                      name="isWhatsApp"
                      className="form-control dropdown-setting"
                      value={this.state.userEditData.isWhatsApp || null}
                      onChange={this.handleEditNewStoreChange
                        .bind(this)
                        .bind(this)}
                    >
                      <option value={null}>
                        {TranslationContext !== undefined
                          ? TranslationContext.option.select
                          : "select"}
                      </option>
                      <option value="Y">Yes</option>
                      <option value="N">No</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="pop-over-div">
                    <label className="edit-label-1">IsBell</label>
                    <select
                      name="isBell"
                      className="form-control dropdown-setting"
                      value={this.state.userEditData.isBell || null}
                      onChange={this.handleEditNewStoreChange.bind(this)}
                    >
                      <option value={null}>
                        {TranslationContext !== undefined
                          ? TranslationContext.option.select
                          : "select"}
                      </option>
                      <option value="Y">Yes</option>
                      <option value="N">No</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="pop-over-div">
                    <label className="edit-label-1">IsWebBot</label>
                    <select
                      name="isWebBot"
                      className="form-control dropdown-setting"
                      value={this.state.userEditData.isWebBot || null}
                      onChange={this.handleEditNewStoreChange.bind(this)}
                    >
                      <option value={null}>
                        {TranslationContext !== undefined
                          ? TranslationContext.option.select
                          : "select"}
                      </option>
                      <option value="Y">Yes</option>
                      <option value="N">No</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="pop-over-div">
                    <label className="edit-label-1">IsShoppingBag</label>
                    <select
                      name="isShoppingBag"
                      className="form-control dropdown-setting"
                      value={this.state.userEditData.isShoppingBag || null}
                      onChange={this.handleEditNewStoreChange.bind(this)}
                    >
                      <option value={null}>
                        {TranslationContext !== undefined
                          ? TranslationContext.option.select
                          : "select"}
                      </option>
                      <option value="Y">Yes</option>
                      <option value="N">No</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="pop-over-div">
                    <label className="edit-label-1">IsERPayment</label>
                    <select
                      name="isERPayment"
                      className="form-control dropdown-setting"
                      value={this.state.userEditData.isERPayment || null}
                      onChange={this.handleEditNewStoreChange.bind(this)}
                    >
                      <option value={null}>
                        {TranslationContext !== undefined
                          ? TranslationContext.option.select
                          : "select"}
                      </option>
                      <option value="Y">Yes</option>
                      <option value="N">No</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="pop-over-div">
                    <label className="edit-label-1">IsERShipping</label>
                    <select
                      name="isERShipping"
                      className="form-control dropdown-setting"
                      value={this.state.userEditData.isERShipping || null}
                      onChange={this.handleEditNewStoreChange.bind(this)}
                    >
                      <option value={null}>
                        {TranslationContext !== undefined
                          ? TranslationContext.option.select
                          : "select"}
                      </option>
                      <option value="Y">Yes</option>
                      <option value="N">No</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="pop-over-div">
                    <label className="edit-label-1">IsOrderSync</label>
                    <select
                      name="isOrderSync"
                      className="form-control dropdown-setting"
                      value={this.state.userEditData.isOrderSync || null}
                      onChange={this.handleEditNewStoreChange.bind(this)}
                    >
                      <option value={null}>
                        {TranslationContext !== undefined
                          ? TranslationContext.option.select
                          : "select"}
                      </option>
                      <option value="Y">Yes</option>
                      <option value="N">No</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="pop-over-div">
                    <label className="edit-label-1">
                      IsSendToShipmentManual
                    </label>
                    <select
                      name="isSendToShipmentManual"
                      className="form-control dropdown-setting"
                      value={
                        this.state.userEditData.isSendToShipmentManual || null
                      }
                      onChange={this.handleEditNewStoreChange.bind(this)}
                    >
                      <option value={null}>
                        {TranslationContext !== undefined
                          ? TranslationContext.option.select
                          : "select"}
                      </option>
                      <option value="Y">Yes</option>
                      <option value="N">No</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="pop-over-div">
                    <label className="edit-label-1">IsDigitalReceipt</label>
                    <select
                      name="isDigitalReceipt"
                      className="form-control dropdown-setting"
                      value={this.state.userEditData.isDigitalReceipt || null}
                      onChange={this.handleEditNewStoreChange.bind(this)}
                    >
                      <option value={null}>
                        {TranslationContext !== undefined
                          ? TranslationContext.option.select
                          : "select"}
                      </option>
                      <option value="Y">Yes</option>
                      <option value="N">No</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="pop-over-div">
                    <label className="edit-label-1">IsStateHub</label>
                    <select
                      name="isStateHub"
                      className="form-control dropdown-setting"
                      value={this.state.userEditData.isStateHub || null}
                      onChange={this.handleEditNewStoreChange.bind(this)}
                    >
                      <option value={null}>
                        {TranslationContext !== undefined
                          ? TranslationContext.option.select
                          : "select"}
                      </option>
                      <option value="1">Yes</option>
                      <option value="0">No</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="pop-over-div">
                    <label className="edit-label-1">IsCityHub</label>
                    <select
                      name="isCityHub"
                      className="form-control dropdown-setting"
                      value={this.state.userEditData.isCityHub || null}
                      onChange={this.handleEditNewStoreChange.bind(this)}
                    >
                      <option value={null}>
                        {TranslationContext !== undefined
                          ? TranslationContext.option.select
                          : "select"}
                      </option>
                      <option value="1">Yes</option>
                      <option value="0">No</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="pop-over-div">
                    <label className="edit-label-1">IsBrandHub</label>
                    <select
                      name="isBrandHub"
                      className="form-control dropdown-setting"
                      value={this.state.userEditData.isBrandHub || null}
                      onChange={this.handleEditNewStoreChange.bind(this)}
                    >
                      <option value={null}>
                        {TranslationContext !== undefined
                          ? TranslationContext.option.select
                          : "select"}
                      </option>
                      <option value="1">Yes</option>
                      <option value="0">No</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="pop-over-div">
                    <label className="edit-label-1">IsStorePayEnable</label>
                    <select
                      name="isStorePayEnable"
                      className="form-control dropdown-setting"
                      value={this.state.userEditData.isStorePayEnable || null}
                      onChange={this.handleEditNewStoreChange.bind(this)}
                    >
                      <option value={null}>
                        {TranslationContext !== undefined
                          ? TranslationContext.option.select
                          : "select"}
                      </option>
                      <option value="1">Yes</option>
                      <option value="0">No</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="pop-over-div">
                    <label className="edit-label-1">Pickup Location</label>
                    <input
                      type="text"
                      className="txt-1"
                      placeholder={"Enter PickupLocation"}
                      maxLength={100}
                      name="pickupLocation"
                      value={this.state.userEditData.pickupLocation || null}
                      onChange={this.handleEditNewStoreChange.bind(this)}
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="text-center mt-3">
                    <span
                      className="pop-over-cancle"
                      onClick={this.toggleEditModal}
                    >
                      {TranslationContext !== undefined
                        ? TranslationContext.button.cancel
                        : "CANCEL"}
                    </span>
                    <button
                      className="pop-over-button FlNone"
                      onClick={this.handleUpdateData.bind(this)}
                    >
                      <label className="pop-over-btnsave-text">
                        {this.state.editSaveLoading ? (
                          <FontAwesomeIcon
                            className="circular-loader"
                            icon={faCircleNotch}
                            spin
                          />
                        ) : (
                          ""
                        )}
                        {TranslationContext !== undefined
                          ? TranslationContext.button.save
                          : "SAVE"}
                      </label>
                    </button>
                  </div>
                </div>
              </div>

              <br />
            </div> */}
          </Modal>
        </div>
      </React.Fragment>
    );
  }
}

export default StoreMaster;
