import React, { Component } from "react";
import { NotificationManager } from "react-notifications";
import { Spin } from "antd";
import ChatService from "../../Services/Chats/ChatService";
import {Select} from 'antd';
const {Option} = Select
class mobileAppSetting extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedSoundID: 0,
      soundListData: [],
      dataLoader: false,
    };
    this.ChatService = new ChatService();
  }
  componentDidMount() {
    this.handleGetMobileSoundData();
  }
  /// handle get sounda data
  handleGetMobileSoundData() {
    let self = this;
    this.ChatService.Post("/CustomerChat/GetMobileSoundList")
      .then((response) => {
        var message = response.message;
        var data = response.responseData;
        if (message === "Success") {
          self.setState({
            soundListData: data.soundList,
            selectedSoundID: data.selectedSoundID,
          });
        } else {
          self.setState({
            soundListData: [],
            selectedSoundID: 0,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  /// handle submit data
  handleSubmitData() {
    var self = this;
    this.setState({
      dataLoader: true,
    });
    let inputData = {
      NewSoundID: this.state.selectedSoundID,
    };
    this.ChatService.PostWithData(
      "/CustomerChat/UpdateUserMobileNotiSound",
      inputData
    )
      .then((response) => {
        var message = response.message;
        if (message === "Success") {
          NotificationManager.success("Notification Sound Updated.");
          //   self.handleGetMobileSoundData();
          self.setState({
            dataLoader: false,
          });
          window.location.href = "/store/mobileAppSetting";
        } else {
          self.setState({
            dataLoader: false,
          });
          NotificationManager.error("Notification Sound Not Updated.");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  render() {
    return (
      <div>
        <Spin spinning={this.state.dataLoader} tip="Please wait...">
          <div className="row m-0">
            <div className="col-md-6 m-auto">
              <div className="right-sect-div minHeightlink m-t-20 heightMOb">
                <div className="createSpace">
                  <label className="create-department">
                    App Notification Sound
                  </label>

                  <div className="div-padding-1 webbotMob">
                    <Select
                      value={this.state.selectedSoundID}
                      onChange={(e) =>
                        this.setState({ selectedSoundID: e})
                      }
                      className="form-control dropdown-setting"
                    >
                      <Option value={0}>Select Sound</Option>
                      {this.state.soundListData !== null &&
                        this.state.soundListData.map((item, i) => (
                          <Option value={item.soundID} key={i}>
                            {item.soundFileName}
                          </Option>
                        ))}
                    </Select>
                  </div>
                  <div className="m-t-10 m-b-10">
                    <button
                      className="addBtn-ticket-hierarchy"
                      onClick={this.handleSubmitData.bind(this)}
                    >
                      <label className="pop-over-btnsave-text">Submit</label>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Spin>
      </div>
    );
  }
}

export default mobileAppSetting;
