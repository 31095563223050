import React, { Component, PureComponent } from "react";
import { authHeader } from "./../../helpers/authHeader";
import { encryption } from "../../helpers/encryption";
import CancelIcon from "./../../assets/Images/cancel.png";
import BroadCastIcon from "./../../assets/Images/broadCast.png";
import BlackInfoIcon from "./../../assets/Images/Info-black.png";
import Sharevia from "./../../assets/Images/sharevia.png";
import Dropdown3 from "./../../assets/Images/dropdown3.png";
import Tick from "./../../assets/Images/tick.png";
import BackLeft from "./../../assets/Images/black-left-arrow.png";
import WhiteRightArrow from "./../../assets/Images/down-white.png";
import Whatsapp from "./../../assets/Images/whatsapp.svg";
import copy from "./../../assets/Images/copy.png";
import Sms1 from "./../../assets/Images/sms1.svg";
import Email from "./../../assets/Images/camp-Email.svg";
import Smsicon from "./../../assets/Images/sms2.svg";
import ChatbotS from "./../../assets/Images/chatbot-icon.svg";
import { Table, Popover, Radio, Select, Collapse, Spin, Checkbox } from "antd";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Link } from "react-router-dom";
import { Tabs, Tab } from "react-bootstrap-tabs/dist";
import moment from "moment";
import { NotificationManager } from "react-notifications";
import Modal from "react-responsive-modal";
import Pagination from "react-pagination-js";
import "react-pagination-js/dist/styles.css";
import Demo from "./../../store/Hashtag";
import ReactTable from "react-table";
import * as translationHI from "../../translations/hindi";
import * as translationMA from "../../translations/marathi";
import CardTick from "./../../assets/Images/card-tick.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import CampaignService from "../../Services/Campaign/CampaignService";
import { handleMaskingNumber } from "../../helpers/maskingNumber";
import LoginService from "../../Services/Login/LoginService";
const { Option } = Select;
const { Panel } = Collapse;

class CampaignExpandComponent extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      campaignId: this.props.CampaignScriptID,
      campaignCustomerData: [],
      isLoading: false,
      expandedRowKeys: [],
      pageCount: 0,
      pageSize: 10,
      pageNo: 1,
      activePanel: [],
      gotoPageNo: "",
      broadcastChannel: this.props.broadcastChannel,
      broadcastCustomerType: this.props.broadcastCustomerType,
      chstomerSelectAll: false,
      selectedCustomerData: null,
      isNumberMasking: false,
      maskingPrefix: 0,
      maskingSufix: 0,
      tenantMobileMasking: false,
    };
    this.LoginService = new LoginService();
    this.CampaignService = new CampaignService();
    this.handleGotoPageNumberChange =
      this.handleGotoPageNumberChange.bind(this);
      
  }

  componentDidMount() {
    this.handlegetCampaignDetailsById(1, 10);
    this.handleCRMRole();
  }

    
  handlegetCampaignDetailsById = (PageNo, PageSize) => {
    let self = this;
    this.setState({ isLoading: true });
    this.CampaignService.PostWithData("/StoreCampaign/GetCampaignCustomer", {
      campaignScriptID: this.state.campaignId,
      pageNo: PageNo,
      pageSize: PageSize,
      FilterStatus: "All",
      MobileNumber: "",
    })
      .then((response) => {
        var message = response.message;
        var responseData = response.responseData.campaignCustomerModel;
        self.setState({ isLoading: false });
        if (message === "Success" && responseData) {
          var pageCount = response.responseData.campaignCustomerCount;
          if (responseData.length > 0) {
            var i = 0;
            responseData.forEach((element) => {
              element.uID = i;
              element["isCheck"] = false;
              i += 1;
            });
          }
          self.setState({
            campaignCustomerData: responseData,
            // isNumberMasking:
            //   window.localStorage.getItem("isNumberMasking") == "true"
            //     ? true
            //     : false,
            // maskingPrefix: window.localStorage.getItem("maskingPrefix"),
            // maskingSufix: window.localStorage.getItem("maskingSufix"),
            // tenantMobileMasking:
            //   window.localStorage.getItem("tenantMobileMasking") === "true"
            //     ? true
            //     : false,
            pageCount,
          });
          self.forceUpdate();
        } else {
          self.setState({
            campaignCustomerData: [],
            pageCount: 0,
          });
          self.forceUpdate();
        }
      })
      .catch((error) => {
        self.setState({ isLoading: false });
        console.log(error);
      });
  };
//handle crm role data
handleCRMRole() {
  this.LoginService.Post("/StoreCRMRole/GetStoreRolesByUserID")
    .then((response) => {
      let msg = response.message;
      if (msg === "Success") {
        this.setState({
          isNumberMasking: response.responseData.isNumberMasking,
          maskingPrefix: response.responseData.maskingPrefix,
          maskingSufix: response.responseData.maskingSufix,
          tenantMobileMasking: response.responseData.tenantLevelMasking,
          isSuggestionText: response.responseData.suggestionText,
        });
      }
    })
    .catch((error) => {
      console.log(error);
    });
}
  handleExpandSubTable = (row, data) => {
    var expandedRowKeysData = [];
    expandedRowKeysData.push(data);
    this.setState({ expandedRowKeys: row ? expandedRowKeysData : [] });
  };
  handleUpdateCampaignResponse = (
    id,
    responseID,
    callRescheduledTo,
    campaignScriptID
  ) => {
    this.props.handleUpdateCampaignResponse(
      id,
      responseID,
      callRescheduledTo,
      campaignScriptID
    );
  };
  handleResponseChange = (cid, e) => {
    this.state.campaignCustomerData.filter((x) => x.id === cid)[0].responseID =
      Number(e);

    this.setState({ campaignCustomerData: this.state.campaignCustomerData });
    this.forceUpdate();
  };
  PaginationOnChange = (e) => {
    this.setState({ pageNo: Number(e) });
    this.handlegetCampaignDetailsById(Number(e), this.state.pageSize);
  };
  handlecollapseChange = (e, event) => {
    this.state.activePanel = e[e.length - 1];
    this.setState({ activePanel: this.state.activePanel });
    this.forceUpdate();
  };

  handlePageItemchange = (e) => {
    console.log(e, "e");
    this.setState({
      pageSize: e,
    });
    this.handlegetCampaignDetailsById(this.state.pageNo, Number(e));
  };
  handleGetCustomerData = (item) => {
    this.props.handleGetCustomerDataForModal(item);
  };
  onDateChange(campaignCustomerID, e) {
    this.state.campaignCustomerData.filter(
      (x) => x.id === campaignCustomerID
    )[0].callRescheduledTo = e;
    this.setState({ campaignCustomerData: this.state.campaignCustomerData });
    this.forceUpdate();
  }

  handleGotoPageNumberChange = (e) => {
    if (!isNaN(e.target.value)) {
      this.setState({
        gotoPageNo: e.target.value,
      });
    } else {
    }
  };
  handleGotoPageChange = async () => {
    if (this.state.pageCount > 9 && this.state.gotoPageNo) {
      if (
        Math.round(this.state.pageCount / this.state.pageSize) >
        Number(this.state.gotoPageNo)
      )
        await this.setState({
          pageNo: Number(this.state.gotoPageNo),
        });
      this.state.pageNo = Number(this.state.gotoPageNo);
      this.handlegetCampaignDetailsById(
        Number(this.state.gotoPageNo),
        this.state.pageSize
      );
    } else {
      NotificationManager.error("Page no not find.");
    }
  };
  handleCustomerCheckChange = (id, e) => {
    e.stopPropagation();
    this.state.campaignCustomerData.filter((x) => x.id === id)[0].isCheck =
      !this.state.campaignCustomerData.filter((x) => x.id === id)[0].isCheck;
    var selectedCustomerData = this.state.campaignCustomerData
      .filter((x) => x.isCheck === true)
      .map((x) => x.id)
      .join();
    this.setState({
      campaignCustomerData: this.state.campaignCustomerData,
      chstomerSelectAll: false,
      selectedCustomerData,
    });
  };
  handleCustomerCheck = (e) => {
    this.state.campaignCustomerData.forEach((element) => {
      element["isCheck"] = e.target.checked;
    });

    var selectedCustomerData = this.state.campaignCustomerData
      .filter((x) => x.isCheck === true)
      .map((x) => x.id)
      .join();
    this.setState({
      campaignCustomerData: this.state.campaignCustomerData,
      chstomerSelectAll: e.target.checked,
      selectedCustomerData,
    });
    this.forceUpdate();
  };
  handleExcuteSubmit = () => {
    this.props.handleMobileSubmit(this.state.selectedCustomerData.split(","));
  };
  handleCancelBroadcast = () => {
    this.state.campaignCustomerData.forEach((element) => {
      element["isCheck"] = false;
    });

    this.setState({
      campaignCustomerData: this.state.campaignCustomerData,
      selectedCustomerData: null,
    });
  };
   //handle crm role data
   handleCRMRole() {
    this.LoginService.Post("/StoreCRMRole/GetStoreRolesByUserID")
      .then((response) => {
        let msg = response.message;
        if (msg === "Success") {
          this.setState({
            isNumberMasking: response.responseData.isNumberMasking,
            maskingPrefix: response.responseData.maskingPrefix,
            maskingSufix: response.responseData.maskingSufix,
            tenantMobileMasking: response.responseData.tenantLevelMasking,
            isSuggestionText: response.responseData.suggestionText,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  //To handle the copied button icon
  handleCopiedNumber = (e, contact_number, copiedTextChatId) => {
    let numberValue = encryption(contact_number, "enc");
    window.localStorage.setItem("copiedNumber", numberValue);
    this.setState({
      isNumberCopiedClicked: true,
      isTextCopied: true,
      copiedTextChatId: copiedTextChatId,
    });
    this.state.isNumberCopiedClicked = true;
    setTimeout(() => {
      this.setState({
        isNumberCopiedClicked: false,
        isTextCopied: false,
      });
    }, 3000);
  };


  render() {
    return (
      <div style={{ marginTop: "25px" }} className="campinsidemob">
        <Spin spinning={this.state.isLoading}>
          <Pagination
            currentPage={this.state.pageNo}
            totalSize={this.state.pageCount}
            sizePerPage={this.state.pageSize}
            changeCurrentPage={this.PaginationOnChange}
            theme="bootstrap"
          />
          <div className="position-relative">
            <div className="item-selection Camp-pagination">
              <Select
                value={this.state.pageSize}
                onChange={this.handlePageItemchange}
              >
                <Option value={10}>10</Option>
                <Option value={20}>20</Option>
                <Option value={30}>30</Option>
              </Select>
              <p>Items per page</p>
            </div>
          </div>
          <div className="row actn-div-mb">
            <div className="col-6">
              {this.state.broadcastCustomerType === 2 ? (
                <Checkbox
                  checked={this.state.chstomerSelectAll}
                  onChange={this.handleCustomerCheck.bind(this)}
                  style={{ marginBottom: "5px" }}
                >
                  Select All
                </Checkbox>
              ) : null}
            </div>
            <div className="col-6" style={{ textAlign: "right" }}>
              {this.state.selectedCustomerData ? (
                // <img
                //   className="executeImg"
                //   onClick={this.handleExcuteSubmit.bind(this)}
                //   src={roundplaybutton}
                //   alt="roundplaybutton"
                // />
                <>
                  <label onClick={this.handleCancelBroadcast.bind(this)}>
                    Cancel
                  </label>
                  <label onClick={this.handleExcuteSubmit.bind(this)}>
                    Execute
                  </label>
                </>
              ) : null}
            </div>
          </div>
          {this.state.pageCount > 9 ? (
            <div>
              <div
                className="camp-goto-div"
                style={{
                  marginTop:
                    this.state.broadcastCustomerType === 2 ? "-60px" : "",
                }}
              >
                <input
                  type="text"
                  value={this.state.gotoPageNo}
                  onChange={this.handleGotoPageNumberChange}
                />
                <button onClick={this.handleGotoPageChange}>GoTo</button>
              </div>
            </div>
          ) : null}

          <Collapse
            bordered={false}
            className="site-collapse-custom-collapse"
            expandIconPosition={"right"}
            destroyInactivePanel={true}
            activeKey={this.state.activePanel}
            onChange={this.handlecollapseChange.bind(this)}
          >
            {this.state.campaignCustomerData.length > 0
              ? this.state.campaignCustomerData.map((item, key) => {
                  return (
                    <Panel
                      header={
                        <>
                          <div>
                            <div
                              className="contaccheckbtn"
                              style={{ margin: "5px" }}
                            >
                              {this.state.broadcastCustomerType === 2 ? (
                                <Checkbox
                                  checked={item.isCheck}
                                  onChange={this.handleCustomerCheckChange.bind(
                                    this,
                                    item.id
                                  )}
                                />
                              ) : null}
                            </div>
                            <div className="namenext">
                              <label
                                className="name"
                                onClick={this.handleGetCustomerData.bind(
                                  this,
                                  item
                                )}
                              >
                                {item.customerName}
                              </label>
                              <img
                                className="ico cr-pnt ml-2"
                                src={Whatsapp}
                                alt="Whatsapp Icon"
                              />
                              <div className="sml-fnt">
                                {this.state.isNumberMasking === true &&
                                this.state.tenantMobileMasking === true ? (
                                  <p className="num">
                                    {handleMaskingNumber(
                                      item.customerNumber,
                                      this.state.maskingPrefix,
                                      this.state.maskingSufix
                                    )}
                                  </p>
                                ) : (
                                  <p className="num stop_copying">
                                    {item.customerNumber}
                                  </p>
                                )}
                              </div>
                            </div>
                            <div className="contacbtn">
                              {item.statusID === 100 ? (
                                <label className="table-btnlabel contactBtnGreen">
                                  {item.statusName}
                                </label>
                              ) : item.statusID === 101 ? (
                                <label className="table-btnlabel notConnectedBtnRed">
                                  {item.statusName}
                                </label>
                              ) : item.statusID === 102 ? (
                                <label className="table-btnlabel followUpBtnYellow">
                                  {item.statusName}
                                </label>
                              ) : item.statusID === 104 ? (
                                <label className="table-btnlabel followUpBtnBlue">
                                  {item.statusName}
                                </label>
                              ) : null}
                            </div>
                          </div>
                        </>
                      }
                      key={key}
                      className="site-collapse-custom-panel"
                    >
                      <div className="mobextend">
                        <div className="">
                          <label className="date">Date</label>
                          <label className="datee">{item.campaignDate}</label>
                        </div>
                        <div className="" style={{ display: "inlineblock" }}>
                          <label className="date">Response</label>
                          <div className="response">
                            <Select
                              style={{ width: "100%", height: "20%" }}
                              placeholder="Select Response"
                              onChange={this.handleResponseChange.bind(
                                this,
                                item.id
                              )}
                              value={item.responseID}
                            >
                              {item.hsCampaignResponseList.map((items, key) => {
                                return (
                                  <Option key={key} value={items.responseID}>
                                    {items.response}
                                  </Option>
                                );
                              })}
                            </Select>
                          </div>
                        </div>
                        <div className="" style={{ display: "inlineblock" }}>
                          <label className="date">Call Rescheduled to</label>
                          <div className="response">
                            <div
                              className={
                                item.responseID === 3 ? "" : "disabled-input"
                              }
                            >
                              <div className="date-time-resp">
                                <DatePicker
                                  id={"startDate" + item.id}
                                  autoComplete="off"
                                  showTimeSelect
                                  name="startDate"
                                  minDate={new Date()}
                                  showMonthDropdown
                                  showYearDropdown
                                  selected={
                                    item.callRescheduledTo !== ""
                                      ? new Date(item.callRescheduledTo)
                                      : new Date()
                                  }
                                  dateFormat="MM/dd/yyyy h:mm aa"
                                  value={
                                    item.callRescheduledTo !== ""
                                      ? moment(item.callRescheduledTo)
                                      : ""
                                  }
                                  onChange={this.onDateChange.bind(
                                    this,
                                    item.id
                                  )}
                                  className={
                                    item.responseID === 3 &&
                                    item.dateTimeHighlight &&
                                    !item.callRescheduledTo
                                      ? "txtStore dateTimeStore dateTimeStore-highlight"
                                      : item.responseID === 3
                                      ? "txtStore dateTimeStore"
                                      : "txtStore dateTimeStore disabled-link"
                                  }
                                  placeholderText={"Select Date & Time"}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="updatebtn">
                          <button
                            onClick={this.handleUpdateCampaignResponse.bind(
                              this,
                              item.id,
                              item.responseID,
                              item.callRescheduledTo,
                              item.campaignScriptID
                            )}
                          >
                            Update
                          </button>
                        </div>
                      </div>
                    </Panel>
                  );
                })
              : null}
          </Collapse>
        </Spin>
      </div>
    );
  }
}

class StoreCampaign extends Component {
  constructor(props) {
    super(props);

    this.state = {
      campaignGridData: [],
      custNameModal: false,
      ResponsiveCustModal: false,
      CampChildTableData: [],
      modalData: {},
      isName: "",
      isMobile: "",
      isEmail: "",
      isBrand: "",
      isCategory: "",
      isSubCategory: "",
      isIssueType: "",
      isTiketTitle: "",
      isTiketDetails: "",
      loading: false,
      ChildTblLoading: false,
      broadcastChannel: "",
      broadChannelValidation: "",
      responsiveShareVia: false,
      sortCustName: "",
      customerModalDetails: {},
      currentPage: 1,
      postsPerPage: 10,
      totalGridRecord: [],
      childCurrentPage: 1,
      ChildPostsPerPage: 10,
      childTotalGridRecord: 0,
      ResponsiveBroadCast: false,
      collapseModalDetails: {},
      useratvdetails: {},
      campaignkeyinsight: {},
      campaignrecommended: [],
      shareCampaignViaSettingModal: {},
      lasttransactiondetails: {},
      lastTransactionItem: [],
      ResponsiveShareNow: false,
      campaignID: 0,
      ResponsiveChooseChannel: 0,
      Respo_ChannelMessanger: false,
      Respo_ChannelBot: false,
      Respo_ChannelSMS: false,
      Respo_ChannelEmail: false,
      filterDropdownVisible: false,
      filterCustomerNumber: false,
      filterCampaignName: false,
      filterCampaignStatus: false,
      strCampStatus: "",
      strStatusIds: "",
      chatbotScript: "",
      smsScript: "",
      campaingPeriod: "",
      filterCustNO: "",
      filterCampName: "",
      showRecommandedtab: false,
      showLastTransactiondtab: false,
      insightShowImg: false,
      handleArrowImg: false,
      expandedRowKeys: "",
      smsDisable: false,
      msngrDisable: false,
      botDisable: false,
      shareDisable: false,
      custMobileValidation: "",
      CampCustNameValidation: "",
      campaignExecutionDetails: [],
      broadcastConfiguration: {},
      storeCode: "",
      campaignCode: "",
      translateLanguage: {},
      broadCastLoading: false,
      isMobileView: false,
      isTableRowClick: false,
      campaignName: "",
      selectedButton: 0,
      broadcastCustomerType: 1,
      selectedCustomerRowKeys: [],
      selectedBroadcastRowData: null,
      isSendBroadcastLoader: false,
      whatsappScript: "",
      isNumberMasking: false,
      maskingPrefix: 0,
      maskingSufix: 0,
    };
    this.CampaignService = new CampaignService();
    this.handleMobileSubmit = this.handleMobileSubmit.bind(this);
    this.handleGetCampaignGridData = this.handleGetCampaignGridData.bind(this);
    this.handleGetCampaignCustomerData =
      this.handleGetCampaignCustomerData.bind(this);
    this.LoginService = new LoginService();
  }

  componentDidMount() {
    this.handleGetCampaignGridData();
    if (window.localStorage.getItem("translateLanguage") === "hindi") {
      this.state.translateLanguage = translationHI;
    } else if (window.localStorage.getItem("translateLanguage") === "marathi") {
      this.state.translateLanguage = translationMA;
    } else {
      this.state.translateLanguage = {};
    }
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
    this.handleCRMRole();
  }
  resize() {
    if (window.innerWidth <= 1000) {
      this.setState({ isMobileView: window.innerWidth <= 1023 });
    } else {
      this.setState({ isTableRowClick: false, isMobileView: false });
    }
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.resize.bind(this));
  }

  handleArrowImg() {
    let handleArrowImg = this.state.handleArrowImg;
    this.setState({
      handleArrowImg: !handleArrowImg,
    });
  }
  //handle crm role data
  handleCRMRole() {
    this.LoginService.Post("/StoreCRMRole/GetStoreRolesByUserID")
      .then((response) => {
        let msg = response.message;
        if (msg === "Success") {
          this.setState({
            isNumberMasking: response.responseData.isNumberMasking,
            maskingPrefix: response.responseData.maskingPrefix,
            maskingSufix: response.responseData.maskingSufix,
            tenantMobileMasking: response.responseData.tenantLevelMasking,
            isSuggestionText: response.responseData.suggestionText,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  onResponseChange(campaignCustomerID, item, e) {
    this.state.CampChildTableData.filter(
      (x) => x.id === campaignCustomerID
    )[0].responseID = parseInt(e.target.value);
    this.state.CampChildTableData.filter(
      (x) => x.id === campaignCustomerID
    )[0].dateTimeHighlight = true;

    this.setState({ CampChildTableData: this.state.CampChildTableData });
  }

  onDateChange(campaignCustomerID, e) {
    this.state.CampChildTableData.filter(
      (x) => x.id === campaignCustomerID
    )[0].callRescheduledTo = e;
    this.setState({ CampChildTableData: this.state.CampChildTableData });
  }

  handleGetCampaignGridData() {
    let self = this;
    this.setState({
      loading: true,
    });

    this.CampaignService.Post("/StoreCampaign/GetCampaignDetails")
      .then((response) => {
        let status = response.message;
        let data = response.responseData;
        for (let obj of data) obj.key = obj.campaignID;
        if (status === "Success") {
          const indexOfLastpost =
            self.state.currentPage * self.state.postsPerPage;
          const indexOfFirstpost = indexOfLastpost - self.state.postsPerPage;
          const currentPosts = data.slice(indexOfFirstpost, indexOfLastpost);
          self.setState({
            campaignGridData: data,
            loading: false,
            totalGridRecord: data.length,
          });
        } else {
          self.setState({
            campaignGridData: [],
            loading: false,
            totalGridRecord: [],
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  ///// Handle Update Campaign data
  handleUpdateCampaignResponse(
    id,
    responseID,
    callRescheduledTo,
    campaignScriptID
  ) {
    const TranslationContext = this.state.translateLanguage.default;
    let self = this,
      calculatedCallReScheduledTo;
    var Updatecheck = "";
    if (responseID === 3) {
      if (callRescheduledTo !== "") {
        this.setState({
          loading: true,
        });

        calculatedCallReScheduledTo = callRescheduledTo;
        this.CampaignService.PostWithData(
          "/StoreCampaign/UpdateCampaignStatusResponse",
          {
            CampaignCustomerID: id,
            ResponseID: responseID,
            CallReScheduledTo: calculatedCallReScheduledTo,
          }
        )
          .then((response) => {
            let status = response.message;
            self.setState({ isTableRowClick: false });
            if (status === "Success") {
              NotificationManager.success(
                TranslationContext !== undefined
                  ? TranslationContext.alertmessage.recordupdatedsuccessfully
                  : "Record Updated Successfully."
              );
              self.handleGetCampaignCustomerData(
                true,
                Updatecheck,
                campaignScriptID,
                ""
              );
              if (self.state.isMobileView) {
                self.setState({ isTableRowClick: true });
              }
            } else {
              self.setState({
                loading: false,
              });
              if (self.state.isMobileView) {
                self.setState({ isTableRowClick: true });
              }
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        NotificationManager.error(
          TranslationContext !== undefined
            ? TranslationContext.alertmessage.pleaseselectdateandtime
            : "Please Select Date and Time."
        );
      }
    } else {
      if (responseID !== 0) {
        this.setState({
          loading: true,
        });

        let inputData = {
          CampaignCustomerID: id,
          ResponseID: responseID,
          CallReScheduledTo: "",
        };
        this.CampaignService.PostWithData(
          "/StoreCampaign/UpdateCampaignStatusResponse",
          inputData
        )
          .then((response) => {
            let status = response.message;
            self.setState({ isTableRowClick: false });
            if (status === "Success") {
              NotificationManager.success(
                TranslationContext !== undefined
                  ? TranslationContext.alertmessage.recordupdatedsuccessfully
                  : "Record Updated Successfully."
              );
              if (self.state.isMobileView) {
                self.setState({ isTableRowClick: true });
              }
              self.handleGetCampaignCustomerData(
                true,
                Updatecheck,
                campaignScriptID,
                ""
              );
            } else {
              self.setState({
                loading: false,
              });
              if (self.state.isMobileView) {
                self.setState({ isTableRowClick: true });
              }
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        NotificationManager.error(
          TranslationContext !== undefined
            ? TranslationContext.alertmessage.pleaseselectresponse
            : "Please Select Response."
        );
      }
    }
  }

  handleCloseCampaign(campaignTypeID, e) {
    const TranslationContext = this.state.translateLanguage.default;
    let self = this;
    this.setState({
      loading: true,
    });
    let inputParams = {
      CampaignTypeID: campaignTypeID,
      IsClosed: 1,
    };
    this.CampaignService.PostWithParams("/StoreTask/CloseCampaign", inputParams)
      .then((response) => {
        let status = response.message;
        if (status === "Success") {
          NotificationManager.success(
            TranslationContext !== undefined
              ? TranslationContext.alertmessage.campaignclosedsuccessfully
              : "Campaign closed successfully."
          );
          self.handleGetCampaignGridData();
        } else {
          self.setState({
            loading: false,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  handleCustomerNameModalClose() {
    this.setState({
      custNameModal: false,
    });
  }

  responsiveCustModalOpen(data, e) {
    e.stopPropagation();
    this.setState({
      ResponsiveCustModal: true,
      chatbotScript: data.chatbotScript,
      smsScript: data.smsScript,
      campaingPeriod: data.campaingPeriod,
      isTableRowClick: false,
      whatsappScript: data.whatsAppScript,
    });
  }
  responsiveCustModalClose() {
    this.setState({
      ResponsiveCustModal: false,
    });
  }
  handleShareViaModalOpen() {
    this.setState({
      responsiveShareVia: true,
    });
  }
  handleShareViaModalClose() {
    this.setState({
      responsiveShareVia: false,
    });
  }
  handleBroadCastModalOpen() {
    this.setState({
      ResponsiveBroadCast: true,
    });
  }
  handleBroadCastModalClose() {
    this.setState({
      ResponsiveBroadCast: false,
    });
  }

  handleShareNowOpenModal() {
    this.setState({
      shareDisable: true,
    });
    if (this.state.Respo_ChannelMessanger === true) {
      this.handleSendViaMessanger(this.state.customerModalDetails);
    } else if (this.state.Respo_ChannelBot === true) {
      this.handleSendViaBotData(this.state.customerModalDetails);
    } else if (this.state.Respo_ChannelSMS === true) {
      this.handleSendViaSMS(this.state.customerModalDetails);
    } else if (this.state.Respo_ChannelEmail === true) {
      console.log("API not ready");
    }
  }
  handleShareNowCloseModal() {
    this.setState({
      ResponsiveShareNow: false,
    });
  }
  handleBroadcastChange = (e) => {
    this.setState({
      broadcastChannel: e.target.value,
    });
  };
  /// handle Per page onchange
  handlePageItemchange = (e) => {
    this.setState({
      ChildPostsPerPage: e,
      childCurrentPage: 1,
      gotoPageNo: "",
    });
    setTimeout(() => {
      this.handleGetCampaignCustomerData(
        true,
        "",
        this.state.campaignID,
        "filter"
      );
    }, 50);
  };
  /// Pagination Onchange
  PaginationOnChange = async (numPage) => {
    await this.setState({
      childCurrentPage: numPage,
    });
    if (this.state.strStatusIds !== "") {
      this.handleGetCampaignCustomer(
        this.state.campaignID,
        this.state.childTotalGridRecord
      );
    } else {
      await setTimeout(() => {
        this.handleGetCampaignCustomerData(true, "", this.state.campaignID, "");
      }, 500);
    }
  };

  /// handle Search Campaign name and status
  handleSearchCampaignNameandStatus() {
    let self = this;
    var filterIds = "";
    if (this.state.strCampStatus !== "") {
      filterIds = this.state.strCampStatus;
    } else {
      filterIds = "All";
    }
    let inputParams = {
      campaignName: this.state.filterCampName,
      statusId: filterIds,
    };
    this.CampaignService.PostWithParams(
      "/StoreCampaign/GetCampaignDetails",
      inputParams
    )
      .then((response) => {
        let status = response.message;
        let data = response.responseData;
        if (status === "Success") {
          self.setState({
            campaignGridData: data,
            CampCustNameValidation: "",
          });
        } else {
          self.setState({
            campaignGridData: [],
            CampCustNameValidation: "",
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  /// Handle Get Campaign customer details
  handleGetCampaignCustomerData(data, row, check, filter) {
    document.addEventListener("contextmenu", (event) => event.preventDefault());
    if (data) {
      this.setState({
        ChildTblLoading: true,
        CampChildTableData: [],
      });
      if (row === "") {
        this.state.filterCustNO = "";
        setTimeout(() => {
          this.setState({
            childTotalGridRecord: 0,
            filterCustNO: "",
          });
        }, 50);
      } else {
        var keys = [];
        if (data) {
          keys.push(row.campaignID);
          this.state.childCurrentPage = 1;
          this.state.ChildPostsPerPage = 10;
          this.state.filterCustNO = "";
          setTimeout(() => {
            this.setState({
              childCurrentPage: 1,
              ChildPostsPerPage: 10,
              childTotalGridRecord: 0,
              expandedRowKeys: keys,
              filterCustNO: "",
            });
          }, 50);
        }
      }

      var campaignId = 0;
      if (check !== undefined || check > 0) {
        campaignId = check;
      } else {
        if (row !== "") {
          this.setState({
            campaignID: row.campaignID,
          });
          campaignId = row.campaignID;
        } else {
          campaignId = this.state.campaignID;
        }
      }
      if (row !== "") {
        this.setState({
          childTotalGridRecord: Number(row.customerCount),
        });
      } else {
      }
      var filterIds = "";
      if (this.state.strStatusIds !== "") {
        filterIds = this.state.strStatusIds;
      } else {
        filterIds = "All";
      }
      var pageNumber = this.state.childCurrentPage;
      if (filter === "filter") {
        pageNumber = 1;
      }
      let self = this;
      let inputData = {
        campaignScriptID: campaignId,
        pageNo: pageNumber,
        pageSize: this.state.ChildPostsPerPage,
        FilterStatus: filterIds,
        MobileNumber: this.state.filterCustNO,
      };
      this.CampaignService.PostWithData(
        "/StoreCampaign/GetCampaignCustomer",
        inputData
      )
        .then((response) => {
          var message = response.message;
          var data = response.responseData;
          if (message == "Success") {
            for (let obj of data.campaignCustomerModel)
              obj.dateTimeHighlight = false;

            self.setState({
              CampChildTableData: data.campaignCustomerModel,
              ChildTblLoading: false,
              loading: false,
              childTotalGridRecord: data.campaignCustomerCount,
              // isNumberMasking:
              //   window.localStorage.getItem("isNumberMasking") == "true"
              //     ? true
              //     : false,
              // maskingPrefix: window.localStorage.getItem("maskingPrefix"),
              // maskingSufix: window.localStorage.getItem("maskingSufix"),
              // tenantMobileMasking:
              //   window.localStorage.getItem("tenantMobileMasking") === "true"
              //     ? true
              //     : false,
            });
          } else {
            self.setState({
              CampChildTableData: [],
              ChildTblLoading: false,
              loading: false,
              childTotalGridRecord: 0,
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      this.setState({
        expandedRowKeys: [],
        CampChildTableData: [],
        strStatusIds: "All",
        ChildPostsPerPage: 10,
        childCurrentPage: 1,
        broadcastCustomerType: 1,
        selectedCustomerRowKeys: [],
        selectedBroadcastRowData: null,
        broadcastChannel: null,
      });
    }
  }
  /// Send Via Bot data
  handleSendViaBotData(data) {
    this.setState({
      botDisable: true,
    });
    let self = this;
    let inputDate = {
      StoreID: data.storecode,
      ProgramCode: data.programcode,
      CustomerID: data.id,
      CustomerMobileNumber: data.customerNumber,
      StoreManagerId: data.storeManagerId,
      CampaignScriptID: data.campaignScriptID,
    };
    this.CampaignService.PostWithData(
      "/StoreCampaign/CampaignShareChatbot",
      inputDate
    )
      .then((response) => {
        const TranslationContext = self.state.translateLanguage.default;
        var message = response.message;
        self.setState({ selectedButton: 0 });
        if (self.state.Respo_ChannelBot === true) {
          if (message === "Success") {
            self.setState({
              ResponsiveShareNow: true,
              custNameModal: false,
              Respo_ChannelBot: false,
              responsiveShareVia: false,
            });
            self.handleGetCampaignCustomerData(
              true,
              "",
              self.state.campaignID,
              ""
            );
          } else {
            NotificationManager.error(
              TranslationContext !== undefined
                ? TranslationContext.alertmessage.sentsuccessfully
                : "Sent Successfully."
            );
          }
        } else {
          if (message === "Success") {
            NotificationManager.success(
              TranslationContext !== undefined
                ? TranslationContext.alertmessage.sentsuccessfully
                : "Sent Successfully."
            );
            self.setState({
              custNameModal: false,
            });
            self.handleGetCampaignCustomerData(
              true,
              "",
              self.state.campaignID,
              ""
            );
          } else {
            NotificationManager.error(
              TranslationContext !== undefined
                ? TranslationContext.alertmessage.failed
                : "Failed"
            );
          }
        }
        self.setState({
          botDisable: false,
          shareDisable: false,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  ///handle Send Via SMS
  handleSendViaSMS(data) {
    this.setState({
      smsDisable: true,
    });
    let self = this;
    let inputData = {
      StoreID: data.storecode,
      ProgramCode: data.programcode,
      CustomerID: data.id,
      CustomerMobileNumber: data.customerNumber,
      StoreManagerId: data.storeManagerId,
      CampaignScriptID: data.campaignScriptID,
    };
    this.CampaignService.PostWithData(
      "/StoreCampaign/CampaignShareSMS",
      inputData
    )
      .then((response) => {
        const TranslationContext = self.state.translateLanguage.default;
        var message = response.message;
        self.setState({ selectedButton: 0 });
        if (self.state.Respo_ChannelSMS === true) {
          if (message === "Success") {
            self.setState({
              ResponsiveShareNow: true,
              custNameModal: false,
              Respo_ChannelMessanger: false,
              responsiveShareVia: false,
            });
          } else {
            NotificationManager.error(
              TranslationContext !== undefined
                ? TranslationContext.alertmessage.servertemporarilynotavailable
                : "Server temporarily not available."
            );
          }
        } else {
          if (message === "Success") {
            self.setState({
              custNameModal: false,
            });
            NotificationManager.success(
              TranslationContext !== undefined
                ? TranslationContext.alertmessage.smssentsuccessfully
                : "SMS Sent Successfully."
            );
          } else {
            NotificationManager.error(
              TranslationContext !== undefined
                ? TranslationContext.alertmessage.smssentfailed
                : "SMS Send Failed."
            );
          }
        }
        self.setState({
          smsDisable: false,
          shareDisable: false,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  /// Send Via Messenger data
  handleSendViaMessanger(data) {
    const TranslationContext = this.state.translateLanguage.default;
    this.setState({
      msngrDisable: true,
    });
    let self = this;
    var mobile_no = data.customerNumber;
    let inputData = {
      StoreID: data.storecode,
      ProgramCode: data.programcode,
      CustomerID: data.id,
      CustomerMobileNumber: data.customerNumber,
      StoreManagerId: data.storeManagerId,
      CampaignScriptID: data.campaignScriptID,
    };
    this.CampaignService.PostWithData(
      "/StoreCampaign/CampaignShareMassanger",
      inputData
    )
      .then((response) => {
        var message = response.message;
        var data = response.responseData;
        self.setState({ selectedButton: 0 });
        if (message === "Success") {
          self.setState({
            custNameModal: false,
          });
          if (self.state.Respo_ChannelMessanger === false) {
            // NotificationManager.success(
            //   TranslationContext !== undefined
            //     ? TranslationContext.alertmessage.sentsuccessfully
            //     : "Sent Successfully."
            // );
          }
          self.handleGetCampaignCustomer(
            self.state.campaignID,
            self.state.childTotalGridRecord,
            "filter"
          );
          if (self.state.isMobileView) {
            if (data) {
              document.getElementById("whatsapp-share").href =
                "whatsapp://send?phone=" +
                mobile_no +
                "&text=" +
                data.split("text=")[1];
              document.getElementById("whatsapp-share").click();
            }
          } else {
            window.open("//" + data, "_blank");
          }

          if (self.state.Respo_ChannelMessanger === true) {
            self.setState({
              ResponsiveShareNow: true,
              custNameModal: false,
              Respo_ChannelMessanger: false,
              responsiveShareVia: false,
            });
          }
        } else {
          NotificationManager.error(
            TranslationContext !== undefined
              ? TranslationContext.alertmessage.servertemporarilynotavailable
              : "Server temporarily not available."
          );
        }
        self.setState({
          msngrDisable: false,
          shareDisable: false,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  //To handle the copied button icon
  handleCopiedNumber = (e, contact_number, copiedTextChatId) => {
    let muskedNumber = handleMaskingNumber(
      contact_number,
      this.state.maskingPrefix,
      this.state.maskingSufix
    );
    let numberValue = encryption(muskedNumber, "enc");
    window.localStorage.setItem("copiedNumber", numberValue);
    this.setState({
      isNumberCopiedClicked: true,
      isTextCopied: true,
      copiedTextChatId: copiedTextChatId,
    });
    this.state.isNumberCopiedClicked = true;
    setTimeout(() => {
      this.setState({
        isNumberCopiedClicked: false,
        isTextCopied: false,
      });
    }, 3000);
  };

  /// Handle Get Customer data
  handleGetCustomerDataForModal(rowData) {
    let self = this;
    var strTag = rowData.customerName.split(" ");
    var sortName = strTag[0].charAt(0).toUpperCase();
    if (strTag.length === 1) {
      sortName = strTag[0].charAt(0).toUpperCase();
    } else {
      sortName += strTag[1].charAt(0).toUpperCase();
    }
    var customerModalDetails = {};

    this.setState({
      customerModalDetails: rowData,
      isCustomerModelLoader: true,
      custNameModal: true,
      sortCustName: sortName,
      modelCustomerName: rowData.customerName,
      modelCustomerNo: rowData.customerNumber,
      selectedButton: 0,
      // campaignIdRow: rowData.campaignScriptID,
    });

    this.handleGetShareCampaignViaSetting(rowData.customerNumber);

    this.handleGetCustomerpopupDetails(
      rowData.customerNumber,
      rowData.campaignScriptID
    );

    this.handleGetStoreCampaignLastTransactionDetails(rowData.customerNumber);
    this.handleGetCampaignRecommendationList(rowData.customerNumber);
  }
  /// handle Campaign status filter for individual select
  handleCheckCampIndividualStatus() {
    var checkboxes = document.getElementsByName("CampallStatus");
    var strCampStatus = "";
    for (var i in checkboxes) {
      if (isNaN(i) === false) {
        if (checkboxes[i].checked === true) {
          if (checkboxes[i].getAttribute("attrIds") !== null)
            strCampStatus += checkboxes[i].getAttribute("attrIds") + ",";
        }
      }
    }
    this.setState({
      filterCampaignStatus: false,
      strCampStatus,
    });
    setTimeout(() => {
      this.handleSearchCampaignNameandStatus();
    }, 50);
  }
  /// handle Campaign status filter for all select
  handleCheckCampAllStatus(event) {
    this.setState((state) => ({ CheckBoxAllBrand: !state.CheckBoxAllBrand }));
    var strCampStatus = "";
    const allCheckboxChecked = event.target.checked;
    var checkboxes = document.getElementsByName("CampallStatus");
    if (allCheckboxChecked) {
      for (var i in checkboxes) {
        if (checkboxes[i].checked === false) {
          checkboxes[i].checked = true;
          if (checkboxes[i].getAttribute("attrIds") !== null)
            strCampStatus = "All";
        }
      }
    } else {
      for (var J in checkboxes) {
        if (checkboxes[J].checked === true) {
          checkboxes[J].checked = false;
        }
      }
      strCampStatus = "";
    }
    this.setState({
      filterCampaignStatus: false,
      strCampStatus,
    });
    setTimeout(() => {
      this.handleSearchCampaignNameandStatus();
    }, 50);
  }

  checkIndividualStatus(campaignScriptID, customerCount, event) {
    var checkboxes = document.getElementsByName("allStatus");
    var strStatusIds = "";
    for (var i in checkboxes) {
      if (isNaN(i) === false) {
        if (checkboxes[i].checked === true) {
          if (checkboxes[i].getAttribute("attrIds") !== null)
            strStatusIds += checkboxes[i].getAttribute("attrIds") + ",";
        }
      }
    }
    this.setState({
      filterDropdownVisible: false,
      strStatusIds,
    });
    setTimeout(() => {
      this.handleGetCampaignCustomer(campaignScriptID, customerCount, "filter");
    }, 50);
  }

  checkAllStatus(campaignScriptID, customerCount, event) {
    this.setState((state) => ({ CheckBoxAllBrand: !state.CheckBoxAllBrand }));
    var strStatusIds = "";
    const allCheckboxChecked = event.target.checked;
    var checkboxes = document.getElementsByName("allStatus");
    if (allCheckboxChecked) {
      for (var i in checkboxes) {
        if (checkboxes[i].checked === false) {
          checkboxes[i].checked = true;
          if (checkboxes[i].getAttribute("attrIds") !== null)
            strStatusIds = "All";
        }
      }
    } else {
      for (var J in checkboxes) {
        if (checkboxes[J].checked === true) {
          checkboxes[J].checked = false;
        }
      }
      strStatusIds = "";
    }
    this.setState({
      filterDropdownVisible: false,
      strStatusIds,
    });
    setTimeout(() => {
      this.handleGetCampaignCustomer(campaignScriptID, customerCount, "filter");
    }, 50);
  }

  handleCustomerFilerOnchange(campaignID, customerCount, e) {
    var reg = /^[0-9\b]+$/;

    if (e.target.value === "" || reg.test(e.target.value)) {
      this.setState({
        filterCustNO: e.target.value,
        custMobileValidation: "Please enter altest 3 numbers.",
      });
    } else {
      e.target.value = "";
    }

    if (this.state.filterCustNO.length > 2) {
      setTimeout(() => {
        this.handleGetCampaignCustomer(campaignID, customerCount, "filter");
      }, 50);
    }
  }

  handleCampaignNameOnchange(e) {
    this.setState({
      filterCampName: e.target.value,
      CampCustNameValidation: "Please enter altest 5 characters.",
    });
    if (this.state.filterCampName.length > 5) {
      setTimeout(() => {
        this.handleSearchCampaignNameandStatus();
      }, 10);
    }
  }
  handleGetCampaignCustomer = (
    campaignScriptID,
    customerCount,
    check,
    pageNo
  ) => {
    let self = this;
    var pageNumber = this.state.childCurrentPage;
    if (check === "filter") {
      pageNumber = 1;
      this.state.childCurrentPage = 1;
    }
    if (check === "GoTo") {
      pageNumber = pageNo;
      this.state.childCurrentPage = pageNo;
    }
    if (customerCount !== "") {
      this.setState({
        childTotalGridRecord: Number(customerCount),
      });
    }
    var filterIds = "";
    if (this.state.strStatusIds !== "") {
      filterIds = this.state.strStatusIds;
    } else {
      filterIds = "All";
    }
    let inputData = {
      campaignScriptID: campaignScriptID,
      pageNo: pageNumber,
      pageSize: this.state.ChildPostsPerPage,
      FilterStatus: filterIds,
      MobileNumber: this.state.filterCustNO,
    };
    this.CampaignService.PostWithData(
      "/StoreCampaign/GetCampaignCustomer",
      inputData
    )
      .then((response) => {
        var message = response.message;
        var data = response.responseData;
        if (message == "Success") {
          self.setState({
            CampChildTableData: data.campaignCustomerModel,
            childTotalGridRecord: data.campaignCustomerCount,
            custMobileValidation: "",
          });
        } else {
          self.setState({
            CampChildTableData: [],
            childTotalGridRecord: 0,
            custMobileValidation: "",
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  ///handle get Broadcast configuration
  handleGetBroadcastConfiguration(
    store_Code,
    campaign_Code,
    selectedRowData,
    e
  ) {
    if (this.state.isMobileView) {
      e.stopPropagation();
    }
    let self = this;
    this.setState({
      broadCastLoading: true,
      selectedBroadcastRowData: selectedRowData,
      broadcastCustomerType: 1,
      broadcastChannel: null,
    });
    let inputParams = {
      storeCode: store_Code,
      campaignCode: campaign_Code,
    };
    this.CampaignService.PostWithParams(
      "/StoreCampaign/GetBroadcastConfigurationResponses",
      inputParams
    )
      .then((response) => {
        var message = response.message;
        var data = response.responseData;
        if (message == "Success") {
          self.setState({
            campaignExecutionDetails: data.campaignExecutionDetailsResponse,
            broadcastConfiguration: data.broadcastConfigurationResponse,
            ResponsiveBroadCast: true,
            storeCode: store_Code,
            campaignCode: campaign_Code,
            broadCastLoading: false,
          });
        } else {
          self.setState({
            campaignExecutionDetails: [],
            broadcastConfiguration: {},
            ResponsiveBroadCast: true,
            broadCastLoading: false,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  /// handle Broadcast execute
  handleBroadcastExecute(store_Code, campaign_Code) {
    var selectedCustomerData = "";
    if (this.state.broadcastCustomerType === 2) {
      selectedCustomerData = this.state.selectedCustomerRowKeys.join(",");
    } else {
      selectedCustomerData = "all";
    }
    const TranslationContext = this.state.translateLanguage.default;
    var self = this;
    if (this.state.broadcastChannel) {
      this.setState({
        isSendBroadcastLoader: true,
      });
      let inputData = {
        storeCode: store_Code,
        campaignCode: campaign_Code,
        channelType: this.state.broadcastChannel,
        selectedCustomer: selectedCustomerData,
      };
      this.CampaignService.PostWithData(
        "/StoreCampaign/InsertBroadCastDetails",
        inputData
      )
        .then((response) => {
          var message = response.message;
          self.setState({
            isSendBroadcastLoader: false,
          });
          if (message == "Success") {
            if (
              self.state.isMobileView &&
              self.state.broadcastCustomerType === 2
            ) {
              self.setState({
                isTableRowClick: false,
              });
            }
            NotificationManager.success(
              TranslationContext !== undefined
                ? TranslationContext.alertmessage.sentsuccessfully
                : "Sent Successfully."
            );
            if (
              self.state.isMobileView &&
              self.state.broadcastCustomerType === 2
            ) {
              self.setState({
                selectedCustomerRowKeys: [],
                broadChannelValidation: "",
              });
            } else {
              self.setState({
                selectedCustomerRowKeys: [],
                broadcastCustomerType: 1,
                selectedBroadcastRowData: null,
                broadcastChannel: "",
                broadChannelValidation: "",
                ResponsiveBroadCast: false,
              });
            }

            if (
              self.state.isMobileView &&
              self.state.broadcastCustomerType === 2
            ) {
              self.setState({
                isTableRowClick: true,
              });
            }
            self.handleGetBroadcastConfiguration(
              store_Code,
              campaign_Code,
              self.state.selectedBroadcastRowData
            );
          } else {
            NotificationManager.error(
              TranslationContext !== undefined
                ? TranslationContext.alertmessage.sentsuccessfully
                : "Sent Failed."
            );
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      self.setState({
        broadChannelValidation: "Please Choose Channel.",
      });
    }
  }
  ////handle whats app icon click
  handleWhatsAppIconClick(itemData) {
    if (document.getElementById("chatwindow")) {
      document.getElementById("newTicketChatId").value =
        itemData.customerChatingID;
      document.getElementById("chatwindow").click();
    }
  }
  //// handle Channel onchange
  handleSelectChannelsOnchange(check) {
    if (check === "Messenger") {
      this.setState({
        Respo_ChannelMessanger: true,
        Respo_ChannelBot: false,
        Respo_ChannelSMS: false,
        Respo_ChannelEmail: false,
      });
    } else if (check === "Bot") {
      this.setState({
        Respo_ChannelMessanger: false,
        Respo_ChannelBot: true,
        Respo_ChannelSMS: false,
        Respo_ChannelEmail: false,
      });
    } else if (check === "SMS") {
      this.setState({
        Respo_ChannelMessanger: false,
        Respo_ChannelBot: false,
        Respo_ChannelSMS: true,
        Respo_ChannelEmail: false,
      });
    } else if (check === "Email") {
      this.setState({
        Respo_ChannelMessanger: false,
        Respo_ChannelBot: false,
        Respo_ChannelSMS: false,
        Respo_ChannelEmail: true,
      });
    }
  }
  handelCampaignRowClick = (data) => {
    if (this.state.isMobileView) {
      this.setState({
        isTableRowClick: true,
        campaignName: data.campaignName,
        campaignID: data.campaignID,
      });
    } else {
    }
  };
  handleCampaignBackClick = () => {
    if (this.state.isMobileView) {
      this.setState({
        isTableRowClick: false,
      });
    }
  };
  ///handle get share campaign via setting
  handleGetShareCampaignViaSetting(mobileNo) {
    let self = this;
    this.setState({
      isCustomerModelLoader: true,
    });
    let inputParams = {
      mobileNumber: mobileNo,
    };

    this.CampaignService.PostWithParams(
      "/StoreCampaign/GetShareCampaignViaSetting",
      inputParams
    )
      .then((response) => {
        var message = response.message;
        var responseData = response.responseData;
        self.setState({
          isCustomerModelLoader: false,
        });
        if (message === "Success" && responseData) {
          self.setState({ shareCampaignViaSettingModal: responseData });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  ///handle get share campaign via setting
  handleGetStoreCampaignLastTransactionDetails(mobileNo) {
    let self = this;

    this.setState({
      isCustomerModelLoader: true,
    });
    let inputParams = {
      mobileNumber: mobileNo,
    };
    this.CampaignService.PostWithParams(
      "/StoreCampaign/GetStoreCampaignLastTransactionDetails",
      inputParams
    )
      .then((response) => {
        var message = response.message;
        var responseData = response.responseData;
        if (message === "Success" && responseData) {
          self.setState({ lasttransactiondetails: responseData });
          self.setState({
            isCustomerModelLoader: false,
          });
          if (responseData.itemDetails !== null) {
            self.setState({
              lastTransactionItem: responseData.itemDetails,
              showLastTransactiondtab: false,
            });
          } else {
            self.setState({
              lastTransactionItem: [],
              showLastTransactiondtab: true,
            });
          }
        } else {
          self.setState({
            lastTransactionItem: [],
            showLastTransactiondtab: true,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  ///handle get campaign recommendation list
  handleGetCampaignRecommendationList(mobileNo) {
    let self = this;
    this.setState({
      isCustomerModelLoader: true,
    });
    let inputParams = {
      mobileNumber: mobileNo,
    };
    this.CampaignService.PostWithParams(
      "/StoreCampaign/GetCampaignRecommendationList",
      inputParams
    )
      .then((response) => {
        var message = response.message;
        var responseData = response.responseData;
        self.setState({
          isCustomerModelLoader: false,
        });
        if (message === "Success" && responseData) {
          self.setState({ campaignrecommended: responseData });
          if (responseData[0].itemCode !== "") {
            self.setState({
              showRecommandedtab: true,
            });
          } else {
            self.setState({
              showRecommandedtab: false,
            });
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  ///handle get store campaign ket insight
  handleGetStoreCampaignKeyInsight(
    mobileNo,
    campaignId,
    lifeTimeValue,
    visitCount
  ) {
    let self = this;
    this.setState({
      isCustomerModelLoader: true,
    });
    let inputParams = {
      mobileNumber: mobileNo,
      campaignID: campaignId,
      lifetimeValue: lifeTimeValue || "",
      VisitCount: visitCount || "",
    };
    this.CampaignService.PostWithParams(
      "/StoreCampaign/GetStoreCampaignKeyInsight",
      inputParams
    )
      .then((response) => {
        var message = response.message;
        var responseData = response.responseData;
        self.setState({
          isCustomerModelLoader: false,
        });
        if (message === "Success" && responseData) {
          if (responseData.insightText.length > 0) {
            if (
              document.getElementById("insight-data").offsetWidth <
              document.getElementById("insight-data").scrollWidth
            ) {
              self.setState({ insightShowImg: true });
            }
          }
        } else {
          self.setState({ insightShowImg: false });
        }
        self.setState({ campaignkeyinsight: responseData });
      })
      .catch((error) => {
        self.setState({
          isCustomerModelLoader: false,
        });
        console.log(error);
      });
  }

  ///handle get customer pop up details
  handleGetCustomerpopupDetails(mobileNo, campaignId) {
    let self = this;
    this.setState({
      isCustomerModelLoader: true,
    });
    let inputParams = {
      mobileNumber: mobileNo,
      campaignID: campaignId,
    };
    this.CampaignService.PostWithParams(
      "/StoreCampaign/GetCustomerpopupDetails",
      inputParams
    )
      .then((response) => {
        var message = response.message;
        var responseData = response.responseData;
        self.setState({
          isCustomerModelLoader: false,
        });
        if (message === "Success" && responseData) {
          self.setState({
            useratvdetails: responseData.useratvdetails,
          });
        }
        self.handleGetStoreCampaignKeyInsight(
          mobileNo,
          campaignId,
          responseData.useratvdetails.lifeTimeValue,
          responseData.useratvdetails.visitCount
        );
      })
      .catch((error) => {
        self.setState({
          isCustomerModelLoader: false,
        });
        console.log(error);
      });
  }
  handleshareNowButtonClick = () => {
    if (this.state.selectedButton === 1) {
      this.handleSendViaSMS(this.state.customerModalDetails);
    }
    if (this.state.selectedButton === 2) {
      this.handleSendViaMessanger(this.state.customerModalDetails);
    }
    if (this.state.selectedButton === 3) {
      this.handleSendViaBotData(this.state.customerModalDetails);
    }
  };
  handleshareOptionSelect = (id) => {
    this.setState({ selectedButton: id });
  };
  handlegetCampaignDetailsById() {
    this.handlegetCampaignDetailsById(1, 10);
  }
  handleGotoPageNumberChange = (e) => {
    if (!isNaN(e.target.value)) {
      this.setState({
        gotoPageNo: e.target.value,
      });
    } else {
    }
  };
  handleGotoPageChange = async () => {
    if (this.state.childTotalGridRecord > 9 && this.state.gotoPageNo) {
      if (
        this.state.childTotalGridRecord / this.state.ChildPostsPerPage + 1 >=
        Number(this.state.gotoPageNo)
      ) {
        await this.setState({
          childCurrentPage: Number(this.state.gotoPageNo),
        });
        this.handleGetCampaignCustomer(
          this.state.campaignID,
          this.state.childTotalGridRecord,
          "GoTo",
          Number(this.state.gotoPageNo)
        );
      } else {
        NotificationManager.error("Page no not find.");
      }
    } else {
      NotificationManager.error("Page no not find.");
    }
  };
  handleBroadcastCustomerTypeChange = (e) => {
    this.setState({
      broadcastCustomerType: e.target.value,
    });
  };
  handleBroadcastNextButtonClick = () => {
    if (this.state.expandedRowKeys.length === 0) {
      if (this.state.isMobileView) {
        this.setState({
          ResponsiveBroadCast: false,
        });
        this.handelCampaignRowClick(this.state.selectedBroadcastRowData);
      } else {
        this.handleGetCampaignCustomerData(
          true,
          this.state.selectedBroadcastRowData
        );
      }
    }
  };
  onSelectChange = (selectedCustomerRowKeys) => {
    this.setState({ selectedCustomerRowKeys });
  };
  handleMobileSubmit = async (selectedCustomer) => {
    await this.setState({
      selectedCustomerRowKeys: selectedCustomer,
    });
    this.handleBroadcastExecute(this.state.storeCode, this.state.campaignCode);
  };
  handleCancelBroadcast = () => {
    this.setState({
      selectedCustomerRowKeys: [],
      isTableRowClick: true,
    });
    setTimeout(() => {
      this.setState({
        isTableRowClick: false,
      });
    }, 50);
  };
  render() {
    const { selectedCustomerRowKeys } = this.state;
    const rowCustomerSelection = {
      selectedCustomerRowKeys,
      onChange: this.onSelectChange,
    };
    const TranslationContext = this.state.translateLanguage.default;
    return (
      <div className="custom-tableak mobback">
        <div className="appcusto">
          <span
            className="apleft"
            style={{
              width: this.state.isTableRowClick ? "calc(100% - 80px)" : "",
            }}
          >
            Campaign Name&nbsp;
            {this.state.isTableRowClick ? ": " + this.state.campaignName : null}
          </span>
          {!this.state.isTableRowClick ? (
            <span className="apright">Customers</span>
          ) : (
            <a
              className="aprightbck apright"
              onClick={this.handleCampaignBackClick.bind(this)}
              style={{ color: "#2561a8" }}
            >
              BACK
            </a>
          )}
        </div>
        <div className="table-cntr store mobcamp">
          {!this.state.isTableRowClick ? (
            <div>
              <Table
                className="components-table-demo-nested antd-table-campaign custom-antd-table"
                columns={[
                  {
                    title:
                      TranslationContext !== undefined
                        ? TranslationContext.title.campaignname
                        : "Campaign Name",
                    dataIndex: "campaignName",
                    className:
                      "camp-status-header camp-status-header-cust-name campname",
                    filterDropdown: (dataIndex) => (
                      <div className="cust-name-drpdwn">
                        <label>
                          {TranslationContext !== undefined
                            ? TranslationContext.label.campaignname
                            : "Campaign Name"}
                        </label>
                        <input
                          type="text"
                          className="txt-1"
                          autoComplete="off"
                          maxLength={100}
                          placeholder={
                            TranslationContext !== undefined
                              ? TranslationContext.placeholder.entercampaignname
                              : "Enter Campaign Name"
                          }
                          value={this.state.filterCampName}
                          onChange={this.handleCampaignNameOnchange.bind(this)}
                        />
                        {this.state.filterCampName.length > 1 && (
                          <p style={{ color: "red", marginBottom: "0px" }}>
                            {this.state.CampCustNameValidation}
                          </p>
                        )}
                      </div>
                    ),
                    filterDropdownVisible: this.state.filterCampaignName,
                    onFilterDropdownVisibleChange: (visible) =>
                      this.setState({ filterCampaignName: visible }),
                    filterIcon: (filtered) => (
                      <span
                        style={{ color: filtered ? "#1890ff" : undefined }}
                      ></span>
                    ),
                    render: (row, item) => {
                      return (
                        <div className="backcolo bord1">
                          <label className="customernamecam">
                            {item.campaignName}
                          </label>
                          <img
                            className="info-icon-cp hidedesk"
                            src={BlackInfoIcon}
                            alt="info-icon"
                            onClick={this.responsiveCustModalOpen.bind(
                              this,
                              item
                            )}
                          />
                          <label className="d-none mobstat">
                            {item.status}
                          </label>
                        </div>
                      );
                    },
                  },
                  {
                    title:
                      TranslationContext !== undefined
                        ? TranslationContext.title.customers
                        : "Customers",
                    dataIndex: "customerCount",
                    className: "mob-custright",
                    render: (row, item) => {
                      return (
                        <div className="mob-backcolo">
                          <div className="mob-broadcast-icon">
                            <img
                              src={BroadCastIcon}
                              alt="cancel-icone"
                              onClick={this.handleGetBroadcastConfiguration.bind(
                                this,
                                item.storeCode,
                                item.campaignCode,
                                item
                              )}
                              className="broadcastimg"
                            />
                          </div>
                        </div>
                      );
                    },
                  },
                  {
                    title:
                      TranslationContext !== undefined
                        ? TranslationContext.title.customers
                        : "Customers",
                    dataIndex: "customerCount",
                    className: "custright",
                    render: (row, item) => {
                      return (
                        <div className="backcolo bord2">
                          <label>{item.customerCount}</label>
                          <div className="rightarrow">
                            <img
                              src={WhiteRightArrow}
                              className=""
                              width="10px"
                            />
                          </div>
                        </div>
                      );
                    },
                  },
                  {
                    title:
                      TranslationContext !== undefined
                        ? TranslationContext.title.campaignscript
                        : "Campaign Script",
                    dataIndex: "campaignScript",
                    className: "table-coloum-hide",
                    render: (row, item) => {
                      return (
                        <div className="chatbotwid">
                          <Popover
                            overlayClassName="antcustom"
                            content={
                              <div className="insertpop1">
                                <div className="dash-creation-popup custompop">
                                  <label className="poptitle">
                                    {TranslationContext !== undefined
                                      ? TranslationContext.label.ChatbotScript
                                      : "Chatbot Script"}
                                  </label>
                                  <label className="channelScript">
                                    {item.chatbotScript}
                                  </label>
                                </div>
                              </div>
                            }
                            placement="bottom"
                          >
                            <a className="button-red">
                              <img
                                className="ico"
                                src={ChatbotS}
                                alt="Chatbot Icon"
                              />
                              {TranslationContext !== undefined
                                ? TranslationContext.label.ChatbotScript
                                : "Chatbot Script"}
                            </a>
                          </Popover>
                          <Popover
                            overlayClassName="antcustom"
                            content={
                              <div className="insertpop1">
                                <div className="dash-creation-popup custompop">
                                  <label className="poptitle">
                                    {TranslationContext !== undefined
                                      ? TranslationContext.label.smsscript
                                      : "SMS Script"}
                                  </label>
                                  <label className="channelScript">
                                    {item.smsScript}
                                  </label>
                                </div>
                              </div>
                            }
                            placement="bottom"
                          >
                            <a className="button-blue">
                              <img
                                className="ico"
                                src={Smsicon}
                                alt="Chatbot Icon"
                              />
                              {TranslationContext !== undefined
                                ? TranslationContext.label.smsscript
                                : "SMS Script"}
                            </a>
                          </Popover>
                          <Popover
                            overlayClassName="antcustom"
                            content={
                              <div className="insertpop1">
                                <div className="dash-creation-popup custompop">
                                  <label className="poptitle">
                                    Whatsapp Script
                                  </label>
                                  <label className="channelScript">
                                    {item.whatsAppScript}
                                  </label>
                                </div>
                              </div>
                            }
                            placement="bottom"
                          >
                            <a className="button-green">
                              <img
                                className="ico"
                                src={Whatsapp}
                                alt="Whatsapp Icon"
                              />
                              Whatsapp Script
                            </a>
                          </Popover>
                        </div>
                      );
                    },
                  },
                  {
                    title:
                      TranslationContext !== undefined
                        ? TranslationContext.title.campaignperiod
                        : "Campaign Period",
                    dataIndex: "campaingPeriod",
                    className: "table-coloum-hide",
                  },
                  {
                    title:
                      TranslationContext !== undefined
                        ? TranslationContext.title.status
                        : "Status",
                    dataIndex: "status",
                    className:
                      "camp-status-header camp-status-header-statusFilter stat",
                    filterDropdown: (data, row) => {
                      return (
                        <div className="campaign-status-drpdwn">
                          <ul>
                            <li>
                              <input
                                type="checkbox"
                                id="Campall-status"
                                className="ch1"
                                onChange={this.handleCheckCampAllStatus.bind(
                                  this
                                )}
                                checked={this.state.CheckBoxAllStatus}
                                name="CampallStatus"
                              />
                              <label htmlFor="Campall-status">
                                <span className="ch1-text">
                                  {TranslationContext !== undefined
                                    ? TranslationContext.span.all
                                    : "All"}
                                </span>
                              </label>
                            </li>
                            <li>
                              <input
                                type="checkbox"
                                id="New100"
                                className="ch1"
                                onChange={this.handleCheckCampIndividualStatus.bind(
                                  this
                                )}
                                name="CampallStatus"
                                attrIds={100}
                              />
                              <label htmlFor="New100">
                                <span className="ch1-text">
                                  {TranslationContext !== undefined
                                    ? TranslationContext.span.new
                                    : "New"}
                                </span>
                              </label>
                            </li>
                            <li>
                              <input
                                type="checkbox"
                                id="Inproress101"
                                className="ch1"
                                onChange={this.handleCheckCampIndividualStatus.bind(
                                  this
                                )}
                                name="CampallStatus"
                                attrIds={101}
                              />
                              <label htmlFor="Inproress101">
                                <span className="ch1-text">
                                  {TranslationContext !== undefined
                                    ? TranslationContext.span.inprogress
                                    : "InProgress"}
                                </span>
                              </label>
                            </li>
                            <li>
                              <input
                                type="checkbox"
                                id="Close102"
                                className="ch1"
                                onChange={this.handleCheckCampIndividualStatus.bind(
                                  this
                                )}
                                name="CampallStatus"
                                attrIds={102}
                              />
                              <label htmlFor="Close102">
                                <span className="ch1-text">
                                  {TranslationContext !== undefined
                                    ? TranslationContext.span.close
                                    : "Close"}
                                </span>
                              </label>
                            </li>
                          </ul>
                        </div>
                      );
                    },
                    filterDropdownVisible: this.state.filterCampaignStatus,
                    onFilterDropdownVisibleChange: (visible) =>
                      this.setState({ filterCampaignStatus: visible }),
                    filterIcon: (filtered) => (
                      <span
                        style={{ color: filtered ? "#1890ff" : undefined }}
                      ></span>
                    ),
                  },
                  {
                    title:
                      TranslationContext !== undefined
                        ? TranslationContext.title.actions
                        : "Actions",
                    className: "actbtnmob",
                    render: (row, item) => {
                      return (
                        <Popover
                          overlayClassName="antcustom antbroadcast"
                          content={
                            <React.Fragment>
                              <div className="general-popover popover-body broadcastpop">
                                {this.state.campaignExecutionDetails.length >
                                0 ? (
                                  <label className="broadcasttitle">
                                    {TranslationContext !== undefined
                                      ? TranslationContext.label.recentcampaigns
                                      : "Recent Campaigns"}
                                  </label>
                                ) : null}

                                {this.state.campaignExecutionDetails !== null &&
                                  this.state.campaignExecutionDetails.map(
                                    (item, b) => (
                                      <div
                                        className="broembox clearfix"
                                        key={b}
                                      >
                                        <p>
                                          <label>{item.channelType}</label>
                                          <span>
                                            {TranslationContext !== undefined
                                              ? TranslationContext.label
                                                  .ExecutedDate
                                              : "Executed Date"}
                                            : {item.executionDate}
                                          </span>
                                        </p>
                                      </div>
                                    )
                                  )}
                                <>
                                  <label className="broadcastsubtitle">
                                    {TranslationContext !== undefined
                                      ? TranslationContext.label.choosechannel
                                      : "Choose Broadcast Channel"}
                                  </label>
                                  <div>
                                    <Radio.Group
                                      onChange={this.handleBroadcastChange}
                                      value={this.state.broadcastChannel}
                                    >
                                      {this.state.broadcastConfiguration
                                        .emailFlag ? (
                                        <Radio
                                          className="broadChannel"
                                          value="Email"
                                          disabled={
                                            this.state.broadcastConfiguration
                                              .disableEmail
                                          }
                                        >
                                          {TranslationContext !== undefined
                                            ? TranslationContext.radio.email
                                            : "Email"}
                                        </Radio>
                                      ) : null}
                                      {this.state.broadcastConfiguration
                                        .smsFlag ? (
                                        <Radio
                                          className="broadChannel"
                                          value="SMS"
                                          disabled={
                                            this.state.broadcastConfiguration
                                              .disableSMS
                                          }
                                        >
                                          {TranslationContext !== undefined
                                            ? TranslationContext.radio.sms
                                            : "SMS"}
                                        </Radio>
                                      ) : null}
                                      {this.state.broadcastConfiguration
                                        .whatsappFlag ? (
                                        <Radio
                                          className="broadChannel"
                                          value="Whatsapp"
                                          disabled={
                                            this.state.broadcastConfiguration
                                              .disableWhatsapp
                                          }
                                        >
                                          {TranslationContext !== undefined
                                            ? TranslationContext.radio.whatsapp
                                            : "Whatsapp"}
                                        </Radio>
                                      ) : null}
                                      {this.state.broadcastConfiguration
                                        .chatSessionActiveFlag ? (
                                        <Radio
                                          className="broadChannel"
                                          value="ChatSession"
                                          disabled={
                                            this.state.broadcastConfiguration
                                              .disableChatSessionActiveFlag
                                          }
                                        >
                                          Chat Session Active
                                        </Radio>
                                      ) : null}
                                    </Radio.Group>

                                    {this.state.broadcastChannel === "" && (
                                      <p
                                        style={{
                                          color: "red",
                                          marginBottom: "0px",
                                        }}
                                      >
                                        {this.state.broadChannelValidation}
                                      </p>
                                    )}
                                  </div>
                                  <label className="broadcastsubtitle">
                                    Choose Customer
                                  </label>
                                  <div>
                                    <Radio.Group
                                      onChange={
                                        this.handleBroadcastCustomerTypeChange
                                      }
                                      value={this.state.broadcastCustomerType}
                                    >
                                      <Radio className="broadChannel" value={1}>
                                        All Customer
                                      </Radio>

                                      <Radio className="broadChannel" value={2}>
                                        Selective Customer
                                      </Radio>
                                    </Radio.Group>
                                  </div>
                                </>
                              </div>
                              {this.state.broadcastCustomerType === 1 ? (
                                <button
                                  type="button"
                                  className="executebtn"
                                  onClick={this.handleBroadcastExecute.bind(
                                    this,
                                    row.storeCode,
                                    row.campaignCode
                                  )}
                                >
                                  {TranslationContext !== undefined
                                    ? TranslationContext.button.execute
                                    : "Execute"}
                                </button>
                              ) : (
                                <button
                                  type="button"
                                  className="executebtn"
                                  onClick={this.handleBroadcastNextButtonClick.bind(
                                    this
                                  )}
                                >
                                  {TranslationContext !== undefined
                                    ? TranslationContext.button.next
                                    : "Next"}
                                </button>
                              )}
                            </React.Fragment>
                          }
                          placement="bottom"
                          trigger="click"
                        >
                          <div className="broadcast-icon">
                            <img
                              src={BroadCastIcon}
                              alt="cancel-icone"
                              onClick={this.handleGetBroadcastConfiguration.bind(
                                this,
                                row.storeCode,
                                row.campaignCode,
                                row
                              )}
                              className="broadcastimg"
                            />
                          </div>
                        </Popover>
                      );
                    },
                  },
                ]}
                expandedRowRender={(row, item) => {
                  return (
                    <div>
                      <Table
                        // pagination={{
                        //   position: ["bottomCenter"],
                        //   current: this.state.childCurrentPage,
                        //   pageSizeOptions: [10, 20, 30],
                        //   showQuickJumper: true,
                        //   showSizeChanger: true,
                        //   total: this.state.childTotalGridRecord,
                        // }}
                        rowSelection={
                          this.state.broadcastCustomerType === 2
                            ? rowCustomerSelection
                            : null
                        }
                        dataSource={this.state.CampChildTableData.filter(
                          (x) => x.campaignScriptID === row.campaignID
                        )}
                        className="midalResponseAction expading"
                        columns={[
                          {
                            title:
                              TranslationContext !== undefined
                                ? TranslationContext.title.customername
                                : "Customer Name",
                            className:
                              "camp-status-header camp-status-header-cust-name",
                            dataIndex: "id",
                            filterDropdown: (dataIndex) => (
                              <div className="cust-name-drpdwn">
                                <label>
                                  {TranslationContext !== undefined
                                    ? TranslationContext.label.customernumber
                                    : "Customer Number"}
                                </label>
                                <input
                                  type="text"
                                  className="txt-1"
                                  autoComplete="off"
                                  placeholder={
                                    TranslationContext !== undefined
                                      ? TranslationContext.placeholder
                                          .entermobileno
                                      : "Enter Mobile No"
                                  }
                                  maxLength={10}
                                  value={this.state.filterCustNO}
                                  onChange={this.handleCustomerFilerOnchange.bind(
                                    this,
                                    row.campaignID,
                                    row.customerCount
                                  )}
                                />
                                {this.state.filterCustNO.length > 1 && (
                                  <p
                                    style={{
                                      color: "red",
                                      marginBottom: "0px",
                                    }}
                                  >
                                    {this.state.custMobileValidation}
                                  </p>
                                )}
                              </div>
                            ),
                            filterDropdownVisible:
                              this.state.filterCustomerNumber,
                            onFilterDropdownVisibleChange: (visible) =>
                              this.setState({ filterCustomerNumber: visible }),
                            filterIcon: (filtered) => (
                              <span
                                style={{
                                  color: filtered ? "#1890ff" : undefined,
                                }}
                              ></span>
                            ),
                            render: (row, item) => {
                              return (
                                <div className="backcoloin bord3">
                                  <div className="d-flex">
                                    <p
                                      className="cust-name"
                                      oncontextmenu="return false;"
                                      onClick={this.handleGetCustomerDataForModal.bind(
                                        this,
                                        item
                                      )}
                                    >
                                      {item.customerName}
                                    </p>
                                    {/* {item.isCustomerChating && ( */}
                                    <img
                                      className="ico cr-pnt ml-2"
                                      src={Whatsapp}
                                      alt="Whatsapp Icon"
                                      // onClick={this.handleWhatsAppIconClick.bind(
                                      //   this,
                                      //   item
                                      // )}
                                    />
                                    {/* )} */}
                                  </div>
                                  <div className="text_copied_div">
                                    <div className="sml-fnt">
                                      {this.state.isNumberMasking === true &&
                                      this.state.tenantMobileMasking ===
                                        true ? (
                                        <p className="num">
                                          {handleMaskingNumber(
                                            item.customerNumber,
                                            this.state.maskingPrefix,
                                            this.state.maskingSufix
                                          )}
                                        </p>
                                      ) : (
                                        <p className="num stop_copying">
                                          {item.customerNumber}
                                        </p>
                                      )}
                                    </div>
                                    {this.state.isNumberMasking === true &&
                                    this.state.tenantMobileMasking === true ? (
                                      <p>
                                        <img
                                          className="imgsz"
                                          src={copy}
                                          onClick={(e) =>
                                            this.handleCopiedNumber(
                                              e,
                                              item.customerNumber,
                                              item.id
                                            )
                                          }
                                        />
                                      </p>
                                    ) : null}
                                    {this.state.isTextCopied &&
                                    this.state.copiedTextChatId === item.id ? (
                                      <label className="text_copied">
                                        {" "}
                                        Copied{" "}
                                      </label>
                                    ) : null}
                                  </div>
                                </div>
                              );
                            },
                          },
                          {
                            title:
                              TranslationContext !== undefined
                                ? TranslationContext.title.date
                                : "Date",
                            dataIndex: "campaignDate",
                            className: "table-coloum-hide",
                          },
                          {
                            title:
                              TranslationContext !== undefined
                                ? TranslationContext.title.response
                                : "Response",
                            className: "table-coloum-hide",
                            render: (row, item) => {
                              return (
                                <div>
                                  <select
                                    className="responceDrop-down dropdown-label"
                                    value={item.responseID}
                                    onChange={this.onResponseChange.bind(
                                      this,
                                      item.id,
                                      item
                                    )}
                                  >
                                    <option hidden>
                                      {TranslationContext !== undefined
                                        ? TranslationContext.option
                                            .SelectResponse
                                        : "Select Response"}
                                    </option>
                                    {item.hsCampaignResponseList !== null &&
                                      item.hsCampaignResponseList.map(
                                        (items, i) => (
                                          <option
                                            key={i}
                                            value={items.responseID}
                                          >
                                            {items.response}
                                          </option>
                                        )
                                      )}
                                  </select>
                                </div>
                              );
                            },
                          },
                          {
                            title:
                              TranslationContext !== undefined
                                ? TranslationContext.title.status
                                : "Status",
                            dataIndex: "statusName",
                            className: "camp-status-header",
                            render: (row, item) => {
                              return (
                                <div className="backcoloin bord4">
                                  {item.statusID === 100 ? (
                                    <label className="table-btnlabel contactBtnGreen">
                                      {item.statusName}
                                    </label>
                                  ) : item.statusID === 101 ? (
                                    <label className="table-btnlabel notConnectedBtnRed">
                                      {item.statusName}
                                    </label>
                                  ) : item.statusID === 102 ? (
                                    <label className="table-btnlabel followUpBtnYellow">
                                      {item.statusName}
                                    </label>
                                  ) : item.statusID === 104 ? (
                                    <label className="table-btnlabel followUpBtnBlue">
                                      {item.statusName}
                                    </label>
                                  ) : null}
                                </div>
                              );
                            },
                            filterDropdown: (dataIndex) => (
                              <div className="campaign-status-drpdwn">
                                <ul>
                                  <li>
                                    <input
                                      type="checkbox"
                                      id="all-status"
                                      className="ch1"
                                      onChange={this.checkAllStatus.bind(
                                        this,
                                        row.campaignID,
                                        row.customerCount
                                      )}
                                      checked={this.state.CheckBoxAllBrand}
                                      name="allStatus"
                                    />
                                    <label htmlFor="all-status">
                                      <span className="ch1-text">
                                        {TranslationContext !== undefined
                                          ? TranslationContext.option.all
                                          : "All"}
                                      </span>
                                    </label>
                                  </li>
                                  <li>
                                    <input
                                      type="checkbox"
                                      id="status100"
                                      className="ch1"
                                      onChange={this.checkIndividualStatus.bind(
                                        this,
                                        row.campaignID,
                                        row.customerCount
                                      )}
                                      name="allStatus"
                                      attrIds={100}
                                    />
                                    <label htmlFor="status100">
                                      <span className="ch1-text">
                                        {TranslationContext !== undefined
                                          ? TranslationContext.span.Contacted
                                          : "Contacted"}
                                      </span>
                                    </label>
                                  </li>
                                  <li>
                                    <input
                                      type="checkbox"
                                      id="status101"
                                      className="ch1"
                                      onChange={this.checkIndividualStatus.bind(
                                        this,
                                        row.campaignID,
                                        row.customerCount
                                      )}
                                      name="allStatus"
                                      attrIds={101}
                                    />
                                    <label htmlFor="status101">
                                      <span className="ch1-text">
                                        {TranslationContext !== undefined
                                          ? TranslationContext.span.NotContacted
                                          : "Not Contacted"}
                                      </span>
                                    </label>
                                  </li>
                                  <li>
                                    <input
                                      type="checkbox"
                                      id="status102"
                                      className="ch1"
                                      onChange={this.checkIndividualStatus.bind(
                                        this,
                                        row.campaignID,
                                        row.customerCount
                                      )}
                                      name="allStatus"
                                      attrIds={102}
                                    />
                                    <label htmlFor="status102">
                                      <span className="ch1-text">
                                        {TranslationContext !== undefined
                                          ? TranslationContext.span.FollowUp
                                          : "Follow Up"}
                                      </span>
                                    </label>
                                  </li>
                                  <li>
                                    <input
                                      type="checkbox"
                                      id="status103"
                                      className="ch1"
                                      onChange={this.checkIndividualStatus.bind(
                                        this,
                                        row.campaignID,
                                        row.customerCount
                                      )}
                                      name="allStatus"
                                      attrIds={103}
                                    />
                                    <label htmlFor="status103">
                                      <span className="ch1-text">
                                        {TranslationContext !== undefined
                                          ? TranslationContext.span.Converted
                                          : "Converted"}
                                      </span>
                                    </label>
                                  </li>
                                  <li>
                                    <input
                                      type="checkbox"
                                      id="status104"
                                      className="ch1"
                                      onChange={this.checkIndividualStatus.bind(
                                        this,
                                        row.campaignID,
                                        row.customerCount
                                      )}
                                      name="allStatus"
                                      attrIds={104}
                                    />
                                    <label htmlFor="status104">
                                      <span className="ch1-text">
                                        {TranslationContext !== undefined
                                          ? TranslationContext.span.Conversation
                                          : "Conversation"}
                                      </span>
                                    </label>
                                  </li>
                                </ul>
                              </div>
                            ),
                            filterDropdownVisible:
                              this.state.filterDropdownVisible,
                            onFilterDropdownVisibleChange: (visible) =>
                              this.setState({ filterDropdownVisible: visible }),
                            filterIcon: (filtered) => (
                              <span
                                style={{
                                  color: filtered ? "#1890ff" : undefined,
                                }}
                              ></span>
                            ),
                          },
                          {
                            title:
                              TranslationContext !== undefined
                                ? TranslationContext.title.callrecheduledto
                                : "Call Recheduled To",
                            className: "table-coloum-hide",
                            dataIndex: "pricePaid",
                            render: (row, item) => {
                              return (
                                <div
                                  className={
                                    item.responseID === 3
                                      ? ""
                                      : "disabled-input"
                                  }
                                >
                                  <div className="date-time-resp">
                                    <DatePicker
                                      id={"startDate" + item.id}
                                      autoComplete="off"
                                      showTimeSelect
                                      name="startDate"
                                      minDate={new Date()}
                                      showMonthDropdown
                                      showYearDropdown
                                      selected={
                                        item.callRescheduledTo !== ""
                                          ? new Date(item.callRescheduledTo)
                                          : new Date()
                                      }
                                      dateFormat="MM/dd/yyyy h:mm aa"
                                      value={
                                        item.callRescheduledTo !== ""
                                          ? moment(item.callRescheduledTo)
                                          : ""
                                      }
                                      onChange={this.onDateChange.bind(
                                        this,
                                        item.id
                                      )}
                                      className={
                                        item.responseID === 3 &&
                                        item.dateTimeHighlight &&
                                        !item.callRescheduledTo
                                          ? "txtStore dateTimeStore dateTimeStore-highlight"
                                          : item.responseID === 3
                                          ? "txtStore dateTimeStore"
                                          : "txtStore dateTimeStore disabled-link"
                                      }
                                      placeholderText={
                                        TranslationContext !== undefined
                                          ? TranslationContext.placeholder
                                              .selecttimeanddate
                                          : "Select Date & Time"
                                      }
                                    />
                                  </div>
                                </div>
                              );
                            },
                          },
                          {
                            title:
                              TranslationContext !== undefined
                                ? TranslationContext.title.actions
                                : "Actions",
                            render: (row, item) => {
                              return (
                                <div>
                                  <div className="table-coloum-hide status-btn-camp">
                                    <div>
                                      <button
                                        className="saveBtn saveLabel"
                                        type="button"
                                        onClick={this.handleUpdateCampaignResponse.bind(
                                          this,
                                          item.id,
                                          item.responseID,
                                          item.callRescheduledTo,
                                          item.campaignScriptID
                                        )}
                                      >
                                        {TranslationContext !== undefined
                                          ? TranslationContext.button.update
                                          : "Update"}
                                      </button>
                                      <button
                                        className="raisedticket-Btn"
                                        style={{ display: "none" }}
                                        type="button"
                                      >
                                        <label className="raise-ticketLbl">
                                          {TranslationContext !== undefined
                                            ? TranslationContext.span
                                                .RaiseTicket
                                            : "Raise Ticket"}
                                        </label>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              );
                            },
                          },
                        ]}
                        expandedRowRender={(row, item) => {
                          return (
                            <div className="innertabcollapse">
                              <table>
                                <tbody>
                                  <tr>
                                    <td>
                                      <label>
                                        {TranslationContext !== undefined
                                          ? TranslationContext.label
                                              .CustomerName
                                          : "Customer Name"}
                                      </label>
                                    </td>
                                    <td>
                                      <div className="d-flex">
                                        <label className=" cust-name">
                                          {row.customerName}
                                        </label>
                                        {row.isCustomerChating && (
                                          <img
                                            className="ico cr-pnt ml-1"
                                            src={Whatsapp}
                                            alt="Whatsapp Icon"
                                            onClick={this.handleWhatsAppIconClick.bind(
                                              this,
                                              row
                                            )}
                                          />
                                        )}
                                      </div>
                                      <span className="sml-fnt">
                                        {row.customerNumber}
                                      </span>
                                    </td>
                                    <td></td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <label>
                                        {TranslationContext !== undefined
                                          ? TranslationContext.label.date
                                          : "Date"}
                                      </label>
                                    </td>
                                    <td>
                                      <label>{row.campaignDate}</label>
                                    </td>
                                    <td></td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <label>
                                        {TranslationContext !== undefined
                                          ? TranslationContext.label.Response
                                          : "Response"}
                                      </label>
                                    </td>
                                    <td>
                                      <select
                                        className="responceDrop-down dropdown-label"
                                        value={row.responseID}
                                        onChange={this.onResponseChange.bind(
                                          this,
                                          row.id,
                                          row
                                        )}
                                      >
                                        <option hidden>
                                          {TranslationContext !== undefined
                                            ? TranslationContext.option
                                                .SelectResponse
                                            : "Select Response"}
                                        </option>
                                        {row.hsCampaignResponseList !== null &&
                                          row.hsCampaignResponseList.map(
                                            (items, i) => (
                                              <option
                                                key={i}
                                                value={items.responseID}
                                              >
                                                {items.response}
                                              </option>
                                            )
                                          )}
                                      </select>
                                    </td>
                                    <td></td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <label>
                                        {TranslationContext !== undefined
                                          ? TranslationContext.label.status
                                          : "Status"}
                                      </label>
                                    </td>
                                    <td>
                                      <div>
                                        {row.statusID === 100 ? (
                                          <label className="table-btnlabel contactBtnGreen">
                                            {row.statusName}
                                          </label>
                                        ) : row.statusID === 101 ? (
                                          <label className="table-btnlabel notConnectedBtnRed">
                                            {row.statusName}
                                          </label>
                                        ) : row.statusID === 102 ? (
                                          <label className="table-btnlabel followUpBtnYellow">
                                            {row.statusName}
                                          </label>
                                        ) : row.statusID === 104 ? (
                                          <label className="table-btnlabel followUpBtnBlue">
                                            {row.statusName}
                                          </label>
                                        ) : null}
                                      </div>
                                    </td>
                                    <td></td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <label>
                                        {TranslationContext !== undefined
                                          ? TranslationContext.label
                                              .callrescheduledto
                                          : "Call Rescheduled to"}
                                      </label>
                                    </td>
                                    <td>
                                      <div
                                        className={
                                          row.responseID === 3
                                            ? ""
                                            : "disabled-input"
                                        }
                                      >
                                        <div className="date-time-resp">
                                          <DatePicker
                                            id={"startDate" + item.id}
                                            autoComplete="off"
                                            showTimeSelect
                                            name="startDate"
                                            minDate={new Date()}
                                            showMonthDropdown
                                            showYearDropdown
                                            selected={
                                              row.callRescheduledTo !== ""
                                                ? new Date(
                                                    row.callRescheduledTo
                                                  )
                                                : new Date()
                                            }
                                            dateFormat="MM/dd/yyyy h:mm aa"
                                            value={
                                              row.callRescheduledTo !== ""
                                                ? moment(row.callRescheduledTo)
                                                : ""
                                            }
                                            onChange={this.onDateChange.bind(
                                              this,
                                              row.id
                                            )}
                                            className={
                                              row.responseID === 3 &&
                                              row.dateTimeHighlight &&
                                              !row.callRescheduledTo
                                                ? "txtStore dateTimeStore dateTimeStore-highlight"
                                                : row.responseID === 3
                                                ? "txtStore dateTimeStore"
                                                : "txtStore dateTimeStore disabled-link"
                                            }
                                            placeholderText={
                                              TranslationContext !== undefined
                                                ? TranslationContext.placeholder
                                                    .selectdateandtime
                                                : "Select Date & Time"
                                            }
                                          />
                                        </div>
                                      </div>
                                    </td>
                                    <td></td>
                                  </tr>
                                  <tr>
                                    <td colSpan="3">
                                      <div
                                        className="mobflo"
                                        style={{ float: "right" }}
                                      >
                                        <button
                                          className="saveBtn saveLabel"
                                          type="button"
                                          onClick={this.handleUpdateCampaignResponse.bind(
                                            this,
                                            row.id,
                                            row.responseID,
                                            row.callRescheduledTo,
                                            row.campaignScriptID
                                          )}
                                        >
                                          {TranslationContext !== undefined
                                            ? TranslationContext.button.update
                                            : "Update"}
                                        </button>
                                        <button
                                          className="raisedticket-Btn saveLabel"
                                          style={{ display: "none" }}
                                        >
                                          {TranslationContext !== undefined
                                            ? TranslationContext.button
                                                .raiseticket
                                            : "Raise Ticket"}
                                        </button>
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          );
                        }}
                        rowKey={(record) => {
                          return record.id;
                        }}
                        expandIconColumnIndex={5}
                        expandIconAsCell={false}
                        pagination={false}
                        loading={this.state.ChildTblLoading}
                      />
                      <Pagination
                        currentPage={this.state.childCurrentPage}
                        totalSize={this.state.childTotalGridRecord}
                        sizePerPage={this.state.ChildPostsPerPage}
                        changeCurrentPage={this.PaginationOnChange}
                        theme="bootstrap"
                      />
                      <div className="position-relative">
                        <div className="item-selection Camp-pagination">
                          <Select
                            value={this.state.ChildPostsPerPage}
                            onChange={this.handlePageItemchange}
                          >
                            <Option value={10}>10</Option>
                            <Option value={20}>20</Option>
                            <Option value={30}>30</Option>
                          </Select>
                          <p>
                            {TranslationContext !== undefined
                              ? TranslationContext.p.itemperpage
                              : "Items per page"}
                          </p>
                        </div>
                      </div>
                      {this.state.selectedCustomerRowKeys.length > 0 ? (
                        <div>
                          <div className="camp-brod-div">
                            <button
                              onClick={this.handleCancelBroadcast.bind(this)}
                            >
                              Cancel
                            </button>
                            <button
                              disabled={this.state.isSendBroadcastLoader}
                              onClick={this.handleBroadcastExecute.bind(
                                this,
                                this.state.selectedBroadcastRowData
                                  ? this.state.selectedBroadcastRowData
                                      .storeCode
                                  : null,
                                this.state.selectedBroadcastRowData
                                  ? this.state.selectedBroadcastRowData
                                      .campaignCode
                                  : null
                              )}
                            >
                              {this.state.isSendBroadcastLoader ? (
                                <FontAwesomeIcon
                                  className="circular-loader"
                                  icon={faCircleNotch}
                                  spin
                                />
                              ) : null}
                              Execute
                            </button>
                          </div>
                        </div>
                      ) : null}
                      {this.state.childTotalGridRecord > 9 ? (
                        <div>
                          <div className="camp-goto-div">
                            <input
                              type="text"
                              value={this.state.gotoPageNo}
                              onChange={this.handleGotoPageNumberChange}
                            />
                            <button onClick={this.handleGotoPageChange}>
                              GoTo
                            </button>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  );
                }}
                onExpand={this.handleGetCampaignCustomerData}
                expandedRowKeys={this.state.expandedRowKeys}
                rowExpandable={this.state.isMobileView === false ? true : false}
                expandIconColumnIndex={
                  this.state.isMobileView === false ? 6 : -1
                }
                expandIconAsCell={false}
                pagination={false}
                loading={this.state.loading}
                dataSource={this.state.campaignGridData}
                onRowClick={this.handelCampaignRowClick.bind(this)}
              />
            </div>
          ) : (
            <div>
              <CampaignExpandComponent
                CampaignScriptID={this.state.campaignID}
                handleMobileSubmit={this.handleMobileSubmit.bind(this)}
                handleGetCustomerDataForModal={this.handleGetCustomerDataForModal.bind(
                  this
                )}
                broadcastCustomerType={this.state.broadcastCustomerType}
                broadcastChannel={this.state.broadcastChannel}
                handleUpdateCampaignResponse={this.handleUpdateCampaignResponse.bind(
                  this
                )}
                handlegetCampaignDetailsById={this.handlegetCampaignDetailsById.bind(
                  this
                )}
              />
            </div>
          )}
        </div>

        <Modal
          open={this.state.ResponsiveCustModal}
          onClose={this.responsiveCustModalClose.bind(this)}
          center
          modalId="customername-popup"
          overlayId="logout-ovrly"
          classNames={{ modal: "customername-popupbottom" }}
        >
          <div
            className="closemob"
            onClick={this.responsiveCustModalClose.bind(this)}
          ></div>
          <img
            src={CancelIcon}
            alt="cancel-icone"
            className="cust-icon"
            onClick={this.responsiveCustModalClose.bind(this)}
          />
          <div className="customername-popupmob">
            <Tabs>
              <Tab label="Chatbot Script">
                <div className="">
                  <div className="dash-creation-popup custompop">
                    <label className="poptitle chatbotmob">
                      {TranslationContext !== undefined
                        ? TranslationContext.label.ChatbotScript
                        : "Chatbot Script"}
                    </label>
                    <label className="channelScript">
                      {this.state.chatbotScript}
                    </label>
                  </div>
                  <div className="camperiod">
                    <h4>
                      {TranslationContext !== undefined
                        ? TranslationContext.h4.campaignperiod
                        : "Campaign Period"}
                      <span>{this.state.campaingPeriod}</span>
                    </h4>
                  </div>
                </div>
              </Tab>
              <Tab label="SMS Script">
                <div className="">
                  <div className="dash-creation-popup custompop">
                    <label className="poptitle chatbotmob">
                      {TranslationContext !== undefined
                        ? TranslationContext.label.smsscript
                        : "SMS Script"}
                    </label>
                    <label className="channelScript">
                      {this.state.smsScript}
                    </label>
                  </div>
                  <div className="camperiod">
                    <h4>
                      {TranslationContext !== undefined
                        ? TranslationContext.h4.campaignperiod
                        : "Campaign Period"}
                      <span>{this.state.campaingPeriod}</span>
                    </h4>
                  </div>
                </div>
              </Tab>
              <Tab label="Whatsapp Script">
                <div className="">
                  <div className="dash-creation-popup custompop">
                    <label className="poptitle chatbotmob">
                      Whatsapp Script
                    </label>
                    <label className="channelScript">
                      {this.state.whatsappScript}
                    </label>
                  </div>
                  <div className="camperiod">
                    <h4>
                      {TranslationContext !== undefined
                        ? TranslationContext.h4.campaignperiod
                        : "Campaign Period"}
                      <span>{this.state.campaingPeriod}</span>
                    </h4>
                  </div>
                </div>
              </Tab>
            </Tabs>
          </div>
        </Modal>

        <Modal
          open={this.state.custNameModal}
          onClose={this.handleCustomerNameModalClose.bind(this)}
          center
          modalId="customername-popup"
          overlayId="logout-ovrly"
          classNames={{ modal: "icon-popupbottom" }}
        >
          <div
            className="closemob"
            onClick={this.handleCustomerNameModalClose.bind(this)}
          ></div>
          <img
            src={CancelIcon}
            alt="cancel-icone"
            className="cust-icon"
            onClick={this.handleCustomerNameModalClose.bind(this)}
          />
          <div className="row">
            <div className="col-12 col-md-12">
              <div className="nr-initials">
                <p>{this.state.sortCustName}</p>
              </div>
              <div className="nr-name">
              <h3>
                  {this.state.shareCampaignViaSettingModal.customerName}{" "}
                  {this.state.isNumberMasking === true &&
                  this.state.tenantMobileMasking === true ? (
                    <p>
                      {handleMaskingNumber(
                        this.state.shareCampaignViaSettingModal.customerNumber,
                        this.state.maskingPrefix,
                        this.state.maskingSufix
                      )}
                    </p>
                  ) : (
                    <p className="num stop_copying">
                      {this.state.shareCampaignViaSettingModal.customerNumber}
                    </p>
                  )}
                </h3>
                <p>{this.state.useratvdetails.tiername}</p>
              </div>
            </div>
          </div>
          {this.state.isMobileView ? (
            <div>
              <label className="Sendvia">Send Via</label>
              <div className="col-12 p-0">
                <div className="sharecamp">
                  <a
                    style={{ display: "none" }}
                    id="whatsapp-share"
                    data-action="share/whatsapp/share"
                  ></a>
                  <ul className="sha">
                    {this.state.shareCampaignViaSettingModal.smsFlag ===
                    true ? (
                      <li
                        onClick={this.handleshareOptionSelect.bind(this, 1)}
                        className={
                          this.state.shareCampaignViaSettingModal.smsClickable
                            ? this.state.selectedButton == 1
                              ? "active"
                              : ""
                            : "dis-btns"
                        }
                        id={this.state.smsDisable ? "dis-sms" : ""}
                      >
                        {this.state.selectedButton == 1 ? (
                          <div className="sele">
                            <img
                              src={CardTick}
                              style={{ width: "15px" }}
                              alt={"select-card"}
                            />
                          </div>
                        ) : null}
                        <img className="ico" src={Sms1} alt="SMS Icon" />
                        {TranslationContext !== undefined
                          ? TranslationContext.li.sms
                          : "SMS"}
                      </li>
                    ) : null}
                    {this.state.shareCampaignViaSettingModal.emailFlag ===
                    true ? (
                      <li
                        className={
                          this.state.shareCampaignViaSettingModal.emailClickable
                            ? this.state.selectedButton == 4
                              ? "active"
                              : ""
                            : "dis-btns"
                        }
                      >
                        {this.state.selectedButton == 4 ? (
                          <div className="sele">
                            <img
                              src={CardTick}
                              style={{ width: "15px" }}
                              alt={"select-card"}
                            />
                          </div>
                        ) : null}
                        <img
                          className="emailico"
                          src={Email}
                          alt="Email Icon"
                        />
                        {TranslationContext !== undefined
                          ? TranslationContext.li.email
                          : "Email"}
                      </li>
                    ) : null}
                    {this.state.shareCampaignViaSettingModal.messengerFlag ===
                    true ? (
                      <li
                        onClick={this.handleshareOptionSelect.bind(this, 2)}
                        className={
                          this.state.shareCampaignViaSettingModal
                            .messengerClickable
                            ? this.state.selectedButton === 2
                              ? "active"
                              : ""
                            : "dis-btns"
                        }
                        id={this.state.msngrDisable ? "dis-msngr" : ""}
                      >
                        {this.state.selectedButton == 2 ? (
                          <div className="sele">
                            <img
                              src={CardTick}
                              style={{ width: "15px" }}
                              alt={"select-card"}
                            />
                          </div>
                        ) : null}
                        <img
                          className="ico"
                          src={Whatsapp}
                          alt="Whatsapp Icon"
                        />
                        <label>
                          {TranslationContext !== undefined
                            ? TranslationContext.li.sendviamessage
                            : "Messenger"}
                        </label>
                      </li>
                    ) : null}
                    {this.state.shareCampaignViaSettingModal.botFlag ===
                    true ? (
                      <li
                        onClick={this.handleshareOptionSelect.bind(this, 3)}
                        className={
                          this.state.shareCampaignViaSettingModal.botClickable
                            ? this.state.selectedButton == 3
                              ? "active"
                              : ""
                            : "dis-btns"
                        }
                        id={this.state.botDisable ? "dis-bot" : ""}
                      >
                        {this.state.selectedButton == 3 ? (
                          <div className="sele">
                            <img
                              src={CardTick}
                              style={{ width: "15px" }}
                              alt={"select-card"}
                            />
                          </div>
                        ) : null}
                        <img
                          className="ico"
                          src={Whatsapp}
                          alt="Whatsapp Icon"
                        />
                        <label>
                          {TranslationContext !== undefined
                            ? TranslationContext.li.sendviabot
                            : "Bot"}
                        </label>
                      </li>
                    ) : null}
                  </ul>
                </div>

                <div className="sharecampmob">
                  <label
                    className="shareviabtn"
                    onClick={this.handleShareViaModalOpen.bind(this)}
                  >
                    <img
                      className="shareviaimg"
                      src={Sharevia}
                      alt="Share Via"
                    />
                    {TranslationContext !== undefined
                      ? TranslationContext.li.sharevia
                      : "Share Via"}
                  </label>
                </div>
              </div>
            </div>
          ) : null}
          <div className="row">
            <div className="col-12 col-md-6">
              {this.state.campaignkeyinsight !== null ? (
                <>
                  {this.state.campaignkeyinsight.insightText === "" ? (
                    <div className="lifetimevalue lt-single">
                      <table>
                        <tbody>
                          <tr>
                            <td>
                              <h4>
                                {TranslationContext !== undefined
                                  ? TranslationContext.h4.lifetimevalue
                                  : "Lifetime Value"}
                              </h4>
                              <label>
                                {this.state.useratvdetails.lifeTimeValue ? (
                                  <>
                                    ₹{this.state.useratvdetails.lifeTimeValue}
                                  </>
                                ) : (
                                  "₹0"
                                )}
                              </label>
                            </td>
                            <td>
                              <h4>
                                {TranslationContext !== undefined
                                  ? TranslationContext.h4.visitcount
                                  : "Visit Count"}
                              </h4>
                              <label>
                                {this.state.useratvdetails.visitCount !==
                                null ? (
                                  <>
                                    {this.state.useratvdetails.visitCount < 9
                                      ? "0" +
                                        this.state.useratvdetails.visitCount
                                      : this.state.useratvdetails.visitCount}
                                  </>
                                ) : (
                                  "0"
                                )}
                              </label>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  ) : (
                    <div className="lifetimevalue">
                      <table>
                        <tbody>
                          <tr>
                            <td>
                              <h4>
                                {TranslationContext !== undefined
                                  ? TranslationContext.h4.lifetimevalue
                                  : "Lifetime Value"}
                              </h4>
                              <label>
                                {this.state.useratvdetails.lifeTimeValue ? (
                                  <>
                                    ₹{this.state.useratvdetails.lifeTimeValue}
                                  </>
                                ) : (
                                  "₹0"
                                )}
                              </label>
                            </td>
                            <td>
                              <h4>
                                {TranslationContext !== undefined
                                  ? TranslationContext.h4.visitcount
                                  : "Visit Count"}
                              </h4>
                              <label>
                                {this.state.useratvdetails.visitCount !==
                                null ? (
                                  <>
                                    {this.state.useratvdetails.visitCount < 9
                                      ? "0" +
                                        this.state.useratvdetails.visitCount
                                      : this.state.useratvdetails.visitCount}
                                  </>
                                ) : (
                                  "0"
                                )}
                              </label>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  )}
                </>
              ) : (
                <>
                  <div className="lifetimevalue lt-single">
                    <table>
                      <tbody>
                        <tr>
                          <td>
                            <h4>
                              {TranslationContext !== undefined
                                ? TranslationContext.h4.lifetimevalue
                                : "Lifetime Value"}
                            </h4>
                            <label>
                              {this.state.useratvdetails.lifeTimeValue ? (
                                <>₹{this.state.useratvdetails.lifeTimeValue}</>
                              ) : (
                                "₹0"
                              )}
                            </label>
                          </td>
                          <td>
                            <h4>
                              {TranslationContext !== undefined
                                ? TranslationContext.h4.visitcount
                                : "Visit Count"}
                            </h4>
                            <label>
                              {this.state.useratvdetails.visitCount !== null ? (
                                <>
                                  {this.state.useratvdetails.visitCount < 9
                                    ? "0" + this.state.useratvdetails.visitCount
                                    : this.state.useratvdetails.visitCount}
                                </>
                              ) : (
                                "0"
                              )}
                            </label>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </>
              )}
              {this.state.campaignkeyinsight !== null ? (
                <>
                  {this.state.campaignkeyinsight.insightText !== "" ? (
                    <div
                      className={
                        this.state.campaignkeyinsight.showKeyInsights
                          ? "keyinsights"
                          : "keyinsights keyinsights-big"
                      }
                    >
                      {this.state.campaignkeyinsight.showKeyInsights && (
                        <h4>
                          {TranslationContext !== undefined
                            ? TranslationContext.h4.keyinsights
                            : "Key Insights"}
                        </h4>
                      )}
                      <p
                        id="insight-data"
                        className={
                          this.state.handleArrowImg ? "full-data-insight" : ""
                        }
                      >
                        {this.state.campaignkeyinsight.insightText}
                      </p>
                      {this.state.insightShowImg && (
                        <img
                          className={
                            this.state.handleArrowImg
                              ? "keyingsightdrp keyingsightdrp-invert"
                              : "keyingsightdrp"
                          }
                          src={Dropdown3}
                          alt="Down Arrow"
                          onClick={this.handleArrowImg.bind(this)}
                        />
                      )}
                    </div>
                  ) : null}
                </>
              ) : null}
            </div>
            <div className="col-12 col-md-6">
              <div className="productbox">
                <div>
                  <ul
                    className={
                      this.state.showRecommandedtab === false ||
                      this.state.showLastTransactiondtab === true
                        ? "nav alert-nav-tabs3 store-nav-tabs tab-single"
                        : "nav alert-nav-tabs3 store-nav-tabs"
                    }
                    role="tablist"
                  >
                    {this.state.showRecommandedtab ? (
                      <li className="nav-item fo">
                        <a
                          className={
                            this.state.showRecommandedtab
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          href="#recommended-tab"
                          role="tab"
                          aria-controls="recommended-tab"
                          aria-selected="true"
                        >
                          {TranslationContext !== undefined
                            ? TranslationContext.a.recommended
                            : "Recommended"}
                        </a>
                      </li>
                    ) : null}

                    {this.state.lastTransactionItem.length > 0 ? (
                      <li className="nav-item fo">
                        <a
                          className={
                            this.state.showRecommandedtab === false
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          href="#lastTransaction-tab"
                          role="tab"
                          aria-controls="lastTransaction-tab"
                          aria-selected="false"
                        >
                          {TranslationContext !== undefined
                            ? TranslationContext.a.lasttransaction
                            : "Last Transaction"}
                        </a>
                      </li>
                    ) : null}
                  </ul>
                </div>
                <div className="tab-content p-0">
                  <div
                    className={
                      this.state.showRecommandedtab
                        ? "tab-pane fade show active"
                        : "tab-pane fade"
                    }
                    id="recommended-tab"
                    role="tabpanel"
                    aria-labelledby="recommended-tab"
                  >
                    <div className="prodscro">
                      <div className="pro-slidercam">
                        <table className="w-100">
                          <tbody>
                            <tr>
                              {this.state.campaignrecommended !== null &&
                                this.state.campaignrecommended.map(
                                  (item, j) => {
                                    var FullProductName = `${item.color}  ${item.subCategory}  ${item.category}`;
                                    var FinalSize = item.size;
                                    return (
                                      <td key={j}>
                                        {item.imageURL !== "" ? (
                                          <div className="imgbox">
                                            <Popover
                                              overlayClassName="antcustom ant-prodesc"
                                              content={
                                                <div className="productdesc">
                                                  <h4>{FullProductName}</h4>
                                                  <p>
                                                    {TranslationContext !==
                                                    undefined
                                                      ? TranslationContext.p
                                                          .lasttransaction
                                                      : "Last Transaction"}
                                                    -{item.itemCode}
                                                  </p>
                                                  <table>
                                                    <tbody>
                                                      {item.color !== "" ? (
                                                        <>
                                                          <tr>
                                                            <td
                                                              style={{
                                                                width: "50px",
                                                              }}
                                                            >
                                                              <label>
                                                                {TranslationContext !==
                                                                undefined
                                                                  ? TranslationContext
                                                                      .label
                                                                      .colors
                                                                  : "Colors"}
                                                                :
                                                              </label>
                                                            </td>
                                                            <td>
                                                              <ul>
                                                                {item.color ===
                                                                "Blue" ? (
                                                                  <li>
                                                                    <a className="colorblue">
                                                                      <span>
                                                                        1
                                                                      </span>
                                                                    </a>
                                                                  </li>
                                                                ) : null}

                                                                {item.color ===
                                                                "Black" ? (
                                                                  <li>
                                                                    <a className="colorblack">
                                                                      <span>
                                                                        1
                                                                      </span>
                                                                    </a>
                                                                  </li>
                                                                ) : null}

                                                                {item.color ===
                                                                "Grey" ? (
                                                                  <li>
                                                                    <a className="colorgrey">
                                                                      <span>
                                                                        1
                                                                      </span>
                                                                    </a>
                                                                  </li>
                                                                ) : null}

                                                                {item.color ===
                                                                "Red" ? (
                                                                  <li>
                                                                    <a className="colorRed">
                                                                      <span>
                                                                        1
                                                                      </span>
                                                                    </a>
                                                                  </li>
                                                                ) : null}
                                                                {item.color ===
                                                                "Yellow" ? (
                                                                  <li>
                                                                    <a className="colorYellow">
                                                                      <span>
                                                                        1
                                                                      </span>
                                                                    </a>
                                                                  </li>
                                                                ) : null}
                                                                {item.color ===
                                                                "Green" ? (
                                                                  <li>
                                                                    <a className="colorGreen">
                                                                      <span>
                                                                        1
                                                                      </span>
                                                                    </a>
                                                                  </li>
                                                                ) : null}
                                                              </ul>
                                                            </td>
                                                          </tr>
                                                        </>
                                                      ) : null}

                                                      {item.size !== "" ? (
                                                        <>
                                                          <tr>
                                                            <td>
                                                              <label>
                                                                {TranslationContext !==
                                                                undefined
                                                                  ? TranslationContext
                                                                      .label
                                                                      .sizes
                                                                  : "Sizes"}
                                                                :
                                                              </label>
                                                            </td>
                                                            <td>
                                                              {isNaN(
                                                                parseInt(
                                                                  FinalSize
                                                                )
                                                              ) === false ? (
                                                                <ul className="sizes">
                                                                  <li>
                                                                    <a>
                                                                      {
                                                                        item.size
                                                                      }
                                                                    </a>
                                                                  </li>
                                                                </ul>
                                                              ) : (
                                                                <ul>
                                                                  <li>
                                                                    <a>
                                                                      {
                                                                        item.size
                                                                      }
                                                                    </a>
                                                                  </li>
                                                                </ul>
                                                              )}
                                                            </td>
                                                          </tr>
                                                        </>
                                                      ) : null}
                                                    </tbody>
                                                  </table>
                                                  <h3>
                                                    {TranslationContext !==
                                                    undefined
                                                      ? TranslationContext.h3
                                                          .inr
                                                      : "INR"}
                                                    {item.price}/-
                                                  </h3>
                                                </div>
                                              }
                                              placement="left"
                                            >
                                              <img
                                                className="shoeimg"
                                                src={item.imageURL}
                                                alt="Product Image"
                                              />
                                            </Popover>
                                            <Link
                                              to={`//${item.url}`}
                                              target="_blank"
                                            >
                                              <img
                                                className="whatsappico"
                                                src={Whatsapp}
                                                alt="Whatsapp Icon"
                                              />
                                            </Link>
                                          </div>
                                        ) : null}

                                        <h4>{item.subCategory}</h4>
                                      </td>
                                    );
                                  }
                                )}
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div
                    className={
                      this.state.showRecommandedtab === false
                        ? "tab-pane fade show active"
                        : "tab-pane fade"
                    }
                    id="lastTransaction-tab"
                    role="tabpanel"
                    aria-labelledby="lastTransaction-tab"
                  >
                    <div>
                      {this.state.lasttransactiondetails !== null ? (
                        <>
                          {this.state.lasttransactiondetails.amount !== "" ? (
                            <div className="transactionbox">
                              <table>
                                <tbody>
                                  <tr>
                                    <td>
                                      <h5>
                                        {TranslationContext !== undefined
                                          ? TranslationContext.h5.billno
                                          : "Bill No."}
                                      </h5>
                                      <label>
                                        {
                                          this.state.lasttransactiondetails
                                            .billNo
                                        }
                                      </label>
                                    </td>
                                    <td>
                                      <h5>
                                        {TranslationContext !== undefined
                                          ? TranslationContext.h5.amount
                                          : "Amount"}
                                      </h5>
                                      <label>
                                        {
                                          this.state.lasttransactiondetails
                                            .amount
                                        }
                                      </label>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <h5>
                                        {TranslationContext !== undefined
                                          ? TranslationContext.h5.store
                                          : "Store"}
                                      </h5>
                                      <label>
                                        {
                                          this.state.lasttransactiondetails
                                            .storeName
                                        }
                                      </label>
                                    </td>
                                    <td>
                                      <h5>
                                        {TranslationContext !== undefined
                                          ? TranslationContext.h5.date
                                          : "Date"}
                                      </h5>
                                      <label>
                                        {
                                          this.state.lasttransactiondetails
                                            .billDate
                                        }
                                      </label>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                              <div className="trasactablist">
                                <div className="myTicket-table remov agentlist last-trans-table">
                                  <ReactTable
                                    className="limit-react-table-body tabscrol"
                                    data={this.state.lastTransactionItem}
                                    columns={[
                                      {
                                        Header: (
                                          <span>
                                            {TranslationContext !== undefined
                                              ? TranslationContext.span.article
                                              : "Article"}
                                          </span>
                                        ),
                                        accessor: "article",
                                      },
                                      {
                                        Header: (
                                          <span>
                                            {TranslationContext !== undefined
                                              ? TranslationContext.span.qty
                                              : "Qty."}
                                          </span>
                                        ),
                                        accessor: "quantity",
                                        width: 60,
                                      },
                                      {
                                        Header: (
                                          <span>
                                            {TranslationContext !== undefined
                                              ? TranslationContext.span.amount
                                              : "Amount"}
                                          </span>
                                        ),
                                        accessor: "amount",
                                        width: 80,
                                      },
                                    ]}
                                    minRows={2}
                                    showPagination={false}
                                    resizable={false}
                                  />
                                </div>
                              </div>
                            </div>
                          ) : (
                            <label className="ChecknoDataCamp">
                              {TranslationContext !== undefined
                                ? TranslationContext.label.norecordfound
                                : "No Record Found"}
                            </label>
                          )}
                        </>
                      ) : (
                        <>
                          <label className="ChecknoDataCamp">
                            {TranslationContext !== undefined
                              ? TranslationContext.label.norecordfound
                              : "No Record Found"}
                          </label>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {!this.state.isMobileView ? (
            <div className="row">
              <div className="col-12">
                <div className="sharecamp">
                  <h4>
                    {TranslationContext !== undefined
                      ? TranslationContext.h4.sharecampaignvia
                      : "Share Campaign Via"}
                  </h4>
                  <ul>
                    {this.state.shareCampaignViaSettingModal.smsFlag ===
                    true ? (
                      <li
                        onClick={this.handleSendViaSMS.bind(
                          this,
                          this.state.customerModalDetails
                        )}
                        className={
                          this.state.shareCampaignViaSettingModal.smsClickable
                            ? ""
                            : "dis-btns"
                        }
                        id={this.state.smsDisable ? "dis-sms" : ""}
                      >
                        <img className="ico" src={Sms1} alt="SMS Icon" />
                        {TranslationContext !== undefined
                          ? TranslationContext.li.sms
                          : "SMS"}
                      </li>
                    ) : null}
                    {this.state.shareCampaignViaSettingModal.emailFlag ===
                    true ? (
                      <li
                        className={
                          this.state.shareCampaignViaSettingModal.emailClickable
                            ? ""
                            : "dis-btns"
                        }
                      >
                        <img
                          className="emailico"
                          src={Email}
                          alt="Email Icon"
                        />
                        {TranslationContext !== undefined
                          ? TranslationContext.li.email
                          : "Email"}
                      </li>
                    ) : null}
                    {this.state.shareCampaignViaSettingModal.messengerFlag ===
                    true ? (
                      <li
                        onClick={this.handleSendViaMessanger.bind(
                          this,
                          this.state.customerModalDetails
                        )}
                        className={
                          this.state.shareCampaignViaSettingModal
                            .messengerClickable
                            ? ""
                            : "dis-btns"
                        }
                        id={this.state.msngrDisable ? "dis-msngr" : ""}
                      >
                        <img
                          className="ico"
                          src={Whatsapp}
                          alt="Whatsapp Icon"
                        />
                        {TranslationContext !== undefined
                          ? TranslationContext.li.sendviamessage
                          : "Send Via Messenger"}
                      </li>
                    ) : null}
                    {this.state.shareCampaignViaSettingModal.botFlag ===
                    true ? (
                      <li
                        onClick={this.handleSendViaBotData.bind(
                          this,
                          this.state.customerModalDetails
                        )}
                        className={
                          this.state.shareCampaignViaSettingModal.botClickable
                            ? ""
                            : "dis-btns"
                        }
                        id={this.state.botDisable ? "dis-bot" : ""}
                      >
                        <img
                          className="ico"
                          src={Whatsapp}
                          alt="Whatsapp Icon"
                        />
                        {TranslationContext !== undefined
                          ? TranslationContext.li.sendviabot
                          : "Send Via Bot"}
                      </li>
                    ) : null}
                  </ul>
                </div>
                <div className="sharecampmob">
                  <label
                    className="shareviabtn"
                    onClick={this.handleShareViaModalOpen.bind(this)}
                  >
                    <img
                      className="shareviaimg"
                      src={Sharevia}
                      alt="Share Via"
                    />
                    {TranslationContext !== undefined
                      ? TranslationContext.li.sharevia
                      : "Share Via"}
                  </label>
                </div>
              </div>
            </div>
          ) : null}
          {this.state.isMobileView ? (
            <button
              disabled={
                this.state.smsDisable ||
                this.state.msngrDisable ||
                this.state.botDisable
              }
              className="shareviabtnmdl"
              onClick={this.handleshareNowButtonClick.bind(this)}
            >
              Share Now
            </button>
          ) : null}
        </Modal>
        <Modal
          open={this.state.ResponsiveShareNow}
          onClose={this.handleShareNowCloseModal.bind(this)}
          center
          modalId="sharesuccesfullpopup"
          overlayId=""
        >
          <img
            src={CancelIcon}
            alt="cancel-icone"
            className="cust-icon"
            onClick={this.handleShareNowCloseModal.bind(this)}
          />
          <div>
            <img className="tick" src={Tick} alt="Tick Icon" />
            <h3>
              {TranslationContext !== undefined
                ? TranslationContext.h3.sharedsuccessfully
                : "Shared Successfully!"}
            </h3>
            <p>
              {TranslationContext !== undefined
                ? TranslationContext.h3.yourmessagehasbeensharedsuccessfully
                : "Your Message has been shared successfully"}
            </p>
          </div>
        </Modal>
        <Modal
          open={this.state.ResponsiveBroadCast}
          onClose={this.handleBroadCastModalClose.bind(this)}
          center
          modalId="sharecamp-popupmob"
          overlayId="logout-ovrly-none"
        >
          <img
            src={CancelIcon}
            alt="cancel-icone"
            className="cust-icon"
            onClick={this.handleBroadCastModalClose.bind(this)}
          />

          <div className="general-popover popover-body broadcastpop">
            {this.state.broadCastLoading === true ? (
              <div className="loader-icon-cntr">
                <div className="loader-icon"></div>
              </div>
            ) : (
              <>
                {this.state.campaignExecutionDetails.length > 0 ? (
                  <label className="broadcasttitle">
                    {TranslationContext !== undefined
                      ? TranslationContext.label.recentcampaign
                      : "Recent Campaigns"}
                  </label>
                ) : null}

                {this.state.campaignExecutionDetails !== null &&
                  this.state.campaignExecutionDetails.map((item, b) => (
                    <div className="broembox clearfix" key={b}>
                      <p>
                        <label>{item.channelType}</label>
                        <span>
                          {TranslationContext !== undefined
                            ? TranslationContext.span.executeddate
                            : "Executed Date"}
                          : {item.executionDate}
                        </span>
                      </p>
                    </div>
                  ))}
              </>
            )}
            <>
              <label className="broadcastsubtitle">
                {TranslationContext !== undefined
                  ? TranslationContext.label.choosechannel
                  : "Choose Channel"}
              </label>
              <div>
                <Radio.Group
                  onChange={this.handleBroadcastChange}
                  value={this.state.broadcastChannel}
                >
                  {this.state.broadcastConfiguration.emailFlag ? (
                    <div className="">
                      <Radio
                        className="broadChannel"
                        value="Email"
                        disabled={
                          this.state.broadcastConfiguration.disableEmail
                        }
                      >
                        {TranslationContext !== undefined
                          ? TranslationContext.radio.email
                          : "Email"}
                      </Radio>
                    </div>
                  ) : null}
                  {this.state.broadcastConfiguration.smsFlag ? (
                    <Radio
                      className="broadChannel"
                      value="SMS"
                      disabled={this.state.broadcastConfiguration.disableSMS}
                    >
                      {TranslationContext !== undefined
                        ? TranslationContext.radio.sms
                        : "SMS"}
                    </Radio>
                  ) : null}
                  {this.state.broadcastConfiguration.whatsappFlag ? (
                    <Radio
                      className="broadChannel"
                      value="Whatsapp"
                      disabled={
                        this.state.broadcastConfiguration.disableWhatsapp
                      }
                    >
                      {TranslationContext !== undefined
                        ? TranslationContext.radio.whatsapp
                        : "Whatsapp"}
                    </Radio>
                  ) : null}
                  {this.state.broadcastConfiguration.chatSessionActiveFlag ? (
                    <Radio
                      className="broadChannel"
                      value="ChatSession"
                      disabled={
                        this.state.broadcastConfiguration
                          .disableChatSessionActiveFlag
                      }
                    >
                      Chat Session Active
                    </Radio>
                  ) : null}
                </Radio.Group>
                {this.state.broadcastChannel === "" && (
                  <p
                    style={{
                      color: "red",
                      marginBottom: "0px",
                    }}
                  >
                    {this.state.broadChannelValidation}
                  </p>
                )}
              </div>
              <label className="broadcastsubtitle">Choose Customer</label>
              <div>
                <Radio.Group
                  onChange={this.handleBroadcastCustomerTypeChange}
                  value={this.state.broadcastCustomerType}
                >
                  <Radio className="broadChannel" value={1}>
                    All Customer
                  </Radio>

                  <Radio className="broadChannel" value={2}>
                    Selective Customer
                  </Radio>
                </Radio.Group>
              </div>
            </>
          </div>
          {this.state.broadcastCustomerType === 1 ? (
            <button
              disabled={this.state.isSendBroadcastLoader}
              type="button"
              className="executebtn"
              onClick={this.handleBroadcastExecute.bind(
                this,
                this.state.storeCode,
                this.state.campaignCode
              )}
            >
              {this.state.isSendBroadcastLoader ? (
                <FontAwesomeIcon
                  className="circular-loader"
                  icon={faCircleNotch}
                  spin
                />
              ) : null}
              {TranslationContext !== undefined
                ? TranslationContext.button.execute
                : "Execute"}
            </button>
          ) : (
            <button
              type="button"
              className="executebtn"
              onClick={this.handleBroadcastNextButtonClick.bind(this)}
            >
              {TranslationContext !== undefined
                ? TranslationContext.button.next
                : "Next"}
            </button>
          )}
        </Modal>
        {/* ---------------Share via Modal-------------------- */}
        <Modal
          open={this.state.responsiveShareVia}
          onClose={this.handleShareViaModalClose.bind(this)}
          center
          modalId="sharecamp-popup"
          overlayId="logout-ovrly"
          classNames={{ modal: "nexticon-popupbottom" }}
        >
          <div
            className="closemob"
            style={{ marginBottom: "10px" }}
            onClick={this.handleShareViaModalClose.bind(this)}
          ></div>
          <img
            src={BackLeft}
            onClick={this.handleShareViaModalClose.bind(this)}
            alt="back"
            className="closemobback"
          />
          <img
            src={CancelIcon}
            alt="cancel-icone"
            className="cust-icon"
            onClick={this.handleShareViaModalClose.bind(this)}
          />
          <div>
            <h4>
              {TranslationContext !== undefined
                ? TranslationContext.h4.choosechannel
                : "Choose Channel"}
            </h4>
            <table className="w-100">
              <tbody>
                <tr>
                  {this.state.shareCampaignViaSettingModal.messengerFlag ===
                  true ? (
                    <td>
                      <a
                        href={Demo.BLANK_LINK}
                        className={
                          this.state.shareCampaignViaSettingModal
                            .messengerClickable
                            ? ""
                            : "dis-btns"
                        }
                      >
                        <div
                          className="chatbox"
                          onClick={this.handleSelectChannelsOnchange.bind(
                            this,
                            "Messenger"
                          )}
                        >
                          <img
                            className="ico"
                            src={Whatsapp}
                            alt="Whatsapp Icon"
                          />
                          {this.state.Respo_ChannelMessanger === true ? (
                            <img className="tick" src={Tick} alt="Tick Icon" />
                          ) : null}
                          {TranslationContext !== undefined
                            ? TranslationContext.div.sendviamessenger
                            : "Send Via Messenger"}
                        </div>
                      </a>
                    </td>
                  ) : null}
                  {this.state.shareCampaignViaSettingModal.botFlag === true ? (
                    <td>
                      <a
                        href={Demo.BLANK_LINK}
                        className={
                          this.state.shareCampaignViaSettingModal.botClickable
                            ? ""
                            : "dis-btns"
                        }
                      >
                        <div
                          className="chatbox"
                          onClick={this.handleSelectChannelsOnchange.bind(
                            this,
                            "Bot"
                          )}
                        >
                          <img
                            className="ico"
                            src={Whatsapp}
                            alt="Whatsapp Icon"
                          />
                          {this.state.Respo_ChannelBot === true ? (
                            <img className="tick" src={Tick} alt="Tick Icon" />
                          ) : null}

                          {TranslationContext !== undefined
                            ? TranslationContext.div.sendviabot
                            : "Send Via Bot"}
                        </div>
                      </a>
                    </td>
                  ) : null}
                </tr>
                <tr>
                  {this.state.shareCampaignViaSettingModal.smsFlag === true ? (
                    <td>
                      <a
                        href={Demo.BLANK_LINK}
                        className={
                          this.state.shareCampaignViaSettingModal.smsClickable
                            ? ""
                            : "dis-btns"
                        }
                      >
                        <div
                          className="chatbox"
                          onClick={this.handleSelectChannelsOnchange.bind(
                            this,
                            "SMS"
                          )}
                        >
                          <img className="ico" src={Sms1} alt="SMS Icon" />
                          {this.state.Respo_ChannelSMS === true ? (
                            <img className="tick" src={Tick} alt="Tick Icon" />
                          ) : null}

                          {TranslationContext !== undefined
                            ? TranslationContext.div.sms
                            : "SMS"}
                        </div>
                      </a>
                    </td>
                  ) : null}
                  {this.state.shareCampaignViaSettingModal.emailFlag ===
                  true ? (
                    <td>
                      <a
                        href={Demo.BLANK_LINK}
                        className={
                          this.state.shareCampaignViaSettingModal.emailClickable
                            ? ""
                            : "dis-btns"
                        }
                      >
                        <div
                          className="chatbox"
                          onClick={this.handleSelectChannelsOnchange.bind(
                            this,
                            "Email"
                          )}
                        >
                          <img className="ico" src={Email} alt="Email Icon" />
                          {this.state.Respo_ChannelEmail === true ? (
                            <img className="tick" src={Tick} alt="Tick Icon" />
                          ) : null}

                          {TranslationContext !== undefined
                            ? TranslationContext.div.email
                            : "Email"}
                        </div>
                      </a>
                    </td>
                  ) : null}
                </tr>
              </tbody>
            </table>

            <div className="sharecampmob">
              <label
                className="shareviabtn"
                onClick={this.handleShareNowOpenModal.bind(this)}
                id={this.state.shareDisable ? "dis-share" : ""}
              >
                {TranslationContext !== undefined
                  ? TranslationContext.label.sharenow
                  : "Share Now"}
              </label>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

export default StoreCampaign;
