import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import Demo from "../../../store/Hashtag";
import { Tabs, Tab } from "react-bootstrap-tabs";
import { Select, Popover, Checkbox } from "antd";
import BlackInfoIcon from "./../../../assets/Images/Info-black.png";
import DownExcel from "./../../../assets/Images/csv.png";
import { CSVLink } from "react-csv";
import Pagination from "react-pagination-js";
import { Switch, Table } from "antd";
import config from "./../../../helpers/config";
import Modal from "react-bootstrap/Modal";
import { NotificationManager } from "react-notifications";
import * as translationHI from "./../../../translations/hindi";
import * as translationMA from "./../../../translations/marathi";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import SettingsService from "../../../Services/Settings/SettingsService";
var uid = 0;
const { Option } = Select;

class RetargetConfig extends Component {
  constructor(props) {
    super(props);

    this.state = {
      retargetHSMTimeDuration: "M",
      retargetHSMTimeValue: "",
      numberofCTA: "",
      placeHolders: "",
      sendViaWhatsapp: false,
      sendViaSMS: false,
      sendViaEmail: false,
      temaplateName: "",
      hsmText: "",
      isBellTicket: false,
      isLogTicket: false,
      isRetargetHSMTimeValue: "",
      isTemaplateName:"",
      isHsmText:"",
      isNumberofCTA: "",
    };
    this.SettingsService = new SettingsService();
  }
  componentDidMount() {
    if (window.localStorage.getItem("module")) {
      var moduleData = JSON.parse(window.localStorage.getItem("module"));
      if (moduleData) {
        var campModule = moduleData.filter(
          (x) => x.moduleName === "Settings" && x.modulestatus === true
        );
        if (campModule.length === 0) {
          this.props.history.push("/store/404notfound");
        }
      }
    }
    this.handleGetRetargetConfig();
    if (window.localStorage.getItem("translateLanguage") === "hindi") {
      this.state.translateLanguage = translationHI;
    } else if (window.localStorage.getItem("translateLanguage") === "marathi") {
      this.state.translateLanguage = translationMA;
    } else {
      this.state.translateLanguage = {};
    }
  }
  //handle get chat session
  handleGetRetargetConfig() {
    let self = this;
    this.SettingsService.Post("/RetargetFlow/GetRetargetConfiguration")
      .then((response) => {
        var message = response.message;
        var data = response.responseData;
        if (message === "Success" && data) {
          window.localStorage.setItem("RetargetConfig", JSON.stringify(data));
          self.setState({
            programCode: data.programCode,
            retargetHSMTimeValue: data.retargetHSMTimeValue,
            retargetHSMTimeDuration: data.retargetHSMTimeDuration,
            numberofCTA: data.numberofCTA,
            sendViaWhatsapp: data.sendViaWhatsapp,
            sendViaSMS: data.sendViaSMS,
            sendViaEmail: data.sendViaEmail,
            temaplateName: data.temaplateName,
            hsmText: data.hsmText,
            isBellTicket: data.isBellTicket,
            isLogTicket: data.isLogTicket,
            placeHolders: data.placeHolders
          });
        } else {
          self.setState({
            retargetHSMTimeDuration: "",
            retargetHSMTimeValue: "",
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  //handle update chate session
  handleUpdateRetargetConfig() {
    const TranslationContext = this.state.translateLanguage.default;
    let self = this;
    if (
      this.state.isRetargetHSMTimeValue === "" &&
      this.state.isTemaplateName === "" &&
      this.state.isHsmText === "" &&
      this.state.isNumberofCTA === ""
    ) {
      let inputData = {
        programCode: this.state.programCode,
        retargetHSMTimeValue: Number(this.state.retargetHSMTimeValue),
        temaplateName: this.state.temaplateName,
        hsmText: this.state.hsmText,
        numberofCTA: Number(this.state.numberofCTA),
        placeHolders: this.state.placeHolders,
        sendViaWhatsapp: this.state.sendViaWhatsapp,
        sendViaSMS: this.state.sendViaSMS,
        sendViaEmail: this.state.sendViaEmail,
        isBellTicket: this.state.isBellTicket,
        isLogTicket: this.state.isLogTicket,
        retargetHSMTimeDuration: this.state.retargetHSMTimeDuration
      };
      this.SettingsService.PostWithData(
        "/RetargetFlow/UpdateRetargetConfiguration",
        inputData
      )
        .then((response) => {
          var message = response.message;
          if (message === "Success") {
            NotificationManager.success(
              TranslationContext !== undefined
                ? TranslationContext.alertmessage.recordupdatedsuccessfully
                : "Record Updated Successfully"
            );
            setTimeout(() => {
             this.handleGetRetargetConfig();
            }, 1000);
          } else {
            NotificationManager.error(
              TranslationContext !== undefined
                ? TranslationContext.alertmessage.recordnotupdated
                : "Record Not Updated"
            );
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }
  //handle submit button
  handleSubmit() {
    if (this.state.retargetHSMTimeValue === "") {
      this.setState({
        isRetargetHSMTimeValue: "Please Enter Value",
      });
      NotificationManager.error("Please Enter HSM Time Value!!!");
    } else {
      this.setState({
        isRetargetHSMTimeValue: "",
      });
    }
    if (this.state.temaplateName === "") {
      this.setState({
        isTemaplateName: "Please Enter Value",
      });
      NotificationManager.error("Please Enter Template Name!!!");
    } else {
      this.setState({
        isTemaplateName: "",
      });
    }
    if (this.state.hsmText === "") {
      this.setState({
        isHsmText: "Please Enter Value",
      });
      NotificationManager.error("Please Enter HSM text!!!");
    } else {
      this.setState({
        isHsmText: "",
      });
    }
    if (this.state.numberofCTA === "") {
      this.setState({
        isNumberofCTA: "Please Enter Value",
      });
      NotificationManager.error("Please Enter CTA!!!");
    } else {
      this.setState({
        isNumberofCTA: "",
      });
    }

    setTimeout(() => {
      this.handleUpdateRetargetConfig();
    }, 10);
  }

  //handle change textbox
  handleOnChange= async (e) =>{
    debugger
    const { name, value } = e.target;
      await this.setState({
        [name]: value,
      });
    
  }
  //handle on change drop-down
  handleRetargetHSMTime(e) {
    this.setState({ retargetHSMTimeDuration: e });
  }
  //handle chat tab radio button change
  handleRadioButtonChange = (e) => {
    debugger
    var name = e.target.name;

    if (name === "sendViaWhatsapp") {
      this.setState({ sendViaWhatsapp: e.target.checked });
    }
    if (name === "sendViaSMS") {
      this.setState({ sendViaSMS: e.target.checked });
    }
    if (name === "sendViaEmail") {
      this.setState({ sendViaEmail: e.target.checked });
    }
    if(name === "isBellTicket"){
      this.setState({ isBellTicket: e.target.checked });
      if(this.state.isBellTicket !== true){
        document.getElementById("isLogTicket").disabled = true;
        this.setState({ isLogTicket: false });
      }
      else{
        document.getElementById("isLogTicket").disabled = false;
      }
    }
    if(name === "isLogTicket"){
      this.setState({ isLogTicket: e.target.checked });
      if(this.state.isLogTicket !== true){
      document.getElementById("isBellTicket").disabled = true;
      this.setState({ isBellTicket: false });
      }
      else{
        document.getElementById("isBellTicket").disabled = false;
      }
    } 
  }

  render() {
    const TranslationContext = this.state?.translateLanguage?.default;
    return (
      <Fragment>
        <div className="container-fluid setting-title setting-breadcrumb">
          <Link to="/store/settings" className="header-path">
            {TranslationContext !== undefined
              ? TranslationContext.link.setting
              : "Settings"}
          </Link>
          <span>&gt;</span>
          <Link
            to={{
              pathname: "/store/settings",
              tabName: "store-tab",
            }}
            className="header-path"
          >
            {TranslationContext !== undefined
              ? TranslationContext.link.store
              : "Store"}
          </Link>
          <span>&gt;</span>
          <Link to={Demo.BLANK_LINK} className="active header-path">
            {TranslationContext !== undefined
              ? TranslationContext.link.order
              : "Retarget Config"}
          </Link>
        </div>
        <div className="Store-paddmodule storeModule">
          <div className="module-tabs">
            {/* <section>
              <Tabs
                onSelect={(index, label) =>
                  this.setState({ selTab: label, isOrderLoading: false })
                }
                selected={this.state.selTab}
              >
                <Tab
                  label={
                    TranslationContext !== undefined
                      ? TranslationContext.label.order
                      : "Retarget"
                  }
                > */}
            <div className="row chattab-card">
              <div className="col-md-12">
                <div
                  className="card"
                  style={{ padding: "35px", height: "auto" }}
                >
                  <div className="chat-drop-down">
                    <div
                      className="row"
                      style={{ width: "100%", margin: "0" }}
                    >
                      <div className="col-md-3">
                        {TranslationContext !== undefined
                          ? TranslationContext.div.programcode
                          : "Program Code"}
                      </div>
                      <div className="col-md-3">
                        <Select
                          showArrow={true}
                          style={{ width: "100%", marginBottom: "10px" }}
                          placeholder={
                            TranslationContext !== undefined
                              ? TranslationContext.placeholder
                                .selectprogramcode
                              : "Select program code"
                          }
                          value={this.state.programCode}
                          disabled={true}
                        >
                          <Option value={this.state.programCode}>
                            {this.state.programCode}
                          </Option>
                        </Select>
                      </div>
                    </div>
                    <div className="col-md-3"></div>
                    <div className="col-md-3"></div>
                  </div>
                  <div
                    className="row"
                    style={{ width: "100%", margin: "0" }}
                  >
                    <div className="col-md-3">
                      {TranslationContext !== undefined
                        ? TranslationContext.div.agentchatsessiontimeout
                        : "Retarget HSM Time Value"}
                    </div>
                    <div className="col-md-3">
                      <div className="chattxtdivcus">
                        <input
                          type="text"
                          className="chatsetngtxt"
                          placeholder={
                            TranslationContext !== undefined
                              ? TranslationContext.placeholder.entervalue
                              : "Enter value"
                          }
                          name="retargetHSMTimeValue"
                          onChange={this.handleOnChange.bind(this)}
                          value={this.state.retargetHSMTimeValue}
                          maxLength={2}
                        />
                        <Select
                          showArrow={true}
                          defaultValue="M"
                          style={{ marginLeft: "10px" }}
                          name="retargetHSMTimeDuration"
                          onChange={this.handleRetargetHSMTime.bind(
                            this
                          )}
                          value={this.state.retargetHSMTimeDuration}
                        >
                          <Option value="M">
                            {TranslationContext !== undefined
                              ? TranslationContext.option.m
                              : "M"}
                          </Option>
                          <Option value="H">
                            {TranslationContext !== undefined
                              ? TranslationContext.option.h
                              : "H"}
                          </Option>
                          <Option value="D">
                            {TranslationContext !== undefined
                              ? TranslationContext.option.d
                              : "D"}
                          </Option>
                        </Select>
                        <Popover content={<></>} placement="bottom">
                          <img
                            className="info-icon-cp"
                            style={{ visibility: "hidden" }}
                            src={BlackInfoIcon}
                            alt="info-icon"
                          />
                        </Popover>
                      </div>
                    </div>
                    <div className="col-md-3"></div>
                    <div className="col-md-3"></div>
                  </div>
                  <div
                    className="row"
                    style={{ width: "100%", margin: "0" }}
                  >
                    <div className="col-md-3">
                      {TranslationContext !== undefined
                        ? TranslationContext.div.temaplateName
                        : "Template Name"}
                    </div>
                    <div className="col-md-3">
                      {/* <div className="chattxtdivcus"> */}
                        <input
                          type="text"
                          className="chatsetngtxt"
                          placeholder={
                            TranslationContext !== undefined
                              ? TranslationContext.placeholder.entervalue
                              : "Enter value"
                          }
                          name="temaplateName"
                          value={this.state.temaplateName}
                          onChange={this.handleOnChange.bind(this)}
                        />
                      {/* </div> */}
                    </div>
                    <div className="col-md-3"></div>
                    <div className="col-md-3"></div>
                  </div>
                  <div
                    className="row"
                    style={{ width: "100%", margin: "0" }}
                  >
                    <div className="col-md-3">
                      {TranslationContext !== undefined
                        ? TranslationContext.div.temaplateName
                        : "HSM Text"}
                    </div>
                    <div className="col-md-3">
                      <div className="chattxtdivcus">
                        <textarea
                          type="text"
                          className="chatsetngtxt"
                          placeholder={
                            TranslationContext !== undefined
                              ? TranslationContext.placeholder.entervalue
                              : "Enter value"
                          }
                          name="hsmText"
                          onChange={this.handleOnChange.bind(this)}
                          value={this.state.hsmText}
                        />
                      </div>
                    </div>
                    <div className="col-md-3"></div>
                    <div className="col-md-3"></div>
                  </div>
                  <div
                    className="row"
                    style={{ width: "100%", margin: "0" }}
                  >
                    <div className="col-md-3">
                      {TranslationContext !== undefined
                        ? TranslationContext.div.agentchatsessiontimeout
                        : "Number of CTA"}
                    </div>
                    <div className="col-md-3">
                      <div className="chattxtdivcus">
                        <input
                          type="text"
                          className="chatsetngtxt"
                          placeholder={
                            TranslationContext !== undefined
                              ? TranslationContext.placeholder.entervalue
                              : "Enter value"
                          }
                          name="numberofCTA"
                          onChange={this.handleOnChange.bind(this)}
                          value={this.state.numberofCTA}
                          maxLength={2}
                        />
                      </div>
                    </div>
                    <div className="col-md-3"></div>
                    <div className="col-md-3"></div>
                  </div>
                  <div
                    className="row"
                    style={{ width: "100%", margin: "0" }}
                  >
                    <div className="col-md-3">
                      Send Message via WhatsApp
                    </div>
                    <div className="col-md-3">
                      <div className="module-switch crm-margin-div crm-padding-div">
                        <div className="switch switch-primary d-inline m-r-10">
                          <input
                            type="checkbox"
                            id="sendViaWhatsapp"
                            name="sendViaWhatsapp"
                            checked={this.state.sendViaWhatsapp}
                            onChange={this.handleRadioButtonChange.bind(
                              this
                            )}
                          />
                          <label
                            htmlFor="sendViaWhatsapp"
                            className="cr cr-float-right"
                          ></label>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3"></div>
                    <div className="col-md-3"></div>
                  </div>
                  <div
                    className="row"
                    style={{ width: "100%", margin: "0" }}
                  >
                    <div className="col-md-3">
                      Send Message via SMS
                    </div>
                    <div className="col-md-3">
                      <div className="module-switch crm-margin-div crm-padding-div">
                        <div className="switch switch-primary d-inline m-r-10">
                          <input
                            type="checkbox"
                            id="sendViaSMS"
                            name="sendViaSMS"
                            checked={this.state.sendViaSMS}
                            onChange={this.handleRadioButtonChange.bind(
                              this
                            )}
                          />
                          <label
                            htmlFor="sendViaSMS"
                            className="cr cr-float-right"
                          ></label>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3"></div>
                    <div className="col-md-3"></div>
                  </div>
                  <div
                    className="row"
                    style={{ width: "100%", margin: "0" }}
                  >
                    <div className="col-md-3">
                      Send Message via Email
                    </div>
                    <div className="col-md-3">
                      <div className="module-switch crm-margin-div crm-padding-div">
                        <div className="switch switch-primary d-inline m-r-10">
                          <input
                            type="checkbox"
                            id="sendViaEmail"
                            name="sendViaEmail"
                            checked={this.state.sendViaEmail}
                            onChange={this.handleRadioButtonChange.bind(
                              this
                            )}
                          />
                          <label
                            htmlFor="sendViaEmail"
                            className="cr cr-float-right"
                          ></label>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3"></div>
                    <div className="col-md-3"></div>
                  </div>
                  <div
                    className="row"
                    style={{ width: "100%", margin: "0" }}
                  >
                    <div className="col-md-3">
                      Bell Ticket
                    </div>
                    <div className="col-md-3">
                      <div className="module-switch crm-margin-div crm-padding-div">
                        <div className="switch switch-primary d-inline m-r-10">
                          <input
                            type="checkbox"
                            id="isBellTicket"
                            name="isBellTicket"
                            checked={this.state.isBellTicket}
                            onChange={this.handleRadioButtonChange.bind(
                              this
                            )}
                          />
                          <label
                            htmlFor="isBellTicket"
                            className="cr cr-float-right"
                          ></label>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3"></div>
                    <div className="col-md-3"></div>
                  </div>
                  <div
                    className="row"
                    style={{ width: "100%", margin: "0" }}
                  >
                    <div className="col-md-3">
                      Log Ticket
                    </div>
                    <div className="col-md-3">
                      <div className="module-switch crm-margin-div crm-padding-div">
                        <div className="switch switch-primary d-inline m-r-10">
                          <input
                            type="checkbox"
                            id="isLogTicket"
                            name="isLogTicket"
                            checked={this.state.isLogTicket}
                            onChange={this.handleRadioButtonChange.bind(
                              this
                            )}
                          />
                          <label
                            htmlFor="isLogTicket"
                            className="cr cr-float-right"
                          ></label>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3"></div>
                    <div className="col-md-3"></div>
                  </div>
                  <div
                    className="row"
                    style={{ width: "100%", margin: "0" }}
                  >
                    <div className="col-md-3"></div>
                    <div className="col-md-3">
                      <div>
                        <button
                          className="butn"
                          type="button"
                          onClick={this.handleSubmit.bind(this)}
                        >
                          {TranslationContext !== undefined
                            ? TranslationContext.button.submit
                            : "SUBMIT"}
                        </button>
                      </div>
                    </div>
                    <div className="col-md-3"></div>
                    <div className="col-md-3"></div>
                  </div>
                </div>
              </div>
            </div>
            {/* </Tab>
              </Tabs>
            </section> */}
          </div>
        </div>
      </Fragment>)
  }
}
export default RetargetConfig;