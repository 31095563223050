import io from "socket.io-client";
import config from "./config";

export const socket = io.connect(config.socketUrl, {
  transports: ["websocket", "polling"],
  upgrade: true,
});

export const videoCallSocket = io.connect(config.videosocketUrl, {
  // export const videoCallSocket = io.connect("http://localhost:4441/", {
  transports: ["websocket", "polling"],
  // upgrade: true,
  // autoconnect: true,
  // reconnection: true,
  // reconnectionDelay: 1000,
  // reconnectionDelayMax: 5000,
  // reconnectionAttempts: 5,
});
