import React, { Component } from "react";
import { Table, Select } from "antd";
import { NotificationManager } from "react-notifications";
import axios from "axios";
import config from "../../helpers/config";
import { authHeader } from "../../helpers/authHeader";
import AppointmentService from "../../Services/Appointment/AppointmentService";
import { handleMaskingNumber } from "../../helpers/maskingNumber";
import LoginService from "../../Services/Login/LoginService";

const { Option } = Select;

class Call extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      CallData: [],
      status: [],
      translateLanguage: {},
      isMobileView: false,
      isTableRowClick: false,
      isSearch: false,
      customerNumber: "",
      isSearchData: false,
      collapseSearch: false,
      agentData: [],
      storeStaffId: null,
      isFilterSearchInMobileVisual: false,
      isNumberMasking: false,
      maskingPrefix: 0,
      maskingSufix: 0,
      isLoading: true,
    };
    this.AppointmentService = new AppointmentService();
    this.LoginService = new LoginService();
  }
  componentDidMount() {
    this.handleCRMRole();
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
    // this.handleAppointmentCount();
    this.handleStoreStaffCommentData();
  }
  resize() {
    if (window.innerWidth <= 1000) {
      this.setState({ isMobileView: window.innerWidth <= 1000 });
    } else {
      this.setState({ isTableRowClick: false, isMobileView: false });
    }
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.resize.bind(this));
  }

  //handle crm role data
  handleCRMRole() {
    
    this.LoginService.Post("/StoreCRMRole/GetStoreRolesByUserID")
      .then((response) => {
        let msg = response.message;
        if (msg === "Success") {
          this.setState({
            isNumberMasking: response.responseData.isNumberMasking,
            maskingPrefix: response.responseData.maskingPrefix,
            maskingSufix: response.responseData.maskingSufix,
            tenantMobileMasking:
              response.responseData.tenantLevelMasking,
            isSuggestionText: response.responseData.suggestionText,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  handleStoreStaffCommentData() {
    let self = this;
    this.setState({
      loading: true,
    });
    axios({
      method: "post",
      url: config.apiUrl + "/CustomerChat/GetStoreStaffCommentList",
      headers: authHeader(),
    })
      .then((response) => {
        let status = response.data.message;
        let data = response.data.responseData;
        if (status === "Success" && data) {
          self.setState({
            CallData: data,
          });
        } else {
          self.setState({
            CallData: [],
            isLoading: false,
          });
        }
        self.setState({
          loading: false,
        });
      })
      .catch((error) => {
        self.setState({
          loading: false,
        });
        console.log(error);
      });
  }

  handleUpdateStaffComment = (commentId, note, mobileNo) => {
    const TranslationContext = this.state.translateLanguage.default;
    let self = this;
    let inputData = {
      CommentId: commentId || 0,
      Note: note || "",
      MobileNo: mobileNo || "",
    };
    axios({
      method: "post",
      url: config.apiUrl + "/CustomerChat/InsertUpdateStaffComment",
      headers: authHeader(),
      data: inputData,
    })
      .then((response) => {
        let status = response.data.message;
        if (status === "Success") {
          NotificationManager.success(
            TranslationContext !== undefined
              ? TranslationContext.alertmessage.recordupdatedsuccessfully
              : "Record Updated Successfully."
          );
          self.handleStoreStaffCommentData();
        } else {
          NotificationManager.error(status);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  handleChangeNote = (item, e) => {
    let tempAppointmentData = this.state.CallData;
    tempAppointmentData.filter((x) => x.commentId === item.commentId)[0].note =
      e.target.value;
    this.setState({
      CallData: tempAppointmentData,
    });
  };

  handleTableFiledChange = (name, item, e) => {
    let tempAppointmentData = this.state.CallData;
    if (name === "Status") {
      tempAppointmentData.filter(
        (x) => x.commentId === item.commentId
      )[0].note = e;
    }
    this.setState({
      CallData: tempAppointmentData,
    });
  };

  render() {
    const TranslationContext = this.state.translateLanguage.default;
    return (
      <>
        <Table
          className="components-table-demo-nested antd-table-campaign custom-antd-table apptab"
          columns={[
            {
              title:
                TranslationContext !== undefined
                  ? TranslationContext.title.date
                  : "Date",
              dataIndex: "date",
            },
            {
              title:
                TranslationContext !== undefined
                  ? TranslationContext.title.time
                  : "Time",
              dataIndex: "time",
            },
            {
              title: "Customer Info",
              dataIndex: "customerInfo",
              className: "appointment-desktop",
              render: (row, item) => {
                return (
                  <div className="d-flex align-items-center overflow-hidden namenumbermain">
                    <div className="namenumber">
                      <div className="appdatemob">
                        {item.customerName}
                        {this.state.isNumberMasking === true ? (
                          <p className="num">
                            {handleMaskingNumber(
                              item.mobileNo,
                              this.state.maskingPrefix,
                              this.state.maskingSufix
                            )}
                          </p>
                        ) : (
                          <p className="num stop_copying">{item.mobileNo}</p>
                        )}
                      </div>
                    </div>
                  </div>
                );
              },
            },
            {
              title: "Comments",
              // dataIndex: "comment",
              render: (row, item) => {
                return (
                  <div className="backcolo bord1">
                    <div className="appdatemob">{item.comment}</div>
                  </div>
                );
              },
            },
            {
              title:
                TranslationContext !== undefined
                  ? TranslationContext.title.staffName
                  : "StoreStaffName",
              dataIndex: "staffName",
            },
            {
              title: "Notes",
              dataIndex: "note",
              render: (row, item) => {
                return (
                  <div className="backcolo bord1">
                    <div className="appdatemob">
                      <textarea
                        disabled={item.isDisable ? true : false}
                        onChange={this.handleChangeNote.bind(this, item)}
                        value={item.note}
                      ></textarea>
                    </div>
                  </div>
                );
              },
            },
            {
              title:
                TranslationContext !== undefined
                  ? TranslationContext.title.actions
                  : "Actions",
              width: "20%",
              render: (row, item) => {
                return (
                  <div className="backcolo bord2">
                    <div className="d-flex justify-content-end">
                      <Select
                        disabled={item.isDisable ? true : false}
                        value={item.status}
                        placeholder="Edit"
                        style={{ width: "100%", marginRight: "10px" }}
                        onChange={this.handleTableFiledChange.bind(
                          this,
                          "Status",
                          item
                        )}
                      >
                        <Option value={""}>Edit</Option>
                      </Select>
                      <div>
                        <button
                          disabled={item.isDisable ? true : false}
                          className={
                            item.isDisable ? "butn btnClickDisabled" : "butn"
                          }
                          onClick={this.handleUpdateStaffComment.bind(
                            this,
                            item.commentId,
                            item.note,
                            item.mobileNo
                          )}
                        >
                          Update
                        </button>
                      </div>
                    </div>
                  </div>
                );
              },
            },
          ]}
          pagination={{ defaultPageSize: 10, showSizeChanger: true }}
          showSizeChanger={true}
          onShowSizeChange={true}
          dataSource={this.state.CallData}
          loading={this.state.loading}
        />
      </>
    );
  }
}

export default Call;
